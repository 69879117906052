import { maxBy } from 'lodash';

import { flatToTree } from '../utils';

import { Model } from './model';
import { Currency } from './currency.model';
import { Benefit } from './benefit.model';
import { Product } from './product.model';

export class ProductVersion extends Model {
  id: string;
  productId: number;
  version: string;
  description: string;
  currency: Currency;
  cardThreshold: number;
  perClaimExcess: number;
  benefits: Benefit[];
  isActive: boolean;
  blockOnPendingClaim: boolean;
  product: Product;

  constructor() {
    super([
      'id',
      'productId',
      'version',
      'description',
      { key: 'currency', useModel: Currency },
      { key: 'cardThreshold', transform: val => parseFloat(val) },
      'perClaimExcess',
      { key: 'benefits', useModel: Benefit },
      'isActive',
      'blockOnPendingClaim',
      { key: 'product', useModel: Product }
    ]);
  }

  private _benefitTree: Benefit[];

  /**
   * Returns benefits in the first level to contain more than 1 benefit.
   */
  get mainBenefits(): Benefit[] {
    const getBenefits = (benefits: Benefit[]) => {
      if (benefits.length > 1) {
        return benefits;
      } else {
        // Benefit with most children
        const benefit = maxBy(benefits, b => b.childrenCount());

        if (benefit.childrenCount() > 0) {
          return getBenefits(benefit.children);
        } else {
          return benefits;
        }
      }
    };

    return getBenefits(this.benefitTree);
  }

  /**
   * Transforms flat benefits array to hierarchical tree
   */
  get benefitTree(): Benefit[] {
    if (typeof this._benefitTree === 'undefined') {
      this._benefitTree = flatToTree(this.benefits, 'id', 'parent', 'children');
    }
    return this._benefitTree;
  }

  get rootBenefit(): Benefit {
    return this.benefitTree[0];
  }
}
