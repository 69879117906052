import { Model } from './model';
import { Merchant } from './merchant.model';
import { BeneficiaryDataUnlock } from './beneficiary-data-unlock.model';

export class Provider extends Model {
  id: number;
  unlocks: BeneficiaryDataUnlock[];
  merchant: Merchant;
  phoneNumber: string;
  email: string;

  constructor() {
    super([
      'id',
      'phoneNumber',
      'email',
      { key: 'merchant', useModel: Merchant },
      { key: 'unlocks', useModel: BeneficiaryDataUnlock }
    ]);
  }
}
