import gql from "graphql-tag";
import { RuleFragments } from '.';

export const RulesSetFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on RulesSetType {
      id
      name
      rules {
        ...ruleFatFields
      }
    }
    ${RuleFragments.fat('ruleFatFields')}
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on RulesSetType {
      id
    }
  `
};
