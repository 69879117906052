import { Component, OnInit, Input } from '@angular/core';

import { checkVal } from '@common/utils';
import { PaymentRule } from '@common/models';
import { mappingsDict } from './mappings';

enum ValueType {
  List = 'list',
  Scalar = 'scalar'
}

interface ResultValue {
  expected: {
    valueType: ValueType;
    values?: any;
    value: any;
  };
  actual: {
    valueType: ValueType;
    values?: any;
    value: any;
  };
}

@Component({
  selector: 'ps-pos-decision-result',
  templateUrl: './pos-decision-result.component.html',
  styleUrls: ['./pos-decision-result.component.scss']
})
export class PosDecisionResultComponent implements OnInit {
  @Input() result: ResultValue;
  @Input() ruleName: PaymentRule;
  ValueType = ValueType;

  constructor() {}

  ngOnInit() {
    checkVal(this.result, 'PosDecisionResultComponent expected result');
    checkVal(this.ruleName, 'PosDecisionResultComponent expected ruleName');
  }

  ruleResultMapping(ruleName: string, resultKey: string) {
    if (!mappingsDict[ruleName] || !mappingsDict[ruleName][resultKey]) {
      return 'Unknown';
    }
    return mappingsDict[ruleName][resultKey];
  }

  isScalar(valueType: ValueType) {
    if (valueType === ValueType.Scalar) {
      return true;
    }
    return false;
  }

  is1dList(valueType: ValueType, value: any) {
    if (valueType === ValueType.List && !this.is2dArray(value)) {
      return true;
    }
    return false;
  }

  isNestedList(valueType: ValueType, value: any) {
    if (valueType === ValueType.List && this.is2dArray(value)) {
      return true;
    }
    return false;
  }

  is2dArray(array: any) {
    if (array && array[0] === undefined) {
      return false;
    } else {
      return array[0].constructor === Array;
    }
  }
}
