<ps-insurer-payments
  *ngIf="insurerPayments?.length > 0"
  [insurerPayments]="insurerPayments"
  [canViewClaim]="canViewClaim"
></ps-insurer-payments>

<ps-beneficiary-payments
  *ngIf="beneficiaryPayments?.length > 0"
  [beneficiaryPayments]="beneficiaryPayments"
  [claimCurrency]="claimCurrency"
></ps-beneficiary-payments>
