import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { MarketFxRateService } from './market-fx-rate.service';

export const MarketFxRateServiceConfig = new InjectionToken<ServiceConfig>(
  'MarketFxRateServiceConfig'
);

@NgModule()
export class MarketFxRateServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<MarketFxRateServiceModule> {
    return {
      ngModule: MarketFxRateServiceModule,
      providers: [MarketFxRateService, { provide: MarketFxRateServiceConfig, useValue: config }]
    };
  }
}
