import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { SearchService } from './search.service';

export const SearchServiceConfig = new InjectionToken<ServiceConfig>('SearchServiceConfig');

@NgModule()
export class SearchServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<SearchServiceModule> {
    return {
      ngModule: SearchServiceModule,
      providers: [SearchService, { provide: SearchServiceConfig, useValue: config }]
    };
  }
}
