import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ProductService } from './product.service';
import { ServiceConfig } from '../common';

export const ProductServiceConfig = new InjectionToken<ServiceConfig>('ProductServiceConfig');

@NgModule()
export class ProductServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<ProductServiceModule> {
    return {
      ngModule: ProductServiceModule,
      providers: [ProductService, { provide: ProductServiceConfig, useValue: config }]
    };
  }
}
