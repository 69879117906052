import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { RulesSet } from '../../models';
import { GQLService } from '../common';
import { RulesSetFragments } from '@common/fragments';

@Injectable({ providedIn: 'root' })
export class RulesSetService extends GQLService<RulesSet> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: RulesSet,
      singular: 'rulesSet',
      plural: 'rulesSets',
      fragments: RulesSetFragments
    });
  }
}
