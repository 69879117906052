<div class="modal show fade" [ngClass]="{ in: visible }" tabindex="-1" role="dialog">
  <div
    class="modal-dialog"
    [ngClass]="{
      'modal-lg': size == 'large',
      'modal-sm': size == 'small',
      'modal-xl': size == 'extra-large'
    }"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ heading }}</h4>
        <ps-button (onClick)="close()" btnClass="close" ariaLabel="Close">
          <span aria-hidden="true">&times;</span>
        </ps-button>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
<div class="modal-backdrop fade" [ngClass]="{ show: visible }"></div>
