import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';

import { DataTableComponent } from './data-table.component';

@Component({
  selector: 'ps-data-table-column',
  templateUrl: './data-table-column.component.html',
  styleUrls: ['./data-table-column.component.scss']
})
export class DataTableColumnComponent implements OnInit {
  @Input() name;
  @Input() value;
  @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;

  constructor(private dataTable: DataTableComponent) {
    dataTable.addColumn(this);
  }

  ngOnInit() {}
}
