import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

import { FiltersComponent } from './filters.component';
import { SelectOption } from '@common/interfaces';

@Component({
  selector: 'ps-filter',
  template: '',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() label: string;
  @Input() param: string;
  @Input() type: string;
  @Input() options?: SelectOption[];
  @Input() default?: string;
  @Input() enabled = true;
  @Output() valueChange = new EventEmitter<string>();

  private supportedTypes = ['text', 'select', 'date', 'dateRange', 'checkbox'];

  constructor(private filters: FiltersComponent) {}

  ngOnInit() {
    this.checkAttributes();
    this.filters.registerFilter(this);
  }

  private checkAttributes() {
    if (!this.label) {
      throw new Error(`Filter must have attribute 'label'`);
    }
    if (!this.param) {
      throw new Error(`Filter must have attribute 'param'`);
    }
    if (!this.type) {
      throw new Error(`Filter must have attribute 'type'`);
    }

    if (this.supportedTypes.indexOf(this.type) === -1) {
      throw new Error(`Filter has unsupported type '${this.type}'`);
    }

    if (this.type === 'select') {
      if (!this.options) {
        throw new Error(`Filter of type 'select' must have attribute 'options'`);
      }
      if (!this.default) {
        throw new Error(`Filter of type 'select' must have attribute 'default'`);
      }
    }
  }

  /**
   * Transforms the querystring input value to appropriate
   * formcontrol value.
   * E.g. '2018-02-01:2018-02-20' -> [ Date, Date ]
   * @param input
   */
  transformIn(input: string): any {
    if (!input) {
      return input;
    }

    if (this.type === 'dateRange') {
      const [from, to] = input.split(':');
      return [new Date(from), new Date(to)];
    } else if (this.type === 'checkbox') {
      return input === 'true' ? true : false;
    } else {
      return input.trim();
    }
  }

  /**
   * Transforms the control output value to appropriate
   * querystring string.
   * E.g. [ Date, Date ] -> '2018-02-01:2018-02-20'
   * @param input
   */
  transformOut(output: any): any {
    if (!output) {
      return output;
    }

    if (this.type === 'dateRange') {
      return (<Array<Date>>output).map(d => moment(d).format('YYYY-MM-DD')).join(':');
    } else if (this.type === 'checkbox') {
      return output;
    } else {
      return output.trim();
    }
  }
}
