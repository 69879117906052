import { Model } from './model';
import { Currency } from './currency.model';
import { PolicyHolder } from './policy-holder.model';
import { CorporatePolicyChain } from './corporate-policy-chain.model';

class PolicyChainPolicy extends Model {
  id: number;
  externalId: string;
  productVersionId: number;
  beneficiaries: number[];
  expiration: string;
  isActive: boolean;

  constructor() {
    super(['id', 'externalId', 'productVersionId', 'beneficiaries', 'expiration', 'isActive']);
  }
}

export class PolicyChain extends Model {
  id: number;
  holderIds: number[];
  corporate: boolean;
  corporatePolicyChainId?: number;
  productId: number;
  currency: Currency;
  policies: PolicyChainPolicy[];
  holders?: PolicyHolder[];
  corporatePolicyChain?: CorporatePolicyChain;

  constructor() {
    super([
      'id',
      'holderIds',
      'corporate',
      'corporatePolicyChainId',
      'productId',
      { key: 'currency', useModel: Currency },
      { key: 'policies', useModel: PolicyChainPolicy },
      { key: 'holders', useModel: PolicyHolder },
      { key: 'corporatePolicyChain', useModel: CorporatePolicyChain }
    ]);
  }

  get corporation() {
    if (!this.corporate || !this.holders || !this.holders[0].corporation) {
      return null;
    }
    return this.holders[0].corporation;
  }
}
