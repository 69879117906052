import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, cutoff: number): string {
    if (!value) {
      return '';
    }

    if (value.length <= cutoff) {
      return value;
    } else {
      return `${value.substr(0, cutoff)}...`;
    }
  }
}
