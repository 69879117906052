import { Model } from './model';

export class Email extends Model {
  id: string;
  body: string;
  recipient: string;
  sender: string;
  createdAt: string;

  constructor() {
    super(['id', 'body', 'recipient', 'sender', 'createdAt']);
  }
}
