import { Component, ViewChild } from '@angular/core';

import { ModalComponent } from '@common/components';
import { checkVal } from '@common/utils';
import { ModalDialog } from '@common/interfaces';

@Component({
  selector: 'ps-claim-note',
  templateUrl: './claim-note.component.html',
  styleUrls: ['./claim-note.component.scss']
})
export class ClaimNoteComponent implements ModalDialog {
  title: string;
  text: string;
  date: string;
  userEmail: string;

  @ViewChild('modal', { static: true }) modal: ModalComponent;

  constructor() {}

  initModal(data: any) {
    this.title = checkVal(data['title'], 'ClaimNoteComponent expected title');
    this.text = checkVal(data['text'], 'ClaimNoteComponent expected text');
    this.date = checkVal(data['timestamp'], 'ClaimNoteComponent expected timestamp');
    this.userEmail = checkVal(data['userEmail'], 'ClaimNoteComponent expected userEmail');
  }
}
