import { Component, OnInit, Input } from '@angular/core';

import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { checkVal } from '@common/utils';

@Component({
  selector: 'ps-decision-result',
  templateUrl: './decision-result.component.html',
  styleUrls: ['./decision-result.component.scss']
})
export class DecisionResultComponent implements OnInit {
  @Input() result: boolean;
  @Input() ruleName: string;
  @Input() ruleDescription: string;

  icons = {
    exclamation: faExclamationCircle,
    ok: faCheckCircle
  };

  constructor() {}

  ngOnInit() {
    checkVal(this.ruleName, 'DecisionResultComponent expected ruleName');
    checkVal(this.ruleDescription, 'DecisionResultComponent expected ruleDescription');
    checkVal(this.result, 'DecisionResultComponent expected result');
  }

  get resultIcon() {
    return this.result ? this.icons.ok : this.icons.exclamation;
  }

  get resultIconClass(): string {
    return this.result ? 'ok-sign' : 'exclamation-sign';
  }
}
