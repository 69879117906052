import gql from 'graphql-tag';

export const MerchantFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on MerchantType {
      id
      name
      address
      gpsLatitude
      gpsLongitude
      detailedInfo
      isVerifiedByAdmin
      provider {
        email
      }
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on MerchantType {
      id
      name
      address
      gpsLatitude
      gpsLongitude
      detailedInfo
      isVerifiedByAdmin
      provider {
        email
      }
    }
  `
};
