<div class="ClaimHistory" [ngClass]="{ 'ClaimHistory--fixed': fixed }" #container>
  <ul *ngIf="history">
    <li class="ClaimHistory__item">
      <span class="date" i18n="@@now">Now</span>
      <ps-button
        btnClass="btn btn-success btn-success--background btn-sm addNoteBtn medium"
        [icon]="icons.plus"
        (onClick)="addNote()"
        i18n="@@addNoteBtn"
        >Add Note</ps-button
      >
    </li>
    <li class="ClaimHistory__item ClaimHistory__loader" *ngIf="loading">
      <div class="Loader"></div>
    </li>
    <li class="ClaimHistory__item" *ngFor="let h of history">
      <span class="date">{{ h.timestamp | localDate }}</span>

      <ng-container *ngIf="h.isCreated">
        <span *ngIf="h.fromMobileApp" i18n="@@claimCreatedByBeneficiaryViaMobileApp"
          >Claim created by <span class="font--semiBold">beneficiary</span> via
          <span class="font--semiBold">mobile app</span></span
        >
        <span *ngIf="h.fromPaymentPlatform" i18n="@@claimCreatedByBeneficiaryViaPaymentCard"
          >Claim created by <span class="font--semiBold">beneficiary</span> via
          <span class="font--semiBold">payment card</span></span
        >
        <span *ngIf="h.fromInsurerDashboard" i18n="@@claimCreatedByInsurer"
          >Claim created by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link>
          via <span class="font--semiBold">insurer dashboard</span></span
        >
      </ng-container>

      <ng-container *ngIf="h.isBenefitChanged">
        <span i18n="@@benefitChangedFrom"
          >Benefit changed from
          <span class="font--semiBold">{{ h.data?.oldBenefit?.title | defaultTo: 'Unknown' }}</span>
          to
          <span class="font--semiBold">{{ h.data?.newBenefit?.title | defaultTo: 'Unknown' }}</span>
          by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
      </ng-container>

      <ng-container *ngIf="h.isMerchantChanged">
        <span *ngIf="h.data.oldMerchant" i18n="@@providerChangedFrom"
          >Provider changed from
          <span class="font--semiBold">{{ h.data?.oldMerchant?.name | defaultTo: 'Unknown' }}</span>
          to
          <span class="font--semiBold">{{ h.data?.newMerchant?.name | defaultTo: 'Unknown' }}</span>
          by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="!h.data.oldMerchant" i18n="@@providerSetTo"
          >Provider set to
          <span class="font--semiBold">{{ h.data?.newMerchant?.name | defaultTo: 'Unknown' }}</span>
          by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
      </ng-container>

      <ng-container *ngIf="h.isReceiptUploaded">
        <span *ngIf="h.fromMobileApp" i18n="@@receiptAddedByBeneficiaryViaMobileApp"
          >Invoice added by <span class="font--semiBold">beneficiary</span> via
          <span class="font--semiBold">mobile app</span></span
        >
        <span *ngIf="h.fromInsurerDashboard" i18n="@@receiptAddedByInsurer"
          >Invoice added by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link>
          via <span class="font--semiBold">insurer dashboard</span></span
        >
        <div class="actions">
          <ps-button
            (onClick)="viewReceipt(h)"
            btnClass="btn btn-secondary medium"
            i18n="@@viewReceiptBtn"
          >
            View Invoice
          </ps-button>
        </div>
      </ng-container>

      <ng-container *ngIf="h.isReceiptNewDecision">
        <span *ngIf="h.data.isAccept" i18n="@@receiptAcceptedBy"
          >Invoice <span class="highlight success">accepted</span> by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isDecline" i18n="@@receiptRejectedBy"
          >Invoice <span class="highlight danger">rejected</span> by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isRequestNew" i18n="@@receiptRequestedBy"
          >New Invoice requested by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isReset"
          >Invoice <span class="highlight warning">reset</span> by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <div class="actions">
          <ps-button
            (onClick)="viewReceipt(h)"
            btnClass="btn btn-secondary medium"
            i18n="@@viewReceiptBtn"
          >
            View Invoice
          </ps-button>
        </div>
      </ng-container>

      <ng-container *ngIf="h.isSupportingDocumentUploaded">
        <span *ngIf="h.fromMobileApp" i18n="@@supportingDocumentAddedByBeneficiaryViaMobileApp"
          >Supporting document added by <span class="font--semiBold">beneficiary</span> via
          <span class="font--semiBold">mobile app</span></span
        >
        <span *ngIf="h.fromInsurerDashboard" i18n="@@supportingDocumentAddedByInsurer"
          >Supporting document added by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link>
          via <span class="font--semiBold">insurer dashboard</span></span
        >
        <div class="actions">
          <ps-button
            (onClick)="viewDocument(h)"
            btnClass="btn btn-secondary medium"
            i18n="@@viewDocumentBtn"
          >
            View Document
          </ps-button>
        </div>
      </ng-container>

      <ng-container *ngIf="h.isSupportingDocumentNewDecision">
        <span *ngIf="h.data.isAccept" i18n="@@supportingDocumentAcceptedBy"
          >Supporting document <span class="highlight success">accepted</span> by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isDecline" i18n="@@supportingDocumentRejectedBy"
          >Supporting document <span class="highlight danger">rejected</span> by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isRequestNew" i18n="@@supportingDocumentRequestedBy"
          >New supporting document was requested by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isReset"
          >Supporting document <span class="highlight warning">reset</span> by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <div class="actions">
          <ps-button (onClick)="viewDocument(h)" btnClass="btn btn-secondary medium">
            View Document
          </ps-button>
        </div>
      </ng-container>

      <ng-container *ngIf="h.isNewNote">
        <span i18n="@@newNoteAddedBy"
          >New note added by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <div class="actions">
          <ps-button
            (onClick)="viewNote(h)"
            btnClass="btn btn-secondary medium viewClaimNoteBtn medium"
            i18n="@@viewNoteBtn"
          >
            View Note
          </ps-button>
        </div>
      </ng-container>

      <ng-container *ngIf="h.isNewDecision">
        <span *ngIf="h.data.isDeclinePayment" i18n="@@claimFlaggedAsDeclined"
          >Claim has been flagged as <span class="highlight danger">declined</span> due to a
          declined payment
        </span>

        <span *ngIf="h.data.isPartiallyAccept" i18n="@@claimPartiallyAcceptedBy"
          >Claim <span class="highlight success">partially accepted</span> by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isAccept" i18n="@@claimAcceptedBy"
          >Claim <span class="highlight success">accepted</span> by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isAuthorize" i18n="@@claimAuthorizedBy"
          >Claim <span class="highlight success">authorized</span> by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isReferAuthorization" i18n="@@claimReferredForAuthorizationBy"
          >Claim was referred for authorization by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <span *ngIf="h.data.isMarkAsPaid" i18n="@@claimMarkedAsPaidBy"
          >Claim was marked as paid by
          <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
            h.user.email
          }}</ps-link></span
        >
        <ng-container
          *ngIf="h.data.isPendingAcceptance"
          i18n="@@allPaymentsSettledAndReceiptsResolved"
          >All payments are settled and receipts are resolved. The claim is now ready to
          <span class="highlight success">accept</span>.</ng-container
        >
        <ng-container *ngIf="h.data.isDecline">
          <span i18n="@@claimDeclinedBy"
            >Claim <span class="highlight danger">declined</span> by
            <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
              h.user.email
            }}</ps-link></span
          >
          <div class="actions">
            <ps-button
              (onClick)="viewDeclineReason(h)"
              btnClass="btn btn-secondary medium"
              i18n="@@viewReasonBtn"
            >
              View Reason
            </ps-button>
          </div>
        </ng-container>
        <ng-container *ngIf="h.data.isCancel">
          <span i18n="@@claimCancelledBy"
            >Claim <span class="highlight danger">cancelled</span> by
            <ps-link [disableLoadOnClick]="true" [href]="'mailto:' + h.user.email">{{
              h.user.email
            }}</ps-link></span
          >
          <div class="actions">
            <ps-button
              (onClick)="viewCancelReason(h)"
              btnClass="btn btn-secondary medium"
              i18n="@@viewReasonBtn"
            >
              View Reason
            </ps-button>
          </div>
        </ng-container>
        <ng-container *ngIf="h.data.isReset">
          <span i18n="@@claimResetBy"
            >Claim <span class="highlight warning">reset</span> by
            <ps-link [href]="'mailto:' + h.user.email">{{ h.user.email }}</ps-link></span
          >
          <div *ngIf="h.data.reason" class="actions">
            <ps-button
              (onClick)="viewClaimResetReason(h)"
              btnClass="btn btn-secondary medium"
              i18n="@@viewReasonBtn"
            >
              View Reason
            </ps-button>
          </div>
          <small *ngIf="!h.data.reason" i18n="@@noReasonGiven">No reason given</small>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="h.isPaymentDeclined">
        <span i18n="@@paymentForDeclinedAt"
          >Payment for
          <span class="font--semiBold">{{ h.data.amount | currency: h.data.currencyCode }}</span>
          declined at <span class="font--semiBold">{{ h.data.merchantName }}</span></span
        >
      </ng-container>

      <ng-container *ngIf="h.isPaymentAuthorized">
        <span i18n="@@paymentForAuthorizedAt"
          >Payment for
          <span class="font--semiBold">{{ h.data.amount | currency: h.data.currencyCode }}</span>
          authorized at <span class="font--semiBold">{{ h.data.merchantName }}</span></span
        >
      </ng-container>

      <ng-container *ngIf="h.isPaymentSettled">
        <span i18n="@@paymentForSettled"
          >Payment for
          <span class="font--semiBold">{{ h.data.amount | currency: h.data.currencyCode }}</span>
          was settled</span
        >
      </ng-container>

      <ng-container *ngIf="h.isPaymentReversed">
        <span i18n="@@paymentReversed"
          >A payment of
          <span class="font--semiBold">{{ h.data.amount | currency: h.data.currencyCode }}</span>
          has been reversed</span
        >
      </ng-container>
    </li>
  </ul>
  <div *ngIf="loading && !history" class="Loader"></div>
</div>
