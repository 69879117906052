import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { PaymentRuleService } from './payment-rule.service';
import { ServiceConfig } from '../common';

export const PaymentRuleServiceConfig = new InjectionToken<ServiceConfig>(
  'PaymentRuleServiceConfig'
);

@NgModule()
export class PaymentRuleServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<PaymentRuleServiceModule> {
    return {
      ngModule: PaymentRuleServiceModule,
      providers: [PaymentRuleService, { provide: PaymentRuleServiceConfig, useValue: config }]
    };
  }
}
