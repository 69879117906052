import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ps-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() isLoading?: boolean;
  @Input() btnClass?: String;
  @Input() glyphicon?: String;
  @Input() icon?: any;
  @Input() iconClass?: any;
  @Input() btnTooltip?: String;
  @Input() type = 'button';
  @Input() disabled = false;
  @Input() loaderPosition = 'before';
  @Input() ariaLabel?: String;

  @Output() onClick = new EventEmitter<any>();

  constructor() {}

  onBtnClick(event: MouseEvent) {
    this.onClick.emit(event);
  }

  get loading() {
    return this.isLoading;
  }
}
