import { camelCase } from 'lodash';

interface StringMap {
  [s: string]: string;
}

/**
 * Generate Google map image URL
 */
export function mapImgUrl(latLng: string, size: string, zoom: number, gmapsApiKey: string): string {
  let url = 'https://maps.googleapis.com/maps/api/staticmap?';
  url += `center=${latLng}`;
  url += `&zoom=${zoom}`;
  url += '&scale=2';
  url += `&size=${size}`;
  url += `&markers=color:red%7C${latLng}`;
  url += `&key=${gmapsApiKey}`;
  return url;
}

/**
 * Takes seconds and returns short duration string.
 * E.g. 1d 3h 25m
 * @param seconds
 */
export function shortDuration(seconds: number): string {
  const minute = 60,
    hour = minute * 60,
    day = hour * 24;
  let d = 0,
    h = 0,
    m = 0;
  const r = [];

  if (seconds >= day) {
    d = Math.floor(seconds / day);
    r.push(`${d}d`);
    seconds -= d * day;
  }

  if (seconds >= hour) {
    h = Math.floor(seconds / hour);
    r.push(`${h}h`);
    seconds -= h * hour;
  }

  if (seconds >= minute) {
    m = Math.floor(seconds / minute);
    r.push(`${m}m`);
    seconds -= m * minute;
  }

  if (r.length === 0) {
    r.push(`${seconds}s`);
  }

  return r.join(' ');
}

/**
 * Recursively camelCase all keys within an object
 * @param input
 */
export function camelCaseObj(input: any): any {
  if (input instanceof Array) {
    return input.map(item => camelCaseObj(item));
  }
  if (!(input instanceof Object)) {
    return input;
  }

  const output = {};
  Object.keys(input).forEach(key => {
    output[camelCase(key)] = camelCaseObj(input[key]);
  });
  return output;
}

export function checkVal(value: any, message: string): any {
  if (typeof value === 'undefined') {
    throw new Error(message);
  }
  return value;
}

export function roundFloat(float: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(float * factor) / factor;
}

export function mapObject(input: any, map: StringMap): any {
  const obj = {};

  Object.keys(input).forEach(key => {
    if (map[key]) {
      obj[map[key]] = input[key];
    }
  });

  return obj;
}

interface TreeNode<T> {
  children: T[];
}

export function flatToTree<T>(items: T[], idKey: string, parentKey: string, childrenKey: string): (T & TreeNode<T>)[] {
  const roots = []; // Root items
  const all = {}; // Key map

  items.forEach(item => (all[item[idKey]] = item));

  // connect children to its parents and split roots
  Object.keys(all).forEach(id => {
    const item = all[id];

    if (item[parentKey] === null) {
      roots.push(item);
    } else if (item[parentKey].id in all) {
      const p = all[item[parentKey].id];
      if (!(childrenKey in p)) {
        p[childrenKey] = [];
      }
      p[childrenKey].push(item);
    }
  });

  return roots;
}

export function capitalize(s: any) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d.getTime());
}

export function percentage(partialValue: number, totalValue: number): number {
  const pct = (100 * partialValue) / totalValue;
  return Math.round(pct);
}

export function objToGql(obj: any, constants?: string[]): string {
  let s = '';
  Object.keys(obj).forEach(key => {
    const val = obj[key];

    // If this is a constant we shouldn't use quotes
    if (!!constants && constants.includes(key)) {
      s += `${key}: ${obj[key]}\n`;
    }
    else if (val !== null && typeof val !== 'undefined') {
      // If this is an object
      if (typeof val === 'object' && !Array.isArray(val)) {
        s += [`${key}: {\n`,
        `  ${objToGql(val, constants)}\n`,
        `}\n`].join('');
      }
      // If this is a primitive type (string, number, bool) or an array
      else {
        s += `${key}: ${JSON.stringify(gqlSanitize(obj[key]))}\n`;
      }
    }
  });
  return s;
}

export function valToGql(val: string | number | boolean): string | number | boolean {
  if (typeof val === 'string') {
    return JSON.stringify(gqlSanitize(val));
  } else {
    return val;
  }
}

export function arrayToGql(arr: any[]): string {
  let s = '[';
  arr.forEach((val, index) => {
    s += typeof val === 'object' ? objToGql(val) : valToGql(val);
    if (index !== arr.length - 1) {
      s += ', ';
    }
  });
  s += ']';
  return s;
}

/**
 * Sanitizes strings so they don't break GQL queries
 * @param str
 */
export function gqlSanitize(str: string) {
  // Replace only if the input is really a string
  if (typeof str === 'string') {
  return str.replace(/\\|\"/g, '');
  }
  return str;
}
