import { Model } from './model';

export class MarketFxRate extends Model {
  pair: string;
  quote: number;

  constructor() {
    super(['pair', 'quote']);
  }
}
