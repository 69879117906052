import { NgModule } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NotFoundComponent } from './not-found/not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { LinkComponentModule } from '@common/components';

@NgModule({
  declarations: [NotFoundComponent, ServerErrorComponent],
  imports: [CommonModule, RouterModule, LinkComponentModule],
  exports: [NotFoundComponent, ServerErrorComponent],
  providers: [Location]
})
export class ErrorPageModule {}
