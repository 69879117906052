import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { environment } from '@environment';
import { SearchResult, MerchantResult, ProductVersionResult } from '@common/models';
import { checkVal, mapImgUrl, flatToTree } from '@common/utils';

@Component({
  selector: 'ps-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  animations: [
    trigger('searchResult', [
      state('in', style({ transform: 'translate3d(0, 0, 0)', opacity: 1 })),
      state('void', style({ transform: 'translate3d(0, 10px, 0)', opacity: 0 })),
      transition('void => *', [animate(300)]),
      transition('* => void', [animate(300)])
    ])
  ]
})
export class SearchResultComponent implements OnInit {
  @Input() result: SearchResult;

  constructor() {}

  ngOnInit() {
    checkVal(this.result, 'SearchResultComponent expected attribute: result');
  }

  mapBgImg(size: string, zoom: number): string {
    if (!(this.result instanceof MerchantResult)) {
      return '';
    }

    const url = mapImgUrl(this.result.merchant.latLng, size, zoom, environment.googleMapsApiKey);
    return `url(${url})`;
  }
}
