import { Component, OnInit, NgZone, ViewEncapsulation, forwardRef } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import { Observable, Subject, Observer, of, interval, from, merge } from 'rxjs';
import { debounceTime, mergeMap, map, debounce } from 'rxjs/operators';
import { LatLngBounds, MapsAPILoader } from '@agm/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { find } from 'lodash';

import { roundFloat } from '@common/utils';
import { MerchantService, CountryService, MerchantCategoryService } from '@common/services';
import { Merchant, MerchantCategory } from '@common/models';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

declare var google: any;

interface MapMarker {
  name: string;
  address: string;
  lat: number;
  lng: number;
  merchant?: Merchant;
  place?: any;
}

interface AutocompleteItem {
  name: string;
  address: string;
  lat: number;
  lng: number;
  merchant?: Merchant;
  place?: any;
}

@Component({
  selector: 'ps-provider-picker',
  templateUrl: './provider-picker.component.html',
  styleUrls: ['./provider-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProviderPickerComponent),
      multi: true
    }
  ]
})
export class ProviderPickerComponent implements OnInit, ControlValueAccessor {
  /**
   * Markers displayed on the map, both existing and new
   */
  mapMarkers: MapMarker[] = [];

  /**
   * FormControl for the search input for both new and
   * existing providers
   */
  searchControl = new FormControl();

  /**
   * Triggered when map is panned or zoomed
   */
  mapBoundsChanged = new Subject();

  /**
   * Updated when subject above is subscribed
   */
  mapBounds: LatLngBounds;

  /**
   * Current tab flag - 'existing' or 'new'
   */
  activeTab = 'existing';

  /**
   * When present the merchant is selected
   */
  selectedMerchant: Merchant;

  /**
   * Initial zoom level (updated when map is zoomed)
   */
  zoomLevel = 1;

  /**
   * Flag to show/hide new provider form
   */
  showProviderForm = false;

  /**
   * The provider form group
   */
  providerForm: FormGroup;

  /**
   * Used to show validation errors only after form submitted
   */
  providerFormSubmitted: boolean;

  /**
   * Selected Google place object when adding new provider
   */
  selectedPlace: any;

  /**
   * To avoid multiple places requests at once
   */
  private loadingPlaces: boolean;

  /**
   * Hash map of visible place ids to avoid duplicating
   * markers when viewing places
   */
  private visiblePlaceIds = {};

  /**
   * Function that will propagate value changes to
   * FormControl
   */
  private propagateChange: any;

  /**
   * The Google places search API has a max search radius of
   * around 50km. The user must therefore zoom in before
   * searching, otherwise they will only get results within
   * 50km of the center of the map bounds.
   */
  private placesMinimumZoom = 8;

  /**
   * Provider category autocomplete data source
   */
  categoryDataSource: Observable<any>;

  /**
   * Array of all currently selected provider categories
   */
  selectedCategories: MerchantCategory[] = [];

  icons = {
    search: faSearch
  };

  constructor(
    private merchantService: MerchantService,
    private mapsAPILoader: MapsAPILoader,
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private merchantCategoryService: MerchantCategoryService
  ) {}

  ngOnInit() {
    this.mapBoundsChanged
      .pipe(debounceTime(500)) // Restrict calls to next handler
      .subscribe((bounds: LatLngBounds) => {
        this.mapBounds = bounds;
        this.updateMarkers();
      });

    this.providerForm = this.createProviderForm();

    // Category autocomplete datasource
    this.categoryDataSource = this.createCategoryDataSource();
  }

  createCategoryDataSource(): Observable<any> {
    return Observable.create((observer: any) => {
      observer.next(this.providerForm.get('category').value);
    }).pipe(
      // Only debounce if there is a query
      debounce(q => interval(!q ? 0 : 500)),
      mergeMap(q =>
        // search provider categories
        this.merchantCategoryService.getAll({ filters: { q: q } }).pipe(
          // Ignore categories that are already in selectedCategories array
          map(categories =>
            categories.filter(el => !!!find(this.selectedCategories, obj => obj.name === el.name))
          )
        )
      )
    );
  }

  /**
   * User selects a category from autocomplete's dropdown
   * @param event selected category item
   */
  selectCategory(event: TypeaheadMatch) {
    const { item: category } = event;
    this.selectedCategories.push(category);
    this.providerForm.get('category').setValue('');
  }

  /**
   * User removes a category from selected categories list by clicking on a "remove" icon
   * @param index selected category's array index
   */
  removeCategory(index: number) {
    this.selectedCategories.splice(index, 1);
  }

  private updateMarkers() {
    if (this.activeTab === 'existing') {
      this.showMerchantMarkers();
    } else if (this.activeTab === 'new') {
      this.showGooglePlaceMarkers();
    }
  }

  /**
   * Populates map markers with Paysure merchants using
   * current map bounds.
   */
  private showMerchantMarkers() {
    if (!this.mapBounds) {
      return;
    }

    const ne = this.mapBounds.getNorthEast();
    const sw = this.mapBounds.getSouthWest();

    this.merchantService
      .getAll({
        filters: {
          geo: `${ne.lat()},${ne.lng()}:${sw.lat()},${sw.lng()}`
        }
      })
      .subscribe(merchants => {
        /**
         * If some merchants are already in the mapMarkers
         * array do not replace them as this triggers any
         * open map markers to close resulting in a negative
         * user experience.
         */
        this.mapMarkers.forEach((m, i) => {
          let duplicate = false;

          // Remove merchants already in markers
          merchants.forEach((me, j) => {
            if (me.id === m.merchant.id) {
              duplicate = true;
              merchants.splice(j, 1);
            }
          });

          // Remove markers not in merchants
          if (!duplicate) {
            this.mapMarkers.splice(i, 1);
          }
        });

        // Push new merchants into markers and transform
        merchants.forEach(m =>
          this.mapMarkers.push({
            name: m.name,
            address: m.address,
            lat: m.lat,
            lng: m.lng,
            merchant: m
          })
        );
      });
  }

  /**
   * Gets and returns place's country short_name
   * @param placeId
   */
  private getPlaceCountryCode(placeId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const dummyEl = document.createElement('div');
      const placesService = new google.maps.places.PlacesService(dummyEl);
      placesService.getDetails(
        { placeId, fields: ['address_components'] },
        (place: any, status: string) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(`Places Service failed to return place with the placeId of ${placeId}.`);
          }
          const { short_name } = place.address_components.find(el => el.types.includes('country'));
          resolve(short_name);
        }
      );
    });
  }

  /**
   * Populates map markers with Google places using current
   * map bounds.
   */
  private showGooglePlaceMarkers() {
    // Don't do anything until map is sufficiently zoomed
    if (this.zoomLevel < this.placesMinimumZoom || this.loadingPlaces) {
      return;
    }

    this.loadingPlaces = true;

    // Ensure the maps api loaded
    this.mapsAPILoader.load().then(() => {
      // Use dummy element rather than passing map
      const dummyEl = document.createElement('div');
      const places = new google.maps.places.PlacesService(dummyEl);

      // Do places search
      places.nearbySearch(
        {
          bounds: new google.maps.LatLngBounds(
            {
              lat: this.mapBounds.getSouthWest().lat(),
              lng: this.mapBounds.getSouthWest().lng()
            },
            {
              lat: this.mapBounds.getNorthEast().lat(),
              lng: this.mapBounds.getNorthEast().lng()
            }
          ),
          types: ['hospital', 'dentist', 'doctor', 'pharmacy', 'physiotherapist']
        },
        (results: any[], status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return;
          }

          const newMarkers: MapMarker[] = results
            .filter(r => !(r['id'] in this.visiblePlaceIds))
            .map(r => {
              this.visiblePlaceIds[r['id']] = true;
              return {
                name: r['name'],
                address: r['vicinity'] || '',
                lat: r.geometry.location.lat(),
                lng: r.geometry.location.lng(),
                place: r
              };
            });

          // Run in Angular zone as we are in a places api callback
          this.zone.run(() => {
            this.mapMarkers = this.mapMarkers.concat(newMarkers);
          });

          this.loadingPlaces = false;
        }
      );
    });
  }

  /**
   * Called by autocomplete input when value changes
   * @param keyword The search term
   */
  searchSource(keyword: string): Observable<AutocompleteItem[]> {
    if (keyword === '') {
      return of([]);
    }

    if (this.activeTab === 'existing') {
      return this.searchSourceMerchants(keyword);
    } else if (this.activeTab === 'new') {
      return this.searchSourcePlaces(keyword);
    }
  }

  /**
   * Searches Paysure merchants
   * @param keyword The search term
   */
  private searchSourceMerchants(keyword: string): Observable<AutocompleteItem[]> {
    return this.merchantService.getAll({ filters: { name_Contains: keyword } }).pipe(
      mergeMap(merchants =>
        of(
          merchants.map(m => {
            return {
              name: m.name,
              address: m.address,
              lat: m.lat,
              lng: m.lng,
              merchant: m
            };
          })
        )
      )
    );
  }

  /**
   * Searches Google Places within the current map bounds
   * @param keyword The search term
   */
  private searchSourcePlaces(keyword: string): Observable<AutocompleteItem[]> {
    // If Google API not defined
    if (!google) {
      return of([]);
    }

    // Use dummy element for places service
    const dummyEl = document.createElement('div');
    const places = new google.maps.places.PlacesService(dummyEl);

    return Observable.create((observer: Observer<AutocompleteItem[]>) => {
      places.nearbySearch(
        {
          name: keyword,
          bounds: new google.maps.LatLngBounds(
            {
              lat: this.mapBounds.getSouthWest().lat(),
              lng: this.mapBounds.getSouthWest().lng()
            },
            {
              lat: this.mapBounds.getNorthEast().lat(),
              lng: this.mapBounds.getNorthEast().lng()
            }
          ),
          // @TODO: Only one type is supported by google
          types: ['hospital', 'dentist', 'doctor', 'pharmacy', 'physiotherapist']
        },
        (results: any[], status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            observer.next([]);
            observer.complete();
            return;
          }

          const items: AutocompleteItem[] = results.map(r => {
            return {
              name: r['name'],
              address: r['vicinity'] || '',
              lat: r.geometry.location.lat(),
              lng: r.geometry.location.lng(),
              place: r
            };
          });

          // Run in Angular zone as we are currently in a
          // Google Maps API callback
          this.zone.run(() => {
            observer.next(items);
            observer.complete();
          });
        }
      );
    });
  }

  /**
   * Formats autocomplete items for display in list
   * @param item
   */
  searchListFormatter(item: AutocompleteItem): string {
    return `<span class="font--semiBold">${item.name}</span><br>${item.address}`;
  }

  /**
   * Formats autocomplete items for display in search field
   * @param item
   */
  searchValueFormatter(item: AutocompleteItem): string {
    return item.name;
  }

  /**
   * When an autocomplete value is selected from the list
   * @param item
   */
  searchValueChanged(item: AutocompleteItem) {
    if (!item) {
      return;
    }
    if (item.merchant) {
      this.selectProvider(item.merchant);
    } else if (item.place) {
      this.openProviderForm(item.place);
    }
  }

  /**
   * When the 'select' button is pressed within the map
   * marker info window
   * @param marker
   */
  selectMarker(marker: MapMarker) {
    if (marker.merchant) {
      this.selectProvider(marker.merchant);
    } else if (marker.place) {
      this.openProviderForm(marker.place);
    }
  }

  /**
   * Selects a provider, collapses the component and
   * propagates change to FormControl
   * @param merchant The merchant object
   */
  private selectProvider(merchant: Merchant) {
    this.selectedMerchant = merchant;
    this.propagateChange(merchant.id);
  }

  /**
   * Hides the map and shows the new provider form,
   * populating it with data using the provided Google
   * Place object
   * @param place
   */
  private openProviderForm(place: any) {
    if (!place) {
      return;
    }

    this.providerForm.setValue({
      name: place['name'],
      address: place['vicinity'] || '',
      detailedInfo: '',
      category: ''
    });

    this.showProviderForm = true;
    this.selectedPlace = place;
  }

  /**
   * Creates a new Paysure merchant from provider form data
   */
  async submitProviderForm() {
    this.providerFormSubmitted = true;
    if (this.providerForm.invalid) {
      return;
    }

    const {
      geometry: { location },
      place_id
    } = this.selectedPlace;

    // API required geo coords to be max 6 decimal places
    const lat = roundFloat(location.lat(), 6),
      lng = roundFloat(location.lng(), 6);

    from(this.getPlaceCountryCode(place_id))
      .pipe(
        mergeMap(cc => this.countryService.getAll({ filters: { code: cc } })),
        map(countries => countries[0])
      )
      .subscribe(({ id: countryId }) => {
        // create a new provider
        this.merchantService
          .create({
            categoriesIds: this.selectedCategories.map(el => el.id),
            countryId: countryId,
            name: this.providerForm.get('name').value,
            address: this.providerForm.get('address').value,
            gpsLatitude: lat,
            gpsLongitude: lng,
            detailedInfo: this.providerForm.get('detailedInfo').value,
            isVerifiedByAdmin: true
          })
          .subscribe(merchant => {
            this.selectProvider(merchant);
          });
      });
  }

  /**
   * Closes the provider form, resets state and shows map
   */
  closeProviderForm() {
    this.providerForm.reset();
    this.searchControl.reset();
    this.showProviderForm = false;
    this.providerFormSubmitted = false;
    this.selectedPlace = null;
  }

  /**
   * Triggered when user clicks 'change' once merchant has
   * already been selected.
   */
  unselectProvider() {
    this.selectedMerchant = null;
    this.changeTab('existing');
    this.propagateChange(null);
  }

  /**
   * Changes current tab and resets state
   * @param tabName
   */
  changeTab(tabName: string) {
    this.activeTab = tabName;
    this.mapMarkers = [];
    this.visiblePlaceIds = {};
    this.searchControl.setValue('');
    this.updateMarkers();

    if (this.showProviderForm) {
      this.closeProviderForm();
    }
  }

  /**
   * When map zoom is changed
   * @param zoom
   */
  zoomChanged(zoom: number) {
    if (this.activeTab === 'new' && zoom < this.placesMinimumZoom) {
      this.visiblePlaceIds = {};
      this.mapMarkers = [];
    }
    this.zoomLevel = zoom;
  }

  /**
   * If user must zoom in more before being able to search
   * Google Places
   */
  get mustZoomIn(): boolean {
    return this.activeTab === 'new' && this.zoomLevel < this.placesMinimumZoom;
  }

  /**
   * Number of times a user needs to zoom in before being
   * able to search Google Places
   */
  get requiredZoom(): number {
    return this.placesMinimumZoom - this.zoomLevel;
  }

  /**
   * Creates the provider form group
   */
  private createProviderForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      detailedInfo: '',
      category: ['']
    });
  }

  // Required ControlValueAccessor methods
  writeValue(merchantId: any) {
    if (!merchantId || isNaN(merchantId)) {
      return;
    }

    this.merchantService.getOne(merchantId).subscribe(merchant => {
      this.selectProvider(merchant);
    });
  }
  registerOnTouched() {}

  /**
   * Register ControlValueAccessor on change function so we
   * can propagate changes in value (merchant id) to parent
   * FormControl
   */
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
}
