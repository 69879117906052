import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { PaymentRulesOverrideService } from './payment-rules-override.service';
import { ServiceConfig } from '../common';

export const PaymentRulesOverrideServiceConfig = new InjectionToken<ServiceConfig>(
  'PaymentRulesOverrideServiceConfig'
);

@NgModule()
export class PaymentRulesOverrideServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<PaymentRulesOverrideServiceModule> {
    return {
      ngModule: PaymentRulesOverrideServiceModule,
      providers: [
        PaymentRulesOverrideService,
        { provide: PaymentRulesOverrideServiceConfig, useValue: config }
      ]
    };
  }
}
