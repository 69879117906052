<ps-modal [heading]="title" #modal>
  <div class="modal-body">
    <p class="Note__text" [innerHTML]="text | breakLines"></p>
    <p class="text-right" i18n="@@claimNoteDescription">
      Written on <span class="font--semiBold">{{ date | localDate }}</span
      > by
      <a [href]="'mailto:' + userEmail">{{ userEmail }} </a>
    </p>
  </div>
  <div class="modal-footer">
    <ps-button (onClick)="modal.close()" btnClass="btn btn-primary" i18n="@@okBtn">OK</ps-button>
  </div>
</ps-modal>
