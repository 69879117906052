import { NgModule } from '@angular/core';
import { ClaimStateLabelComponent } from './claim-state-label.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [TooltipModule],
  declarations: [ClaimStateLabelComponent],
  exports: [ClaimStateLabelComponent]
})
export class ClaimStateLabelComponentModule {}
