<nav class="Navbar d-flex justify-content-between">
  <button class="Navbar__toggle btn-reset" (click)="onMenuToggle.emit()">
    <fa-icon [icon]="icons.faBars"></fa-icon>
  </button>
  <div *ngIf="showSearch" class="SearchBar d-flex align-items-center" (click)="openSearch()">
    <fa-icon [icon]="icons.faSearch" class="SearchBar__icon"></fa-icon>
    <div class="SearchBar__placeholder align-self-center">
      <span class="d-none d-sm-block">Search products, policies, claims, and more</span>
      <span class="d-sm-none">Search...</span>
    </div>
  </div>
  <a class="Navbar__logo" *ngIf="showLogo" routerLink="/">
    <img src="/assets/img/logo-transparent.svg" />
  </a>
</nav>
