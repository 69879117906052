import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ButtonComponent } from './button.component';

@NgModule({
  imports: [CommonModule, TooltipModule.forRoot(), FontAwesomeModule],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class ButtonComponentModule {}
