import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Breadcrumb, Breadcrumbizable } from '../../interfaces';

@Injectable({ providedIn: 'root' })
export class BreadcrumbProvider {
  private currentBreadcrumbs: Breadcrumb[] = [];
  private breadcrumbsSource = new BehaviorSubject<Breadcrumb[]>([]);

  breadcrumbs: Observable<Breadcrumb[]> = this.breadcrumbsSource.asObservable();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    // Update breadcrumbs on route change
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateBreadcrumbs(); // Keep 'this' ref
    });

    // Update breadcrumbs on init
    this.updateBreadcrumbs();
  }

  private getCurrentRouteData(): any {
    let currentRoute = this.activatedRoute.root;

    // Traverse down to the current (child) route
    while (currentRoute.children[0] !== undefined) {
      currentRoute = currentRoute.children[0];
    }

    // If no route snapshot (e.g. a redirect)
    if (!currentRoute.hasOwnProperty('snapshot')) {
      return {};
    }

    return currentRoute.snapshot.data;
  }

  private updateBreadcrumbs(): void {
    const data = this.getCurrentRouteData();

    // Clear existing breadcrumbs
    this.clear();

    // If no breadcrumbs to show
    if (data.breadcrumbs === undefined || !(data.breadcrumbs instanceof Array)) {
      return;
    }

    const breadcrumbs = data.breadcrumbs as string[];
    breadcrumbs.forEach(b => {
      if (data.hasOwnProperty(b)) {
        const model = <Breadcrumbizable>data[b];
        this.push(model.breadcrumbize());
      }
    });
  }

  push(breadcrumb: Breadcrumb): void {
    this.currentBreadcrumbs.push(breadcrumb);
    this.breadcrumbsSource.next(this.currentBreadcrumbs);
  }

  pop(): Breadcrumb {
    const breadcrumb = this.currentBreadcrumbs.pop();
    this.breadcrumbsSource.next(this.currentBreadcrumbs);
    return breadcrumb;
  }

  clear(): void {
    this.currentBreadcrumbs = [];
    this.breadcrumbsSource.next(this.currentBreadcrumbs);
  }
}
