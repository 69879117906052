import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { CurrencyService } from './currency.service';

export const CurrencyServiceConfig = new InjectionToken<ServiceConfig>('CurrencyServiceConfig');

@NgModule()
export class CurrencyServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<CurrencyServiceModule> {
    return {
      ngModule: CurrencyServiceModule,
      providers: [CurrencyService, { provide: CurrencyServiceConfig, useValue: config }]
    };
  }
}
