import { Component, OnInit, Input, HostListener, Renderer2, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { ModalProvider } from '@common/providers';

@Component({
  selector: 'ps-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, AfterViewInit {
  // @TODO: Add some transition animation
  private transitionDuration = 0;
  private startedClose = false;

  @Input() heading: string;
  @Input() size: string;

  visible = false;

  constructor(
    private modalService: ModalProvider,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngAfterViewInit() {
    // Query HTML elements of all modals and backdrops
    const modals = document.querySelectorAll('.modal.show');
    const backdrops = document.querySelectorAll('.modal-backdrop.fade');
    // Z-index of the first modal is set to 1050
    let zIndex = 1050;
    // If there's more than one modal shown
    if (modals.length > 1) {
      // Increase z-index for each subsequent modal-backdrop pair so that
      // they overlay the previous pair
      zIndex += 10;
      for (let i = 1; i < modals.length; i++) {
        (backdrops[i] as HTMLElement).style.zIndex = zIndex.toString();
        (modals[i] as HTMLElement).style.zIndex = (zIndex + 1).toString();
      }
    }
  }

  ngOnInit() {
    if (this.size && ['extra-large', 'large', 'medium', 'small'].indexOf(this.size) === -1) {
      throw new Error(`Modal component size '${this.size}' is not supported`);
    }

    // Wait for next tick to trigger CSS animation
    setTimeout(() => {
      this.visible = true;
      this.renderer.addClass(document.body, 'modal-open');
    }, 100);

    // Close if route changes
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => this.close());
  }

  @HostListener('document:keydown.escape')
  escapeKeyHandler() {
    this.close();
  }

  close(data?: any) {
    if (this.startedClose) {
      return;
    }

    this.startedClose = true;
    this.visible = false;
    this.renderer.removeClass(document.body, 'modal-open');
    setTimeout(() => this.modalService.pop(data ? data : null), this.transitionDuration);
  }
}
