import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { SupportingDocumentService } from './supporting-document.service';

export const SupportingDocumentServiceConfig = new InjectionToken<ServiceConfig>(
  'SupportingDocumentServiceConfig'
);

@NgModule()
export class SupportingDocumentServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<SupportingDocumentServiceModule> {
    return {
      ngModule: SupportingDocumentServiceModule,
      providers: [
        SupportingDocumentService,
        { provide: SupportingDocumentServiceConfig, useValue: config }
      ]
    };
  }
}
