import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { ReceiptService } from './receipt.service';

export const ReceiptServiceConfig = new InjectionToken<ServiceConfig>('ReceiptServiceConfig');

@NgModule()
export class ReceiptServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<ReceiptServiceModule> {
    return {
      ngModule: ReceiptServiceModule,
      providers: [ReceiptService, { provide: ReceiptServiceConfig, useValue: config }]
    };
  }
}
