import { environment } from '@environment';

// Angular
import { NgModule, NO_ERRORS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Vendor
import { SimpleNotificationsModule } from 'angular2-notifications';
import * as Sentry from '@sentry/browser';
import { CookieService } from 'ngx-cookie-service';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';

// Modules
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { CommonModule } from '@angular/common';
import {
  AlertComponentModule,
  LinkComponentModule,
  LoginComponentModule
} from '@common/components';
import {
  NotificationManagerModule,
  AuthProviderModule,
  SettingsProviderModule
} from '@common/providers';
import {
  UserServiceModule,
  StatisticsServiceModule,
  ConfigValueServiceModule,
  NotificationServiceModule,
  ClaimServiceModule,
  ProductServiceModule,
  PolicyServiceModule,
  ProductVersionServiceModule,
  BeneficiaryServiceModule,
  ReceiptServiceModule,
  RulesSetServiceModule,
  SearchServiceModule,
  MerchantServiceModule,
  IcdCodeServiceModule,
  CardServiceModule,
  CountryServiceModule,
  MerchantCategoryServiceModule,
  SupportingDocumentServiceModule,
  MarketFxRateServiceModule,
  CurrencyServiceModule,
  PaymentRuleServiceModule,
  PaymentRulesOverrideServiceModule
} from '@common/services';
import { SessionInterceptor } from './core/interceptors/session.interceptor';
import { PaysureErrorHandler } from './core/services/paysure-error-handler';
import { serviceConfig } from './config/service';
import { config } from './config/config';
import { GraphQLModule } from './graphql.module';
import { ErrorPageModule } from '@common/error-page/error-page.module';

// Init Sentry
if (environment.production) {
  Sentry.init({ dsn: environment.sentryDsn, release: environment.sentryRelease });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot(),
    ErrorPageModule,
    AlertComponentModule,
    DashboardModule,
    LoginComponentModule,
    LinkComponentModule.forRoot(),
    // Services - temporary
    NotificationManagerModule.forRoot({ pollInterval: config.notificationPollInterval }),
    AuthProviderModule.forRoot(serviceConfig),
    UserServiceModule.forRoot(serviceConfig),
    StatisticsServiceModule.forRoot(serviceConfig),
    NotificationServiceModule.forRoot(serviceConfig),
    SettingsProviderModule.forRoot({ localKey: config.localKeySettings }),
    ConfigValueServiceModule.forRoot(serviceConfig),
    ClaimServiceModule.forRoot(serviceConfig),
    ProductServiceModule.forRoot(serviceConfig),
    PolicyServiceModule.forRoot(serviceConfig),
    ProductVersionServiceModule.forRoot(serviceConfig),
    BeneficiaryServiceModule.forRoot(serviceConfig),
    ReceiptServiceModule.forRoot(serviceConfig),
    RulesSetServiceModule.forRoot(serviceConfig),
    SearchServiceModule.forRoot(serviceConfig),
    MerchantServiceModule.forRoot(serviceConfig),
    IcdCodeServiceModule.forRoot(serviceConfig),
    CardServiceModule.forRoot(serviceConfig),
    CountryServiceModule.forRoot(serviceConfig),
    MerchantCategoryServiceModule.forRoot(serviceConfig),
    SupportingDocumentServiceModule.forRoot(serviceConfig),
    MarketFxRateServiceModule.forRoot(serviceConfig),
    CurrencyServiceModule.forRoot(serviceConfig),
    PaymentRulesOverrideServiceModule.forRoot(serviceConfig),
    PaymentRuleServiceModule.forRoot(serviceConfig),
    GraphQLModule
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    { provide: ErrorHandler, useClass: PaysureErrorHandler }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
