import gql from 'graphql-tag';

export const PaymentFrequencyControlFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentFrequencyControlSpecificationType {
      count
      timeWindowMinutes
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentFrequencyControlSpecificationType {
      count
      timeWindowMinutes
    }
  `
};
