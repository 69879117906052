export enum ClaimHistoryAction {
  Created = 'CREATED',
  Updated = 'UPDATED',
  MerchantChanged = 'MERCHANT_CHANGED',
  BenefitChanged = 'BENEFIT_CHANGED',
  NewDecision = 'NEW_DECISION',
  NewNote = 'NEW_NOTE',
  ReceiptUploaded = 'RECEIPT_UPLOADED',
  ReceiptNewDecision = 'RECEIPT_NEW_DECISION',
  SupportingDocumentUploaded = 'SUPPORTING_DOCUMENT_UPLOADED',
  SupportingDocumentNewDecision = 'SUPPORTING_DOCUMENT_NEW_DECISION',
  PaymentDeclined = 'PAYMENT_DECLINED',
  PaymentAuthorized = 'PAYMENT_AUTHORIZED',
  PaymentSettled = 'PAYMENT_SETTLED',
  PaymentIncremented = 'PAYMENT_INCREMENTED'
}
