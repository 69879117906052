import { Model } from './model';
import { InsurerFundsAmount } from './insurer-funds-amount.model';

export interface StatisticsClaimsCount {
  thisWeek: number;
  today: number;
  thisMonth: number;
  allTime: number;
}

export interface StatisticsReviewDuration {
  durationWorkHours: number;
  duration: number;
  target: number;
}

export interface StatisticsReviewDurations {
  preAuthorization: StatisticsReviewDuration;
  receipt: StatisticsReviewDuration;
}

export class Statistics extends Model {
  claimsAwaitingActionCount: {
    pendingAuthorization: number;
    pendingReview: number;
    stagnant: number;
  };
  claimsCount: {
    preAuthorizations: StatisticsClaimsCount;
    all: StatisticsClaimsCount;
    instant: StatisticsClaimsCount;
  };
  averageReviewDuration: {
    allTime: StatisticsReviewDurations;
    lastWeek: StatisticsReviewDurations;
    lastMonth: StatisticsReviewDurations;
    today: StatisticsReviewDurations;
  };
  timeSaved: number;
  insurerFundsAmounts: InsurerFundsAmount[];

  constructor() {
    super([
      'claimsAwaitingActionCount',
      'claimsCount',
      'averageReviewDuration',
      'timeSaved',
      { key: 'insurerFundsAmounts', useModel: InsurerFundsAmount }
    ]);
  }
}
