import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DashboardPageComponent } from './pages/dashboard/dashboard-page.component';
import { DashboardComponentModule } from '@common/components';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, DashboardComponentModule],
  declarations: [DashboardPageComponent]
})
export class DashboardModule {}
