<ps-modal [heading]="exclusion.name" class="BenefitExclusionDialog" #modal>
  <div class="modal-body">
    <div class="BenefitExclusionDialog__description">
      <h5>Description</h5>
      <p>{{ exclusion.description }}</p>
    </div>
    <div
      *ngIf="exclusion.icdCodes.length"
      class="BenefitExclusionDialog__codes BenefitExclusionDialog__codes--icds"
    >
      <h5>Excluded ICD Codes</h5>
      <ul>
        <li *ngFor="let icd of exclusion.icdCodes">
          <span class="BenefitExclusionDialog__code">{{ icd.code }}</span>
          <span>{{ icd.name }}</span>
        </li>
      </ul>
    </div>
    <div
      *ngIf="exclusion.cptCodes.length"
      class="BenefitExclusionDialog__codes BenefitExclusionDialog__codes--cpts"
    >
      <h5>Excluded CPT Codes</h5>
      <ul>
        <li *ngFor="let cpt of exclusion.cptCodes">
          <span class="BenefitExclusionDialog__code">{{ cpt.code }}</span>
          <span>{{ cpt.name }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <ps-button (click)="modal.close()" btnClass="btn btn-primary">OK</ps-button>
  </div>
</ps-modal>
