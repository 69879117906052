import { Injectable } from '@angular/core';
import { ProductVersion } from '../../models';
import { GQLService } from '../common';
import { Apollo } from 'apollo-angular';
import { ProductVersionFragments } from '@common/fragments';

@Injectable({ providedIn: 'root' })
export class ProductVersionService extends GQLService<ProductVersion> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: ProductVersion,
      singular: 'productVersion',
      plural: 'productVersions',
      fragments: ProductVersionFragments
    });
  }
}
