import { Breadcrumbizable, Breadcrumb } from '../interfaces';
import { ProductVersion } from './product-version.model';
import { Model } from './model';
import { Currency } from './currency.model';
import { Country } from './country.model';

class ProductInsurer extends Model {
  slug: string;
  name: string;
  country: Country;

  constructor() {
    super(['slug', 'name', { key: 'country', useModel: Country }]);
  }
}

export class Product extends Model implements Breadcrumbizable {
  id: number;
  name: string;
  description: string;
  currency: Currency;
  versionIds: number[];
  versions?: ProductVersion[];

  constructor() {
    super([
      'id',
      'name',
      'description',
      { key: 'currency', useModel: Currency },
      'versionIds',
      { key: 'versions', useModel: ProductVersion }
    ]);
  }

  breadcrumbize(): Breadcrumb {
    return {
      title: 'Product',
      routerLink: `/dashboard/products/${this.id}`,
      items: [
        {
          name: 'Name',
          value: this.name
        },
        {
          name: 'Description',
          value: `${this.description.substr(0, 60)}...`
        }
      ]
    };
  }
}
