import gql from 'graphql-tag';
import { CurrencyFragments } from './currency.fragments';
import { UserFragments } from './user.fragments';

export const CardFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on CardType {
      id
      rulesCurrentStates {
        policyId
        ruleType
        triggered
      }
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on CardType {
      id
      proxyId
      actions {
        action
        created
        source
        comment
        user {
          ...userSlimFields
        }
      }
      lastDigits
      nameOnCard
      expiration
      ready
      currency {
        ...currencySlimFields
      }
      blockedPendingClaim
      isFrozen
      isBlocked
      isDeactivated
      isCancelled
      isReplacementOrdered
      isIssued
      cardType
      rulesCurrentStates {
        policyId
        ruleType
        triggered
      }
    }
    ${UserFragments.slim('userSlimFields')}
    ${CurrencyFragments.slim('currencySlimFields')}
  `
};
