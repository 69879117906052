import { Model } from '../../models';
import { Apollo } from 'apollo-angular';
import { Observable, of, BehaviorSubject } from 'rxjs';
import gql from 'graphql-tag';
import { take, map } from 'rxjs/operators';
import { upperFirst } from 'lodash';
import { objToGql, gqlSanitize } from '@common/utils';

interface ResultsParams {
  ordering?: string;
  page?: number;
  pageSize?: number;
}

type GQLFragmentGen = (fragmentName: string) => any;

export interface GQLServiceFragments {
  slim: GQLFragmentGen;
  fat: GQLFragmentGen;
}

// TODO: Should put this in central config
const pageSize = 10;

export abstract class GQLService<T extends Model> {
  protected apollo: Apollo;

  constructor(
    private config: {
      apollo: Apollo;
      model: new () => T;
      singular: string;
      plural: string;
      fragments: GQLServiceFragments;
      constants?: string[];
    }
  ) {
    this.apollo = config.apollo;
  }

  private _totalPages: BehaviorSubject<number> = new BehaviorSubject(0);
  private _totalItems: BehaviorSubject<number> = new BehaviorSubject(0);
  private _currentPageItems: BehaviorSubject<number> = new BehaviorSubject(0);

  protected set setTotalPages(val: number) {
    this._totalPages.next(val);
  }

  public get totalPages(): BehaviorSubject<number> {
    return this._totalPages;
  }

  protected set setTotalItems(val: number) {
    this._totalItems.next(val);
  }

  public get totalItems(): BehaviorSubject<number> {
    return this._totalItems;
  }

  protected set setCurrentPageItems(val: number) {
    this._currentPageItems.next(val);
  }

  public get currentPageItems(): BehaviorSubject<number> {
    return this._currentPageItems;
  }

  private makeParams(objVal: any): string {
    function serialize(obj: any) {
      return Object.keys(obj)
        .map(key => {
          const isString = typeof obj[key] === 'string';
          const isObj = typeof obj[key] === 'object';
          const isArray = Array.isArray(obj[key]);
          let val = '';

          if (isString) {
            if (key === 'page') {
              val = obj[key];
            } else {
              val = `"${gqlSanitize(obj[key])}"`;
            }
          } else if (isArray) {
            val = `[${obj[key].map(el => `"${gqlSanitize(el)}"`).join(', ')}]`;
          } else if (isObj) {
            val = `{${serialize(obj[key])}}`;
          } else {
            val = obj[key];
          }
          return `${key}: ${val}`;
        })
        .join(', ');
    }

    if (!objVal || Object.keys(objVal).length === 0) {
      return '';
    }

    let s = '(';
    s += serialize(objVal);
    s += ')';

    return s;
  }
  protected pageCount(itemCount: number) {
    return Math.ceil(itemCount / pageSize);
  }

  getAll(options?: {
    filters?: any;
    resultsParams?: ResultsParams;
    include?: string[];
    noPaging?: boolean;
    fragment?: GQLFragmentGen;
  }): Observable<T[]> {
    if (!options) {
      options = {};
    }

    if (!options.resultsParams) {
      options.resultsParams = {};
    }

    if (!options.noPaging) {
      options.resultsParams['pageSize'] = pageSize;
    }

    return this.apollo
      .watchQuery({
        query: gql`
        {
          ${this.config.plural}${this.makeParams(options.filters)} {
            totalCount
            results${this.makeParams(options.resultsParams)} {
              ...${this.config.singular}SlimFields
            }
          }
        }
        ${
          options.fragment
            ? options.fragment(`${this.config.singular}SlimFields`)
            : this.config.fragments.slim(`${this.config.singular}SlimFields`)
        }
      `,
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        take(1),
        map((res: any) => {
          const collection = res.data[this.config.plural];

          this.setTotalPages = this.pageCount(collection.totalCount);
          this.setTotalItems = collection.totalCount;
          this.setCurrentPageItems = collection.results.length;

          return collection.results.map((data: any) => {
            return new this.config.model().deserialize(data);
          });
        })
      );
  }

  // TODO: Keeps interface consistant for the moment,  but going
  // forward we should use `getAll` with the `id_In` filter.
  getSome(
    ids: (number | string)[],
    options?: {
      filters?: any;
      resultsParams?: ResultsParams;
      include?: string[];
      noPaging?: boolean;
      fragment?: GQLFragmentGen;
      fat?: boolean;
    }
  ): Observable<T[]> {
    // If no ids don't bother with request
    if (ids.length === 0) {
      return of([]);
    }

    if (!options) {
      options = {};
    }

    if (!options.filters) {
      options.filters = {};
    }

    options.filters['id_In'] = ids;

    return this.apollo
      .watchQuery({
        query: gql`
        {
          ${this.config.plural}${this.makeParams(options.filters)} {
            results {
              ...${this.config.singular}Fields
            }
          }
        }
        ${
          options.fat
            ? this.config.fragments.fat(`${this.config.singular}Fields`)
            : this.config.fragments.slim(`${this.config.singular}Fields`)
        }
      `,
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        take(1),
        map((res: any) => {
          return res.data[this.config.plural].results.map((data: any) => {
            return new this.config.model().deserialize(data);
          });
        })
      );
  }

  /**
   * Get a single object by ID
   * @param id ID of the object
   * @param slim If true, requests a slim object
   */
  getOne(
    id: number,
    options?: {
      fragment?: GQLFragmentGen;
    }
  ): Observable<T> {
    if (!options) {
      options = {};
    }
    return this.apollo
      .watchQuery({
        query: gql`
        {
          ${this.config.singular}(id: ${id}) {
            ...${this.config.singular}FatFields
          }
        }
        ${
          options.fragment
            ? options.fragment(`${this.config.singular}FatFields`)
            : this.config.fragments.fat(`${this.config.singular}FatFields`)
        }
      `,
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        take(1),
        map((res: any) => {
          if (!res.data[this.config.singular]) {
            return null;
          }
          return new this.config.model().deserialize(res.data[this.config.singular]);
        })
      );
  }

  create(
    body: any,
    file?: any,
    options?: {
      fragment?: GQLFragmentGen;
    }
  ): Observable<T> {
    if (!options) {
      options = {};
    }

    const createKey = `${this.config.singular}Create`;
    const newKey = `new${upperFirst(this.config.singular)}`;

    const variables: any = {};
    if (file) {
      variables.file = file;
    }

    const context: any = {};
    if (file) {
      context.useMultipart = true;
    }

    const returnKey = this.config.singular.toLowerCase();

    return this.apollo
      .mutate({
        mutation: gql`
        mutation${file ? '($file: Upload!)' : ''} {
          ${createKey}(${newKey}: {
            ${objToGql(body, this.config.constants)}
            ${file ? 'file: $file' : ''}
          }) {
            ok
            ${returnKey} {
              ...${this.config.singular}FatFields
            }
          }
        }
        ${
          options.fragment
            ? options.fragment(`${this.config.singular}FatFields`)
            : this.config.fragments.fat(`${this.config.singular}FatFields`)
        }
      `,
        variables,
        context
      })
      .pipe(
        map((res: any) => {
          const data = res.data[createKey];
          if (data.ok) {
            return new this.config.model().deserialize(data[returnKey]);
          } else {
            throw new Error(`Couldn't create ${newKey}`);
          }
        })
      );
  }

  delete(id: number): Observable<any> {
    const deleteKey = `${this.config.singular}Delete`;

    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          ${deleteKey}(id: ${id}) {
            ok
          }
        }
      `
      })
      .pipe(
        map((res: any) => {
          const data = res.data[deleteKey];
          if (data.ok) {
            return of(true);
          } else {
            throw new Error(`Couldn't delete ${deleteKey}`);
          }
        })
      );
  }

  update(id: number, body: any): Observable<T> {
    const updateKey = `${this.config.singular}Update`;
    const newKey = `new${upperFirst(this.config.singular)}`;

    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          ${updateKey}(${newKey}: {
            id: ${id}
            ${objToGql(body, this.config.constants)}
          }) {
            ok
            ${this.config.singular} {
              ...${this.config.singular}FatFields
            }
          }
        }
        ${this.config.fragments.fat(`${this.config.singular}FatFields`)}
      `
      })
      .pipe(
        map((res: any) => {
          const data = res.data[updateKey];
          if (data.ok) {
            return new this.config.model().deserialize(data[this.config.singular]);
          } else {
            throw new Error(`Couldn't update ${this.config.singular} #${id}`);
          }
        })
      );
  }
}
