import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { PaymentControlsModalComponent } from './payment-controls-modal.component';
import { ModalComponentModule } from '../modal';
import { ButtonComponentModule } from '../button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    ModalComponentModule,
    ButtonComponentModule,
    FontAwesomeModule
  ],
  declarations: [PaymentControlsModalComponent],
  entryComponents: [PaymentControlsModalComponent],
  exports: [PaymentControlsModalComponent]
})
export class PaymentControlsModalComponentModule {}
