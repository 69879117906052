import { Model } from './model';

export class CptCodes extends Model {
  claimCptCodeId: number;
  cptCodeId: number;
  default: boolean;
  claimId: number;

  constructor() {
    super(['claim_cpt_code_id', 'cpt_code_id', 'default', 'claim_id']);
  }
}
