import { Model } from './model';
import { IcdCode } from './icd-code.model';

export class ReceiptItem extends Model {
  id: number;
  title: string;
  amount: number;
  isAccepted: boolean;
  icd: IcdCode;

  constructor() {
    super([
      'id',
      'title',
      'amount',
      'isAccepted',
      { key: 'icd', useModel: IcdCode }
    ]);
  }
}
