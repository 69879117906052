import { Pipe, PipeTransform } from '@angular/core';
import * as moment_ from 'moment-timezone';
const moment = moment_;

@Pipe({ name: 'timeAgo' })
export class TimeAgoPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    const then = moment(value),
      now = moment();
    const duration = moment.duration(then.diff(now));

    let humanized = duration.humanize(true);
    if (humanized === 'a few seconds ago') {
      humanized = 'just now';
    }

    return humanized;
  }
}
