import { Model } from './model';
import { Permission } from '@common/enums';

class UserDetails extends Model {
  claimsAuthority: number;

  constructor() {
    super(['claimsAuthority']);
  }
}

export class User extends Model {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  passwordExpiration?: string;
  details: UserDetails;
  groups: string[];
  permissions: string[];
  lastActivity: string;
  lastLogin: string;
  isSuperuser: boolean;
  providerEmployee: {
    provider: {
      id: string;
      merchant: { id: string; name: string };
    };
  };

  constructor() {
    super([
      'id',
      'firstName',
      'lastName',
      'email',
      'passwordExpiration',
      { key: 'details', useModel: UserDetails },
      'groups',
      'permissions',
      'lastActivity',
      'lastLogin',
      'isSuperuser',
      'providerEmployee'
    ]);
  }

  hasClaimsAuthority(value: number): boolean {
    if (this.details.claimsAuthority === null || this.details.claimsAuthority < 0 || isNaN(value)) {
      return true;
    }

    return value <= this.details.claimsAuthority;
  }

  get claimsAuthority(): number {
    const ca = this.details.claimsAuthority;
    if (ca !== null && !isNaN(ca) && ca > -1) {
      return ca;
    } else {
      return null;
    }
  }

  hasPermission(permission: string): boolean {
    return this.isSuperuser || this.permissions.indexOf(permission) !== -1;
  }

  get fullName(): string {
    if (!this.firstName) return '';

    let fullName = this.firstName;
    if (this.lastName) {
      fullName += ` ${this.lastName}`;
    }

    return fullName;
  }

  get canAccessCustomerData(): boolean {
    return this.hasPermission(Permission.CanAccessCustomerService);
  }

  get merchantId(): string {
    if (!this.providerEmployee) {
      return null;
    }

    return this.providerEmployee.provider.merchant.id || this.providerEmployee.provider.id;
  }

  get merchantName(): string {
    if (!this.providerEmployee) {
      return null;
    }

    return this.providerEmployee.provider.merchant.name;
  }
}
