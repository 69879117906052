import { Component, OnInit } from '@angular/core';
import {
  Location,
  LocationStrategy,
  HashLocationStrategy
} from '@angular/common';

@Component({
  selector: 'app-server-error',
  providers: [
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit() {}

  goBack() {
    this.location.back();
  }
}
