import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';
import { Observable, throwError, empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthProvider } from '@common/providers';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private notifications: NotificationsService,
    private cookieService: CookieService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get auth service on the fly to avoid cyclic dependency err
    const authProvider = this.injector.get(AuthProvider);

    // If we are not logged in then don't run the session interceptor
    if (!authProvider.loggedIn) {
      return next.handle(
        req.clone({
          withCredentials: true
        })
      );
    }

    // Read CSRF token from the cookie
    const csrfToken = this.cookieService.get('csrftoken');

    // Reference headers
    // We want to append to or overwrite headers, not start afresh.
    let headers = req.headers;

    // Append CSRF token header
    headers = headers.append('X-CSRFToken', csrfToken);

    // Clone request
    const authReq = req.clone({
      withCredentials: true,
      headers
    });

    // Intercept responses
    return next.handle(authReq).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          // Expired token
          if (err.status === 401 || err.status === 403) {
            authProvider.logout();
            this.router.navigate(['login']);
            this.notifications.info(
              'Logged Out',
              err.error.message || 'Your session has expired or is invalid. Please login again.'
            );
            // Don't throw error as it has just been handled
            return empty();
          }
        }

        // Anything else, throw the error so it is reported
        return throwError(err);
      })
    );
  }
}
