import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';

import { Claim, ClaimHistory, IcdCode, ClaimIcdCode } from '../../models';
import { ClaimDecision } from '../../interfaces';
import { GQLService } from '../common';
import { Apollo } from 'apollo-angular';
import { ClaimFragments, ClaimHistoryFragments } from '@common/fragments';
import gql from 'graphql-tag';
import { take, map, mergeMap } from 'rxjs/operators';
import { ClaimIcdCodeFragments } from '@common/fragments/claim-icd-code.fragments';
import { objToGql, valToGql,  } from '@common/utils';
import { MerchantService } from '../merchant.service';
import { uniq } from 'lodash';

@Injectable({ providedIn: 'root' })
export class ClaimService extends GQLService<Claim> {

  constructor(
    apollo: Apollo,
    private merchantService: MerchantService
  ) {
    super({
      apollo,
      model: Claim,
      singular: 'claim',
      plural: 'claims',
      fragments: ClaimFragments,
      constants: ['source']
    });
  }

  makeDecision(claimId: number, decision: ClaimDecision): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          claimUpdate(newDecision: {
            id: ${claimId}
            ${objToGql(decision, ['action'])}
          }) {
            ok
          }
        }
      `
    }).pipe(map((res: any) => {
      const data = res.data.claimUpdate;
      if (data.ok) {
        return true
      } else {
        throw new Error(`Couldn't make claim decision. Claim #${claimId}, Decision: ${decision.action}`);
      }
    }));
  }

  changeBenefit(claimId: number, newBenefitId: number): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          claimUpdate(benefitChange: {
            id: ${claimId}
            benefitId: ${newBenefitId}
          }) {
            ok
          }
        }
      `
    }).pipe(map((res: any) => {
      const data = res.data.claimUpdate;
      if (data.ok) {
        return true
      } else {
        throw new Error(`Couldn't change claim benefit. Claim #${claimId}, Benefit #${newBenefitId}`);
      }
    }));
  }

  changeMerchant(claimId: number, newMerchantId: number): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          claimUpdate(merchantChange: {
            id: ${claimId},
            merchantId: ${newMerchantId}
          }) {
            ok
          }
        }
      `
    }).pipe(map((res: any) => {
      const data = res.data.claimUpdate;
      if (data.ok) {
        return true
      } else {
        throw new Error(`Couldn't change claim merchant. Claim #${claimId}, Merchant #${newMerchantId}`);
      }
    }));
  }

  addNote(claimId: number, text: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          claimUpdate(newNote: {
            id: ${claimId}
            text: ${valToGql(text)}
          }) {
            ok
          }
        }
      `
    }).pipe(map((res: any) => {
      if (res.data.claimUpdate.ok) {
        return true;
      } else {
        throw new Error(`Couldn't add new note to claim #${claimId}`);
      }
    }));
  }

  addAdditionalInfo(claimId: number, additionalInfo: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          claimUpdate(additionalInfoChange: {
            id: ${claimId}
            additionalInformation: ${valToGql(additionalInfo)}
          }) {
            ok
            claim {
              additionalInformation
            }
          }
        }
      `
    }).pipe(map((res: any) => {
      const {
        data: {
          claimUpdate: {
            ok,
            claim: { additionalInformation }
          }
        }
      } = res;

      if (ok) {
        return additionalInformation;
      } else {
        throw new Error(`Couldn't change additional information of claim #${claimId}`);
      }
    }));
  }


  getHistory(claimId: number): Observable<ClaimHistory[]> {
    return this.apollo.watchQuery({
      query: gql`
        {
          notifications(claimId: "${claimId}") {
            results {
              ...claimHistorySlimFields
            }
          }
        }
        ${ClaimHistoryFragments.slim('claimHistorySlimFields')}
      `,
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      take(1),
      map((res: any): ClaimHistory[] => {
        return res.data.notifications.results.map((data: any) => {
          return new ClaimHistory().deserialize(data);
        });
      }),
      mergeMap(history => {
        const merchantIds = [];

        history.forEach(h => {
          if (!h.data) { return; }

          if (h.data.newMerchantId) {
            merchantIds.push(h.data.newMerchantId);
          }

          if (h.data.oldMerchantId) {
            merchantIds.push(h.data.oldMerchantId);
          }
        });

        // Link in merchants
        return this.merchantService.getSome(uniq(merchantIds)).pipe(map(merchants => {
          return history.map(h => {
            if (!h.data) { return h; }

            if (h.data.newMerchantId) {
              h.data.newMerchant = merchants.find(m => m.id === h.data.newMerchantId);
            }

            if (h.data.oldMerchantId) {
              h.data.oldMerchant = merchants.find(m => m.id === h.data.oldMerchantId);
            }

            return h;
          });
        }));
      })
    );
  }

  addIcdCode(claimId: number, icdCode: IcdCode, isDefault: boolean): Observable<ClaimIcdCode> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          claimIcdCodeCreate(newClaimIcdCode:{
            claimId: ${claimId},
            icdCodeId: ${icdCode.id},
            default: ${isDefault}
          }) {
            ok
            claimicdcode {
              ...claimIcdCodeFields
            }
          }
        }
        ${ClaimIcdCodeFragments.fat('claimIcdCodeFields')}
      `
    }).pipe(map((res: any) => {
      const data = res.data.claimIcdCodeCreate;
      if (data.ok) {
        return new ClaimIcdCode().deserialize(data.claimicdcode);
      } else {
        throw new Error(`Couldn't add claim ICD code. Claim #${claimId}, ICDCode #${icdCode.id}`);
      }
    }));
  }

  removeIcdCode(claimIcdCode: ClaimIcdCode): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          claimIcdCodeDelete(id: ${claimIcdCode.id}) {
            ok
          }
        }
      `
    }).pipe(map((res: any) => {
      if (res.data.claimIcdCodeDelete.ok) {
        return true;
      } else {
        throw new Error(`Couldn't delete claim ICD code #${claimIcdCode.id}`);
      }
    }));
  }

  updateIcdCode(claimIcdCode: ClaimIcdCode, isDefault: boolean): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          claimIcdCodeUpdate(newClaimIcdCode: {
            id: ${claimIcdCode.id}
            default: ${isDefault}
          }) {
            ok
          }
        }
      `
    }).pipe(map((res: any) => {
      if (res.data.claimIcdCodeUpdate.ok) {
        return true;
      } else {
        throw new Error(`Couldn't update claim ICD code #${claimIcdCode.id}`);
      }
    }));
  }
}
