import { Component, Input } from '@angular/core';
import { PaymentSettlementEventType } from '@common/enums';
import { PaymentSettlementEvent } from '@common/models';

@Component({
  selector: 'ps-payment-settlement-events',
  templateUrl: './payment-settlement-events.component.html',
  styleUrls: ['./payment-settlement-events.component.scss']
})
export class PaymentSettlementEventsComponent {
  @Input() settlementEvents: PaymentSettlementEvent[];
  @Input() currencyCode: string;
  expanded = false;

  eventTypeDict = {
    [PaymentSettlementEventType.FirstPresentment]: 'Settlement',
    [PaymentSettlementEventType.FirstPresentmentRefund]: 'Refund'
  };
}
