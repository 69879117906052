<div class="page-wrap">
  <div class="content-wrapper text-center">
    <span class="heading" i18n="not found page heading@@notFoundPageHeading">Page Not Found</span>
    <p i18n="@@notFoundPageDescription">
      The page you are looking for was not found.
    </p>
    <p i18n="@@notFoundPageNavigation">
      Go to <a routerLink="/dashboard" class="btn-link">Dashboard</a> or go
      <a (click)="goBack()">back</a>.
    </p>
  </div>
</div>
