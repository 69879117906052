import gql from "graphql-tag";

export const IcdCodeFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on IcdCodeType {
      id
      code
      name
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on IcdCodeType {
      id
      code
      name
    }
  `
};
