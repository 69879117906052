<div class="Search" [@overlay] *ngIf="show">
  <div class="Search__content" [ngClass]="{ 'Search__content--results': results.length }">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="SearchBox">
            <input
              #searchInput
              class="SearchBox__input form-control .form-control-lg"
              [formControl]="search"
              type="text"
              placeholder="Search products, policies, claims, and more"
              i18n-placeholder="@@searchStuffPholder"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="none"
            />
            <fa-icon [icon]="icons.search" class="SearchBox__icon"></fa-icon>
            <div class="SearchBox__loader Loader" *ngIf="loading"></div>
          </div>
        </div>
      </div>
      <div class="Search__results row">
        <div class="col-md-4" *ngFor="let result of results">
          <ps-search-result [result]="result" (click)="open(result)"></ps-search-result>
        </div>
      </div>
    </div>
  </div>
  <button class="Search__close" (click)="close()">
    <fa-icon [icon]="icons.close"></fa-icon>
  </button>
</div>
