import { Platform } from '@common/enums';

export const config = {
  apiPageSize: 20,
  apiSource: 'INSURER_DASHBOARD',
  dateFormat: 'YYYY-MM-DD HH:mm',
  navbarHeight: 50,
  notificationPollInterval: 5000,
  systemStatusPollInterval: 60000,
  liveClaimsInterval: 5000,
  localKeySettings: 'paysure.settings',
  searchTransitionDuration: 200,
  amountRegex: new RegExp('^[0-9.,]+$'),
  csrfCookieName: 'csrftoken',
  icdBlacklist: ['K21', 'K29', 'E08', 'E78', 'I10', 'I15'],
  allowedSources: [
    Platform.PaymentPlatform,
    Platform.InsurerDashboard,
    Platform.ImportScript,
    Platform.MobileApp
  ],
  VISA_PORTAL_ENTITY_TAG: 'VIRTUAL_CARD_ALLOWED'
};
