import { Component, Input, ViewChild } from '@angular/core';
import { ModalComponent } from '@common/components/modal';
import { CodesExclusionType } from '@common/gql';
import { ModalDialog } from '@common/interfaces';

@Component({
  selector: 'ps-benefit-exclusion-dialog[exclusion]',
  styleUrls: ['./benefit-exclusion-dialog.component.scss'],
  templateUrl: './benefit-exclusion-dialog.component.html'
})
export class BenefitExclusionDialogComponent implements ModalDialog {
  @ViewChild('modal', { static: true }) modal: ModalComponent;
  exclusion: CodesExclusionType;

  initModal({ exclusion }: { exclusion: CodesExclusionType }) {
    this.exclusion = exclusion;
  }
}
