import gql from 'graphql-tag';
import { CurrencyFragments, UserFragments } from '.';
import { ReceiptItemFragments } from './receipt-item.fragments';

export const ReceiptFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on ReceiptType {
      id
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on ReceiptType {
      id
      source
      state
      reason
      uploadedAt
      file
      fileType
      thumbnail
      filename
      resolvedAt
      resolvedBy {
        ...userFields
      }
      paymentAuthorizationId
      acceptedAmount
      acceptedOriginAmount
      fxRate
      transactionFxRate
      transactionAt
      user {
        ...userFields
      }
      currency {
        ...currencyFields
      }
      claim {
        id
      }
      items {
        ...receiptItemFatFields
      }
    }
    ${CurrencyFragments.fat('currencyFields')}
    ${UserFragments.fat('userFields')}
    ${ReceiptItemFragments.fat('receiptItemFatFields')}
  `
};
