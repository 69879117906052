import gql from 'graphql-tag';

export const ProviderFragments = {
  slim: (fragmentName: string) => gql`
  fragment ${fragmentName} on ProviderType {
    id
    unlocks {
      id
      isEnabled
      createdAt
      beneficiary {
        id
      }
    }
    merchant {
      id
      name
      isVerifiedByAdmin
      address
    }
  }`,
  fat: (fragmentName: string) => gql`
  fragment ${fragmentName} on ProviderType {
    id
    unlocks {
      id
      isEnabled
      createdAt
      beneficiary {
        id
      }
    }
    merchant {
      id
      name
      isVerifiedByAdmin
      address
    }
  }`
};
