export enum ClaimDecisionAction {
  Authorize = 'AUTHORIZE',
  Accept = 'ACCEPT',
  Decline = 'DECLINE',
  Cancel = 'CANCEL',
  Reset = 'RESET',
  ReferAuthorization = 'REFER_AUTHORIZATION',
  MarkAsPaid = 'MARK_AS_PAID',
  MarkAsDirectSettlement = 'MARK_AS_DIRECT_SETTLEMENT'
}
