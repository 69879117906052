import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardIbanModalComponent } from './card-iban-modal.component';
import { ButtonComponentModule } from '../button';
import { ModalComponentModule } from '../modal';

@NgModule({
  imports: [CommonModule, ModalComponentModule, ButtonComponentModule],
  declarations: [CardIbanModalComponent],
  entryComponents: [CardIbanModalComponent],
  exports: [CardIbanModalComponent]
})
export class CardIbanModalComponentModule {}
