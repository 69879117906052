import { NgModule } from '@angular/core';

import { LocalDatePipe } from './local-date.pipe';
import { SettingsProviderModule } from '@common/providers';

@NgModule({
  imports: [SettingsProviderModule],
  declarations: [LocalDatePipe],
  exports: [LocalDatePipe]
})
export class LocalDatePipeModule {}
