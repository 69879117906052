import { Pipe, PipeTransform } from '@angular/core';
import * as moment_ from 'moment-timezone';
const moment = moment_;

import { SettingsProvider } from '@common/providers';

const defaultDateFormat = 'YYYY-MM-DD HH:mm';

@Pipe({ name: 'localDate' })
export class LocalDatePipe implements PipeTransform {
  constructor(private settingsService: SettingsProvider) {}

  transform(value: string, customFormat?: string): string {
    const timezone = this.settingsService.settings.timezone;
    const format = customFormat || defaultDateFormat;
    return moment(value)
      .tz(timezone)
      .format(format);
  }
}
