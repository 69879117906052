<div class="BenefitExclusion">
  <div class="BenefitExclusion__header">
    <h5 class="BenefitExclusion__title">{{ exclusion.name }}</h5>
    <button class="BenefitExclusion__cta" (click)="viewExclusion()">
      <fa-icon [icon]="icons.faEye"></fa-icon>
      View
    </button>
  </div>
  <p class="BenefitExclusion__description">{{ exclusion.description | truncate: 110 }}</p>
  <div class="BenefitExclusion__icds" *ngIf="exclusion.icdCodes.length">
    <label>ICD Codes:</label>
    <div>
      <div class="BenefitExclusion__code" *ngFor="let icd of exclusion.icdCodes">
        {{ icd.code }}
      </div>
    </div>
  </div>
  <div class="BenefitExclusion__cpts" *ngIf="exclusion.cptCodes.length">
    <label>CPT Codes:</label>
    <div>
      <div class="BenefitExclusion__code" *ngFor="let cpt of exclusion.cptCodes">
        {{ cpt.code }}
      </div>
    </div>
  </div>
</div>
