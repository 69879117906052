import { Model } from './model';
import { ConfigValueKey } from '../enums';

export class ConfigValue extends Model {
  value: string;
  key: ConfigValueKey;

  constructor() {
    super(['value', 'key']);
  }
}
