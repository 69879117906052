import { Component } from '@angular/core';
import { DashboardDisplayOptions, SidebarLink } from '@common/interfaces';
import {
  faFileInvoiceDollar,
  faFileMedicalAlt,
  faHospital,
  faTachometerAlt,
  faThList
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent {
  // Nav links for the dashboard and navbar on mobile
  links: SidebarLink[] = [
    { text: 'Overview', to: '/dashboard/overview', icon: faTachometerAlt },
    { text: 'Claims', to: '/dashboard/claims', icon: faFileInvoiceDollar },
    { text: 'Policies', to: '/dashboard/policies', icon: faFileMedicalAlt },
    { text: 'Products', to: '/dashboard/products', icon: faThList },
    { text: 'Providers', to: '/dashboard/providers', icon: faHospital }
  ];

  dashboardDisplayOptions: DashboardDisplayOptions = {
    showSearch: true,
    showBreadcrumbs: true,
    sidebarOptions: {
      showAccount: true,
      showNotifications: true,
      showSystemStatus: true
    }
  };
}
