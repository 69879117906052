import { Model } from './model';
import { toInt } from '@common/utils/model-transforms';
import { Benefit } from './benefit.model';

export class BenefitUsage extends Model {
  currentCount: number;
  currentAmount: number;
  currentAmountCovered: number;
  remainingLimitCount: number;
  remainingLimitAmount: number;
  currentPendingAmount: number;
  currentPendingCount: number;
  benefit: Benefit;

  constructor() {
    super([
      'currentCount',
      { key: 'currentAmount', transform: toInt },
      { key: 'currentAmountCovered', transform: toInt },
      { key: 'currentPendingAmount', transform: toInt },
      { key: 'currentPendingCount', transform: toInt },
      { key: 'remainingLimitCount', transform: toInt },
      { key: 'remainingLimitAmount', transform: toInt },
      { key: 'benefit', useModel: Benefit }
    ]);
  }
}
