import { Injectable } from '@angular/core';
import { Merchant } from '../../models';
import { GQLService } from '../common';
import { Apollo } from 'apollo-angular';
import { MerchantFragments } from '@common/fragments';

@Injectable({ providedIn: 'root' })
export class MerchantService extends GQLService<Merchant> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: Merchant,
      singular: 'merchant',
      plural: 'merchants',
      fragments: MerchantFragments
    });
  }
}
