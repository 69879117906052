import gql from 'graphql-tag';
import { IcdCodeFragments } from './icd-code.fragments';

export const ClaimIcdCodeFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on ClaimIcdCodeType {
      id
      default
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on ClaimIcdCodeType {
      id
      default
      icdCode {
        ...icdCodeFields
      }
      provider {
        id
      }
    }
    ${IcdCodeFragments.slim('icdCodeFields')}
  `
};
