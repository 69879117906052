import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonComponentModule } from '../button';
import { ModalComponentModule } from '../modal';
import { AddClaimNoteComponent } from './add-claim-note.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponentModule,
    ModalComponentModule
  ],
  entryComponents: [AddClaimNoteComponent],
  declarations: [AddClaimNoteComponent],
  exports: [AddClaimNoteComponent]
})
export class AddClaimNoteComponentModule {}
