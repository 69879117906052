import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LocalDatePipeModule, TruncatePipeModule } from '@common/pipes';
import { BreadcrumbsComponentModule } from '../breadcrumbs';
import { SearchComponentModule } from '../search';
import { ClaimStateLabelComponentModule } from '../claim-state-label';
import { NavbarComponentModule } from '../navbar';

import { DashboardComponent } from './dashboard/dashboard.component';
import { SystemStatusComponent } from './system-status/system-status.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SimpleNotificationsModule } from 'angular2-notifications';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    BreadcrumbsComponentModule,
    LocalDatePipeModule,
    TooltipModule,
    TruncatePipeModule,
    NavbarComponentModule,
    SearchComponentModule,
    ClaimStateLabelComponentModule,
    FontAwesomeModule,
    SimpleNotificationsModule.forRoot()
  ],
  declarations: [DashboardComponent, SystemStatusComponent, SidebarComponent],
  exports: [DashboardComponent, SidebarComponent, SystemStatusComponent]
})
export class DashboardComponentModule {}
