import { Injectable } from '@angular/core';
import { GQLService } from '../common';
import { IcdCode } from '../../models';
import { Apollo } from 'apollo-angular';
import { IcdCodeFragments } from '@common/fragments';

@Injectable({ providedIn: 'root' })
export class IcdCodeService extends GQLService<IcdCode> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: IcdCode,
      singular: 'icdCode',
      plural: 'icdCodes',
      fragments: IcdCodeFragments
    });
  }
}
