<div class="table-responsive DataTable">
  <table class="table DataTable__table">
    <thead *ngIf="hasHeader">
      <tr>
        <th scope="col" *ngFor="let column of columns">{{ column.name }}</th>
      </tr>
    </thead>
    <tbody *ngIf="!loading">
      <tr *ngFor="let row of rows">
        <td *ngFor="let column of columns">
          <ng-template
            [ngTemplateOutlet]="column.template"
            [ngTemplateOutletContext]="{ $implicit: row }"
          ></ng-template>
        </td>
      </tr>
      <tr *ngIf="rows.length === 0">
        <td [attr.colspan]="columns.length">{{ emptyMessage }}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="loading" class="Loader"></div>
</div>
<ps-pagination *ngIf="pages" [totalPages]="pages"></ps-pagination>
