import { Model } from './model';

export class IcdCode extends Model {
  id: string;
  code: string;
  name: string;
  description: string;

  constructor() {
    super(['id', 'code', 'name', 'description', 'default']);
  }
}
