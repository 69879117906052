import gql from 'graphql-tag';

export const PaymentAuthorizationFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentAuthorizationType {
      id
      acceptedAmount
      currencyCode
      createdAt
      merchantName
      attempts {
        id
        transactionTime
        decisionResult
        amount
        appliedRules {
          ruleType
          result
          reason
          skipped
          score
          actualRaw
          expectedRaw
        }
        reversals {
          id
          createdAt
          amount
          reversedBy
        }
        preAuthMatching {
          claimId
          success
          matchingExplanation {
            ruleType
            result
            reason
            skipped
            score
            actualRaw
            expectedRaw
          }
        }
      }
      settlement {
        id
        transactionTime
        amount
        currencyCode
        events {
          id
          amount
          transactionTime
          eventType
        }
      }
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentAuthorizationType {
      id
      acceptedAmount
      currencyCode
      createdAt
      merchantName
      attempts {
        id
        transactionTime
        decisionResult
        amount
        appliedRules {
          ruleType
          result
          reason
          skipped
          score
          actualRaw
          expectedRaw
        }
        reversals {
          id
          createdAt
          amount
          reversedBy
        }
        preAuthMatching {
          claimId
          success
          matchingExplanation {
            ruleType
            result
            reason
            skipped
            score
            actualRaw
            expectedRaw
          }
        }
      }
      settlement {
        id
        transactionTime
        amount
        currencyCode
        events {
          id
          amount
          transactionTime
          eventType
        }
      }
    }
  `
};
