<button
  [type]="type"
  (click)="onBtnClick($event)"
  [ngClass]="btnClass ? btnClass : ''"
  [tooltip]="btnTooltip"
  [disabled]="disabled"
  [aria-label]="ariaLabel"
>
  <fa-icon *ngIf="icon" [icon]="icon" [ngClass]="icon ? 'icon ' + iconClass : 'icon'"></fa-icon>
  <span class="btnText"><ng-content></ng-content></span>
  <div [ngClass]="['loaderWrapper ' + loaderPosition]">
    <span *ngIf="loading" class="Loader btnLoader"></span>
  </div>
</button>
