<h5 i18n="@@beneficiaryPayments">Beneficiary Payments</h5>
<div class="table-responsive">
  <table class="table payments-table">
    <thead>
      <tr>
        <th i18n="@@time">Time</th>
        <th i18n="@@status">Status</th>
        <th i18n="@@amount">Amount</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let p of beneficiaryPayments">
        <tr class="payments-table__row">
          <td width="160">{{ p.transactionTime | localDate }}</td>
          <td>
            <ps-payment-state-label [state]="p.state"></ps-payment-state-label>
          </td>
          <td class="expandCell d-flex justify-content-end expand">
            <span> {{ p.amount | currency: claimCurrency.code }}</span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
