import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { Product } from '../../models';
import { GQLService } from '../common';
import { ProductFragments } from '@common/fragments';

@Injectable({ providedIn: 'root' })
export class ProductService extends GQLService<Product> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: Product,
      singular: 'product',
      plural: 'products',
      fragments: ProductFragments
    });
  }
}
