import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CardComponent } from './card.component';
import { LinkComponentModule } from '../link';
import { ButtonComponentModule } from '../button';
import { LocalDatePipeModule } from '@common/pipes';
import { CardPinModalComponentModule } from '../card-pin-modal';
import { CardActionModalComponentModule } from '../card-action-modal';
import { PaymentControlsModalComponentModule } from '../payment-controls-modal';
import { PaymentRulesModalComponentModule } from '../payment-rules-modal';

@NgModule({
  imports: [
    CommonModule,
    LinkComponentModule,
    ButtonComponentModule,
    BsDropdownModule.forRoot(),
    FontAwesomeModule,
    LocalDatePipeModule,
    CardPinModalComponentModule,
    CardActionModalComponentModule,
    PaymentControlsModalComponentModule,
    PaymentRulesModalComponentModule
  ],
  declarations: [CardComponent],
  exports: [CardComponent]
})
export class CardComponentModule {}
