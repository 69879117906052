import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BenefitUsageInput } from '../benefit-usages.component';

interface Item {
  title: string;
  cols: {
    title: string;
    value: any;
    remaining?: any;
  }[];
}

@Component({
  selector: 'ps-benefit-breakdown[benefit][currencyCode]',
  templateUrl: './benefit-breakdown.component.html',
  styleUrls: ['./benefit-breakdown.component.scss']
})
export class BenefitBreakdownComponent implements OnInit {
  @Input() benefit: BenefitUsageInput;
  @Input() currencyCode: string;

  items: Item[];

  constructor(private currencyPipe: CurrencyPipe) {}

  ngOnInit() {
    const { limit, coPayment, excess } = this.benefit;

    const toCurrency = (amount: number) => {
      return this.currencyPipe.transform(amount, this.currencyCode);
    };

    const defaultTo = (amount: number, alt: any) => {
      return amount < 0 ? alt : amount;
    }

    this.items = [
      {
        title: 'Overall Limit',
        cols: [
          {
            title: 'Amount',
            value: toCurrency(limit.amount.total),
            remaining: toCurrency(limit.amount.remaining)
          },
          {
            title: 'Uses',
            value: defaultTo(limit.uses.total, 'Unlimited'),
            remaining: limit.uses.total > -1 ? limit.uses.remaining : null
          }
        ]
      },
      {
        title: 'Co-payment',
        cols: [
          {
            title: 'Percent',
            value: `${coPayment.pct}%`
          },
          {
            title: 'Maximum',
            value: defaultTo(coPayment.max, 'N/A')
          }
        ]
      },
      {
        title: 'Excess',
        cols: [
          {
            title: 'Benefit Level',
            value: toCurrency(excess.benefit)
          },
          {
            title: 'Claim Level',
            value: toCurrency(excess.claim)
          }
        ]
      }
    ];
  }
}
