<ps-modal [heading]="title" size="medium" #modal>
  <div class="modal-body">
    <p class="text-center">{{ text }}</p>
  </div>
  <div class="modal-footer">
    <ps-button
      *ngFor="let button of buttons"
      (onClick)="handleButtonClick(button)"
      [btnClass]="getButtonClass(button)"
    >
      {{ button.text }}
    </ps-button>
  </div>
</ps-modal>
