import { Injectable, Inject, forwardRef } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { mergeMap, map, take } from 'rxjs/operators';
import gql from 'graphql-tag';

import { Notification } from '@common/models';
import { GQLService } from '../common';
import { NotificationFragments } from '../../fragments';
import { AuthProvider } from '@common/providers';

interface QueryParams {
  read?: boolean;
  claimsAuthority?: number;
  lastId?: number;
}

@Injectable({ providedIn: 'root' })
export class NotificationService extends GQLService<Notification> {
  constructor(
    apollo: Apollo,
    @Inject(forwardRef(() => AuthProvider)) private authProvider: AuthProvider
  ) {
    super({
      apollo,
      model: Notification,
      singular: 'notification',
      plural: 'notifications',
      fragments: NotificationFragments
    });
  }

  private createQuery(params: QueryParams): any {
    const q = {
      any: [
        {
          match: { entityType: 'claim.claim', data_Action: 'REFER_AUTHORIZATION' }
        },
        {
          match: { action: 'CREATED', source: 'MOBILE_APP' },
          any: [
            { match: { entityType: 'claim.claim' } },
            { match: { entityType: 'claim.receipt' } }
          ]
        }
      ]
    };

    if (params.claimsAuthority !== undefined) {
      q['claimsAuthority'] = params.claimsAuthority;
    }

    if (params.read !== undefined) {
      q['match'] = { read: params.read };
    }

    if (params.lastId !== undefined) {
      if (!q['match']) {
        q['match'] = {};
      }
      q['match']['lastId'] = params.lastId;
    }

    const json = JSON.stringify(q);
    // Remove quotes from property names
    return json.replace(/"([^"]+)":/g, '$1:');
  }

  /**
   * Get unread notification count for current user
   */
  getUnreadCount(): Observable<number> {
    return this.authProvider.user.pipe(
      mergeMap(user => {
        const params: QueryParams = { read: false };
        if (user.claimsAuthority) {
          params.claimsAuthority = user.claimsAuthority;
        }
        const qs = this.createQuery(params);

        return this.apollo
          .watchQuery({
            query: gql`
              {
                notifications(search: ${qs}) {
                  totalCount
                }
              }
            `
          })
          .valueChanges.pipe(
            take(1),
            map((res: any) => {
              return res.data.notifications.totalCount;
            })
          );
      })
    );
  }

  /**
   * Gets notifications for current user
   */
  getNotifications(unread?: boolean, lastId?: number): Observable<Notification[]> {
    return this.authProvider.user.pipe(
      mergeMap(user => {
        const params: QueryParams = {};
        if (user.claimsAuthority) {
          params.claimsAuthority = user.claimsAuthority;
        }
        if (unread) {
          params.read = !unread;
        }
        if (lastId) {
          params.lastId = lastId;
        }
        const qs = this.createQuery(params);

        return this.apollo
          .watchQuery({
            query: gql`
            {
              notifications(search: ${qs}) {
                totalCount
                results {
                  ...notificationsSlimFields
                }
              }
            }
            ${NotificationFragments.slim('notificationsSlimFields')}
          `,
            fetchPolicy: 'no-cache'
          })
          .valueChanges.pipe(
            take(1),
            map((res: any) => {
              const collection = res.data.notifications;

              this.setTotalPages = this.pageCount(collection.totalCount);
              this.setTotalItems = collection.totalCount;

              return collection.results.map((data: any) => {
                return new Notification().deserialize(data);
              });
            })
          );
      })
    );
  }

  markRead(notificationId: number): any {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          notificationUpdate(readChange: {
            id: ${notificationId}
            read: true
          }) {
            ok
          }
        }
      `
      })
      .pipe(
        map((res: any) => {
          const data = res.data.notificationUpdate;
          if (data.ok) {
            return true;
          } else {
            throw new Error(`Couldn't mark notification #${notificationId} as read.`);
          }
        })
      );
  }
}
