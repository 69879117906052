import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertComponent } from './alert.component';
import { ButtonComponentModule } from '../button/button.component.module';
import { ModalComponentModule } from '../modal/modal.component.module';

@NgModule({
  imports: [CommonModule, ButtonComponentModule, ModalComponentModule],
  declarations: [AlertComponent],
  entryComponents: [AlertComponent],
  exports: [AlertComponent]
})
export class AlertComponentModule {}
