<div class="container">
  <div class="row">
    <div class="col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title" i18n="@@user">
            User
          </h5>
        </div>

        <div class="card-body">
          <div class="card-row" *ngIf="currentUser.fullName">
            <div class="card-row__label" i18n="@@name">Name</div>
            <div class="card-row__value">{{ currentUser.fullName }}</div>
          </div>
          <div class="card-row">
            <div class="card-row__label" i18n="@@email">Email</div>
            <div class="card-row__value">{{ currentUser.email }}</div>
          </div>
          <div class="card-row">
            <div class="card-row__label" i18n="@@lastActivity">Last Activity</div>
            <div class="card-row__value">{{ currentUser.lastActivity | localDate }}</div>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="fundingAccounts.length > 0">
        <div class="card-header">
          <h5 class="card-title" i18n="@@insurerFunds">Insurer Funds</h5>
        </div>
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th i18n="@@currency">Currency</th>
                <th i18n="@@amount">Amount</th>
                <th i18n="@@fundDetails">Fund Details</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let fa of fundingAccounts">
                <tr>
                  <td>
                    {{ fa.insurerFundsAmount.currencyCode }}
                  </td>
                  <td>
                    <ng-container i18n="@@unknown" *ngIf="fa.insurerFundsAmount.amount === null">
                      Unknown
                    </ng-container>
                    <ng-container *ngIf="fa.insurerFundsAmount.amount !== null">
                      {{ fa.insurerFundsAmount.amount }}
                    </ng-container>
                  </td>
                  <td>
                    <ng-container *ngIf="fa.iban === null">N/A</ng-container>
                    <ng-container *ngIf="fa.iban !== null">
                      <ps-button
                        (onClick)="showIban(fa.iban, fa.bic)"
                        btnClass="btn btn-secondary small"
                      >
                        Show IBAN
                      </ps-button>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5 class="card-title" i18n="@@settingsPageTitle">
            Your Personal Preferences
          </h5>
        </div>
        <div class="card-body">
          <form [formGroup]="settingsForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="form-group">
              <label i18n="settings page timezone label@@timezoneLabel">Timezone:</label>
              <select class="form-control" formControlName="timezone">
                <option *ngFor="let timezone of timezones" [value]="timezone">{{
                  timezone
                }}</option>
              </select>
            </div>
            <div class="d-flex justify-content-end">
              <ps-button type="submit" btnClass="btn btn-primary" i18n="saveBtn">Save</ps-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
