import { Model } from './model';

export class Corporation extends Model {
  id: number;
  externalId?: string;
  companyName: string;
  address: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;

  constructor() {
    super([
      'id',
      'externalId',
      'companyName',
      'address',
      'contactName',
      'contactEmail',
      'contactPhone'
    ]);
  }
}
