import { Component, OnInit, ViewChild } from '@angular/core';

import { ModalComponent } from '@common/components';
import { ModalDialog } from '@common/interfaces';
import { checkVal } from '@common/utils';

@Component({
  selector: 'ps-card-pin-modal',
  templateUrl: './card-pin-modal.component.html',
  styleUrls: ['./card-pin-modal.component.scss']
})
export class CardPinModalComponent implements ModalDialog, OnInit {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  loading = true;
  pin: string;
  cardId: number;

  constructor() {}

  initModal({ cardId }) {
    this.cardId = checkVal(cardId, 'Expected cardId');
  }

  ngOnInit() {
    setTimeout(() => (this.loading = false), 1500);
    this.pin = (2838 * this.cardId).toString().substr(0, 4);
  }
}
