import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { delay, take } from 'rxjs/operators';
import { omit as _omit } from 'lodash';

import { FilterComponent } from './filter.component';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ps-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  // FontAwesome icons
  icons = {
    filter: faFilter
  };

  filters: FilterComponent[] = [];
  form: FormGroup = new FormGroup({});
  initialized = false;
  maxDate: Date;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    // Set max selectable date
    this.maxDate = new Date();

    this.route.queryParams.pipe(delay(1)).subscribe(params => {
      this.form.reset();

      for (const key in params) {
        if (this.form.get(key)) {
          const filter = this.filters.find(f => f.param === key);
          this.form.get(key).setValue(filter.transformIn(params[key]));
        }
      }
    });
  }

  registerFilter(filter: FilterComponent) {
    this.filters.push(filter);
    this.form.addControl(filter.param, new FormControl());

    this.form.get(filter.param).valueChanges.subscribe(value => {
      if (value === 'null') {
        value = null;
      }
      filter.valueChange.emit(filter.transformOut(value));
    });
  }

  onSubmit() {
    const params = {};
    const values = this.form.value;

    Object.keys(values).forEach(key => {
      const filter = this.filters.find(f => f.param === key);
      if (values[key] !== '' && values[key] !== 'null') {
        params[key] = filter.transformOut(values[key]);
      }
    });

    this.router.navigate([], {
      queryParams: params
    });
  }

  reset() {
    this.form.reset();

    this.route.queryParams.pipe(take(1)).subscribe(params => {
      // Strip out filter params but leave unrelated ones
      const filterKeys = this.filters.map(f => f.param);
      const stripped = _omit(params, filterKeys);

      this.router.navigate([], {
        queryParams: stripped
      });
    });
  }
}
