<table class="table Reversals">
  <tbody>
    <tr *ngFor="let reversal of reversals">
      <td width="160">
        {{ reversal.createdAt | localDate }}
      </td>
      <td>
        {{ reversedByDict[reversal.reversedBy] || reversal.reversedBy }}
      </td>
      <td class="lastCell">
        <span>
          {{ reversal.amount | currency: currencyCode }}
        </span>
      </td>
    </tr>
  </tbody>
</table>
