import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { PaymentRulesModalComponent } from './payment-rules-modal.component';
import { ModalComponentModule } from '../modal';
import { ButtonComponentModule } from '../button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalComponentModule,
    ButtonComponentModule,
    FontAwesomeModule
  ],
  declarations: [PaymentRulesModalComponent],
  entryComponents: [PaymentRulesModalComponent],
  exports: [PaymentRulesModalComponent]
})
export class PaymentRulesModalComponentModule {}
