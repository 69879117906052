<a
  (click)="onLinkClick($event)"
  [attr.routerLink]="routerLink ? routerLink : null"
  [ngClass]="linkClass ? 'link ' + linkClass : 'link'"
  [attr.queryParams]="queryParams ? queryParams : null"
  [attr.href]="href ? href : null"
  [attr.target]="target"
>
  <span class="linkText">
    <ng-content></ng-content>
  </span>
  <fa-icon
    *ngIf="icon"
    [icon]="icon"
    [ngClass]="iconClass ? 'icon ' + iconClass : 'icon'"
  ></fa-icon>
  <div [ngClass]="['loaderWrapper ' + loaderPosition]">
    <span *ngIf="loading" class="Loader linkLoader"></span>
  </div>
</a>
