<div class="Provider row" [ngClass]="{ 'Provider--small': isSmall, 'Provider--large': isLarge }">
  <div [ngClass]="{ 'col-md-3': isSmall, 'col-md-6': !isSmall }">
    <img
      *ngIf="isLarge"
      [src]="getMap(640, 150, 12)"
      width="640"
      height="200"
      class="img-fluid Provider__map"
    />
    <img
      *ngIf="isMedium"
      [src]="getMap(260, 140, 13)"
      width="260"
      height="140"
      class="img-fluid Provider__map"
    />
    <img
      *ngIf="isSmall"
      [src]="getMap(150, 100, 11)"
      width="150"
      height="100"
      class="img-fluid Provider__map"
    />
  </div>
  <div [ngClass]="{ 'col-md-9': isSmall, 'col-md-6': !isSmall }">
    <address class="Provider__address">
      <span class="font--semiBold Provider__name">{{ merchant.name }}</span>
      {{ merchant.address }}
    </address>

    <ng-container *ngIf="showVerified && !isSmall">
      <span
        *ngIf="merchant.isVerifiedByAdmin"
        class="Provider__status Provider__status--verified  mr-2"
        tooltip="This provider has been verified by an admin"
      >
        <fa-icon [icon]="icons.ok"></fa-icon> Verified
      </span>
      <span
        *ngIf="!merchant.isVerifiedByAdmin"
        class="Provider__status Provider__status--unverified  mr-2 mb-2"
        tooltip="This provider was chosen by the beneficiary and has not been verified by an admin. Upon accepting this claim the provider will be marked as verified."
      >
        <fa-icon [icon]="icons.warning"></fa-icon> Not Verified
      </span>
    </ng-container>

    <ng-container *ngIf="showUnlocked && !isSmall && isBeneficiaryUnlocked !== undefined">
      <span
        *ngIf="isBeneficiaryUnlocked"
        class="Provider__status Provider__status--beneficiaryUnlocked"
        tooltip="This provider can access the beneficiary's claims at their institution"
      >
        <fa-icon [icon]="icons.ok"></fa-icon> Beneficiary Unlocked
      </span>
      <span
        *ngIf="!isBeneficiaryUnlocked"
        class="Provider__status Provider__status--beneficiaryLocked"
        tooltip="This provider cannot access the beneficiary's claims at their institution"
      >
        <fa-icon [icon]="icons.locked"></fa-icon> Beneficiary Locked
      </span>
    </ng-container>
  </div>
</div>
