import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  OnInit,
  Inject,
  ComponentFactoryResolver
} from '@angular/core';

import { NotificationsService } from 'angular2-notifications';
import { mergeMap, filter, finalize } from 'rxjs/operators';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { faCheckCircle, faAsterisk, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import {
  AlertComponent,
  AlertComponentInput,
  PaymentRulesModalComponent,
  PaymentControlsModalComponent
} from '@common/components';
import { CardService } from '@common/services';
import { Permission, CardType } from '@common/enums';
import { Card, User } from '@common/models';
import { ModalProvider } from '@common/providers';

import { CardActionModalComponent } from '../card-action-modal/card-action-modal.component';
import { CardPinModalComponent } from '../card-pin-modal/card-pin-modal.component';

@Component({
  selector: 'ps-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: true } }]
})
export class CardComponent implements OnInit {
  card: Card;
  loading = true;
  CardType = CardType;
  icons = {
    ok: faCheckCircle,
    blocked: faTimesCircle,
    frozen: faAsterisk
  };

  private aspect = 1.586;

  @ViewChild('cardEl', { static: true }) cardRef: ElementRef;

  @Input() cardId: number;
  @Input() policyId: string;
  @Input() cardThreshold: number;
  @Input() currentUser: User;

  constructor(
    private cardService: CardService,
    private notificationsService: NotificationsService,
    private modalProvider: ModalProvider,
    @Inject(ComponentFactoryResolver) private cfr: ComponentFactoryResolver
  ) {
    this.modalProvider.updateContext(this.cfr);
  }

  ngOnInit() {
    this.loadCard(this.cardId);
    setTimeout(() => this.resizeCard(), 1);
  }

  @HostListener('window:resize') resizeCard() {
    const el: HTMLElement = this.cardRef.nativeElement;
    el.style.height = Math.round(el.clientWidth / this.aspect) + 'px';
  }

  loadCard(cardId: number) {
    this.cardService
      .getOne(cardId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(card => (this.card = card));
  }

  freeze() {
    this.modalProvider
      .open(CardActionModalComponent, { title: 'Freeze Card' })
      .pipe(
        filter(data => data['success'] === true),
        mergeMap(data => this.cardService.freeze(this.cardId, data['message']))
      )
      .subscribe(() => {
        this.loadCard(this.cardId);
        this.notificationsService.info('Card Frozen', 'The card has been frozen.');
      });
  }

  unfreeze() {
    this.modalProvider.open(AlertComponent, <AlertComponentInput>{
      title: 'Unfreeze Card',
      text:
        'Are you sure you want to unfreeze this card? ' +
        'The beneficiary will be notified via their mobile app.',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.cardService.unfreeze(this.cardId).subscribe(() => {
              this.loadCard(this.cardId);
              this.notificationsService.info('Card Unfrozen', 'The card has been unfrozen.');
            });
            return true;
          }
        }
      ]
    });
  }

  block() {
    this.modalProvider
      .open(CardActionModalComponent, { title: 'Block Card' })
      .pipe(
        filter(data => data['success'] === true),
        mergeMap(data => this.cardService.block(this.cardId, data['message']))
      )
      .subscribe(() => {
        this.loadCard(this.cardId);
        this.notificationsService.info('Card Blocked', 'The card has been blocked.');
      });
  }

  unblock() {
    this.modalProvider.open(AlertComponent, <AlertComponentInput>{
      title: 'Unblock Card',
      text:
        'Are you sure you want to unblock this card? ' +
        'The beneficiary will be notified via their mobile app.',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.cardService.unblock(this.cardId).subscribe(() => {
              this.loadCard(this.cardId);
              this.notificationsService.info('Card Unblocked', 'The card has been unblocked.');
            });
            return true;
          }
        }
      ]
    });
  }

  replace() {
    this.modalProvider
      .open(CardActionModalComponent, { title: 'Order Replacement Card' })
      .pipe(
        filter(data => data['success'] === true),
        mergeMap(data => this.cardService.replace(this.cardId, data['message']))
      )
      .subscribe(() => {
        this.loadCard(this.cardId);
        this.notificationsService.success(
          'Replacement Ordered',
          'A replacement card has been ordered.'
        );
      });
  }

  showPin(): void {
    this.modalProvider.open(CardPinModalComponent, { cardId: this.cardId });
  }

  showPaymentControls(): void {
    this.modalProvider.open(PaymentControlsModalComponent, {
      cardProxyId: this.card.proxyId,
      policyId: this.policyId.toString()
    });
  }

  showPaymentRules(): void {
    this.modalProvider.open(PaymentRulesModalComponent, {
      cardProxyId: this.card.proxyId
    });
  }

  /**
   * Can the current user unblock the payment card?
   */
  get canUnblock(): boolean {
    return this.currentUser.hasPermission(Permission.CanUnblockCard);
  }
}
