<table class="Result__table">
  <tbody>
    <tr>
      <td>
        <span class="Result__label"
          >{{ isScalar(result.actual.valueType) ? 'Actual value' : 'Actual values' }}:</span
        >
      </td>
      <td>
        <span class="Result__value font--semiBold"
          ><!-- Scalar -->
          <ng-container *ngIf="isScalar(result.actual.valueType)">{{
            ruleResultMapping(ruleName, result.actual.value)
          }}</ng-container>
          <!-- 1D List -->
          <ng-container *ngIf="is1dList(result.actual.valueType, result.actual.values)">
            <ng-container *ngFor="let item of result.actual.values; let i = index">
              {{
                i === result.actual.values.length - 1
                  ? ruleResultMapping(ruleName, item)
                  : ruleResultMapping(ruleName, item) + ', '
              }}
            </ng-container>
          </ng-container>
          <!-- Nested List -->
          <ng-container *ngIf="isNestedList(result.actual.valueType, result.actual.values)">
            <ng-container *ngFor="let subList of result.actual.values; let i = index">
              <ng-container *ngFor="let item of subList; let j = index">
                {{
                  i === result.actual.values.length - 1 && j === subList.length - 1
                    ? ruleResultMapping(ruleName, item)
                    : ruleResultMapping(ruleName, item) + ', '
                }}
              </ng-container>
            </ng-container>
          </ng-container>
        </span>
      </td>
    </tr>
  </tbody>
</table>
