import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { FundingAccountService } from './funding-account.service';
import { ServiceConfig } from '../common';

export const FundingAccountServiceConfig = new InjectionToken<ServiceConfig>(
  'FundingAccountServiceConfig'
);

@NgModule()
export class FundingAccountServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<FundingAccountServiceModule> {
    return {
      ngModule: FundingAccountServiceModule,
      providers: [FundingAccountService, { provide: FundingAccountServiceConfig, useValue: config }]
    };
  }
}
