import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DataTableColumnComponent } from './data-table-column.component';

@Component({
  selector: 'ps-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit {
  rows = [];
  loading = false;

  @Input() dataSource: any; // @TODO create datasource interface
  @Input() pages: number;
  @Input() emptyMessage: string;

  columns: DataTableColumnComponent[] = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.loading = true;

      (<Observable<any[]>>this.dataSource(params))
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(rows => (this.rows = rows));
    });
  }

  addColumn(column: DataTableColumnComponent) {
    this.columns.push(column);
  }

  get hasHeader(): boolean {
    for (let i = 0; i < this.columns.length; i++) {
      const column = this.columns[i];
      if (!!column.name) {
        return true;
      }
    }

    return false;
  }

  reload(flashNewRows?: boolean) {
    this.route.queryParams.subscribe(params => {
      (<Observable<any[]>>this.dataSource(params))
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(rows => (this.rows = rows));
    });
  }
}
