import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Breadcrumb } from '../../interfaces';
import { BreadcrumbProvider } from '@common/providers';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { noSubselectionAllowedMessage } from 'graphql/validation/rules/ScalarLeafs';

@Component({
  selector: 'ps-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  private focusedIndex = -1;
  private currentRoute: ActivatedRoute;

  breadcrumbs: Breadcrumb[];
  icons = { faChevronRight };
  elementWidth: number;

  constructor(
    private breadcrumbService: BreadcrumbProvider,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // Subscribe to changes in breadcrumbs
    this.breadcrumbService.breadcrumbs.subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs;
      this.focusedIndex = -1;
      // the width of collapsed breadcrumbs is 30px, and there's only one expanded
      // which has a width of 200px
      this.elementWidth =
        this.breadcrumbs.length > 0 ? (this.breadcrumbs.length - 1) * 30 + 200 : 0;
    });

    this.updateCurrentRoute();
  }

  /**
   * Because this component is placed within the dashboard
   * component. Therefore when handling relative links we
   * need to make sure navigation is relative to the
   * deepest child route, and not the dashboard.
   *
   * Here we make sure that this.currentRoute is always the
   * deepest ActivatedRoute.
   */
  updateCurrentRoute() {
    const deepestFirstChild = (route: ActivatedRoute) => {
      if (route.firstChild) {
        return deepestFirstChild(route.firstChild);
      } else {
        return route;
      }
    };

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => this.activatedRoute)
      )
      .subscribe(route => (this.currentRoute = deepestFirstChild(route)));

    this.currentRoute = deepestFirstChild(this.activatedRoute);
  }

  shouldCollapse(index: number): boolean {
    if (this.focusedIndex > -1) {
      return this.focusedIndex !== index;
    }
    return index + 1 < this.breadcrumbs.length;
  }

  focus(index: number): void {
    this.focusedIndex = index;
  }

  blur(): void {
    this.focusedIndex = -1;
  }

  /**
   * Navigate relative to this.currentRoute
   */
  navigate(routerLink: string): void {
    this.router.navigate([routerLink], {
      relativeTo: this.currentRoute
    });
  }
}
