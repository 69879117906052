import { Component, Input, OnInit } from '@angular/core';
import { DashboardDisplayOptions } from '@common/interfaces';
import { Link } from '../sidebar/sidebar.component';

@Component({
  selector: 'ps-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @Input() links: Link[];
  @Input() displayOptions: DashboardDisplayOptions;

  sidebarOpen = false; // Mobile only
  showNavbar = true;

  ngOnInit() {
    this.showNavbar = this.isMobile();
    window.onresize = () => {
      this.showNavbar = this.isMobile();
    };
  }

  isMobile(): boolean {
    const w = document.documentElement.clientWidth;
    return w < 768 ? true : false;
  }

  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }
}
