<table class="table breakdown-table">
  <thead>
    <tr>
      <th i18n="@@time">Time</th>
      <th i18n="@@event">Event</th>
      <th i18n="@@amount">Amount</th>
    </tr>
  </thead>
  <tbody>
    <!-- <Payment Settlement> -->
    <ng-container *ngIf="settlement">
      <tr class="attempt-separator">
        <td colspan="4"></td>
      </tr>
      <tr
        [ngClass]="{ expanded: settlement.expanded }"
        (click)="toggleSettlementEvents(settlement)"
      >
        <td width="160">{{ settlement.transactionTime | localDate }}</td>
        <td i18n="@@settlementReceived">Settlement Received</td>
        <td class="expandCell d-flex justify-content-end expand">
          <span>
            {{ settlement.amount | currency: settlement.currencyCode }}
          </span>
          <a class="payments-table__expand" *ngIf="settlement?.events?.length > 0">
            <div class="expandIconContainer">
              <fa-icon [icon]="settlement.expanded ? icons.caretUp : icons.caredDown"></fa-icon>
            </div>
          </a>
        </td>
      </tr>
      <tr
        class="payments-table__breakdown"
        *ngIf="settlement?.events?.length > 0"
        [ngClass]="{ 'd-none': !settlement.expanded }"
      >
        <td colspan="4" class="p-0">
          <div colspan="4" class="separator"></div>
          <ps-payment-settlement-events
            [settlementEvents]="settlement.events"
            [currencyCode]="settlement.currencyCode"
          ></ps-payment-settlement-events>
        </td>
      </tr>
    </ng-container>
    <!-- </Payment Settlement> -->

    <!-- <Payment Authorization Attempts> -->
    <ng-container *ngFor="let attempt of attempts">
      <tr class="attempt-separator">
        <td colspan="4"></td>
      </tr>
      <tr [ngClass]="{ expanded: attempt.expanded }" (click)="toggleReversals(attempt)">
        <td width="160">
          {{ attempt.transactionTime | localDate }}
        </td>
        <td>
          <span>
            <ng-container i18n="@@authorizationAt" *ngIf="attempt.decisionResult">
              Authorization at
            </ng-container>
            <ng-container i18n="@@authorizationDeclinedAt" *ngIf="!attempt.decisionResult">
              Authorization declined at
            </ng-container>
            <span class="font--semiBold">{{ merchantName }}</span>
          </span>
          <fa-icon
            *ngIf="!attempt.decisionResult && attempt.appliedRules.length > 0"
            (click)="viewReason(attempt)"
            [icon]="icons.info"
            class="ml-2 text-info viewReasonIcon"
          ></fa-icon>
        </td>
        <td class="expandCell d-flex justify-content-end expand">
          <span>{{ attempt.amount | currency: currencyCode }}</span>
          <div class="expandIconContainer">
            <fa-icon
              [icon]="attempt.expanded ? icons.caretUp : icons.caredDown"
              *ngIf="attempt.reversals?.length > 0"
            >
            </fa-icon>
          </div>
        </td>
      </tr>
      <tr
        class="payments-table__breakdown"
        [ngClass]="{ 'd-none': !attempt.expanded }"
        *ngIf="attempt?.reversals?.length > 0"
      >
        <td colspan="4" class="p-0">
          <div colspan="4" class="separator"></div>
          <ps-payment-reversals
            [reversals]="attempt.reversals"
            [currencyCode]="currencyCode"
          ></ps-payment-reversals>
        </td>
      </tr>
    </ng-container>
    <!-- </Payment Authorization Attempts> -->
  </tbody>
</table>
