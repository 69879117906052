<div class="Sidebar" [ngClass]="{ 'Sidebar--collapsed': collapsed, 'Sidebar--open': open }">
  <div class="Sidebar__header">
    <a class="Sidebar__logo" routerLink="/">
      <img src="/assets/img/paysure-transparent.svg" />
    </a>
    <button class="Sidebar__toggle btn-reset" (click)="toggleCollapse()">
      <fa-icon [icon]="toggleIcon"></fa-icon>
    </button>
    <button class="Sidebar__close btn-reset" (click)="onClose.emit()">
      <fa-icon [icon]="icons.faTimes"></fa-icon>
    </button>
  </div>
  <div class="Sidebar__scroller">
    <ul class="Sidebar__nav">
      <li routerLinkActive="active" *ngFor="let link of links">
        <a [routerLink]="link.to" (click)="onClose.emit()" [title]="link.text">
          <fa-icon [icon]="link.icon"></fa-icon>
          <span>{{ link.text }}</span>
        </a>
      </li>
    </ul>
    <ul class="Sidebar__nav" *ngIf="displayOptions.showNotifications || displayOptions.showAccount">
      <li routerLinkActive="active" *ngIf="displayOptions.showNotifications">
        <a routerLink="/dashboard/notifications" (click)="onClose.emit()" title="Notifications">
          <fa-icon [icon]="icons.faBell"></fa-icon>
          <span>
            <ng-container i18n="@@notificationsLink">Notifications</ng-container>&nbsp;
            <span *ngIf="unreadNotifications" class="badge">{{ unreadNotifications }}</span>
          </span>
        </a>
      </li>
      <li routerLinkActive="active" *ngIf="displayOptions.showAccount">
        <a routerLink="/dashboard/account" (click)="onClose.emit()" title="Account">
          <fa-icon [icon]="icons.faUserCircle"></fa-icon>
          <span>Account</span>
        </a>
      </li>
    </ul>
    <div class="Sidebar__sysStatus" *ngIf="displayOptions.showSystemStatus">
      <ps-system-status></ps-system-status>
    </div>
  </div>
  <button class="Sidebar__logout btn-reset" title="Sign Out" (click)="logout()">
    <fa-icon [icon]="icons.faSignOutAlt"></fa-icon>
    <span>Sign Out</span>
  </button>
</div>
