import { Model } from './model';
import { PaymentRuleType } from '@common/enums';

class Specification extends Model {
  amount?: number;
  count?: number;
  timeWindowMinutes?: number;
  timeWindowDays?: number;
  ruleName: PaymentRuleType;

  constructor() {
    super(['amount', 'count', 'timeWindowMinutes', 'timeWindowDays', 'ruleName']);
  }
}

export class Rule extends Model {
  id: number;
  ruleType: string;
  description: string;
  specification: Specification;

  constructor() {
    super(['id', 'ruleType', 'description', { key: 'specification', useModel: Specification }]);
  }
}
