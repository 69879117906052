import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  CustomDateTime: any;
  CustomDate: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  CustomTime: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  DecimalScalar: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * In contrary of the default graphene.types.datetime.DateTime this implementation can parse arbitrary (!)
   * date formats and output them in the same format - iso.
   *
   * Useful when input can be in both date and datetime format
   */
  AutoParsedDateTime: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type AccountInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** classification */
  classification?: Maybe<Scalars['String']>;
  /** classification */
  classification_Icontains?: Maybe<Scalars['String']>;
  /** entity type */
  entityType?: Maybe<Scalars['String']>;
  /** entity type */
  entityType_Icontains?: Maybe<Scalars['String']>;
  /** entity id */
  entityId?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Gt?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Lt?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Gte?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Lte?: Maybe<Scalars['Int']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  classification_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityId_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  claim?: Maybe<ClaimInputFilterObject>;
  postingentry?: Maybe<PostingEntryInputFilterObject>;
};

/** An enumeration. */
export enum Action {
  Authorize = 'AUTHORIZE',
  Accept = 'ACCEPT',
  Decline = 'DECLINE',
  Cancel = 'CANCEL',
  Reset = 'RESET',
  ReferAuthorization = 'REFER_AUTHORIZATION',
  MarkAsPaid = 'MARK_AS_PAID'
}

export type AddressInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** street name */
  streetName?: Maybe<Scalars['String']>;
  /** street name */
  streetName_Icontains?: Maybe<Scalars['String']>;
  /** street number */
  streetNumber?: Maybe<Scalars['String']>;
  /** street number */
  streetNumber_Icontains?: Maybe<Scalars['String']>;
  /** address refinement */
  addressRefinement?: Maybe<Scalars['String']>;
  /** address refinement */
  addressRefinement_Icontains?: Maybe<Scalars['String']>;
  /** zip code */
  zipCode?: Maybe<Scalars['String']>;
  /** zip code */
  zipCode_Icontains?: Maybe<Scalars['String']>;
  /** city */
  city?: Maybe<Scalars['String']>;
  /** city */
  city_Icontains?: Maybe<Scalars['String']>;
  /** region */
  region?: Maybe<Scalars['String']>;
  /** region */
  region_Icontains?: Maybe<Scalars['String']>;
  /** raw */
  raw?: Maybe<Scalars['String']>;
  /** raw */
  raw_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  streetName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  streetNumber_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressRefinement_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  zipCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  city_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  region_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<CountryInputFilterObject>;
};

export type AddressInputType = {
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  addressRefinement?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  country?: Maybe<CustomerCountryInputType>;
  streetName_Icontains?: Maybe<Scalars['String']>;
  streetNumber_Icontains?: Maybe<Scalars['String']>;
  addressRefinement_Icontains?: Maybe<Scalars['String']>;
  zipCode_Icontains?: Maybe<Scalars['String']>;
  city_Icontains?: Maybe<Scalars['String']>;
  countryId_Gt?: Maybe<Scalars['Int']>;
  countryId_Lt?: Maybe<Scalars['Int']>;
  countryId_Gte?: Maybe<Scalars['Int']>;
  countryId_Lte?: Maybe<Scalars['Int']>;
  region_Icontains?: Maybe<Scalars['String']>;
  raw_Icontains?: Maybe<Scalars['String']>;
  formatted_Icontains?: Maybe<Scalars['String']>;
};

export type AddressListType = {
  __typename?: 'AddressListType';
  /** Address list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CorporationAddressType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type AddressListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  addressRefinement?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  country?: Maybe<CustomerCountryType>;
  region?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
};

export type AggregatedBeneficiaryCardInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** currency code */
  currencyCode?: Maybe<Scalars['String']>;
  /** currency code */
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  /** beneficiary id */
  beneficiaryId?: Maybe<Scalars['Int']>;
  /** beneficiary id */
  beneficiaryId_Gt?: Maybe<Scalars['Int']>;
  /** beneficiary id */
  beneficiaryId_Lt?: Maybe<Scalars['Int']>;
  /** beneficiary id */
  beneficiaryId_Gte?: Maybe<Scalars['Int']>;
  /** beneficiary id */
  beneficiaryId_Lte?: Maybe<Scalars['Int']>;
  /** card threshold */
  cardThreshold?: Maybe<Scalars['Float']>;
  /** card threshold */
  cardThreshold_Gt?: Maybe<Scalars['Float']>;
  /** card threshold */
  cardThreshold_Lt?: Maybe<Scalars['Float']>;
  /** card threshold */
  cardThreshold_Gte?: Maybe<Scalars['Float']>;
  /** card threshold */
  cardThreshold_Lte?: Maybe<Scalars['Float']>;
  numberOfDeclinedClaimsReached?: Maybe<Scalars['Boolean']>;
  numberOfClaimsWithinSpecifiedTimeWindowReached?: Maybe<Scalars['Boolean']>;
  totalNumberOfClaimsReached?: Maybe<Scalars['Boolean']>;
  velocityControlViolated?: Maybe<Scalars['Boolean']>;
  frequencyControlViolated?: Maybe<Scalars['Boolean']>;
  pendingClaimToBlock?: Maybe<Scalars['Boolean']>;
  /** benefit limits remaining */
  benefitLimitsRemaining?: Maybe<Scalars['JSONString']>;
  cardReady?: Maybe<Scalars['Boolean']>;
  /** funding account id */
  fundingAccountId?: Maybe<Scalars['Int']>;
  /** funding account id */
  fundingAccountId_Gt?: Maybe<Scalars['Int']>;
  /** funding account id */
  fundingAccountId_Lt?: Maybe<Scalars['Int']>;
  /** funding account id */
  fundingAccountId_Gte?: Maybe<Scalars['Int']>;
  /** funding account id */
  fundingAccountId_Lte?: Maybe<Scalars['Int']>;
  /** gps latitude */
  gpsLatitude?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** locked */
  locked?: Maybe<Scalars['CustomDateTime']>;
  /** locked */
  locked_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** locked */
  locked_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** locked */
  locked_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** locked */
  locked_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  currencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiaryId_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  cardThreshold_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  numberOfDeclinedClaimsReached_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  numberOfClaimsWithinSpecifiedTimeWindowReached_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  totalNumberOfClaimsReached_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  velocityControlViolated_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  frequencyControlViolated_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  pendingClaimToBlock_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  benefitLimitsRemaining_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  cardReady_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  fundingAccountId_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gpsLatitude_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  gpsLongitude_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  updatedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  locked_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  cardProxyId?: Maybe<CardInputFilterObject>;
};

export type AggregatedBeneficiaryCardListType = {
  __typename?: 'AggregatedBeneficiaryCardListType';
  /** AggregatedBeneficiaryCard list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<AggregatedBeneficiaryCardType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type AggregatedBeneficiaryCardListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type AggregatedBeneficiaryCardType = {
  __typename?: 'AggregatedBeneficiaryCardType';
  /** beneficiary id */
  beneficiaryId?: Maybe<Scalars['Int']>;
  /** benefit limits remaining */
  benefitLimitsRemaining?: Maybe<Scalars['JSONString']>;
  /** card proxy id */
  cardProxyId?: Maybe<CardType>;
  cardReady?: Maybe<Scalars['Boolean']>;
  /** card threshold */
  cardThreshold?: Maybe<Scalars['Float']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** currency code */
  currencyCode?: Maybe<Scalars['String']>;
  frequencyControlViolated?: Maybe<Scalars['Boolean']>;
  /** funding account id */
  fundingAccountId?: Maybe<Scalars['Int']>;
  /** gps latitude */
  gpsLatitude?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** locked */
  locked?: Maybe<Scalars['CustomDateTime']>;
  numberOfClaimsWithinSpecifiedTimeWindowReached?: Maybe<Scalars['Boolean']>;
  numberOfDeclinedClaimsReached?: Maybe<Scalars['Boolean']>;
  pendingClaimToBlock?: Maybe<Scalars['Boolean']>;
  totalNumberOfClaimsReached?: Maybe<Scalars['Boolean']>;
  /** updated at */
  updatedAt?: Maybe<Scalars['CustomDateTime']>;
  velocityControlViolated?: Maybe<Scalars['Boolean']>;
};

export type AggregatedPreauthInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** card proxy id */
  cardProxyId?: Maybe<Scalars['String']>;
  /** card proxy id */
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  /** currency code */
  currencyCode?: Maybe<Scalars['String']>;
  /** currency code */
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  /** optional currency code */
  optionalCurrencyCode?: Maybe<Scalars['String']>;
  /** optional currency code */
  optionalCurrencyCode_Icontains?: Maybe<Scalars['String']>;
  /** country code */
  countryCode?: Maybe<Scalars['String']>;
  /** country code */
  countryCode_Icontains?: Maybe<Scalars['String']>;
  /** merchant category code */
  merchantCategoryCode?: Maybe<Scalars['String']>;
  /** merchant category code */
  merchantCategoryCode_Icontains?: Maybe<Scalars['String']>;
  /** merchant name */
  merchantName?: Maybe<Scalars['String']>;
  /** merchant name */
  merchantName_Icontains?: Maybe<Scalars['String']>;
  /** merchant id */
  merchantId?: Maybe<Scalars['String']>;
  /** merchant id */
  merchantId_Icontains?: Maybe<Scalars['String']>;
  /** merchant address */
  merchantAddress?: Maybe<Scalars['String']>;
  /** merchant address */
  merchantAddress_Icontains?: Maybe<Scalars['String']>;
  /** merchant gps latitude */
  merchantGpsLatitude?: Maybe<Scalars['Float']>;
  /** merchant gps latitude */
  merchantGpsLatitude_Gt?: Maybe<Scalars['Float']>;
  /** merchant gps latitude */
  merchantGpsLatitude_Lt?: Maybe<Scalars['Float']>;
  /** merchant gps latitude */
  merchantGpsLatitude_Gte?: Maybe<Scalars['Float']>;
  /** merchant gps latitude */
  merchantGpsLatitude_Lte?: Maybe<Scalars['Float']>;
  /** merchant gps longitude */
  merchantGpsLongitude?: Maybe<Scalars['Float']>;
  /** merchant gps longitude */
  merchantGpsLongitude_Gt?: Maybe<Scalars['Float']>;
  /** merchant gps longitude */
  merchantGpsLongitude_Lt?: Maybe<Scalars['Float']>;
  /** merchant gps longitude */
  merchantGpsLongitude_Gte?: Maybe<Scalars['Float']>;
  /** merchant gps longitude */
  merchantGpsLongitude_Lte?: Maybe<Scalars['Float']>;
  /** time window from */
  timeWindowFrom?: Maybe<Scalars['CustomDateTime']>;
  /** time window from */
  timeWindowFrom_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** time window from */
  timeWindowFrom_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** time window from */
  timeWindowFrom_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** time window from */
  timeWindowFrom_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** time window to */
  timeWindowTo?: Maybe<Scalars['CustomDateTime']>;
  /** time window to */
  timeWindowTo_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** time window to */
  timeWindowTo_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** time window to */
  timeWindowTo_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** time window to */
  timeWindowTo_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cardProxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  optionalCurrencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  countryCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantCategoryCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantAddress_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantGpsLatitude_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  merchantGpsLongitude_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  timeWindowFrom_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  timeWindowTo_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  updatedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  claim?: Maybe<ClaimInputFilterObject>;
};

export type AggregatedPreauthListType = {
  __typename?: 'AggregatedPreauthListType';
  /** AggregatedPreauth list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<AggregatedPreauthType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type AggregatedPreauthListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type AggregatedPreauthType = {
  __typename?: 'AggregatedPreauthType';
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** card proxy id */
  cardProxyId?: Maybe<Scalars['String']>;
  /** claim */
  claim?: Maybe<ClaimType>;
  /** country code */
  countryCode?: Maybe<Scalars['String']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** currency code */
  currencyCode?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** merchant address */
  merchantAddress?: Maybe<Scalars['String']>;
  /** merchant category code */
  merchantCategoryCode?: Maybe<Scalars['String']>;
  /** merchant gps latitude */
  merchantGpsLatitude?: Maybe<Scalars['Float']>;
  /** merchant gps longitude */
  merchantGpsLongitude?: Maybe<Scalars['Float']>;
  /** merchant id */
  merchantId?: Maybe<Scalars['String']>;
  /** merchant name */
  merchantName?: Maybe<Scalars['String']>;
  /** optional currency code */
  optionalCurrencyCode?: Maybe<Scalars['String']>;
  /** time window from */
  timeWindowFrom?: Maybe<Scalars['CustomDateTime']>;
  /** time window to */
  timeWindowTo?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt?: Maybe<Scalars['CustomDateTime']>;
};

export type AuthenticationEntityRuleType = PaymentPlatformRuleType & {
  __typename?: 'AuthenticationEntityRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  actual?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};

export type AuthenticationMethodRuleType = PaymentPlatformRuleType & {
  __typename?: 'AuthenticationMethodRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AveragereviewdurationType = {
  __typename?: 'AveragereviewdurationType';
  today?: Maybe<DayreviewdurationfieldType>;
  lastWeek?: Maybe<DayreviewdurationfieldType>;
  lastMonth?: Maybe<DayreviewdurationfieldType>;
  allTime?: Maybe<DayreviewdurationfieldType>;
};

export type BeneficiaryBenefitUsageInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /**  current count */
  CurrentCount?: Maybe<Scalars['Int']>;
  /**  current count */
  CurrentCount_Gt?: Maybe<Scalars['Int']>;
  /**  current count */
  CurrentCount_Lt?: Maybe<Scalars['Int']>;
  /**  current count */
  CurrentCount_Gte?: Maybe<Scalars['Int']>;
  /**  current count */
  CurrentCount_Lte?: Maybe<Scalars['Int']>;
  /**  current amount */
  CurrentAmount?: Maybe<Scalars['Float']>;
  /**  current amount */
  CurrentAmount_Gt?: Maybe<Scalars['Float']>;
  /**  current amount */
  CurrentAmount_Lt?: Maybe<Scalars['Float']>;
  /**  current amount */
  CurrentAmount_Gte?: Maybe<Scalars['Float']>;
  /**  current amount */
  CurrentAmount_Lte?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered_Gt?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered_Lt?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered_Gte?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered_Lte?: Maybe<Scalars['Float']>;
  /**  current pending count */
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  /**  current pending count */
  CurrentPendingCount_Gt?: Maybe<Scalars['Int']>;
  /**  current pending count */
  CurrentPendingCount_Lt?: Maybe<Scalars['Int']>;
  /**  current pending count */
  CurrentPendingCount_Gte?: Maybe<Scalars['Int']>;
  /**  current pending count */
  CurrentPendingCount_Lte?: Maybe<Scalars['Int']>;
  /**  current pending amount */
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  /**  current pending amount */
  CurrentPendingAmount_Gt?: Maybe<Scalars['Float']>;
  /**  current pending amount */
  CurrentPendingAmount_Lt?: Maybe<Scalars['Float']>;
  /**  current pending amount */
  CurrentPendingAmount_Gte?: Maybe<Scalars['Float']>;
  /**  current pending amount */
  CurrentPendingAmount_Lte?: Maybe<Scalars['Float']>;
  /**  consumed excess */
  ConsumedExcess?: Maybe<Scalars['Float']>;
  /**  consumed excess */
  ConsumedExcess_Gt?: Maybe<Scalars['Float']>;
  /**  consumed excess */
  ConsumedExcess_Lt?: Maybe<Scalars['Float']>;
  /**  consumed excess */
  ConsumedExcess_Gte?: Maybe<Scalars['Float']>;
  /**  consumed excess */
  ConsumedExcess_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  CurrentCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  CurrentAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  CurrentAmountCovered_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  CurrentPendingCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  CurrentPendingAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  ConsumedExcess_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  sharedUsage?: Maybe<BenefitUsageInputFilterObject>;
  claims?: Maybe<ClaimInputFilterObject>;
};

export type BeneficiaryBenefitUsageListType = {
  __typename?: 'BeneficiaryBenefitUsageListType';
  /** BeneficiaryBenefitUsage list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<BeneficiaryBenefitUsageType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type BeneficiaryBenefitUsageListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Individual Benefit Usages per given Beneficiary and Policy */
export type BeneficiaryBenefitUsageType = {
  __typename?: 'BeneficiaryBenefitUsageType';
  /**  consumed excess */
  ConsumedExcess?: Maybe<Scalars['Float']>;
  /**  current amount */
  CurrentAmount?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  /**  current count */
  CurrentCount?: Maybe<Scalars['Int']>;
  /**  current pending amount */
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  /**  current pending count */
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  /** beneficiary */
  beneficiary?: Maybe<BeneficiaryType>;
  /** Claim list */
  claims?: Maybe<Array<Maybe<ClaimType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** shared usage */
  sharedUsage?: Maybe<BenefitUsageType>;
  minimalDurationEligible?: Maybe<Scalars['Boolean']>;
  currentPendingCount?: Maybe<Scalars['Int']>;
  currentPendingAmount?: Maybe<Scalars['Decimal']>;
  consumedExcess?: Maybe<Scalars['Float']>;
  remainingLimitAmount?: Maybe<Scalars['Decimal']>;
  remainingLimitCount?: Maybe<Scalars['Int']>;
};

/** Individual Benefit Usages per given Beneficiary and Policy */
export type BeneficiaryBenefitUsageTypeClaimsArgs = {
  claimicdcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<ClaimIcdCodeInputFilterObject>;
  claimcptcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimcptcode?: Maybe<ClaimCptCodeInputFilterObject>;
  breakdown_Isnull?: Maybe<Scalars['Boolean']>;
  breakdown?: Maybe<ClaimBreakdownInputFilterObject>;
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  receipts_Isnull?: Maybe<Scalars['Boolean']>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  defaultForCards_Isnull?: Maybe<Scalars['Boolean']>;
  defaultForCards?: Maybe<CardInputFilterObject>;
  aggregatedPreauths_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedPreauths?: Maybe<AggregatedPreauthInputFilterObject>;
  payments_Isnull?: Maybe<Scalars['Boolean']>;
  payments?: Maybe<PaymentInputFilterObject>;
  paymentAuthorizations_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizations?: Maybe<PaymentAuthorizationInputFilterObject>;
  account_Isnull?: Maybe<Scalars['Boolean']>;
  account?: Maybe<AccountInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchant_Isnull?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantInputFilterObject>;
  beneficiaryBenefitUsage_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryBenefitUsage?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  retroactive_Isnull?: Maybe<Scalars['Boolean']>;
  retroactive?: Maybe<Scalars['Boolean']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  maxAmount_Isnull?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Float']>;
  maxAmount_Gt?: Maybe<Scalars['Float']>;
  maxAmount_Gte?: Maybe<Scalars['Float']>;
  maxAmount_Lt?: Maybe<Scalars['Float']>;
  maxAmount_Lte?: Maybe<Scalars['Float']>;
  maxAmount_In?: Maybe<Scalars['Float']>;
  eligibleAmount_Isnull?: Maybe<Scalars['Boolean']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  eligibleAmount_Gt?: Maybe<Scalars['Float']>;
  eligibleAmount_Gte?: Maybe<Scalars['Float']>;
  eligibleAmount_Lt?: Maybe<Scalars['Float']>;
  eligibleAmount_Lte?: Maybe<Scalars['Float']>;
  eligibleAmount_In?: Maybe<Scalars['Float']>;
  transactionAmount_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAmount?: Maybe<Scalars['Float']>;
  transactionAmount_Gt?: Maybe<Scalars['Float']>;
  transactionAmount_Gte?: Maybe<Scalars['Float']>;
  transactionAmount_Lt?: Maybe<Scalars['Float']>;
  transactionAmount_Lte?: Maybe<Scalars['Float']>;
  transactionAmount_In?: Maybe<Scalars['Float']>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  isDummy_Isnull?: Maybe<Scalars['Boolean']>;
  isDummy?: Maybe<Scalars['Boolean']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  allowTransferringToFuture_Isnull?: Maybe<Scalars['Boolean']>;
  allowTransferringToFuture?: Maybe<Scalars['Boolean']>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  created_Isnull?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  created_Gt?: Maybe<Scalars['DateTime']>;
  created_Gte?: Maybe<Scalars['DateTime']>;
  created_Lt?: Maybe<Scalars['DateTime']>;
  created_Lte?: Maybe<Scalars['DateTime']>;
  created_In?: Maybe<Scalars['DateTime']>;
  updated_Isnull?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['DateTime']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  updated_In?: Maybe<Scalars['DateTime']>;
  referenceId_Isnull?: Maybe<Scalars['Boolean']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_Iexact?: Maybe<Scalars['String']>;
  referenceId_Contains?: Maybe<Scalars['String']>;
  referenceId_Icontains?: Maybe<Scalars['String']>;
  referenceId_Startswith?: Maybe<Scalars['String']>;
  referenceId_Istartswith?: Maybe<Scalars['String']>;
  referenceId_Endswith?: Maybe<Scalars['String']>;
  referenceId_Iendswith?: Maybe<Scalars['String']>;
  referenceId_In?: Maybe<Scalars['String']>;
  icdCodes_Isnull?: Maybe<Scalars['Boolean']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes_Isnull?: Maybe<Scalars['Boolean']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
  isStagnant?: Maybe<Scalars['Boolean']>;
  isInstant?: Maybe<Scalars['Boolean']>;
  benefitCategory?: Maybe<BenefitCategoryInputFilterObject>;
  benefit?: Maybe<BenefitInputFilterObject>;
  policy?: Maybe<PolicyInputFilterObject>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  beneficiaryIdWithIncludedDependants?: Maybe<Scalars['String']>;
  beneficiaryIdInWithIncludedDependants?: Maybe<Scalars['String']>;
  source_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardState?: Maybe<Scalars['String']>;
  codesExclusion?: Maybe<CodesExclusionInputFilterObject>;
};

export type BeneficiaryDataUnlockInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** used at */
  usedAt?: Maybe<Scalars['CustomDateTime']>;
  /** used at */
  usedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** used at */
  usedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** used at */
  usedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** used at */
  usedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** valid until */
  validUntil?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  usedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  isEnabled_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  validUntil_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  provider?: Maybe<ProviderInputFilterObject>;
};

export type BeneficiaryDataUnlockListType = {
  __typename?: 'BeneficiaryDataUnlockListType';
  /** BeneficiaryDataUnlock list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<BeneficiaryDataUnlockType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type BeneficiaryDataUnlockListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type BeneficiaryDataUnlockMutation = {
  __typename?: 'BeneficiaryDataUnlockMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  beneficiarydataunlock?: Maybe<BeneficiaryDataUnlockType>;
};

export type BeneficiaryDataUnlockType = {
  __typename?: 'BeneficiaryDataUnlockType';
  /** beneficiary */
  beneficiary?: Maybe<BeneficiaryType>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** provider */
  provider?: Maybe<ProviderType>;
  /** used at */
  usedAt?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil?: Maybe<Scalars['CustomDateTime']>;
};

export type BeneficiaryDisableAllUnlocksInputType = {
  id?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['ID']>;
};

export type BeneficiaryInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** member since */
  memberSince?: Maybe<Scalars['CustomDate']>;
  /** member since */
  memberSince_Gt?: Maybe<Scalars['CustomDate']>;
  /** member since */
  memberSince_Lt?: Maybe<Scalars['CustomDate']>;
  /** member since */
  memberSince_Gte?: Maybe<Scalars['CustomDate']>;
  /** member since */
  memberSince_Lte?: Maybe<Scalars['CustomDate']>;
  /** customer uuid */
  customerUuid?: Maybe<Scalars['UUID']>;
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  /** additional information */
  additionalInformation_Icontains?: Maybe<Scalars['String']>;
  mobileAppAllowed?: Maybe<Scalars['Boolean']>;
  cardAllowed?: Maybe<Scalars['Boolean']>;
  /** token */
  token?: Maybe<Scalars['String']>;
  /** token */
  token_Icontains?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerInputType>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isActive_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  memberSince_In?: Maybe<Array<Maybe<Scalars['CustomDate']>>>;
  customerUuid_In?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  additionalInformation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  mobileAppAllowed_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  cardAllowed_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  token_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  customer_In?: Maybe<Array<Maybe<CustomerInputType>>>;
  parents?: Maybe<BeneficiaryInputFilterObject>;
  dependants?: Maybe<BeneficiaryInputFilterObject>;
  policyHolder?: Maybe<PolicyHolderInputFilterObject>;
  beneficiaries?: Maybe<BeneficiaryInputFilterObject>;
  policies?: Maybe<PolicyInputFilterObject>;
  usages?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedClaims?: Maybe<ClaimInputFilterObject>;
  cards?: Maybe<CardInputFilterObject>;
  currentCardsForPayments?: Maybe<CardInputFilterObject>;
  cardActions?: Maybe<CardActionInputFilterObject>;
  mobileAppUser?: Maybe<MobileAppUserInputFilterObject>;
  paymentRulesOverrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
  unlocks?: Maybe<BeneficiaryDataUnlockInputFilterObject>;
};

export type BeneficiaryListType = {
  __typename?: 'BeneficiaryListType';
  /** Beneficiary list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<BeneficiaryType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type BeneficiaryListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type BeneficiaryMutation = {
  __typename?: 'BeneficiaryMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  beneficiary?: Maybe<BeneficiaryType>;
};

export type BeneficiaryPaymentInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** state */
  state?: Maybe<Scalars['String']>;
  /** state */
  state_Icontains?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  claim?: Maybe<ClaimInputFilterObject>;
  paymentPtr?: Maybe<PaymentInputFilterObject>;
  personalCard?: Maybe<PersonalCardInputFilterObject>;
};

export type BeneficiaryPaymentListType = PaymentInterface & {
  __typename?: 'BeneficiaryPaymentListType';
  /** BeneficiaryPayment list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<BeneficiaryPaymentType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  /** claim */
  claim?: Maybe<ClaimType>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** state */
  state?: Maybe<PaymentStateEnum>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
};

export type BeneficiaryPaymentListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type BeneficiaryPaymentType = PaymentInterface & {
  __typename?: 'BeneficiaryPaymentType';
  amount?: Maybe<Scalars['Float']>;
  /** claim */
  claim?: Maybe<ClaimType>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** personal card */
  personalCard?: Maybe<PersonalCardType>;
  /** state */
  state?: Maybe<PaymentStateEnum>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryType = {
  __typename?: 'BeneficiaryType';
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  /** CardAction list */
  cardActions?: Maybe<Array<Maybe<CardActionType>>>;
  cardAllowed?: Maybe<Scalars['Boolean']>;
  /** Card list */
  cards?: Maybe<Array<Maybe<CardType>>>;
  /** Card list */
  currentCardsForPayments?: Maybe<Array<Maybe<CardType>>>;
  /** customer uuid */
  customerUuid?: Maybe<Scalars['UUID']>;
  /** Beneficiary list */
  dependants?: Maybe<Array<Maybe<BeneficiaryType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  /** member since */
  memberSince?: Maybe<Scalars['CustomDate']>;
  mobileAppAllowed?: Maybe<Scalars['Boolean']>;
  mobileAppUser?: Maybe<MobileAppUserType>;
  /** Beneficiary list */
  parents?: Maybe<Array<Maybe<BeneficiaryType>>>;
  /** PaymentRulesOverride list */
  paymentRulesOverrides?: Maybe<Array<Maybe<PaymentRulesOverrideType>>>;
  /** Policy list */
  policies?: Maybe<Array<Maybe<PolicyType>>>;
  policyHolder?: Maybe<PolicyHolderType>;
  /** Claim list */
  requestedClaims?: Maybe<Array<Maybe<ClaimType>>>;
  /** token */
  token?: Maybe<Scalars['String']>;
  /** BeneficiaryDataUnlock list */
  unlocks?: Maybe<Array<Maybe<BeneficiaryDataUnlockType>>>;
  /** BeneficiaryBenefitUsage list */
  usages?: Maybe<Array<Maybe<BeneficiaryBenefitUsageType>>>;
  isLeading?: Maybe<Scalars['Boolean']>;
  hasDummyClaims?: Maybe<Scalars['Boolean']>;
  customer?: Maybe<CustomerType>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypeCardActionsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  card_Isnull?: Maybe<Scalars['Boolean']>;
  card?: Maybe<CardInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  created_Isnull?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  created_Gt?: Maybe<Scalars['DateTime']>;
  created_Gte?: Maybe<Scalars['DateTime']>;
  created_Lt?: Maybe<Scalars['DateTime']>;
  created_Lte?: Maybe<Scalars['DateTime']>;
  created_In?: Maybe<Scalars['DateTime']>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  action_Isnull?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_Iexact?: Maybe<Scalars['String']>;
  action_Contains?: Maybe<Scalars['String']>;
  action_Icontains?: Maybe<Scalars['String']>;
  action_Startswith?: Maybe<Scalars['String']>;
  action_Istartswith?: Maybe<Scalars['String']>;
  action_Endswith?: Maybe<Scalars['String']>;
  action_Iendswith?: Maybe<Scalars['String']>;
  action_In?: Maybe<Scalars['String']>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypeCardsArgs = {
  actions_Isnull?: Maybe<Scalars['Boolean']>;
  actions?: Maybe<CardActionInputFilterObject>;
  aggregatedbeneficiarycard_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedbeneficiarycard?: Maybe<AggregatedBeneficiaryCardInputFilterObject>;
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  proxyId_Isnull?: Maybe<Scalars['Boolean']>;
  proxyId?: Maybe<Scalars['String']>;
  proxyId_Iexact?: Maybe<Scalars['String']>;
  proxyId_Contains?: Maybe<Scalars['String']>;
  proxyId_Icontains?: Maybe<Scalars['String']>;
  proxyId_Startswith?: Maybe<Scalars['String']>;
  proxyId_Istartswith?: Maybe<Scalars['String']>;
  proxyId_Endswith?: Maybe<Scalars['String']>;
  proxyId_Iendswith?: Maybe<Scalars['String']>;
  proxyId_In?: Maybe<Scalars['String']>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  nameOnCard_Isnull?: Maybe<Scalars['Boolean']>;
  nameOnCard?: Maybe<Scalars['String']>;
  nameOnCard_Iexact?: Maybe<Scalars['String']>;
  nameOnCard_Contains?: Maybe<Scalars['String']>;
  nameOnCard_Icontains?: Maybe<Scalars['String']>;
  nameOnCard_Startswith?: Maybe<Scalars['String']>;
  nameOnCard_Istartswith?: Maybe<Scalars['String']>;
  nameOnCard_Endswith?: Maybe<Scalars['String']>;
  nameOnCard_Iendswith?: Maybe<Scalars['String']>;
  nameOnCard_In?: Maybe<Scalars['String']>;
  lastDigits_Isnull?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastDigits_Iexact?: Maybe<Scalars['String']>;
  lastDigits_Contains?: Maybe<Scalars['String']>;
  lastDigits_Icontains?: Maybe<Scalars['String']>;
  lastDigits_Startswith?: Maybe<Scalars['String']>;
  lastDigits_Istartswith?: Maybe<Scalars['String']>;
  lastDigits_Endswith?: Maybe<Scalars['String']>;
  lastDigits_Iendswith?: Maybe<Scalars['String']>;
  lastDigits_In?: Maybe<Scalars['String']>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['String']>;
  expiration_Iexact?: Maybe<Scalars['String']>;
  expiration_Contains?: Maybe<Scalars['String']>;
  expiration_Icontains?: Maybe<Scalars['String']>;
  expiration_Startswith?: Maybe<Scalars['String']>;
  expiration_Istartswith?: Maybe<Scalars['String']>;
  expiration_Endswith?: Maybe<Scalars['String']>;
  expiration_Iendswith?: Maybe<Scalars['String']>;
  expiration_In?: Maybe<Scalars['String']>;
  policyChain_Isnull?: Maybe<Scalars['Boolean']>;
  policyChain?: Maybe<PolicyChainInputFilterObject>;
  isFrozen_Isnull?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isBlocked_Isnull?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeactivated_Isnull?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isCancelled_Isnull?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isIssued_Isnull?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered_Isnull?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  defaultClaim_Isnull?: Maybe<Scalars['Boolean']>;
  defaultClaim?: Maybe<ClaimInputFilterObject>;
  currentBeneficiaryForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentBeneficiaryForPayments?: Maybe<BeneficiaryInputFilterObject>;
  cardType_Isnull?: Maybe<Scalars['Boolean']>;
  cardType?: Maybe<Scalars['String']>;
  cardType_Iexact?: Maybe<Scalars['String']>;
  cardType_Contains?: Maybe<Scalars['String']>;
  cardType_Icontains?: Maybe<Scalars['String']>;
  cardType_Startswith?: Maybe<Scalars['String']>;
  cardType_Istartswith?: Maybe<Scalars['String']>;
  cardType_Endswith?: Maybe<Scalars['String']>;
  cardType_Iendswith?: Maybe<Scalars['String']>;
  cardType_In?: Maybe<Scalars['String']>;
  aggregationProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationProcessedAt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedAt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_In?: Maybe<Scalars['Int']>;
  idForPublicRetrieval_Isnull?: Maybe<Scalars['Boolean']>;
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  sentSmses_Isnull?: Maybe<Scalars['Boolean']>;
  sentSmses?: Maybe<SmsInputFilterObject>;
  policyId?: Maybe<Scalars['String']>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypeCurrentCardsForPaymentsArgs = {
  actions_Isnull?: Maybe<Scalars['Boolean']>;
  actions?: Maybe<CardActionInputFilterObject>;
  aggregatedbeneficiarycard_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedbeneficiarycard?: Maybe<AggregatedBeneficiaryCardInputFilterObject>;
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  proxyId_Isnull?: Maybe<Scalars['Boolean']>;
  proxyId?: Maybe<Scalars['String']>;
  proxyId_Iexact?: Maybe<Scalars['String']>;
  proxyId_Contains?: Maybe<Scalars['String']>;
  proxyId_Icontains?: Maybe<Scalars['String']>;
  proxyId_Startswith?: Maybe<Scalars['String']>;
  proxyId_Istartswith?: Maybe<Scalars['String']>;
  proxyId_Endswith?: Maybe<Scalars['String']>;
  proxyId_Iendswith?: Maybe<Scalars['String']>;
  proxyId_In?: Maybe<Scalars['String']>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  nameOnCard_Isnull?: Maybe<Scalars['Boolean']>;
  nameOnCard?: Maybe<Scalars['String']>;
  nameOnCard_Iexact?: Maybe<Scalars['String']>;
  nameOnCard_Contains?: Maybe<Scalars['String']>;
  nameOnCard_Icontains?: Maybe<Scalars['String']>;
  nameOnCard_Startswith?: Maybe<Scalars['String']>;
  nameOnCard_Istartswith?: Maybe<Scalars['String']>;
  nameOnCard_Endswith?: Maybe<Scalars['String']>;
  nameOnCard_Iendswith?: Maybe<Scalars['String']>;
  nameOnCard_In?: Maybe<Scalars['String']>;
  lastDigits_Isnull?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastDigits_Iexact?: Maybe<Scalars['String']>;
  lastDigits_Contains?: Maybe<Scalars['String']>;
  lastDigits_Icontains?: Maybe<Scalars['String']>;
  lastDigits_Startswith?: Maybe<Scalars['String']>;
  lastDigits_Istartswith?: Maybe<Scalars['String']>;
  lastDigits_Endswith?: Maybe<Scalars['String']>;
  lastDigits_Iendswith?: Maybe<Scalars['String']>;
  lastDigits_In?: Maybe<Scalars['String']>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['String']>;
  expiration_Iexact?: Maybe<Scalars['String']>;
  expiration_Contains?: Maybe<Scalars['String']>;
  expiration_Icontains?: Maybe<Scalars['String']>;
  expiration_Startswith?: Maybe<Scalars['String']>;
  expiration_Istartswith?: Maybe<Scalars['String']>;
  expiration_Endswith?: Maybe<Scalars['String']>;
  expiration_Iendswith?: Maybe<Scalars['String']>;
  expiration_In?: Maybe<Scalars['String']>;
  policyChain_Isnull?: Maybe<Scalars['Boolean']>;
  policyChain?: Maybe<PolicyChainInputFilterObject>;
  isFrozen_Isnull?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isBlocked_Isnull?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeactivated_Isnull?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isCancelled_Isnull?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isIssued_Isnull?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered_Isnull?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  defaultClaim_Isnull?: Maybe<Scalars['Boolean']>;
  defaultClaim?: Maybe<ClaimInputFilterObject>;
  currentBeneficiaryForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentBeneficiaryForPayments?: Maybe<BeneficiaryInputFilterObject>;
  cardType_Isnull?: Maybe<Scalars['Boolean']>;
  cardType?: Maybe<Scalars['String']>;
  cardType_Iexact?: Maybe<Scalars['String']>;
  cardType_Contains?: Maybe<Scalars['String']>;
  cardType_Icontains?: Maybe<Scalars['String']>;
  cardType_Startswith?: Maybe<Scalars['String']>;
  cardType_Istartswith?: Maybe<Scalars['String']>;
  cardType_Endswith?: Maybe<Scalars['String']>;
  cardType_Iendswith?: Maybe<Scalars['String']>;
  cardType_In?: Maybe<Scalars['String']>;
  aggregationProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationProcessedAt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedAt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_In?: Maybe<Scalars['Int']>;
  idForPublicRetrieval_Isnull?: Maybe<Scalars['Boolean']>;
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  sentSmses_Isnull?: Maybe<Scalars['Boolean']>;
  sentSmses?: Maybe<SmsInputFilterObject>;
  policyId?: Maybe<Scalars['String']>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypeDependantsArgs = {
  dependants_Isnull?: Maybe<Scalars['Boolean']>;
  dependants?: Maybe<BeneficiaryInputFilterObject>;
  policyHolder_Isnull?: Maybe<Scalars['Boolean']>;
  policyHolder?: Maybe<PolicyHolderInputFilterObject>;
  policies_Isnull?: Maybe<Scalars['Boolean']>;
  policies?: Maybe<PolicyInputFilterObject>;
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedClaims_Isnull?: Maybe<Scalars['Boolean']>;
  requestedClaims?: Maybe<ClaimInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  currentCardsForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentCardsForPayments?: Maybe<CardInputFilterObject>;
  cardActions_Isnull?: Maybe<Scalars['Boolean']>;
  cardActions?: Maybe<CardActionInputFilterObject>;
  mobileAppUser_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppUser?: Maybe<MobileAppUserInputFilterObject>;
  paymentRulesOverrides_Isnull?: Maybe<Scalars['Boolean']>;
  paymentRulesOverrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
  unlocks_Isnull?: Maybe<Scalars['Boolean']>;
  unlocks?: Maybe<BeneficiaryDataUnlockInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  memberSince_Isnull?: Maybe<Scalars['Boolean']>;
  memberSince?: Maybe<Scalars['Date']>;
  memberSince_Gt?: Maybe<Scalars['Date']>;
  memberSince_Gte?: Maybe<Scalars['Date']>;
  memberSince_Lt?: Maybe<Scalars['Date']>;
  memberSince_Lte?: Maybe<Scalars['Date']>;
  memberSince_In?: Maybe<Scalars['Date']>;
  customerUuid_Isnull?: Maybe<Scalars['Boolean']>;
  customerUuid?: Maybe<Scalars['UUID']>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  mobileAppAllowed_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppAllowed?: Maybe<Scalars['Boolean']>;
  cardAllowed_Isnull?: Maybe<Scalars['Boolean']>;
  cardAllowed?: Maybe<Scalars['Boolean']>;
  token_Isnull?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  token_Iexact?: Maybe<Scalars['String']>;
  token_Contains?: Maybe<Scalars['String']>;
  token_Icontains?: Maybe<Scalars['String']>;
  token_Startswith?: Maybe<Scalars['String']>;
  token_Istartswith?: Maybe<Scalars['String']>;
  token_Endswith?: Maybe<Scalars['String']>;
  token_Iendswith?: Maybe<Scalars['String']>;
  token_In?: Maybe<Scalars['String']>;
  parents_Isnull?: Maybe<Scalars['Boolean']>;
  parents?: Maybe<BeneficiaryInputFilterObject>;
  beneficiaries?: Maybe<BeneficiaryInputFilterObject>;
  customer?: Maybe<CustomerInputType>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypeParentsArgs = {
  dependants_Isnull?: Maybe<Scalars['Boolean']>;
  dependants?: Maybe<BeneficiaryInputFilterObject>;
  policyHolder_Isnull?: Maybe<Scalars['Boolean']>;
  policyHolder?: Maybe<PolicyHolderInputFilterObject>;
  policies_Isnull?: Maybe<Scalars['Boolean']>;
  policies?: Maybe<PolicyInputFilterObject>;
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedClaims_Isnull?: Maybe<Scalars['Boolean']>;
  requestedClaims?: Maybe<ClaimInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  currentCardsForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentCardsForPayments?: Maybe<CardInputFilterObject>;
  cardActions_Isnull?: Maybe<Scalars['Boolean']>;
  cardActions?: Maybe<CardActionInputFilterObject>;
  mobileAppUser_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppUser?: Maybe<MobileAppUserInputFilterObject>;
  paymentRulesOverrides_Isnull?: Maybe<Scalars['Boolean']>;
  paymentRulesOverrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
  unlocks_Isnull?: Maybe<Scalars['Boolean']>;
  unlocks?: Maybe<BeneficiaryDataUnlockInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  memberSince_Isnull?: Maybe<Scalars['Boolean']>;
  memberSince?: Maybe<Scalars['Date']>;
  memberSince_Gt?: Maybe<Scalars['Date']>;
  memberSince_Gte?: Maybe<Scalars['Date']>;
  memberSince_Lt?: Maybe<Scalars['Date']>;
  memberSince_Lte?: Maybe<Scalars['Date']>;
  memberSince_In?: Maybe<Scalars['Date']>;
  customerUuid_Isnull?: Maybe<Scalars['Boolean']>;
  customerUuid?: Maybe<Scalars['UUID']>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  mobileAppAllowed_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppAllowed?: Maybe<Scalars['Boolean']>;
  cardAllowed_Isnull?: Maybe<Scalars['Boolean']>;
  cardAllowed?: Maybe<Scalars['Boolean']>;
  token_Isnull?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  token_Iexact?: Maybe<Scalars['String']>;
  token_Contains?: Maybe<Scalars['String']>;
  token_Icontains?: Maybe<Scalars['String']>;
  token_Startswith?: Maybe<Scalars['String']>;
  token_Istartswith?: Maybe<Scalars['String']>;
  token_Endswith?: Maybe<Scalars['String']>;
  token_Iendswith?: Maybe<Scalars['String']>;
  token_In?: Maybe<Scalars['String']>;
  parents_Isnull?: Maybe<Scalars['Boolean']>;
  parents?: Maybe<BeneficiaryInputFilterObject>;
  beneficiaries?: Maybe<BeneficiaryInputFilterObject>;
  customer?: Maybe<CustomerInputType>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypePaymentRulesOverridesArgs = {
  paymentauthorizationattempt_Isnull?: Maybe<Scalars['Boolean']>;
  paymentauthorizationattempt?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProxyId_Isnull?: Maybe<Scalars['Boolean']>;
  cardProxyId?: Maybe<Scalars['String']>;
  cardProxyId_Iexact?: Maybe<Scalars['String']>;
  cardProxyId_Contains?: Maybe<Scalars['String']>;
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  cardProxyId_Startswith?: Maybe<Scalars['String']>;
  cardProxyId_Istartswith?: Maybe<Scalars['String']>;
  cardProxyId_Endswith?: Maybe<Scalars['String']>;
  cardProxyId_Iendswith?: Maybe<Scalars['String']>;
  cardProxyId_In?: Maybe<Scalars['String']>;
  overrideType_Isnull?: Maybe<Scalars['Boolean']>;
  overrideType?: Maybe<Scalars['String']>;
  overrideType_Iexact?: Maybe<Scalars['String']>;
  overrideType_Contains?: Maybe<Scalars['String']>;
  overrideType_Icontains?: Maybe<Scalars['String']>;
  overrideType_Startswith?: Maybe<Scalars['String']>;
  overrideType_Istartswith?: Maybe<Scalars['String']>;
  overrideType_Endswith?: Maybe<Scalars['String']>;
  overrideType_Iendswith?: Maybe<Scalars['String']>;
  overrideType_In?: Maybe<Scalars['String']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  validUntil_Isnull?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_Gt?: Maybe<Scalars['DateTime']>;
  validUntil_Gte?: Maybe<Scalars['DateTime']>;
  validUntil_Lt?: Maybe<Scalars['DateTime']>;
  validUntil_Lte?: Maybe<Scalars['DateTime']>;
  validUntil_In?: Maybe<Scalars['DateTime']>;
  validNTimes_Isnull?: Maybe<Scalars['Boolean']>;
  validNTimes?: Maybe<Scalars['Int']>;
  validNTimes_Gt?: Maybe<Scalars['Int']>;
  validNTimes_Gte?: Maybe<Scalars['Int']>;
  validNTimes_Lt?: Maybe<Scalars['Int']>;
  validNTimes_Lte?: Maybe<Scalars['Int']>;
  validNTimes_In?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  specificationQuery?: Maybe<Scalars['String']>;
  overrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypePoliciesArgs = {
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BenefitUsageInputFilterObject>;
  sessions_Isnull?: Maybe<Scalars['Boolean']>;
  sessions?: Maybe<MobileAppSessionInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Scalars['String']>;
  chain_Isnull?: Maybe<Scalars['Boolean']>;
  chain?: Maybe<PolicyChainInputFilterObject>;
  productVersion_Isnull?: Maybe<Scalars['Boolean']>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['DateTime']>;
  expiration_Gt?: Maybe<Scalars['DateTime']>;
  expiration_Gte?: Maybe<Scalars['DateTime']>;
  expiration_Lt?: Maybe<Scalars['DateTime']>;
  expiration_Lte?: Maybe<Scalars['DateTime']>;
  expiration_In?: Maybe<Scalars['DateTime']>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  beneficiaries_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaries?: Maybe<BeneficiaryInputFilterObject>;
  externalId_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypeRequestedClaimsArgs = {
  claimicdcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<ClaimIcdCodeInputFilterObject>;
  claimcptcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimcptcode?: Maybe<ClaimCptCodeInputFilterObject>;
  breakdown_Isnull?: Maybe<Scalars['Boolean']>;
  breakdown?: Maybe<ClaimBreakdownInputFilterObject>;
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  receipts_Isnull?: Maybe<Scalars['Boolean']>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  defaultForCards_Isnull?: Maybe<Scalars['Boolean']>;
  defaultForCards?: Maybe<CardInputFilterObject>;
  aggregatedPreauths_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedPreauths?: Maybe<AggregatedPreauthInputFilterObject>;
  payments_Isnull?: Maybe<Scalars['Boolean']>;
  payments?: Maybe<PaymentInputFilterObject>;
  paymentAuthorizations_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizations?: Maybe<PaymentAuthorizationInputFilterObject>;
  account_Isnull?: Maybe<Scalars['Boolean']>;
  account?: Maybe<AccountInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchant_Isnull?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantInputFilterObject>;
  beneficiaryBenefitUsage_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryBenefitUsage?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  retroactive_Isnull?: Maybe<Scalars['Boolean']>;
  retroactive?: Maybe<Scalars['Boolean']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  maxAmount_Isnull?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Float']>;
  maxAmount_Gt?: Maybe<Scalars['Float']>;
  maxAmount_Gte?: Maybe<Scalars['Float']>;
  maxAmount_Lt?: Maybe<Scalars['Float']>;
  maxAmount_Lte?: Maybe<Scalars['Float']>;
  maxAmount_In?: Maybe<Scalars['Float']>;
  eligibleAmount_Isnull?: Maybe<Scalars['Boolean']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  eligibleAmount_Gt?: Maybe<Scalars['Float']>;
  eligibleAmount_Gte?: Maybe<Scalars['Float']>;
  eligibleAmount_Lt?: Maybe<Scalars['Float']>;
  eligibleAmount_Lte?: Maybe<Scalars['Float']>;
  eligibleAmount_In?: Maybe<Scalars['Float']>;
  transactionAmount_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAmount?: Maybe<Scalars['Float']>;
  transactionAmount_Gt?: Maybe<Scalars['Float']>;
  transactionAmount_Gte?: Maybe<Scalars['Float']>;
  transactionAmount_Lt?: Maybe<Scalars['Float']>;
  transactionAmount_Lte?: Maybe<Scalars['Float']>;
  transactionAmount_In?: Maybe<Scalars['Float']>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  isDummy_Isnull?: Maybe<Scalars['Boolean']>;
  isDummy?: Maybe<Scalars['Boolean']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  allowTransferringToFuture_Isnull?: Maybe<Scalars['Boolean']>;
  allowTransferringToFuture?: Maybe<Scalars['Boolean']>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  created_Isnull?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  created_Gt?: Maybe<Scalars['DateTime']>;
  created_Gte?: Maybe<Scalars['DateTime']>;
  created_Lt?: Maybe<Scalars['DateTime']>;
  created_Lte?: Maybe<Scalars['DateTime']>;
  created_In?: Maybe<Scalars['DateTime']>;
  updated_Isnull?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['DateTime']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  updated_In?: Maybe<Scalars['DateTime']>;
  referenceId_Isnull?: Maybe<Scalars['Boolean']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_Iexact?: Maybe<Scalars['String']>;
  referenceId_Contains?: Maybe<Scalars['String']>;
  referenceId_Icontains?: Maybe<Scalars['String']>;
  referenceId_Startswith?: Maybe<Scalars['String']>;
  referenceId_Istartswith?: Maybe<Scalars['String']>;
  referenceId_Endswith?: Maybe<Scalars['String']>;
  referenceId_Iendswith?: Maybe<Scalars['String']>;
  referenceId_In?: Maybe<Scalars['String']>;
  icdCodes_Isnull?: Maybe<Scalars['Boolean']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes_Isnull?: Maybe<Scalars['Boolean']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
  isStagnant?: Maybe<Scalars['Boolean']>;
  isInstant?: Maybe<Scalars['Boolean']>;
  benefitCategory?: Maybe<BenefitCategoryInputFilterObject>;
  benefit?: Maybe<BenefitInputFilterObject>;
  policy?: Maybe<PolicyInputFilterObject>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  beneficiaryIdWithIncludedDependants?: Maybe<Scalars['String']>;
  beneficiaryIdInWithIncludedDependants?: Maybe<Scalars['String']>;
  source_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardState?: Maybe<Scalars['String']>;
  codesExclusion?: Maybe<CodesExclusionInputFilterObject>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypeUnlocksArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  usedAt_Isnull?: Maybe<Scalars['Boolean']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  usedAt_Gt?: Maybe<Scalars['DateTime']>;
  usedAt_Gte?: Maybe<Scalars['DateTime']>;
  usedAt_Lt?: Maybe<Scalars['DateTime']>;
  usedAt_Lte?: Maybe<Scalars['DateTime']>;
  usedAt_In?: Maybe<Scalars['DateTime']>;
  isEnabled_Isnull?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  validUntil_Isnull?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_Gt?: Maybe<Scalars['DateTime']>;
  validUntil_Gte?: Maybe<Scalars['DateTime']>;
  validUntil_Lt?: Maybe<Scalars['DateTime']>;
  validUntil_Lte?: Maybe<Scalars['DateTime']>;
  validUntil_In?: Maybe<Scalars['DateTime']>;
};

/**
 * Beneficiaries with filtered Policies, that are accessible within current session.
 *
 *
 * Path hints:
 * ----------
 * - external_ids: `beneficiary.policies.external_id`
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 */
export type BeneficiaryTypeUsagesArgs = {
  claims_Isnull?: Maybe<Scalars['Boolean']>;
  claims?: Maybe<ClaimInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  sharedUsage_Isnull?: Maybe<Scalars['Boolean']>;
  sharedUsage?: Maybe<BenefitUsageInputFilterObject>;
  CurrentCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentCount?: Maybe<Scalars['Int']>;
  CurrentCount_Gt?: Maybe<Scalars['Int']>;
  CurrentCount_Gte?: Maybe<Scalars['Int']>;
  CurrentCount_Lt?: Maybe<Scalars['Int']>;
  CurrentCount_Lte?: Maybe<Scalars['Int']>;
  CurrentCount_In?: Maybe<Scalars['Int']>;
  CurrentAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmount?: Maybe<Scalars['Float']>;
  CurrentAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentAmount_In?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_In?: Maybe<Scalars['Float']>;
  CurrentPendingCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_In?: Maybe<Scalars['Int']>;
  CurrentPendingAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_In?: Maybe<Scalars['Float']>;
  ConsumedExcess_Isnull?: Maybe<Scalars['Boolean']>;
  ConsumedExcess?: Maybe<Scalars['Float']>;
  ConsumedExcess_Gt?: Maybe<Scalars['Float']>;
  ConsumedExcess_Gte?: Maybe<Scalars['Float']>;
  ConsumedExcess_Lt?: Maybe<Scalars['Float']>;
  ConsumedExcess_Lte?: Maybe<Scalars['Float']>;
  ConsumedExcess_In?: Maybe<Scalars['Float']>;
};

/** Checks whether a matching benefit has a suitably high limit. */
export type BenefitAmountRuleType = PaymentPlatformRuleType & {
  __typename?: 'BenefitAmountRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
};

export type BenefitCategoryInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** icon */
  icon?: Maybe<Scalars['String']>;
  /** icon */
  icon_Icontains?: Maybe<Scalars['String']>;
  /** title */
  title?: Maybe<Scalars['String']>;
  /** title */
  title_Icontains?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  description_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  icon_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer?: Maybe<InsurerInputFilterObject>;
  merchantCategories?: Maybe<MerchantCategoryInputFilterObject>;
  benefits?: Maybe<BenefitInputFilterObject>;
};

export type BenefitCategoryListType = {
  __typename?: 'BenefitCategoryListType';
  /** BenefitCategory list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<BenefitCategoryType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type BenefitCategoryListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type BenefitCategoryType = {
  __typename?: 'BenefitCategoryType';
  /** Benefit list */
  benefits?: Maybe<Array<Maybe<BenefitType>>>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** icon */
  icon?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** insurer */
  insurer?: Maybe<InsurerType>;
  /** MerchantCategory list */
  merchantCategories?: Maybe<Array<Maybe<MerchantCategoryType>>>;
  /** title */
  title?: Maybe<Scalars['String']>;
};

export type BenefitCategoryTypeBenefitsArgs = {
  children_Isnull?: Maybe<Scalars['Boolean']>;
  children?: Maybe<BenefitInputFilterObject>;
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BenefitUsageInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVersion_Isnull?: Maybe<Scalars['Boolean']>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<BenefitInputFilterObject>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category?: Maybe<BenefitCategoryInputFilterObject>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  excess_Isnull?: Maybe<Scalars['Boolean']>;
  excess?: Maybe<Scalars['Float']>;
  excess_Gt?: Maybe<Scalars['Float']>;
  excess_Gte?: Maybe<Scalars['Float']>;
  excess_Lt?: Maybe<Scalars['Float']>;
  excess_Lte?: Maybe<Scalars['Float']>;
  excess_In?: Maybe<Scalars['Float']>;
  limitCount_Isnull?: Maybe<Scalars['Boolean']>;
  limitCount?: Maybe<Scalars['Int']>;
  limitCount_Gt?: Maybe<Scalars['Int']>;
  limitCount_Gte?: Maybe<Scalars['Int']>;
  limitCount_Lt?: Maybe<Scalars['Int']>;
  limitCount_Lte?: Maybe<Scalars['Int']>;
  limitCount_In?: Maybe<Scalars['Int']>;
  limitAmount_Isnull?: Maybe<Scalars['Boolean']>;
  limitAmount?: Maybe<Scalars['Float']>;
  limitAmount_Gt?: Maybe<Scalars['Float']>;
  limitAmount_Gte?: Maybe<Scalars['Float']>;
  limitAmount_Lt?: Maybe<Scalars['Float']>;
  limitAmount_Lte?: Maybe<Scalars['Float']>;
  limitAmount_In?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryLimitCount?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Gt?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Gte?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Lt?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Lte?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_In?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryLimitAmount?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Gt?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Gte?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Lt?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Lte?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_In?: Maybe<Scalars['Float']>;
  renewalAtYearsEnd_Isnull?: Maybe<Scalars['Boolean']>;
  renewalAtYearsEnd?: Maybe<Scalars['Boolean']>;
  coPayment_Isnull?: Maybe<Scalars['Boolean']>;
  coPayment?: Maybe<Scalars['Int']>;
  coPayment_Gt?: Maybe<Scalars['Int']>;
  coPayment_Gte?: Maybe<Scalars['Int']>;
  coPayment_Lt?: Maybe<Scalars['Int']>;
  coPayment_Lte?: Maybe<Scalars['Int']>;
  coPayment_In?: Maybe<Scalars['Int']>;
  coPaymentMax_Isnull?: Maybe<Scalars['Boolean']>;
  coPaymentMax?: Maybe<Scalars['Float']>;
  coPaymentMax_Gt?: Maybe<Scalars['Float']>;
  coPaymentMax_Gte?: Maybe<Scalars['Float']>;
  coPaymentMax_Lt?: Maybe<Scalars['Float']>;
  coPaymentMax_Lte?: Maybe<Scalars['Float']>;
  coPaymentMax_In?: Maybe<Scalars['Float']>;
  minimalDurationDays_Isnull?: Maybe<Scalars['Boolean']>;
  minimalDurationDays?: Maybe<Scalars['Int']>;
  minimalDurationDays_Gt?: Maybe<Scalars['Int']>;
  minimalDurationDays_Gte?: Maybe<Scalars['Int']>;
  minimalDurationDays_Lt?: Maybe<Scalars['Int']>;
  minimalDurationDays_Lte?: Maybe<Scalars['Int']>;
  minimalDurationDays_In?: Maybe<Scalars['Int']>;
  claimId?: Maybe<Scalars['String']>;
};

export type BenefitCategoryTypeMerchantCategoriesArgs = {
  benefitCategories_Isnull?: Maybe<Scalars['Boolean']>;
  benefitCategories?: Maybe<BenefitCategoryInputFilterObject>;
  merchant_Isnull?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  merchantCategories?: Maybe<MerchantCategoryInputFilterObject>;
  categories?: Maybe<MerchantCategoryInputFilterObject>;
};

/** Checks whether a matching benefit has a suitably high limit. */
export type BenefitCountRuleType = PaymentPlatformRuleType & {
  __typename?: 'BenefitCountRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
};

export type BenefitInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** title */
  title?: Maybe<Scalars['String']>;
  /** title */
  title_Icontains?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  description_Icontains?: Maybe<Scalars['String']>;
  /** amount in product currency */
  excess?: Maybe<Scalars['Float']>;
  /** amount in product currency */
  excess_Gt?: Maybe<Scalars['Float']>;
  /** amount in product currency */
  excess_Lt?: Maybe<Scalars['Float']>;
  /** amount in product currency */
  excess_Gte?: Maybe<Scalars['Float']>;
  /** amount in product currency */
  excess_Lte?: Maybe<Scalars['Float']>;
  /** limit number of payments, when -1, limit is not set */
  limitCount?: Maybe<Scalars['Int']>;
  /** limit number of payments, when -1, limit is not set */
  limitCount_Gt?: Maybe<Scalars['Int']>;
  /** limit number of payments, when -1, limit is not set */
  limitCount_Lt?: Maybe<Scalars['Int']>;
  /** limit number of payments, when -1, limit is not set */
  limitCount_Gte?: Maybe<Scalars['Int']>;
  /** limit number of payments, when -1, limit is not set */
  limitCount_Lte?: Maybe<Scalars['Int']>;
  /** amount in product currency, when -1, limit is not set */
  _limitAmount?: Maybe<Scalars['Float']>;
  /** amount in product currency, when -1, limit is not set */
  limitAmount_Gt?: Maybe<Scalars['Float']>;
  /** amount in product currency, when -1, limit is not set */
  limitAmount_Lt?: Maybe<Scalars['Float']>;
  /** amount in product currency, when -1, limit is not set */
  limitAmount_Gte?: Maybe<Scalars['Float']>;
  /** amount in product currency, when -1, limit is not set */
  limitAmount_Lte?: Maybe<Scalars['Float']>;
  /** limit number of payments per beneficiary, when -1, limit is not set */
  beneficiaryLimitCount?: Maybe<Scalars['Float']>;
  /** limit number of payments per beneficiary, when -1, limit is not set */
  beneficiaryLimitCount_Gt?: Maybe<Scalars['Float']>;
  /** limit number of payments per beneficiary, when -1, limit is not set */
  beneficiaryLimitCount_Lt?: Maybe<Scalars['Float']>;
  /** limit number of payments per beneficiary, when -1, limit is not set */
  beneficiaryLimitCount_Gte?: Maybe<Scalars['Float']>;
  /** limit number of payments per beneficiary, when -1, limit is not set */
  beneficiaryLimitCount_Lte?: Maybe<Scalars['Float']>;
  /** amount in GBP, when -1, limit is not set */
  beneficiaryLimitAmount?: Maybe<Scalars['Float']>;
  /** amount in GBP, when -1, limit is not set */
  beneficiaryLimitAmount_Gt?: Maybe<Scalars['Float']>;
  /** amount in GBP, when -1, limit is not set */
  beneficiaryLimitAmount_Lt?: Maybe<Scalars['Float']>;
  /** amount in GBP, when -1, limit is not set */
  beneficiaryLimitAmount_Gte?: Maybe<Scalars['Float']>;
  /** amount in GBP, when -1, limit is not set */
  beneficiaryLimitAmount_Lte?: Maybe<Scalars['Float']>;
  renewalAtYearsEnd?: Maybe<Scalars['Boolean']>;
  /** Ratio of co-payment in percentage */
  coPayment?: Maybe<Scalars['Int']>;
  /** Ratio of co-payment in percentage */
  coPayment_Gt?: Maybe<Scalars['Int']>;
  /** Ratio of co-payment in percentage */
  coPayment_Lt?: Maybe<Scalars['Int']>;
  /** Ratio of co-payment in percentage */
  coPayment_Gte?: Maybe<Scalars['Int']>;
  /** Ratio of co-payment in percentage */
  coPayment_Lte?: Maybe<Scalars['Int']>;
  /** Maximal co-payment in product currency, when -1, limit is not set */
  coPaymentMax?: Maybe<Scalars['Float']>;
  /** Maximal co-payment in product currency, when -1, limit is not set */
  coPaymentMax_Gt?: Maybe<Scalars['Float']>;
  /** Maximal co-payment in product currency, when -1, limit is not set */
  coPaymentMax_Lt?: Maybe<Scalars['Float']>;
  /** Maximal co-payment in product currency, when -1, limit is not set */
  coPaymentMax_Gte?: Maybe<Scalars['Float']>;
  /** Maximal co-payment in product currency, when -1, limit is not set */
  coPaymentMax_Lte?: Maybe<Scalars['Float']>;
  /** Minimal duration (in days) of insurance to be eligible for the benefit */
  minimalDurationDays?: Maybe<Scalars['Int']>;
  /** Minimal duration (in days) of insurance to be eligible for the benefit */
  minimalDurationDays_Gt?: Maybe<Scalars['Int']>;
  /** Minimal duration (in days) of insurance to be eligible for the benefit */
  minimalDurationDays_Lt?: Maybe<Scalars['Int']>;
  /** Minimal duration (in days) of insurance to be eligible for the benefit */
  minimalDurationDays_Gte?: Maybe<Scalars['Int']>;
  /** Minimal duration (in days) of insurance to be eligible for the benefit */
  minimalDurationDays_Lte?: Maybe<Scalars['Int']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  title_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  excess_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  limitCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  limitAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  beneficiaryLimitCount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  beneficiaryLimitAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  renewalAtYearsEnd_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  coPayment_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  coPaymentMax_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  minimalDurationDays_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  parent?: Maybe<BenefitInputFilterObject>;
  category?: Maybe<BenefitCategoryInputFilterObject>;
  children?: Maybe<BenefitInputFilterObject>;
  usages?: Maybe<BenefitUsageInputFilterObject>;
};

export type BenefitListType = {
  __typename?: 'BenefitListType';
  /** Benefit list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<BenefitType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type BenefitListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type BenefitType = {
  __typename?: 'BenefitType';
  /** amount in GBP, when -1, limit is not set */
  beneficiaryLimitAmount?: Maybe<Scalars['Float']>;
  /** limit number of payments per beneficiary, when -1, limit is not set */
  beneficiaryLimitCount?: Maybe<Scalars['Float']>;
  /** category */
  category?: Maybe<BenefitCategoryType>;
  /** Benefit list */
  children?: Maybe<Array<Maybe<BenefitType>>>;
  /** Ratio of co-payment in percentage */
  coPayment?: Maybe<Scalars['Int']>;
  /** Maximal co-payment in product currency, when -1, limit is not set */
  coPaymentMax?: Maybe<Scalars['Float']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** amount in product currency */
  excess?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** amount in product currency, when -1, limit is not set */
  limitAmount?: Maybe<Scalars['Float']>;
  /** limit number of payments, when -1, limit is not set */
  limitCount?: Maybe<Scalars['Int']>;
  /** Minimal duration (in days) of insurance to be eligible for the benefit */
  minimalDurationDays?: Maybe<Scalars['Int']>;
  /** parent */
  parent?: Maybe<BenefitType>;
  /** product version */
  productVersion?: Maybe<ProductVersionType>;
  renewalAtYearsEnd?: Maybe<Scalars['Boolean']>;
  /** title */
  title?: Maybe<Scalars['String']>;
  /** BenefitUsage list */
  usages?: Maybe<Array<Maybe<BenefitUsageType>>>;
};

export type BenefitTypeChildrenArgs = {
  children_Isnull?: Maybe<Scalars['Boolean']>;
  children?: Maybe<BenefitInputFilterObject>;
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BenefitUsageInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVersion_Isnull?: Maybe<Scalars['Boolean']>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<BenefitInputFilterObject>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category?: Maybe<BenefitCategoryInputFilterObject>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  excess_Isnull?: Maybe<Scalars['Boolean']>;
  excess?: Maybe<Scalars['Float']>;
  excess_Gt?: Maybe<Scalars['Float']>;
  excess_Gte?: Maybe<Scalars['Float']>;
  excess_Lt?: Maybe<Scalars['Float']>;
  excess_Lte?: Maybe<Scalars['Float']>;
  excess_In?: Maybe<Scalars['Float']>;
  limitCount_Isnull?: Maybe<Scalars['Boolean']>;
  limitCount?: Maybe<Scalars['Int']>;
  limitCount_Gt?: Maybe<Scalars['Int']>;
  limitCount_Gte?: Maybe<Scalars['Int']>;
  limitCount_Lt?: Maybe<Scalars['Int']>;
  limitCount_Lte?: Maybe<Scalars['Int']>;
  limitCount_In?: Maybe<Scalars['Int']>;
  limitAmount_Isnull?: Maybe<Scalars['Boolean']>;
  limitAmount?: Maybe<Scalars['Float']>;
  limitAmount_Gt?: Maybe<Scalars['Float']>;
  limitAmount_Gte?: Maybe<Scalars['Float']>;
  limitAmount_Lt?: Maybe<Scalars['Float']>;
  limitAmount_Lte?: Maybe<Scalars['Float']>;
  limitAmount_In?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryLimitCount?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Gt?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Gte?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Lt?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Lte?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_In?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryLimitAmount?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Gt?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Gte?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Lt?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Lte?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_In?: Maybe<Scalars['Float']>;
  renewalAtYearsEnd_Isnull?: Maybe<Scalars['Boolean']>;
  renewalAtYearsEnd?: Maybe<Scalars['Boolean']>;
  coPayment_Isnull?: Maybe<Scalars['Boolean']>;
  coPayment?: Maybe<Scalars['Int']>;
  coPayment_Gt?: Maybe<Scalars['Int']>;
  coPayment_Gte?: Maybe<Scalars['Int']>;
  coPayment_Lt?: Maybe<Scalars['Int']>;
  coPayment_Lte?: Maybe<Scalars['Int']>;
  coPayment_In?: Maybe<Scalars['Int']>;
  coPaymentMax_Isnull?: Maybe<Scalars['Boolean']>;
  coPaymentMax?: Maybe<Scalars['Float']>;
  coPaymentMax_Gt?: Maybe<Scalars['Float']>;
  coPaymentMax_Gte?: Maybe<Scalars['Float']>;
  coPaymentMax_Lt?: Maybe<Scalars['Float']>;
  coPaymentMax_Lte?: Maybe<Scalars['Float']>;
  coPaymentMax_In?: Maybe<Scalars['Float']>;
  minimalDurationDays_Isnull?: Maybe<Scalars['Boolean']>;
  minimalDurationDays?: Maybe<Scalars['Int']>;
  minimalDurationDays_Gt?: Maybe<Scalars['Int']>;
  minimalDurationDays_Gte?: Maybe<Scalars['Int']>;
  minimalDurationDays_Lt?: Maybe<Scalars['Int']>;
  minimalDurationDays_Lte?: Maybe<Scalars['Int']>;
  minimalDurationDays_In?: Maybe<Scalars['Int']>;
  claimId?: Maybe<Scalars['String']>;
};

export type BenefitTypeUsagesArgs = {
  memberUsages_Isnull?: Maybe<Scalars['Boolean']>;
  memberUsages?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  benefit_Isnull?: Maybe<Scalars['Boolean']>;
  benefit?: Maybe<BenefitInputFilterObject>;
  policy_Isnull?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<PolicyInputFilterObject>;
  CurrentCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentCount?: Maybe<Scalars['Int']>;
  CurrentCount_Gt?: Maybe<Scalars['Int']>;
  CurrentCount_Gte?: Maybe<Scalars['Int']>;
  CurrentCount_Lt?: Maybe<Scalars['Int']>;
  CurrentCount_Lte?: Maybe<Scalars['Int']>;
  CurrentCount_In?: Maybe<Scalars['Int']>;
  CurrentAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmount?: Maybe<Scalars['Float']>;
  CurrentAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentAmount_In?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_In?: Maybe<Scalars['Float']>;
  CurrentPendingCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_In?: Maybe<Scalars['Int']>;
  CurrentPendingAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_In?: Maybe<Scalars['Float']>;
};

export type BenefitUsageInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /**  current count */
  CurrentCount?: Maybe<Scalars['Int']>;
  /**  current count */
  CurrentCount_Gt?: Maybe<Scalars['Int']>;
  /**  current count */
  CurrentCount_Lt?: Maybe<Scalars['Int']>;
  /**  current count */
  CurrentCount_Gte?: Maybe<Scalars['Int']>;
  /**  current count */
  CurrentCount_Lte?: Maybe<Scalars['Int']>;
  /**  current amount */
  CurrentAmount?: Maybe<Scalars['Float']>;
  /**  current amount */
  CurrentAmount_Gt?: Maybe<Scalars['Float']>;
  /**  current amount */
  CurrentAmount_Lt?: Maybe<Scalars['Float']>;
  /**  current amount */
  CurrentAmount_Gte?: Maybe<Scalars['Float']>;
  /**  current amount */
  CurrentAmount_Lte?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered_Gt?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered_Lt?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered_Gte?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered_Lte?: Maybe<Scalars['Float']>;
  /**  current pending count */
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  /**  current pending count */
  CurrentPendingCount_Gt?: Maybe<Scalars['Int']>;
  /**  current pending count */
  CurrentPendingCount_Lt?: Maybe<Scalars['Int']>;
  /**  current pending count */
  CurrentPendingCount_Gte?: Maybe<Scalars['Int']>;
  /**  current pending count */
  CurrentPendingCount_Lte?: Maybe<Scalars['Int']>;
  /**  current pending amount */
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  /**  current pending amount */
  CurrentPendingAmount_Gt?: Maybe<Scalars['Float']>;
  /**  current pending amount */
  CurrentPendingAmount_Lt?: Maybe<Scalars['Float']>;
  /**  current pending amount */
  CurrentPendingAmount_Gte?: Maybe<Scalars['Float']>;
  /**  current pending amount */
  CurrentPendingAmount_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  CurrentCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  CurrentAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  CurrentAmountCovered_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  CurrentPendingCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  CurrentPendingAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  benefit?: Maybe<BenefitInputFilterObject>;
  policy?: Maybe<PolicyInputFilterObject>;
  memberUsages?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
};

export type BenefitUsageListType = {
  __typename?: 'BenefitUsageListType';
  /** BenefitUsage list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<BenefitUsageType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type BenefitUsageListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Shared Benefit Usages per Policy */
export type BenefitUsageType = {
  __typename?: 'BenefitUsageType';
  /**  current amount */
  CurrentAmount?: Maybe<Scalars['Float']>;
  /**  current amount covered */
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  /**  current count */
  CurrentCount?: Maybe<Scalars['Int']>;
  /**  current pending amount */
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  /**  current pending count */
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  /** benefit */
  benefit?: Maybe<BenefitType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** BeneficiaryBenefitUsage list */
  memberUsages?: Maybe<Array<Maybe<BeneficiaryBenefitUsageType>>>;
  /** policy */
  policy?: Maybe<PolicyType>;
  currentCount?: Maybe<Scalars['Int']>;
  currentAmount?: Maybe<Scalars['Decimal']>;
  currentAmountCovered?: Maybe<Scalars['Decimal']>;
  currentPendingCount?: Maybe<Scalars['Int']>;
  currentPendingAmount?: Maybe<Scalars['Decimal']>;
  remainingLimitAmount?: Maybe<Scalars['Decimal']>;
  remainingLimitCount?: Maybe<Scalars['Int']>;
};

/** Shared Benefit Usages per Policy */
export type BenefitUsageTypeMemberUsagesArgs = {
  claims_Isnull?: Maybe<Scalars['Boolean']>;
  claims?: Maybe<ClaimInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  sharedUsage_Isnull?: Maybe<Scalars['Boolean']>;
  sharedUsage?: Maybe<BenefitUsageInputFilterObject>;
  CurrentCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentCount?: Maybe<Scalars['Int']>;
  CurrentCount_Gt?: Maybe<Scalars['Int']>;
  CurrentCount_Gte?: Maybe<Scalars['Int']>;
  CurrentCount_Lt?: Maybe<Scalars['Int']>;
  CurrentCount_Lte?: Maybe<Scalars['Int']>;
  CurrentCount_In?: Maybe<Scalars['Int']>;
  CurrentAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmount?: Maybe<Scalars['Float']>;
  CurrentAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentAmount_In?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_In?: Maybe<Scalars['Float']>;
  CurrentPendingCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_In?: Maybe<Scalars['Int']>;
  CurrentPendingAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_In?: Maybe<Scalars['Float']>;
  ConsumedExcess_Isnull?: Maybe<Scalars['Boolean']>;
  ConsumedExcess?: Maybe<Scalars['Float']>;
  ConsumedExcess_Gt?: Maybe<Scalars['Float']>;
  ConsumedExcess_Gte?: Maybe<Scalars['Float']>;
  ConsumedExcess_Lt?: Maybe<Scalars['Float']>;
  ConsumedExcess_Lte?: Maybe<Scalars['Float']>;
  ConsumedExcess_In?: Maybe<Scalars['Float']>;
};

export type BlockedFundsInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  fundingAccount?: Maybe<FundingAccountInputFilterObject>;
};

export type BlockedFundsType = {
  __typename?: 'BlockedFundsType';
  /** funding account */
  fundingAccount?: Maybe<FundingAccountType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
};

/** An enumeration. */
export enum CardActionActionEnum {
  /** Re-activate card */
  Activate = 'ACTIVATE',
  /** Deactivate card */
  Deactivate = 'DEACTIVATE',
  /** Cancel card */
  Cancel = 'CANCEL',
  /** Freeze card */
  Freeze = 'FREEZE',
  /** Unfreeze card */
  Unfreeze = 'UNFREEZE',
  /** Block card */
  Block = 'BLOCK',
  /** Unblock card */
  Unblock = 'UNBLOCK',
  /** Order replacement card */
  Replacement = 'REPLACEMENT',
  /** Disable and cancell card */
  Revoke = 'REVOKE'
}

/** An enumeration. */
export enum CardActionActionEnumCreate {
  /** Re-activate card */
  Activate = 'ACTIVATE',
  /** Deactivate card */
  Deactivate = 'DEACTIVATE',
  /** Cancel card */
  Cancel = 'CANCEL',
  /** Freeze card */
  Freeze = 'FREEZE',
  /** Unfreeze card */
  Unfreeze = 'UNFREEZE',
  /** Block card */
  Block = 'BLOCK',
  /** Unblock card */
  Unblock = 'UNBLOCK',
  /** Order replacement card */
  Replacement = 'REPLACEMENT',
  /** Disable and cancell card */
  Revoke = 'REVOKE'
}

/** An enumeration. */
export enum CardActionActionEnumUpdate {
  /** Re-activate card */
  Activate = 'ACTIVATE',
  /** Deactivate card */
  Deactivate = 'DEACTIVATE',
  /** Cancel card */
  Cancel = 'CANCEL',
  /** Freeze card */
  Freeze = 'FREEZE',
  /** Unfreeze card */
  Unfreeze = 'UNFREEZE',
  /** Block card */
  Block = 'BLOCK',
  /** Unblock card */
  Unblock = 'UNBLOCK',
  /** Order replacement card */
  Replacement = 'REPLACEMENT',
  /** Disable and cancell card */
  Revoke = 'REVOKE'
}

export type CardActionInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** created */
  created?: Maybe<Scalars['CustomDateTime']>;
  /** created */
  created_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created */
  created_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created */
  created_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created */
  created_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** comment */
  comment?: Maybe<Scalars['String']>;
  /** comment */
  comment_Icontains?: Maybe<Scalars['String']>;
  /** action */
  action?: Maybe<Scalars['String']>;
  /** action */
  action_Icontains?: Maybe<Scalars['String']>;
  /** source */
  source?: Maybe<Scalars['String']>;
  /** source */
  source_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  created_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  comment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  action_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  card?: Maybe<CardInputFilterObject>;
  user?: Maybe<UserInputFilterObject>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
};

export type CardActionListType = {
  __typename?: 'CardActionListType';
  /** CardAction list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CardActionType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CardActionListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CardActionMutation = {
  __typename?: 'CardActionMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  cardaction?: Maybe<CardActionType>;
};

/** An enumeration. */
export enum CardActionSourceEnum {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

/** An enumeration. */
export enum CardActionSourceEnumCreate {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

/** An enumeration. */
export enum CardActionSourceEnumUpdate {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

export type CardActionType = {
  __typename?: 'CardActionType';
  /** action */
  action?: Maybe<CardActionActionEnum>;
  /** card */
  card?: Maybe<CardType>;
  /** comment */
  comment?: Maybe<Scalars['String']>;
  /** created */
  created?: Maybe<Scalars['CustomDateTime']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** requested by */
  requestedBy?: Maybe<BeneficiaryType>;
  /** source */
  source?: Maybe<CardActionSourceEnum>;
  /** user */
  user?: Maybe<UserType>;
};

/** An enumeration. */
export enum CardCardTypeEnum {
  /** physical */
  Physical = 'PHYSICAL',
  /** virtual */
  Virtual = 'VIRTUAL'
}

/** An enumeration. */
export enum CardCardTypeEnumUpdate {
  /** physical */
  Physical = 'PHYSICAL',
  /** virtual */
  Virtual = 'VIRTUAL'
}

export type CardCustomerInputType = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Checks whether the way card data was input into the payments network is considered safe. */
export type CardDataInputRuleType = PaymentPlatformRuleType & {
  __typename?: 'CardDataInputRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Scalars['String']>;
};

export type CardDetailsType = {
  __typename?: 'CardDetailsType';
  encryptedData?: Maybe<Scalars['String']>;
  iv?: Maybe<Scalars['String']>;
};

/** Checks dynamic rules (i.e. rules enforced by REST service, like velocity controls) */
export type CardDynamicRulesType = PaymentPlatformRuleType & {
  __typename?: 'CardDynamicRulesType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Boolean']>;
  actual?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
};

export type CardEventInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gte?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lte?: Maybe<Scalars['Int']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** transaction source */
  transactionSource?: Maybe<Scalars['String']>;
  /** transaction source */
  transactionSource_Icontains?: Maybe<Scalars['String']>;
  /** transaction type */
  transactionType?: Maybe<Scalars['String']>;
  /** transaction type */
  transactionType_Icontains?: Maybe<Scalars['String']>;
  /** transaction id */
  transactionId?: Maybe<Scalars['String']>;
  /** transaction id */
  transactionId_Icontains?: Maybe<Scalars['String']>;
  /** raw request */
  rawRequest?: Maybe<Scalars['JSONString']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  /** entity id */
  entityId?: Maybe<Scalars['String']>;
  /** entity id */
  entityId_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  keepProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  needManualProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  transactionSource_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  rawRequest_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  metadata_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  entityId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
};

export type CardEventListType = {
  __typename?: 'CardEventListType';
  /** CardEvent list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CardEventType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CardEventListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum CardEventTransactionSourceEnum {
  /** RailsBank */
  Railsbank = 'RAILSBANK',
  /** GPS */
  Gps = 'GPS',
  /** Visa Report */
  VisaReport = 'VISA_REPORT'
}

export type CardEventType = {
  __typename?: 'CardEventType';
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** entity id */
  entityId?: Maybe<Scalars['String']>;
  /** entity type */
  entityType?: Maybe<ContentTypeType>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  keepProcessing?: Maybe<Scalars['Boolean']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** Type for a GenericForeignKey field */
  processedMessage?: Maybe<GenericForeignKeyType>;
  /** raw request */
  rawRequest?: Maybe<Scalars['JSONString']>;
  /** transaction id */
  transactionId?: Maybe<Scalars['String']>;
  /** transaction source */
  transactionSource?: Maybe<CardEventTransactionSourceEnum>;
  /** transaction type */
  transactionType?: Maybe<Scalars['String']>;
};

/**
 * Checks whether the card holder was present when the transaction took place.
 * There are use cases when the card holder is not present as the transaction
 * could be an installment, recurring subscription etc.
 */
export type CardHolderPresenceRuleType = PaymentPlatformRuleType & {
  __typename?: 'CardHolderPresenceRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Scalars['String']>;
};

export type CardInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** proxy id */
  proxyId?: Maybe<Scalars['String']>;
  /** proxy id */
  proxyId_Icontains?: Maybe<Scalars['String']>;
  /** name on card */
  nameOnCard?: Maybe<Scalars['String']>;
  /** name on card */
  nameOnCard_Icontains?: Maybe<Scalars['String']>;
  /** last digits */
  lastDigits?: Maybe<Scalars['String']>;
  /** last digits */
  lastDigits_Icontains?: Maybe<Scalars['String']>;
  /** expiration */
  expiration?: Maybe<Scalars['String']>;
  /** expiration */
  expiration_Icontains?: Maybe<Scalars['String']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  /** card type */
  cardType?: Maybe<Scalars['String']>;
  /** card type */
  cardType_Icontains?: Maybe<Scalars['String']>;
  /** aggregation processed at */
  aggregationProcessedAt?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation processed at */
  aggregationProcessedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation processed at */
  aggregationProcessedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation processed at */
  aggregationProcessedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation processed at */
  aggregationProcessedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation failed at */
  aggregationFailedAt?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation failed at */
  aggregationFailedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation failed at */
  aggregationFailedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation failed at */
  aggregationFailedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation failed at */
  aggregationFailedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  /** aggregation failed count */
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  /** aggregation failed count */
  aggregationFailedCount_Gt?: Maybe<Scalars['Int']>;
  /** aggregation failed count */
  aggregationFailedCount_Lt?: Maybe<Scalars['Int']>;
  /** aggregation failed count */
  aggregationFailedCount_Gte?: Maybe<Scalars['Int']>;
  /** aggregation failed count */
  aggregationFailedCount_Lte?: Maybe<Scalars['Int']>;
  /** id for public retrieval */
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  /** external ledger id */
  externalLedgerId?: Maybe<Scalars['String']>;
  /** external ledger id */
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  proxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameOnCard_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastDigits_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiration_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isFrozen_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isBlocked_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isDeactivated_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isCancelled_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isIssued_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isReplacementOrdered_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  cardType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregationProcessedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  aggregationFailedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  aggregationKeepProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  aggregationFailedCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  idForPublicRetrieval_In?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  externalLedgerId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  policyChain?: Maybe<PolicyChainInputFilterObject>;
  defaultClaim?: Maybe<ClaimInputFilterObject>;
  currentBeneficiaryForPayments?: Maybe<BeneficiaryInputFilterObject>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  sentSmses?: Maybe<SmsInputFilterObject>;
  actions?: Maybe<CardActionInputFilterObject>;
  aggregatedbeneficiarycard?: Maybe<AggregatedBeneficiaryCardInputFilterObject>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
};

/** Checks if the card is ready (e.g. not frozen, not blocked). */
export type CardIsReadyRuleType = PaymentPlatformRuleType & {
  __typename?: 'CardIsReadyRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Boolean']>;
  actual?: Maybe<Scalars['Boolean']>;
};

export type CardListType = {
  __typename?: 'CardListType';
  /** Card list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CardType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CardListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CardMerchantInputType = {
  countryCode?: Maybe<Scalars['String']>;
  mccs?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantId?: Maybe<Scalars['String']>;
};

export type CardMutation = {
  __typename?: 'CardMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  card?: Maybe<CardType>;
};

export type CardPaymentCardInputType = {
  referenceId?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  velocityControl?: Maybe<PaymentVelocityControlSpecificationTypeInput>;
  frequencyControl?: Maybe<PaymentFrequencyControlSpecificationTypeInput>;
  sendEmail: Scalars['Boolean'];
  sendSms: Scalars['Boolean'];
};

export type CardPreAuthorizationInputType = {
  timeFrom?: Maybe<Scalars['AutoParsedDateTime']>;
  timeTo?: Maybe<Scalars['AutoParsedDateTime']>;
  /** Max amount */
  limit?: Maybe<Scalars['Float']>;
};

/**
 * Checks whether the card was actually present when the transaction took place.
 * There are use cases when the card is not present, since the transaction
 * can happen, for instance, over the phone.
 */
export type CardPresenceRuleType = PaymentPlatformRuleType & {
  __typename?: 'CardPresenceRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Scalars['String']>;
};

export type CardRulesInputSelectionType = {
  paymentVelocityControl?: Maybe<PaymentVelocityControlSpecificationTypeInput>;
  paymentFrequencyControl?: Maybe<PaymentFrequencyControlSpecificationTypeInput>;
  claimsTimeWindow?: Maybe<ClaimTimeWindowSpecificationTypeInput>;
  claimsDeclinedMax?: Maybe<ClaimsDeclinedMaxSpecificationTypeInput>;
  claimsMax?: Maybe<ClaimsMaxSpecificationTypeInput>;
};

/** An enumeration. */
export enum CardState {
  Queued = 'QUEUED',
  Active = 'ACTIVE',
  Used = 'USED',
  Revoked = 'REVOKED',
  MultipleCards = 'MULTIPLE_CARDS',
  NoCard = 'NO_CARD'
}

export type CardType = {
  __typename?: 'CardType';
  /** CardAction list */
  actions?: Maybe<Array<Maybe<CardActionType>>>;
  /** AggregatedBeneficiaryCard list */
  aggregatedbeneficiarycard?: Maybe<Array<Maybe<AggregatedBeneficiaryCardType>>>;
  /** aggregation failed at */
  aggregationFailedAt?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation failed count */
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  /** aggregation processed at */
  aggregationProcessedAt?: Maybe<Scalars['CustomDateTime']>;
  /** beneficiary */
  beneficiary?: Maybe<BeneficiaryType>;
  /** card type */
  cardType?: Maybe<CardCardTypeEnum>;
  /** current beneficiary for payments */
  currentBeneficiaryForPayments?: Maybe<BeneficiaryType>;
  /** default claim */
  defaultClaim?: Maybe<ClaimType>;
  /** expiration */
  expiration?: Maybe<Scalars['String']>;
  /** external ledger id */
  externalLedgerId?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** id for public retrieval */
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  /** InsurerPayment list */
  insurerPayments?: Maybe<Array<Maybe<InsurerPaymentType>>>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  /** last digits */
  lastDigits?: Maybe<Scalars['String']>;
  nameOnCard?: Maybe<Scalars['String']>;
  /** policy chain */
  policyChain?: Maybe<PolicyChainType>;
  /** proxy id */
  proxyId?: Maybe<Scalars['String']>;
  /** Email list */
  sentEmails?: Maybe<Array<Maybe<EmailType>>>;
  /** Sms list */
  sentSmses?: Maybe<Array<Maybe<SmsType>>>;
  currency?: Maybe<CurrencyType>;
  ready?: Maybe<Scalars['Boolean']>;
  blockedPendingClaim?: Maybe<Scalars['Boolean']>;
  rulesCurrentStates?: Maybe<Array<Maybe<RulesState>>>;
  threshold?: Maybe<Scalars['Float']>;
  paymentAuthorizations?: Maybe<Array<Maybe<PaymentAuthorizationType>>>;
  paymentRulesOverrides?: Maybe<Array<Maybe<PaymentRulesOverrideType>>>;
  cardDetails?: Maybe<CardDetailsType>;
};

export type CardTypeActionsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  card_Isnull?: Maybe<Scalars['Boolean']>;
  card?: Maybe<CardInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  created_Isnull?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  created_Gt?: Maybe<Scalars['DateTime']>;
  created_Gte?: Maybe<Scalars['DateTime']>;
  created_Lt?: Maybe<Scalars['DateTime']>;
  created_Lte?: Maybe<Scalars['DateTime']>;
  created_In?: Maybe<Scalars['DateTime']>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  action_Isnull?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_Iexact?: Maybe<Scalars['String']>;
  action_Contains?: Maybe<Scalars['String']>;
  action_Icontains?: Maybe<Scalars['String']>;
  action_Startswith?: Maybe<Scalars['String']>;
  action_Istartswith?: Maybe<Scalars['String']>;
  action_Endswith?: Maybe<Scalars['String']>;
  action_Iendswith?: Maybe<Scalars['String']>;
  action_In?: Maybe<Scalars['String']>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
};

export type CardTypeAggregatedbeneficiarycardArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProxyId_Isnull?: Maybe<Scalars['Boolean']>;
  cardProxyId?: Maybe<CardInputFilterObject>;
  currencyCode_Isnull?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_Iexact?: Maybe<Scalars['String']>;
  currencyCode_Contains?: Maybe<Scalars['String']>;
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  currencyCode_Startswith?: Maybe<Scalars['String']>;
  currencyCode_Istartswith?: Maybe<Scalars['String']>;
  currencyCode_Endswith?: Maybe<Scalars['String']>;
  currencyCode_Iendswith?: Maybe<Scalars['String']>;
  currencyCode_In?: Maybe<Scalars['String']>;
  beneficiaryId_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryId?: Maybe<Scalars['Int']>;
  beneficiaryId_Gt?: Maybe<Scalars['Int']>;
  beneficiaryId_Gte?: Maybe<Scalars['Int']>;
  beneficiaryId_Lt?: Maybe<Scalars['Int']>;
  beneficiaryId_Lte?: Maybe<Scalars['Int']>;
  beneficiaryId_In?: Maybe<Scalars['Int']>;
  cardThreshold_Isnull?: Maybe<Scalars['Boolean']>;
  cardThreshold?: Maybe<Scalars['Float']>;
  cardThreshold_Gt?: Maybe<Scalars['Float']>;
  cardThreshold_Gte?: Maybe<Scalars['Float']>;
  cardThreshold_Lt?: Maybe<Scalars['Float']>;
  cardThreshold_Lte?: Maybe<Scalars['Float']>;
  cardThreshold_In?: Maybe<Scalars['Float']>;
  numberOfDeclinedClaimsReached_Isnull?: Maybe<Scalars['Boolean']>;
  numberOfDeclinedClaimsReached?: Maybe<Scalars['Boolean']>;
  numberOfClaimsWithinSpecifiedTimeWindowReached_Isnull?: Maybe<Scalars['Boolean']>;
  numberOfClaimsWithinSpecifiedTimeWindowReached?: Maybe<Scalars['Boolean']>;
  totalNumberOfClaimsReached_Isnull?: Maybe<Scalars['Boolean']>;
  totalNumberOfClaimsReached?: Maybe<Scalars['Boolean']>;
  velocityControlViolated_Isnull?: Maybe<Scalars['Boolean']>;
  velocityControlViolated?: Maybe<Scalars['Boolean']>;
  frequencyControlViolated_Isnull?: Maybe<Scalars['Boolean']>;
  frequencyControlViolated?: Maybe<Scalars['Boolean']>;
  pendingClaimToBlock_Isnull?: Maybe<Scalars['Boolean']>;
  pendingClaimToBlock?: Maybe<Scalars['Boolean']>;
  cardReady_Isnull?: Maybe<Scalars['Boolean']>;
  cardReady?: Maybe<Scalars['Boolean']>;
  fundingAccountId_Isnull?: Maybe<Scalars['Boolean']>;
  fundingAccountId?: Maybe<Scalars['Int']>;
  fundingAccountId_Gt?: Maybe<Scalars['Int']>;
  fundingAccountId_Gte?: Maybe<Scalars['Int']>;
  fundingAccountId_Lt?: Maybe<Scalars['Int']>;
  fundingAccountId_Lte?: Maybe<Scalars['Int']>;
  fundingAccountId_In?: Maybe<Scalars['Int']>;
  gpsLatitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLatitude?: Maybe<Scalars['Float']>;
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  gpsLatitude_In?: Maybe<Scalars['Float']>;
  gpsLongitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLongitude?: Maybe<Scalars['Float']>;
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  gpsLongitude_In?: Maybe<Scalars['Float']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  updatedAt_Isnull?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gte?: Maybe<Scalars['DateTime']>;
  updatedAt_Lt?: Maybe<Scalars['DateTime']>;
  updatedAt_Lte?: Maybe<Scalars['DateTime']>;
  updatedAt_In?: Maybe<Scalars['DateTime']>;
  locked_Isnull?: Maybe<Scalars['Boolean']>;
  locked?: Maybe<Scalars['DateTime']>;
  locked_Gt?: Maybe<Scalars['DateTime']>;
  locked_Gte?: Maybe<Scalars['DateTime']>;
  locked_Lt?: Maybe<Scalars['DateTime']>;
  locked_Lte?: Maybe<Scalars['DateTime']>;
  locked_In?: Maybe<Scalars['DateTime']>;
};

export type CardTypeInsurerPaymentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Scalars['DateTime']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  paymentPtr_Isnull?: Maybe<Scalars['Boolean']>;
  paymentPtr?: Maybe<PaymentInputFilterObject>;
  card_Isnull?: Maybe<Scalars['Boolean']>;
  card?: Maybe<CardInputFilterObject>;
  authorization_Isnull?: Maybe<Scalars['Boolean']>;
  authorization?: Maybe<PaymentAuthorizationInputFilterObject>;
  settlement_Isnull?: Maybe<Scalars['Boolean']>;
  settlement?: Maybe<PaymentSettlementInputFilterObject>;
};

export type CardTypeSentEmailsArgs = {
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  notifications_Isnull?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<NotificationInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  sender_Isnull?: Maybe<Scalars['Boolean']>;
  sender?: Maybe<Scalars['String']>;
  sender_Iexact?: Maybe<Scalars['String']>;
  sender_Contains?: Maybe<Scalars['String']>;
  sender_Icontains?: Maybe<Scalars['String']>;
  sender_Startswith?: Maybe<Scalars['String']>;
  sender_Istartswith?: Maybe<Scalars['String']>;
  sender_Endswith?: Maybe<Scalars['String']>;
  sender_Iendswith?: Maybe<Scalars['String']>;
  sender_In?: Maybe<Scalars['String']>;
  recipient_Isnull?: Maybe<Scalars['Boolean']>;
  recipient?: Maybe<Scalars['String']>;
  recipient_Iexact?: Maybe<Scalars['String']>;
  recipient_Contains?: Maybe<Scalars['String']>;
  recipient_Icontains?: Maybe<Scalars['String']>;
  recipient_Startswith?: Maybe<Scalars['String']>;
  recipient_Istartswith?: Maybe<Scalars['String']>;
  recipient_Endswith?: Maybe<Scalars['String']>;
  recipient_Iendswith?: Maybe<Scalars['String']>;
  recipient_In?: Maybe<Scalars['String']>;
  subject_Isnull?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  subject_Iexact?: Maybe<Scalars['String']>;
  subject_Contains?: Maybe<Scalars['String']>;
  subject_Icontains?: Maybe<Scalars['String']>;
  subject_Startswith?: Maybe<Scalars['String']>;
  subject_Istartswith?: Maybe<Scalars['String']>;
  subject_Endswith?: Maybe<Scalars['String']>;
  subject_Iendswith?: Maybe<Scalars['String']>;
  subject_In?: Maybe<Scalars['String']>;
  body_Isnull?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  messageId_Isnull?: Maybe<Scalars['Boolean']>;
  messageId?: Maybe<Scalars['UUID']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
};

export type CardTypeSentSmsesArgs = {
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber_Isnull?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumber_Iexact?: Maybe<Scalars['String']>;
  phoneNumber_Contains?: Maybe<Scalars['String']>;
  phoneNumber_Icontains?: Maybe<Scalars['String']>;
  phoneNumber_Startswith?: Maybe<Scalars['String']>;
  phoneNumber_Istartswith?: Maybe<Scalars['String']>;
  phoneNumber_Endswith?: Maybe<Scalars['String']>;
  phoneNumber_Iendswith?: Maybe<Scalars['String']>;
  phoneNumber_In?: Maybe<Scalars['String']>;
  body_Isnull?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  sentSmses?: Maybe<SmsInputFilterObject>;
};

export type CardTypeCardDetailsArgs = {
  secret: Scalars['String'];
};

/** Checks whether the transaction contained an extra amount for a cashback. */
export type CashbackRuleType = PaymentPlatformRuleType & {
  __typename?: 'CashbackRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Boolean']>;
  actual?: Maybe<Scalars['Boolean']>;
};

export type ChangeCurrentBeneficiaryForPaymentsInput = {
  id?: Maybe<Scalars['ID']>;
  beneficiaryId?: Maybe<Scalars['ID']>;
};

/** Chip Fallback occurs when a chip card is used a swipe card (i.e. with magnetic stripe). */
export type ChipFallbackRuleType = PaymentPlatformRuleType & {
  __typename?: 'ChipFallbackRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Scalars['String']>;
};

export type ClaimBreakdownInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** per claim excess */
  perClaimExcess?: Maybe<Scalars['Float']>;
  /** per claim excess */
  perClaimExcess_Gt?: Maybe<Scalars['Float']>;
  /** per claim excess */
  perClaimExcess_Lt?: Maybe<Scalars['Float']>;
  /** per claim excess */
  perClaimExcess_Gte?: Maybe<Scalars['Float']>;
  /** per claim excess */
  perClaimExcess_Lte?: Maybe<Scalars['Float']>;
  /** policy wide excess */
  policyWideExcess?: Maybe<Scalars['Float']>;
  /** policy wide excess */
  policyWideExcess_Gt?: Maybe<Scalars['Float']>;
  /** policy wide excess */
  policyWideExcess_Lt?: Maybe<Scalars['Float']>;
  /** policy wide excess */
  policyWideExcess_Gte?: Maybe<Scalars['Float']>;
  /** policy wide excess */
  policyWideExcess_Lte?: Maybe<Scalars['Float']>;
  /** benefit level excess */
  benefitLevelExcess?: Maybe<Scalars['Float']>;
  /** benefit level excess */
  benefitLevelExcess_Gt?: Maybe<Scalars['Float']>;
  /** benefit level excess */
  benefitLevelExcess_Lt?: Maybe<Scalars['Float']>;
  /** benefit level excess */
  benefitLevelExcess_Gte?: Maybe<Scalars['Float']>;
  /** benefit level excess */
  benefitLevelExcess_Lte?: Maybe<Scalars['Float']>;
  /** co payment */
  coPayment?: Maybe<Scalars['Float']>;
  /** co payment */
  coPayment_Gt?: Maybe<Scalars['Float']>;
  /** co payment */
  coPayment_Lt?: Maybe<Scalars['Float']>;
  /** co payment */
  coPayment_Gte?: Maybe<Scalars['Float']>;
  /** co payment */
  coPayment_Lte?: Maybe<Scalars['Float']>;
  /** covered */
  covered?: Maybe<Scalars['Float']>;
  /** covered */
  covered_Gt?: Maybe<Scalars['Float']>;
  /** covered */
  covered_Lt?: Maybe<Scalars['Float']>;
  /** covered */
  covered_Gte?: Maybe<Scalars['Float']>;
  /** covered */
  covered_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  perClaimExcess_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  policyWideExcess_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  benefitLevelExcess_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  coPayment_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  covered_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  claim?: Maybe<ClaimInputFilterObject>;
};

export type ClaimBreakdownListType = {
  __typename?: 'ClaimBreakdownListType';
  /** ClaimBreakdown list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ClaimBreakdownType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ClaimBreakdownListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ClaimBreakdownType = {
  __typename?: 'ClaimBreakdownType';
  /** benefit level excess */
  benefitLevelExcess?: Maybe<Scalars['Float']>;
  /** claim */
  claim?: Maybe<ClaimType>;
  /** co payment */
  coPayment?: Maybe<Scalars['Float']>;
  /** covered */
  covered?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** per claim excess */
  perClaimExcess?: Maybe<Scalars['Float']>;
  /** policy wide excess */
  policyWideExcess?: Maybe<Scalars['Float']>;
};

export type ClaimcountType = {
  __typename?: 'ClaimcountType';
  today?: Maybe<Scalars['Int']>;
  thisWeek?: Maybe<Scalars['Int']>;
  thisMonth?: Maybe<Scalars['Int']>;
  allTime?: Maybe<Scalars['Int']>;
};

export type ClaimCptCodeInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  default?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  default_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  cptCode?: Maybe<CptCodeInputFilterObject>;
  claim?: Maybe<ClaimInputFilterObject>;
};

export type ClaimCptCodeListType = {
  __typename?: 'ClaimCptCodeListType';
  /** ClaimCptCode list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ClaimCptCodeType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ClaimCptCodeListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ClaimCptCodeMutation = {
  __typename?: 'ClaimCptCodeMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  claimcptcode?: Maybe<ClaimCptCodeType>;
};

/** ClaimIcdCode represents a relationship between Claim and CptCode. */
export type ClaimCptCodeType = {
  __typename?: 'ClaimCptCodeType';
  /** claim */
  claim?: Maybe<ClaimType>;
  /** cpt code */
  cptCode?: Maybe<CptCodeType>;
  default?: Maybe<Scalars['Boolean']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
};

export type ClaimDecisionInputType = {
  id?: Maybe<Scalars['ID']>;
  action?: Maybe<Action>;
  claimId?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['DecimalScalar']>;
  preAuthorization?: Maybe<PreAuthorizationTypeInput>;
  netting?: Maybe<NettingInputType>;
};

export type ClaimIcdCodeInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  default?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  default_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  icdCode?: Maybe<IcdCodeInputFilterObject>;
  claim?: Maybe<ClaimInputFilterObject>;
  provider?: Maybe<ProviderInputFilterObject>;
};

export type ClaimIcdCodeListType = {
  __typename?: 'ClaimIcdCodeListType';
  /** ClaimIcdCode list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ClaimIcdCodeType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ClaimIcdCodeListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ClaimIcdCodeMutation = {
  __typename?: 'ClaimIcdCodeMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  claimicdcode?: Maybe<ClaimIcdCodeType>;
};

/** ClaimIcdCode represents a relationship between Claim and IcdCode. */
export type ClaimIcdCodeType = {
  __typename?: 'ClaimIcdCodeType';
  /** claim */
  claim?: Maybe<ClaimType>;
  default?: Maybe<Scalars['Boolean']>;
  /** icd code */
  icdCode?: Maybe<IcdCodeType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** provider */
  provider?: Maybe<ProviderType>;
};

export type ClaimInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  retroactive?: Maybe<Scalars['Boolean']>;
  /** pre authorization */
  preAuthorization?: Maybe<Scalars['JSONString']>;
  /** state */
  state?: Maybe<Scalars['String']>;
  /** state */
  state_Icontains?: Maybe<Scalars['String']>;
  /** max amount */
  maxAmount?: Maybe<Scalars['Float']>;
  /** max amount */
  maxAmount_Gt?: Maybe<Scalars['Float']>;
  /** max amount */
  maxAmount_Lt?: Maybe<Scalars['Float']>;
  /** max amount */
  maxAmount_Gte?: Maybe<Scalars['Float']>;
  /** max amount */
  maxAmount_Lte?: Maybe<Scalars['Float']>;
  /** eligible amount */
  eligibleAmount?: Maybe<Scalars['Float']>;
  /** eligible amount */
  eligibleAmount_Gt?: Maybe<Scalars['Float']>;
  /** eligible amount */
  eligibleAmount_Lt?: Maybe<Scalars['Float']>;
  /** eligible amount */
  eligibleAmount_Gte?: Maybe<Scalars['Float']>;
  /** eligible amount */
  eligibleAmount_Lte?: Maybe<Scalars['Float']>;
  /** transaction amount */
  transactionAmount?: Maybe<Scalars['Float']>;
  /** transaction amount */
  transactionAmount_Gt?: Maybe<Scalars['Float']>;
  /** transaction amount */
  transactionAmount_Lt?: Maybe<Scalars['Float']>;
  /** transaction amount */
  transactionAmount_Gte?: Maybe<Scalars['Float']>;
  /** transaction amount */
  transactionAmount_Lte?: Maybe<Scalars['Float']>;
  /** source */
  source?: Maybe<Scalars['String']>;
  /** source */
  source_Icontains?: Maybe<Scalars['String']>;
  isDummy?: Maybe<Scalars['Boolean']>;
  /**  reason */
  Reason?: Maybe<Scalars['String']>;
  /**  reason */
  Reason_Icontains?: Maybe<Scalars['String']>;
  allowTransferringToFuture?: Maybe<Scalars['Boolean']>;
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  /** additional information */
  additionalInformation_Icontains?: Maybe<Scalars['String']>;
  /** created */
  created?: Maybe<Scalars['CustomDateTime']>;
  /** created */
  created_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created */
  created_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created */
  created_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created */
  created_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** updated */
  updated?: Maybe<Scalars['CustomDateTime']>;
  /** updated */
  updated_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** updated */
  updated_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** updated */
  updated_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** updated */
  updated_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** reference id */
  referenceId?: Maybe<Scalars['String']>;
  /** reference id */
  referenceId_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  retroactive_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  preAuthorization_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  maxAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  eligibleAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  transactionAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDummy_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  Reason_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowTransferringToFuture_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  additionalInformation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  updated_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  referenceId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchant?: Maybe<MerchantInputFilterObject>;
  beneficiaryBenefitUsage?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
  codesExclusion?: Maybe<CodesExclusionInputFilterObject>;
  claimicdcode?: Maybe<ClaimIcdCodeInputFilterObject>;
  claimcptcode?: Maybe<ClaimCptCodeInputFilterObject>;
  breakdown?: Maybe<ClaimBreakdownInputFilterObject>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  defaultForCards?: Maybe<CardInputFilterObject>;
  aggregatedPreauths?: Maybe<AggregatedPreauthInputFilterObject>;
  payments?: Maybe<PaymentInputFilterObject>;
  paymentAuthorizations?: Maybe<PaymentAuthorizationInputFilterObject>;
  account?: Maybe<AccountInputFilterObject>;
};

export type ClaimListType = {
  __typename?: 'ClaimListType';
  /** Claim list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ClaimType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ClaimListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ClaimMutation = {
  __typename?: 'ClaimMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  claim?: Maybe<ClaimType>;
};

export type ClaimNettingType = {
  __typename?: 'ClaimNettingType';
  receivables?: Maybe<Scalars['Float']>;
  liabilities?: Maybe<Scalars['Float']>;
};

export type ClaimNoteType = {
  __typename?: 'ClaimNoteType';
  source?: Maybe<Source>;
  claimId?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  timestamp?: Maybe<Scalars['AutoParsedDateTime']>;
};

/** Checks if pre-auth is needed. If it's the case, checks if suitable pre-auth is available. */
export type ClaimPreauthRuleType = PaymentPlatformRuleType & {
  __typename?: 'ClaimPreauthRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
};

export type ClaimsawaitingactioncountType = {
  __typename?: 'ClaimsawaitingactioncountType';
  pendingAuthorization?: Maybe<Scalars['Int']>;
  pendingReview?: Maybe<Scalars['Int']>;
  stagnant?: Maybe<Scalars['Int']>;
};

export type ClaimscountType = {
  __typename?: 'ClaimscountType';
  all?: Maybe<ClaimcountType>;
  instant?: Maybe<ClaimcountType>;
  preAuthorizations?: Maybe<ClaimcountType>;
};

export type ClaimsDeclinedMaxSpecificationType = RulesCardSpecification & {
  __typename?: 'ClaimsDeclinedMaxSpecificationType';
  ruleName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type ClaimsDeclinedMaxSpecificationTypeInput = {
  count?: Maybe<Scalars['Int']>;
};

export type ClaimsMaxSpecificationType = RulesCardSpecification & {
  __typename?: 'ClaimsMaxSpecificationType';
  ruleName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type ClaimsMaxSpecificationTypeInput = {
  count?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ClaimSourceEnum {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

/** An enumeration. */
export enum ClaimStateEnum {
  /** Pending Authorization */
  PendingAuthorization = 'PENDING_AUTHORIZATION',
  /** Authorization referred */
  ReferredAuthorization = 'REFERRED_AUTHORIZATION',
  /** Authorized */
  Authorized = 'AUTHORIZED',
  /** Paid */
  Paid = 'PAID',
  /** Pending review */
  PendingReview = 'PENDING_REVIEW',
  /** Pending acceptance */
  PendingAcceptance = 'PENDING_ACCEPTANCE',
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Partially Accepted */
  PartiallyAccepted = 'PARTIALLY_ACCEPTED',
  /** Declined */
  Declined = 'DECLINED',
  /** Payment declined */
  PaymentDeclined = 'PAYMENT_DECLINED',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Historic claim */
  HistoricClaim = 'HISTORIC_CLAIM'
}

export type ClaimTimeWindowSpecificationType = RulesCardSpecification & {
  __typename?: 'ClaimTimeWindowSpecificationType';
  ruleName?: Maybe<Scalars['String']>;
  timeWindowDays?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type ClaimTimeWindowSpecificationTypeInput = {
  timeWindowDays?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

/**
 * Schema for claims
 *
 * There are two classes of claims - instant claims produced by payment platform and standard claims.
 *
 * Standard claims require pre-authorization to be present.
 * To authorize such a claim, the claim needs to be within user's claims authority.
 * If the user is not permitted to perform authorization, they can still refer it to users with higher
 * claims authority. Please note, that these two actions are the two only occasions when `pre_authorization`
 * and `max_amount` are processed.
 *
 * Should insurer require changing decision about the claim, only users with specific permissions are allowed to
 * reset a claim.
 *
 * With regards to merchants, please note that to accept a claim you either need a verified merchant to be specified,
 * or the system will mark the merchant specified as a verified one.
 *
 * Note: `accepted` Claim with not all `ACCEPTED` receipts will be in `PARTIALLY_ACCEPTED` state.
 *
 * Note: While accepting a Claim with not yet verified Merchant, that Merchant becomes verified.
 *
 * Path hints:
 * ----------
 * - beneficiary: 'beneficiaryBenefitUsage.beneficiary`
 *
 *
 * Mutations:
 * ----------
 *     Update
 *         claimUpdate(newClaim: {...})
 *
 *         There are 5 update actions which are tne ({triggered based on the input argument:
 *
 *             1) `newDecison` : Claim's state decision actions are triggered
 *             2) `merchantChange` : Claim's merchant is changed
 *             3) `benefitChange` : `beneficiary_benefit_usage` is changed according  to the new benefit
 *             4) `newNote` : Note is attached to the claim as a notification -> claim is not updated
 *             5) `additionalInfoChange` : Additional information is added or updated on the claim
 */
export type ClaimType = {
  __typename?: 'ClaimType';
  /**  reason */
  Reason?: Maybe<Scalars['String']>;
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  /** AggregatedPreauth list */
  aggregatedPreauths?: Maybe<Array<Maybe<AggregatedPreauthType>>>;
  allowTransferringToFuture?: Maybe<Scalars['Boolean']>;
  /** beneficiary benefit usage */
  beneficiaryBenefitUsage?: Maybe<BeneficiaryBenefitUsageType>;
  breakdown?: Maybe<ClaimBreakdownType>;
  /** ClaimCptCode list */
  claimcptcode?: Maybe<Array<Maybe<ClaimCptCodeType>>>;
  /** ClaimIcdCode list */
  claimicdcode?: Maybe<Array<Maybe<ClaimIcdCodeType>>>;
  codesExclusion?: Maybe<Array<CodesExclusionType>>;
  cptCodes?: Maybe<Array<Maybe<CptCodeType>>>;
  /** created */
  created?: Maybe<Scalars['CustomDateTime']>;
  /** Card list */
  defaultForCards?: Maybe<Array<Maybe<CardType>>>;
  /** eligible amount */
  eligibleAmount?: Maybe<Scalars['Float']>;
  icdCodes?: Maybe<Array<Maybe<IcdCodeType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  isDummy?: Maybe<Scalars['Boolean']>;
  /** max amount */
  maxAmount?: Maybe<Scalars['Float']>;
  /** merchant */
  merchant?: Maybe<MerchantType>;
  /** PaymentAuthorization list */
  paymentAuthorizations?: Maybe<Array<Maybe<PaymentAuthorizationType>>>;
  payments?: Maybe<Array<Maybe<PaymentInterface>>>;
  preAuthorization?: Maybe<PreAuthorizationType>;
  /** Receipt list */
  receipts?: Maybe<Array<Maybe<ReceiptType>>>;
  /** reference id */
  referenceId?: Maybe<Scalars['String']>;
  /** requested by */
  requestedBy?: Maybe<BeneficiaryType>;
  retroactive?: Maybe<Scalars['Boolean']>;
  /** source */
  source?: Maybe<ClaimSourceEnum>;
  /** state */
  state?: Maybe<ClaimStateEnum>;
  /** SupportingDocument list */
  supportingDocuments?: Maybe<Array<Maybe<SupportingDocumentType>>>;
  /** transaction amount */
  transactionAmount?: Maybe<Scalars['Float']>;
  /** updated */
  updated?: Maybe<Scalars['CustomDateTime']>;
  notes?: Maybe<Array<Maybe<ClaimNoteType>>>;
  netting?: Maybe<NettingOutputType>;
  creatorId?: Maybe<Scalars['ID']>;
  currency?: Maybe<CurrencyType>;
  policy?: Maybe<PolicyType>;
  benefit?: Maybe<BenefitType>;
  beneficiary?: Maybe<BeneficiaryType>;
  acceptedAmount?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  amountToBeCharged?: Maybe<Scalars['Float']>;
  amountToBeReimbursed?: Maybe<Scalars['Float']>;
  numberOfPayments?: Maybe<Scalars['Int']>;
  /** Remaining amount for the claim preauth if there is any */
  remainingAmount?: Maybe<Scalars['Float']>;
  cardState?: Maybe<CardState>;
  validForIcd?: Maybe<ValidForIcdCodeType>;
};

/**
 * Schema for claims
 *
 * There are two classes of claims - instant claims produced by payment platform and standard claims.
 *
 * Standard claims require pre-authorization to be present.
 * To authorize such a claim, the claim needs to be within user's claims authority.
 * If the user is not permitted to perform authorization, they can still refer it to users with higher
 * claims authority. Please note, that these two actions are the two only occasions when `pre_authorization`
 * and `max_amount` are processed.
 *
 * Should insurer require changing decision about the claim, only users with specific permissions are allowed to
 * reset a claim.
 *
 * With regards to merchants, please note that to accept a claim you either need a verified merchant to be specified,
 * or the system will mark the merchant specified as a verified one.
 *
 * Note: `accepted` Claim with not all `ACCEPTED` receipts will be in `PARTIALLY_ACCEPTED` state.
 *
 * Note: While accepting a Claim with not yet verified Merchant, that Merchant becomes verified.
 *
 * Path hints:
 * ----------
 * - beneficiary: 'beneficiaryBenefitUsage.beneficiary`
 *
 *
 * Mutations:
 * ----------
 *     Update
 *         claimUpdate(newClaim: {...})
 *
 *         There are 5 update actions which are tne ({triggered based on the input argument:
 *
 *             1) `newDecison` : Claim's state decision actions are triggered
 *             2) `merchantChange` : Claim's merchant is changed
 *             3) `benefitChange` : `beneficiary_benefit_usage` is changed according  to the new benefit
 *             4) `newNote` : Note is attached to the claim as a notification -> claim is not updated
 *             5) `additionalInfoChange` : Additional information is added or updated on the claim
 */
export type ClaimTypeAggregatedPreauthsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  cardProxyId_Isnull?: Maybe<Scalars['Boolean']>;
  cardProxyId?: Maybe<Scalars['String']>;
  cardProxyId_Iexact?: Maybe<Scalars['String']>;
  cardProxyId_Contains?: Maybe<Scalars['String']>;
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  cardProxyId_Startswith?: Maybe<Scalars['String']>;
  cardProxyId_Istartswith?: Maybe<Scalars['String']>;
  cardProxyId_Endswith?: Maybe<Scalars['String']>;
  cardProxyId_Iendswith?: Maybe<Scalars['String']>;
  cardProxyId_In?: Maybe<Scalars['String']>;
  currencyCode_Isnull?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_Iexact?: Maybe<Scalars['String']>;
  currencyCode_Contains?: Maybe<Scalars['String']>;
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  currencyCode_Startswith?: Maybe<Scalars['String']>;
  currencyCode_Istartswith?: Maybe<Scalars['String']>;
  currencyCode_Endswith?: Maybe<Scalars['String']>;
  currencyCode_Iendswith?: Maybe<Scalars['String']>;
  currencyCode_In?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Isnull?: Maybe<Scalars['Boolean']>;
  optionalCurrencyCode?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Iexact?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Contains?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Icontains?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Startswith?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Istartswith?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Endswith?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Iendswith?: Maybe<Scalars['String']>;
  optionalCurrencyCode_In?: Maybe<Scalars['String']>;
  countryCode_Isnull?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_Iexact?: Maybe<Scalars['String']>;
  countryCode_Contains?: Maybe<Scalars['String']>;
  countryCode_Icontains?: Maybe<Scalars['String']>;
  countryCode_Startswith?: Maybe<Scalars['String']>;
  countryCode_Istartswith?: Maybe<Scalars['String']>;
  countryCode_Endswith?: Maybe<Scalars['String']>;
  countryCode_Iendswith?: Maybe<Scalars['String']>;
  countryCode_In?: Maybe<Scalars['String']>;
  merchantCategoryCode_Isnull?: Maybe<Scalars['Boolean']>;
  merchantCategoryCode?: Maybe<Scalars['String']>;
  merchantCategoryCode_Iexact?: Maybe<Scalars['String']>;
  merchantCategoryCode_Contains?: Maybe<Scalars['String']>;
  merchantCategoryCode_Icontains?: Maybe<Scalars['String']>;
  merchantCategoryCode_Startswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Istartswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Endswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Iendswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_In?: Maybe<Scalars['String']>;
  merchantName_Isnull?: Maybe<Scalars['Boolean']>;
  merchantName?: Maybe<Scalars['String']>;
  merchantName_Iexact?: Maybe<Scalars['String']>;
  merchantName_Contains?: Maybe<Scalars['String']>;
  merchantName_Icontains?: Maybe<Scalars['String']>;
  merchantName_Startswith?: Maybe<Scalars['String']>;
  merchantName_Istartswith?: Maybe<Scalars['String']>;
  merchantName_Endswith?: Maybe<Scalars['String']>;
  merchantName_Iendswith?: Maybe<Scalars['String']>;
  merchantName_In?: Maybe<Scalars['String']>;
  merchantId_Isnull?: Maybe<Scalars['Boolean']>;
  merchantId?: Maybe<Scalars['String']>;
  merchantId_Iexact?: Maybe<Scalars['String']>;
  merchantId_Contains?: Maybe<Scalars['String']>;
  merchantId_Icontains?: Maybe<Scalars['String']>;
  merchantId_Startswith?: Maybe<Scalars['String']>;
  merchantId_Istartswith?: Maybe<Scalars['String']>;
  merchantId_Endswith?: Maybe<Scalars['String']>;
  merchantId_Iendswith?: Maybe<Scalars['String']>;
  merchantId_In?: Maybe<Scalars['String']>;
  merchantAddress_Isnull?: Maybe<Scalars['Boolean']>;
  merchantAddress?: Maybe<Scalars['String']>;
  merchantGpsLatitude_Isnull?: Maybe<Scalars['Boolean']>;
  merchantGpsLatitude?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_Gt?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_Gte?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_Lt?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_Lte?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_In?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_Isnull?: Maybe<Scalars['Boolean']>;
  merchantGpsLongitude?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_Gt?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_Gte?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_Lt?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_Lte?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_In?: Maybe<Scalars['Float']>;
  timeWindowFrom_Isnull?: Maybe<Scalars['Boolean']>;
  timeWindowFrom?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_Gt?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_Gte?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_Lt?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_Lte?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_In?: Maybe<Scalars['DateTime']>;
  timeWindowTo_Isnull?: Maybe<Scalars['Boolean']>;
  timeWindowTo?: Maybe<Scalars['DateTime']>;
  timeWindowTo_Gt?: Maybe<Scalars['DateTime']>;
  timeWindowTo_Gte?: Maybe<Scalars['DateTime']>;
  timeWindowTo_Lt?: Maybe<Scalars['DateTime']>;
  timeWindowTo_Lte?: Maybe<Scalars['DateTime']>;
  timeWindowTo_In?: Maybe<Scalars['DateTime']>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  updatedAt_Isnull?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gte?: Maybe<Scalars['DateTime']>;
  updatedAt_Lt?: Maybe<Scalars['DateTime']>;
  updatedAt_Lte?: Maybe<Scalars['DateTime']>;
  updatedAt_In?: Maybe<Scalars['DateTime']>;
};

/**
 * Schema for claims
 *
 * There are two classes of claims - instant claims produced by payment platform and standard claims.
 *
 * Standard claims require pre-authorization to be present.
 * To authorize such a claim, the claim needs to be within user's claims authority.
 * If the user is not permitted to perform authorization, they can still refer it to users with higher
 * claims authority. Please note, that these two actions are the two only occasions when `pre_authorization`
 * and `max_amount` are processed.
 *
 * Should insurer require changing decision about the claim, only users with specific permissions are allowed to
 * reset a claim.
 *
 * With regards to merchants, please note that to accept a claim you either need a verified merchant to be specified,
 * or the system will mark the merchant specified as a verified one.
 *
 * Note: `accepted` Claim with not all `ACCEPTED` receipts will be in `PARTIALLY_ACCEPTED` state.
 *
 * Note: While accepting a Claim with not yet verified Merchant, that Merchant becomes verified.
 *
 * Path hints:
 * ----------
 * - beneficiary: 'beneficiaryBenefitUsage.beneficiary`
 *
 *
 * Mutations:
 * ----------
 *     Update
 *         claimUpdate(newClaim: {...})
 *
 *         There are 5 update actions which are tne ({triggered based on the input argument:
 *
 *             1) `newDecison` : Claim's state decision actions are triggered
 *             2) `merchantChange` : Claim's merchant is changed
 *             3) `benefitChange` : `beneficiary_benefit_usage` is changed according  to the new benefit
 *             4) `newNote` : Note is attached to the claim as a notification -> claim is not updated
 *             5) `additionalInfoChange` : Additional information is added or updated on the claim
 */
export type ClaimTypeClaimcptcodeArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cptCode_Isnull?: Maybe<Scalars['Boolean']>;
  cptCode?: Maybe<CptCodeInputFilterObject>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  default_Isnull?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
};

/**
 * Schema for claims
 *
 * There are two classes of claims - instant claims produced by payment platform and standard claims.
 *
 * Standard claims require pre-authorization to be present.
 * To authorize such a claim, the claim needs to be within user's claims authority.
 * If the user is not permitted to perform authorization, they can still refer it to users with higher
 * claims authority. Please note, that these two actions are the two only occasions when `pre_authorization`
 * and `max_amount` are processed.
 *
 * Should insurer require changing decision about the claim, only users with specific permissions are allowed to
 * reset a claim.
 *
 * With regards to merchants, please note that to accept a claim you either need a verified merchant to be specified,
 * or the system will mark the merchant specified as a verified one.
 *
 * Note: `accepted` Claim with not all `ACCEPTED` receipts will be in `PARTIALLY_ACCEPTED` state.
 *
 * Note: While accepting a Claim with not yet verified Merchant, that Merchant becomes verified.
 *
 * Path hints:
 * ----------
 * - beneficiary: 'beneficiaryBenefitUsage.beneficiary`
 *
 *
 * Mutations:
 * ----------
 *     Update
 *         claimUpdate(newClaim: {...})
 *
 *         There are 5 update actions which are tne ({triggered based on the input argument:
 *
 *             1) `newDecison` : Claim's state decision actions are triggered
 *             2) `merchantChange` : Claim's merchant is changed
 *             3) `benefitChange` : `beneficiary_benefit_usage` is changed according  to the new benefit
 *             4) `newNote` : Note is attached to the claim as a notification -> claim is not updated
 *             5) `additionalInfoChange` : Additional information is added or updated on the claim
 */
export type ClaimTypeClaimicdcodeArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  icdCode_Isnull?: Maybe<Scalars['Boolean']>;
  icdCode?: Maybe<IcdCodeInputFilterObject>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  default_Isnull?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
};

/**
 * Schema for claims
 *
 * There are two classes of claims - instant claims produced by payment platform and standard claims.
 *
 * Standard claims require pre-authorization to be present.
 * To authorize such a claim, the claim needs to be within user's claims authority.
 * If the user is not permitted to perform authorization, they can still refer it to users with higher
 * claims authority. Please note, that these two actions are the two only occasions when `pre_authorization`
 * and `max_amount` are processed.
 *
 * Should insurer require changing decision about the claim, only users with specific permissions are allowed to
 * reset a claim.
 *
 * With regards to merchants, please note that to accept a claim you either need a verified merchant to be specified,
 * or the system will mark the merchant specified as a verified one.
 *
 * Note: `accepted` Claim with not all `ACCEPTED` receipts will be in `PARTIALLY_ACCEPTED` state.
 *
 * Note: While accepting a Claim with not yet verified Merchant, that Merchant becomes verified.
 *
 * Path hints:
 * ----------
 * - beneficiary: 'beneficiaryBenefitUsage.beneficiary`
 *
 *
 * Mutations:
 * ----------
 *     Update
 *         claimUpdate(newClaim: {...})
 *
 *         There are 5 update actions which are tne ({triggered based on the input argument:
 *
 *             1) `newDecison` : Claim's state decision actions are triggered
 *             2) `merchantChange` : Claim's merchant is changed
 *             3) `benefitChange` : `beneficiary_benefit_usage` is changed according  to the new benefit
 *             4) `newNote` : Note is attached to the claim as a notification -> claim is not updated
 *             5) `additionalInfoChange` : Additional information is added or updated on the claim
 */
export type ClaimTypeDefaultForCardsArgs = {
  actions_Isnull?: Maybe<Scalars['Boolean']>;
  actions?: Maybe<CardActionInputFilterObject>;
  aggregatedbeneficiarycard_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedbeneficiarycard?: Maybe<AggregatedBeneficiaryCardInputFilterObject>;
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  proxyId_Isnull?: Maybe<Scalars['Boolean']>;
  proxyId?: Maybe<Scalars['String']>;
  proxyId_Iexact?: Maybe<Scalars['String']>;
  proxyId_Contains?: Maybe<Scalars['String']>;
  proxyId_Icontains?: Maybe<Scalars['String']>;
  proxyId_Startswith?: Maybe<Scalars['String']>;
  proxyId_Istartswith?: Maybe<Scalars['String']>;
  proxyId_Endswith?: Maybe<Scalars['String']>;
  proxyId_Iendswith?: Maybe<Scalars['String']>;
  proxyId_In?: Maybe<Scalars['String']>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  nameOnCard_Isnull?: Maybe<Scalars['Boolean']>;
  nameOnCard?: Maybe<Scalars['String']>;
  nameOnCard_Iexact?: Maybe<Scalars['String']>;
  nameOnCard_Contains?: Maybe<Scalars['String']>;
  nameOnCard_Icontains?: Maybe<Scalars['String']>;
  nameOnCard_Startswith?: Maybe<Scalars['String']>;
  nameOnCard_Istartswith?: Maybe<Scalars['String']>;
  nameOnCard_Endswith?: Maybe<Scalars['String']>;
  nameOnCard_Iendswith?: Maybe<Scalars['String']>;
  nameOnCard_In?: Maybe<Scalars['String']>;
  lastDigits_Isnull?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastDigits_Iexact?: Maybe<Scalars['String']>;
  lastDigits_Contains?: Maybe<Scalars['String']>;
  lastDigits_Icontains?: Maybe<Scalars['String']>;
  lastDigits_Startswith?: Maybe<Scalars['String']>;
  lastDigits_Istartswith?: Maybe<Scalars['String']>;
  lastDigits_Endswith?: Maybe<Scalars['String']>;
  lastDigits_Iendswith?: Maybe<Scalars['String']>;
  lastDigits_In?: Maybe<Scalars['String']>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['String']>;
  expiration_Iexact?: Maybe<Scalars['String']>;
  expiration_Contains?: Maybe<Scalars['String']>;
  expiration_Icontains?: Maybe<Scalars['String']>;
  expiration_Startswith?: Maybe<Scalars['String']>;
  expiration_Istartswith?: Maybe<Scalars['String']>;
  expiration_Endswith?: Maybe<Scalars['String']>;
  expiration_Iendswith?: Maybe<Scalars['String']>;
  expiration_In?: Maybe<Scalars['String']>;
  policyChain_Isnull?: Maybe<Scalars['Boolean']>;
  policyChain?: Maybe<PolicyChainInputFilterObject>;
  isFrozen_Isnull?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isBlocked_Isnull?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeactivated_Isnull?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isCancelled_Isnull?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isIssued_Isnull?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered_Isnull?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  defaultClaim_Isnull?: Maybe<Scalars['Boolean']>;
  defaultClaim?: Maybe<ClaimInputFilterObject>;
  currentBeneficiaryForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentBeneficiaryForPayments?: Maybe<BeneficiaryInputFilterObject>;
  cardType_Isnull?: Maybe<Scalars['Boolean']>;
  cardType?: Maybe<Scalars['String']>;
  cardType_Iexact?: Maybe<Scalars['String']>;
  cardType_Contains?: Maybe<Scalars['String']>;
  cardType_Icontains?: Maybe<Scalars['String']>;
  cardType_Startswith?: Maybe<Scalars['String']>;
  cardType_Istartswith?: Maybe<Scalars['String']>;
  cardType_Endswith?: Maybe<Scalars['String']>;
  cardType_Iendswith?: Maybe<Scalars['String']>;
  cardType_In?: Maybe<Scalars['String']>;
  aggregationProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationProcessedAt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedAt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_In?: Maybe<Scalars['Int']>;
  idForPublicRetrieval_Isnull?: Maybe<Scalars['Boolean']>;
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  sentSmses_Isnull?: Maybe<Scalars['Boolean']>;
  sentSmses?: Maybe<SmsInputFilterObject>;
  policyId?: Maybe<Scalars['String']>;
};

/**
 * Schema for claims
 *
 * There are two classes of claims - instant claims produced by payment platform and standard claims.
 *
 * Standard claims require pre-authorization to be present.
 * To authorize such a claim, the claim needs to be within user's claims authority.
 * If the user is not permitted to perform authorization, they can still refer it to users with higher
 * claims authority. Please note, that these two actions are the two only occasions when `pre_authorization`
 * and `max_amount` are processed.
 *
 * Should insurer require changing decision about the claim, only users with specific permissions are allowed to
 * reset a claim.
 *
 * With regards to merchants, please note that to accept a claim you either need a verified merchant to be specified,
 * or the system will mark the merchant specified as a verified one.
 *
 * Note: `accepted` Claim with not all `ACCEPTED` receipts will be in `PARTIALLY_ACCEPTED` state.
 *
 * Note: While accepting a Claim with not yet verified Merchant, that Merchant becomes verified.
 *
 * Path hints:
 * ----------
 * - beneficiary: 'beneficiaryBenefitUsage.beneficiary`
 *
 *
 * Mutations:
 * ----------
 *     Update
 *         claimUpdate(newClaim: {...})
 *
 *         There are 5 update actions which are tne ({triggered based on the input argument:
 *
 *             1) `newDecison` : Claim's state decision actions are triggered
 *             2) `merchantChange` : Claim's merchant is changed
 *             3) `benefitChange` : `beneficiary_benefit_usage` is changed according  to the new benefit
 *             4) `newNote` : Note is attached to the claim as a notification -> claim is not updated
 *             5) `additionalInfoChange` : Additional information is added or updated on the claim
 */
export type ClaimTypePaymentAuthorizationsArgs = {
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  attempts_Isnull?: Maybe<Scalars['Boolean']>;
  attempts?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  authorizationId_Isnull?: Maybe<Scalars['Boolean']>;
  authorizationId?: Maybe<Scalars['String']>;
  authorizationId_Iexact?: Maybe<Scalars['String']>;
  authorizationId_Contains?: Maybe<Scalars['String']>;
  authorizationId_Icontains?: Maybe<Scalars['String']>;
  authorizationId_Startswith?: Maybe<Scalars['String']>;
  authorizationId_Istartswith?: Maybe<Scalars['String']>;
  authorizationId_Endswith?: Maybe<Scalars['String']>;
  authorizationId_Iendswith?: Maybe<Scalars['String']>;
  authorizationId_In?: Maybe<Scalars['String']>;
  cardProxyId_Isnull?: Maybe<Scalars['Boolean']>;
  cardProxyId?: Maybe<Scalars['String']>;
  cardProxyId_Iexact?: Maybe<Scalars['String']>;
  cardProxyId_Contains?: Maybe<Scalars['String']>;
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  cardProxyId_Startswith?: Maybe<Scalars['String']>;
  cardProxyId_Istartswith?: Maybe<Scalars['String']>;
  cardProxyId_Endswith?: Maybe<Scalars['String']>;
  cardProxyId_Iendswith?: Maybe<Scalars['String']>;
  cardProxyId_In?: Maybe<Scalars['String']>;
  beneficiaryId_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryId?: Maybe<Scalars['Int']>;
  beneficiaryId_Gt?: Maybe<Scalars['Int']>;
  beneficiaryId_Gte?: Maybe<Scalars['Int']>;
  beneficiaryId_Lt?: Maybe<Scalars['Int']>;
  beneficiaryId_Lte?: Maybe<Scalars['Int']>;
  beneficiaryId_In?: Maybe<Scalars['Int']>;
  merchantName_Isnull?: Maybe<Scalars['Boolean']>;
  merchantName?: Maybe<Scalars['String']>;
  merchantName_Iexact?: Maybe<Scalars['String']>;
  merchantName_Contains?: Maybe<Scalars['String']>;
  merchantName_Icontains?: Maybe<Scalars['String']>;
  merchantName_Startswith?: Maybe<Scalars['String']>;
  merchantName_Istartswith?: Maybe<Scalars['String']>;
  merchantName_Endswith?: Maybe<Scalars['String']>;
  merchantName_Iendswith?: Maybe<Scalars['String']>;
  merchantName_In?: Maybe<Scalars['String']>;
  merchantExternalId_Isnull?: Maybe<Scalars['Boolean']>;
  merchantExternalId?: Maybe<Scalars['String']>;
  merchantExternalId_Iexact?: Maybe<Scalars['String']>;
  merchantExternalId_Contains?: Maybe<Scalars['String']>;
  merchantExternalId_Icontains?: Maybe<Scalars['String']>;
  merchantExternalId_Startswith?: Maybe<Scalars['String']>;
  merchantExternalId_Istartswith?: Maybe<Scalars['String']>;
  merchantExternalId_Endswith?: Maybe<Scalars['String']>;
  merchantExternalId_Iendswith?: Maybe<Scalars['String']>;
  merchantExternalId_In?: Maybe<Scalars['String']>;
  merchantAddress_Isnull?: Maybe<Scalars['Boolean']>;
  merchantAddress?: Maybe<Scalars['String']>;
  merchantCountryCode_Isnull?: Maybe<Scalars['Boolean']>;
  merchantCountryCode?: Maybe<Scalars['String']>;
  merchantCountryCode_Iexact?: Maybe<Scalars['String']>;
  merchantCountryCode_Contains?: Maybe<Scalars['String']>;
  merchantCountryCode_Icontains?: Maybe<Scalars['String']>;
  merchantCountryCode_Startswith?: Maybe<Scalars['String']>;
  merchantCountryCode_Istartswith?: Maybe<Scalars['String']>;
  merchantCountryCode_Endswith?: Maybe<Scalars['String']>;
  merchantCountryCode_Iendswith?: Maybe<Scalars['String']>;
  merchantCountryCode_In?: Maybe<Scalars['String']>;
  merchantCategoryCode_Isnull?: Maybe<Scalars['Boolean']>;
  merchantCategoryCode?: Maybe<Scalars['String']>;
  merchantCategoryCode_Iexact?: Maybe<Scalars['String']>;
  merchantCategoryCode_Contains?: Maybe<Scalars['String']>;
  merchantCategoryCode_Icontains?: Maybe<Scalars['String']>;
  merchantCategoryCode_Startswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Istartswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Endswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Iendswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_In?: Maybe<Scalars['String']>;
  currencyCode_Isnull?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_Iexact?: Maybe<Scalars['String']>;
  currencyCode_Contains?: Maybe<Scalars['String']>;
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  currencyCode_Startswith?: Maybe<Scalars['String']>;
  currencyCode_Istartswith?: Maybe<Scalars['String']>;
  currencyCode_Endswith?: Maybe<Scalars['String']>;
  currencyCode_Iendswith?: Maybe<Scalars['String']>;
  currencyCode_In?: Maybe<Scalars['String']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
};

/**
 * Schema for claims
 *
 * There are two classes of claims - instant claims produced by payment platform and standard claims.
 *
 * Standard claims require pre-authorization to be present.
 * To authorize such a claim, the claim needs to be within user's claims authority.
 * If the user is not permitted to perform authorization, they can still refer it to users with higher
 * claims authority. Please note, that these two actions are the two only occasions when `pre_authorization`
 * and `max_amount` are processed.
 *
 * Should insurer require changing decision about the claim, only users with specific permissions are allowed to
 * reset a claim.
 *
 * With regards to merchants, please note that to accept a claim you either need a verified merchant to be specified,
 * or the system will mark the merchant specified as a verified one.
 *
 * Note: `accepted` Claim with not all `ACCEPTED` receipts will be in `PARTIALLY_ACCEPTED` state.
 *
 * Note: While accepting a Claim with not yet verified Merchant, that Merchant becomes verified.
 *
 * Path hints:
 * ----------
 * - beneficiary: 'beneficiaryBenefitUsage.beneficiary`
 *
 *
 * Mutations:
 * ----------
 *     Update
 *         claimUpdate(newClaim: {...})
 *
 *         There are 5 update actions which are tne ({triggered based on the input argument:
 *
 *             1) `newDecison` : Claim's state decision actions are triggered
 *             2) `merchantChange` : Claim's merchant is changed
 *             3) `benefitChange` : `beneficiary_benefit_usage` is changed according  to the new benefit
 *             4) `newNote` : Note is attached to the claim as a notification -> claim is not updated
 *             5) `additionalInfoChange` : Additional information is added or updated on the claim
 */
export type ClaimTypeReceiptsArgs = {
  items_Isnull?: Maybe<Scalars['Boolean']>;
  items?: Maybe<ReceiptItemInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Scalars['DateTime']>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Scalars['String']>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Scalars['DateTime']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  paymentAuthorizationId_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizationId?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iexact?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Contains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Icontains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Startswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Istartswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Endswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iendswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_In?: Maybe<Scalars['String']>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Scalars['Float']>;
  transactionAt_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gte?: Maybe<Scalars['DateTime']>;
  transactionAt_Lt?: Maybe<Scalars['DateTime']>;
  transactionAt_Lte?: Maybe<Scalars['DateTime']>;
  transactionAt_In?: Maybe<Scalars['DateTime']>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Scalars['String']>;
};

/**
 * Schema for claims
 *
 * There are two classes of claims - instant claims produced by payment platform and standard claims.
 *
 * Standard claims require pre-authorization to be present.
 * To authorize such a claim, the claim needs to be within user's claims authority.
 * If the user is not permitted to perform authorization, they can still refer it to users with higher
 * claims authority. Please note, that these two actions are the two only occasions when `pre_authorization`
 * and `max_amount` are processed.
 *
 * Should insurer require changing decision about the claim, only users with specific permissions are allowed to
 * reset a claim.
 *
 * With regards to merchants, please note that to accept a claim you either need a verified merchant to be specified,
 * or the system will mark the merchant specified as a verified one.
 *
 * Note: `accepted` Claim with not all `ACCEPTED` receipts will be in `PARTIALLY_ACCEPTED` state.
 *
 * Note: While accepting a Claim with not yet verified Merchant, that Merchant becomes verified.
 *
 * Path hints:
 * ----------
 * - beneficiary: 'beneficiaryBenefitUsage.beneficiary`
 *
 *
 * Mutations:
 * ----------
 *     Update
 *         claimUpdate(newClaim: {...})
 *
 *         There are 5 update actions which are tne ({triggered based on the input argument:
 *
 *             1) `newDecison` : Claim's state decision actions are triggered
 *             2) `merchantChange` : Claim's merchant is changed
 *             3) `benefitChange` : `beneficiary_benefit_usage` is changed according  to the new benefit
 *             4) `newNote` : Note is attached to the claim as a notification -> claim is not updated
 *             5) `additionalInfoChange` : Additional information is added or updated on the claim
 */
export type ClaimTypeSupportingDocumentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Scalars['DateTime']>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Scalars['String']>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Scalars['DateTime']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Scalars['String']>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
};

/**
 * Schema for claims
 *
 * There are two classes of claims - instant claims produced by payment platform and standard claims.
 *
 * Standard claims require pre-authorization to be present.
 * To authorize such a claim, the claim needs to be within user's claims authority.
 * If the user is not permitted to perform authorization, they can still refer it to users with higher
 * claims authority. Please note, that these two actions are the two only occasions when `pre_authorization`
 * and `max_amount` are processed.
 *
 * Should insurer require changing decision about the claim, only users with specific permissions are allowed to
 * reset a claim.
 *
 * With regards to merchants, please note that to accept a claim you either need a verified merchant to be specified,
 * or the system will mark the merchant specified as a verified one.
 *
 * Note: `accepted` Claim with not all `ACCEPTED` receipts will be in `PARTIALLY_ACCEPTED` state.
 *
 * Note: While accepting a Claim with not yet verified Merchant, that Merchant becomes verified.
 *
 * Path hints:
 * ----------
 * - beneficiary: 'beneficiaryBenefitUsage.beneficiary`
 *
 *
 * Mutations:
 * ----------
 *     Update
 *         claimUpdate(newClaim: {...})
 *
 *         There are 5 update actions which are tne ({triggered based on the input argument:
 *
 *             1) `newDecison` : Claim's state decision actions are triggered
 *             2) `merchantChange` : Claim's merchant is changed
 *             3) `benefitChange` : `beneficiary_benefit_usage` is changed according  to the new benefit
 *             4) `newNote` : Note is attached to the claim as a notification -> claim is not updated
 *             5) `additionalInfoChange` : Additional information is added or updated on the claim
 */
export type ClaimTypeValidForIcdArgs = {
  icdCode: Scalars['ID'];
};

export type CodesExclusionInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  description_Icontains?: Maybe<Scalars['String']>;
  /** entity id */
  entityId?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Gt?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Lt?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Gte?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Lte?: Maybe<Scalars['Int']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityId_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
};

export type CodesExclusionInputType = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  entityTypeName: Scalars['String'];
  entityId: Scalars['ID'];
  icdCodes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cptCodes?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CodesExclusionListType = {
  __typename?: 'CodesExclusionListType';
  /** CodesExclusion list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CodesExclusionType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CodesExclusionListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CodesExclusionMutation = {
  __typename?: 'CodesExclusionMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  codesexclusion?: Maybe<CodesExclusionType>;
};

/**
 * Icd codes exclusion for a given model.
 *
 * Valid `entity_type_name` are:
 *  - claim
 *  - beneficiary
 *  - policy
 */
export type CodesExclusionType = {
  __typename?: 'CodesExclusionType';
  /** Claim list */
  claim?: Maybe<Array<Maybe<ClaimType>>>;
  /** CptCode list */
  cptCodes?: Maybe<Array<Maybe<CptCodeType>>>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** entity id */
  entityId?: Maybe<Scalars['Int']>;
  /** entity type */
  entityType?: Maybe<ContentTypeType>;
  /** IcdCode list */
  icdCodes?: Maybe<Array<Maybe<IcdCodeType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** name */
  name?: Maybe<Scalars['String']>;
};

/**
 * Icd codes exclusion for a given model.
 *
 * Valid `entity_type_name` are:
 *  - claim
 *  - beneficiary
 *  - policy
 */
export type CodesExclusionTypeClaimArgs = {
  claimicdcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<ClaimIcdCodeInputFilterObject>;
  claimcptcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimcptcode?: Maybe<ClaimCptCodeInputFilterObject>;
  breakdown_Isnull?: Maybe<Scalars['Boolean']>;
  breakdown?: Maybe<ClaimBreakdownInputFilterObject>;
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  receipts_Isnull?: Maybe<Scalars['Boolean']>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  defaultForCards_Isnull?: Maybe<Scalars['Boolean']>;
  defaultForCards?: Maybe<CardInputFilterObject>;
  aggregatedPreauths_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedPreauths?: Maybe<AggregatedPreauthInputFilterObject>;
  payments_Isnull?: Maybe<Scalars['Boolean']>;
  payments?: Maybe<PaymentInputFilterObject>;
  paymentAuthorizations_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizations?: Maybe<PaymentAuthorizationInputFilterObject>;
  account_Isnull?: Maybe<Scalars['Boolean']>;
  account?: Maybe<AccountInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchant_Isnull?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantInputFilterObject>;
  beneficiaryBenefitUsage_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryBenefitUsage?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  retroactive_Isnull?: Maybe<Scalars['Boolean']>;
  retroactive?: Maybe<Scalars['Boolean']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  maxAmount_Isnull?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Float']>;
  maxAmount_Gt?: Maybe<Scalars['Float']>;
  maxAmount_Gte?: Maybe<Scalars['Float']>;
  maxAmount_Lt?: Maybe<Scalars['Float']>;
  maxAmount_Lte?: Maybe<Scalars['Float']>;
  maxAmount_In?: Maybe<Scalars['Float']>;
  eligibleAmount_Isnull?: Maybe<Scalars['Boolean']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  eligibleAmount_Gt?: Maybe<Scalars['Float']>;
  eligibleAmount_Gte?: Maybe<Scalars['Float']>;
  eligibleAmount_Lt?: Maybe<Scalars['Float']>;
  eligibleAmount_Lte?: Maybe<Scalars['Float']>;
  eligibleAmount_In?: Maybe<Scalars['Float']>;
  transactionAmount_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAmount?: Maybe<Scalars['Float']>;
  transactionAmount_Gt?: Maybe<Scalars['Float']>;
  transactionAmount_Gte?: Maybe<Scalars['Float']>;
  transactionAmount_Lt?: Maybe<Scalars['Float']>;
  transactionAmount_Lte?: Maybe<Scalars['Float']>;
  transactionAmount_In?: Maybe<Scalars['Float']>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  isDummy_Isnull?: Maybe<Scalars['Boolean']>;
  isDummy?: Maybe<Scalars['Boolean']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  allowTransferringToFuture_Isnull?: Maybe<Scalars['Boolean']>;
  allowTransferringToFuture?: Maybe<Scalars['Boolean']>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  created_Isnull?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  created_Gt?: Maybe<Scalars['DateTime']>;
  created_Gte?: Maybe<Scalars['DateTime']>;
  created_Lt?: Maybe<Scalars['DateTime']>;
  created_Lte?: Maybe<Scalars['DateTime']>;
  created_In?: Maybe<Scalars['DateTime']>;
  updated_Isnull?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['DateTime']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  updated_In?: Maybe<Scalars['DateTime']>;
  referenceId_Isnull?: Maybe<Scalars['Boolean']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_Iexact?: Maybe<Scalars['String']>;
  referenceId_Contains?: Maybe<Scalars['String']>;
  referenceId_Icontains?: Maybe<Scalars['String']>;
  referenceId_Startswith?: Maybe<Scalars['String']>;
  referenceId_Istartswith?: Maybe<Scalars['String']>;
  referenceId_Endswith?: Maybe<Scalars['String']>;
  referenceId_Iendswith?: Maybe<Scalars['String']>;
  referenceId_In?: Maybe<Scalars['String']>;
  icdCodes_Isnull?: Maybe<Scalars['Boolean']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes_Isnull?: Maybe<Scalars['Boolean']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
  isStagnant?: Maybe<Scalars['Boolean']>;
  isInstant?: Maybe<Scalars['Boolean']>;
  benefitCategory?: Maybe<BenefitCategoryInputFilterObject>;
  benefit?: Maybe<BenefitInputFilterObject>;
  policy?: Maybe<PolicyInputFilterObject>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  beneficiaryIdWithIncludedDependants?: Maybe<Scalars['String']>;
  beneficiaryIdInWithIncludedDependants?: Maybe<Scalars['String']>;
  source_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardState?: Maybe<Scalars['String']>;
  codesExclusion?: Maybe<CodesExclusionInputFilterObject>;
};

/**
 * Icd codes exclusion for a given model.
 *
 * Valid `entity_type_name` are:
 *  - claim
 *  - beneficiary
 *  - policy
 */
export type CodesExclusionTypeCptCodesArgs = {
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimcptcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimcptcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  exclusion_Isnull?: Maybe<Scalars['Boolean']>;
  exclusion?: Maybe<CodesExclusionInputFilterObject>;
  cptcode_Isnull?: Maybe<Scalars['Boolean']>;
  cptcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<CptCodeInputFilterObject>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Scalars['String']>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  codeSet_Isnull?: Maybe<Scalars['Boolean']>;
  codeSet?: Maybe<CptCodeSetInputFilterObject>;
  code_Startswith_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameOrCode_Icontains?: Maybe<Scalars['String']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
};

/**
 * Icd codes exclusion for a given model.
 *
 * Valid `entity_type_name` are:
 *  - claim
 *  - beneficiary
 *  - policy
 */
export type CodesExclusionTypeIcdCodesArgs = {
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimicdcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  receiptItems_Isnull?: Maybe<Scalars['Boolean']>;
  receiptItems?: Maybe<ReceiptItemInputFilterObject>;
  exclusion_Isnull?: Maybe<Scalars['Boolean']>;
  exclusion?: Maybe<CodesExclusionInputFilterObject>;
  icdcode_Isnull?: Maybe<Scalars['Boolean']>;
  icdcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<IcdCodeInputFilterObject>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Scalars['String']>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  codeSet_Isnull?: Maybe<Scalars['Boolean']>;
  codeSet?: Maybe<IcdCodeSetInputFilterObject>;
  code_Startswith_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameOrCode_Icontains?: Maybe<Scalars['String']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
};

export type ContentTypeInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** app label */
  appLabel?: Maybe<Scalars['String']>;
  /** app label */
  appLabel_Icontains?: Maybe<Scalars['String']>;
  /** python model class name */
  model?: Maybe<Scalars['String']>;
  /** python model class name */
  model_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  appLabel_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  model_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  permission?: Maybe<PermissionInputFilterObject>;
  codesexclusion?: Maybe<CodesExclusionInputFilterObject>;
  notification?: Maybe<NotificationInputFilterObject>;
  cardevent?: Maybe<CardEventInputFilterObject>;
  logentry?: Maybe<LogEntryInputFilterObject>;
  jobtask?: Maybe<JobTaskInputFilterObject>;
};

export type ContentTypeType = {
  __typename?: 'ContentTypeType';
  /** app label */
  appLabel?: Maybe<Scalars['String']>;
  /** CardEvent list */
  cardevent?: Maybe<Array<Maybe<CardEventType>>>;
  /** CodesExclusion list */
  codesexclusion?: Maybe<Array<Maybe<CodesExclusionType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** python model class name */
  model?: Maybe<Scalars['String']>;
  /** Notification list */
  notification?: Maybe<Array<Maybe<NotificationType>>>;
};

export type ContentTypeTypeCardeventArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Scalars['DateTime']>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Scalars['DateTime']>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Scalars['Int']>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  transactionSource_Isnull?: Maybe<Scalars['Boolean']>;
  transactionSource?: Maybe<Scalars['String']>;
  transactionSource_Iexact?: Maybe<Scalars['String']>;
  transactionSource_Contains?: Maybe<Scalars['String']>;
  transactionSource_Icontains?: Maybe<Scalars['String']>;
  transactionSource_Startswith?: Maybe<Scalars['String']>;
  transactionSource_Istartswith?: Maybe<Scalars['String']>;
  transactionSource_Endswith?: Maybe<Scalars['String']>;
  transactionSource_Iendswith?: Maybe<Scalars['String']>;
  transactionSource_In?: Maybe<Scalars['String']>;
  transactionType_Isnull?: Maybe<Scalars['Boolean']>;
  transactionType?: Maybe<Scalars['String']>;
  transactionType_Iexact?: Maybe<Scalars['String']>;
  transactionType_Contains?: Maybe<Scalars['String']>;
  transactionType_Icontains?: Maybe<Scalars['String']>;
  transactionType_Startswith?: Maybe<Scalars['String']>;
  transactionType_Istartswith?: Maybe<Scalars['String']>;
  transactionType_Endswith?: Maybe<Scalars['String']>;
  transactionType_Iendswith?: Maybe<Scalars['String']>;
  transactionType_In?: Maybe<Scalars['String']>;
  transactionId_Isnull?: Maybe<Scalars['Boolean']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionId_Iexact?: Maybe<Scalars['String']>;
  transactionId_Contains?: Maybe<Scalars['String']>;
  transactionId_Icontains?: Maybe<Scalars['String']>;
  transactionId_Startswith?: Maybe<Scalars['String']>;
  transactionId_Istartswith?: Maybe<Scalars['String']>;
  transactionId_Endswith?: Maybe<Scalars['String']>;
  transactionId_Iendswith?: Maybe<Scalars['String']>;
  transactionId_In?: Maybe<Scalars['String']>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['String']>;
  entityId_Iexact?: Maybe<Scalars['String']>;
  entityId_Contains?: Maybe<Scalars['String']>;
  entityId_Icontains?: Maybe<Scalars['String']>;
  entityId_Startswith?: Maybe<Scalars['String']>;
  entityId_Istartswith?: Maybe<Scalars['String']>;
  entityId_Endswith?: Maybe<Scalars['String']>;
  entityId_Iendswith?: Maybe<Scalars['String']>;
  entityId_In?: Maybe<Scalars['String']>;
};

export type ContentTypeTypeCodesexclusionArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['Int']>;
  entityId_Gt?: Maybe<Scalars['Int']>;
  entityId_Gte?: Maybe<Scalars['Int']>;
  entityId_Lt?: Maybe<Scalars['Int']>;
  entityId_Lte?: Maybe<Scalars['Int']>;
  entityId_In?: Maybe<Scalars['Int']>;
  icdCodes_Isnull?: Maybe<Scalars['Boolean']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes_Isnull?: Maybe<Scalars['Boolean']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
};

export type ContentTypeTypeNotificationArgs = {
  mobileNotifications_Isnull?: Maybe<Scalars['Boolean']>;
  mobileNotifications?: Maybe<MobileNotificationInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Scalars['DateTime']>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Scalars['DateTime']>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Scalars['Int']>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  action_Isnull?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_Iexact?: Maybe<Scalars['String']>;
  action_Contains?: Maybe<Scalars['String']>;
  action_Icontains?: Maybe<Scalars['String']>;
  action_Startswith?: Maybe<Scalars['String']>;
  action_Istartswith?: Maybe<Scalars['String']>;
  action_Endswith?: Maybe<Scalars['String']>;
  action_Iendswith?: Maybe<Scalars['String']>;
  action_In?: Maybe<Scalars['String']>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Scalars['DateTime']>;
  read_Isnull?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['String']>;
  entityId_Iexact?: Maybe<Scalars['String']>;
  entityId_Contains?: Maybe<Scalars['String']>;
  entityId_Icontains?: Maybe<Scalars['String']>;
  entityId_Startswith?: Maybe<Scalars['String']>;
  entityId_Istartswith?: Maybe<Scalars['String']>;
  entityId_Endswith?: Maybe<Scalars['String']>;
  entityId_Iendswith?: Maybe<Scalars['String']>;
  entityId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  source?: Maybe<Scalars['String']>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claimsAuthority?: Maybe<Scalars['Float']>;
  search?: Maybe<NotificationSearchInputType>;
  claimId?: Maybe<Scalars['String']>;
};

export type CorporatePolicyChainInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** external id */
  externalId_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  externalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  corporations?: Maybe<CorporationInputFilterObject>;
  chains?: Maybe<PolicyChainInputFilterObject>;
};

export type CorporatePolicyChainListType = {
  __typename?: 'CorporatePolicyChainListType';
  /** CorporatePolicyChain list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CorporatePolicyChainType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CorporatePolicyChainListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CorporatePolicyChainMutation = {
  __typename?: 'CorporatePolicyChainMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  corporatepolicychain?: Maybe<CorporatePolicyChainType>;
};

export type CorporatePolicyChainType = {
  __typename?: 'CorporatePolicyChainType';
  /** PolicyChain list */
  chains?: Maybe<Array<Maybe<PolicyChainType>>>;
  /** Corporation list */
  corporations?: Maybe<Array<Maybe<CorporationType>>>;
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  chainsIds?: Maybe<Array<Maybe<PolicyChainType>>>;
};

export type CorporatePolicyChainTypeChainsArgs = {
  policies_Isnull?: Maybe<Scalars['Boolean']>;
  policies?: Maybe<PolicyInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_Isnull?: Maybe<Scalars['Boolean']>;
  product?: Maybe<ProductInputFilterObject>;
  corporatePolicyChain_Isnull?: Maybe<Scalars['Boolean']>;
  corporatePolicyChain?: Maybe<CorporatePolicyChainInputFilterObject>;
  holders_Isnull?: Maybe<Scalars['Boolean']>;
  holders?: Maybe<PolicyHolderInputFilterObject>;
  corporations?: Maybe<CorporationInputFilterObject>;
  corporate?: Maybe<Scalars['Boolean']>;
};

export type CorporatePolicyChainTypeCorporationsArgs = {
  chains_Isnull?: Maybe<Scalars['Boolean']>;
  chains?: Maybe<CorporatePolicyChainInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Scalars['String']>;
  companyName_Isnull?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
  companyName_Iexact?: Maybe<Scalars['String']>;
  companyName_Contains?: Maybe<Scalars['String']>;
  companyName_Icontains?: Maybe<Scalars['String']>;
  companyName_Startswith?: Maybe<Scalars['String']>;
  companyName_Istartswith?: Maybe<Scalars['String']>;
  companyName_Endswith?: Maybe<Scalars['String']>;
  companyName_Iendswith?: Maybe<Scalars['String']>;
  companyName_In?: Maybe<Scalars['String']>;
  address_Isnull?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  address_Iexact?: Maybe<Scalars['String']>;
  address_Contains?: Maybe<Scalars['String']>;
  address_Icontains?: Maybe<Scalars['String']>;
  address_Startswith?: Maybe<Scalars['String']>;
  address_Istartswith?: Maybe<Scalars['String']>;
  address_Endswith?: Maybe<Scalars['String']>;
  address_Iendswith?: Maybe<Scalars['String']>;
  address_In?: Maybe<Scalars['String']>;
  contactName_Isnull?: Maybe<Scalars['Boolean']>;
  contactName?: Maybe<Scalars['String']>;
  contactName_Iexact?: Maybe<Scalars['String']>;
  contactName_Contains?: Maybe<Scalars['String']>;
  contactName_Icontains?: Maybe<Scalars['String']>;
  contactName_Startswith?: Maybe<Scalars['String']>;
  contactName_Istartswith?: Maybe<Scalars['String']>;
  contactName_Endswith?: Maybe<Scalars['String']>;
  contactName_Iendswith?: Maybe<Scalars['String']>;
  contactName_In?: Maybe<Scalars['String']>;
  contactEmail_Isnull?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactEmail_Iexact?: Maybe<Scalars['String']>;
  contactEmail_Contains?: Maybe<Scalars['String']>;
  contactEmail_Icontains?: Maybe<Scalars['String']>;
  contactEmail_Startswith?: Maybe<Scalars['String']>;
  contactEmail_Istartswith?: Maybe<Scalars['String']>;
  contactEmail_Endswith?: Maybe<Scalars['String']>;
  contactEmail_Iendswith?: Maybe<Scalars['String']>;
  contactEmail_In?: Maybe<Scalars['String']>;
  contactPhone_Isnull?: Maybe<Scalars['Boolean']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactPhone_Iexact?: Maybe<Scalars['String']>;
  contactPhone_Contains?: Maybe<Scalars['String']>;
  contactPhone_Icontains?: Maybe<Scalars['String']>;
  contactPhone_Startswith?: Maybe<Scalars['String']>;
  contactPhone_Istartswith?: Maybe<Scalars['String']>;
  contactPhone_Endswith?: Maybe<Scalars['String']>;
  contactPhone_Iendswith?: Maybe<Scalars['String']>;
  contactPhone_In?: Maybe<Scalars['String']>;
  corporations?: Maybe<CorporationInputFilterObject>;
};

/**
 * Address
 *
 * Can be created by the Customer mutation
 */
export type CorporationAddressType = {
  __typename?: 'CorporationAddressType';
  /** address refinement */
  addressRefinement?: Maybe<Scalars['String']>;
  /** city */
  city?: Maybe<Scalars['String']>;
  /** country */
  country?: Maybe<CountryType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** raw */
  raw?: Maybe<Scalars['String']>;
  /** region */
  region?: Maybe<Scalars['String']>;
  /** street name */
  streetName?: Maybe<Scalars['String']>;
  /** street number */
  streetNumber?: Maybe<Scalars['String']>;
  /** zip code */
  zipCode?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
};

export type CorporationInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** external id */
  externalId_Icontains?: Maybe<Scalars['String']>;
  /** company name */
  companyName?: Maybe<Scalars['String']>;
  /** company name */
  companyName_Icontains?: Maybe<Scalars['String']>;
  /** address */
  address?: Maybe<Scalars['String']>;
  /** address */
  address_Icontains?: Maybe<Scalars['String']>;
  /** contact name */
  contactName?: Maybe<Scalars['String']>;
  /** contact name */
  contactName_Icontains?: Maybe<Scalars['String']>;
  /** contact email */
  contactEmail?: Maybe<Scalars['String']>;
  /** contact email */
  contactEmail_Icontains?: Maybe<Scalars['String']>;
  /** contact phone */
  contactPhone?: Maybe<Scalars['String']>;
  /** contact phone */
  contactPhone_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  externalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactEmail_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactPhone_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  corporations?: Maybe<CorporationInputFilterObject>;
  chains?: Maybe<CorporatePolicyChainInputFilterObject>;
};

export type CorporationListType = {
  __typename?: 'CorporationListType';
  /** Corporation list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CorporationType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CorporationListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CorporationMutation = {
  __typename?: 'CorporationMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  corporation?: Maybe<CorporationType>;
};

export type CorporationType = {
  __typename?: 'CorporationType';
  /** address */
  address?: Maybe<Scalars['String']>;
  /** CorporatePolicyChain list */
  chains?: Maybe<Array<Maybe<CorporatePolicyChainType>>>;
  /** company name */
  companyName?: Maybe<Scalars['String']>;
  /** contact email */
  contactEmail?: Maybe<Scalars['String']>;
  /** contact name */
  contactName?: Maybe<Scalars['String']>;
  /** contact phone */
  contactPhone?: Maybe<Scalars['String']>;
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
};

export type CorporationTypeChainsArgs = {
  chains_Isnull?: Maybe<Scalars['Boolean']>;
  chains?: Maybe<PolicyChainInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Scalars['String']>;
  corporations_Isnull?: Maybe<Scalars['Boolean']>;
  corporations?: Maybe<CorporationInputFilterObject>;
};

export type CountryInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** code */
  code?: Maybe<Scalars['String']>;
  /** code */
  code_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurers?: Maybe<InsurerInputFilterObject>;
  merchants?: Maybe<MerchantInputFilterObject>;
  address?: Maybe<AddressInputFilterObject>;
};

export type CountryListType = {
  __typename?: 'CountryListType';
  /** Country list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CountryType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CountryListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CountryRuleType = PaymentPlatformRuleType & {
  __typename?: 'CountryRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['String']>;
  actual?: Maybe<Scalars['String']>;
};

export type CountryType = {
  __typename?: 'CountryType';
  /** Address list */
  address?: Maybe<Array<Maybe<CorporationAddressType>>>;
  /** code */
  code?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** Insurer list */
  insurers?: Maybe<Array<Maybe<InsurerType>>>;
  /** Merchant list */
  merchants?: Maybe<Array<Maybe<MerchantType>>>;
  /** name */
  name?: Maybe<Scalars['String']>;
};

export type CountryTypeAddressArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  streetName_Isnull?: Maybe<Scalars['Boolean']>;
  streetName?: Maybe<Scalars['String']>;
  streetName_Iexact?: Maybe<Scalars['String']>;
  streetName_Contains?: Maybe<Scalars['String']>;
  streetName_Icontains?: Maybe<Scalars['String']>;
  streetName_Startswith?: Maybe<Scalars['String']>;
  streetName_Istartswith?: Maybe<Scalars['String']>;
  streetName_Endswith?: Maybe<Scalars['String']>;
  streetName_Iendswith?: Maybe<Scalars['String']>;
  streetName_In?: Maybe<Scalars['String']>;
  streetNumber_Isnull?: Maybe<Scalars['Boolean']>;
  streetNumber?: Maybe<Scalars['String']>;
  streetNumber_Iexact?: Maybe<Scalars['String']>;
  streetNumber_Contains?: Maybe<Scalars['String']>;
  streetNumber_Icontains?: Maybe<Scalars['String']>;
  streetNumber_Startswith?: Maybe<Scalars['String']>;
  streetNumber_Istartswith?: Maybe<Scalars['String']>;
  streetNumber_Endswith?: Maybe<Scalars['String']>;
  streetNumber_Iendswith?: Maybe<Scalars['String']>;
  streetNumber_In?: Maybe<Scalars['String']>;
  addressRefinement_Isnull?: Maybe<Scalars['Boolean']>;
  addressRefinement?: Maybe<Scalars['String']>;
  addressRefinement_Iexact?: Maybe<Scalars['String']>;
  addressRefinement_Contains?: Maybe<Scalars['String']>;
  addressRefinement_Icontains?: Maybe<Scalars['String']>;
  addressRefinement_Startswith?: Maybe<Scalars['String']>;
  addressRefinement_Istartswith?: Maybe<Scalars['String']>;
  addressRefinement_Endswith?: Maybe<Scalars['String']>;
  addressRefinement_Iendswith?: Maybe<Scalars['String']>;
  addressRefinement_In?: Maybe<Scalars['String']>;
  zipCode_Isnull?: Maybe<Scalars['Boolean']>;
  zipCode?: Maybe<Scalars['String']>;
  zipCode_Iexact?: Maybe<Scalars['String']>;
  zipCode_Contains?: Maybe<Scalars['String']>;
  zipCode_Icontains?: Maybe<Scalars['String']>;
  zipCode_Startswith?: Maybe<Scalars['String']>;
  zipCode_Istartswith?: Maybe<Scalars['String']>;
  zipCode_Endswith?: Maybe<Scalars['String']>;
  zipCode_Iendswith?: Maybe<Scalars['String']>;
  zipCode_In?: Maybe<Scalars['String']>;
  city_Isnull?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  city_Iexact?: Maybe<Scalars['String']>;
  city_Contains?: Maybe<Scalars['String']>;
  city_Icontains?: Maybe<Scalars['String']>;
  city_Startswith?: Maybe<Scalars['String']>;
  city_Istartswith?: Maybe<Scalars['String']>;
  city_Endswith?: Maybe<Scalars['String']>;
  city_Iendswith?: Maybe<Scalars['String']>;
  city_In?: Maybe<Scalars['String']>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryInputFilterObject>;
  region_Isnull?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  region_Iexact?: Maybe<Scalars['String']>;
  region_Contains?: Maybe<Scalars['String']>;
  region_Icontains?: Maybe<Scalars['String']>;
  region_Startswith?: Maybe<Scalars['String']>;
  region_Istartswith?: Maybe<Scalars['String']>;
  region_Endswith?: Maybe<Scalars['String']>;
  region_Iendswith?: Maybe<Scalars['String']>;
  region_In?: Maybe<Scalars['String']>;
  raw_Isnull?: Maybe<Scalars['Boolean']>;
  raw?: Maybe<Scalars['String']>;
  raw_Iexact?: Maybe<Scalars['String']>;
  raw_Contains?: Maybe<Scalars['String']>;
  raw_Icontains?: Maybe<Scalars['String']>;
  raw_Startswith?: Maybe<Scalars['String']>;
  raw_Istartswith?: Maybe<Scalars['String']>;
  raw_Endswith?: Maybe<Scalars['String']>;
  raw_Iendswith?: Maybe<Scalars['String']>;
  raw_In?: Maybe<Scalars['String']>;
};

export type CountryTypeInsurersArgs = {
  employees_Isnull?: Maybe<Scalars['Boolean']>;
  employees?: Maybe<InsurerEmployeeInputFilterObject>;
  workDays_Isnull?: Maybe<Scalars['Boolean']>;
  workDays?: Maybe<WorkDayInputFilterObject>;
  fundingAccounts_Isnull?: Maybe<Scalars['Boolean']>;
  fundingAccounts?: Maybe<FundingAccountInputFilterObject>;
  products_Isnull?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductInputFilterObject>;
  benefitCategories_Isnull?: Maybe<Scalars['Boolean']>;
  benefitCategories?: Maybe<BenefitCategoryInputFilterObject>;
  notification_Isnull?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<NotificationInputFilterObject>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryInputFilterObject>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  slug_Isnull?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_Iexact?: Maybe<Scalars['String']>;
  slug_Contains?: Maybe<Scalars['String']>;
  slug_Icontains?: Maybe<Scalars['String']>;
  slug_Startswith?: Maybe<Scalars['String']>;
  slug_Istartswith?: Maybe<Scalars['String']>;
  slug_Endswith?: Maybe<Scalars['String']>;
  slug_Iendswith?: Maybe<Scalars['String']>;
  slug_In?: Maybe<Scalars['String']>;
  phoneNumber_Isnull?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumber_Iexact?: Maybe<Scalars['String']>;
  phoneNumber_Contains?: Maybe<Scalars['String']>;
  phoneNumber_Icontains?: Maybe<Scalars['String']>;
  phoneNumber_Startswith?: Maybe<Scalars['String']>;
  phoneNumber_Istartswith?: Maybe<Scalars['String']>;
  phoneNumber_Endswith?: Maybe<Scalars['String']>;
  phoneNumber_Iendswith?: Maybe<Scalars['String']>;
  phoneNumber_In?: Maybe<Scalars['String']>;
  mobileAppPasswordValidity_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppPasswordValidity?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_Gt?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_Gte?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_Lt?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_Lte?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_In?: Maybe<Scalars['Int']>;
  mobileAppPasswordRegexp_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppPasswordRegexp?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Iexact?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Contains?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Icontains?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Startswith?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Istartswith?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Endswith?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Iendswith?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_In?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type CountryTypeMerchantsArgs = {
  claims_Isnull?: Maybe<Scalars['Boolean']>;
  claims?: Maybe<ClaimInputFilterObject>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryInputFilterObject>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Scalars['String']>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  address_Isnull?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  gpsLatitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLatitude?: Maybe<Scalars['Float']>;
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  gpsLatitude_In?: Maybe<Scalars['Float']>;
  gpsLongitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLongitude?: Maybe<Scalars['Float']>;
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  gpsLongitude_In?: Maybe<Scalars['Float']>;
  detailedInfo_Isnull?: Maybe<Scalars['Boolean']>;
  detailedInfo?: Maybe<Scalars['String']>;
  isVerifiedByAdmin_Isnull?: Maybe<Scalars['Boolean']>;
  isVerifiedByAdmin?: Maybe<Scalars['Boolean']>;
  categories_Isnull?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<MerchantCategoryInputFilterObject>;
  geo?: Maybe<Scalars['String']>;
};

export type CptCodeInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** code */
  code?: Maybe<Scalars['String']>;
  /** code */
  code_Icontains?: Maybe<Scalars['String']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  description_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent?: Maybe<CptCodeInputFilterObject>;
  codeSet?: Maybe<CptCodeSetInputFilterObject>;
  claim?: Maybe<ClaimInputFilterObject>;
  claimcptcode?: Maybe<ClaimCptCodeInputFilterObject>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
  exclusion?: Maybe<CodesExclusionInputFilterObject>;
  cptcode?: Maybe<CptCodeInputFilterObject>;
};

export type CptCodeListType = {
  __typename?: 'CptCodeListType';
  /** CptCode list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CptCodeType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CptCodeListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CptCodeSetInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** code */
  code?: Maybe<Scalars['String']>;
  /** code */
  code_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cptcode?: Maybe<CptCodeInputFilterObject>;
};

export type CptCodeSetListType = {
  __typename?: 'CptCodeSetListType';
  /** CptCodeSet list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CptCodeSetType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CptCodeSetListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CptCodeSetType = {
  __typename?: 'CptCodeSetType';
  /** code */
  code?: Maybe<Scalars['String']>;
  /** CptCode list */
  cptcode?: Maybe<Array<Maybe<CptCodeType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** name */
  name?: Maybe<Scalars['String']>;
};

export type CptCodeSetTypeCptcodeArgs = {
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimcptcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimcptcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  exclusion_Isnull?: Maybe<Scalars['Boolean']>;
  exclusion?: Maybe<CodesExclusionInputFilterObject>;
  cptcode_Isnull?: Maybe<Scalars['Boolean']>;
  cptcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<CptCodeInputFilterObject>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Scalars['String']>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  codeSet_Isnull?: Maybe<Scalars['Boolean']>;
  codeSet?: Maybe<CptCodeSetInputFilterObject>;
  code_Startswith_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameOrCode_Icontains?: Maybe<Scalars['String']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
};

/**
 * Represents CPT code entity belonging to a particular CPT code set. Please note that the `id` field
 *    contains a value without any business-related meaning. The actual code is stored within `code` field.
 *    Due to the fact there can be multiple code sets, values in the `code` field are not unique.
 */
export type CptCodeType = {
  __typename?: 'CptCodeType';
  /** code */
  code?: Maybe<Scalars['String']>;
  /** code set */
  codeSet?: Maybe<CptCodeSetType>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** CodesExclusion list */
  exclusion?: Maybe<Array<Maybe<CodesExclusionType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** name */
  name?: Maybe<Scalars['String']>;
  /** parent */
  parent?: Maybe<CptCodeType>;
};

/**
 * Represents CPT code entity belonging to a particular CPT code set. Please note that the `id` field
 *    contains a value without any business-related meaning. The actual code is stored within `code` field.
 *    Due to the fact there can be multiple code sets, values in the `code` field are not unique.
 */
export type CptCodeTypeExclusionArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['Int']>;
  entityId_Gt?: Maybe<Scalars['Int']>;
  entityId_Gte?: Maybe<Scalars['Int']>;
  entityId_Lt?: Maybe<Scalars['Int']>;
  entityId_Lte?: Maybe<Scalars['Int']>;
  entityId_In?: Maybe<Scalars['Int']>;
  icdCodes_Isnull?: Maybe<Scalars['Boolean']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes_Isnull?: Maybe<Scalars['Boolean']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
};

export type CreateBeneficiaryDataUnlockInputType = {
  isEnabled?: Maybe<Scalars['Boolean']>;
  /** used at */
  usedAt?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil?: Maybe<Scalars['CustomDateTime']>;
  /** beneficiary */
  beneficiaryId: Scalars['ID'];
  /** provider */
  providerId: Scalars['ID'];
};

export type CreateBeneficiaryInputType = {
  /** member since */
  memberSince: Scalars['CustomDate'];
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  cardAllowed?: Maybe<Scalars['Boolean']>;
  /** customer uuid */
  customerUuid?: Maybe<Scalars['UUID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  mobileAppAllowed?: Maybe<Scalars['Boolean']>;
  /** token */
  token?: Maybe<Scalars['String']>;
  parentsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  policiesIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customer?: Maybe<CustomerInputType>;
  dependantsIds?: Maybe<Array<Scalars['ID']>>;
  policyHolderId?: Maybe<Scalars['ID']>;
  usagesIds?: Maybe<Array<Scalars['ID']>>;
  mobileAppUserId?: Maybe<Scalars['ID']>;
  unlocksIds?: Maybe<Array<Scalars['ID']>>;
  requestedClaimsIds?: Maybe<Array<Scalars['ID']>>;
  cardsIds?: Maybe<Array<Scalars['ID']>>;
  currentCardsForPaymentsIds?: Maybe<Array<Scalars['ID']>>;
  cardActionsIds?: Maybe<Array<Scalars['ID']>>;
  paymentRulesOverridesIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateCardActionInputType = {
  /** source */
  source: CardActionSourceEnumCreate;
  /** action */
  action?: Maybe<CardActionActionEnumCreate>;
  /** comment */
  comment?: Maybe<Scalars['String']>;
  /** card */
  cardId: Scalars['ID'];
  /** requested by */
  requestedById?: Maybe<Scalars['ID']>;
};

export type CreateClaimCptCodeInputType = {
  default?: Maybe<Scalars['Boolean']>;
  /** cpt code */
  cptCodeId: Scalars['ID'];
  /** claim */
  claimId: Scalars['ID'];
};

export type CreateClaimIcdCodeInputType = {
  default?: Maybe<Scalars['Boolean']>;
  /** icd code */
  icdCodeId: Scalars['ID'];
  /** claim */
  claimId: Scalars['ID'];
};

export type CreateClaimInputType = {
  preAuthorization?: Maybe<PreAuthorizationTypeInput>;
  netting?: Maybe<NettingInputType>;
  benefitId?: Maybe<Scalars['ID']>;
  beneficiaryId: Scalars['ID'];
  policyId: Scalars['ID'];
  requestedById: Scalars['ID'];
  merchantId?: Maybe<Scalars['ID']>;
  maxAmount?: Maybe<Scalars['DecimalScalar']>;
  retroactive?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Source>;
  additionalInformation?: Maybe<Scalars['String']>;
};

export type CreateCorporatePolicyChainInputType = {
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  corporationsIds: Array<Scalars['ID']>;
  chainsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateCorporationInputType = {
  /** company name */
  companyName: Scalars['String'];
  /** external id */
  externalId: Scalars['String'];
  /** address */
  address?: Maybe<Scalars['String']>;
  /** contact email */
  contactEmail?: Maybe<Scalars['String']>;
  /** contact name */
  contactName?: Maybe<Scalars['String']>;
  /** contact phone */
  contactPhone?: Maybe<Scalars['String']>;
  chainsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateDocumentForClaimInputType = {
  claimId?: Maybe<Scalars['ID']>;
  fileType?: Maybe<File_Type>;
};

export type CreateMerchantInputType = {
  /** address */
  address: Scalars['String'];
  /** gps latitude */
  gpsLatitude: Scalars['Float'];
  /** gps longitude */
  gpsLongitude: Scalars['Float'];
  /** name */
  name: Scalars['String'];
  /** detailed info */
  detailedInfo?: Maybe<Scalars['String']>;
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  isVerifiedByAdmin?: Maybe<Scalars['Boolean']>;
  categoriesIds?: Maybe<Array<Scalars['ID']>>;
  /** country */
  countryId: Scalars['ID'];
  providerId?: Maybe<Scalars['ID']>;
  claimsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateMobileAppUserInputType = {
  /** password */
  password: Scalars['String'];
  /** username */
  username: Scalars['String'];
  /** last login */
  lastLogin?: Maybe<Scalars['CustomDateTime']>;
  /** password expiration */
  passwordExpiration?: Maybe<Scalars['CustomDateTime']>;
  /** beneficiary */
  beneficiaryId: Scalars['ID'];
  sessionsIds?: Maybe<Array<Scalars['ID']>>;
  locationStampsIds?: Maybe<Array<Scalars['ID']>>;
  mobileNotificationsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreatePersonalCardInputType = {
  /** proxy id */
  proxyId: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  /** policy holder */
  policyHolderId: Scalars['ID'];
  beneficiaryPaymentsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreatePolicyChainInputType = {
  holdersIds: Array<Scalars['ID']>;
  /** product */
  productId: Scalars['ID'];
  /** corporate policy chain */
  corporatePolicyChainId?: Maybe<Scalars['ID']>;
  policiesIds?: Maybe<Array<Scalars['ID']>>;
  cardsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreatePolicyHolderInputType = {
  /** customer uuid */
  customerUuid?: Maybe<Scalars['UUID']>;
  chainsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customer?: Maybe<CustomerInputType>;
  /** beneficiary */
  beneficiaryId?: Maybe<Scalars['ID']>;
  personalCardsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreatePolicyInputType = {
  /** expiration */
  expiration: Scalars['CustomDateTime'];
  /** external id */
  externalId: Scalars['String'];
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  beneficiariesIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** chain */
  chainId: Scalars['ID'];
  /** product version */
  productVersionId: Scalars['ID'];
  usagesIds?: Maybe<Array<Scalars['ID']>>;
  sessionsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateProviderEmployeeInputType = {
  /** provider */
  providerId: Scalars['ID'];
  /** user */
  userId: Scalars['ID'];
};

export type CreateProviderInputType = {
  claimicdcode?: Maybe<Array<Scalars['ID']>>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  receiveNotifications?: Maybe<Scalars['Boolean']>;
  /** merchant */
  merchantId: Scalars['ID'];
  providerEmployeesIds?: Maybe<Array<Scalars['ID']>>;
  unlocksIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateReceiptInputType = {
  filename?: Maybe<Scalars['String']>;
  /** source */
  source: ReceiptSourceEnumCreate;
  /**  resolved at */
  ResolvedAt?: Maybe<Scalars['CustomDateTime']>;
  /** file type */
  fileType?: Maybe<ReceiptFileTypeEnumCreate>;
  fxRate?: Maybe<Scalars['DecimalScalar']>;
  /** external payment authorization_id */
  paymentAuthorizationId?: Maybe<Scalars['String']>;
  transactionAt?: Maybe<Scalars['AutoParsedDateTime']>;
  currencyId?: Maybe<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
  /** claim */
  claimId: Scalars['ID'];
  itemsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateReceiptItemInputType = {
  /** amount */
  amount: Scalars['Float'];
  /** title */
  title: Scalars['String'];
  isAccepted?: Maybe<Scalars['Boolean']>;
  /** receipt */
  receiptId: Scalars['ID'];
  /** icd */
  icdId?: Maybe<Scalars['ID']>;
};

export type CreateRulesSetInputType = {
  /** name */
  name: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** product version */
  productVersionId: Scalars['ID'];
  rulesIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateSupportingDocumentInputType = {
  filename?: Maybe<Scalars['String']>;
  /** source */
  source: SupportingDocumentSourceEnumCreate;
  /**  resolved at */
  ResolvedAt?: Maybe<Scalars['CustomDateTime']>;
  /** file type */
  fileType?: Maybe<SupportingDocumentFileTypeEnumCreate>;
  file?: Maybe<Scalars['Upload']>;
  /** claim */
  claimId: Scalars['ID'];
  sentEmailsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateUserInputType = {
  logentry?: Maybe<Array<Scalars['ID']>>;
  notification?: Maybe<Array<Scalars['ID']>>;
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  userpasswordhistoryconfig?: Maybe<Array<Scalars['ID']>>;
  /** date joined */
  dateJoined?: Maybe<Scalars['CustomDateTime']>;
  /** email address */
  email?: Maybe<Scalars['String']>;
  /** first name */
  firstName?: Maybe<Scalars['String']>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Designates whether the user can log into this admin site. */
  isStaff?: Maybe<Scalars['Boolean']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: Maybe<Scalars['Boolean']>;
  /** last login */
  lastLogin?: Maybe<Scalars['CustomDateTime']>;
  /** last name */
  lastName?: Maybe<Scalars['String']>;
  groupsNames: Array<Maybe<Scalars['String']>>;
  groupsIds?: Maybe<Array<Scalars['ID']>>;
  userPermissionsIds?: Maybe<Array<Scalars['ID']>>;
  authTokenId?: Maybe<Scalars['ID']>;
  employeeId?: Maybe<Scalars['ID']>;
  passwordExpirationId?: Maybe<Scalars['ID']>;
  detailsId?: Maybe<Scalars['ID']>;
  providerEmployeeId?: Maybe<Scalars['ID']>;
  supportingDocumentsIds?: Maybe<Array<Scalars['ID']>>;
  resolvedSupportingDocumentsIds?: Maybe<Array<Scalars['ID']>>;
  receiptsIds?: Maybe<Array<Scalars['ID']>>;
  resolvedReceiptsIds?: Maybe<Array<Scalars['ID']>>;
  cardActionsIds?: Maybe<Array<Scalars['ID']>>;
};

export type CurrencyInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** code */
  code?: Maybe<Scalars['String']>;
  /** code */
  code_Icontains?: Maybe<Scalars['String']>;
  /** symbol before */
  symbolBefore?: Maybe<Scalars['String']>;
  /** symbol before */
  symbolBefore_Icontains?: Maybe<Scalars['String']>;
  /** symbol after */
  symbolAfter?: Maybe<Scalars['String']>;
  /** symbol after */
  symbolAfter_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  symbolBefore_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  symbolAfter_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fundingAccounts?: Maybe<FundingAccountInputFilterObject>;
  products?: Maybe<ProductInputFilterObject>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  journalEntries?: Maybe<JournalEntryInputFilterObject>;
};

export type CurrencyListType = {
  __typename?: 'CurrencyListType';
  /** Currency list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<CurrencyType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CurrencyListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type CurrencyRuleType = PaymentPlatformRuleType & {
  __typename?: 'CurrencyRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['String']>;
  actual?: Maybe<Scalars['String']>;
};

/**
 * Filters
 * ------
 * - q: autocomplete for name and code fields
 */
export type CurrencyType = {
  __typename?: 'CurrencyType';
  /** code */
  code?: Maybe<Scalars['String']>;
  /** FundingAccount list */
  fundingAccounts?: Maybe<Array<Maybe<FundingAccountType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** name */
  name?: Maybe<Scalars['String']>;
  /** Product list */
  products?: Maybe<Array<Maybe<ProductType>>>;
  /** Receipt list */
  receipts?: Maybe<Array<Maybe<ReceiptType>>>;
  /** symbol after */
  symbolAfter?: Maybe<Scalars['String']>;
  /** symbol before */
  symbolBefore?: Maybe<Scalars['String']>;
};

/**
 * Filters
 * ------
 * - q: autocomplete for name and code fields
 */
export type CurrencyTypeFundingAccountsArgs = {
  physicalCardMeta_Isnull?: Maybe<Scalars['Boolean']>;
  physicalCardMeta?: Maybe<PhysicalCardAccountMetaInputFilterObject>;
  virtualCardMeta_Isnull?: Maybe<Scalars['Boolean']>;
  virtualCardMeta?: Maybe<VirtualCardAccountMetaInputFilterObject>;
  blockedFunds_Isnull?: Maybe<Scalars['Boolean']>;
  blockedFunds?: Maybe<BlockedFundsInputFilterObject>;
  settlementFunds_Isnull?: Maybe<Scalars['Boolean']>;
  settlementFunds?: Maybe<SettlementFundsInputFilterObject>;
  topUps_Isnull?: Maybe<Scalars['Boolean']>;
  topUps?: Maybe<TopUpTransactionInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  issuer_Isnull?: Maybe<Scalars['Boolean']>;
  issuer?: Maybe<IssuerInputFilterObject>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Scalars['String']>;
  topUpsLastProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsLastProcessedAt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_In?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsStartProcessingFrom?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Gt?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Gte?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Lt?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Lte?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_In?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsFailedAt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_In?: Maybe<Scalars['DateTime']>;
  topUpsKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsKeepProcessing?: Maybe<Scalars['Boolean']>;
  topUpsFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsFailedCount?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Gt?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Gte?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Lt?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Lte?: Maybe<Scalars['Int']>;
  topUpsFailedCount_In?: Maybe<Scalars['Int']>;
  fundingOffsetAmount_Isnull?: Maybe<Scalars['Boolean']>;
  fundingOffsetAmount?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Gt?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Gte?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Lt?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Lte?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_In?: Maybe<Scalars['Float']>;
  iban_Isnull?: Maybe<Scalars['Boolean']>;
  iban?: Maybe<Scalars['String']>;
  iban_Iexact?: Maybe<Scalars['String']>;
  iban_Contains?: Maybe<Scalars['String']>;
  iban_Icontains?: Maybe<Scalars['String']>;
  iban_Startswith?: Maybe<Scalars['String']>;
  iban_Istartswith?: Maybe<Scalars['String']>;
  iban_Endswith?: Maybe<Scalars['String']>;
  iban_Iendswith?: Maybe<Scalars['String']>;
  iban_In?: Maybe<Scalars['String']>;
  bic_Isnull?: Maybe<Scalars['Boolean']>;
  bic?: Maybe<Scalars['String']>;
  bic_Iexact?: Maybe<Scalars['String']>;
  bic_Contains?: Maybe<Scalars['String']>;
  bic_Icontains?: Maybe<Scalars['String']>;
  bic_Startswith?: Maybe<Scalars['String']>;
  bic_Istartswith?: Maybe<Scalars['String']>;
  bic_Endswith?: Maybe<Scalars['String']>;
  bic_Iendswith?: Maybe<Scalars['String']>;
  bic_In?: Maybe<Scalars['String']>;
};

/**
 * Filters
 * ------
 * - q: autocomplete for name and code fields
 */
export type CurrencyTypeProductsArgs = {
  versions_Isnull?: Maybe<Scalars['Boolean']>;
  versions?: Maybe<ProductVersionInputFilterObject>;
  chains_Isnull?: Maybe<Scalars['Boolean']>;
  chains?: Maybe<PolicyChainInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  tag_Isnull?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['String']>;
  tag_Iexact?: Maybe<Scalars['String']>;
  tag_Contains?: Maybe<Scalars['String']>;
  tag_Icontains?: Maybe<Scalars['String']>;
  tag_Startswith?: Maybe<Scalars['String']>;
  tag_Istartswith?: Maybe<Scalars['String']>;
  tag_Endswith?: Maybe<Scalars['String']>;
  tag_Iendswith?: Maybe<Scalars['String']>;
  tag_In?: Maybe<Scalars['String']>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  tag_Exclude_Icontains?: Maybe<Scalars['String']>;
  tag_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * Filters
 * ------
 * - q: autocomplete for name and code fields
 */
export type CurrencyTypeReceiptsArgs = {
  items_Isnull?: Maybe<Scalars['Boolean']>;
  items?: Maybe<ReceiptItemInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Scalars['DateTime']>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Scalars['String']>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Scalars['DateTime']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  paymentAuthorizationId_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizationId?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iexact?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Contains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Icontains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Startswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Istartswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Endswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iendswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_In?: Maybe<Scalars['String']>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Scalars['Float']>;
  transactionAt_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gte?: Maybe<Scalars['DateTime']>;
  transactionAt_Lt?: Maybe<Scalars['DateTime']>;
  transactionAt_Lte?: Maybe<Scalars['DateTime']>;
  transactionAt_In?: Maybe<Scalars['DateTime']>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Scalars['String']>;
};

export type CustomerCountryInputType = {
  id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id_Gt?: Maybe<Scalars['Int']>;
  id_Lt?: Maybe<Scalars['Int']>;
  id_Gte?: Maybe<Scalars['Int']>;
  id_Lte?: Maybe<Scalars['Int']>;
  code_Icontains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};

export type CustomerCountryType = {
  __typename?: 'CustomerCountryType';
  id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomerInputType = {
  id?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  phoneNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInputType>;
  id_Icontains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  firstName_Icontains?: Maybe<Scalars['String']>;
  lastName_Icontains?: Maybe<Scalars['String']>;
  dateOfBirth_Gt?: Maybe<Scalars['Date']>;
  dateOfBirth_Lt?: Maybe<Scalars['Date']>;
  dateOfBirth_Gte?: Maybe<Scalars['Date']>;
  dateOfBirth_Lte?: Maybe<Scalars['Date']>;
  email_Icontains?: Maybe<Scalars['String']>;
  phoneNumber_Icontains?: Maybe<Scalars['String']>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  id?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  address?: Maybe<AddressType>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type DatetimeRangeType = {
  __typename?: 'DatetimeRangeType';
  valueFrom?: Maybe<Scalars['AutoParsedDateTime']>;
  valueTo?: Maybe<Scalars['AutoParsedDateTime']>;
};

export type DayreviewdurationfieldType = {
  __typename?: 'DayreviewdurationfieldType';
  preAuthorization?: Maybe<DurationsfieldType>;
  receipt?: Maybe<DurationsfieldType>;
};

export type DisableInputType = {
  id: Scalars['ID'];
};

export type DisableRuleSetInputType = {
  id: Scalars['ID'];
  active: Scalars['Boolean'];
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL';
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum Document_Action {
  Accept = 'ACCEPT',
  Decline = 'DECLINE',
  RequestNew = 'REQUEST_NEW',
  Reset = 'RESET'
}

export type DurationsfieldType = {
  __typename?: 'DurationsfieldType';
  duration?: Maybe<Scalars['Int']>;
  durationWorkHours?: Maybe<Scalars['Int']>;
  target?: Maybe<Scalars['Int']>;
};

export type EmailInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** sender */
  sender?: Maybe<Scalars['String']>;
  /** sender */
  sender_Icontains?: Maybe<Scalars['String']>;
  /** recipient */
  recipient?: Maybe<Scalars['String']>;
  /** recipient */
  recipient_Icontains?: Maybe<Scalars['String']>;
  /** subject */
  subject?: Maybe<Scalars['String']>;
  /** subject */
  subject_Icontains?: Maybe<Scalars['String']>;
  /** body */
  body?: Maybe<Scalars['String']>;
  /** body */
  body_Icontains?: Maybe<Scalars['String']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** message id */
  messageId?: Maybe<Scalars['UUID']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sender_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipient_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  subject_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  body_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  messageId_In?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  cards?: Maybe<CardInputFilterObject>;
  notifications?: Maybe<NotificationInputFilterObject>;
};

export type EmailType = {
  __typename?: 'EmailType';
  /** body */
  body?: Maybe<Scalars['String']>;
  /** Card list */
  cards?: Maybe<Array<Maybe<CardType>>>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** message id */
  messageId?: Maybe<Scalars['UUID']>;
  /** Notification list */
  notifications?: Maybe<Array<Maybe<NotificationType>>>;
  /** recipient */
  recipient?: Maybe<Scalars['String']>;
  /** sender */
  sender?: Maybe<Scalars['String']>;
  /** subject */
  subject?: Maybe<Scalars['String']>;
  /** SupportingDocument list */
  supportingDocuments?: Maybe<Array<Maybe<SupportingDocumentType>>>;
};

export type EmailTypeCardsArgs = {
  actions_Isnull?: Maybe<Scalars['Boolean']>;
  actions?: Maybe<CardActionInputFilterObject>;
  aggregatedbeneficiarycard_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedbeneficiarycard?: Maybe<AggregatedBeneficiaryCardInputFilterObject>;
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  proxyId_Isnull?: Maybe<Scalars['Boolean']>;
  proxyId?: Maybe<Scalars['String']>;
  proxyId_Iexact?: Maybe<Scalars['String']>;
  proxyId_Contains?: Maybe<Scalars['String']>;
  proxyId_Icontains?: Maybe<Scalars['String']>;
  proxyId_Startswith?: Maybe<Scalars['String']>;
  proxyId_Istartswith?: Maybe<Scalars['String']>;
  proxyId_Endswith?: Maybe<Scalars['String']>;
  proxyId_Iendswith?: Maybe<Scalars['String']>;
  proxyId_In?: Maybe<Scalars['String']>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  nameOnCard_Isnull?: Maybe<Scalars['Boolean']>;
  nameOnCard?: Maybe<Scalars['String']>;
  nameOnCard_Iexact?: Maybe<Scalars['String']>;
  nameOnCard_Contains?: Maybe<Scalars['String']>;
  nameOnCard_Icontains?: Maybe<Scalars['String']>;
  nameOnCard_Startswith?: Maybe<Scalars['String']>;
  nameOnCard_Istartswith?: Maybe<Scalars['String']>;
  nameOnCard_Endswith?: Maybe<Scalars['String']>;
  nameOnCard_Iendswith?: Maybe<Scalars['String']>;
  nameOnCard_In?: Maybe<Scalars['String']>;
  lastDigits_Isnull?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastDigits_Iexact?: Maybe<Scalars['String']>;
  lastDigits_Contains?: Maybe<Scalars['String']>;
  lastDigits_Icontains?: Maybe<Scalars['String']>;
  lastDigits_Startswith?: Maybe<Scalars['String']>;
  lastDigits_Istartswith?: Maybe<Scalars['String']>;
  lastDigits_Endswith?: Maybe<Scalars['String']>;
  lastDigits_Iendswith?: Maybe<Scalars['String']>;
  lastDigits_In?: Maybe<Scalars['String']>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['String']>;
  expiration_Iexact?: Maybe<Scalars['String']>;
  expiration_Contains?: Maybe<Scalars['String']>;
  expiration_Icontains?: Maybe<Scalars['String']>;
  expiration_Startswith?: Maybe<Scalars['String']>;
  expiration_Istartswith?: Maybe<Scalars['String']>;
  expiration_Endswith?: Maybe<Scalars['String']>;
  expiration_Iendswith?: Maybe<Scalars['String']>;
  expiration_In?: Maybe<Scalars['String']>;
  policyChain_Isnull?: Maybe<Scalars['Boolean']>;
  policyChain?: Maybe<PolicyChainInputFilterObject>;
  isFrozen_Isnull?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isBlocked_Isnull?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeactivated_Isnull?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isCancelled_Isnull?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isIssued_Isnull?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered_Isnull?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  defaultClaim_Isnull?: Maybe<Scalars['Boolean']>;
  defaultClaim?: Maybe<ClaimInputFilterObject>;
  currentBeneficiaryForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentBeneficiaryForPayments?: Maybe<BeneficiaryInputFilterObject>;
  cardType_Isnull?: Maybe<Scalars['Boolean']>;
  cardType?: Maybe<Scalars['String']>;
  cardType_Iexact?: Maybe<Scalars['String']>;
  cardType_Contains?: Maybe<Scalars['String']>;
  cardType_Icontains?: Maybe<Scalars['String']>;
  cardType_Startswith?: Maybe<Scalars['String']>;
  cardType_Istartswith?: Maybe<Scalars['String']>;
  cardType_Endswith?: Maybe<Scalars['String']>;
  cardType_Iendswith?: Maybe<Scalars['String']>;
  cardType_In?: Maybe<Scalars['String']>;
  aggregationProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationProcessedAt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedAt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_In?: Maybe<Scalars['Int']>;
  idForPublicRetrieval_Isnull?: Maybe<Scalars['Boolean']>;
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  sentSmses_Isnull?: Maybe<Scalars['Boolean']>;
  sentSmses?: Maybe<SmsInputFilterObject>;
  policyId?: Maybe<Scalars['String']>;
};

export type EmailTypeNotificationsArgs = {
  mobileNotifications_Isnull?: Maybe<Scalars['Boolean']>;
  mobileNotifications?: Maybe<MobileNotificationInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Scalars['DateTime']>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Scalars['DateTime']>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Scalars['Int']>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  action_Isnull?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_Iexact?: Maybe<Scalars['String']>;
  action_Contains?: Maybe<Scalars['String']>;
  action_Icontains?: Maybe<Scalars['String']>;
  action_Startswith?: Maybe<Scalars['String']>;
  action_Istartswith?: Maybe<Scalars['String']>;
  action_Endswith?: Maybe<Scalars['String']>;
  action_Iendswith?: Maybe<Scalars['String']>;
  action_In?: Maybe<Scalars['String']>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Scalars['DateTime']>;
  read_Isnull?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['String']>;
  entityId_Iexact?: Maybe<Scalars['String']>;
  entityId_Contains?: Maybe<Scalars['String']>;
  entityId_Icontains?: Maybe<Scalars['String']>;
  entityId_Startswith?: Maybe<Scalars['String']>;
  entityId_Istartswith?: Maybe<Scalars['String']>;
  entityId_Endswith?: Maybe<Scalars['String']>;
  entityId_Iendswith?: Maybe<Scalars['String']>;
  entityId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  source?: Maybe<Scalars['String']>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claimsAuthority?: Maybe<Scalars['Float']>;
  search?: Maybe<NotificationSearchInputType>;
  claimId?: Maybe<Scalars['String']>;
};

export type EmailTypeSupportingDocumentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Scalars['DateTime']>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Scalars['String']>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Scalars['DateTime']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Scalars['String']>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
};

export type ErrorType = {
  __typename?: 'ErrorType';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type ExpiringTokenInputType = {
  beneficiaryToken: Scalars['String'];
  dateOfBirth?: Maybe<Scalars['Date']>;
};

/** An enumeration. */
export enum File_Type {
  GuaranteeOfPayment = 'GUARANTEE_OF_PAYMENT',
  CustomerAgreement = 'CUSTOMER_AGREEMENT',
  SignedCustomerAgreement = 'SIGNED_CUSTOMER_AGREEMENT',
  EstimatedBill = 'ESTIMATED_BILL',
  InterimBill = 'INTERIM_BILL',
  Other = 'OTHER'
}

/** Available only in the UK, additional indicator about the trustworthiness of the merchant. */
export type FraudIndicatorMerchantRuleType = PaymentPlatformRuleType & {
  __typename?: 'FraudIndicatorMerchantRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Scalars['String']>;
};

export type FundingAccountInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** external ledger id */
  externalLedgerId?: Maybe<Scalars['String']>;
  /** external ledger id */
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  /** top ups last processed at */
  topUpsLastProcessedAt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups last processed at */
  topUpsLastProcessedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups last processed at */
  topUpsLastProcessedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups last processed at */
  topUpsLastProcessedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** top ups last processed at */
  topUpsLastProcessedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** top ups start processing from */
  topUpsStartProcessingFrom?: Maybe<Scalars['CustomDateTime']>;
  /** top ups start processing from */
  topUpsStartProcessingFrom_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups start processing from */
  topUpsStartProcessingFrom_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups start processing from */
  topUpsStartProcessingFrom_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** top ups start processing from */
  topUpsStartProcessingFrom_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** top ups failed at */
  topUpsFailedAt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups failed at */
  topUpsFailedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups failed at */
  topUpsFailedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups failed at */
  topUpsFailedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** top ups failed at */
  topUpsFailedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  topUpsKeepProcessing?: Maybe<Scalars['Boolean']>;
  /** top ups failed count */
  topUpsFailedCount?: Maybe<Scalars['Int']>;
  /** top ups failed count */
  topUpsFailedCount_Gt?: Maybe<Scalars['Int']>;
  /** top ups failed count */
  topUpsFailedCount_Lt?: Maybe<Scalars['Int']>;
  /** top ups failed count */
  topUpsFailedCount_Gte?: Maybe<Scalars['Int']>;
  /** top ups failed count */
  topUpsFailedCount_Lte?: Maybe<Scalars['Int']>;
  /** funding offset amount */
  fundingOffsetAmount?: Maybe<Scalars['Float']>;
  /** funding offset amount */
  fundingOffsetAmount_Gt?: Maybe<Scalars['Float']>;
  /** funding offset amount */
  fundingOffsetAmount_Lt?: Maybe<Scalars['Float']>;
  /** funding offset amount */
  fundingOffsetAmount_Gte?: Maybe<Scalars['Float']>;
  /** funding offset amount */
  fundingOffsetAmount_Lte?: Maybe<Scalars['Float']>;
  /** iban */
  iban?: Maybe<Scalars['String']>;
  /** iban */
  iban_Icontains?: Maybe<Scalars['String']>;
  /** bic */
  bic?: Maybe<Scalars['String']>;
  /** bic */
  bic_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  externalLedgerId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  topUpsLastProcessedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  topUpsStartProcessingFrom_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  topUpsFailedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  topUpsKeepProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  topUpsFailedCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fundingOffsetAmount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  iban_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  bic_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency?: Maybe<CurrencyInputFilterObject>;
  insurer?: Maybe<InsurerInputFilterObject>;
  issuer?: Maybe<IssuerInputFilterObject>;
  physicalCardMeta?: Maybe<PhysicalCardAccountMetaInputFilterObject>;
  virtualCardMeta?: Maybe<VirtualCardAccountMetaInputFilterObject>;
  blockedFunds?: Maybe<BlockedFundsInputFilterObject>;
  settlementFunds?: Maybe<SettlementFundsInputFilterObject>;
  topUps?: Maybe<TopUpTransactionInputFilterObject>;
};

export type FundingAccountListType = {
  __typename?: 'FundingAccountListType';
  /** FundingAccount list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<FundingAccountType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type FundingAccountListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type FundingAccountType = {
  __typename?: 'FundingAccountType';
  /** bic */
  bic?: Maybe<Scalars['String']>;
  blockedFunds?: Maybe<BlockedFundsType>;
  /** currency */
  currency?: Maybe<CurrencyType>;
  /** external ledger id */
  externalLedgerId?: Maybe<Scalars['String']>;
  /** funding offset amount */
  fundingOffsetAmount?: Maybe<Scalars['Float']>;
  /** iban */
  iban?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** insurer */
  insurer?: Maybe<InsurerType>;
  /** issuer */
  issuer?: Maybe<IssuerType>;
  physicalCardMeta?: Maybe<PhysicalCardAccountMetaType>;
  settlementFunds?: Maybe<SettlementFundsType>;
  /** TopUpTransaction list */
  topUps?: Maybe<Array<Maybe<TopUpTransactionType>>>;
  /** top ups failed at */
  topUpsFailedAt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups failed count */
  topUpsFailedCount?: Maybe<Scalars['Int']>;
  topUpsKeepProcessing?: Maybe<Scalars['Boolean']>;
  /** top ups last processed at */
  topUpsLastProcessedAt?: Maybe<Scalars['CustomDateTime']>;
  /** top ups start processing from */
  topUpsStartProcessingFrom?: Maybe<Scalars['CustomDateTime']>;
  virtualCardMeta?: Maybe<VirtualCardMetaAccountType>;
  statistics?: Maybe<StatisticsType>;
};

export type FundingAccountTypeTopUpsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fundingAccount_Isnull?: Maybe<Scalars['Boolean']>;
  fundingAccount?: Maybe<FundingAccountInputFilterObject>;
  externalTransactionId_Isnull?: Maybe<Scalars['Boolean']>;
  externalTransactionId?: Maybe<Scalars['String']>;
  externalTransactionId_Iexact?: Maybe<Scalars['String']>;
  externalTransactionId_Contains?: Maybe<Scalars['String']>;
  externalTransactionId_Icontains?: Maybe<Scalars['String']>;
  externalTransactionId_Startswith?: Maybe<Scalars['String']>;
  externalTransactionId_Istartswith?: Maybe<Scalars['String']>;
  externalTransactionId_Endswith?: Maybe<Scalars['String']>;
  externalTransactionId_Iendswith?: Maybe<Scalars['String']>;
  externalTransactionId_In?: Maybe<Scalars['String']>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  receivedAt_Isnull?: Maybe<Scalars['Boolean']>;
  receivedAt?: Maybe<Scalars['Date']>;
  receivedAt_Gt?: Maybe<Scalars['Date']>;
  receivedAt_Gte?: Maybe<Scalars['Date']>;
  receivedAt_Lt?: Maybe<Scalars['Date']>;
  receivedAt_Lte?: Maybe<Scalars['Date']>;
  receivedAt_In?: Maybe<Scalars['Date']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
};

/**  Auto generated Type for a model's GenericForeignKey field  */
export type GenericForeignKeyType = {
  __typename?: 'GenericForeignKeyType';
  appLabel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  modelName?: Maybe<Scalars['String']>;
};

/** Checks whether the transaction contained an extra amount as a gratuity fee. */
export type GratuityFeeRuleType = PaymentPlatformRuleType & {
  __typename?: 'GratuityFeeRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Boolean']>;
  actual?: Maybe<Scalars['Boolean']>;
};

export type GroupDetailsInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** Maximal amount that the users in the group can act on. To set no limit, please use "-1". */
  claimsAuthority?: Maybe<Scalars['Float']>;
  /** Maximal amount that the users in the group can act on. To set no limit, please use "-1". */
  claimsAuthority_Gt?: Maybe<Scalars['Float']>;
  /** Maximal amount that the users in the group can act on. To set no limit, please use "-1". */
  claimsAuthority_Lt?: Maybe<Scalars['Float']>;
  /** Maximal amount that the users in the group can act on. To set no limit, please use "-1". */
  claimsAuthority_Gte?: Maybe<Scalars['Float']>;
  /** Maximal amount that the users in the group can act on. To set no limit, please use "-1". */
  claimsAuthority_Lte?: Maybe<Scalars['Float']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  passwordValidity?: Maybe<Scalars['Int']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  passwordValidity_Gt?: Maybe<Scalars['Int']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  passwordValidity_Lt?: Maybe<Scalars['Int']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  passwordValidity_Gte?: Maybe<Scalars['Int']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  passwordValidity_Lte?: Maybe<Scalars['Int']>;
  /** password regexp */
  passwordRegexp?: Maybe<Scalars['String']>;
  /** password regexp */
  passwordRegexp_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimsAuthority_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  passwordValidity_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  passwordRegexp_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  group?: Maybe<GroupInputFilterObject>;
};

export type GroupDetailsType = {
  __typename?: 'GroupDetailsType';
  /** Maximal amount that the users in the group can act on. To set no limit, please use "-1". */
  claimsAuthority?: Maybe<Scalars['Float']>;
  /** group */
  group?: Maybe<GroupType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** password regexp */
  passwordRegexp?: Maybe<Scalars['String']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  passwordValidity?: Maybe<Scalars['Int']>;
};

export type GroupInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions?: Maybe<PermissionInputFilterObject>;
  groups?: Maybe<GroupInputFilterObject>;
  user?: Maybe<UserInputFilterObject>;
  details?: Maybe<GroupDetailsInputFilterObject>;
};

export type GroupListType = {
  __typename?: 'GroupListType';
  /** Group list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<GroupType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type GroupListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type GroupType = {
  __typename?: 'GroupType';
  details?: Maybe<GroupDetailsType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** name */
  name?: Maybe<Scalars['String']>;
  /** User list */
  user?: Maybe<Array<Maybe<UserType>>>;
};

export type GroupTypeUserArgs = {
  authToken_Isnull?: Maybe<Scalars['Boolean']>;
  authToken?: Maybe<TokenInputFilterObject>;
  employee_Isnull?: Maybe<Scalars['Boolean']>;
  employee?: Maybe<InsurerEmployeeInputFilterObject>;
  passwordExpiration_Isnull?: Maybe<Scalars['Boolean']>;
  passwordExpiration?: Maybe<PasswordExpirationInputFilterObject>;
  details_Isnull?: Maybe<Scalars['Boolean']>;
  details?: Maybe<UserDetailsInputFilterObject>;
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  resolvedSupportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  resolvedSupportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  receipts_Isnull?: Maybe<Scalars['Boolean']>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  resolvedReceipts_Isnull?: Maybe<Scalars['Boolean']>;
  resolvedReceipts?: Maybe<ReceiptInputFilterObject>;
  cardActions_Isnull?: Maybe<Scalars['Boolean']>;
  cardActions?: Maybe<CardActionInputFilterObject>;
  notification_Isnull?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<NotificationInputFilterObject>;
  providerEmployee_Isnull?: Maybe<Scalars['Boolean']>;
  providerEmployee?: Maybe<ProviderEmployeeInputFilterObject>;
  userpasswordhistoryconfig_Isnull?: Maybe<Scalars['Boolean']>;
  userpasswordhistoryconfig?: Maybe<UserPasswordHistoryConfigInputFilterObject>;
  logentry_Isnull?: Maybe<Scalars['Boolean']>;
  logentry?: Maybe<LogEntryInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  password_Isnull?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  password_Iexact?: Maybe<Scalars['String']>;
  password_Contains?: Maybe<Scalars['String']>;
  password_Icontains?: Maybe<Scalars['String']>;
  password_Startswith?: Maybe<Scalars['String']>;
  password_Istartswith?: Maybe<Scalars['String']>;
  password_Endswith?: Maybe<Scalars['String']>;
  password_Iendswith?: Maybe<Scalars['String']>;
  password_In?: Maybe<Scalars['String']>;
  lastLogin_Isnull?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastLogin_Gt?: Maybe<Scalars['DateTime']>;
  lastLogin_Gte?: Maybe<Scalars['DateTime']>;
  lastLogin_Lt?: Maybe<Scalars['DateTime']>;
  lastLogin_Lte?: Maybe<Scalars['DateTime']>;
  lastLogin_In?: Maybe<Scalars['DateTime']>;
  isSuperuser_Isnull?: Maybe<Scalars['Boolean']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  username_Isnull?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  username_Iexact?: Maybe<Scalars['String']>;
  username_Contains?: Maybe<Scalars['String']>;
  username_Icontains?: Maybe<Scalars['String']>;
  username_Startswith?: Maybe<Scalars['String']>;
  username_Istartswith?: Maybe<Scalars['String']>;
  username_Endswith?: Maybe<Scalars['String']>;
  username_Iendswith?: Maybe<Scalars['String']>;
  username_In?: Maybe<Scalars['String']>;
  firstName_Isnull?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_Iexact?: Maybe<Scalars['String']>;
  firstName_Contains?: Maybe<Scalars['String']>;
  firstName_Icontains?: Maybe<Scalars['String']>;
  firstName_Startswith?: Maybe<Scalars['String']>;
  firstName_Istartswith?: Maybe<Scalars['String']>;
  firstName_Endswith?: Maybe<Scalars['String']>;
  firstName_Iendswith?: Maybe<Scalars['String']>;
  firstName_In?: Maybe<Scalars['String']>;
  lastName_Isnull?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_Iexact?: Maybe<Scalars['String']>;
  lastName_Contains?: Maybe<Scalars['String']>;
  lastName_Icontains?: Maybe<Scalars['String']>;
  lastName_Startswith?: Maybe<Scalars['String']>;
  lastName_Istartswith?: Maybe<Scalars['String']>;
  lastName_Endswith?: Maybe<Scalars['String']>;
  lastName_Iendswith?: Maybe<Scalars['String']>;
  lastName_In?: Maybe<Scalars['String']>;
  email_Isnull?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_Iexact?: Maybe<Scalars['String']>;
  email_Contains?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
  email_Startswith?: Maybe<Scalars['String']>;
  email_Istartswith?: Maybe<Scalars['String']>;
  email_Endswith?: Maybe<Scalars['String']>;
  email_Iendswith?: Maybe<Scalars['String']>;
  email_In?: Maybe<Scalars['String']>;
  isStaff_Isnull?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  dateJoined_Isnull?: Maybe<Scalars['Boolean']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  dateJoined_Gt?: Maybe<Scalars['DateTime']>;
  dateJoined_Gte?: Maybe<Scalars['DateTime']>;
  dateJoined_Lt?: Maybe<Scalars['DateTime']>;
  dateJoined_Lte?: Maybe<Scalars['DateTime']>;
  dateJoined_In?: Maybe<Scalars['DateTime']>;
  groups_Isnull?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<GroupInputFilterObject>;
  userPermissions_Isnull?: Maybe<Scalars['Boolean']>;
  userPermissions?: Maybe<PermissionInputFilterObject>;
  isMe?: Maybe<Scalars['Boolean']>;
};

/** Checks whether the client is actually insured with us. */
export type HasInsuranceRuleType = PaymentPlatformRuleType & {
  __typename?: 'HasInsuranceRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Boolean']>;
  actual?: Maybe<Scalars['Boolean']>;
};

export type IcdCodeInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** code */
  code?: Maybe<Scalars['String']>;
  /** code */
  code_Icontains?: Maybe<Scalars['String']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  description_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent?: Maybe<IcdCodeInputFilterObject>;
  codeSet?: Maybe<IcdCodeSetInputFilterObject>;
  claim?: Maybe<ClaimInputFilterObject>;
  claimicdcode?: Maybe<ClaimIcdCodeInputFilterObject>;
  receiptItems?: Maybe<ReceiptItemInputFilterObject>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  exclusion?: Maybe<CodesExclusionInputFilterObject>;
  icdcode?: Maybe<IcdCodeInputFilterObject>;
};

export type IcdCodeListType = {
  __typename?: 'IcdCodeListType';
  /** IcdCode list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<IcdCodeType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type IcdCodeListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type IcdCodeSetInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** code */
  code?: Maybe<Scalars['String']>;
  /** code */
  code_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  icdcode?: Maybe<IcdCodeInputFilterObject>;
};

export type IcdCodeSetListType = {
  __typename?: 'IcdCodeSetListType';
  /** IcdCodeSet list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<IcdCodeSetType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type IcdCodeSetListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type IcdCodeSetType = {
  __typename?: 'IcdCodeSetType';
  /** code */
  code?: Maybe<Scalars['String']>;
  /** IcdCode list */
  icdcode?: Maybe<Array<Maybe<IcdCodeType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** name */
  name?: Maybe<Scalars['String']>;
};

export type IcdCodeSetTypeIcdcodeArgs = {
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimicdcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  receiptItems_Isnull?: Maybe<Scalars['Boolean']>;
  receiptItems?: Maybe<ReceiptItemInputFilterObject>;
  exclusion_Isnull?: Maybe<Scalars['Boolean']>;
  exclusion?: Maybe<CodesExclusionInputFilterObject>;
  icdcode_Isnull?: Maybe<Scalars['Boolean']>;
  icdcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<IcdCodeInputFilterObject>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Scalars['String']>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  codeSet_Isnull?: Maybe<Scalars['Boolean']>;
  codeSet?: Maybe<IcdCodeSetInputFilterObject>;
  code_Startswith_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameOrCode_Icontains?: Maybe<Scalars['String']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
};

/**
 * Represents ICD code entity belonging to a particular ICD code set. Please note that the `id` field
 *    contains a value without any business-related meaning. The actual code is stored within `code` field.
 *    Due to the fact there can be multiple code sets, values in the `code` field are not unique.
 */
export type IcdCodeType = {
  __typename?: 'IcdCodeType';
  /** code */
  code?: Maybe<Scalars['String']>;
  /** code set */
  codeSet?: Maybe<IcdCodeSetType>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** CodesExclusion list */
  exclusion?: Maybe<Array<Maybe<CodesExclusionType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** name */
  name?: Maybe<Scalars['String']>;
  /** parent */
  parent?: Maybe<IcdCodeType>;
  /** ReceiptItem list */
  receiptItems?: Maybe<Array<Maybe<ReceiptItemType>>>;
};

/**
 * Represents ICD code entity belonging to a particular ICD code set. Please note that the `id` field
 *    contains a value without any business-related meaning. The actual code is stored within `code` field.
 *    Due to the fact there can be multiple code sets, values in the `code` field are not unique.
 */
export type IcdCodeTypeExclusionArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['Int']>;
  entityId_Gt?: Maybe<Scalars['Int']>;
  entityId_Gte?: Maybe<Scalars['Int']>;
  entityId_Lt?: Maybe<Scalars['Int']>;
  entityId_Lte?: Maybe<Scalars['Int']>;
  entityId_In?: Maybe<Scalars['Int']>;
  icdCodes_Isnull?: Maybe<Scalars['Boolean']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes_Isnull?: Maybe<Scalars['Boolean']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
};

/**
 * Represents ICD code entity belonging to a particular ICD code set. Please note that the `id` field
 *    contains a value without any business-related meaning. The actual code is stored within `code` field.
 *    Due to the fact there can be multiple code sets, values in the `code` field are not unique.
 */
export type IcdCodeTypeReceiptItemsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  receipt_Isnull?: Maybe<Scalars['Boolean']>;
  receipt?: Maybe<ReceiptInputFilterObject>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Scalars['String']>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  isAccepted_Isnull?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  icd_Isnull?: Maybe<Scalars['Boolean']>;
  icd?: Maybe<IcdCodeInputFilterObject>;
};

export type InsufficientFundsRuleType = PaymentPlatformRuleType & {
  __typename?: 'InsufficientFundsRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Float']>;
};

export type InsurerEmployeeInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  insurer?: Maybe<InsurerInputFilterObject>;
  user?: Maybe<UserInputFilterObject>;
};

export type InsurerEmployeeListType = {
  __typename?: 'InsurerEmployeeListType';
  /** InsurerEmployee list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<InsurerEmployeeType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type InsurerEmployeeListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type InsurerEmployeeType = {
  __typename?: 'InsurerEmployeeType';
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** insurer */
  insurer?: Maybe<InsurerType>;
  /** user */
  user?: Maybe<UserType>;
};

export type InsurerFundsAmount = {
  __typename?: 'InsurerFundsAmount';
  insurer?: Maybe<InsurerType>;
  currencyCode?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type InsurerInputFilterObject = {
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** slug */
  slug?: Maybe<Scalars['String']>;
  /** slug */
  slug_Icontains?: Maybe<Scalars['String']>;
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** phone number */
  phoneNumber_Icontains?: Maybe<Scalars['String']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  mobileAppPasswordValidity?: Maybe<Scalars['Int']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  mobileAppPasswordValidity_Gt?: Maybe<Scalars['Int']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  mobileAppPasswordValidity_Lt?: Maybe<Scalars['Int']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  mobileAppPasswordValidity_Gte?: Maybe<Scalars['Int']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  mobileAppPasswordValidity_Lte?: Maybe<Scalars['Int']>;
  /** mobile app password regexp */
  mobileAppPasswordRegexp?: Maybe<Scalars['String']>;
  /** mobile app password regexp */
  mobileAppPasswordRegexp_Icontains?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  mobileAppPasswordValidity_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  mobileAppPasswordRegexp_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<CountryInputFilterObject>;
  employees?: Maybe<InsurerEmployeeInputFilterObject>;
  workDays?: Maybe<WorkDayInputFilterObject>;
  fundingAccounts?: Maybe<FundingAccountInputFilterObject>;
  products?: Maybe<ProductInputFilterObject>;
  benefitCategories?: Maybe<BenefitCategoryInputFilterObject>;
  notification?: Maybe<NotificationInputFilterObject>;
};

export type InsurerListType = {
  __typename?: 'InsurerListType';
  /** Insurer list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<InsurerType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type InsurerListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type InsurerPaymentInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** state */
  state?: Maybe<Scalars['String']>;
  /** state */
  state_Icontains?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  claim?: Maybe<ClaimInputFilterObject>;
  paymentPtr?: Maybe<PaymentInputFilterObject>;
  card?: Maybe<CardInputFilterObject>;
  authorization?: Maybe<PaymentAuthorizationInputFilterObject>;
  settlement?: Maybe<PaymentSettlementInputFilterObject>;
};

export type InsurerPaymentListType = PaymentInterface & {
  __typename?: 'InsurerPaymentListType';
  /** InsurerPayment list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<InsurerPaymentType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  /** claim */
  claim?: Maybe<ClaimType>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** state */
  state?: Maybe<PaymentStateEnum>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
};

export type InsurerPaymentListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type InsurerPaymentType = PaymentInterface & {
  __typename?: 'InsurerPaymentType';
  amount?: Maybe<Scalars['Float']>;
  /** authorization */
  authorization?: Maybe<PaymentAuthorizationType>;
  /** card */
  card?: Maybe<CardType>;
  /** claim */
  claim?: Maybe<ClaimType>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** settlement */
  settlement?: Maybe<PaymentSettlementType>;
  /** state */
  state?: Maybe<PaymentStateEnum>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
};

export type InsurerType = {
  __typename?: 'InsurerType';
  /** BenefitCategory list */
  benefitCategories?: Maybe<Array<Maybe<BenefitCategoryType>>>;
  /** country */
  country?: Maybe<CountryType>;
  /** InsurerEmployee list */
  employees?: Maybe<Array<Maybe<InsurerEmployeeType>>>;
  /** FundingAccount list */
  fundingAccounts?: Maybe<Array<Maybe<FundingAccountType>>>;
  /** mobile app password regexp */
  mobileAppPasswordRegexp?: Maybe<Scalars['String']>;
  /** New passwords expire after N days. -1 to disable expiration. */
  mobileAppPasswordValidity?: Maybe<Scalars['Int']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** Notification list */
  notification?: Maybe<Array<Maybe<NotificationType>>>;
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Product list */
  products?: Maybe<Array<Maybe<ProductType>>>;
  /** slug */
  slug?: Maybe<Scalars['String']>;
};

export type InsurerTypeBenefitCategoriesArgs = {
  benefits_Isnull?: Maybe<Scalars['Boolean']>;
  benefits?: Maybe<BenefitInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  icon_Isnull?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  icon_Iexact?: Maybe<Scalars['String']>;
  icon_Contains?: Maybe<Scalars['String']>;
  icon_Icontains?: Maybe<Scalars['String']>;
  icon_Startswith?: Maybe<Scalars['String']>;
  icon_Istartswith?: Maybe<Scalars['String']>;
  icon_Endswith?: Maybe<Scalars['String']>;
  icon_Iendswith?: Maybe<Scalars['String']>;
  icon_In?: Maybe<Scalars['String']>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  merchantCategories_Isnull?: Maybe<Scalars['Boolean']>;
  merchantCategories?: Maybe<MerchantCategoryInputFilterObject>;
};

export type InsurerTypeEmployeesArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
};

export type InsurerTypeFundingAccountsArgs = {
  physicalCardMeta_Isnull?: Maybe<Scalars['Boolean']>;
  physicalCardMeta?: Maybe<PhysicalCardAccountMetaInputFilterObject>;
  virtualCardMeta_Isnull?: Maybe<Scalars['Boolean']>;
  virtualCardMeta?: Maybe<VirtualCardAccountMetaInputFilterObject>;
  blockedFunds_Isnull?: Maybe<Scalars['Boolean']>;
  blockedFunds?: Maybe<BlockedFundsInputFilterObject>;
  settlementFunds_Isnull?: Maybe<Scalars['Boolean']>;
  settlementFunds?: Maybe<SettlementFundsInputFilterObject>;
  topUps_Isnull?: Maybe<Scalars['Boolean']>;
  topUps?: Maybe<TopUpTransactionInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  issuer_Isnull?: Maybe<Scalars['Boolean']>;
  issuer?: Maybe<IssuerInputFilterObject>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Scalars['String']>;
  topUpsLastProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsLastProcessedAt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_In?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsStartProcessingFrom?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Gt?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Gte?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Lt?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Lte?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_In?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsFailedAt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_In?: Maybe<Scalars['DateTime']>;
  topUpsKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsKeepProcessing?: Maybe<Scalars['Boolean']>;
  topUpsFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsFailedCount?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Gt?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Gte?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Lt?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Lte?: Maybe<Scalars['Int']>;
  topUpsFailedCount_In?: Maybe<Scalars['Int']>;
  fundingOffsetAmount_Isnull?: Maybe<Scalars['Boolean']>;
  fundingOffsetAmount?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Gt?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Gte?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Lt?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Lte?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_In?: Maybe<Scalars['Float']>;
  iban_Isnull?: Maybe<Scalars['Boolean']>;
  iban?: Maybe<Scalars['String']>;
  iban_Iexact?: Maybe<Scalars['String']>;
  iban_Contains?: Maybe<Scalars['String']>;
  iban_Icontains?: Maybe<Scalars['String']>;
  iban_Startswith?: Maybe<Scalars['String']>;
  iban_Istartswith?: Maybe<Scalars['String']>;
  iban_Endswith?: Maybe<Scalars['String']>;
  iban_Iendswith?: Maybe<Scalars['String']>;
  iban_In?: Maybe<Scalars['String']>;
  bic_Isnull?: Maybe<Scalars['Boolean']>;
  bic?: Maybe<Scalars['String']>;
  bic_Iexact?: Maybe<Scalars['String']>;
  bic_Contains?: Maybe<Scalars['String']>;
  bic_Icontains?: Maybe<Scalars['String']>;
  bic_Startswith?: Maybe<Scalars['String']>;
  bic_Istartswith?: Maybe<Scalars['String']>;
  bic_Endswith?: Maybe<Scalars['String']>;
  bic_Iendswith?: Maybe<Scalars['String']>;
  bic_In?: Maybe<Scalars['String']>;
};

export type InsurerTypeNotificationArgs = {
  mobileNotifications_Isnull?: Maybe<Scalars['Boolean']>;
  mobileNotifications?: Maybe<MobileNotificationInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Scalars['DateTime']>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Scalars['DateTime']>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Scalars['Int']>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  action_Isnull?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_Iexact?: Maybe<Scalars['String']>;
  action_Contains?: Maybe<Scalars['String']>;
  action_Icontains?: Maybe<Scalars['String']>;
  action_Startswith?: Maybe<Scalars['String']>;
  action_Istartswith?: Maybe<Scalars['String']>;
  action_Endswith?: Maybe<Scalars['String']>;
  action_Iendswith?: Maybe<Scalars['String']>;
  action_In?: Maybe<Scalars['String']>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Scalars['DateTime']>;
  read_Isnull?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['String']>;
  entityId_Iexact?: Maybe<Scalars['String']>;
  entityId_Contains?: Maybe<Scalars['String']>;
  entityId_Icontains?: Maybe<Scalars['String']>;
  entityId_Startswith?: Maybe<Scalars['String']>;
  entityId_Istartswith?: Maybe<Scalars['String']>;
  entityId_Endswith?: Maybe<Scalars['String']>;
  entityId_Iendswith?: Maybe<Scalars['String']>;
  entityId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  source?: Maybe<Scalars['String']>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claimsAuthority?: Maybe<Scalars['Float']>;
  search?: Maybe<NotificationSearchInputType>;
  claimId?: Maybe<Scalars['String']>;
};

export type InsurerTypeProductsArgs = {
  versions_Isnull?: Maybe<Scalars['Boolean']>;
  versions?: Maybe<ProductVersionInputFilterObject>;
  chains_Isnull?: Maybe<Scalars['Boolean']>;
  chains?: Maybe<PolicyChainInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  tag_Isnull?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['String']>;
  tag_Iexact?: Maybe<Scalars['String']>;
  tag_Contains?: Maybe<Scalars['String']>;
  tag_Icontains?: Maybe<Scalars['String']>;
  tag_Startswith?: Maybe<Scalars['String']>;
  tag_Istartswith?: Maybe<Scalars['String']>;
  tag_Endswith?: Maybe<Scalars['String']>;
  tag_Iendswith?: Maybe<Scalars['String']>;
  tag_In?: Maybe<Scalars['String']>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  tag_Exclude_Icontains?: Maybe<Scalars['String']>;
  tag_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum IssuerIdentifierEnum {
  /** Railsbank */
  Railsbank = 'RAILSBANK'
}

export type IssuerInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** identifier */
  identifier?: Maybe<Scalars['String']>;
  /** identifier */
  identifier_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  identifier_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fundingAccounts?: Maybe<FundingAccountInputFilterObject>;
};

export type IssuerType = {
  __typename?: 'IssuerType';
  /** FundingAccount list */
  fundingAccounts?: Maybe<Array<Maybe<FundingAccountType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** identifier */
  identifier?: Maybe<IssuerIdentifierEnum>;
};

export type IssuerTypeFundingAccountsArgs = {
  physicalCardMeta_Isnull?: Maybe<Scalars['Boolean']>;
  physicalCardMeta?: Maybe<PhysicalCardAccountMetaInputFilterObject>;
  virtualCardMeta_Isnull?: Maybe<Scalars['Boolean']>;
  virtualCardMeta?: Maybe<VirtualCardAccountMetaInputFilterObject>;
  blockedFunds_Isnull?: Maybe<Scalars['Boolean']>;
  blockedFunds?: Maybe<BlockedFundsInputFilterObject>;
  settlementFunds_Isnull?: Maybe<Scalars['Boolean']>;
  settlementFunds?: Maybe<SettlementFundsInputFilterObject>;
  topUps_Isnull?: Maybe<Scalars['Boolean']>;
  topUps?: Maybe<TopUpTransactionInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  issuer_Isnull?: Maybe<Scalars['Boolean']>;
  issuer?: Maybe<IssuerInputFilterObject>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Scalars['String']>;
  topUpsLastProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsLastProcessedAt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_In?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsStartProcessingFrom?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Gt?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Gte?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Lt?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Lte?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_In?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsFailedAt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_In?: Maybe<Scalars['DateTime']>;
  topUpsKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsKeepProcessing?: Maybe<Scalars['Boolean']>;
  topUpsFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsFailedCount?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Gt?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Gte?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Lt?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Lte?: Maybe<Scalars['Int']>;
  topUpsFailedCount_In?: Maybe<Scalars['Int']>;
  fundingOffsetAmount_Isnull?: Maybe<Scalars['Boolean']>;
  fundingOffsetAmount?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Gt?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Gte?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Lt?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Lte?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_In?: Maybe<Scalars['Float']>;
  iban_Isnull?: Maybe<Scalars['Boolean']>;
  iban?: Maybe<Scalars['String']>;
  iban_Iexact?: Maybe<Scalars['String']>;
  iban_Contains?: Maybe<Scalars['String']>;
  iban_Icontains?: Maybe<Scalars['String']>;
  iban_Startswith?: Maybe<Scalars['String']>;
  iban_Istartswith?: Maybe<Scalars['String']>;
  iban_Endswith?: Maybe<Scalars['String']>;
  iban_Iendswith?: Maybe<Scalars['String']>;
  iban_In?: Maybe<Scalars['String']>;
  bic_Isnull?: Maybe<Scalars['Boolean']>;
  bic?: Maybe<Scalars['String']>;
  bic_Iexact?: Maybe<Scalars['String']>;
  bic_Contains?: Maybe<Scalars['String']>;
  bic_Icontains?: Maybe<Scalars['String']>;
  bic_Startswith?: Maybe<Scalars['String']>;
  bic_Istartswith?: Maybe<Scalars['String']>;
  bic_Endswith?: Maybe<Scalars['String']>;
  bic_Iendswith?: Maybe<Scalars['String']>;
  bic_In?: Maybe<Scalars['String']>;
};

export type JobTaskInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** task type */
  taskType?: Maybe<Scalars['String']>;
  /** task type */
  taskType_Icontains?: Maybe<Scalars['String']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** entity id */
  entityId?: Maybe<Scalars['String']>;
  /** entity id */
  entityId_Icontains?: Maybe<Scalars['String']>;
  /** data */
  data?: Maybe<Scalars['JSONString']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  taskType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  keepProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  needManualProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  entityId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  data_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  runs?: Maybe<JobTaskRunInputFilterObject>;
};

export type JobTaskRunInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** started at */
  startedAt?: Maybe<Scalars['CustomDateTime']>;
  /** started at */
  startedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** started at */
  startedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** started at */
  startedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** started at */
  startedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** finished at */
  finishedAt?: Maybe<Scalars['CustomDateTime']>;
  /** finished at */
  finishedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** finished at */
  finishedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** finished at */
  finishedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** finished at */
  finishedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failure data */
  failureData?: Maybe<Scalars['JSONString']>;
  /** success data */
  successData?: Maybe<Scalars['JSONString']>;
  failed?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  startedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  finishedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failureData_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  successData_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  failed_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  task?: Maybe<JobTaskInputFilterObject>;
};

export type JournalEntryInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  updatedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  currency?: Maybe<CurrencyInputFilterObject>;
  transaction?: Maybe<TransactionInputFilterObject>;
  postingentry?: Maybe<PostingEntryInputFilterObject>;
};

export type LocationRuleType = PaymentPlatformRuleType & {
  __typename?: 'LocationRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<LocationType>;
  actual?: Maybe<LocationType>;
};

export type LocationType = {
  __typename?: 'LocationType';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type LogEntryInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** action time */
  actionTime?: Maybe<Scalars['CustomDateTime']>;
  /** action time */
  actionTime_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** action time */
  actionTime_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** action time */
  actionTime_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** action time */
  actionTime_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** object id */
  objectId?: Maybe<Scalars['String']>;
  /** object id */
  objectId_Icontains?: Maybe<Scalars['String']>;
  /** object repr */
  objectRepr?: Maybe<Scalars['String']>;
  /** object repr */
  objectRepr_Icontains?: Maybe<Scalars['String']>;
  /** action flag */
  actionFlag?: Maybe<Scalars['Int']>;
  /** action flag */
  actionFlag_Gt?: Maybe<Scalars['Int']>;
  /** action flag */
  actionFlag_Lt?: Maybe<Scalars['Int']>;
  /** action flag */
  actionFlag_Gte?: Maybe<Scalars['Int']>;
  /** action flag */
  actionFlag_Lte?: Maybe<Scalars['Int']>;
  /** change message */
  changeMessage?: Maybe<Scalars['String']>;
  /** change message */
  changeMessage_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  actionTime_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  objectId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  objectRepr_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  actionFlag_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  changeMessage_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<UserInputFilterObject>;
  contentType?: Maybe<ContentTypeInputFilterObject>;
};

/** Checks whether all mandatory fields are present in the payload. */
export type MandatoryNotNullFieldsRuleType = PaymentPlatformRuleType & {
  __typename?: 'MandatoryNotNullFieldsRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MarketFxRateObject = {
  __typename?: 'MarketFxRateObject';
  pair?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['Float']>;
};

/**
 * Gets market FX rate values.
 *
 * Possible filters are:
 *
 * * `code_from` - code of the source currency. USD by default.
 * * `code_to` - code of the destination currency. If left blank, all supported pairs are shown.
 * * `date` - YYYY-MM-DD, if not present, current date is used.
 */
export type MarketFxRateType = {
  __typename?: 'MarketFxRateType';
  results?: Maybe<Array<Maybe<MarketFxRateObject>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Match = {
  entityType?: Maybe<Scalars['String']>;
  lastId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['ID']>;
  action?: Maybe<Scalars['String']>;
  data_Action?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  read?: Maybe<Scalars['Boolean']>;
};

export type MerchantCategoryInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** code */
  code?: Maybe<Scalars['String']>;
  /** code */
  code_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantCategories?: Maybe<MerchantCategoryInputFilterObject>;
  benefitCategories?: Maybe<BenefitCategoryInputFilterObject>;
  categories?: Maybe<MerchantCategoryInputFilterObject>;
  merchant?: Maybe<MerchantInputFilterObject>;
};

export type MerchantCategoryListType = {
  __typename?: 'MerchantCategoryListType';
  /** MerchantCategory list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<MerchantCategoryType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type MerchantCategoryListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/**
 * Merchant categories provided by Card companies
 *
 * Filters
 * ------
 * - q: autocomplete for name and code fields
 */
export type MerchantCategoryType = {
  __typename?: 'MerchantCategoryType';
  /** BenefitCategory list */
  benefitCategories?: Maybe<Array<Maybe<BenefitCategoryType>>>;
  /** code */
  code?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** Merchant list */
  merchant?: Maybe<Array<Maybe<MerchantType>>>;
  /** name */
  name?: Maybe<Scalars['String']>;
};

/**
 * Merchant categories provided by Card companies
 *
 * Filters
 * ------
 * - q: autocomplete for name and code fields
 */
export type MerchantCategoryTypeBenefitCategoriesArgs = {
  benefits_Isnull?: Maybe<Scalars['Boolean']>;
  benefits?: Maybe<BenefitInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  icon_Isnull?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  icon_Iexact?: Maybe<Scalars['String']>;
  icon_Contains?: Maybe<Scalars['String']>;
  icon_Icontains?: Maybe<Scalars['String']>;
  icon_Startswith?: Maybe<Scalars['String']>;
  icon_Istartswith?: Maybe<Scalars['String']>;
  icon_Endswith?: Maybe<Scalars['String']>;
  icon_Iendswith?: Maybe<Scalars['String']>;
  icon_In?: Maybe<Scalars['String']>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  merchantCategories_Isnull?: Maybe<Scalars['Boolean']>;
  merchantCategories?: Maybe<MerchantCategoryInputFilterObject>;
};

/**
 * Merchant categories provided by Card companies
 *
 * Filters
 * ------
 * - q: autocomplete for name and code fields
 */
export type MerchantCategoryTypeMerchantArgs = {
  claims_Isnull?: Maybe<Scalars['Boolean']>;
  claims?: Maybe<ClaimInputFilterObject>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryInputFilterObject>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Scalars['String']>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  address_Isnull?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  gpsLatitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLatitude?: Maybe<Scalars['Float']>;
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  gpsLatitude_In?: Maybe<Scalars['Float']>;
  gpsLongitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLongitude?: Maybe<Scalars['Float']>;
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  gpsLongitude_In?: Maybe<Scalars['Float']>;
  detailedInfo_Isnull?: Maybe<Scalars['Boolean']>;
  detailedInfo?: Maybe<Scalars['String']>;
  isVerifiedByAdmin_Isnull?: Maybe<Scalars['Boolean']>;
  isVerifiedByAdmin?: Maybe<Scalars['Boolean']>;
  categories_Isnull?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<MerchantCategoryInputFilterObject>;
  geo?: Maybe<Scalars['String']>;
};

export type MerchantCodeRuleType = PaymentPlatformRuleType & {
  __typename?: 'MerchantCodeRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['String']>;
  actual?: Maybe<Scalars['String']>;
};

export type MerchantIdRuleType = PaymentPlatformRuleType & {
  __typename?: 'MerchantIdRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['String']>;
  actual?: Maybe<Scalars['String']>;
};

export type MerchantInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** external id */
  externalId_Icontains?: Maybe<Scalars['String']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** address */
  address?: Maybe<Scalars['String']>;
  /** address */
  address_Icontains?: Maybe<Scalars['String']>;
  /** gps latitude */
  gpsLatitude?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  /** detailed info */
  detailedInfo?: Maybe<Scalars['String']>;
  /** detailed info */
  detailedInfo_Icontains?: Maybe<Scalars['String']>;
  isVerifiedByAdmin?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  externalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  gpsLatitude_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  gpsLongitude_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  detailedInfo_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVerifiedByAdmin_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  country?: Maybe<CountryInputFilterObject>;
  categories?: Maybe<MerchantCategoryInputFilterObject>;
  claims?: Maybe<ClaimInputFilterObject>;
  provider?: Maybe<ProviderInputFilterObject>;
};

export type MerchantListType = {
  __typename?: 'MerchantListType';
  /** Merchant list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<MerchantType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type MerchantListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type MerchantMutation = {
  __typename?: 'MerchantMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  merchant?: Maybe<MerchantType>;
};

export type MerchantNameRuleType = PaymentPlatformRuleType & {
  __typename?: 'MerchantNameRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['String']>;
  actual?: Maybe<Scalars['String']>;
};

/**
 * Merchants both from mobile apps (not verified) as well as from Insurer Dashboard.
 *
 * Extra filters:
 *
 * * `geo` - bound rectangular given by two GPS poins in format formatted as `{north-east corner}:{south-west corner}`
 *   where corner is defined by `{latitude},{longitude}` in North-East direction
 */
export type MerchantType = {
  __typename?: 'MerchantType';
  /** address */
  address?: Maybe<Scalars['String']>;
  /** MerchantCategory list */
  categories?: Maybe<Array<Maybe<MerchantCategoryType>>>;
  /** Claim list */
  claims?: Maybe<Array<Maybe<ClaimType>>>;
  /** country */
  country?: Maybe<CountryType>;
  /** detailed info */
  detailedInfo?: Maybe<Scalars['String']>;
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** gps latitude */
  gpsLatitude?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  isVerifiedByAdmin?: Maybe<Scalars['Boolean']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<ProviderType>;
};

/**
 * Merchants both from mobile apps (not verified) as well as from Insurer Dashboard.
 *
 * Extra filters:
 *
 * * `geo` - bound rectangular given by two GPS poins in format formatted as `{north-east corner}:{south-west corner}`
 *   where corner is defined by `{latitude},{longitude}` in North-East direction
 */
export type MerchantTypeCategoriesArgs = {
  benefitCategories_Isnull?: Maybe<Scalars['Boolean']>;
  benefitCategories?: Maybe<BenefitCategoryInputFilterObject>;
  merchant_Isnull?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Scalars['String']>;
  q?: Maybe<Scalars['String']>;
  merchantCategories?: Maybe<MerchantCategoryInputFilterObject>;
  categories?: Maybe<MerchantCategoryInputFilterObject>;
};

/**
 * Merchants both from mobile apps (not verified) as well as from Insurer Dashboard.
 *
 * Extra filters:
 *
 * * `geo` - bound rectangular given by two GPS poins in format formatted as `{north-east corner}:{south-west corner}`
 *   where corner is defined by `{latitude},{longitude}` in North-East direction
 */
export type MerchantTypeClaimsArgs = {
  claimicdcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<ClaimIcdCodeInputFilterObject>;
  claimcptcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimcptcode?: Maybe<ClaimCptCodeInputFilterObject>;
  breakdown_Isnull?: Maybe<Scalars['Boolean']>;
  breakdown?: Maybe<ClaimBreakdownInputFilterObject>;
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  receipts_Isnull?: Maybe<Scalars['Boolean']>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  defaultForCards_Isnull?: Maybe<Scalars['Boolean']>;
  defaultForCards?: Maybe<CardInputFilterObject>;
  aggregatedPreauths_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedPreauths?: Maybe<AggregatedPreauthInputFilterObject>;
  payments_Isnull?: Maybe<Scalars['Boolean']>;
  payments?: Maybe<PaymentInputFilterObject>;
  paymentAuthorizations_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizations?: Maybe<PaymentAuthorizationInputFilterObject>;
  account_Isnull?: Maybe<Scalars['Boolean']>;
  account?: Maybe<AccountInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchant_Isnull?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantInputFilterObject>;
  beneficiaryBenefitUsage_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryBenefitUsage?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  retroactive_Isnull?: Maybe<Scalars['Boolean']>;
  retroactive?: Maybe<Scalars['Boolean']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  maxAmount_Isnull?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Float']>;
  maxAmount_Gt?: Maybe<Scalars['Float']>;
  maxAmount_Gte?: Maybe<Scalars['Float']>;
  maxAmount_Lt?: Maybe<Scalars['Float']>;
  maxAmount_Lte?: Maybe<Scalars['Float']>;
  maxAmount_In?: Maybe<Scalars['Float']>;
  eligibleAmount_Isnull?: Maybe<Scalars['Boolean']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  eligibleAmount_Gt?: Maybe<Scalars['Float']>;
  eligibleAmount_Gte?: Maybe<Scalars['Float']>;
  eligibleAmount_Lt?: Maybe<Scalars['Float']>;
  eligibleAmount_Lte?: Maybe<Scalars['Float']>;
  eligibleAmount_In?: Maybe<Scalars['Float']>;
  transactionAmount_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAmount?: Maybe<Scalars['Float']>;
  transactionAmount_Gt?: Maybe<Scalars['Float']>;
  transactionAmount_Gte?: Maybe<Scalars['Float']>;
  transactionAmount_Lt?: Maybe<Scalars['Float']>;
  transactionAmount_Lte?: Maybe<Scalars['Float']>;
  transactionAmount_In?: Maybe<Scalars['Float']>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  isDummy_Isnull?: Maybe<Scalars['Boolean']>;
  isDummy?: Maybe<Scalars['Boolean']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  allowTransferringToFuture_Isnull?: Maybe<Scalars['Boolean']>;
  allowTransferringToFuture?: Maybe<Scalars['Boolean']>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  created_Isnull?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  created_Gt?: Maybe<Scalars['DateTime']>;
  created_Gte?: Maybe<Scalars['DateTime']>;
  created_Lt?: Maybe<Scalars['DateTime']>;
  created_Lte?: Maybe<Scalars['DateTime']>;
  created_In?: Maybe<Scalars['DateTime']>;
  updated_Isnull?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['DateTime']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  updated_In?: Maybe<Scalars['DateTime']>;
  referenceId_Isnull?: Maybe<Scalars['Boolean']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_Iexact?: Maybe<Scalars['String']>;
  referenceId_Contains?: Maybe<Scalars['String']>;
  referenceId_Icontains?: Maybe<Scalars['String']>;
  referenceId_Startswith?: Maybe<Scalars['String']>;
  referenceId_Istartswith?: Maybe<Scalars['String']>;
  referenceId_Endswith?: Maybe<Scalars['String']>;
  referenceId_Iendswith?: Maybe<Scalars['String']>;
  referenceId_In?: Maybe<Scalars['String']>;
  icdCodes_Isnull?: Maybe<Scalars['Boolean']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes_Isnull?: Maybe<Scalars['Boolean']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
  isStagnant?: Maybe<Scalars['Boolean']>;
  isInstant?: Maybe<Scalars['Boolean']>;
  benefitCategory?: Maybe<BenefitCategoryInputFilterObject>;
  benefit?: Maybe<BenefitInputFilterObject>;
  policy?: Maybe<PolicyInputFilterObject>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  beneficiaryIdWithIncludedDependants?: Maybe<Scalars['String']>;
  beneficiaryIdInWithIncludedDependants?: Maybe<Scalars['String']>;
  source_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardState?: Maybe<Scalars['String']>;
  codesExclusion?: Maybe<CodesExclusionInputFilterObject>;
};

export type MetaEntry = {
  __typename?: 'MetaEntry';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MetaEntryInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MobileAppSessionInputFilterObject = {
  /** token */
  token?: Maybe<Scalars['String']>;
  /** token */
  token_Icontains?: Maybe<Scalars['String']>;
  /** data */
  data?: Maybe<Scalars['JSONString']>;
  /** last activity */
  lastActivity?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Lte?: Maybe<Scalars['CustomDateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  token_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  data_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  lastActivity_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  isActive_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  user?: Maybe<MobileAppUserInputFilterObject>;
  policy?: Maybe<PolicyInputFilterObject>;
};

export type MobileAppSessionListType = {
  __typename?: 'MobileAppSessionListType';
  /** MobileAppSession list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<MobileAppSessionType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type MobileAppSessionListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type MobileAppSessionType = {
  __typename?: 'MobileAppSessionType';
  /** data */
  data?: Maybe<Scalars['JSONString']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** last activity */
  lastActivity?: Maybe<Scalars['CustomDateTime']>;
  /** policy */
  policy?: Maybe<PolicyType>;
  /** token */
  token?: Maybe<Scalars['String']>;
  /** user */
  user?: Maybe<MobileAppUserType>;
};

export type MobileAppUserInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** password */
  password?: Maybe<Scalars['String']>;
  /** password */
  password_Icontains?: Maybe<Scalars['String']>;
  /** last login */
  lastLogin?: Maybe<Scalars['CustomDateTime']>;
  /** last login */
  lastLogin_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** last login */
  lastLogin_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** last login */
  lastLogin_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** last login */
  lastLogin_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** username */
  username?: Maybe<Scalars['String']>;
  /** username */
  username_Icontains?: Maybe<Scalars['String']>;
  /** password expiration */
  passwordExpiration?: Maybe<Scalars['CustomDateTime']>;
  /** password expiration */
  passwordExpiration_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** password expiration */
  passwordExpiration_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** password expiration */
  passwordExpiration_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** password expiration */
  passwordExpiration_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  password_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastLogin_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  username_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  passwordExpiration_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  mobileNotifications?: Maybe<MobileNotificationInputFilterObject>;
  sessions?: Maybe<MobileAppSessionInputFilterObject>;
  locationStamps?: Maybe<SessionLocationStampInputFilterObject>;
};

export type MobileAppUserListType = {
  __typename?: 'MobileAppUserListType';
  /** MobileAppUser list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<MobileAppUserType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type MobileAppUserListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type MobileAppUserMutation = {
  __typename?: 'MobileAppUserMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  mobileappuser?: Maybe<MobileAppUserType>;
};

export type MobileAppUserType = {
  __typename?: 'MobileAppUserType';
  /** beneficiary */
  beneficiary?: Maybe<BeneficiaryType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** last login */
  lastLogin?: Maybe<Scalars['CustomDateTime']>;
  /** SessionLocationStamp list */
  locationStamps?: Maybe<Array<Maybe<SessionLocationStampType>>>;
  /** MobileNotification list */
  mobileNotifications?: Maybe<Array<Maybe<MobileNotificationType>>>;
  /** password */
  password?: Maybe<Scalars['String']>;
  /** password expiration */
  passwordExpiration?: Maybe<Scalars['CustomDateTime']>;
  /** MobileAppSession list */
  sessions?: Maybe<Array<Maybe<MobileAppSessionType>>>;
  /** username */
  username?: Maybe<Scalars['String']>;
};

export type MobileAppUserTypeLocationStampsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<MobileAppUserInputFilterObject>;
  platform_Isnull?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Scalars['String']>;
  platform_Iexact?: Maybe<Scalars['String']>;
  platform_Contains?: Maybe<Scalars['String']>;
  platform_Icontains?: Maybe<Scalars['String']>;
  platform_Startswith?: Maybe<Scalars['String']>;
  platform_Istartswith?: Maybe<Scalars['String']>;
  platform_Endswith?: Maybe<Scalars['String']>;
  platform_Iendswith?: Maybe<Scalars['String']>;
  platform_In?: Maybe<Scalars['String']>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Scalars['DateTime']>;
  gpsLatitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLatitude?: Maybe<Scalars['Float']>;
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  gpsLatitude_In?: Maybe<Scalars['Float']>;
  gpsLongitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLongitude?: Maybe<Scalars['Float']>;
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  gpsLongitude_In?: Maybe<Scalars['Float']>;
};

export type MobileAppUserTypeMobileNotificationsArgs = {
  notification_Isnull?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<NotificationInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<MobileAppUserInputFilterObject>;
  platform_Isnull?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Scalars['String']>;
  platform_Iexact?: Maybe<Scalars['String']>;
  platform_Contains?: Maybe<Scalars['String']>;
  platform_Icontains?: Maybe<Scalars['String']>;
  platform_Startswith?: Maybe<Scalars['String']>;
  platform_Istartswith?: Maybe<Scalars['String']>;
  platform_Endswith?: Maybe<Scalars['String']>;
  platform_Iendswith?: Maybe<Scalars['String']>;
  platform_In?: Maybe<Scalars['String']>;
  deviceToken_Isnull?: Maybe<Scalars['Boolean']>;
  deviceToken?: Maybe<Scalars['String']>;
  success_Isnull?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
};

export type MobileAppUserTypeSessionsArgs = {
  token_Isnull?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  token_Iexact?: Maybe<Scalars['String']>;
  token_Contains?: Maybe<Scalars['String']>;
  token_Icontains?: Maybe<Scalars['String']>;
  token_Startswith?: Maybe<Scalars['String']>;
  token_Istartswith?: Maybe<Scalars['String']>;
  token_Endswith?: Maybe<Scalars['String']>;
  token_Iendswith?: Maybe<Scalars['String']>;
  token_In?: Maybe<Scalars['String']>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<MobileAppUserInputFilterObject>;
  policy_Isnull?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<PolicyInputFilterObject>;
  lastActivity_Isnull?: Maybe<Scalars['Boolean']>;
  lastActivity?: Maybe<Scalars['DateTime']>;
  lastActivity_Gt?: Maybe<Scalars['DateTime']>;
  lastActivity_Gte?: Maybe<Scalars['DateTime']>;
  lastActivity_Lt?: Maybe<Scalars['DateTime']>;
  lastActivity_Lte?: Maybe<Scalars['DateTime']>;
  lastActivity_In?: Maybe<Scalars['DateTime']>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type MobileNotificationInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** platform */
  platform?: Maybe<Scalars['String']>;
  /** platform */
  platform_Icontains?: Maybe<Scalars['String']>;
  /** device token */
  deviceToken?: Maybe<Scalars['String']>;
  /** device token */
  deviceToken_Icontains?: Maybe<Scalars['String']>;
  /** payload */
  payload?: Maybe<Scalars['JSONString']>;
  success?: Maybe<Scalars['Boolean']>;
  /** timestamp */
  timestamp?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** service response */
  serviceResponse?: Maybe<Scalars['JSONString']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  platform_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  deviceToken_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  payload_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  success_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  timestamp_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  serviceResponse_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  notification?: Maybe<NotificationInputFilterObject>;
  user?: Maybe<MobileAppUserInputFilterObject>;
};

export type MobileNotificationListType = {
  __typename?: 'MobileNotificationListType';
  /** MobileNotification list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<MobileNotificationType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type MobileNotificationListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum MobileNotificationPlatformEnum {
  /** iOS */
  Ios = 'IOS',
  /** Android */
  Android = 'ANDROID'
}

export type MobileNotificationType = {
  __typename?: 'MobileNotificationType';
  /** device token */
  deviceToken?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** notification */
  notification?: Maybe<NotificationType>;
  /** payload */
  payload?: Maybe<Scalars['JSONString']>;
  /** platform */
  platform?: Maybe<MobileNotificationPlatformEnum>;
  /** service response */
  serviceResponse?: Maybe<Scalars['JSONString']>;
  success?: Maybe<Scalars['Boolean']>;
  /** timestamp */
  timestamp?: Maybe<Scalars['CustomDateTime']>;
  /** user */
  user?: Maybe<MobileAppUserType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create mutation for <class 'rules.models.Rule'> */
  ruleCreate?: Maybe<RuleMutation>;
  /** Create mutation for <class 'rules.models.RulesSet'> */
  ruleSetCreate?: Maybe<RulesSetMutation>;
  /** Update mutation for <class 'rules.models.RulesSet'> */
  ruleSetUpdate?: Maybe<RulesSetMutation>;
  /** Create mutation for <class 'paymentplatform.models.PaymentRulesOverride'> */
  paymentRulesOverrideCreate?: Maybe<PaymentRulesOverrideMutation>;
  /** Delete mutation for <class 'paymentplatform.models.PaymentRulesOverride'> */
  paymentRulesOverrideDelete?: Maybe<PaymentRulesOverrideMutation>;
  /** Update mutation for <class 'paymentplatform.models.PaymentRulesOverride'> */
  paymentRulesOverrideUpdate?: Maybe<PaymentRulesOverrideMutation>;
  /** Create mutation for <class 'mobile_app.models.MobileAppUser'> */
  mobileAppUserCreate?: Maybe<MobileAppUserMutation>;
  /** Delete mutation for <class 'mobile_app.models.MobileAppUser'> */
  mobileAppUserDelete?: Maybe<MobileAppUserMutation>;
  /** Update mutation for <class 'mobile_app.models.MobileAppUser'> */
  mobileAppUserUpdate?: Maybe<MobileAppUserMutation>;
  /** Create mutation for <class 'provider.models.Provider'> */
  providerCreate?: Maybe<ProviderMutation>;
  /** Update mutation for <class 'provider.models.Provider'> */
  providerUpdate?: Maybe<ProviderMutation>;
  /** Create mutation for <class 'provider.models.ProviderEmployee'> */
  providerEmployeeCreate?: Maybe<ProviderEmployeeMutation>;
  /** Delete mutation for <class 'provider.models.ProviderEmployee'> */
  providerEmployeeDelete?: Maybe<ProviderEmployeeMutation>;
  /** Update mutation for <class 'provider.models.ProviderEmployee'> */
  providerEmployeeUpdate?: Maybe<ProviderEmployeeMutation>;
  /** Create mutation for <class 'provider.models.BeneficiaryDataUnlock'> */
  beneficiaryDataUnlockCreate?: Maybe<BeneficiaryDataUnlockMutation>;
  /** Update mutation for <class 'provider.models.BeneficiaryDataUnlock'> */
  beneficiaryDataUnlockUpdate?: Maybe<BeneficiaryDataUnlockMutation>;
  /** Create mutation for <class 'merchant.models.Merchant'> */
  merchantCreate?: Maybe<MerchantMutation>;
  /** Delete mutation for <class 'merchant.models.Merchant'> */
  merchantDelete?: Maybe<MerchantMutation>;
  /** Update mutation for <class 'merchant.models.Merchant'> */
  merchantUpdate?: Maybe<MerchantMutation>;
  /** Create mutation for <class 'django.contrib.auth.models.User'> */
  userCreate?: Maybe<UserMutation>;
  /** Delete mutation for <class 'django.contrib.auth.models.User'> */
  userDelete?: Maybe<UserMutation>;
  /** Update mutation for <class 'django.contrib.auth.models.User'> */
  userUpdate?: Maybe<UserMutation>;
  /** Update mutation for <class 'audit_trail.models.Notification'> */
  notificationUpdate?: Maybe<NotificationMutation>;
  /** Create mutation for <class 'card.models.PersonalCard'> */
  personalCardCreate?: Maybe<PersonalCardMutation>;
  /** Delete mutation for <class 'card.models.PersonalCard'> */
  personalCardDelete?: Maybe<PersonalCardMutation>;
  /** Update mutation for <class 'card.models.PersonalCard'> */
  personalCardUpdate?: Maybe<PersonalCardMutation>;
  /** Create mutation for <class 'card.models.CardAction'> */
  cardActionCreate?: Maybe<CardActionMutation>;
  /** Delete mutation for <class 'card.models.CardAction'> */
  cardActionDelete?: Maybe<CardActionMutation>;
  /** Update mutation for <class 'card.models.CardAction'> */
  cardActionUpdate?: Maybe<CardActionMutation>;
  /** Create mutation for <class 'card.models.Card'> */
  cardCreate?: Maybe<CardMutation>;
  /** Update mutation for <class 'card.models.Card'> */
  cardUpdate?: Maybe<CardMutation>;
  /** Create mutation for <class 'policy.models.Policy'> */
  policyCreate?: Maybe<PolicyMutation>;
  /** Delete mutation for <class 'policy.models.Policy'> */
  policyDelete?: Maybe<PolicyMutation>;
  /** Update mutation for <class 'policy.models.Policy'> */
  policyUpdate?: Maybe<PolicyMutation>;
  /** Create mutation for <class 'policy.models.Beneficiary'> */
  beneficiaryCreate?: Maybe<BeneficiaryMutation>;
  /** Update mutation for <class 'policy.models.Beneficiary'> */
  beneficiaryUpdate?: Maybe<BeneficiaryMutation>;
  /** Create mutation for <class 'customer.models.Corporation'> */
  corporationCreate?: Maybe<CorporationMutation>;
  /** Delete mutation for <class 'customer.models.Corporation'> */
  corporationDelete?: Maybe<CorporationMutation>;
  /** Update mutation for <class 'customer.models.Corporation'> */
  corporationUpdate?: Maybe<CorporationMutation>;
  /** Create mutation for <class 'policy.models.PolicyHolder'> */
  policyHolderCreate?: Maybe<PolicyHolderMutation>;
  /** Update mutation for <class 'policy.models.PolicyHolder'> */
  policyHolderUpdate?: Maybe<PolicyHolderMutation>;
  /** Create mutation for <class 'policy.models.PolicyChain'> */
  policyChainCreate?: Maybe<PolicyChainMutation>;
  /** Delete mutation for <class 'policy.models.PolicyChain'> */
  policyChainDelete?: Maybe<PolicyChainMutation>;
  /** Update mutation for <class 'policy.models.PolicyChain'> */
  policyChainUpdate?: Maybe<PolicyChainMutation>;
  /** Create mutation for <class 'policy.models.CorporatePolicyChain'> */
  corporatePolicyChainCreate?: Maybe<CorporatePolicyChainMutation>;
  /** Delete mutation for <class 'policy.models.CorporatePolicyChain'> */
  corporatePolicyChainDelete?: Maybe<CorporatePolicyChainMutation>;
  /** Update mutation for <class 'policy.models.CorporatePolicyChain'> */
  corporatePolicyChainUpdate?: Maybe<CorporatePolicyChainMutation>;
  /** Create mutation for <class 'claim.models.Claim'> */
  claimCreate?: Maybe<ClaimMutation>;
  /** Delete mutation for <class 'claim.models.Claim'> */
  claimDelete?: Maybe<ClaimMutation>;
  /** Update mutation for <class 'claim.models.Claim'> */
  claimUpdate?: Maybe<ClaimMutation>;
  /** Create mutation for <class 'claim.models.ClaimIcdCode'> */
  claimIcdCodeCreate?: Maybe<ClaimIcdCodeMutation>;
  /** Delete mutation for <class 'claim.models.ClaimIcdCode'> */
  claimIcdCodeDelete?: Maybe<ClaimIcdCodeMutation>;
  /** Update mutation for <class 'claim.models.ClaimIcdCode'> */
  claimIcdCodeUpdate?: Maybe<ClaimIcdCodeMutation>;
  /** Create mutation for <class 'claim.models.ClaimCptCode'> */
  claimCptCodeCreate?: Maybe<ClaimCptCodeMutation>;
  /** Delete mutation for <class 'claim.models.ClaimCptCode'> */
  claimCptCodeDelete?: Maybe<ClaimCptCodeMutation>;
  /** Update mutation for <class 'claim.models.ClaimCptCode'> */
  claimCptCodeUpdate?: Maybe<ClaimCptCodeMutation>;
  /** Create mutation for <class 'claim.models.Receipt'> */
  receiptCreate?: Maybe<ReceiptMutation>;
  /** Update mutation for <class 'claim.models.Receipt'> */
  receiptUpdate?: Maybe<ReceiptMutation>;
  /** Create mutation for <class 'claim.models.SupportingDocument'> */
  supportingDocumentCreate?: Maybe<SupportingDocumentMutation>;
  /** Update mutation for <class 'claim.models.SupportingDocument'> */
  supportingDocumentUpdate?: Maybe<SupportingDocumentMutation>;
  /** Create mutation for <class 'claim.models.ReceiptItem'> */
  receiptItemCreate?: Maybe<ReceiptItemMutation>;
  /** Delete mutation for <class 'claim.models.ReceiptItem'> */
  receiptItemDelete?: Maybe<ReceiptItemMutation>;
  /** Update mutation for <class 'claim.models.ReceiptItem'> */
  receiptItemUpdate?: Maybe<ReceiptItemMutation>;
  /** Create mutation for <class 'claim.models.CodesExclusion'> */
  codesExclusionCreate?: Maybe<CodesExclusionMutation>;
  /** Delete mutation for <class 'claim.models.CodesExclusion'> */
  codesExclusionDelete?: Maybe<CodesExclusionMutation>;
};

export type MutationRuleCreateArgs = {
  newPaymentFrequencyRule?: Maybe<PaymentFrequencyControlSpecificationTypeInput>;
  newPaymentVelocityRule?: Maybe<PaymentVelocityControlSpecificationTypeInput>;
};

export type MutationRuleSetCreateArgs = {
  newRulesSet: CreateRulesSetInputType;
};

export type MutationRuleSetUpdateArgs = {
  disableRuleSet?: Maybe<DisableRuleSetInputType>;
};

export type MutationPaymentRulesOverrideCreateArgs = {
  newCardOverride?: Maybe<RulesCardOverrideInputType>;
  newRulesDisable?: Maybe<RulesDisableOverrideInputType>;
  newRulesModification?: Maybe<RulesModificationOverrideInputType>;
};

export type MutationPaymentRulesOverrideDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationPaymentRulesOverrideUpdateArgs = {
  newPaymentRulesOverride: UpdatePaymentRulesOverrideInputType;
};

export type MutationMobileAppUserCreateArgs = {
  newMobileAppUser: CreateMobileAppUserInputType;
};

export type MutationMobileAppUserDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationMobileAppUserUpdateArgs = {
  newMobileAppUser: UpdateMobileAppUserInputType;
};

export type MutationProviderCreateArgs = {
  newProvider: CreateProviderInputType;
};

export type MutationProviderUpdateArgs = {
  newProvider: UpdateProviderInputType;
};

export type MutationProviderEmployeeCreateArgs = {
  newProviderEmployee: CreateProviderEmployeeInputType;
};

export type MutationProviderEmployeeDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationProviderEmployeeUpdateArgs = {
  newProviderEmployee: UpdateProviderEmployeeInputType;
};

export type MutationBeneficiaryDataUnlockCreateArgs = {
  newExpiringToken?: Maybe<ExpiringTokenInputType>;
  newBeneficiaryDataUnlock?: Maybe<CreateBeneficiaryDataUnlockInputType>;
};

export type MutationBeneficiaryDataUnlockUpdateArgs = {
  disable?: Maybe<DisableInputType>;
};

export type MutationMerchantCreateArgs = {
  newMerchant: CreateMerchantInputType;
};

export type MutationMerchantDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationMerchantUpdateArgs = {
  newMerchant: UpdateMerchantInputType;
};

export type MutationUserCreateArgs = {
  newUser: CreateUserInputType;
};

export type MutationUserDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationUserUpdateArgs = {
  newUser: UpdateUserInputType;
};

export type MutationNotificationUpdateArgs = {
  readChange?: Maybe<ReadChangeInput>;
};

export type MutationPersonalCardCreateArgs = {
  newPersonalCard: CreatePersonalCardInputType;
};

export type MutationPersonalCardDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationPersonalCardUpdateArgs = {
  newPersonalCard: UpdatePersonalCardInputType;
};

export type MutationCardActionCreateArgs = {
  newCardAction: CreateCardActionInputType;
};

export type MutationCardActionDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationCardActionUpdateArgs = {
  newCardAction: UpdateCardActionInputType;
};

export type MutationCardCreateArgs = {
  setupPaymentCard?: Maybe<SetupPaymentCardInput>;
};

export type MutationCardUpdateArgs = {
  changeCurrentBeneficiaryForPayments?: Maybe<ChangeCurrentBeneficiaryForPaymentsInput>;
  resendCard?: Maybe<ResendCardInput>;
  newCard?: Maybe<UpdateCardInputType>;
};

export type MutationPolicyCreateArgs = {
  newPolicy: CreatePolicyInputType;
};

export type MutationPolicyDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationPolicyUpdateArgs = {
  newPolicy: UpdatePolicyInputType;
};

export type MutationBeneficiaryCreateArgs = {
  newBeneficiary: CreateBeneficiaryInputType;
};

export type MutationBeneficiaryUpdateArgs = {
  disableAllUnlocks?: Maybe<BeneficiaryDisableAllUnlocksInputType>;
  newBeneficiary?: Maybe<UpdateBeneficiaryInputType>;
};

export type MutationCorporationCreateArgs = {
  newCorporation: CreateCorporationInputType;
};

export type MutationCorporationDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationCorporationUpdateArgs = {
  newCorporation: UpdateCorporationInputType;
};

export type MutationPolicyHolderCreateArgs = {
  newPolicyHolder: CreatePolicyHolderInputType;
};

export type MutationPolicyHolderUpdateArgs = {
  newPolicyHolder: UpdatePolicyHolderInputType;
};

export type MutationPolicyChainCreateArgs = {
  newPolicyChain: CreatePolicyChainInputType;
};

export type MutationPolicyChainDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationPolicyChainUpdateArgs = {
  newPolicyChain: UpdatePolicyChainInputType;
};

export type MutationCorporatePolicyChainCreateArgs = {
  newCorporatePolicyChain: CreateCorporatePolicyChainInputType;
};

export type MutationCorporatePolicyChainDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationCorporatePolicyChainUpdateArgs = {
  newCorporatePolicyChain: UpdateCorporatePolicyChainInputType;
};

export type MutationClaimCreateArgs = {
  newClaim: CreateClaimInputType;
};

export type MutationClaimDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationClaimUpdateArgs = {
  additionalInfoChange?: Maybe<NewClaimAdditionalInfoInputType>;
  benefitChange?: Maybe<NewClaimBenefitInputType>;
  merchantChange?: Maybe<NewClaimMerchantInputType>;
  newDecision?: Maybe<ClaimDecisionInputType>;
  newNote?: Maybe<NewClaimNoteInputType>;
};

export type MutationClaimIcdCodeCreateArgs = {
  newClaimIcdCode: CreateClaimIcdCodeInputType;
};

export type MutationClaimIcdCodeDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationClaimIcdCodeUpdateArgs = {
  newClaimIcdCode: UpdateClaimIcdCodeInputType;
};

export type MutationClaimCptCodeCreateArgs = {
  newClaimCptCode: CreateClaimCptCodeInputType;
};

export type MutationClaimCptCodeDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationClaimCptCodeUpdateArgs = {
  newClaimCptCode: UpdateClaimCptCodeInputType;
};

export type MutationReceiptCreateArgs = {
  newReceipt: CreateReceiptInputType;
};

export type MutationReceiptUpdateArgs = {
  newReceiptDecision?: Maybe<NewReceiptDecision>;
  newReceipt?: Maybe<UpdateReceiptInputType>;
};

export type MutationSupportingDocumentCreateArgs = {
  generateDocument?: Maybe<CreateDocumentForClaimInputType>;
  newSupportingDocument?: Maybe<CreateSupportingDocumentInputType>;
};

export type MutationSupportingDocumentUpdateArgs = {
  newSupportingDocumentDecision?: Maybe<NewReceiptDecision>;
  sendGuaranteeOfPayment?: Maybe<SendDocumentToProviderInputType>;
  newSupportingDocument?: Maybe<UpdateSupportingDocumentInputType>;
};

export type MutationReceiptItemCreateArgs = {
  newReceiptItem: CreateReceiptItemInputType;
};

export type MutationReceiptItemDeleteArgs = {
  id: Scalars['ID'];
};

export type MutationReceiptItemUpdateArgs = {
  newReceiptItem: UpdateReceiptItemInputType;
};

export type MutationCodesExclusionCreateArgs = {
  newCodesExclusion?: Maybe<CodesExclusionInputType>;
};

export type MutationCodesExclusionDeleteArgs = {
  id: Scalars['ID'];
};

export type NettingInputType = {
  internalNetting?: Maybe<Scalars['Boolean']>;
  allowTransferringToFuture?: Maybe<Scalars['Boolean']>;
  transferFromPast?: Maybe<Scalars['Boolean']>;
};

export type NettingOutputType = {
  __typename?: 'NettingOutputType';
  thisClaim?: Maybe<ClaimNettingType>;
  previousClaims?: Maybe<ClaimNettingType>;
};

export type NewClaimAdditionalInfoInputType = {
  id?: Maybe<Scalars['ID']>;
  additionalInformation?: Maybe<Scalars['String']>;
};

export type NewClaimBenefitInputType = {
  id?: Maybe<Scalars['ID']>;
  benefitId?: Maybe<Scalars['ID']>;
};

export type NewClaimMerchantInputType = {
  id?: Maybe<Scalars['ID']>;
  merchantId?: Maybe<Scalars['ID']>;
};

export type NewClaimNoteInputType = {
  id?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
};

export type NewReceiptDecision = {
  id?: Maybe<Scalars['ID']>;
  documentAction?: Maybe<Document_Action>;
  reason?: Maybe<Scalars['String']>;
};

export type NotificationInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gte?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lte?: Maybe<Scalars['Int']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** action */
  action?: Maybe<Scalars['String']>;
  /** action */
  action_Icontains?: Maybe<Scalars['String']>;
  /** data */
  data?: Maybe<Scalars['JSONString']>;
  /** timestamp */
  timestamp?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Lte?: Maybe<Scalars['CustomDateTime']>;
  read?: Maybe<Scalars['Boolean']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** entity id */
  entityId?: Maybe<Scalars['String']>;
  /** entity id */
  entityId_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  keepProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  needManualProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  action_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  data_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  timestamp_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  read_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  entityId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer?: Maybe<InsurerInputFilterObject>;
  user?: Maybe<UserInputFilterObject>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  mobileNotifications?: Maybe<MobileNotificationInputFilterObject>;
};

export type NotificationListType = {
  __typename?: 'NotificationListType';
  /** Notification list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<NotificationType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type NotificationListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type NotificationMutation = {
  __typename?: 'NotificationMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  notification?: Maybe<NotificationType>;
};

/** Input for Notification search by any and match */
export type NotificationSearchInputType = {
  any?: Maybe<Array<Maybe<NotificationSearchInputType>>>;
  match?: Maybe<Match>;
  claimsAuthority?: Maybe<Scalars['Float']>;
};

/** To use same filter functionality as for `last_id` use `idGt` */
export type NotificationType = {
  __typename?: 'NotificationType';
  /** action */
  action?: Maybe<Scalars['String']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** data */
  data?: Maybe<Scalars['JSONString']>;
  /** entity id */
  entityId?: Maybe<Scalars['String']>;
  /** entity type */
  entityType?: Maybe<ContentTypeType>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** insurer */
  insurer?: Maybe<InsurerType>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  /** MobileNotification list */
  mobileNotifications?: Maybe<Array<Maybe<MobileNotificationType>>>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  read?: Maybe<Scalars['Boolean']>;
  /** Email list */
  sentEmails?: Maybe<Array<Maybe<EmailType>>>;
  /** timestamp */
  timestamp?: Maybe<Scalars['CustomDateTime']>;
  /** user */
  user?: Maybe<UserType>;
  source?: Maybe<Scalars['String']>;
};

/** To use same filter functionality as for `last_id` use `idGt` */
export type NotificationTypeMobileNotificationsArgs = {
  notification_Isnull?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<NotificationInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<MobileAppUserInputFilterObject>;
  platform_Isnull?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Scalars['String']>;
  platform_Iexact?: Maybe<Scalars['String']>;
  platform_Contains?: Maybe<Scalars['String']>;
  platform_Icontains?: Maybe<Scalars['String']>;
  platform_Startswith?: Maybe<Scalars['String']>;
  platform_Istartswith?: Maybe<Scalars['String']>;
  platform_Endswith?: Maybe<Scalars['String']>;
  platform_Iendswith?: Maybe<Scalars['String']>;
  platform_In?: Maybe<Scalars['String']>;
  deviceToken_Isnull?: Maybe<Scalars['Boolean']>;
  deviceToken?: Maybe<Scalars['String']>;
  success_Isnull?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
};

/** To use same filter functionality as for `last_id` use `idGt` */
export type NotificationTypeSentEmailsArgs = {
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  notifications_Isnull?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<NotificationInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  sender_Isnull?: Maybe<Scalars['Boolean']>;
  sender?: Maybe<Scalars['String']>;
  sender_Iexact?: Maybe<Scalars['String']>;
  sender_Contains?: Maybe<Scalars['String']>;
  sender_Icontains?: Maybe<Scalars['String']>;
  sender_Startswith?: Maybe<Scalars['String']>;
  sender_Istartswith?: Maybe<Scalars['String']>;
  sender_Endswith?: Maybe<Scalars['String']>;
  sender_Iendswith?: Maybe<Scalars['String']>;
  sender_In?: Maybe<Scalars['String']>;
  recipient_Isnull?: Maybe<Scalars['Boolean']>;
  recipient?: Maybe<Scalars['String']>;
  recipient_Iexact?: Maybe<Scalars['String']>;
  recipient_Contains?: Maybe<Scalars['String']>;
  recipient_Icontains?: Maybe<Scalars['String']>;
  recipient_Startswith?: Maybe<Scalars['String']>;
  recipient_Istartswith?: Maybe<Scalars['String']>;
  recipient_Endswith?: Maybe<Scalars['String']>;
  recipient_Iendswith?: Maybe<Scalars['String']>;
  recipient_In?: Maybe<Scalars['String']>;
  subject_Isnull?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  subject_Iexact?: Maybe<Scalars['String']>;
  subject_Contains?: Maybe<Scalars['String']>;
  subject_Icontains?: Maybe<Scalars['String']>;
  subject_Startswith?: Maybe<Scalars['String']>;
  subject_Istartswith?: Maybe<Scalars['String']>;
  subject_Endswith?: Maybe<Scalars['String']>;
  subject_Iendswith?: Maybe<Scalars['String']>;
  subject_In?: Maybe<Scalars['String']>;
  body_Isnull?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  messageId_Isnull?: Maybe<Scalars['Boolean']>;
  messageId?: Maybe<Scalars['UUID']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
};

export type PasswordExpirationInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** expiration */
  expiration?: Maybe<Scalars['CustomDateTime']>;
  /** expiration */
  expiration_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** expiration */
  expiration_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** expiration */
  expiration_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** expiration */
  expiration_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  expiration_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  user?: Maybe<UserInputFilterObject>;
};

export type PasswordHistoryInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** Password hash */
  password?: Maybe<Scalars['String']>;
  /** Password hash */
  password_Icontains?: Maybe<Scalars['String']>;
  /** Date */
  date?: Maybe<Scalars['CustomDateTime']>;
  /** Date */
  date_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** Date */
  date_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** Date */
  date_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** Date */
  date_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  password_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  userConfig?: Maybe<UserPasswordHistoryConfigInputFilterObject>;
};

/** An enumeration. */
export enum Payment_Rule {
  MandatoryNotNullFieldsRule = 'MandatoryNotNullFieldsRule',
  CardIsReadyRule = 'CardIsReadyRule',
  TransactionTypeRule = 'TransactionTypeRule',
  HasInsuranceRule = 'HasInsuranceRule',
  SufficientFunds = 'SufficientFunds',
  ClaimPreauthRule = 'ClaimPreauthRule',
  PendingClaimRule = 'PendingClaimRule',
  BenefitAmountRule = 'BenefitAmountRule',
  BenefitCountRule = 'BenefitCountRule',
  CardDynamicRules = 'CardDynamicRules',
  MerchantCodeRule = 'MerchantCodeRule',
  CountryRule = 'CountryRule',
  CurrencyRule = 'CurrencyRule',
  TimestampRule = 'TimestampRule',
  InsufficientFundsRule = 'InsufficientFundsRule',
  LocationRule = 'LocationRule',
  MerchantNameRule = 'MerchantNameRule',
  UnsupportedMccRule = 'UnsupportedMCCRule',
  MerchantIdRule = 'MerchantIdRule',
  CardHolderPresenceRule = 'CardHolderPresenceRule',
  CardPresenceRule = 'CardPresenceRule',
  CardDataInputRule = 'CardDataInputRule',
  ChipFallbackRule = 'ChipFallbackRule',
  FraudIndicatorMerchantRule = 'FraudIndicatorMerchantRule',
  SecurityProtocolRule = 'SecurityProtocolRule',
  SecurityMethod3dSecureRule = 'SecurityMethod3dSecureRule',
  AuthenticationMethodRule = 'AuthenticationMethodRule',
  AuthenticationEntityRule = 'AuthenticationEntityRule',
  CashbackRule = 'CashbackRule',
  GratuityFeeRule = 'GratuityFeeRule'
}

/** An enumeration. */
export enum PaymentAuthorizationAttemptDecisionByEnum {
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Processor */
  Processor = 'PROCESSOR',
  /** Visa Report */
  VisaReport = 'VISA_REPORT'
}

export type PaymentAuthorizationAttemptInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gte?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lte?: Maybe<Scalars['Int']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** external authorization_attempt_id */
  authorizationAttemptId?: Maybe<Scalars['String']>;
  /** external authorization_attempt_id */
  authorizationAttemptId_Icontains?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Gt?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Lt?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Gte?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Lte?: Maybe<Scalars['Float']>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** transaction type */
  transactionType?: Maybe<Scalars['String']>;
  /** transaction type */
  transactionType_Icontains?: Maybe<Scalars['String']>;
  decisionResult?: Maybe<Scalars['Boolean']>;
  /** decision by */
  decisionBy?: Maybe<Scalars['String']>;
  /** decision by */
  decisionBy_Icontains?: Maybe<Scalars['String']>;
  /** raw request */
  rawRequest?: Maybe<Scalars['JSONString']>;
  /** raw decision */
  rawDecision?: Maybe<Scalars['JSONString']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  keepProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  needManualProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  authorizationAttemptId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  fxRate_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  transactionType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  decisionResult_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  decisionBy_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  rawRequest_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  rawDecision_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  metadata_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  authorization?: Maybe<PaymentAuthorizationInputFilterObject>;
  overrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
  reversals?: Maybe<PaymentAuthorizationAttemptReversalInputFilterObject>;
};

export type PaymentAuthorizationAttemptListType = {
  __typename?: 'PaymentAuthorizationAttemptListType';
  /** PaymentAuthorizationAttempt list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PaymentAuthorizationAttemptType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentAuthorizationAttemptListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PaymentAuthorizationAttemptReversalInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gte?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lte?: Maybe<Scalars['Int']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** external reversal id */
  reversalId?: Maybe<Scalars['String']>;
  /** external reversal id */
  reversalId_Icontains?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Gt?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Lt?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Gte?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Lte?: Maybe<Scalars['Float']>;
  /** raw request */
  rawRequest?: Maybe<Scalars['JSONString']>;
  /** reversed by */
  reversedBy?: Maybe<Scalars['String']>;
  /** reversed by */
  reversedBy_Icontains?: Maybe<Scalars['String']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  keepProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  needManualProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  reversalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  fxRate_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  rawRequest_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  reversedBy_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  metadata_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  authorizationAttempt?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
};

export type PaymentAuthorizationAttemptReversalListType = {
  __typename?: 'PaymentAuthorizationAttemptReversalListType';
  /** PaymentAuthorizationAttemptReversal list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PaymentAuthorizationAttemptReversalType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentAuthorizationAttemptReversalListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum PaymentAuthorizationAttemptReversalReversedByEnum {
  /** Manually */
  Manually = 'MANUALLY',
  /** Processor */
  Processor = 'PROCESSOR',
  /** Visa Report */
  VisaReport = 'VISA_REPORT'
}

export type PaymentAuthorizationAttemptReversalType = {
  __typename?: 'PaymentAuthorizationAttemptReversalType';
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** authorization attempt */
  authorizationAttempt?: Maybe<PaymentAuthorizationAttemptType>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** fx rate */
  fxRate?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  keepProcessing?: Maybe<Scalars['Boolean']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** raw request */
  rawRequest?: Maybe<Scalars['JSONString']>;
  /** external reversal id */
  reversalId?: Maybe<Scalars['String']>;
  /** reversed by */
  reversedBy?: Maybe<PaymentAuthorizationAttemptReversalReversedByEnum>;
};

/** An enumeration. */
export enum PaymentAuthorizationAttemptTransactionTypeEnum {
  /** Debits Goods Services */
  DebitsGoodsServices = 'DEBITS_GOODS_SERVICES',
  /** Debits Atm */
  DebitsAtm = 'DEBITS_ATM',
  /** Adjustment Credits */
  AdjustmentCredits = 'ADJUSTMENT_CREDITS',
  /** Debits Goods Cash Back */
  DebitsGoodsCashBack = 'DEBITS_GOODS_CASH_BACK',
  /** Debits Cash Advances */
  DebitsCashAdvances = 'DEBITS_CASH_ADVANCES',
  /** Debits Cash Advances 2 */
  DebitsCashAdvances_2 = 'DEBITS_CASH_ADVANCES_2',
  /** Unique Mcc */
  UniqueMcc = 'UNIQUE_MCC',
  /** Debit Adjustments */
  DebitAdjustments = 'DEBIT_ADJUSTMENTS',
  /** Credits Refund */
  CreditsRefund = 'CREDITS_REFUND',
  /** Credits Deposit */
  CreditsDeposit = 'CREDITS_DEPOSIT',
  /** Credits Card Load */
  CreditsCardLoad = 'CREDITS_CARD_LOAD',
  /** Credits Card Unload */
  CreditsCardUnload = 'CREDITS_CARD_UNLOAD',
  /** Original Credits */
  OriginalCredits = 'ORIGINAL_CREDITS',
  /** Credits Payment Transaction */
  CreditsPaymentTransaction = 'CREDITS_PAYMENT_TRANSACTION',
  /** Balance Inquiry */
  BalanceInquiry = 'BALANCE_INQUIRY',
  /** Tokenisation Auth */
  TokenisationAuth = 'TOKENISATION_AUTH',
  /** Tokenisation Activation Code */
  TokenisationActivationCode = 'TOKENISATION_ACTIVATION_CODE',
  /** Tokenisation Completion */
  TokenisationCompletion = 'TOKENISATION_COMPLETION',
  /** Tokenisation Event */
  TokenisationEvent = 'TOKENISATION_EVENT',
  /** Pin Unblock */
  PinUnblock = 'PIN_UNBLOCK',
  /** Pin Change */
  PinChange = 'PIN_CHANGE'
}

/**
 * Payment authorization attempts
 *
 * - `applied_rules`: List of payment rules applied to this attempt
 * - `pre_auth_matching`: List of pre auth payment rules applied to this attempt
 *
 * Example:
 * -------
 * {
 *   paymentAuthorizationAttempts {
 *     results {
 *       reasons
 *       appliedRules {
 *         reason
 *         result
 *         ruleType
 *         skipped
 *       }
 *       preAuthMatching {
 *         claimId
 *         success
 *         matchingExplanation {
 *           ruleType
 *           score
 *         }
 *       }
 *       overrides {
 *         id
 *       }
 *     }
 *   }
 * }
 */
export type PaymentAuthorizationAttemptType = {
  __typename?: 'PaymentAuthorizationAttemptType';
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** authorization */
  authorization?: Maybe<PaymentAuthorizationType>;
  /** external authorization_attempt_id */
  authorizationAttemptId?: Maybe<Scalars['String']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** decision by */
  decisionBy?: Maybe<PaymentAuthorizationAttemptDecisionByEnum>;
  decisionResult?: Maybe<Scalars['Boolean']>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** fx rate */
  fxRate?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  keepProcessing?: Maybe<Scalars['Boolean']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** PaymentRulesOverride list */
  overrides?: Maybe<Array<Maybe<PaymentRulesOverrideType>>>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** raw decision */
  rawDecision?: Maybe<Scalars['JSONString']>;
  /** raw request */
  rawRequest?: Maybe<Scalars['JSONString']>;
  /** PaymentAuthorizationAttemptReversal list */
  reversals?: Maybe<Array<Maybe<PaymentAuthorizationAttemptReversalType>>>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
  /** transaction type */
  transactionType?: Maybe<PaymentAuthorizationAttemptTransactionTypeEnum>;
  appliedRules?: Maybe<Array<Maybe<PaymentPlatformRuleType>>>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  preAuthMatching?: Maybe<Array<Maybe<PreAuthMatchingType>>>;
};

/**
 * Payment authorization attempts
 *
 * - `applied_rules`: List of payment rules applied to this attempt
 * - `pre_auth_matching`: List of pre auth payment rules applied to this attempt
 *
 * Example:
 * -------
 * {
 *   paymentAuthorizationAttempts {
 *     results {
 *       reasons
 *       appliedRules {
 *         reason
 *         result
 *         ruleType
 *         skipped
 *       }
 *       preAuthMatching {
 *         claimId
 *         success
 *         matchingExplanation {
 *           ruleType
 *           score
 *         }
 *       }
 *       overrides {
 *         id
 *       }
 *     }
 *   }
 * }
 */
export type PaymentAuthorizationAttemptTypeOverridesArgs = {
  paymentauthorizationattempt_Isnull?: Maybe<Scalars['Boolean']>;
  paymentauthorizationattempt?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProxyId_Isnull?: Maybe<Scalars['Boolean']>;
  cardProxyId?: Maybe<Scalars['String']>;
  cardProxyId_Iexact?: Maybe<Scalars['String']>;
  cardProxyId_Contains?: Maybe<Scalars['String']>;
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  cardProxyId_Startswith?: Maybe<Scalars['String']>;
  cardProxyId_Istartswith?: Maybe<Scalars['String']>;
  cardProxyId_Endswith?: Maybe<Scalars['String']>;
  cardProxyId_Iendswith?: Maybe<Scalars['String']>;
  cardProxyId_In?: Maybe<Scalars['String']>;
  overrideType_Isnull?: Maybe<Scalars['Boolean']>;
  overrideType?: Maybe<Scalars['String']>;
  overrideType_Iexact?: Maybe<Scalars['String']>;
  overrideType_Contains?: Maybe<Scalars['String']>;
  overrideType_Icontains?: Maybe<Scalars['String']>;
  overrideType_Startswith?: Maybe<Scalars['String']>;
  overrideType_Istartswith?: Maybe<Scalars['String']>;
  overrideType_Endswith?: Maybe<Scalars['String']>;
  overrideType_Iendswith?: Maybe<Scalars['String']>;
  overrideType_In?: Maybe<Scalars['String']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  validUntil_Isnull?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_Gt?: Maybe<Scalars['DateTime']>;
  validUntil_Gte?: Maybe<Scalars['DateTime']>;
  validUntil_Lt?: Maybe<Scalars['DateTime']>;
  validUntil_Lte?: Maybe<Scalars['DateTime']>;
  validUntil_In?: Maybe<Scalars['DateTime']>;
  validNTimes_Isnull?: Maybe<Scalars['Boolean']>;
  validNTimes?: Maybe<Scalars['Int']>;
  validNTimes_Gt?: Maybe<Scalars['Int']>;
  validNTimes_Gte?: Maybe<Scalars['Int']>;
  validNTimes_Lt?: Maybe<Scalars['Int']>;
  validNTimes_Lte?: Maybe<Scalars['Int']>;
  validNTimes_In?: Maybe<Scalars['Int']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  specificationQuery?: Maybe<Scalars['String']>;
  overrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
};

/**
 * Payment authorization attempts
 *
 * - `applied_rules`: List of payment rules applied to this attempt
 * - `pre_auth_matching`: List of pre auth payment rules applied to this attempt
 *
 * Example:
 * -------
 * {
 *   paymentAuthorizationAttempts {
 *     results {
 *       reasons
 *       appliedRules {
 *         reason
 *         result
 *         ruleType
 *         skipped
 *       }
 *       preAuthMatching {
 *         claimId
 *         success
 *         matchingExplanation {
 *           ruleType
 *           score
 *         }
 *       }
 *       overrides {
 *         id
 *       }
 *     }
 *   }
 * }
 */
export type PaymentAuthorizationAttemptTypeReversalsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Scalars['DateTime']>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Scalars['DateTime']>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Scalars['Int']>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  authorizationAttempt_Isnull?: Maybe<Scalars['Boolean']>;
  authorizationAttempt?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
  reversalId_Isnull?: Maybe<Scalars['Boolean']>;
  reversalId?: Maybe<Scalars['String']>;
  reversalId_Iexact?: Maybe<Scalars['String']>;
  reversalId_Contains?: Maybe<Scalars['String']>;
  reversalId_Icontains?: Maybe<Scalars['String']>;
  reversalId_Startswith?: Maybe<Scalars['String']>;
  reversalId_Istartswith?: Maybe<Scalars['String']>;
  reversalId_Endswith?: Maybe<Scalars['String']>;
  reversalId_Iendswith?: Maybe<Scalars['String']>;
  reversalId_In?: Maybe<Scalars['String']>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Scalars['Float']>;
  reversedBy_Isnull?: Maybe<Scalars['Boolean']>;
  reversedBy?: Maybe<Scalars['String']>;
  reversedBy_Iexact?: Maybe<Scalars['String']>;
  reversedBy_Contains?: Maybe<Scalars['String']>;
  reversedBy_Icontains?: Maybe<Scalars['String']>;
  reversedBy_Startswith?: Maybe<Scalars['String']>;
  reversedBy_Istartswith?: Maybe<Scalars['String']>;
  reversedBy_Endswith?: Maybe<Scalars['String']>;
  reversedBy_Iendswith?: Maybe<Scalars['String']>;
  reversedBy_In?: Maybe<Scalars['String']>;
};

export type PaymentAuthorizationInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** external authorization_id */
  authorizationId?: Maybe<Scalars['String']>;
  /** external authorization_id */
  authorizationId_Icontains?: Maybe<Scalars['String']>;
  /** card proxy id */
  cardProxyId?: Maybe<Scalars['String']>;
  /** card proxy id */
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  /** beneficiary id */
  beneficiaryId?: Maybe<Scalars['Int']>;
  /** beneficiary id */
  beneficiaryId_Gt?: Maybe<Scalars['Int']>;
  /** beneficiary id */
  beneficiaryId_Lt?: Maybe<Scalars['Int']>;
  /** beneficiary id */
  beneficiaryId_Gte?: Maybe<Scalars['Int']>;
  /** beneficiary id */
  beneficiaryId_Lte?: Maybe<Scalars['Int']>;
  /** merchant name */
  merchantName?: Maybe<Scalars['String']>;
  /** merchant name */
  merchantName_Icontains?: Maybe<Scalars['String']>;
  /** merchant external id */
  merchantExternalId?: Maybe<Scalars['String']>;
  /** merchant external id */
  merchantExternalId_Icontains?: Maybe<Scalars['String']>;
  /** merchant address */
  merchantAddress?: Maybe<Scalars['String']>;
  /** merchant address */
  merchantAddress_Icontains?: Maybe<Scalars['String']>;
  /** merchant country code */
  merchantCountryCode?: Maybe<Scalars['String']>;
  /** merchant country code */
  merchantCountryCode_Icontains?: Maybe<Scalars['String']>;
  /** merchant category code */
  merchantCategoryCode?: Maybe<Scalars['String']>;
  /** merchant category code */
  merchantCategoryCode_Icontains?: Maybe<Scalars['String']>;
  /** currency code */
  currencyCode?: Maybe<Scalars['String']>;
  /** currency code */
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  authorizationId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiaryId_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  merchantName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantExternalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantAddress_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantCountryCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantCategoryCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  metadata_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  claim?: Maybe<ClaimInputFilterObject>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  attempts?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
};

export type PaymentAuthorizationListType = {
  __typename?: 'PaymentAuthorizationListType';
  /** PaymentAuthorization list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PaymentAuthorizationType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentAuthorizationListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PaymentAuthorizationType = {
  __typename?: 'PaymentAuthorizationType';
  /** PaymentAuthorizationAttempt list */
  attempts?: Maybe<Array<Maybe<PaymentAuthorizationAttemptType>>>;
  /** external authorization_id */
  authorizationId?: Maybe<Scalars['String']>;
  /** beneficiary id */
  beneficiaryId?: Maybe<Scalars['Int']>;
  /** card proxy id */
  cardProxyId?: Maybe<Scalars['String']>;
  /** claim */
  claim?: Maybe<ClaimType>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** currency code */
  currencyCode?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** InsurerPayment list */
  insurerPayments?: Maybe<Array<Maybe<InsurerPaymentType>>>;
  /** merchant address */
  merchantAddress?: Maybe<Scalars['String']>;
  /** merchant category code */
  merchantCategoryCode?: Maybe<Scalars['String']>;
  /** merchant country code */
  merchantCountryCode?: Maybe<Scalars['String']>;
  /** merchant external id */
  merchantExternalId?: Maybe<Scalars['String']>;
  /** merchant name */
  merchantName?: Maybe<Scalars['String']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  acceptedAmount?: Maybe<Scalars['Float']>;
  card?: Maybe<CardType>;
  settlement?: Maybe<PaymentSettlementType>;
};

export type PaymentAuthorizationTypeAttemptsArgs = {
  reversals_Isnull?: Maybe<Scalars['Boolean']>;
  reversals?: Maybe<PaymentAuthorizationAttemptReversalInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Scalars['DateTime']>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Scalars['DateTime']>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Scalars['Int']>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  authorization_Isnull?: Maybe<Scalars['Boolean']>;
  authorization?: Maybe<PaymentAuthorizationInputFilterObject>;
  authorizationAttemptId_Isnull?: Maybe<Scalars['Boolean']>;
  authorizationAttemptId?: Maybe<Scalars['String']>;
  authorizationAttemptId_Iexact?: Maybe<Scalars['String']>;
  authorizationAttemptId_Contains?: Maybe<Scalars['String']>;
  authorizationAttemptId_Icontains?: Maybe<Scalars['String']>;
  authorizationAttemptId_Startswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_Istartswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_Endswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_Iendswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_In?: Maybe<Scalars['String']>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Scalars['Float']>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Scalars['DateTime']>;
  transactionType_Isnull?: Maybe<Scalars['Boolean']>;
  transactionType?: Maybe<Scalars['String']>;
  transactionType_Iexact?: Maybe<Scalars['String']>;
  transactionType_Contains?: Maybe<Scalars['String']>;
  transactionType_Icontains?: Maybe<Scalars['String']>;
  transactionType_Startswith?: Maybe<Scalars['String']>;
  transactionType_Istartswith?: Maybe<Scalars['String']>;
  transactionType_Endswith?: Maybe<Scalars['String']>;
  transactionType_Iendswith?: Maybe<Scalars['String']>;
  transactionType_In?: Maybe<Scalars['String']>;
  decisionResult_Isnull?: Maybe<Scalars['Boolean']>;
  decisionResult?: Maybe<Scalars['Boolean']>;
  decisionBy_Isnull?: Maybe<Scalars['Boolean']>;
  decisionBy?: Maybe<Scalars['String']>;
  decisionBy_Iexact?: Maybe<Scalars['String']>;
  decisionBy_Contains?: Maybe<Scalars['String']>;
  decisionBy_Icontains?: Maybe<Scalars['String']>;
  decisionBy_Startswith?: Maybe<Scalars['String']>;
  decisionBy_Istartswith?: Maybe<Scalars['String']>;
  decisionBy_Endswith?: Maybe<Scalars['String']>;
  decisionBy_Iendswith?: Maybe<Scalars['String']>;
  decisionBy_In?: Maybe<Scalars['String']>;
  overrides_Isnull?: Maybe<Scalars['Boolean']>;
  overrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
};

export type PaymentAuthorizationTypeInsurerPaymentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Scalars['DateTime']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  paymentPtr_Isnull?: Maybe<Scalars['Boolean']>;
  paymentPtr?: Maybe<PaymentInputFilterObject>;
  card_Isnull?: Maybe<Scalars['Boolean']>;
  card?: Maybe<CardInputFilterObject>;
  authorization_Isnull?: Maybe<Scalars['Boolean']>;
  authorization?: Maybe<PaymentAuthorizationInputFilterObject>;
  settlement_Isnull?: Maybe<Scalars['Boolean']>;
  settlement?: Maybe<PaymentSettlementInputFilterObject>;
};

export type PaymentFrequencyControlSpecificationType = RulesCardSpecification & {
  __typename?: 'PaymentFrequencyControlSpecificationType';
  ruleName?: Maybe<Scalars['String']>;
  timeWindowMinutes?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type PaymentFrequencyControlSpecificationTypeInput = {
  timeWindowMinutes?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
};

export type PaymentInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** state */
  state?: Maybe<Scalars['String']>;
  /** state */
  state_Icontains?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  claim?: Maybe<ClaimInputFilterObject>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  beneficiaryPayments?: Maybe<BeneficiaryPaymentInputFilterObject>;
};

export type PaymentInterface = {
  amount?: Maybe<Scalars['Float']>;
  /** claim */
  claim?: Maybe<ClaimType>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** state */
  state?: Maybe<PaymentStateEnum>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
};

/** This field contains metadata about decisions taken by Payment Platform. */
export type PaymentPlatformRuleType = {
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
};

export type PaymentRuleListType = {
  __typename?: 'PaymentRuleListType';
  results?: Maybe<Array<Maybe<PaymentRuleType>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentRuleListTypeResultsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
};

export type PaymentRulesOverrideInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** card proxy id */
  cardProxyId?: Maybe<Scalars['String']>;
  /** card proxy id */
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  /** override type */
  overrideType?: Maybe<Scalars['String']>;
  /** override type */
  overrideType_Icontains?: Maybe<Scalars['String']>;
  /** data */
  data?: Maybe<Scalars['JSONString']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** comment */
  comment?: Maybe<Scalars['String']>;
  /** comment */
  comment_Icontains?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** valid until */
  validUntil?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** valid until */
  validUntil_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** valid n times */
  validNTimes?: Maybe<Scalars['Int']>;
  /** valid n times */
  validNTimes_Gt?: Maybe<Scalars['Int']>;
  /** valid n times */
  validNTimes_Lt?: Maybe<Scalars['Int']>;
  /** valid n times */
  validNTimes_Gte?: Maybe<Scalars['Int']>;
  /** valid n times */
  validNTimes_Lte?: Maybe<Scalars['Int']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cardProxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  overrideType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  data_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  comment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  validUntil_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  validNTimes_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  overrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
  paymentauthorizationattempt?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
};

export type PaymentRulesOverrideListType = {
  __typename?: 'PaymentRulesOverrideListType';
  /** PaymentRulesOverride list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PaymentRulesOverrideType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentRulesOverrideListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PaymentRulesOverrideMutation = {
  __typename?: 'PaymentRulesOverrideMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  paymentrulesoverride?: Maybe<PaymentRulesOverrideType>;
};

/** An enumeration. */
export enum PaymentRulesOverrideOverrideTypeEnum {
  /** Disable Payment Platform authorization rules */
  RulesDisable = 'RULES_DISABLE',
  /** Change parameters of Payment Platform authorization rules */
  RulesModification = 'RULES_MODIFICATION',
  /** Aggregated beneficiary card overrides */
  CardOverrides = 'CARD_OVERRIDES'
}

/**
 * "
 * Payment rules overrides
 *
 * Extra filters:
 * --------------
 * * `is_enabled` - it checks whether expiration fields (`is_active`, `valid_until` and `valid_n_times`)
 * wasn't violated
 *
 * ```
 * {
 *     "card_id": int (Card.id),
 *     "override_type": enum (RULES_DISABLE, RULES_MODIFICATION, CARD_OVERRIDES),
 *     "data": str (json),
 *     "created_at": timestamp,
 *     "comment": str,
 *     "requested_by": int (Beneficiary.id),
 *     "is_active": bool,
 *     "valid_until": timestamp,
 *     "valid_n_times" int
 * }
 * ```
 * * `specification_query` (string) - "Raw filter" for override `data` field. it uses django filter query, see docs
 *     for more info: https://docs.djangoproject.com/en/2.2/ref/contrib/postgres/fields/#querying-hstorefield
 *
 *     The format is <field in specification data>=<value>;<type conversion>. To access child field use double scores
 *     (e.g. given data field {"a": {"b": 10}} query "a__b=10" would retrieve all entities which b is equal to 10).
 *     Part after <value> is optional and is used for value conversion. By default value is considered to be string if
 *     no conversion is specified. Supported conversion are: `int`, `float` and `bool`.
 *
 *     Examples:
 *         - `has_key=payment-frequency-control`: Get all frequency card overrides
 *         - `payment-velocity-control__has_key=amount`: Get all velocity card overrides which change `amount` value
 *         - `payment-velocity-control__amount=10;int`: Get all velocity card overrides with amount equal to 10
 *
 * Update mutation:
 * ----------------
 * Modification of just these fields are permitted: `comment`, `is_active`, `valid_until` and `valid_n_times`. In order
 *  to change other fields you need to deactivate the previous override of the given type, if there is any, and
 *  create a new override.
 *
 *
 * # `data` schema for override_type
 *
 * - `RULES_DISABLE`: List of rule names or `*` to disable all the rules. In order to disable particular pre_auth
 * sub-rules use `ClaimPreauthRule.<name_of_the_preauth_rule>` or just `ClaimPreauthRule` to disable whole preauth
 * matching.
 * For example: `["ClaimPreauthRule.CountryRule", "BenefitAmountRule"]`
 * - `CARD_OVERRIDES`: Dict of rule names with spefications as values such as:
 * `{"payment-frequency-control": {"count": 5}}`
 * - `RULES_MODIFICATION`: Dict<rule_name, Dict<rule_field_name, rule_field_value>> - change parameters of particular
 * Payment Platform rules. It's done by creating new instance of rule class from provided params fed to
 * constructor. Unfortunately a knowledge about particular classes is necessary and won't be validated on creation,
 * but in PaymentPlatform when processing a transaction. Note that this will change with GraphQL. Same as with
 * `RULES_DISABLE` dot notation can be used to modify preauth rules.
 */
export type PaymentRulesOverrideType = {
  __typename?: 'PaymentRulesOverrideType';
  /** card proxy id */
  cardProxyId?: Maybe<Scalars['String']>;
  /** comment */
  comment?: Maybe<Scalars['String']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** data */
  data?: Maybe<Scalars['JSONString']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  /** override type */
  overrideType?: Maybe<PaymentRulesOverrideOverrideTypeEnum>;
  /** PaymentAuthorizationAttempt list */
  paymentauthorizationattempt?: Maybe<Array<Maybe<PaymentAuthorizationAttemptType>>>;
  /** requested by */
  requestedBy?: Maybe<BeneficiaryType>;
  /** valid n times */
  validNTimes?: Maybe<Scalars['Int']>;
  /** valid until */
  validUntil?: Maybe<Scalars['CustomDateTime']>;
  card?: Maybe<CardType>;
  override?: Maybe<RulesOverride>;
};

/**
 * "
 * Payment rules overrides
 *
 * Extra filters:
 * --------------
 * * `is_enabled` - it checks whether expiration fields (`is_active`, `valid_until` and `valid_n_times`)
 * wasn't violated
 *
 * ```
 * {
 *     "card_id": int (Card.id),
 *     "override_type": enum (RULES_DISABLE, RULES_MODIFICATION, CARD_OVERRIDES),
 *     "data": str (json),
 *     "created_at": timestamp,
 *     "comment": str,
 *     "requested_by": int (Beneficiary.id),
 *     "is_active": bool,
 *     "valid_until": timestamp,
 *     "valid_n_times" int
 * }
 * ```
 * * `specification_query` (string) - "Raw filter" for override `data` field. it uses django filter query, see docs
 *     for more info: https://docs.djangoproject.com/en/2.2/ref/contrib/postgres/fields/#querying-hstorefield
 *
 *     The format is <field in specification data>=<value>;<type conversion>. To access child field use double scores
 *     (e.g. given data field {"a": {"b": 10}} query "a__b=10" would retrieve all entities which b is equal to 10).
 *     Part after <value> is optional and is used for value conversion. By default value is considered to be string if
 *     no conversion is specified. Supported conversion are: `int`, `float` and `bool`.
 *
 *     Examples:
 *         - `has_key=payment-frequency-control`: Get all frequency card overrides
 *         - `payment-velocity-control__has_key=amount`: Get all velocity card overrides which change `amount` value
 *         - `payment-velocity-control__amount=10;int`: Get all velocity card overrides with amount equal to 10
 *
 * Update mutation:
 * ----------------
 * Modification of just these fields are permitted: `comment`, `is_active`, `valid_until` and `valid_n_times`. In order
 *  to change other fields you need to deactivate the previous override of the given type, if there is any, and
 *  create a new override.
 *
 *
 * # `data` schema for override_type
 *
 * - `RULES_DISABLE`: List of rule names or `*` to disable all the rules. In order to disable particular pre_auth
 * sub-rules use `ClaimPreauthRule.<name_of_the_preauth_rule>` or just `ClaimPreauthRule` to disable whole preauth
 * matching.
 * For example: `["ClaimPreauthRule.CountryRule", "BenefitAmountRule"]`
 * - `CARD_OVERRIDES`: Dict of rule names with spefications as values such as:
 * `{"payment-frequency-control": {"count": 5}}`
 * - `RULES_MODIFICATION`: Dict<rule_name, Dict<rule_field_name, rule_field_value>> - change parameters of particular
 * Payment Platform rules. It's done by creating new instance of rule class from provided params fed to
 * constructor. Unfortunately a knowledge about particular classes is necessary and won't be validated on creation,
 * but in PaymentPlatform when processing a transaction. Note that this will change with GraphQL. Same as with
 * `RULES_DISABLE` dot notation can be used to modify preauth rules.
 */
export type PaymentRulesOverrideTypePaymentauthorizationattemptArgs = {
  reversals_Isnull?: Maybe<Scalars['Boolean']>;
  reversals?: Maybe<PaymentAuthorizationAttemptReversalInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Scalars['DateTime']>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Scalars['DateTime']>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Scalars['Int']>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  authorization_Isnull?: Maybe<Scalars['Boolean']>;
  authorization?: Maybe<PaymentAuthorizationInputFilterObject>;
  authorizationAttemptId_Isnull?: Maybe<Scalars['Boolean']>;
  authorizationAttemptId?: Maybe<Scalars['String']>;
  authorizationAttemptId_Iexact?: Maybe<Scalars['String']>;
  authorizationAttemptId_Contains?: Maybe<Scalars['String']>;
  authorizationAttemptId_Icontains?: Maybe<Scalars['String']>;
  authorizationAttemptId_Startswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_Istartswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_Endswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_Iendswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_In?: Maybe<Scalars['String']>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Scalars['Float']>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Scalars['DateTime']>;
  transactionType_Isnull?: Maybe<Scalars['Boolean']>;
  transactionType?: Maybe<Scalars['String']>;
  transactionType_Iexact?: Maybe<Scalars['String']>;
  transactionType_Contains?: Maybe<Scalars['String']>;
  transactionType_Icontains?: Maybe<Scalars['String']>;
  transactionType_Startswith?: Maybe<Scalars['String']>;
  transactionType_Istartswith?: Maybe<Scalars['String']>;
  transactionType_Endswith?: Maybe<Scalars['String']>;
  transactionType_Iendswith?: Maybe<Scalars['String']>;
  transactionType_In?: Maybe<Scalars['String']>;
  decisionResult_Isnull?: Maybe<Scalars['Boolean']>;
  decisionResult?: Maybe<Scalars['Boolean']>;
  decisionBy_Isnull?: Maybe<Scalars['Boolean']>;
  decisionBy?: Maybe<Scalars['String']>;
  decisionBy_Iexact?: Maybe<Scalars['String']>;
  decisionBy_Contains?: Maybe<Scalars['String']>;
  decisionBy_Icontains?: Maybe<Scalars['String']>;
  decisionBy_Startswith?: Maybe<Scalars['String']>;
  decisionBy_Istartswith?: Maybe<Scalars['String']>;
  decisionBy_Endswith?: Maybe<Scalars['String']>;
  decisionBy_Iendswith?: Maybe<Scalars['String']>;
  decisionBy_In?: Maybe<Scalars['String']>;
  overrides_Isnull?: Maybe<Scalars['Boolean']>;
  overrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
};

/**
 * Rules supported by PaymentPlatform.
 *
 * ```
 * {
 *   paymentRules {
 *     ruleName
 *     subrules
 *   }
 * }
 * ```
 */
export type PaymentRuleType = {
  __typename?: 'PaymentRuleType';
  ruleName?: Maybe<Scalars['String']>;
  subrules?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** An enumeration. */
export enum PaymentSettlementEventEventTypeEnum {
  /** First presentment */
  FirstPresentment = 'FIRST_PRESENTMENT',
  /** First presentment: Refund */
  FirstPresentmentRefund = 'FIRST_PRESENTMENT_REFUND',
  /** Settlement reversal of a purchase */
  PurchaseReversal = 'PURCHASE_REVERSAL',
  /** Settlement reversal of a refund */
  RefundReversal = 'REFUND_REVERSAL',
  /** Settlement reversal of a cash withdrawal */
  WithdrawalReversal = 'WITHDRAWAL_REVERSAL'
}

export type PaymentSettlementEventInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gte?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lte?: Maybe<Scalars['Int']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** external settlement_event_id */
  settlementEventId?: Maybe<Scalars['String']>;
  /** external settlement_event_id */
  settlementEventId_Icontains?: Maybe<Scalars['String']>;
  /** event type */
  eventType?: Maybe<Scalars['String']>;
  /** event type */
  eventType_Icontains?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Gt?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Lt?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Gte?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Lte?: Maybe<Scalars['Float']>;
  /** raw request */
  rawRequest?: Maybe<Scalars['JSONString']>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** transaction time */
  transactionTime_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  keepProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  needManualProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  settlementEventId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  eventType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  fxRate_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  rawRequest_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  metadata_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  settlement?: Maybe<PaymentSettlementInputFilterObject>;
  issuerTransactions?: Maybe<SettlementTransactionInputFilterObject>;
};

export type PaymentSettlementEventType = {
  __typename?: 'PaymentSettlementEventType';
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** event type */
  eventType?: Maybe<PaymentSettlementEventEventTypeEnum>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** fx rate */
  fxRate?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  keepProcessing?: Maybe<Scalars['Boolean']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** raw request */
  rawRequest?: Maybe<Scalars['JSONString']>;
  /** settlement */
  settlement?: Maybe<PaymentSettlementType>;
  /** external settlement_event_id */
  settlementEventId?: Maybe<Scalars['String']>;
  /** transaction time */
  transactionTime?: Maybe<Scalars['CustomDateTime']>;
};

export type PaymentSettlementInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** processed at */
  processedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** failed at */
  failedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lt?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Gte?: Maybe<Scalars['Int']>;
  /** failed count */
  failedCount_Lte?: Maybe<Scalars['Int']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** external authorization_id */
  authorizationId?: Maybe<Scalars['String']>;
  /** external authorization_id */
  authorizationId_Icontains?: Maybe<Scalars['String']>;
  /** currency code */
  currencyCode?: Maybe<Scalars['String']>;
  /** currency code */
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  keepProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  needManualProcessing_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  authorizationId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  issuerTransactions?: Maybe<SettlementTransactionInputFilterObject>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  events?: Maybe<PaymentSettlementEventInputFilterObject>;
};

export type PaymentSettlementListType = {
  __typename?: 'PaymentSettlementListType';
  /** PaymentSettlement list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PaymentSettlementType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaymentSettlementListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PaymentSettlementType = {
  __typename?: 'PaymentSettlementType';
  /** external authorization_id */
  authorizationId?: Maybe<Scalars['String']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** currency code */
  currencyCode?: Maybe<Scalars['String']>;
  /** PaymentSettlementEvent list */
  events?: Maybe<Array<Maybe<PaymentSettlementEventType>>>;
  /** failed at */
  failedAt?: Maybe<Scalars['CustomDateTime']>;
  /** failed count */
  failedCount?: Maybe<Scalars['Int']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** InsurerPayment list */
  insurerPayments?: Maybe<Array<Maybe<InsurerPaymentType>>>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  /** processed at */
  processedAt?: Maybe<Scalars['CustomDateTime']>;
  paymentAuthorization?: Maybe<PaymentAuthorizationType>;
  amount?: Maybe<Scalars['Float']>;
  fxRate?: Maybe<Scalars['Float']>;
  metadata?: Maybe<Scalars['GenericScalar']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  rawRequest?: Maybe<Scalars['GenericScalar']>;
};

export type PaymentSettlementTypeEventsArgs = {
  issuerTransactions_Isnull?: Maybe<Scalars['Boolean']>;
  issuerTransactions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Scalars['DateTime']>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Scalars['DateTime']>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Scalars['Int']>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  settlement_Isnull?: Maybe<Scalars['Boolean']>;
  settlement?: Maybe<PaymentSettlementInputFilterObject>;
  settlementEventId_Isnull?: Maybe<Scalars['Boolean']>;
  settlementEventId?: Maybe<Scalars['String']>;
  settlementEventId_Iexact?: Maybe<Scalars['String']>;
  settlementEventId_Contains?: Maybe<Scalars['String']>;
  settlementEventId_Icontains?: Maybe<Scalars['String']>;
  settlementEventId_Startswith?: Maybe<Scalars['String']>;
  settlementEventId_Istartswith?: Maybe<Scalars['String']>;
  settlementEventId_Endswith?: Maybe<Scalars['String']>;
  settlementEventId_Iendswith?: Maybe<Scalars['String']>;
  settlementEventId_In?: Maybe<Scalars['String']>;
  eventType_Isnull?: Maybe<Scalars['Boolean']>;
  eventType?: Maybe<Scalars['String']>;
  eventType_Iexact?: Maybe<Scalars['String']>;
  eventType_Contains?: Maybe<Scalars['String']>;
  eventType_Icontains?: Maybe<Scalars['String']>;
  eventType_Startswith?: Maybe<Scalars['String']>;
  eventType_Istartswith?: Maybe<Scalars['String']>;
  eventType_Endswith?: Maybe<Scalars['String']>;
  eventType_Iendswith?: Maybe<Scalars['String']>;
  eventType_In?: Maybe<Scalars['String']>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Scalars['Float']>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Scalars['DateTime']>;
};

export type PaymentSettlementTypeInsurerPaymentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Scalars['DateTime']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  paymentPtr_Isnull?: Maybe<Scalars['Boolean']>;
  paymentPtr?: Maybe<PaymentInputFilterObject>;
  card_Isnull?: Maybe<Scalars['Boolean']>;
  card?: Maybe<CardInputFilterObject>;
  authorization_Isnull?: Maybe<Scalars['Boolean']>;
  authorization?: Maybe<PaymentAuthorizationInputFilterObject>;
  settlement_Isnull?: Maybe<Scalars['Boolean']>;
  settlement?: Maybe<PaymentSettlementInputFilterObject>;
};

/** An enumeration. */
export enum PaymentStateEnum {
  /** Pending */
  Pending = 'PENDING',
  /** Declined */
  Declined = 'DECLINED',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Authorized */
  Authorized = 'AUTHORIZED',
  /** Settled */
  Settled = 'SETTLED'
}

export type PaymentVelocityControlSpecificationType = RulesCardSpecification & {
  __typename?: 'PaymentVelocityControlSpecificationType';
  ruleName?: Maybe<Scalars['String']>;
  timeWindowMinutes?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
};

export type PaymentVelocityControlSpecificationTypeInput = {
  timeWindowMinutes?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
};

/** Checks if there is a pending claim present and if it means that the transaction should be declined. */
export type PendingClaimRuleType = PaymentPlatformRuleType & {
  __typename?: 'PendingClaimRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Boolean']>;
  actual?: Maybe<Scalars['Boolean']>;
};

export type PermissionInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** codename */
  codename?: Maybe<Scalars['String']>;
  /** codename */
  codename_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  codename_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType?: Maybe<ContentTypeInputFilterObject>;
  permissions?: Maybe<PermissionInputFilterObject>;
  group?: Maybe<GroupInputFilterObject>;
  userPermissions?: Maybe<PermissionInputFilterObject>;
  user?: Maybe<UserInputFilterObject>;
};

export type PersonalCardInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** proxy id */
  proxyId?: Maybe<Scalars['String']>;
  /** proxy id */
  proxyId_Icontains?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  proxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  default_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  active_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  policyHolder?: Maybe<PolicyHolderInputFilterObject>;
  beneficiaryPayments?: Maybe<BeneficiaryPaymentInputFilterObject>;
};

export type PersonalCardListType = {
  __typename?: 'PersonalCardListType';
  /** PersonalCard list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PersonalCardType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PersonalCardListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PersonalCardMutation = {
  __typename?: 'PersonalCardMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  personalcard?: Maybe<PersonalCardType>;
};

export type PersonalCardType = {
  __typename?: 'PersonalCardType';
  active?: Maybe<Scalars['Boolean']>;
  /** BeneficiaryPayment list */
  beneficiaryPayments?: Maybe<Array<Maybe<BeneficiaryPaymentType>>>;
  default?: Maybe<Scalars['Boolean']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** policy holder */
  policyHolder?: Maybe<PolicyHolderType>;
  /** proxy id */
  proxyId?: Maybe<Scalars['String']>;
};

export type PersonalCardTypeBeneficiaryPaymentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Scalars['DateTime']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  paymentPtr_Isnull?: Maybe<Scalars['Boolean']>;
  paymentPtr?: Maybe<PaymentInputFilterObject>;
  personalCard_Isnull?: Maybe<Scalars['Boolean']>;
  personalCard?: Maybe<PersonalCardInputFilterObject>;
};

export type PhysicalCardAccountMetaInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** card design */
  cardDesign?: Maybe<Scalars['String']>;
  /** card design */
  cardDesign_Icontains?: Maybe<Scalars['String']>;
  /** card programme */
  cardProgramme?: Maybe<Scalars['String']>;
  /** card programme */
  cardProgramme_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cardDesign_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProgramme_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fundingAccount?: Maybe<FundingAccountInputFilterObject>;
};

export type PhysicalCardAccountMetaListType = {
  __typename?: 'PhysicalCardAccountMetaListType';
  /** PhysicalCardAccountMeta list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PhysicalCardAccountMetaType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PhysicalCardAccountMetaListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PhysicalCardAccountMetaType = {
  __typename?: 'PhysicalCardAccountMetaType';
  /** card design */
  cardDesign?: Maybe<Scalars['String']>;
  /** card programme */
  cardProgramme?: Maybe<Scalars['String']>;
  /** funding account */
  fundingAccount?: Maybe<FundingAccountType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
};

export type PolicyChainInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  product?: Maybe<ProductInputFilterObject>;
  corporatePolicyChain?: Maybe<CorporatePolicyChainInputFilterObject>;
  holders?: Maybe<PolicyHolderInputFilterObject>;
  policies?: Maybe<PolicyInputFilterObject>;
  cards?: Maybe<CardInputFilterObject>;
};

export type PolicyChainListType = {
  __typename?: 'PolicyChainListType';
  /** PolicyChain list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PolicyChainType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PolicyChainListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PolicyChainMutation = {
  __typename?: 'PolicyChainMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  policychain?: Maybe<PolicyChainType>;
};

/**
 * Filter:
 * --------
 * - corporations: Shortcut to `corporate_policy_chain__corporations`
 * - corporate: Shortcut to `corporate_policy_chain__isnull`
 *
 * Example:
 * --------
 * mutation {
 *   policyChainCreate(newPolicyChain: {holderIds: [1], productId: 1}) {
 *     ok
 *     policyChain {
 *       id
 *       holderIds
 *       productId
 *     }
 *   }
 * }
 */
export type PolicyChainType = {
  __typename?: 'PolicyChainType';
  /** Card list */
  cards?: Maybe<Array<Maybe<CardType>>>;
  /** corporate policy chain */
  corporatePolicyChain?: Maybe<CorporatePolicyChainType>;
  /** PolicyHolder list */
  holders?: Maybe<Array<Maybe<PolicyHolderType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  policies?: Maybe<Array<Maybe<PolicyType>>>;
  /** product */
  product?: Maybe<ProductType>;
  corporate?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyType>;
};

/**
 * Filter:
 * --------
 * - corporations: Shortcut to `corporate_policy_chain__corporations`
 * - corporate: Shortcut to `corporate_policy_chain__isnull`
 *
 * Example:
 * --------
 * mutation {
 *   policyChainCreate(newPolicyChain: {holderIds: [1], productId: 1}) {
 *     ok
 *     policyChain {
 *       id
 *       holderIds
 *       productId
 *     }
 *   }
 * }
 */
export type PolicyChainTypeCardsArgs = {
  actions_Isnull?: Maybe<Scalars['Boolean']>;
  actions?: Maybe<CardActionInputFilterObject>;
  aggregatedbeneficiarycard_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedbeneficiarycard?: Maybe<AggregatedBeneficiaryCardInputFilterObject>;
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  proxyId_Isnull?: Maybe<Scalars['Boolean']>;
  proxyId?: Maybe<Scalars['String']>;
  proxyId_Iexact?: Maybe<Scalars['String']>;
  proxyId_Contains?: Maybe<Scalars['String']>;
  proxyId_Icontains?: Maybe<Scalars['String']>;
  proxyId_Startswith?: Maybe<Scalars['String']>;
  proxyId_Istartswith?: Maybe<Scalars['String']>;
  proxyId_Endswith?: Maybe<Scalars['String']>;
  proxyId_Iendswith?: Maybe<Scalars['String']>;
  proxyId_In?: Maybe<Scalars['String']>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  nameOnCard_Isnull?: Maybe<Scalars['Boolean']>;
  nameOnCard?: Maybe<Scalars['String']>;
  nameOnCard_Iexact?: Maybe<Scalars['String']>;
  nameOnCard_Contains?: Maybe<Scalars['String']>;
  nameOnCard_Icontains?: Maybe<Scalars['String']>;
  nameOnCard_Startswith?: Maybe<Scalars['String']>;
  nameOnCard_Istartswith?: Maybe<Scalars['String']>;
  nameOnCard_Endswith?: Maybe<Scalars['String']>;
  nameOnCard_Iendswith?: Maybe<Scalars['String']>;
  nameOnCard_In?: Maybe<Scalars['String']>;
  lastDigits_Isnull?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastDigits_Iexact?: Maybe<Scalars['String']>;
  lastDigits_Contains?: Maybe<Scalars['String']>;
  lastDigits_Icontains?: Maybe<Scalars['String']>;
  lastDigits_Startswith?: Maybe<Scalars['String']>;
  lastDigits_Istartswith?: Maybe<Scalars['String']>;
  lastDigits_Endswith?: Maybe<Scalars['String']>;
  lastDigits_Iendswith?: Maybe<Scalars['String']>;
  lastDigits_In?: Maybe<Scalars['String']>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['String']>;
  expiration_Iexact?: Maybe<Scalars['String']>;
  expiration_Contains?: Maybe<Scalars['String']>;
  expiration_Icontains?: Maybe<Scalars['String']>;
  expiration_Startswith?: Maybe<Scalars['String']>;
  expiration_Istartswith?: Maybe<Scalars['String']>;
  expiration_Endswith?: Maybe<Scalars['String']>;
  expiration_Iendswith?: Maybe<Scalars['String']>;
  expiration_In?: Maybe<Scalars['String']>;
  policyChain_Isnull?: Maybe<Scalars['Boolean']>;
  policyChain?: Maybe<PolicyChainInputFilterObject>;
  isFrozen_Isnull?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isBlocked_Isnull?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeactivated_Isnull?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isCancelled_Isnull?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isIssued_Isnull?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered_Isnull?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  defaultClaim_Isnull?: Maybe<Scalars['Boolean']>;
  defaultClaim?: Maybe<ClaimInputFilterObject>;
  currentBeneficiaryForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentBeneficiaryForPayments?: Maybe<BeneficiaryInputFilterObject>;
  cardType_Isnull?: Maybe<Scalars['Boolean']>;
  cardType?: Maybe<Scalars['String']>;
  cardType_Iexact?: Maybe<Scalars['String']>;
  cardType_Contains?: Maybe<Scalars['String']>;
  cardType_Icontains?: Maybe<Scalars['String']>;
  cardType_Startswith?: Maybe<Scalars['String']>;
  cardType_Istartswith?: Maybe<Scalars['String']>;
  cardType_Endswith?: Maybe<Scalars['String']>;
  cardType_Iendswith?: Maybe<Scalars['String']>;
  cardType_In?: Maybe<Scalars['String']>;
  aggregationProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationProcessedAt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedAt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_In?: Maybe<Scalars['Int']>;
  idForPublicRetrieval_Isnull?: Maybe<Scalars['Boolean']>;
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  sentSmses_Isnull?: Maybe<Scalars['Boolean']>;
  sentSmses?: Maybe<SmsInputFilterObject>;
  policyId?: Maybe<Scalars['String']>;
};

/**
 * Filter:
 * --------
 * - corporations: Shortcut to `corporate_policy_chain__corporations`
 * - corporate: Shortcut to `corporate_policy_chain__isnull`
 *
 * Example:
 * --------
 * mutation {
 *   policyChainCreate(newPolicyChain: {holderIds: [1], productId: 1}) {
 *     ok
 *     policyChain {
 *       id
 *       holderIds
 *       productId
 *     }
 *   }
 * }
 */
export type PolicyChainTypeHoldersArgs = {
  chains_Isnull?: Maybe<Scalars['Boolean']>;
  chains?: Maybe<PolicyChainInputFilterObject>;
  personalCards_Isnull?: Maybe<Scalars['Boolean']>;
  personalCards?: Maybe<PersonalCardInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  customerUuid_Isnull?: Maybe<Scalars['Boolean']>;
  customerUuid?: Maybe<Scalars['UUID']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Scalars['String']>;
  holders?: Maybe<PolicyHolderInputFilterObject>;
  customer?: Maybe<CustomerInputType>;
};

export type PolicyHolderInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** customer uuid */
  customerUuid?: Maybe<Scalars['UUID']>;
  customer?: Maybe<CustomerInputType>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customerUuid_In?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  customer_In?: Maybe<Array<Maybe<CustomerInputType>>>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  holders?: Maybe<PolicyHolderInputFilterObject>;
  chains?: Maybe<PolicyChainInputFilterObject>;
  personalCards?: Maybe<PersonalCardInputFilterObject>;
};

export type PolicyHolderListType = {
  __typename?: 'PolicyHolderListType';
  /** PolicyHolder list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PolicyHolderType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PolicyHolderListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PolicyHolderMutation = {
  __typename?: 'PolicyHolderMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  policyholder?: Maybe<PolicyHolderType>;
};

/**
 * Path hints:
 * ----------
 * - benefit: `policy_holder.chains.policies.product_version.benefits`
 *
 * Extra filters:
 * --------------
 *   `external_id` - External id of the customer
 *   `benefits` - Shortcut to `chains__policies__product_version__benefits`
 *
 * Extra mutation input:
 * --------------------
 *   `customer` - Field for the new customer
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 *
 * Example mutations:
 * -----------------
 *     mutation {
 *       policyHolderCreate(newPolicyHolder: {customer: {externalId: "customer_id:a", firstName: "name",
 *       lastName: "last", address: "address", dateOfBirth: "1970-01-01", email: "nobody@gmail.com"}, chainIds: []}) {
 *         ok
 *         policyHolder {
 *           id
 *           customer {
 *             firstName
 *           }
 *         }
 *       }
 *     }
 *
 *     mutation {
 *       policyHolderCreate(newPolicyHolder: {beneficiaryId: 2, chainIds: []}) {
 *         ok
 *         policyHolder {
 *           id
 *         }
 *       }
 *     }
 */
export type PolicyHolderType = {
  __typename?: 'PolicyHolderType';
  /** beneficiary */
  beneficiary?: Maybe<BeneficiaryType>;
  chains?: Maybe<Array<Maybe<PolicyChainType>>>;
  /** customer uuid */
  customerUuid?: Maybe<Scalars['UUID']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** PersonalCard list */
  personalCards?: Maybe<Array<Maybe<PersonalCardType>>>;
  customer?: Maybe<CustomerType>;
};

/**
 * Path hints:
 * ----------
 * - benefit: `policy_holder.chains.policies.product_version.benefits`
 *
 * Extra filters:
 * --------------
 *   `external_id` - External id of the customer
 *   `benefits` - Shortcut to `chains__policies__product_version__benefits`
 *
 * Extra mutation input:
 * --------------------
 *   `customer` - Field for the new customer
 *
 * Actions:
 * --------
 *   After all mutations a notification is triggered
 *
 * Example query:
 * --------------
 *   Filter by benefit id.
 *
 *     {
 *       policyHolders(chains: {policies: {productVersion: {benefits: {id_In: [1, 2]}}}}) {
 *         results {
 *           id
 *         }
 *       }
 *     }
 *
 * Example mutations:
 * -----------------
 *     mutation {
 *       policyHolderCreate(newPolicyHolder: {customer: {externalId: "customer_id:a", firstName: "name",
 *       lastName: "last", address: "address", dateOfBirth: "1970-01-01", email: "nobody@gmail.com"}, chainIds: []}) {
 *         ok
 *         policyHolder {
 *           id
 *           customer {
 *             firstName
 *           }
 *         }
 *       }
 *     }
 *
 *     mutation {
 *       policyHolderCreate(newPolicyHolder: {beneficiaryId: 2, chainIds: []}) {
 *         ok
 *         policyHolder {
 *           id
 *         }
 *       }
 *     }
 */
export type PolicyHolderTypePersonalCardsArgs = {
  beneficiaryPayments_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryPayments?: Maybe<BeneficiaryPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  proxyId_Isnull?: Maybe<Scalars['Boolean']>;
  proxyId?: Maybe<Scalars['String']>;
  proxyId_Iexact?: Maybe<Scalars['String']>;
  proxyId_Contains?: Maybe<Scalars['String']>;
  proxyId_Icontains?: Maybe<Scalars['String']>;
  proxyId_Startswith?: Maybe<Scalars['String']>;
  proxyId_Istartswith?: Maybe<Scalars['String']>;
  proxyId_Endswith?: Maybe<Scalars['String']>;
  proxyId_Iendswith?: Maybe<Scalars['String']>;
  proxyId_In?: Maybe<Scalars['String']>;
  policyHolder_Isnull?: Maybe<Scalars['Boolean']>;
  policyHolder?: Maybe<PolicyHolderInputFilterObject>;
  default_Isnull?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  active_Isnull?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type PolicyInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** external id */
  externalId_Icontains?: Maybe<Scalars['String']>;
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  /** additional information */
  additionalInformation_Icontains?: Maybe<Scalars['String']>;
  /** expiration */
  expiration?: Maybe<Scalars['CustomDateTime']>;
  /** expiration */
  expiration_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** expiration */
  expiration_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** expiration */
  expiration_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** expiration */
  expiration_Lte?: Maybe<Scalars['CustomDateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  externalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalInformation_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiration_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  isActive_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  chain?: Maybe<PolicyChainInputFilterObject>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  beneficiaries?: Maybe<BeneficiaryInputFilterObject>;
  usages?: Maybe<BenefitUsageInputFilterObject>;
  sessions?: Maybe<MobileAppSessionInputFilterObject>;
};

export type PolicyListType = {
  __typename?: 'PolicyListType';
  /** Policy list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<PolicyType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type PolicyListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type PolicyMutation = {
  __typename?: 'PolicyMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  policy?: Maybe<PolicyType>;
};

/**
 * Path hints:
 * ----------
 *  - product: `policy.product_version.product`
 */
export type PolicyType = {
  __typename?: 'PolicyType';
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  /** Beneficiary list */
  beneficiaries?: Maybe<Array<Maybe<BeneficiaryType>>>;
  /** chain */
  chain?: Maybe<PolicyChainType>;
  /** expiration */
  expiration?: Maybe<Scalars['CustomDateTime']>;
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  /** product version */
  productVersion?: Maybe<ProductVersionType>;
  /** MobileAppSession list */
  sessions?: Maybe<Array<Maybe<MobileAppSessionType>>>;
  /** BenefitUsage list */
  usages?: Maybe<Array<Maybe<BenefitUsageType>>>;
  beneficiaryCount?: Maybe<Scalars['Int']>;
  remainingDays?: Maybe<Scalars['Int']>;
};

/**
 * Path hints:
 * ----------
 *  - product: `policy.product_version.product`
 */
export type PolicyTypeBeneficiariesArgs = {
  dependants_Isnull?: Maybe<Scalars['Boolean']>;
  dependants?: Maybe<BeneficiaryInputFilterObject>;
  policyHolder_Isnull?: Maybe<Scalars['Boolean']>;
  policyHolder?: Maybe<PolicyHolderInputFilterObject>;
  policies_Isnull?: Maybe<Scalars['Boolean']>;
  policies?: Maybe<PolicyInputFilterObject>;
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedClaims_Isnull?: Maybe<Scalars['Boolean']>;
  requestedClaims?: Maybe<ClaimInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  currentCardsForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentCardsForPayments?: Maybe<CardInputFilterObject>;
  cardActions_Isnull?: Maybe<Scalars['Boolean']>;
  cardActions?: Maybe<CardActionInputFilterObject>;
  mobileAppUser_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppUser?: Maybe<MobileAppUserInputFilterObject>;
  paymentRulesOverrides_Isnull?: Maybe<Scalars['Boolean']>;
  paymentRulesOverrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
  unlocks_Isnull?: Maybe<Scalars['Boolean']>;
  unlocks?: Maybe<BeneficiaryDataUnlockInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  memberSince_Isnull?: Maybe<Scalars['Boolean']>;
  memberSince?: Maybe<Scalars['Date']>;
  memberSince_Gt?: Maybe<Scalars['Date']>;
  memberSince_Gte?: Maybe<Scalars['Date']>;
  memberSince_Lt?: Maybe<Scalars['Date']>;
  memberSince_Lte?: Maybe<Scalars['Date']>;
  memberSince_In?: Maybe<Scalars['Date']>;
  customerUuid_Isnull?: Maybe<Scalars['Boolean']>;
  customerUuid?: Maybe<Scalars['UUID']>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  mobileAppAllowed_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppAllowed?: Maybe<Scalars['Boolean']>;
  cardAllowed_Isnull?: Maybe<Scalars['Boolean']>;
  cardAllowed?: Maybe<Scalars['Boolean']>;
  token_Isnull?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  token_Iexact?: Maybe<Scalars['String']>;
  token_Contains?: Maybe<Scalars['String']>;
  token_Icontains?: Maybe<Scalars['String']>;
  token_Startswith?: Maybe<Scalars['String']>;
  token_Istartswith?: Maybe<Scalars['String']>;
  token_Endswith?: Maybe<Scalars['String']>;
  token_Iendswith?: Maybe<Scalars['String']>;
  token_In?: Maybe<Scalars['String']>;
  parents_Isnull?: Maybe<Scalars['Boolean']>;
  parents?: Maybe<BeneficiaryInputFilterObject>;
  beneficiaries?: Maybe<BeneficiaryInputFilterObject>;
  customer?: Maybe<CustomerInputType>;
};

/**
 * Path hints:
 * ----------
 *  - product: `policy.product_version.product`
 */
export type PolicyTypeSessionsArgs = {
  token_Isnull?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  token_Iexact?: Maybe<Scalars['String']>;
  token_Contains?: Maybe<Scalars['String']>;
  token_Icontains?: Maybe<Scalars['String']>;
  token_Startswith?: Maybe<Scalars['String']>;
  token_Istartswith?: Maybe<Scalars['String']>;
  token_Endswith?: Maybe<Scalars['String']>;
  token_Iendswith?: Maybe<Scalars['String']>;
  token_In?: Maybe<Scalars['String']>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<MobileAppUserInputFilterObject>;
  policy_Isnull?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<PolicyInputFilterObject>;
  lastActivity_Isnull?: Maybe<Scalars['Boolean']>;
  lastActivity?: Maybe<Scalars['DateTime']>;
  lastActivity_Gt?: Maybe<Scalars['DateTime']>;
  lastActivity_Gte?: Maybe<Scalars['DateTime']>;
  lastActivity_Lt?: Maybe<Scalars['DateTime']>;
  lastActivity_Lte?: Maybe<Scalars['DateTime']>;
  lastActivity_In?: Maybe<Scalars['DateTime']>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

/**
 * Path hints:
 * ----------
 *  - product: `policy.product_version.product`
 */
export type PolicyTypeUsagesArgs = {
  memberUsages_Isnull?: Maybe<Scalars['Boolean']>;
  memberUsages?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  benefit_Isnull?: Maybe<Scalars['Boolean']>;
  benefit?: Maybe<BenefitInputFilterObject>;
  policy_Isnull?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<PolicyInputFilterObject>;
  CurrentCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentCount?: Maybe<Scalars['Int']>;
  CurrentCount_Gt?: Maybe<Scalars['Int']>;
  CurrentCount_Gte?: Maybe<Scalars['Int']>;
  CurrentCount_Lt?: Maybe<Scalars['Int']>;
  CurrentCount_Lte?: Maybe<Scalars['Int']>;
  CurrentCount_In?: Maybe<Scalars['Int']>;
  CurrentAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmount?: Maybe<Scalars['Float']>;
  CurrentAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentAmount_In?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_In?: Maybe<Scalars['Float']>;
  CurrentPendingCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_In?: Maybe<Scalars['Int']>;
  CurrentPendingAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_In?: Maybe<Scalars['Float']>;
};

export type PostingEntryInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  journalEntry?: Maybe<JournalEntryInputFilterObject>;
  account?: Maybe<AccountInputFilterObject>;
};

/** Pre-auths tested when trying to find a suitable pre-auth for the transaction. */
export type PreAuthMatchingType = {
  __typename?: 'PreAuthMatchingType';
  claimId?: Maybe<Scalars['ID']>;
  success?: Maybe<Scalars['Boolean']>;
  matchingExplanation?: Maybe<Array<Maybe<PaymentPlatformRuleType>>>;
};

export type PreAuthorizationType = {
  __typename?: 'PreAuthorizationType';
  preferredDate?: Maybe<Scalars['AutoParsedDateTime']>;
  symptoms?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  isNewCondition?: Maybe<Scalars['Boolean']>;
  receivedTreatment?: Maybe<Scalars['Boolean']>;
  timeWindowFrom?: Maybe<Scalars['AutoParsedDateTime']>;
  timeWindowTo?: Maybe<Scalars['AutoParsedDateTime']>;
  preferredMerchantId?: Maybe<Scalars['Int']>;
  preferredMerchant?: Maybe<MerchantType>;
  currencyCode?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['Float']>;
};

export type PreAuthorizationTypeInput = {
  preferredMerchant?: Maybe<CreateMerchantInputType>;
  preferredDate?: Maybe<Scalars['AutoParsedDateTime']>;
  symptoms?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  isNewCondition?: Maybe<Scalars['Boolean']>;
  receivedTreatment?: Maybe<Scalars['Boolean']>;
  timeWindowFrom?: Maybe<Scalars['AutoParsedDateTime']>;
  timeWindowTo?: Maybe<Scalars['AutoParsedDateTime']>;
  preferredMerchantId?: Maybe<Scalars['Int']>;
  currencyCode?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['Float']>;
};

export type ProductInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** tag */
  tag?: Maybe<Scalars['String']>;
  /** tag */
  tag_Icontains?: Maybe<Scalars['String']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  description_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tag_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer?: Maybe<InsurerInputFilterObject>;
  currency?: Maybe<CurrencyInputFilterObject>;
  versions?: Maybe<ProductVersionInputFilterObject>;
  chains?: Maybe<PolicyChainInputFilterObject>;
};

export type ProductListType = {
  __typename?: 'ProductListType';
  /** Product list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ProductType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ProductType = {
  __typename?: 'ProductType';
  /** PolicyChain list */
  chains?: Maybe<Array<Maybe<PolicyChainType>>>;
  /** currency */
  currency?: Maybe<CurrencyType>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** insurer */
  insurer?: Maybe<InsurerType>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** tag */
  tag?: Maybe<Scalars['String']>;
  /** ProductVersion list */
  versions?: Maybe<Array<Maybe<ProductVersionType>>>;
  versionIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ProductTypeChainsArgs = {
  policies_Isnull?: Maybe<Scalars['Boolean']>;
  policies?: Maybe<PolicyInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_Isnull?: Maybe<Scalars['Boolean']>;
  product?: Maybe<ProductInputFilterObject>;
  corporatePolicyChain_Isnull?: Maybe<Scalars['Boolean']>;
  corporatePolicyChain?: Maybe<CorporatePolicyChainInputFilterObject>;
  holders_Isnull?: Maybe<Scalars['Boolean']>;
  holders?: Maybe<PolicyHolderInputFilterObject>;
  corporations?: Maybe<CorporationInputFilterObject>;
  corporate?: Maybe<Scalars['Boolean']>;
};

export type ProductTypeVersionsArgs = {
  benefits_Isnull?: Maybe<Scalars['Boolean']>;
  benefits?: Maybe<BenefitInputFilterObject>;
  policies_Isnull?: Maybe<Scalars['Boolean']>;
  policies?: Maybe<PolicyInputFilterObject>;
  ruleSets_Isnull?: Maybe<Scalars['Boolean']>;
  ruleSets?: Maybe<RulesSetInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_Isnull?: Maybe<Scalars['Boolean']>;
  product?: Maybe<ProductInputFilterObject>;
  version_Isnull?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
  version_Iexact?: Maybe<Scalars['String']>;
  version_Contains?: Maybe<Scalars['String']>;
  version_Icontains?: Maybe<Scalars['String']>;
  version_Startswith?: Maybe<Scalars['String']>;
  version_Istartswith?: Maybe<Scalars['String']>;
  version_Endswith?: Maybe<Scalars['String']>;
  version_Iendswith?: Maybe<Scalars['String']>;
  version_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  cardThreshold_Isnull?: Maybe<Scalars['Boolean']>;
  cardThreshold?: Maybe<Scalars['Float']>;
  cardThreshold_Gt?: Maybe<Scalars['Float']>;
  cardThreshold_Gte?: Maybe<Scalars['Float']>;
  cardThreshold_Lt?: Maybe<Scalars['Float']>;
  cardThreshold_Lte?: Maybe<Scalars['Float']>;
  cardThreshold_In?: Maybe<Scalars['Float']>;
  perClaimExcess_Isnull?: Maybe<Scalars['Boolean']>;
  perClaimExcess?: Maybe<Scalars['Float']>;
  perClaimExcess_Gt?: Maybe<Scalars['Float']>;
  perClaimExcess_Gte?: Maybe<Scalars['Float']>;
  perClaimExcess_Lt?: Maybe<Scalars['Float']>;
  perClaimExcess_Lte?: Maybe<Scalars['Float']>;
  perClaimExcess_In?: Maybe<Scalars['Float']>;
  excessesCapped_Isnull?: Maybe<Scalars['Boolean']>;
  excessesCapped?: Maybe<Scalars['Boolean']>;
  blockOnPendingClaim_Isnull?: Maybe<Scalars['Boolean']>;
  blockOnPendingClaim?: Maybe<Scalars['Boolean']>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  updatedAt_Isnull?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gte?: Maybe<Scalars['DateTime']>;
  updatedAt_Lt?: Maybe<Scalars['DateTime']>;
  updatedAt_Lte?: Maybe<Scalars['DateTime']>;
  updatedAt_In?: Maybe<Scalars['DateTime']>;
  sendBreakdownEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sendBreakdownEmails?: Maybe<Scalars['Boolean']>;
};

export type ProductVersionInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** version */
  version?: Maybe<Scalars['String']>;
  /** version */
  version_Icontains?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  description_Icontains?: Maybe<Scalars['String']>;
  /** If set to null, no transaction without pre-auths will be authorised. */
  cardThreshold?: Maybe<Scalars['Float']>;
  /** If set to null, no transaction without pre-auths will be authorised. */
  cardThreshold_Gt?: Maybe<Scalars['Float']>;
  /** If set to null, no transaction without pre-auths will be authorised. */
  cardThreshold_Lt?: Maybe<Scalars['Float']>;
  /** If set to null, no transaction without pre-auths will be authorised. */
  cardThreshold_Gte?: Maybe<Scalars['Float']>;
  /** If set to null, no transaction without pre-auths will be authorised. */
  cardThreshold_Lte?: Maybe<Scalars['Float']>;
  /** per claim excess */
  perClaimExcess?: Maybe<Scalars['Float']>;
  /** per claim excess */
  perClaimExcess_Gt?: Maybe<Scalars['Float']>;
  /** per claim excess */
  perClaimExcess_Lt?: Maybe<Scalars['Float']>;
  /** per claim excess */
  perClaimExcess_Gte?: Maybe<Scalars['Float']>;
  /** per claim excess */
  perClaimExcess_Lte?: Maybe<Scalars['Float']>;
  excessesCapped?: Maybe<Scalars['Boolean']>;
  blockOnPendingClaim?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** updated at */
  updatedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** claim authorization rules */
  claimAuthorizationRules?: Maybe<Scalars['JSONString']>;
  sendBreakdownEmails?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  version_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardThreshold_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  perClaimExcess_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  excessesCapped_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  blockOnPendingClaim_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isActive_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  updatedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  claimAuthorizationRules_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  sendBreakdownEmails_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  product?: Maybe<ProductInputFilterObject>;
  benefits?: Maybe<BenefitInputFilterObject>;
  policies?: Maybe<PolicyInputFilterObject>;
  ruleSets?: Maybe<RulesSetInputFilterObject>;
};

export type ProductVersionListType = {
  __typename?: 'ProductVersionListType';
  /** ProductVersion list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ProductVersionType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProductVersionListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ProductVersionType = {
  __typename?: 'ProductVersionType';
  /** Benefit list */
  benefits?: Maybe<Array<Maybe<BenefitType>>>;
  blockOnPendingClaim?: Maybe<Scalars['Boolean']>;
  /** If set to null, no transaction without pre-auths will be authorised. */
  cardThreshold?: Maybe<Scalars['Float']>;
  /** claim authorization rules */
  claimAuthorizationRules?: Maybe<Scalars['JSONString']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  excessesCapped?: Maybe<Scalars['Boolean']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  /** per claim excess */
  perClaimExcess?: Maybe<Scalars['Float']>;
  /** Policy list */
  policies?: Maybe<Array<Maybe<PolicyType>>>;
  /** product */
  product?: Maybe<ProductType>;
  /** RulesSet list */
  ruleSets?: Maybe<Array<Maybe<RulesSetType>>>;
  sendBreakdownEmails?: Maybe<Scalars['Boolean']>;
  /** updated at */
  updatedAt?: Maybe<Scalars['CustomDateTime']>;
  /** version */
  version?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyType>;
};

export type ProductVersionTypeBenefitsArgs = {
  children_Isnull?: Maybe<Scalars['Boolean']>;
  children?: Maybe<BenefitInputFilterObject>;
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BenefitUsageInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVersion_Isnull?: Maybe<Scalars['Boolean']>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<BenefitInputFilterObject>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category?: Maybe<BenefitCategoryInputFilterObject>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  excess_Isnull?: Maybe<Scalars['Boolean']>;
  excess?: Maybe<Scalars['Float']>;
  excess_Gt?: Maybe<Scalars['Float']>;
  excess_Gte?: Maybe<Scalars['Float']>;
  excess_Lt?: Maybe<Scalars['Float']>;
  excess_Lte?: Maybe<Scalars['Float']>;
  excess_In?: Maybe<Scalars['Float']>;
  limitCount_Isnull?: Maybe<Scalars['Boolean']>;
  limitCount?: Maybe<Scalars['Int']>;
  limitCount_Gt?: Maybe<Scalars['Int']>;
  limitCount_Gte?: Maybe<Scalars['Int']>;
  limitCount_Lt?: Maybe<Scalars['Int']>;
  limitCount_Lte?: Maybe<Scalars['Int']>;
  limitCount_In?: Maybe<Scalars['Int']>;
  limitAmount_Isnull?: Maybe<Scalars['Boolean']>;
  limitAmount?: Maybe<Scalars['Float']>;
  limitAmount_Gt?: Maybe<Scalars['Float']>;
  limitAmount_Gte?: Maybe<Scalars['Float']>;
  limitAmount_Lt?: Maybe<Scalars['Float']>;
  limitAmount_Lte?: Maybe<Scalars['Float']>;
  limitAmount_In?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryLimitCount?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Gt?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Gte?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Lt?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Lte?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_In?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryLimitAmount?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Gt?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Gte?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Lt?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Lte?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_In?: Maybe<Scalars['Float']>;
  renewalAtYearsEnd_Isnull?: Maybe<Scalars['Boolean']>;
  renewalAtYearsEnd?: Maybe<Scalars['Boolean']>;
  coPayment_Isnull?: Maybe<Scalars['Boolean']>;
  coPayment?: Maybe<Scalars['Int']>;
  coPayment_Gt?: Maybe<Scalars['Int']>;
  coPayment_Gte?: Maybe<Scalars['Int']>;
  coPayment_Lt?: Maybe<Scalars['Int']>;
  coPayment_Lte?: Maybe<Scalars['Int']>;
  coPayment_In?: Maybe<Scalars['Int']>;
  coPaymentMax_Isnull?: Maybe<Scalars['Boolean']>;
  coPaymentMax?: Maybe<Scalars['Float']>;
  coPaymentMax_Gt?: Maybe<Scalars['Float']>;
  coPaymentMax_Gte?: Maybe<Scalars['Float']>;
  coPaymentMax_Lt?: Maybe<Scalars['Float']>;
  coPaymentMax_Lte?: Maybe<Scalars['Float']>;
  coPaymentMax_In?: Maybe<Scalars['Float']>;
  minimalDurationDays_Isnull?: Maybe<Scalars['Boolean']>;
  minimalDurationDays?: Maybe<Scalars['Int']>;
  minimalDurationDays_Gt?: Maybe<Scalars['Int']>;
  minimalDurationDays_Gte?: Maybe<Scalars['Int']>;
  minimalDurationDays_Lt?: Maybe<Scalars['Int']>;
  minimalDurationDays_Lte?: Maybe<Scalars['Int']>;
  minimalDurationDays_In?: Maybe<Scalars['Int']>;
  claimId?: Maybe<Scalars['String']>;
};

export type ProductVersionTypePoliciesArgs = {
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BenefitUsageInputFilterObject>;
  sessions_Isnull?: Maybe<Scalars['Boolean']>;
  sessions?: Maybe<MobileAppSessionInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Scalars['String']>;
  chain_Isnull?: Maybe<Scalars['Boolean']>;
  chain?: Maybe<PolicyChainInputFilterObject>;
  productVersion_Isnull?: Maybe<Scalars['Boolean']>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['DateTime']>;
  expiration_Gt?: Maybe<Scalars['DateTime']>;
  expiration_Gte?: Maybe<Scalars['DateTime']>;
  expiration_Lt?: Maybe<Scalars['DateTime']>;
  expiration_Lte?: Maybe<Scalars['DateTime']>;
  expiration_In?: Maybe<Scalars['DateTime']>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  beneficiaries_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaries?: Maybe<BeneficiaryInputFilterObject>;
  externalId_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductVersionTypeRuleSetsArgs = {
  rules_Isnull?: Maybe<Scalars['Boolean']>;
  rules?: Maybe<RuleInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVersion_Isnull?: Maybe<Scalars['Boolean']>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  active_Isnull?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type ProviderEmployeeInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  provider?: Maybe<ProviderInputFilterObject>;
  user?: Maybe<UserInputFilterObject>;
};

export type ProviderEmployeeListType = {
  __typename?: 'ProviderEmployeeListType';
  /** ProviderEmployee list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ProviderEmployeeType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProviderEmployeeListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ProviderEmployeeMutation = {
  __typename?: 'ProviderEmployeeMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  provideremployee?: Maybe<ProviderEmployeeType>;
};

export type ProviderEmployeeType = {
  __typename?: 'ProviderEmployeeType';
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** provider */
  provider?: Maybe<ProviderType>;
  /** user */
  user?: Maybe<UserType>;
};

export type ProviderInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** email */
  email_Icontains?: Maybe<Scalars['String']>;
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** phone number */
  phoneNumber_Icontains?: Maybe<Scalars['String']>;
  receiveNotifications?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  email_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  receiveNotifications_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  merchant?: Maybe<MerchantInputFilterObject>;
  claimicdcode?: Maybe<ClaimIcdCodeInputFilterObject>;
  providerEmployees?: Maybe<ProviderEmployeeInputFilterObject>;
  unlocks?: Maybe<BeneficiaryDataUnlockInputFilterObject>;
};

export type ProviderListType = {
  __typename?: 'ProviderListType';
  /** Provider list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ProviderType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProviderListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ProviderMutation = {
  __typename?: 'ProviderMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  provider?: Maybe<ProviderType>;
};

/** Providers are Merchants that can access their own Portal to view claims */
export type ProviderType = {
  __typename?: 'ProviderType';
  /** ClaimIcdCode list */
  claimicdcode?: Maybe<Array<Maybe<ClaimIcdCodeType>>>;
  /** email */
  email?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** merchant */
  merchant?: Maybe<MerchantType>;
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** ProviderEmployee list */
  providerEmployees?: Maybe<Array<Maybe<ProviderEmployeeType>>>;
  receiveNotifications?: Maybe<Scalars['Boolean']>;
  /** BeneficiaryDataUnlock list */
  unlocks?: Maybe<Array<Maybe<BeneficiaryDataUnlockType>>>;
};

/** Providers are Merchants that can access their own Portal to view claims */
export type ProviderTypeClaimicdcodeArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  icdCode_Isnull?: Maybe<Scalars['Boolean']>;
  icdCode?: Maybe<IcdCodeInputFilterObject>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  default_Isnull?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
};

/** Providers are Merchants that can access their own Portal to view claims */
export type ProviderTypeProviderEmployeesArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
};

/** Providers are Merchants that can access their own Portal to view claims */
export type ProviderTypeUnlocksArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  usedAt_Isnull?: Maybe<Scalars['Boolean']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  usedAt_Gt?: Maybe<Scalars['DateTime']>;
  usedAt_Gte?: Maybe<Scalars['DateTime']>;
  usedAt_Lt?: Maybe<Scalars['DateTime']>;
  usedAt_Lte?: Maybe<Scalars['DateTime']>;
  usedAt_In?: Maybe<Scalars['DateTime']>;
  isEnabled_Isnull?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  validUntil_Isnull?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_Gt?: Maybe<Scalars['DateTime']>;
  validUntil_Gte?: Maybe<Scalars['DateTime']>;
  validUntil_Lt?: Maybe<Scalars['DateTime']>;
  validUntil_Lte?: Maybe<Scalars['DateTime']>;
  validUntil_In?: Maybe<Scalars['DateTime']>;
};

export type Query = {
  __typename?: 'Query';
  /** Object type for <class 'aggregate.models.AggregatedPreauth'> */
  aggregatedPreauth?: Maybe<AggregatedPreauthType>;
  /** List type for <class 'aggregate.models.AggregatedPreauth'> */
  aggregatedPreauths?: Maybe<AggregatedPreauthListType>;
  /** Object type for <class 'aggregate.models.AggregatedBeneficiaryCard'> */
  aggregatedBeneficiaryCard?: Maybe<AggregatedBeneficiaryCardType>;
  /** List type for <class 'aggregate.models.AggregatedBeneficiaryCard'> */
  aggregatedBeneficiaryCards?: Maybe<AggregatedBeneficiaryCardListType>;
  /** Object type for <class 'contrib.models.Country'> */
  country?: Maybe<CountryType>;
  /** List type for <class 'contrib.models.Country'> */
  countries?: Maybe<CountryListType>;
  /** Object type for <class 'contrib.models.IcdCodeSet'> */
  icdCodeSet?: Maybe<IcdCodeSetType>;
  /** List type for <class 'contrib.models.IcdCodeSet'> */
  icdCodeSets?: Maybe<IcdCodeSetListType>;
  /** Object type for <class 'contrib.models.CptCodeSet'> */
  cptCodeSet?: Maybe<CptCodeSetType>;
  /** List type for <class 'contrib.models.CptCodeSet'> */
  cptCodeSets?: Maybe<CptCodeSetListType>;
  /** Object type for <class 'contrib.models.IcdCode'> */
  icdCode?: Maybe<IcdCodeType>;
  /** List type for <class 'contrib.models.IcdCode'> */
  icdCodes?: Maybe<IcdCodeListType>;
  /** Object type for <class 'contrib.models.CptCode'> */
  cptCode?: Maybe<CptCodeType>;
  /** List type for <class 'contrib.models.CptCode'> */
  cptCodes?: Maybe<CptCodeListType>;
  /** Object type for <class 'contrib.models.Currency'> */
  currency?: Maybe<CurrencyType>;
  /** List type for <class 'contrib.models.Currency'> */
  currencies?: Maybe<CurrencyListType>;
  marketFxRates?: Maybe<MarketFxRateType>;
  /** Object type for <class 'rules.models.Rule'> */
  rule?: Maybe<RuleType>;
  /** List type for <class 'rules.models.Rule'> */
  rules?: Maybe<RuleListType>;
  /** Object type for <class 'rules.models.RulesSet'> */
  rulesSet?: Maybe<RulesSetType>;
  /** List type for <class 'rules.models.RulesSet'> */
  rulesSets?: Maybe<RulesSetListType>;
  /** Object type for <class 'product.models.BenefitCategory'> */
  benefitCategory?: Maybe<BenefitCategoryType>;
  /** List type for <class 'product.models.BenefitCategory'> */
  benefitCategories?: Maybe<BenefitCategoryListType>;
  /** Object type for <class 'product.models.Benefit'> */
  benefit?: Maybe<BenefitType>;
  /** List type for <class 'product.models.Benefit'> */
  benefits?: Maybe<BenefitListType>;
  /** Object type for <class 'product.models.Product'> */
  product?: Maybe<ProductType>;
  /** List type for <class 'product.models.Product'> */
  products?: Maybe<ProductListType>;
  /** Object type for <class 'product.models.ProductVersion'> */
  productVersion?: Maybe<ProductVersionType>;
  /** List type for <class 'product.models.ProductVersion'> */
  productVersions?: Maybe<ProductVersionListType>;
  /** Object type for <class 'paymentplatform.models.BeneficiaryPayment'> */
  beneficiaryPayment?: Maybe<BeneficiaryPaymentType>;
  /** List type for <class 'paymentplatform.models.BeneficiaryPayment'> */
  beneficiaryPayments?: Maybe<BeneficiaryPaymentListType>;
  /** Object type for <class 'paymentplatform.models.InsurerPayment'> */
  insurerPayment?: Maybe<InsurerPaymentType>;
  /** List type for <class 'paymentplatform.models.InsurerPayment'> */
  insurerPayments?: Maybe<InsurerPaymentListType>;
  payment?: Maybe<PaymentInterface>;
  payments?: Maybe<Result>;
  /** Object type for <class 'paymentplatform.models.PaymentAuthorization'> */
  paymentAuthorization?: Maybe<PaymentAuthorizationType>;
  /** List type for <class 'paymentplatform.models.PaymentAuthorization'> */
  paymentAuthorizations?: Maybe<PaymentAuthorizationListType>;
  /** Object type for <class 'paymentplatform.models.PaymentAuthorizationAttempt'> */
  paymentAuthorizationAttempt?: Maybe<PaymentAuthorizationAttemptType>;
  /** List type for <class 'paymentplatform.models.PaymentAuthorizationAttempt'> */
  paymentAuthorizationAttempts?: Maybe<PaymentAuthorizationAttemptListType>;
  /** Object type for <class 'paymentplatform.models.PaymentRulesOverride'> */
  paymentRulesOverride?: Maybe<PaymentRulesOverrideType>;
  /** List type for <class 'paymentplatform.models.PaymentRulesOverride'> */
  paymentRulesOverrides?: Maybe<PaymentRulesOverrideListType>;
  /** Object type for <class 'paymentplatform.models.PaymentSettlement'> */
  paymentSettlement?: Maybe<PaymentSettlementType>;
  /** List type for <class 'paymentplatform.models.PaymentSettlement'> */
  paymentSettlements?: Maybe<PaymentSettlementListType>;
  paymentPlatformRules?: Maybe<PaymentRuleListType>;
  /** Object type for <class 'paymentplatform.models.PaymentAuthorizationAttemptReversal'> */
  paymentAuthorizationAttemptReversal?: Maybe<PaymentAuthorizationAttemptReversalType>;
  /** List type for <class 'paymentplatform.models.PaymentAuthorizationAttemptReversal'> */
  paymentAuthorizationAttemptReversals?: Maybe<PaymentAuthorizationAttemptReversalListType>;
  /** Object type for <class 'paymentplatform.models.CardEvent'> */
  cardEvent?: Maybe<CardEventType>;
  /** List type for <class 'paymentplatform.models.CardEvent'> */
  cardEvents?: Maybe<CardEventListType>;
  /** Object type for <class 'mobile_app.models.MobileAppUser'> */
  mobileAppUser?: Maybe<MobileAppUserType>;
  /** List type for <class 'mobile_app.models.MobileAppUser'> */
  mobileAppUsers?: Maybe<MobileAppUserListType>;
  /** Object type for <class 'mobile_app.models.MobileAppSession'> */
  mobileAppSession?: Maybe<MobileAppSessionType>;
  /** List type for <class 'mobile_app.models.MobileAppSession'> */
  mobileAppSessions?: Maybe<MobileAppSessionListType>;
  /** Object type for <class 'mobile_app.models.SessionLocationStamp'> */
  locationStamp?: Maybe<SessionLocationStampType>;
  /** List type for <class 'mobile_app.models.SessionLocationStamp'> */
  locationStamps?: Maybe<SessionLocationStampListType>;
  /** Object type for <class 'provider.models.Provider'> */
  provider?: Maybe<ProviderType>;
  /** List type for <class 'provider.models.Provider'> */
  providers?: Maybe<ProviderListType>;
  /** Object type for <class 'provider.models.ProviderEmployee'> */
  providerEmployee?: Maybe<ProviderEmployeeType>;
  /** List type for <class 'provider.models.ProviderEmployee'> */
  providerEmployees?: Maybe<ProviderEmployeeListType>;
  /** Object type for <class 'provider.models.BeneficiaryDataUnlock'> */
  beneficiaryDataUnlock?: Maybe<BeneficiaryDataUnlockType>;
  /** List type for <class 'provider.models.BeneficiaryDataUnlock'> */
  beneficiaryDataUnlocks?: Maybe<BeneficiaryDataUnlockListType>;
  /** Object type for <class 'merchant.models.MerchantCategory'> */
  merchantCategory?: Maybe<MerchantCategoryType>;
  /** List type for <class 'merchant.models.MerchantCategory'> */
  merchantCategories?: Maybe<MerchantCategoryListType>;
  /** Object type for <class 'merchant.models.Merchant'> */
  merchant?: Maybe<MerchantType>;
  /** List type for <class 'merchant.models.Merchant'> */
  merchants?: Maybe<MerchantListType>;
  /** Object type for <class 'django.contrib.auth.models.Group'> */
  group?: Maybe<GroupType>;
  /** List type for <class 'django.contrib.auth.models.Group'> */
  groups?: Maybe<GroupListType>;
  /** Object type for <class 'django.contrib.auth.models.User'> */
  user?: Maybe<UserType>;
  /** List type for <class 'django.contrib.auth.models.User'> */
  users?: Maybe<UserListType>;
  /** Object type for <class 'insurer.models.Insurer'> */
  insurer?: Maybe<InsurerType>;
  /** List type for <class 'insurer.models.Insurer'> */
  insurers?: Maybe<InsurerListType>;
  /** Object type for <class 'insurer.models.InsurerEmployee'> */
  insurerEmployee?: Maybe<InsurerEmployeeType>;
  /** List type for <class 'insurer.models.InsurerEmployee'> */
  insurerEmployees?: Maybe<InsurerEmployeeListType>;
  /** Object type for <class 'insurer.models.FundingAccount'> */
  fundingAccount?: Maybe<FundingAccountType>;
  /** List type for <class 'insurer.models.FundingAccount'> */
  fundingAccounts?: Maybe<FundingAccountListType>;
  /** Object type for <class 'insurer.models.PhysicalCardAccountMeta'> */
  physicalCardMeta?: Maybe<PhysicalCardAccountMetaType>;
  /** List type for <class 'insurer.models.PhysicalCardAccountMeta'> */
  physicalCardMetas?: Maybe<PhysicalCardAccountMetaListType>;
  /** Object type for <class 'insurer.models.VirtualCardAccountMeta'> */
  virtualCardMeta?: Maybe<VirtualCardMetaAccountType>;
  /** List type for <class 'insurer.models.VirtualCardAccountMeta'> */
  virtualCardMetas?: Maybe<VirtualCardAccountMetaListType>;
  /** Object type for <class 'audit_trail.models.Notification'> */
  notification?: Maybe<NotificationType>;
  /** List type for <class 'audit_trail.models.Notification'> */
  notifications?: Maybe<NotificationListType>;
  /** Object type for <class 'audit_trail.models.MobileNotification'> */
  mobileNotification?: Maybe<MobileNotificationType>;
  /** List type for <class 'audit_trail.models.MobileNotification'> */
  mobileNotifications?: Maybe<MobileNotificationListType>;
  /** Object type for <class 'card.models.CardAction'> */
  cardAction?: Maybe<CardActionType>;
  /** List type for <class 'card.models.CardAction'> */
  cardActions?: Maybe<CardActionListType>;
  /** Object type for <class 'card.models.Card'> */
  card?: Maybe<CardType>;
  /** List type for <class 'card.models.Card'> */
  cards?: Maybe<CardListType>;
  /** Object type for <class 'card.models.PersonalCard'> */
  personalCard?: Maybe<PersonalCardType>;
  /** List type for <class 'card.models.PersonalCard'> */
  personalCards?: Maybe<PersonalCardListType>;
  /** Object type for <class 'customer.models.Address'> */
  address?: Maybe<CorporationAddressType>;
  /** List type for <class 'customer.models.Address'> */
  addresses?: Maybe<AddressListType>;
  /** Object type for <class 'policy.models.BeneficiaryBenefitUsage'> */
  beneficiaryBenefitUsage?: Maybe<BeneficiaryBenefitUsageType>;
  /** List type for <class 'policy.models.BeneficiaryBenefitUsage'> */
  beneficiaryBenefitUsages?: Maybe<BeneficiaryBenefitUsageListType>;
  /** Object type for <class 'policy.models.Policy'> */
  policy?: Maybe<PolicyType>;
  /** List type for <class 'policy.models.Policy'> */
  policies?: Maybe<PolicyListType>;
  /** Object type for <class 'policy.models.Beneficiary'> */
  beneficiary?: Maybe<BeneficiaryType>;
  /** List type for <class 'policy.models.Beneficiary'> */
  beneficiaries?: Maybe<BeneficiaryListType>;
  /** Object type for <class 'customer.models.Corporation'> */
  corporation?: Maybe<CorporationType>;
  /** List type for <class 'customer.models.Corporation'> */
  corporations?: Maybe<CorporationListType>;
  /** Object type for <class 'policy.models.PolicyHolder'> */
  policyHolder?: Maybe<PolicyHolderType>;
  /** List type for <class 'policy.models.PolicyHolder'> */
  policyHolders?: Maybe<PolicyHolderListType>;
  /** Object type for <class 'policy.models.BenefitUsage'> */
  benefitUsage?: Maybe<BenefitUsageType>;
  /** List type for <class 'policy.models.BenefitUsage'> */
  benefitUsages?: Maybe<BenefitUsageListType>;
  /** Object type for <class 'policy.models.PolicyChain'> */
  policyChain?: Maybe<PolicyChainType>;
  /** List type for <class 'policy.models.PolicyChain'> */
  policyChains?: Maybe<PolicyChainListType>;
  /** Object type for <class 'policy.models.CorporatePolicyChain'> */
  corporatePolicyChain?: Maybe<CorporatePolicyChainType>;
  /** List type for <class 'policy.models.CorporatePolicyChain'> */
  corporatePolicyChains?: Maybe<CorporatePolicyChainListType>;
  /** Object type for <class 'claim.models.Claim'> */
  claim?: Maybe<ClaimType>;
  /** List type for <class 'claim.models.Claim'> */
  claims?: Maybe<ClaimListType>;
  /** Object type for <class 'claim.models.ClaimIcdCode'> */
  claimIcdCode?: Maybe<ClaimIcdCodeType>;
  /** List type for <class 'claim.models.ClaimIcdCode'> */
  claimIcdCodes?: Maybe<ClaimIcdCodeListType>;
  /** Object type for <class 'claim.models.ClaimCptCode'> */
  claimCptCode?: Maybe<ClaimCptCodeType>;
  /** List type for <class 'claim.models.ClaimCptCode'> */
  claimCptCodes?: Maybe<ClaimCptCodeListType>;
  /** Object type for <class 'claim.models.ClaimBreakdown'> */
  claimBreakdown?: Maybe<ClaimBreakdownType>;
  /** List type for <class 'claim.models.ClaimBreakdown'> */
  claimBreakdowns?: Maybe<ClaimBreakdownListType>;
  /** Object type for <class 'claim.models.Receipt'> */
  receipt?: Maybe<ReceiptType>;
  /** List type for <class 'claim.models.Receipt'> */
  receipts?: Maybe<ReceiptListType>;
  /** Object type for <class 'claim.models.SupportingDocument'> */
  supportingDocument?: Maybe<SupportingDocumentType>;
  /** List type for <class 'claim.models.SupportingDocument'> */
  supportingDocuments?: Maybe<SupportingDocumentListType>;
  /** Object type for <class 'claim.models.ReceiptItem'> */
  receiptItem?: Maybe<ReceiptItemType>;
  /** List type for <class 'claim.models.ReceiptItem'> */
  receiptItems?: Maybe<ReceiptItemListType>;
  /** Object type for <class 'claim.models.CodesExclusion'> */
  codesExclusion?: Maybe<CodesExclusionType>;
  /** List type for <class 'claim.models.CodesExclusion'> */
  codesExclusions?: Maybe<CodesExclusionListType>;
  _debug?: Maybe<DjangoDebug>;
};

export type QueryAggregatedPreauthArgs = {
  id: Scalars['ID'];
};

export type QueryAggregatedPreauthsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  cardProxyId_Isnull?: Maybe<Scalars['Boolean']>;
  cardProxyId?: Maybe<Scalars['String']>;
  cardProxyId_Iexact?: Maybe<Scalars['String']>;
  cardProxyId_Contains?: Maybe<Scalars['String']>;
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  cardProxyId_Startswith?: Maybe<Scalars['String']>;
  cardProxyId_Istartswith?: Maybe<Scalars['String']>;
  cardProxyId_Endswith?: Maybe<Scalars['String']>;
  cardProxyId_Iendswith?: Maybe<Scalars['String']>;
  cardProxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_Isnull?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_Iexact?: Maybe<Scalars['String']>;
  currencyCode_Contains?: Maybe<Scalars['String']>;
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  currencyCode_Startswith?: Maybe<Scalars['String']>;
  currencyCode_Istartswith?: Maybe<Scalars['String']>;
  currencyCode_Endswith?: Maybe<Scalars['String']>;
  currencyCode_Iendswith?: Maybe<Scalars['String']>;
  currencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  optionalCurrencyCode_Isnull?: Maybe<Scalars['Boolean']>;
  optionalCurrencyCode?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Iexact?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Contains?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Icontains?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Startswith?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Istartswith?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Endswith?: Maybe<Scalars['String']>;
  optionalCurrencyCode_Iendswith?: Maybe<Scalars['String']>;
  optionalCurrencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  countryCode_Isnull?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_Iexact?: Maybe<Scalars['String']>;
  countryCode_Contains?: Maybe<Scalars['String']>;
  countryCode_Icontains?: Maybe<Scalars['String']>;
  countryCode_Startswith?: Maybe<Scalars['String']>;
  countryCode_Istartswith?: Maybe<Scalars['String']>;
  countryCode_Endswith?: Maybe<Scalars['String']>;
  countryCode_Iendswith?: Maybe<Scalars['String']>;
  countryCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantCategoryCode_Isnull?: Maybe<Scalars['Boolean']>;
  merchantCategoryCode?: Maybe<Scalars['String']>;
  merchantCategoryCode_Iexact?: Maybe<Scalars['String']>;
  merchantCategoryCode_Contains?: Maybe<Scalars['String']>;
  merchantCategoryCode_Icontains?: Maybe<Scalars['String']>;
  merchantCategoryCode_Startswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Istartswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Endswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Iendswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantName_Isnull?: Maybe<Scalars['Boolean']>;
  merchantName?: Maybe<Scalars['String']>;
  merchantName_Iexact?: Maybe<Scalars['String']>;
  merchantName_Contains?: Maybe<Scalars['String']>;
  merchantName_Icontains?: Maybe<Scalars['String']>;
  merchantName_Startswith?: Maybe<Scalars['String']>;
  merchantName_Istartswith?: Maybe<Scalars['String']>;
  merchantName_Endswith?: Maybe<Scalars['String']>;
  merchantName_Iendswith?: Maybe<Scalars['String']>;
  merchantName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantId_Isnull?: Maybe<Scalars['Boolean']>;
  merchantId?: Maybe<Scalars['String']>;
  merchantId_Iexact?: Maybe<Scalars['String']>;
  merchantId_Contains?: Maybe<Scalars['String']>;
  merchantId_Icontains?: Maybe<Scalars['String']>;
  merchantId_Startswith?: Maybe<Scalars['String']>;
  merchantId_Istartswith?: Maybe<Scalars['String']>;
  merchantId_Endswith?: Maybe<Scalars['String']>;
  merchantId_Iendswith?: Maybe<Scalars['String']>;
  merchantId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantAddress_Isnull?: Maybe<Scalars['Boolean']>;
  merchantAddress?: Maybe<Scalars['String']>;
  merchantGpsLatitude_Isnull?: Maybe<Scalars['Boolean']>;
  merchantGpsLatitude?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_Gt?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_Gte?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_Lt?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_Lte?: Maybe<Scalars['Float']>;
  merchantGpsLatitude_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantGpsLongitude_Isnull?: Maybe<Scalars['Boolean']>;
  merchantGpsLongitude?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_Gt?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_Gte?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_Lt?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_Lte?: Maybe<Scalars['Float']>;
  merchantGpsLongitude_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeWindowFrom_Isnull?: Maybe<Scalars['Boolean']>;
  timeWindowFrom?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_Gt?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_Gte?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_Lt?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_Lte?: Maybe<Scalars['DateTime']>;
  timeWindowFrom_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeWindowTo_Isnull?: Maybe<Scalars['Boolean']>;
  timeWindowTo?: Maybe<Scalars['DateTime']>;
  timeWindowTo_Gt?: Maybe<Scalars['DateTime']>;
  timeWindowTo_Gte?: Maybe<Scalars['DateTime']>;
  timeWindowTo_Lt?: Maybe<Scalars['DateTime']>;
  timeWindowTo_Lte?: Maybe<Scalars['DateTime']>;
  timeWindowTo_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_Isnull?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gte?: Maybe<Scalars['DateTime']>;
  updatedAt_Lt?: Maybe<Scalars['DateTime']>;
  updatedAt_Lte?: Maybe<Scalars['DateTime']>;
  updatedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryAggregatedBeneficiaryCardArgs = {
  id: Scalars['ID'];
};

export type QueryAggregatedBeneficiaryCardsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProxyId_Isnull?: Maybe<Scalars['Boolean']>;
  cardProxyId?: Maybe<CardInputFilterObject>;
  currencyCode_Isnull?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_Iexact?: Maybe<Scalars['String']>;
  currencyCode_Contains?: Maybe<Scalars['String']>;
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  currencyCode_Startswith?: Maybe<Scalars['String']>;
  currencyCode_Istartswith?: Maybe<Scalars['String']>;
  currencyCode_Endswith?: Maybe<Scalars['String']>;
  currencyCode_Iendswith?: Maybe<Scalars['String']>;
  currencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiaryId_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryId?: Maybe<Scalars['Int']>;
  beneficiaryId_Gt?: Maybe<Scalars['Int']>;
  beneficiaryId_Gte?: Maybe<Scalars['Int']>;
  beneficiaryId_Lt?: Maybe<Scalars['Int']>;
  beneficiaryId_Lte?: Maybe<Scalars['Int']>;
  beneficiaryId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardThreshold_Isnull?: Maybe<Scalars['Boolean']>;
  cardThreshold?: Maybe<Scalars['Float']>;
  cardThreshold_Gt?: Maybe<Scalars['Float']>;
  cardThreshold_Gte?: Maybe<Scalars['Float']>;
  cardThreshold_Lt?: Maybe<Scalars['Float']>;
  cardThreshold_Lte?: Maybe<Scalars['Float']>;
  cardThreshold_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  numberOfDeclinedClaimsReached_Isnull?: Maybe<Scalars['Boolean']>;
  numberOfDeclinedClaimsReached?: Maybe<Scalars['Boolean']>;
  numberOfClaimsWithinSpecifiedTimeWindowReached_Isnull?: Maybe<Scalars['Boolean']>;
  numberOfClaimsWithinSpecifiedTimeWindowReached?: Maybe<Scalars['Boolean']>;
  totalNumberOfClaimsReached_Isnull?: Maybe<Scalars['Boolean']>;
  totalNumberOfClaimsReached?: Maybe<Scalars['Boolean']>;
  velocityControlViolated_Isnull?: Maybe<Scalars['Boolean']>;
  velocityControlViolated?: Maybe<Scalars['Boolean']>;
  frequencyControlViolated_Isnull?: Maybe<Scalars['Boolean']>;
  frequencyControlViolated?: Maybe<Scalars['Boolean']>;
  pendingClaimToBlock_Isnull?: Maybe<Scalars['Boolean']>;
  pendingClaimToBlock?: Maybe<Scalars['Boolean']>;
  cardReady_Isnull?: Maybe<Scalars['Boolean']>;
  cardReady?: Maybe<Scalars['Boolean']>;
  fundingAccountId_Isnull?: Maybe<Scalars['Boolean']>;
  fundingAccountId?: Maybe<Scalars['Int']>;
  fundingAccountId_Gt?: Maybe<Scalars['Int']>;
  fundingAccountId_Gte?: Maybe<Scalars['Int']>;
  fundingAccountId_Lt?: Maybe<Scalars['Int']>;
  fundingAccountId_Lte?: Maybe<Scalars['Int']>;
  fundingAccountId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  gpsLatitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLatitude?: Maybe<Scalars['Float']>;
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  gpsLatitude_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  gpsLongitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLongitude?: Maybe<Scalars['Float']>;
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  gpsLongitude_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_Isnull?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gte?: Maybe<Scalars['DateTime']>;
  updatedAt_Lt?: Maybe<Scalars['DateTime']>;
  updatedAt_Lte?: Maybe<Scalars['DateTime']>;
  updatedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  locked_Isnull?: Maybe<Scalars['Boolean']>;
  locked?: Maybe<Scalars['DateTime']>;
  locked_Gt?: Maybe<Scalars['DateTime']>;
  locked_Gte?: Maybe<Scalars['DateTime']>;
  locked_Lt?: Maybe<Scalars['DateTime']>;
  locked_Lte?: Maybe<Scalars['DateTime']>;
  locked_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryCountryArgs = {
  id: Scalars['ID'];
};

export type QueryCountriesArgs = {
  insurers_Isnull?: Maybe<Scalars['Boolean']>;
  insurers?: Maybe<InsurerInputFilterObject>;
  merchants_Isnull?: Maybe<Scalars['Boolean']>;
  merchants?: Maybe<MerchantInputFilterObject>;
  address_Isnull?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryIcdCodeSetArgs = {
  id: Scalars['ID'];
};

export type QueryIcdCodeSetsArgs = {
  icdcode_Isnull?: Maybe<Scalars['Boolean']>;
  icdcode?: Maybe<IcdCodeInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryCptCodeSetArgs = {
  id: Scalars['ID'];
};

export type QueryCptCodeSetsArgs = {
  cptcode_Isnull?: Maybe<Scalars['Boolean']>;
  cptcode?: Maybe<CptCodeInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryIcdCodeArgs = {
  id: Scalars['ID'];
};

export type QueryIcdCodesArgs = {
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimicdcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  receiptItems_Isnull?: Maybe<Scalars['Boolean']>;
  receiptItems?: Maybe<ReceiptItemInputFilterObject>;
  exclusion_Isnull?: Maybe<Scalars['Boolean']>;
  exclusion?: Maybe<CodesExclusionInputFilterObject>;
  icdcode_Isnull?: Maybe<Scalars['Boolean']>;
  icdcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<IcdCodeInputFilterObject>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  codeSet_Isnull?: Maybe<Scalars['Boolean']>;
  codeSet?: Maybe<IcdCodeSetInputFilterObject>;
  code_Startswith_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameOrCode_Icontains?: Maybe<Scalars['String']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
};

export type QueryCptCodeArgs = {
  id: Scalars['ID'];
};

export type QueryCptCodesArgs = {
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimcptcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimcptcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  exclusion_Isnull?: Maybe<Scalars['Boolean']>;
  exclusion?: Maybe<CodesExclusionInputFilterObject>;
  cptcode_Isnull?: Maybe<Scalars['Boolean']>;
  cptcode?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<CptCodeInputFilterObject>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  codeSet_Isnull?: Maybe<Scalars['Boolean']>;
  codeSet?: Maybe<CptCodeSetInputFilterObject>;
  code_Startswith_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  nameOrCode_Icontains?: Maybe<Scalars['String']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
};

export type QueryCurrencyArgs = {
  id: Scalars['ID'];
};

export type QueryCurrenciesArgs = {
  fundingAccounts_Isnull?: Maybe<Scalars['Boolean']>;
  fundingAccounts?: Maybe<FundingAccountInputFilterObject>;
  products_Isnull?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductInputFilterObject>;
  receipts_Isnull?: Maybe<Scalars['Boolean']>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  journalEntries_Isnull?: Maybe<Scalars['Boolean']>;
  journalEntries?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  symbolBefore_Isnull?: Maybe<Scalars['Boolean']>;
  symbolBefore?: Maybe<Scalars['String']>;
  symbolBefore_Iexact?: Maybe<Scalars['String']>;
  symbolBefore_Contains?: Maybe<Scalars['String']>;
  symbolBefore_Icontains?: Maybe<Scalars['String']>;
  symbolBefore_Startswith?: Maybe<Scalars['String']>;
  symbolBefore_Istartswith?: Maybe<Scalars['String']>;
  symbolBefore_Endswith?: Maybe<Scalars['String']>;
  symbolBefore_Iendswith?: Maybe<Scalars['String']>;
  symbolBefore_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  symbolAfter_Isnull?: Maybe<Scalars['Boolean']>;
  symbolAfter?: Maybe<Scalars['String']>;
  symbolAfter_Iexact?: Maybe<Scalars['String']>;
  symbolAfter_Contains?: Maybe<Scalars['String']>;
  symbolAfter_Icontains?: Maybe<Scalars['String']>;
  symbolAfter_Startswith?: Maybe<Scalars['String']>;
  symbolAfter_Istartswith?: Maybe<Scalars['String']>;
  symbolAfter_Endswith?: Maybe<Scalars['String']>;
  symbolAfter_Iendswith?: Maybe<Scalars['String']>;
  symbolAfter_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  q?: Maybe<Scalars['String']>;
};

export type QueryMarketFxRatesArgs = {
  codeFrom?: Maybe<Scalars['String']>;
  codeTo?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['AutoParsedDateTime']>;
};

export type QueryRuleArgs = {
  id: Scalars['ID'];
};

export type QueryRulesArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ruleSet_Isnull?: Maybe<Scalars['Boolean']>;
  ruleSet?: Maybe<RulesSetInputFilterObject>;
  ruleType_Isnull?: Maybe<Scalars['Boolean']>;
  ruleType?: Maybe<Scalars['String']>;
  ruleType_Iexact?: Maybe<Scalars['String']>;
  ruleType_Contains?: Maybe<Scalars['String']>;
  ruleType_Icontains?: Maybe<Scalars['String']>;
  ruleType_Startswith?: Maybe<Scalars['String']>;
  ruleType_Istartswith?: Maybe<Scalars['String']>;
  ruleType_Endswith?: Maybe<Scalars['String']>;
  ruleType_Iendswith?: Maybe<Scalars['String']>;
  ruleType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type QueryRulesSetArgs = {
  id: Scalars['ID'];
};

export type QueryRulesSetsArgs = {
  rules_Isnull?: Maybe<Scalars['Boolean']>;
  rules?: Maybe<RuleInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVersion_Isnull?: Maybe<Scalars['Boolean']>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  active_Isnull?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type QueryBenefitCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryBenefitCategoriesArgs = {
  benefits_Isnull?: Maybe<Scalars['Boolean']>;
  benefits?: Maybe<BenefitInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  icon_Isnull?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  icon_Iexact?: Maybe<Scalars['String']>;
  icon_Contains?: Maybe<Scalars['String']>;
  icon_Icontains?: Maybe<Scalars['String']>;
  icon_Startswith?: Maybe<Scalars['String']>;
  icon_Istartswith?: Maybe<Scalars['String']>;
  icon_Endswith?: Maybe<Scalars['String']>;
  icon_Iendswith?: Maybe<Scalars['String']>;
  icon_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  merchantCategories_Isnull?: Maybe<Scalars['Boolean']>;
  merchantCategories?: Maybe<MerchantCategoryInputFilterObject>;
};

export type QueryBenefitArgs = {
  id: Scalars['ID'];
};

export type QueryBenefitsArgs = {
  children_Isnull?: Maybe<Scalars['Boolean']>;
  children?: Maybe<BenefitInputFilterObject>;
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BenefitUsageInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  productVersion_Isnull?: Maybe<Scalars['Boolean']>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<BenefitInputFilterObject>;
  category_Isnull?: Maybe<Scalars['Boolean']>;
  category?: Maybe<BenefitCategoryInputFilterObject>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  excess_Isnull?: Maybe<Scalars['Boolean']>;
  excess?: Maybe<Scalars['Float']>;
  excess_Gt?: Maybe<Scalars['Float']>;
  excess_Gte?: Maybe<Scalars['Float']>;
  excess_Lt?: Maybe<Scalars['Float']>;
  excess_Lte?: Maybe<Scalars['Float']>;
  excess_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  limitCount_Isnull?: Maybe<Scalars['Boolean']>;
  limitCount?: Maybe<Scalars['Int']>;
  limitCount_Gt?: Maybe<Scalars['Int']>;
  limitCount_Gte?: Maybe<Scalars['Int']>;
  limitCount_Lt?: Maybe<Scalars['Int']>;
  limitCount_Lte?: Maybe<Scalars['Int']>;
  limitCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  limitAmount_Isnull?: Maybe<Scalars['Boolean']>;
  limitAmount?: Maybe<Scalars['Float']>;
  limitAmount_Gt?: Maybe<Scalars['Float']>;
  limitAmount_Gte?: Maybe<Scalars['Float']>;
  limitAmount_Lt?: Maybe<Scalars['Float']>;
  limitAmount_Lte?: Maybe<Scalars['Float']>;
  limitAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiaryLimitCount_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryLimitCount?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Gt?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Gte?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Lt?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_Lte?: Maybe<Scalars['Float']>;
  beneficiaryLimitCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiaryLimitAmount_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryLimitAmount?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Gt?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Gte?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Lt?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_Lte?: Maybe<Scalars['Float']>;
  beneficiaryLimitAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  renewalAtYearsEnd_Isnull?: Maybe<Scalars['Boolean']>;
  renewalAtYearsEnd?: Maybe<Scalars['Boolean']>;
  coPayment_Isnull?: Maybe<Scalars['Boolean']>;
  coPayment?: Maybe<Scalars['Int']>;
  coPayment_Gt?: Maybe<Scalars['Int']>;
  coPayment_Gte?: Maybe<Scalars['Int']>;
  coPayment_Lt?: Maybe<Scalars['Int']>;
  coPayment_Lte?: Maybe<Scalars['Int']>;
  coPayment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  coPaymentMax_Isnull?: Maybe<Scalars['Boolean']>;
  coPaymentMax?: Maybe<Scalars['Float']>;
  coPaymentMax_Gt?: Maybe<Scalars['Float']>;
  coPaymentMax_Gte?: Maybe<Scalars['Float']>;
  coPaymentMax_Lt?: Maybe<Scalars['Float']>;
  coPaymentMax_Lte?: Maybe<Scalars['Float']>;
  coPaymentMax_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimalDurationDays_Isnull?: Maybe<Scalars['Boolean']>;
  minimalDurationDays?: Maybe<Scalars['Int']>;
  minimalDurationDays_Gt?: Maybe<Scalars['Int']>;
  minimalDurationDays_Gte?: Maybe<Scalars['Int']>;
  minimalDurationDays_Lt?: Maybe<Scalars['Int']>;
  minimalDurationDays_Lte?: Maybe<Scalars['Int']>;
  minimalDurationDays_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claimId?: Maybe<Scalars['String']>;
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductsArgs = {
  versions_Isnull?: Maybe<Scalars['Boolean']>;
  versions?: Maybe<ProductVersionInputFilterObject>;
  chains_Isnull?: Maybe<Scalars['Boolean']>;
  chains?: Maybe<PolicyChainInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  tag_Isnull?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['String']>;
  tag_Iexact?: Maybe<Scalars['String']>;
  tag_Contains?: Maybe<Scalars['String']>;
  tag_Icontains?: Maybe<Scalars['String']>;
  tag_Startswith?: Maybe<Scalars['String']>;
  tag_Istartswith?: Maybe<Scalars['String']>;
  tag_Endswith?: Maybe<Scalars['String']>;
  tag_Iendswith?: Maybe<Scalars['String']>;
  tag_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  tag_Exclude_Icontains?: Maybe<Scalars['String']>;
  tag_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryProductVersionArgs = {
  id: Scalars['ID'];
};

export type QueryProductVersionsArgs = {
  benefits_Isnull?: Maybe<Scalars['Boolean']>;
  benefits?: Maybe<BenefitInputFilterObject>;
  policies_Isnull?: Maybe<Scalars['Boolean']>;
  policies?: Maybe<PolicyInputFilterObject>;
  ruleSets_Isnull?: Maybe<Scalars['Boolean']>;
  ruleSets?: Maybe<RulesSetInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_Isnull?: Maybe<Scalars['Boolean']>;
  product?: Maybe<ProductInputFilterObject>;
  version_Isnull?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
  version_Iexact?: Maybe<Scalars['String']>;
  version_Contains?: Maybe<Scalars['String']>;
  version_Icontains?: Maybe<Scalars['String']>;
  version_Startswith?: Maybe<Scalars['String']>;
  version_Istartswith?: Maybe<Scalars['String']>;
  version_Endswith?: Maybe<Scalars['String']>;
  version_Iendswith?: Maybe<Scalars['String']>;
  version_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  cardThreshold_Isnull?: Maybe<Scalars['Boolean']>;
  cardThreshold?: Maybe<Scalars['Float']>;
  cardThreshold_Gt?: Maybe<Scalars['Float']>;
  cardThreshold_Gte?: Maybe<Scalars['Float']>;
  cardThreshold_Lt?: Maybe<Scalars['Float']>;
  cardThreshold_Lte?: Maybe<Scalars['Float']>;
  cardThreshold_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  perClaimExcess_Isnull?: Maybe<Scalars['Boolean']>;
  perClaimExcess?: Maybe<Scalars['Float']>;
  perClaimExcess_Gt?: Maybe<Scalars['Float']>;
  perClaimExcess_Gte?: Maybe<Scalars['Float']>;
  perClaimExcess_Lt?: Maybe<Scalars['Float']>;
  perClaimExcess_Lte?: Maybe<Scalars['Float']>;
  perClaimExcess_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  excessesCapped_Isnull?: Maybe<Scalars['Boolean']>;
  excessesCapped?: Maybe<Scalars['Boolean']>;
  blockOnPendingClaim_Isnull?: Maybe<Scalars['Boolean']>;
  blockOnPendingClaim?: Maybe<Scalars['Boolean']>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_Isnull?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gt?: Maybe<Scalars['DateTime']>;
  updatedAt_Gte?: Maybe<Scalars['DateTime']>;
  updatedAt_Lt?: Maybe<Scalars['DateTime']>;
  updatedAt_Lte?: Maybe<Scalars['DateTime']>;
  updatedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  sendBreakdownEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sendBreakdownEmails?: Maybe<Scalars['Boolean']>;
};

export type QueryBeneficiaryPaymentArgs = {
  id: Scalars['ID'];
};

export type QueryBeneficiaryPaymentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentPtr_Isnull?: Maybe<Scalars['Boolean']>;
  paymentPtr?: Maybe<PaymentInputFilterObject>;
  personalCard_Isnull?: Maybe<Scalars['Boolean']>;
  personalCard?: Maybe<PersonalCardInputFilterObject>;
};

export type QueryInsurerPaymentArgs = {
  id: Scalars['ID'];
};

export type QueryInsurerPaymentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentPtr_Isnull?: Maybe<Scalars['Boolean']>;
  paymentPtr?: Maybe<PaymentInputFilterObject>;
  card_Isnull?: Maybe<Scalars['Boolean']>;
  card?: Maybe<CardInputFilterObject>;
  authorization_Isnull?: Maybe<Scalars['Boolean']>;
  authorization?: Maybe<PaymentAuthorizationInputFilterObject>;
  settlement_Isnull?: Maybe<Scalars['Boolean']>;
  settlement?: Maybe<PaymentSettlementInputFilterObject>;
};

export type QueryPaymentArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentsArgs = {
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  beneficiaryPayments_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryPayments?: Maybe<BeneficiaryPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  modelname?: Maybe<Scalars['String']>;
  modelname_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryPaymentAuthorizationArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentAuthorizationsArgs = {
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  attempts_Isnull?: Maybe<Scalars['Boolean']>;
  attempts?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  authorizationId_Isnull?: Maybe<Scalars['Boolean']>;
  authorizationId?: Maybe<Scalars['String']>;
  authorizationId_Iexact?: Maybe<Scalars['String']>;
  authorizationId_Contains?: Maybe<Scalars['String']>;
  authorizationId_Icontains?: Maybe<Scalars['String']>;
  authorizationId_Startswith?: Maybe<Scalars['String']>;
  authorizationId_Istartswith?: Maybe<Scalars['String']>;
  authorizationId_Endswith?: Maybe<Scalars['String']>;
  authorizationId_Iendswith?: Maybe<Scalars['String']>;
  authorizationId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProxyId_Isnull?: Maybe<Scalars['Boolean']>;
  cardProxyId?: Maybe<Scalars['String']>;
  cardProxyId_Iexact?: Maybe<Scalars['String']>;
  cardProxyId_Contains?: Maybe<Scalars['String']>;
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  cardProxyId_Startswith?: Maybe<Scalars['String']>;
  cardProxyId_Istartswith?: Maybe<Scalars['String']>;
  cardProxyId_Endswith?: Maybe<Scalars['String']>;
  cardProxyId_Iendswith?: Maybe<Scalars['String']>;
  cardProxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiaryId_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryId?: Maybe<Scalars['Int']>;
  beneficiaryId_Gt?: Maybe<Scalars['Int']>;
  beneficiaryId_Gte?: Maybe<Scalars['Int']>;
  beneficiaryId_Lt?: Maybe<Scalars['Int']>;
  beneficiaryId_Lte?: Maybe<Scalars['Int']>;
  beneficiaryId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantName_Isnull?: Maybe<Scalars['Boolean']>;
  merchantName?: Maybe<Scalars['String']>;
  merchantName_Iexact?: Maybe<Scalars['String']>;
  merchantName_Contains?: Maybe<Scalars['String']>;
  merchantName_Icontains?: Maybe<Scalars['String']>;
  merchantName_Startswith?: Maybe<Scalars['String']>;
  merchantName_Istartswith?: Maybe<Scalars['String']>;
  merchantName_Endswith?: Maybe<Scalars['String']>;
  merchantName_Iendswith?: Maybe<Scalars['String']>;
  merchantName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantExternalId_Isnull?: Maybe<Scalars['Boolean']>;
  merchantExternalId?: Maybe<Scalars['String']>;
  merchantExternalId_Iexact?: Maybe<Scalars['String']>;
  merchantExternalId_Contains?: Maybe<Scalars['String']>;
  merchantExternalId_Icontains?: Maybe<Scalars['String']>;
  merchantExternalId_Startswith?: Maybe<Scalars['String']>;
  merchantExternalId_Istartswith?: Maybe<Scalars['String']>;
  merchantExternalId_Endswith?: Maybe<Scalars['String']>;
  merchantExternalId_Iendswith?: Maybe<Scalars['String']>;
  merchantExternalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantAddress_Isnull?: Maybe<Scalars['Boolean']>;
  merchantAddress?: Maybe<Scalars['String']>;
  merchantCountryCode_Isnull?: Maybe<Scalars['Boolean']>;
  merchantCountryCode?: Maybe<Scalars['String']>;
  merchantCountryCode_Iexact?: Maybe<Scalars['String']>;
  merchantCountryCode_Contains?: Maybe<Scalars['String']>;
  merchantCountryCode_Icontains?: Maybe<Scalars['String']>;
  merchantCountryCode_Startswith?: Maybe<Scalars['String']>;
  merchantCountryCode_Istartswith?: Maybe<Scalars['String']>;
  merchantCountryCode_Endswith?: Maybe<Scalars['String']>;
  merchantCountryCode_Iendswith?: Maybe<Scalars['String']>;
  merchantCountryCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchantCategoryCode_Isnull?: Maybe<Scalars['Boolean']>;
  merchantCategoryCode?: Maybe<Scalars['String']>;
  merchantCategoryCode_Iexact?: Maybe<Scalars['String']>;
  merchantCategoryCode_Contains?: Maybe<Scalars['String']>;
  merchantCategoryCode_Icontains?: Maybe<Scalars['String']>;
  merchantCategoryCode_Startswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Istartswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Endswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_Iendswith?: Maybe<Scalars['String']>;
  merchantCategoryCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_Isnull?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_Iexact?: Maybe<Scalars['String']>;
  currencyCode_Contains?: Maybe<Scalars['String']>;
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  currencyCode_Startswith?: Maybe<Scalars['String']>;
  currencyCode_Istartswith?: Maybe<Scalars['String']>;
  currencyCode_Endswith?: Maybe<Scalars['String']>;
  currencyCode_Iendswith?: Maybe<Scalars['String']>;
  currencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryPaymentAuthorizationAttemptArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentAuthorizationAttemptsArgs = {
  reversals_Isnull?: Maybe<Scalars['Boolean']>;
  reversals?: Maybe<PaymentAuthorizationAttemptReversalInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  authorization_Isnull?: Maybe<Scalars['Boolean']>;
  authorization?: Maybe<PaymentAuthorizationInputFilterObject>;
  authorizationAttemptId_Isnull?: Maybe<Scalars['Boolean']>;
  authorizationAttemptId?: Maybe<Scalars['String']>;
  authorizationAttemptId_Iexact?: Maybe<Scalars['String']>;
  authorizationAttemptId_Contains?: Maybe<Scalars['String']>;
  authorizationAttemptId_Icontains?: Maybe<Scalars['String']>;
  authorizationAttemptId_Startswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_Istartswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_Endswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_Iendswith?: Maybe<Scalars['String']>;
  authorizationAttemptId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionTime_Isnull?: Maybe<Scalars['Boolean']>;
  transactionTime?: Maybe<Scalars['DateTime']>;
  transactionTime_Gt?: Maybe<Scalars['DateTime']>;
  transactionTime_Gte?: Maybe<Scalars['DateTime']>;
  transactionTime_Lt?: Maybe<Scalars['DateTime']>;
  transactionTime_Lte?: Maybe<Scalars['DateTime']>;
  transactionTime_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionType_Isnull?: Maybe<Scalars['Boolean']>;
  transactionType?: Maybe<Scalars['String']>;
  transactionType_Iexact?: Maybe<Scalars['String']>;
  transactionType_Contains?: Maybe<Scalars['String']>;
  transactionType_Icontains?: Maybe<Scalars['String']>;
  transactionType_Startswith?: Maybe<Scalars['String']>;
  transactionType_Istartswith?: Maybe<Scalars['String']>;
  transactionType_Endswith?: Maybe<Scalars['String']>;
  transactionType_Iendswith?: Maybe<Scalars['String']>;
  transactionType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  decisionResult_Isnull?: Maybe<Scalars['Boolean']>;
  decisionResult?: Maybe<Scalars['Boolean']>;
  decisionBy_Isnull?: Maybe<Scalars['Boolean']>;
  decisionBy?: Maybe<Scalars['String']>;
  decisionBy_Iexact?: Maybe<Scalars['String']>;
  decisionBy_Contains?: Maybe<Scalars['String']>;
  decisionBy_Icontains?: Maybe<Scalars['String']>;
  decisionBy_Startswith?: Maybe<Scalars['String']>;
  decisionBy_Istartswith?: Maybe<Scalars['String']>;
  decisionBy_Endswith?: Maybe<Scalars['String']>;
  decisionBy_Iendswith?: Maybe<Scalars['String']>;
  decisionBy_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  overrides_Isnull?: Maybe<Scalars['Boolean']>;
  overrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
};

export type QueryPaymentRulesOverrideArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentRulesOverridesArgs = {
  paymentauthorizationattempt_Isnull?: Maybe<Scalars['Boolean']>;
  paymentauthorizationattempt?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProxyId_Isnull?: Maybe<Scalars['Boolean']>;
  cardProxyId?: Maybe<Scalars['String']>;
  cardProxyId_Iexact?: Maybe<Scalars['String']>;
  cardProxyId_Contains?: Maybe<Scalars['String']>;
  cardProxyId_Icontains?: Maybe<Scalars['String']>;
  cardProxyId_Startswith?: Maybe<Scalars['String']>;
  cardProxyId_Istartswith?: Maybe<Scalars['String']>;
  cardProxyId_Endswith?: Maybe<Scalars['String']>;
  cardProxyId_Iendswith?: Maybe<Scalars['String']>;
  cardProxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  overrideType_Isnull?: Maybe<Scalars['Boolean']>;
  overrideType?: Maybe<Scalars['String']>;
  overrideType_Iexact?: Maybe<Scalars['String']>;
  overrideType_Contains?: Maybe<Scalars['String']>;
  overrideType_Icontains?: Maybe<Scalars['String']>;
  overrideType_Startswith?: Maybe<Scalars['String']>;
  overrideType_Istartswith?: Maybe<Scalars['String']>;
  overrideType_Endswith?: Maybe<Scalars['String']>;
  overrideType_Iendswith?: Maybe<Scalars['String']>;
  overrideType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  validUntil_Isnull?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_Gt?: Maybe<Scalars['DateTime']>;
  validUntil_Gte?: Maybe<Scalars['DateTime']>;
  validUntil_Lt?: Maybe<Scalars['DateTime']>;
  validUntil_Lte?: Maybe<Scalars['DateTime']>;
  validUntil_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  validNTimes_Isnull?: Maybe<Scalars['Boolean']>;
  validNTimes?: Maybe<Scalars['Int']>;
  validNTimes_Gt?: Maybe<Scalars['Int']>;
  validNTimes_Gte?: Maybe<Scalars['Int']>;
  validNTimes_Lt?: Maybe<Scalars['Int']>;
  validNTimes_Lte?: Maybe<Scalars['Int']>;
  validNTimes_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  specificationQuery?: Maybe<Scalars['String']>;
  overrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
};

export type QueryPaymentSettlementArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentSettlementsArgs = {
  issuerTransactions_Isnull?: Maybe<Scalars['Boolean']>;
  issuerTransactions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  events_Isnull?: Maybe<Scalars['Boolean']>;
  events?: Maybe<PaymentSettlementEventInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  authorizationId_Isnull?: Maybe<Scalars['Boolean']>;
  authorizationId?: Maybe<Scalars['String']>;
  authorizationId_Iexact?: Maybe<Scalars['String']>;
  authorizationId_Contains?: Maybe<Scalars['String']>;
  authorizationId_Icontains?: Maybe<Scalars['String']>;
  authorizationId_Startswith?: Maybe<Scalars['String']>;
  authorizationId_Istartswith?: Maybe<Scalars['String']>;
  authorizationId_Endswith?: Maybe<Scalars['String']>;
  authorizationId_Iendswith?: Maybe<Scalars['String']>;
  authorizationId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_Isnull?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_Iexact?: Maybe<Scalars['String']>;
  currencyCode_Contains?: Maybe<Scalars['String']>;
  currencyCode_Icontains?: Maybe<Scalars['String']>;
  currencyCode_Startswith?: Maybe<Scalars['String']>;
  currencyCode_Istartswith?: Maybe<Scalars['String']>;
  currencyCode_Endswith?: Maybe<Scalars['String']>;
  currencyCode_Iendswith?: Maybe<Scalars['String']>;
  currencyCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryPaymentAuthorizationAttemptReversalArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentAuthorizationAttemptReversalsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  authorizationAttempt_Isnull?: Maybe<Scalars['Boolean']>;
  authorizationAttempt?: Maybe<PaymentAuthorizationAttemptInputFilterObject>;
  reversalId_Isnull?: Maybe<Scalars['Boolean']>;
  reversalId?: Maybe<Scalars['String']>;
  reversalId_Iexact?: Maybe<Scalars['String']>;
  reversalId_Contains?: Maybe<Scalars['String']>;
  reversalId_Icontains?: Maybe<Scalars['String']>;
  reversalId_Startswith?: Maybe<Scalars['String']>;
  reversalId_Istartswith?: Maybe<Scalars['String']>;
  reversalId_Endswith?: Maybe<Scalars['String']>;
  reversalId_Iendswith?: Maybe<Scalars['String']>;
  reversalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  reversedBy_Isnull?: Maybe<Scalars['Boolean']>;
  reversedBy?: Maybe<Scalars['String']>;
  reversedBy_Iexact?: Maybe<Scalars['String']>;
  reversedBy_Contains?: Maybe<Scalars['String']>;
  reversedBy_Icontains?: Maybe<Scalars['String']>;
  reversedBy_Startswith?: Maybe<Scalars['String']>;
  reversedBy_Istartswith?: Maybe<Scalars['String']>;
  reversedBy_Endswith?: Maybe<Scalars['String']>;
  reversedBy_Iendswith?: Maybe<Scalars['String']>;
  reversedBy_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryCardEventArgs = {
  id: Scalars['ID'];
};

export type QueryCardEventsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  transactionSource_Isnull?: Maybe<Scalars['Boolean']>;
  transactionSource?: Maybe<Scalars['String']>;
  transactionSource_Iexact?: Maybe<Scalars['String']>;
  transactionSource_Contains?: Maybe<Scalars['String']>;
  transactionSource_Icontains?: Maybe<Scalars['String']>;
  transactionSource_Startswith?: Maybe<Scalars['String']>;
  transactionSource_Istartswith?: Maybe<Scalars['String']>;
  transactionSource_Endswith?: Maybe<Scalars['String']>;
  transactionSource_Iendswith?: Maybe<Scalars['String']>;
  transactionSource_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionType_Isnull?: Maybe<Scalars['Boolean']>;
  transactionType?: Maybe<Scalars['String']>;
  transactionType_Iexact?: Maybe<Scalars['String']>;
  transactionType_Contains?: Maybe<Scalars['String']>;
  transactionType_Icontains?: Maybe<Scalars['String']>;
  transactionType_Startswith?: Maybe<Scalars['String']>;
  transactionType_Istartswith?: Maybe<Scalars['String']>;
  transactionType_Endswith?: Maybe<Scalars['String']>;
  transactionType_Iendswith?: Maybe<Scalars['String']>;
  transactionType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionId_Isnull?: Maybe<Scalars['Boolean']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionId_Iexact?: Maybe<Scalars['String']>;
  transactionId_Contains?: Maybe<Scalars['String']>;
  transactionId_Icontains?: Maybe<Scalars['String']>;
  transactionId_Startswith?: Maybe<Scalars['String']>;
  transactionId_Istartswith?: Maybe<Scalars['String']>;
  transactionId_Endswith?: Maybe<Scalars['String']>;
  transactionId_Iendswith?: Maybe<Scalars['String']>;
  transactionId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['String']>;
  entityId_Iexact?: Maybe<Scalars['String']>;
  entityId_Contains?: Maybe<Scalars['String']>;
  entityId_Icontains?: Maybe<Scalars['String']>;
  entityId_Startswith?: Maybe<Scalars['String']>;
  entityId_Istartswith?: Maybe<Scalars['String']>;
  entityId_Endswith?: Maybe<Scalars['String']>;
  entityId_Iendswith?: Maybe<Scalars['String']>;
  entityId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryMobileAppUserArgs = {
  id: Scalars['ID'];
};

export type QueryMobileAppUsersArgs = {
  mobileNotifications_Isnull?: Maybe<Scalars['Boolean']>;
  mobileNotifications?: Maybe<MobileNotificationInputFilterObject>;
  sessions_Isnull?: Maybe<Scalars['Boolean']>;
  sessions?: Maybe<MobileAppSessionInputFilterObject>;
  locationStamps_Isnull?: Maybe<Scalars['Boolean']>;
  locationStamps?: Maybe<SessionLocationStampInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  password_Isnull?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  password_Iexact?: Maybe<Scalars['String']>;
  password_Contains?: Maybe<Scalars['String']>;
  password_Icontains?: Maybe<Scalars['String']>;
  password_Startswith?: Maybe<Scalars['String']>;
  password_Istartswith?: Maybe<Scalars['String']>;
  password_Endswith?: Maybe<Scalars['String']>;
  password_Iendswith?: Maybe<Scalars['String']>;
  password_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastLogin_Isnull?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastLogin_Gt?: Maybe<Scalars['DateTime']>;
  lastLogin_Gte?: Maybe<Scalars['DateTime']>;
  lastLogin_Lt?: Maybe<Scalars['DateTime']>;
  lastLogin_Lte?: Maybe<Scalars['DateTime']>;
  lastLogin_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  username_Isnull?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  username_Iexact?: Maybe<Scalars['String']>;
  username_Contains?: Maybe<Scalars['String']>;
  username_Icontains?: Maybe<Scalars['String']>;
  username_Startswith?: Maybe<Scalars['String']>;
  username_Istartswith?: Maybe<Scalars['String']>;
  username_Endswith?: Maybe<Scalars['String']>;
  username_Iendswith?: Maybe<Scalars['String']>;
  username_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  passwordExpiration_Isnull?: Maybe<Scalars['Boolean']>;
  passwordExpiration?: Maybe<Scalars['DateTime']>;
  passwordExpiration_Gt?: Maybe<Scalars['DateTime']>;
  passwordExpiration_Gte?: Maybe<Scalars['DateTime']>;
  passwordExpiration_Lt?: Maybe<Scalars['DateTime']>;
  passwordExpiration_Lte?: Maybe<Scalars['DateTime']>;
  passwordExpiration_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryMobileAppSessionArgs = {
  id: Scalars['ID'];
};

export type QueryMobileAppSessionsArgs = {
  token_Isnull?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  token_Iexact?: Maybe<Scalars['String']>;
  token_Contains?: Maybe<Scalars['String']>;
  token_Icontains?: Maybe<Scalars['String']>;
  token_Startswith?: Maybe<Scalars['String']>;
  token_Istartswith?: Maybe<Scalars['String']>;
  token_Endswith?: Maybe<Scalars['String']>;
  token_Iendswith?: Maybe<Scalars['String']>;
  token_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<MobileAppUserInputFilterObject>;
  policy_Isnull?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<PolicyInputFilterObject>;
  lastActivity_Isnull?: Maybe<Scalars['Boolean']>;
  lastActivity?: Maybe<Scalars['DateTime']>;
  lastActivity_Gt?: Maybe<Scalars['DateTime']>;
  lastActivity_Gte?: Maybe<Scalars['DateTime']>;
  lastActivity_Lt?: Maybe<Scalars['DateTime']>;
  lastActivity_Lte?: Maybe<Scalars['DateTime']>;
  lastActivity_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
};

export type QueryLocationStampArgs = {
  id: Scalars['ID'];
};

export type QueryLocationStampsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<MobileAppUserInputFilterObject>;
  platform_Isnull?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Scalars['String']>;
  platform_Iexact?: Maybe<Scalars['String']>;
  platform_Contains?: Maybe<Scalars['String']>;
  platform_Icontains?: Maybe<Scalars['String']>;
  platform_Startswith?: Maybe<Scalars['String']>;
  platform_Istartswith?: Maybe<Scalars['String']>;
  platform_Endswith?: Maybe<Scalars['String']>;
  platform_Iendswith?: Maybe<Scalars['String']>;
  platform_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  gpsLatitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLatitude?: Maybe<Scalars['Float']>;
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  gpsLatitude_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  gpsLongitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLongitude?: Maybe<Scalars['Float']>;
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  gpsLongitude_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryProviderArgs = {
  id: Scalars['ID'];
};

export type QueryProvidersArgs = {
  claimicdcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<ClaimIcdCodeInputFilterObject>;
  providerEmployees_Isnull?: Maybe<Scalars['Boolean']>;
  providerEmployees?: Maybe<ProviderEmployeeInputFilterObject>;
  unlocks_Isnull?: Maybe<Scalars['Boolean']>;
  unlocks?: Maybe<BeneficiaryDataUnlockInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchant_Isnull?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantInputFilterObject>;
  email_Isnull?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_Iexact?: Maybe<Scalars['String']>;
  email_Contains?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
  email_Startswith?: Maybe<Scalars['String']>;
  email_Istartswith?: Maybe<Scalars['String']>;
  email_Endswith?: Maybe<Scalars['String']>;
  email_Iendswith?: Maybe<Scalars['String']>;
  email_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber_Isnull?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumber_Iexact?: Maybe<Scalars['String']>;
  phoneNumber_Contains?: Maybe<Scalars['String']>;
  phoneNumber_Icontains?: Maybe<Scalars['String']>;
  phoneNumber_Startswith?: Maybe<Scalars['String']>;
  phoneNumber_Istartswith?: Maybe<Scalars['String']>;
  phoneNumber_Endswith?: Maybe<Scalars['String']>;
  phoneNumber_Iendswith?: Maybe<Scalars['String']>;
  phoneNumber_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  receiveNotifications_Isnull?: Maybe<Scalars['Boolean']>;
  receiveNotifications?: Maybe<Scalars['Boolean']>;
};

export type QueryProviderEmployeeArgs = {
  id: Scalars['ID'];
};

export type QueryProviderEmployeesArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
};

export type QueryBeneficiaryDataUnlockArgs = {
  id: Scalars['ID'];
};

export type QueryBeneficiaryDataUnlocksArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  usedAt_Isnull?: Maybe<Scalars['Boolean']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  usedAt_Gt?: Maybe<Scalars['DateTime']>;
  usedAt_Gte?: Maybe<Scalars['DateTime']>;
  usedAt_Lt?: Maybe<Scalars['DateTime']>;
  usedAt_Lte?: Maybe<Scalars['DateTime']>;
  usedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isEnabled_Isnull?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  validUntil_Isnull?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  validUntil_Gt?: Maybe<Scalars['DateTime']>;
  validUntil_Gte?: Maybe<Scalars['DateTime']>;
  validUntil_Lt?: Maybe<Scalars['DateTime']>;
  validUntil_Lte?: Maybe<Scalars['DateTime']>;
  validUntil_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryMerchantCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryMerchantCategoriesArgs = {
  benefitCategories_Isnull?: Maybe<Scalars['Boolean']>;
  benefitCategories?: Maybe<BenefitCategoryInputFilterObject>;
  merchant_Isnull?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  code_Isnull?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  code_Iexact?: Maybe<Scalars['String']>;
  code_Contains?: Maybe<Scalars['String']>;
  code_Icontains?: Maybe<Scalars['String']>;
  code_Startswith?: Maybe<Scalars['String']>;
  code_Istartswith?: Maybe<Scalars['String']>;
  code_Endswith?: Maybe<Scalars['String']>;
  code_Iendswith?: Maybe<Scalars['String']>;
  code_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  q?: Maybe<Scalars['String']>;
  merchantCategories?: Maybe<MerchantCategoryInputFilterObject>;
  categories?: Maybe<MerchantCategoryInputFilterObject>;
};

export type QueryMerchantArgs = {
  id: Scalars['ID'];
};

export type QueryMerchantsArgs = {
  claims_Isnull?: Maybe<Scalars['Boolean']>;
  claims?: Maybe<ClaimInputFilterObject>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryInputFilterObject>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_Isnull?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  gpsLatitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLatitude?: Maybe<Scalars['Float']>;
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  gpsLatitude_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  gpsLongitude_Isnull?: Maybe<Scalars['Boolean']>;
  gpsLongitude?: Maybe<Scalars['Float']>;
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  gpsLongitude_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailedInfo_Isnull?: Maybe<Scalars['Boolean']>;
  detailedInfo?: Maybe<Scalars['String']>;
  isVerifiedByAdmin_Isnull?: Maybe<Scalars['Boolean']>;
  isVerifiedByAdmin?: Maybe<Scalars['Boolean']>;
  categories_Isnull?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<MerchantCategoryInputFilterObject>;
  geo?: Maybe<Scalars['String']>;
};

export type QueryGroupArgs = {
  id: Scalars['ID'];
};

export type QueryGroupsArgs = {
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  details_Isnull?: Maybe<Scalars['Boolean']>;
  details?: Maybe<GroupDetailsInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions_Isnull?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<PermissionInputFilterObject>;
  groups?: Maybe<GroupInputFilterObject>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  authToken_Isnull?: Maybe<Scalars['Boolean']>;
  authToken?: Maybe<TokenInputFilterObject>;
  employee_Isnull?: Maybe<Scalars['Boolean']>;
  employee?: Maybe<InsurerEmployeeInputFilterObject>;
  passwordExpiration_Isnull?: Maybe<Scalars['Boolean']>;
  passwordExpiration?: Maybe<PasswordExpirationInputFilterObject>;
  details_Isnull?: Maybe<Scalars['Boolean']>;
  details?: Maybe<UserDetailsInputFilterObject>;
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  resolvedSupportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  resolvedSupportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  receipts_Isnull?: Maybe<Scalars['Boolean']>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  resolvedReceipts_Isnull?: Maybe<Scalars['Boolean']>;
  resolvedReceipts?: Maybe<ReceiptInputFilterObject>;
  cardActions_Isnull?: Maybe<Scalars['Boolean']>;
  cardActions?: Maybe<CardActionInputFilterObject>;
  notification_Isnull?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<NotificationInputFilterObject>;
  providerEmployee_Isnull?: Maybe<Scalars['Boolean']>;
  providerEmployee?: Maybe<ProviderEmployeeInputFilterObject>;
  userpasswordhistoryconfig_Isnull?: Maybe<Scalars['Boolean']>;
  userpasswordhistoryconfig?: Maybe<UserPasswordHistoryConfigInputFilterObject>;
  logentry_Isnull?: Maybe<Scalars['Boolean']>;
  logentry?: Maybe<LogEntryInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  password_Isnull?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  password_Iexact?: Maybe<Scalars['String']>;
  password_Contains?: Maybe<Scalars['String']>;
  password_Icontains?: Maybe<Scalars['String']>;
  password_Startswith?: Maybe<Scalars['String']>;
  password_Istartswith?: Maybe<Scalars['String']>;
  password_Endswith?: Maybe<Scalars['String']>;
  password_Iendswith?: Maybe<Scalars['String']>;
  password_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastLogin_Isnull?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastLogin_Gt?: Maybe<Scalars['DateTime']>;
  lastLogin_Gte?: Maybe<Scalars['DateTime']>;
  lastLogin_Lt?: Maybe<Scalars['DateTime']>;
  lastLogin_Lte?: Maybe<Scalars['DateTime']>;
  lastLogin_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isSuperuser_Isnull?: Maybe<Scalars['Boolean']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  username_Isnull?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
  username_Iexact?: Maybe<Scalars['String']>;
  username_Contains?: Maybe<Scalars['String']>;
  username_Icontains?: Maybe<Scalars['String']>;
  username_Startswith?: Maybe<Scalars['String']>;
  username_Istartswith?: Maybe<Scalars['String']>;
  username_Endswith?: Maybe<Scalars['String']>;
  username_Iendswith?: Maybe<Scalars['String']>;
  username_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName_Isnull?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_Iexact?: Maybe<Scalars['String']>;
  firstName_Contains?: Maybe<Scalars['String']>;
  firstName_Icontains?: Maybe<Scalars['String']>;
  firstName_Startswith?: Maybe<Scalars['String']>;
  firstName_Istartswith?: Maybe<Scalars['String']>;
  firstName_Endswith?: Maybe<Scalars['String']>;
  firstName_Iendswith?: Maybe<Scalars['String']>;
  firstName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName_Isnull?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_Iexact?: Maybe<Scalars['String']>;
  lastName_Contains?: Maybe<Scalars['String']>;
  lastName_Icontains?: Maybe<Scalars['String']>;
  lastName_Startswith?: Maybe<Scalars['String']>;
  lastName_Istartswith?: Maybe<Scalars['String']>;
  lastName_Endswith?: Maybe<Scalars['String']>;
  lastName_Iendswith?: Maybe<Scalars['String']>;
  lastName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_Isnull?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_Iexact?: Maybe<Scalars['String']>;
  email_Contains?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
  email_Startswith?: Maybe<Scalars['String']>;
  email_Istartswith?: Maybe<Scalars['String']>;
  email_Endswith?: Maybe<Scalars['String']>;
  email_Iendswith?: Maybe<Scalars['String']>;
  email_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isStaff_Isnull?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  dateJoined_Isnull?: Maybe<Scalars['Boolean']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  dateJoined_Gt?: Maybe<Scalars['DateTime']>;
  dateJoined_Gte?: Maybe<Scalars['DateTime']>;
  dateJoined_Lt?: Maybe<Scalars['DateTime']>;
  dateJoined_Lte?: Maybe<Scalars['DateTime']>;
  dateJoined_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  groups_Isnull?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<GroupInputFilterObject>;
  userPermissions_Isnull?: Maybe<Scalars['Boolean']>;
  userPermissions?: Maybe<PermissionInputFilterObject>;
  isMe?: Maybe<Scalars['Boolean']>;
};

export type QueryInsurerArgs = {
  id: Scalars['ID'];
};

export type QueryInsurersArgs = {
  employees_Isnull?: Maybe<Scalars['Boolean']>;
  employees?: Maybe<InsurerEmployeeInputFilterObject>;
  workDays_Isnull?: Maybe<Scalars['Boolean']>;
  workDays?: Maybe<WorkDayInputFilterObject>;
  fundingAccounts_Isnull?: Maybe<Scalars['Boolean']>;
  fundingAccounts?: Maybe<FundingAccountInputFilterObject>;
  products_Isnull?: Maybe<Scalars['Boolean']>;
  products?: Maybe<ProductInputFilterObject>;
  benefitCategories_Isnull?: Maybe<Scalars['Boolean']>;
  benefitCategories?: Maybe<BenefitCategoryInputFilterObject>;
  notification_Isnull?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<NotificationInputFilterObject>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryInputFilterObject>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_Isnull?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_Iexact?: Maybe<Scalars['String']>;
  slug_Contains?: Maybe<Scalars['String']>;
  slug_Icontains?: Maybe<Scalars['String']>;
  slug_Startswith?: Maybe<Scalars['String']>;
  slug_Istartswith?: Maybe<Scalars['String']>;
  slug_Endswith?: Maybe<Scalars['String']>;
  slug_Iendswith?: Maybe<Scalars['String']>;
  slug_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumber_Isnull?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumber_Iexact?: Maybe<Scalars['String']>;
  phoneNumber_Contains?: Maybe<Scalars['String']>;
  phoneNumber_Icontains?: Maybe<Scalars['String']>;
  phoneNumber_Startswith?: Maybe<Scalars['String']>;
  phoneNumber_Istartswith?: Maybe<Scalars['String']>;
  phoneNumber_Endswith?: Maybe<Scalars['String']>;
  phoneNumber_Iendswith?: Maybe<Scalars['String']>;
  phoneNumber_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  mobileAppPasswordValidity_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppPasswordValidity?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_Gt?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_Gte?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_Lt?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_Lte?: Maybe<Scalars['Int']>;
  mobileAppPasswordValidity_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  mobileAppPasswordRegexp_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppPasswordRegexp?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Iexact?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Contains?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Icontains?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Startswith?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Istartswith?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Endswith?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_Iendswith?: Maybe<Scalars['String']>;
  mobileAppPasswordRegexp_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
};

export type QueryInsurerEmployeeArgs = {
  id: Scalars['ID'];
};

export type QueryInsurerEmployeesArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
};

export type QueryFundingAccountArgs = {
  id: Scalars['ID'];
};

export type QueryFundingAccountsArgs = {
  physicalCardMeta_Isnull?: Maybe<Scalars['Boolean']>;
  physicalCardMeta?: Maybe<PhysicalCardAccountMetaInputFilterObject>;
  virtualCardMeta_Isnull?: Maybe<Scalars['Boolean']>;
  virtualCardMeta?: Maybe<VirtualCardAccountMetaInputFilterObject>;
  blockedFunds_Isnull?: Maybe<Scalars['Boolean']>;
  blockedFunds?: Maybe<BlockedFundsInputFilterObject>;
  settlementFunds_Isnull?: Maybe<Scalars['Boolean']>;
  settlementFunds?: Maybe<SettlementFundsInputFilterObject>;
  topUps_Isnull?: Maybe<Scalars['Boolean']>;
  topUps?: Maybe<TopUpTransactionInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  issuer_Isnull?: Maybe<Scalars['Boolean']>;
  issuer?: Maybe<IssuerInputFilterObject>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  topUpsLastProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsLastProcessedAt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  topUpsLastProcessedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  topUpsStartProcessingFrom_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsStartProcessingFrom?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Gt?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Gte?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Lt?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_Lte?: Maybe<Scalars['DateTime']>;
  topUpsStartProcessingFrom_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  topUpsFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsFailedAt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  topUpsFailedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  topUpsKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsKeepProcessing?: Maybe<Scalars['Boolean']>;
  topUpsFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  topUpsFailedCount?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Gt?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Gte?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Lt?: Maybe<Scalars['Int']>;
  topUpsFailedCount_Lte?: Maybe<Scalars['Int']>;
  topUpsFailedCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fundingOffsetAmount_Isnull?: Maybe<Scalars['Boolean']>;
  fundingOffsetAmount?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Gt?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Gte?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Lt?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_Lte?: Maybe<Scalars['Float']>;
  fundingOffsetAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  iban_Isnull?: Maybe<Scalars['Boolean']>;
  iban?: Maybe<Scalars['String']>;
  iban_Iexact?: Maybe<Scalars['String']>;
  iban_Contains?: Maybe<Scalars['String']>;
  iban_Icontains?: Maybe<Scalars['String']>;
  iban_Startswith?: Maybe<Scalars['String']>;
  iban_Istartswith?: Maybe<Scalars['String']>;
  iban_Endswith?: Maybe<Scalars['String']>;
  iban_Iendswith?: Maybe<Scalars['String']>;
  iban_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  bic_Isnull?: Maybe<Scalars['Boolean']>;
  bic?: Maybe<Scalars['String']>;
  bic_Iexact?: Maybe<Scalars['String']>;
  bic_Contains?: Maybe<Scalars['String']>;
  bic_Icontains?: Maybe<Scalars['String']>;
  bic_Startswith?: Maybe<Scalars['String']>;
  bic_Istartswith?: Maybe<Scalars['String']>;
  bic_Endswith?: Maybe<Scalars['String']>;
  bic_Iendswith?: Maybe<Scalars['String']>;
  bic_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryPhysicalCardMetaArgs = {
  id: Scalars['ID'];
};

export type QueryPhysicalCardMetasArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardDesign_Isnull?: Maybe<Scalars['Boolean']>;
  cardDesign?: Maybe<Scalars['String']>;
  cardDesign_Iexact?: Maybe<Scalars['String']>;
  cardDesign_Contains?: Maybe<Scalars['String']>;
  cardDesign_Icontains?: Maybe<Scalars['String']>;
  cardDesign_Startswith?: Maybe<Scalars['String']>;
  cardDesign_Istartswith?: Maybe<Scalars['String']>;
  cardDesign_Endswith?: Maybe<Scalars['String']>;
  cardDesign_Iendswith?: Maybe<Scalars['String']>;
  cardDesign_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProgramme_Isnull?: Maybe<Scalars['Boolean']>;
  cardProgramme?: Maybe<Scalars['String']>;
  cardProgramme_Iexact?: Maybe<Scalars['String']>;
  cardProgramme_Contains?: Maybe<Scalars['String']>;
  cardProgramme_Icontains?: Maybe<Scalars['String']>;
  cardProgramme_Startswith?: Maybe<Scalars['String']>;
  cardProgramme_Istartswith?: Maybe<Scalars['String']>;
  cardProgramme_Endswith?: Maybe<Scalars['String']>;
  cardProgramme_Iendswith?: Maybe<Scalars['String']>;
  cardProgramme_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fundingAccount_Isnull?: Maybe<Scalars['Boolean']>;
  fundingAccount?: Maybe<FundingAccountInputFilterObject>;
};

export type QueryVirtualCardMetaArgs = {
  id: Scalars['ID'];
};

export type QueryVirtualCardMetasArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardDesign_Isnull?: Maybe<Scalars['Boolean']>;
  cardDesign?: Maybe<Scalars['String']>;
  cardDesign_Iexact?: Maybe<Scalars['String']>;
  cardDesign_Contains?: Maybe<Scalars['String']>;
  cardDesign_Icontains?: Maybe<Scalars['String']>;
  cardDesign_Startswith?: Maybe<Scalars['String']>;
  cardDesign_Istartswith?: Maybe<Scalars['String']>;
  cardDesign_Endswith?: Maybe<Scalars['String']>;
  cardDesign_Iendswith?: Maybe<Scalars['String']>;
  cardDesign_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProgramme_Isnull?: Maybe<Scalars['Boolean']>;
  cardProgramme?: Maybe<Scalars['String']>;
  cardProgramme_Iexact?: Maybe<Scalars['String']>;
  cardProgramme_Contains?: Maybe<Scalars['String']>;
  cardProgramme_Icontains?: Maybe<Scalars['String']>;
  cardProgramme_Startswith?: Maybe<Scalars['String']>;
  cardProgramme_Istartswith?: Maybe<Scalars['String']>;
  cardProgramme_Endswith?: Maybe<Scalars['String']>;
  cardProgramme_Iendswith?: Maybe<Scalars['String']>;
  cardProgramme_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fundingAccount_Isnull?: Maybe<Scalars['Boolean']>;
  fundingAccount?: Maybe<FundingAccountInputFilterObject>;
};

export type QueryNotificationArgs = {
  id: Scalars['ID'];
};

export type QueryNotificationsArgs = {
  mobileNotifications_Isnull?: Maybe<Scalars['Boolean']>;
  mobileNotifications?: Maybe<MobileNotificationInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  action_Isnull?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_Iexact?: Maybe<Scalars['String']>;
  action_Contains?: Maybe<Scalars['String']>;
  action_Icontains?: Maybe<Scalars['String']>;
  action_Startswith?: Maybe<Scalars['String']>;
  action_Istartswith?: Maybe<Scalars['String']>;
  action_Endswith?: Maybe<Scalars['String']>;
  action_Iendswith?: Maybe<Scalars['String']>;
  action_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  read_Isnull?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['String']>;
  entityId_Iexact?: Maybe<Scalars['String']>;
  entityId_Contains?: Maybe<Scalars['String']>;
  entityId_Icontains?: Maybe<Scalars['String']>;
  entityId_Startswith?: Maybe<Scalars['String']>;
  entityId_Istartswith?: Maybe<Scalars['String']>;
  entityId_Endswith?: Maybe<Scalars['String']>;
  entityId_Iendswith?: Maybe<Scalars['String']>;
  entityId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  source?: Maybe<Scalars['String']>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claimsAuthority?: Maybe<Scalars['Float']>;
  search?: Maybe<NotificationSearchInputType>;
  claimId?: Maybe<Scalars['String']>;
};

export type QueryMobileNotificationArgs = {
  id: Scalars['ID'];
};

export type QueryMobileNotificationsArgs = {
  notification_Isnull?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<NotificationInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<MobileAppUserInputFilterObject>;
  platform_Isnull?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Scalars['String']>;
  platform_Iexact?: Maybe<Scalars['String']>;
  platform_Contains?: Maybe<Scalars['String']>;
  platform_Icontains?: Maybe<Scalars['String']>;
  platform_Startswith?: Maybe<Scalars['String']>;
  platform_Istartswith?: Maybe<Scalars['String']>;
  platform_Endswith?: Maybe<Scalars['String']>;
  platform_Iendswith?: Maybe<Scalars['String']>;
  platform_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  deviceToken_Isnull?: Maybe<Scalars['Boolean']>;
  deviceToken?: Maybe<Scalars['String']>;
  success_Isnull?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
};

export type QueryCardActionArgs = {
  id: Scalars['ID'];
};

export type QueryCardActionsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  card_Isnull?: Maybe<Scalars['Boolean']>;
  card?: Maybe<CardInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  created_Isnull?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  created_Gt?: Maybe<Scalars['DateTime']>;
  created_Gte?: Maybe<Scalars['DateTime']>;
  created_Lt?: Maybe<Scalars['DateTime']>;
  created_Lte?: Maybe<Scalars['DateTime']>;
  created_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  action_Isnull?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_Iexact?: Maybe<Scalars['String']>;
  action_Contains?: Maybe<Scalars['String']>;
  action_Icontains?: Maybe<Scalars['String']>;
  action_Startswith?: Maybe<Scalars['String']>;
  action_Istartswith?: Maybe<Scalars['String']>;
  action_Endswith?: Maybe<Scalars['String']>;
  action_Iendswith?: Maybe<Scalars['String']>;
  action_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
};

export type QueryCardArgs = {
  id: Scalars['ID'];
};

export type QueryCardsArgs = {
  actions_Isnull?: Maybe<Scalars['Boolean']>;
  actions?: Maybe<CardActionInputFilterObject>;
  aggregatedbeneficiarycard_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedbeneficiarycard?: Maybe<AggregatedBeneficiaryCardInputFilterObject>;
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  proxyId_Isnull?: Maybe<Scalars['Boolean']>;
  proxyId?: Maybe<Scalars['String']>;
  proxyId_Iexact?: Maybe<Scalars['String']>;
  proxyId_Contains?: Maybe<Scalars['String']>;
  proxyId_Icontains?: Maybe<Scalars['String']>;
  proxyId_Startswith?: Maybe<Scalars['String']>;
  proxyId_Istartswith?: Maybe<Scalars['String']>;
  proxyId_Endswith?: Maybe<Scalars['String']>;
  proxyId_Iendswith?: Maybe<Scalars['String']>;
  proxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  nameOnCard_Isnull?: Maybe<Scalars['Boolean']>;
  nameOnCard?: Maybe<Scalars['String']>;
  nameOnCard_Iexact?: Maybe<Scalars['String']>;
  nameOnCard_Contains?: Maybe<Scalars['String']>;
  nameOnCard_Icontains?: Maybe<Scalars['String']>;
  nameOnCard_Startswith?: Maybe<Scalars['String']>;
  nameOnCard_Istartswith?: Maybe<Scalars['String']>;
  nameOnCard_Endswith?: Maybe<Scalars['String']>;
  nameOnCard_Iendswith?: Maybe<Scalars['String']>;
  nameOnCard_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastDigits_Isnull?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastDigits_Iexact?: Maybe<Scalars['String']>;
  lastDigits_Contains?: Maybe<Scalars['String']>;
  lastDigits_Icontains?: Maybe<Scalars['String']>;
  lastDigits_Startswith?: Maybe<Scalars['String']>;
  lastDigits_Istartswith?: Maybe<Scalars['String']>;
  lastDigits_Endswith?: Maybe<Scalars['String']>;
  lastDigits_Iendswith?: Maybe<Scalars['String']>;
  lastDigits_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['String']>;
  expiration_Iexact?: Maybe<Scalars['String']>;
  expiration_Contains?: Maybe<Scalars['String']>;
  expiration_Icontains?: Maybe<Scalars['String']>;
  expiration_Startswith?: Maybe<Scalars['String']>;
  expiration_Istartswith?: Maybe<Scalars['String']>;
  expiration_Endswith?: Maybe<Scalars['String']>;
  expiration_Iendswith?: Maybe<Scalars['String']>;
  expiration_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  policyChain_Isnull?: Maybe<Scalars['Boolean']>;
  policyChain?: Maybe<PolicyChainInputFilterObject>;
  isFrozen_Isnull?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isBlocked_Isnull?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeactivated_Isnull?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isCancelled_Isnull?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isIssued_Isnull?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered_Isnull?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  defaultClaim_Isnull?: Maybe<Scalars['Boolean']>;
  defaultClaim?: Maybe<ClaimInputFilterObject>;
  currentBeneficiaryForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentBeneficiaryForPayments?: Maybe<BeneficiaryInputFilterObject>;
  cardType_Isnull?: Maybe<Scalars['Boolean']>;
  cardType?: Maybe<Scalars['String']>;
  cardType_Iexact?: Maybe<Scalars['String']>;
  cardType_Contains?: Maybe<Scalars['String']>;
  cardType_Icontains?: Maybe<Scalars['String']>;
  cardType_Startswith?: Maybe<Scalars['String']>;
  cardType_Istartswith?: Maybe<Scalars['String']>;
  cardType_Endswith?: Maybe<Scalars['String']>;
  cardType_Iendswith?: Maybe<Scalars['String']>;
  cardType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregationProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationProcessedAt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregationFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedAt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregationKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  idForPublicRetrieval_Isnull?: Maybe<Scalars['Boolean']>;
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  sentSmses_Isnull?: Maybe<Scalars['Boolean']>;
  sentSmses?: Maybe<SmsInputFilterObject>;
  policyId?: Maybe<Scalars['String']>;
};

export type QueryPersonalCardArgs = {
  id: Scalars['ID'];
};

export type QueryPersonalCardsArgs = {
  beneficiaryPayments_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryPayments?: Maybe<BeneficiaryPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  proxyId_Isnull?: Maybe<Scalars['Boolean']>;
  proxyId?: Maybe<Scalars['String']>;
  proxyId_Iexact?: Maybe<Scalars['String']>;
  proxyId_Contains?: Maybe<Scalars['String']>;
  proxyId_Icontains?: Maybe<Scalars['String']>;
  proxyId_Startswith?: Maybe<Scalars['String']>;
  proxyId_Istartswith?: Maybe<Scalars['String']>;
  proxyId_Endswith?: Maybe<Scalars['String']>;
  proxyId_Iendswith?: Maybe<Scalars['String']>;
  proxyId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  policyHolder_Isnull?: Maybe<Scalars['Boolean']>;
  policyHolder?: Maybe<PolicyHolderInputFilterObject>;
  default_Isnull?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  active_Isnull?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type QueryAddressArgs = {
  id: Scalars['ID'];
};

export type QueryAddressesArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  streetName_Isnull?: Maybe<Scalars['Boolean']>;
  streetName?: Maybe<Scalars['String']>;
  streetName_Iexact?: Maybe<Scalars['String']>;
  streetName_Contains?: Maybe<Scalars['String']>;
  streetName_Icontains?: Maybe<Scalars['String']>;
  streetName_Startswith?: Maybe<Scalars['String']>;
  streetName_Istartswith?: Maybe<Scalars['String']>;
  streetName_Endswith?: Maybe<Scalars['String']>;
  streetName_Iendswith?: Maybe<Scalars['String']>;
  streetName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  streetNumber_Isnull?: Maybe<Scalars['Boolean']>;
  streetNumber?: Maybe<Scalars['String']>;
  streetNumber_Iexact?: Maybe<Scalars['String']>;
  streetNumber_Contains?: Maybe<Scalars['String']>;
  streetNumber_Icontains?: Maybe<Scalars['String']>;
  streetNumber_Startswith?: Maybe<Scalars['String']>;
  streetNumber_Istartswith?: Maybe<Scalars['String']>;
  streetNumber_Endswith?: Maybe<Scalars['String']>;
  streetNumber_Iendswith?: Maybe<Scalars['String']>;
  streetNumber_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressRefinement_Isnull?: Maybe<Scalars['Boolean']>;
  addressRefinement?: Maybe<Scalars['String']>;
  addressRefinement_Iexact?: Maybe<Scalars['String']>;
  addressRefinement_Contains?: Maybe<Scalars['String']>;
  addressRefinement_Icontains?: Maybe<Scalars['String']>;
  addressRefinement_Startswith?: Maybe<Scalars['String']>;
  addressRefinement_Istartswith?: Maybe<Scalars['String']>;
  addressRefinement_Endswith?: Maybe<Scalars['String']>;
  addressRefinement_Iendswith?: Maybe<Scalars['String']>;
  addressRefinement_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  zipCode_Isnull?: Maybe<Scalars['Boolean']>;
  zipCode?: Maybe<Scalars['String']>;
  zipCode_Iexact?: Maybe<Scalars['String']>;
  zipCode_Contains?: Maybe<Scalars['String']>;
  zipCode_Icontains?: Maybe<Scalars['String']>;
  zipCode_Startswith?: Maybe<Scalars['String']>;
  zipCode_Istartswith?: Maybe<Scalars['String']>;
  zipCode_Endswith?: Maybe<Scalars['String']>;
  zipCode_Iendswith?: Maybe<Scalars['String']>;
  zipCode_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  city_Isnull?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  city_Iexact?: Maybe<Scalars['String']>;
  city_Contains?: Maybe<Scalars['String']>;
  city_Icontains?: Maybe<Scalars['String']>;
  city_Startswith?: Maybe<Scalars['String']>;
  city_Istartswith?: Maybe<Scalars['String']>;
  city_Endswith?: Maybe<Scalars['String']>;
  city_Iendswith?: Maybe<Scalars['String']>;
  city_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_Isnull?: Maybe<Scalars['Boolean']>;
  country?: Maybe<CountryInputFilterObject>;
  region_Isnull?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Scalars['String']>;
  region_Iexact?: Maybe<Scalars['String']>;
  region_Contains?: Maybe<Scalars['String']>;
  region_Icontains?: Maybe<Scalars['String']>;
  region_Startswith?: Maybe<Scalars['String']>;
  region_Istartswith?: Maybe<Scalars['String']>;
  region_Endswith?: Maybe<Scalars['String']>;
  region_Iendswith?: Maybe<Scalars['String']>;
  region_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw_Isnull?: Maybe<Scalars['Boolean']>;
  raw?: Maybe<Scalars['String']>;
  raw_Iexact?: Maybe<Scalars['String']>;
  raw_Contains?: Maybe<Scalars['String']>;
  raw_Icontains?: Maybe<Scalars['String']>;
  raw_Startswith?: Maybe<Scalars['String']>;
  raw_Istartswith?: Maybe<Scalars['String']>;
  raw_Endswith?: Maybe<Scalars['String']>;
  raw_Iendswith?: Maybe<Scalars['String']>;
  raw_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryBeneficiaryBenefitUsageArgs = {
  id: Scalars['ID'];
};

export type QueryBeneficiaryBenefitUsagesArgs = {
  claims_Isnull?: Maybe<Scalars['Boolean']>;
  claims?: Maybe<ClaimInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  sharedUsage_Isnull?: Maybe<Scalars['Boolean']>;
  sharedUsage?: Maybe<BenefitUsageInputFilterObject>;
  CurrentCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentCount?: Maybe<Scalars['Int']>;
  CurrentCount_Gt?: Maybe<Scalars['Int']>;
  CurrentCount_Gte?: Maybe<Scalars['Int']>;
  CurrentCount_Lt?: Maybe<Scalars['Int']>;
  CurrentCount_Lte?: Maybe<Scalars['Int']>;
  CurrentCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  CurrentAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmount?: Maybe<Scalars['Float']>;
  CurrentAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  CurrentAmountCovered_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  CurrentPendingCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  CurrentPendingAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ConsumedExcess_Isnull?: Maybe<Scalars['Boolean']>;
  ConsumedExcess?: Maybe<Scalars['Float']>;
  ConsumedExcess_Gt?: Maybe<Scalars['Float']>;
  ConsumedExcess_Gte?: Maybe<Scalars['Float']>;
  ConsumedExcess_Lt?: Maybe<Scalars['Float']>;
  ConsumedExcess_Lte?: Maybe<Scalars['Float']>;
  ConsumedExcess_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryPolicyArgs = {
  id: Scalars['ID'];
};

export type QueryPoliciesArgs = {
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BenefitUsageInputFilterObject>;
  sessions_Isnull?: Maybe<Scalars['Boolean']>;
  sessions?: Maybe<MobileAppSessionInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  chain_Isnull?: Maybe<Scalars['Boolean']>;
  chain?: Maybe<PolicyChainInputFilterObject>;
  productVersion_Isnull?: Maybe<Scalars['Boolean']>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['DateTime']>;
  expiration_Gt?: Maybe<Scalars['DateTime']>;
  expiration_Gte?: Maybe<Scalars['DateTime']>;
  expiration_Lt?: Maybe<Scalars['DateTime']>;
  expiration_Lte?: Maybe<Scalars['DateTime']>;
  expiration_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  beneficiaries_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaries?: Maybe<BeneficiaryInputFilterObject>;
  externalId_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryBeneficiaryArgs = {
  id: Scalars['ID'];
};

export type QueryBeneficiariesArgs = {
  dependants_Isnull?: Maybe<Scalars['Boolean']>;
  dependants?: Maybe<BeneficiaryInputFilterObject>;
  policyHolder_Isnull?: Maybe<Scalars['Boolean']>;
  policyHolder?: Maybe<PolicyHolderInputFilterObject>;
  policies_Isnull?: Maybe<Scalars['Boolean']>;
  policies?: Maybe<PolicyInputFilterObject>;
  usages_Isnull?: Maybe<Scalars['Boolean']>;
  usages?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedClaims_Isnull?: Maybe<Scalars['Boolean']>;
  requestedClaims?: Maybe<ClaimInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  currentCardsForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentCardsForPayments?: Maybe<CardInputFilterObject>;
  cardActions_Isnull?: Maybe<Scalars['Boolean']>;
  cardActions?: Maybe<CardActionInputFilterObject>;
  mobileAppUser_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppUser?: Maybe<MobileAppUserInputFilterObject>;
  paymentRulesOverrides_Isnull?: Maybe<Scalars['Boolean']>;
  paymentRulesOverrides?: Maybe<PaymentRulesOverrideInputFilterObject>;
  unlocks_Isnull?: Maybe<Scalars['Boolean']>;
  unlocks?: Maybe<BeneficiaryDataUnlockInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive_Isnull?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  memberSince_Isnull?: Maybe<Scalars['Boolean']>;
  memberSince?: Maybe<Scalars['Date']>;
  memberSince_Gt?: Maybe<Scalars['Date']>;
  memberSince_Gte?: Maybe<Scalars['Date']>;
  memberSince_Lt?: Maybe<Scalars['Date']>;
  memberSince_Lte?: Maybe<Scalars['Date']>;
  memberSince_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  customerUuid_Isnull?: Maybe<Scalars['Boolean']>;
  customerUuid?: Maybe<Scalars['UUID']>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  mobileAppAllowed_Isnull?: Maybe<Scalars['Boolean']>;
  mobileAppAllowed?: Maybe<Scalars['Boolean']>;
  cardAllowed_Isnull?: Maybe<Scalars['Boolean']>;
  cardAllowed?: Maybe<Scalars['Boolean']>;
  token_Isnull?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  token_Iexact?: Maybe<Scalars['String']>;
  token_Contains?: Maybe<Scalars['String']>;
  token_Icontains?: Maybe<Scalars['String']>;
  token_Startswith?: Maybe<Scalars['String']>;
  token_Istartswith?: Maybe<Scalars['String']>;
  token_Endswith?: Maybe<Scalars['String']>;
  token_Iendswith?: Maybe<Scalars['String']>;
  token_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  parents_Isnull?: Maybe<Scalars['Boolean']>;
  parents?: Maybe<BeneficiaryInputFilterObject>;
  beneficiaries?: Maybe<BeneficiaryInputFilterObject>;
  customer?: Maybe<CustomerInputType>;
};

export type QueryCorporationArgs = {
  id: Scalars['ID'];
};

export type QueryCorporationsArgs = {
  chains_Isnull?: Maybe<Scalars['Boolean']>;
  chains?: Maybe<CorporatePolicyChainInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyName_Isnull?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Scalars['String']>;
  companyName_Iexact?: Maybe<Scalars['String']>;
  companyName_Contains?: Maybe<Scalars['String']>;
  companyName_Icontains?: Maybe<Scalars['String']>;
  companyName_Startswith?: Maybe<Scalars['String']>;
  companyName_Istartswith?: Maybe<Scalars['String']>;
  companyName_Endswith?: Maybe<Scalars['String']>;
  companyName_Iendswith?: Maybe<Scalars['String']>;
  companyName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_Isnull?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  address_Iexact?: Maybe<Scalars['String']>;
  address_Contains?: Maybe<Scalars['String']>;
  address_Icontains?: Maybe<Scalars['String']>;
  address_Startswith?: Maybe<Scalars['String']>;
  address_Istartswith?: Maybe<Scalars['String']>;
  address_Endswith?: Maybe<Scalars['String']>;
  address_Iendswith?: Maybe<Scalars['String']>;
  address_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactName_Isnull?: Maybe<Scalars['Boolean']>;
  contactName?: Maybe<Scalars['String']>;
  contactName_Iexact?: Maybe<Scalars['String']>;
  contactName_Contains?: Maybe<Scalars['String']>;
  contactName_Icontains?: Maybe<Scalars['String']>;
  contactName_Startswith?: Maybe<Scalars['String']>;
  contactName_Istartswith?: Maybe<Scalars['String']>;
  contactName_Endswith?: Maybe<Scalars['String']>;
  contactName_Iendswith?: Maybe<Scalars['String']>;
  contactName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactEmail_Isnull?: Maybe<Scalars['Boolean']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactEmail_Iexact?: Maybe<Scalars['String']>;
  contactEmail_Contains?: Maybe<Scalars['String']>;
  contactEmail_Icontains?: Maybe<Scalars['String']>;
  contactEmail_Startswith?: Maybe<Scalars['String']>;
  contactEmail_Istartswith?: Maybe<Scalars['String']>;
  contactEmail_Endswith?: Maybe<Scalars['String']>;
  contactEmail_Iendswith?: Maybe<Scalars['String']>;
  contactEmail_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactPhone_Isnull?: Maybe<Scalars['Boolean']>;
  contactPhone?: Maybe<Scalars['String']>;
  contactPhone_Iexact?: Maybe<Scalars['String']>;
  contactPhone_Contains?: Maybe<Scalars['String']>;
  contactPhone_Icontains?: Maybe<Scalars['String']>;
  contactPhone_Startswith?: Maybe<Scalars['String']>;
  contactPhone_Istartswith?: Maybe<Scalars['String']>;
  contactPhone_Endswith?: Maybe<Scalars['String']>;
  contactPhone_Iendswith?: Maybe<Scalars['String']>;
  contactPhone_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  corporations?: Maybe<CorporationInputFilterObject>;
};

export type QueryPolicyHolderArgs = {
  id: Scalars['ID'];
};

export type QueryPolicyHoldersArgs = {
  chains_Isnull?: Maybe<Scalars['Boolean']>;
  chains?: Maybe<PolicyChainInputFilterObject>;
  personalCards_Isnull?: Maybe<Scalars['Boolean']>;
  personalCards?: Maybe<PersonalCardInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  customerUuid_Isnull?: Maybe<Scalars['Boolean']>;
  customerUuid?: Maybe<Scalars['UUID']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Scalars['String']>;
  holders?: Maybe<PolicyHolderInputFilterObject>;
  customer?: Maybe<CustomerInputType>;
};

export type QueryBenefitUsageArgs = {
  id: Scalars['ID'];
};

export type QueryBenefitUsagesArgs = {
  memberUsages_Isnull?: Maybe<Scalars['Boolean']>;
  memberUsages?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  benefit_Isnull?: Maybe<Scalars['Boolean']>;
  benefit?: Maybe<BenefitInputFilterObject>;
  policy_Isnull?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<PolicyInputFilterObject>;
  CurrentCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentCount?: Maybe<Scalars['Int']>;
  CurrentCount_Gt?: Maybe<Scalars['Int']>;
  CurrentCount_Gte?: Maybe<Scalars['Int']>;
  CurrentCount_Lt?: Maybe<Scalars['Int']>;
  CurrentCount_Lte?: Maybe<Scalars['Int']>;
  CurrentCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  CurrentAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmount?: Maybe<Scalars['Float']>;
  CurrentAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  CurrentAmountCovered_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentAmountCovered?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Gte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lt?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_Lte?: Maybe<Scalars['Float']>;
  CurrentAmountCovered_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  CurrentPendingCount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingCount?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Gte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lt?: Maybe<Scalars['Int']>;
  CurrentPendingCount_Lte?: Maybe<Scalars['Int']>;
  CurrentPendingCount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  CurrentPendingAmount_Isnull?: Maybe<Scalars['Boolean']>;
  CurrentPendingAmount?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Gte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lt?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_Lte?: Maybe<Scalars['Float']>;
  CurrentPendingAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryPolicyChainArgs = {
  id: Scalars['ID'];
};

export type QueryPolicyChainsArgs = {
  policies_Isnull?: Maybe<Scalars['Boolean']>;
  policies?: Maybe<PolicyInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_Isnull?: Maybe<Scalars['Boolean']>;
  product?: Maybe<ProductInputFilterObject>;
  corporatePolicyChain_Isnull?: Maybe<Scalars['Boolean']>;
  corporatePolicyChain?: Maybe<CorporatePolicyChainInputFilterObject>;
  holders_Isnull?: Maybe<Scalars['Boolean']>;
  holders?: Maybe<PolicyHolderInputFilterObject>;
  corporations?: Maybe<CorporationInputFilterObject>;
  corporate?: Maybe<Scalars['Boolean']>;
};

export type QueryCorporatePolicyChainArgs = {
  id: Scalars['ID'];
};

export type QueryCorporatePolicyChainsArgs = {
  chains_Isnull?: Maybe<Scalars['Boolean']>;
  chains?: Maybe<PolicyChainInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalId_Isnull?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_Iexact?: Maybe<Scalars['String']>;
  externalId_Contains?: Maybe<Scalars['String']>;
  externalId_Icontains?: Maybe<Scalars['String']>;
  externalId_Startswith?: Maybe<Scalars['String']>;
  externalId_Istartswith?: Maybe<Scalars['String']>;
  externalId_Endswith?: Maybe<Scalars['String']>;
  externalId_Iendswith?: Maybe<Scalars['String']>;
  externalId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  corporations_Isnull?: Maybe<Scalars['Boolean']>;
  corporations?: Maybe<CorporationInputFilterObject>;
};

export type QueryClaimArgs = {
  id: Scalars['ID'];
};

export type QueryClaimsArgs = {
  claimicdcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<ClaimIcdCodeInputFilterObject>;
  claimcptcode_Isnull?: Maybe<Scalars['Boolean']>;
  claimcptcode?: Maybe<ClaimCptCodeInputFilterObject>;
  breakdown_Isnull?: Maybe<Scalars['Boolean']>;
  breakdown?: Maybe<ClaimBreakdownInputFilterObject>;
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  receipts_Isnull?: Maybe<Scalars['Boolean']>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  defaultForCards_Isnull?: Maybe<Scalars['Boolean']>;
  defaultForCards?: Maybe<CardInputFilterObject>;
  aggregatedPreauths_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedPreauths?: Maybe<AggregatedPreauthInputFilterObject>;
  payments_Isnull?: Maybe<Scalars['Boolean']>;
  payments?: Maybe<PaymentInputFilterObject>;
  paymentAuthorizations_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizations?: Maybe<PaymentAuthorizationInputFilterObject>;
  account_Isnull?: Maybe<Scalars['Boolean']>;
  account?: Maybe<AccountInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  merchant_Isnull?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantInputFilterObject>;
  beneficiaryBenefitUsage_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiaryBenefitUsage?: Maybe<BeneficiaryBenefitUsageInputFilterObject>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
  retroactive_Isnull?: Maybe<Scalars['Boolean']>;
  retroactive?: Maybe<Scalars['Boolean']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  maxAmount_Isnull?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Float']>;
  maxAmount_Gt?: Maybe<Scalars['Float']>;
  maxAmount_Gte?: Maybe<Scalars['Float']>;
  maxAmount_Lt?: Maybe<Scalars['Float']>;
  maxAmount_Lte?: Maybe<Scalars['Float']>;
  maxAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  eligibleAmount_Isnull?: Maybe<Scalars['Boolean']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  eligibleAmount_Gt?: Maybe<Scalars['Float']>;
  eligibleAmount_Gte?: Maybe<Scalars['Float']>;
  eligibleAmount_Lt?: Maybe<Scalars['Float']>;
  eligibleAmount_Lte?: Maybe<Scalars['Float']>;
  eligibleAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionAmount_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAmount?: Maybe<Scalars['Float']>;
  transactionAmount_Gt?: Maybe<Scalars['Float']>;
  transactionAmount_Gte?: Maybe<Scalars['Float']>;
  transactionAmount_Lt?: Maybe<Scalars['Float']>;
  transactionAmount_Lte?: Maybe<Scalars['Float']>;
  transactionAmount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDummy_Isnull?: Maybe<Scalars['Boolean']>;
  isDummy?: Maybe<Scalars['Boolean']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  allowTransferringToFuture_Isnull?: Maybe<Scalars['Boolean']>;
  allowTransferringToFuture?: Maybe<Scalars['Boolean']>;
  additionalInformation_Isnull?: Maybe<Scalars['Boolean']>;
  additionalInformation?: Maybe<Scalars['String']>;
  created_Isnull?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  created_Gt?: Maybe<Scalars['DateTime']>;
  created_Gte?: Maybe<Scalars['DateTime']>;
  created_Lt?: Maybe<Scalars['DateTime']>;
  created_Lte?: Maybe<Scalars['DateTime']>;
  created_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  updated_Isnull?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['DateTime']>;
  updated_Gt?: Maybe<Scalars['DateTime']>;
  updated_Gte?: Maybe<Scalars['DateTime']>;
  updated_Lt?: Maybe<Scalars['DateTime']>;
  updated_Lte?: Maybe<Scalars['DateTime']>;
  updated_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  referenceId_Isnull?: Maybe<Scalars['Boolean']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceId_Iexact?: Maybe<Scalars['String']>;
  referenceId_Contains?: Maybe<Scalars['String']>;
  referenceId_Icontains?: Maybe<Scalars['String']>;
  referenceId_Startswith?: Maybe<Scalars['String']>;
  referenceId_Istartswith?: Maybe<Scalars['String']>;
  referenceId_Endswith?: Maybe<Scalars['String']>;
  referenceId_Iendswith?: Maybe<Scalars['String']>;
  referenceId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  icdCodes_Isnull?: Maybe<Scalars['Boolean']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes_Isnull?: Maybe<Scalars['Boolean']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
  isStagnant?: Maybe<Scalars['Boolean']>;
  isInstant?: Maybe<Scalars['Boolean']>;
  benefitCategory?: Maybe<BenefitCategoryInputFilterObject>;
  benefit?: Maybe<BenefitInputFilterObject>;
  policy?: Maybe<PolicyInputFilterObject>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  beneficiaryIdWithIncludedDependants?: Maybe<Scalars['String']>;
  beneficiaryIdInWithIncludedDependants?: Maybe<Scalars['String']>;
  source_Not_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardState?: Maybe<Scalars['String']>;
  codesExclusion?: Maybe<CodesExclusionInputFilterObject>;
};

export type QueryClaimIcdCodeArgs = {
  id: Scalars['ID'];
};

export type QueryClaimIcdCodesArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  icdCode_Isnull?: Maybe<Scalars['Boolean']>;
  icdCode?: Maybe<IcdCodeInputFilterObject>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  default_Isnull?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  provider_Isnull?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<ProviderInputFilterObject>;
};

export type QueryClaimCptCodeArgs = {
  id: Scalars['ID'];
};

export type QueryClaimCptCodesArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cptCode_Isnull?: Maybe<Scalars['Boolean']>;
  cptCode?: Maybe<CptCodeInputFilterObject>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  default_Isnull?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
};

export type QueryClaimBreakdownArgs = {
  id: Scalars['ID'];
};

export type QueryClaimBreakdownsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  perClaimExcess_Isnull?: Maybe<Scalars['Boolean']>;
  perClaimExcess?: Maybe<Scalars['Float']>;
  perClaimExcess_Gt?: Maybe<Scalars['Float']>;
  perClaimExcess_Gte?: Maybe<Scalars['Float']>;
  perClaimExcess_Lt?: Maybe<Scalars['Float']>;
  perClaimExcess_Lte?: Maybe<Scalars['Float']>;
  perClaimExcess_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  policyWideExcess_Isnull?: Maybe<Scalars['Boolean']>;
  policyWideExcess?: Maybe<Scalars['Float']>;
  policyWideExcess_Gt?: Maybe<Scalars['Float']>;
  policyWideExcess_Gte?: Maybe<Scalars['Float']>;
  policyWideExcess_Lt?: Maybe<Scalars['Float']>;
  policyWideExcess_Lte?: Maybe<Scalars['Float']>;
  policyWideExcess_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  benefitLevelExcess_Isnull?: Maybe<Scalars['Boolean']>;
  benefitLevelExcess?: Maybe<Scalars['Float']>;
  benefitLevelExcess_Gt?: Maybe<Scalars['Float']>;
  benefitLevelExcess_Gte?: Maybe<Scalars['Float']>;
  benefitLevelExcess_Lt?: Maybe<Scalars['Float']>;
  benefitLevelExcess_Lte?: Maybe<Scalars['Float']>;
  benefitLevelExcess_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  coPayment_Isnull?: Maybe<Scalars['Boolean']>;
  coPayment?: Maybe<Scalars['Float']>;
  coPayment_Gt?: Maybe<Scalars['Float']>;
  coPayment_Gte?: Maybe<Scalars['Float']>;
  coPayment_Lt?: Maybe<Scalars['Float']>;
  coPayment_Lte?: Maybe<Scalars['Float']>;
  coPayment_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  covered_Isnull?: Maybe<Scalars['Boolean']>;
  covered?: Maybe<Scalars['Float']>;
  covered_Gt?: Maybe<Scalars['Float']>;
  covered_Gte?: Maybe<Scalars['Float']>;
  covered_Lt?: Maybe<Scalars['Float']>;
  covered_Lte?: Maybe<Scalars['Float']>;
  covered_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
};

export type QueryReceiptArgs = {
  id: Scalars['ID'];
};

export type QueryReceiptsArgs = {
  items_Isnull?: Maybe<Scalars['Boolean']>;
  items?: Maybe<ReceiptItemInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  paymentAuthorizationId_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizationId?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iexact?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Contains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Icontains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Startswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Istartswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Endswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iendswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionAt_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gte?: Maybe<Scalars['DateTime']>;
  transactionAt_Lt?: Maybe<Scalars['DateTime']>;
  transactionAt_Lte?: Maybe<Scalars['DateTime']>;
  transactionAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuerySupportingDocumentArgs = {
  id: Scalars['ID'];
};

export type QuerySupportingDocumentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
};

export type QueryReceiptItemArgs = {
  id: Scalars['ID'];
};

export type QueryReceiptItemsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  receipt_Isnull?: Maybe<Scalars['Boolean']>;
  receipt?: Maybe<ReceiptInputFilterObject>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAccepted_Isnull?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  icd_Isnull?: Maybe<Scalars['Boolean']>;
  icd?: Maybe<IcdCodeInputFilterObject>;
};

export type QueryCodesExclusionArgs = {
  id: Scalars['ID'];
};

export type QueryCodesExclusionsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['Int']>;
  entityId_Gt?: Maybe<Scalars['Int']>;
  entityId_Gte?: Maybe<Scalars['Int']>;
  entityId_Lt?: Maybe<Scalars['Int']>;
  entityId_Lte?: Maybe<Scalars['Int']>;
  entityId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  icdCodes_Isnull?: Maybe<Scalars['Boolean']>;
  icdCodes?: Maybe<IcdCodeInputFilterObject>;
  cptCodes_Isnull?: Maybe<Scalars['Boolean']>;
  cptCodes?: Maybe<CptCodeInputFilterObject>;
};

export type ReadChangeInput = {
  id?: Maybe<Scalars['ID']>;
  read?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum ReceiptFileTypeEnum {
  /** Final Bill */
  FinalBill = 'FINAL_BILL',
  /** Other */
  Other = 'OTHER'
}

/** An enumeration. */
export enum ReceiptFileTypeEnumCreate {
  /** Final Bill */
  FinalBill = 'FINAL_BILL',
  /** Other */
  Other = 'OTHER'
}

/** An enumeration. */
export enum ReceiptFileTypeEnumUpdate {
  /** Final Bill */
  FinalBill = 'FINAL_BILL',
  /** Other */
  Other = 'OTHER'
}

export type ReceiptInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** source */
  source?: Maybe<Scalars['String']>;
  /** source */
  source_Icontains?: Maybe<Scalars['String']>;
  /** (('ACCEPTED', 'Accepted'), ('DECLINED', 'Declined'), ('REQUESTED_NEW', 'Requested New')) */
  state?: Maybe<Scalars['String']>;
  /** (('ACCEPTED', 'Accepted'), ('DECLINED', 'Declined'), ('REQUESTED_NEW', 'Requested New')) */
  state_Icontains?: Maybe<Scalars['String']>;
  /**  reason */
  Reason?: Maybe<Scalars['String']>;
  /**  reason */
  Reason_Icontains?: Maybe<Scalars['String']>;
  /** uploaded at */
  uploadedAt?: Maybe<Scalars['CustomDateTime']>;
  /** uploaded at */
  uploadedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** uploaded at */
  uploadedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** uploaded at */
  uploadedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** uploaded at */
  uploadedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** file */
  file?: Maybe<Scalars['String']>;
  /** file */
  file_Icontains?: Maybe<Scalars['String']>;
  /** filename */
  filename?: Maybe<Scalars['String']>;
  /** filename */
  filename_Icontains?: Maybe<Scalars['String']>;
  /**  resolved at */
  ResolvedAt?: Maybe<Scalars['CustomDateTime']>;
  /**  resolved at */
  ResolvedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /**  resolved at */
  ResolvedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /**  resolved at */
  ResolvedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /**  resolved at */
  ResolvedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** external payment authorization_id */
  paymentAuthorizationId?: Maybe<Scalars['String']>;
  /** external payment authorization_id */
  paymentAuthorizationId_Icontains?: Maybe<Scalars['String']>;
  /** fx rate */
  fxRate?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Gt?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Lt?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Gte?: Maybe<Scalars['Float']>;
  /** fx rate */
  fxRate_Lte?: Maybe<Scalars['Float']>;
  /** transaction at */
  transactionAt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction at */
  transactionAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction at */
  transactionAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** transaction at */
  transactionAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** transaction at */
  transactionAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** file type */
  fileType?: Maybe<Scalars['String']>;
  /** file type */
  fileType_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  Reason_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  file_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  filename_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ResolvedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  paymentAuthorizationId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fxRate_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  transactionAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  fileType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim?: Maybe<ClaimInputFilterObject>;
  user?: Maybe<UserInputFilterObject>;
  currency?: Maybe<CurrencyInputFilterObject>;
  ResolvedBy?: Maybe<UserInputFilterObject>;
  items?: Maybe<ReceiptItemInputFilterObject>;
};

export type ReceiptItemInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** title */
  title?: Maybe<Scalars['String']>;
  /** title */
  title_Icontains?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  title_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  isAccepted_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  receipt?: Maybe<ReceiptInputFilterObject>;
  icd?: Maybe<IcdCodeInputFilterObject>;
};

export type ReceiptItemListType = {
  __typename?: 'ReceiptItemListType';
  /** ReceiptItem list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ReceiptItemType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ReceiptItemListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ReceiptItemMutation = {
  __typename?: 'ReceiptItemMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  receiptitem?: Maybe<ReceiptItemType>;
};

export type ReceiptItemType = {
  __typename?: 'ReceiptItemType';
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** icd */
  icd?: Maybe<IcdCodeType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  isAccepted?: Maybe<Scalars['Boolean']>;
  /** receipt */
  receipt?: Maybe<ReceiptType>;
  /** title */
  title?: Maybe<Scalars['String']>;
};

export type ReceiptListType = {
  __typename?: 'ReceiptListType';
  /** Receipt list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<ReceiptType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type ReceiptListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ReceiptMutation = {
  __typename?: 'ReceiptMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  receipt?: Maybe<ReceiptType>;
};

/** An enumeration. */
export enum ReceiptSourceEnum {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

/** An enumeration. */
export enum ReceiptSourceEnumCreate {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

/** An enumeration. */
export enum ReceiptSourceEnumUpdate {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

/**
 * Actions
 * ------
 *  - create: 'file' need to be provided in the request as a file payload
 */
export type ReceiptType = {
  __typename?: 'ReceiptType';
  /** claim */
  claim?: Maybe<ClaimType>;
  /** currency */
  currency?: Maybe<CurrencyType>;
  file?: Maybe<Scalars['String']>;
  /** file type */
  fileType?: Maybe<ReceiptFileTypeEnum>;
  /** filename */
  filename?: Maybe<Scalars['String']>;
  /** fx rate */
  fxRate?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** ReceiptItem list */
  items?: Maybe<Array<Maybe<ReceiptItemType>>>;
  /** external payment authorization_id */
  paymentAuthorizationId?: Maybe<Scalars['String']>;
  /** source */
  source?: Maybe<ReceiptSourceEnum>;
  /** (('ACCEPTED', 'Accepted'), ('DECLINED', 'Declined'), ('REQUESTED_NEW', 'Requested New')) */
  state?: Maybe<Scalars['String']>;
  /** transaction at */
  transactionAt?: Maybe<Scalars['CustomDateTime']>;
  /** uploaded at */
  uploadedAt?: Maybe<Scalars['CustomDateTime']>;
  /** user */
  user?: Maybe<UserType>;
  thumbnail?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  resolvedBy?: Maybe<UserType>;
  resolvedAt?: Maybe<Scalars['String']>;
  transactionFxRate?: Maybe<Scalars['DecimalScalar']>;
  acceptedOriginAmount?: Maybe<Scalars['Float']>;
  acceptedAmount?: Maybe<Scalars['Float']>;
};

/**
 * Actions
 * ------
 *  - create: 'file' need to be provided in the request as a file payload
 */
export type ReceiptTypeItemsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  receipt_Isnull?: Maybe<Scalars['Boolean']>;
  receipt?: Maybe<ReceiptInputFilterObject>;
  title_Isnull?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_Iexact?: Maybe<Scalars['String']>;
  title_Contains?: Maybe<Scalars['String']>;
  title_Icontains?: Maybe<Scalars['String']>;
  title_Startswith?: Maybe<Scalars['String']>;
  title_Istartswith?: Maybe<Scalars['String']>;
  title_Endswith?: Maybe<Scalars['String']>;
  title_Iendswith?: Maybe<Scalars['String']>;
  title_In?: Maybe<Scalars['String']>;
  amount_Isnull?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['Float']>;
  amount_Gt?: Maybe<Scalars['Float']>;
  amount_Gte?: Maybe<Scalars['Float']>;
  amount_Lt?: Maybe<Scalars['Float']>;
  amount_Lte?: Maybe<Scalars['Float']>;
  amount_In?: Maybe<Scalars['Float']>;
  isAccepted_Isnull?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  icd_Isnull?: Maybe<Scalars['Boolean']>;
  icd?: Maybe<IcdCodeInputFilterObject>;
};

export type ResendCardInput = {
  id?: Maybe<Scalars['ID']>;
  email: Scalars['Boolean'];
  sms: Scalars['Boolean'];
};

export type Result = {
  __typename?: 'Result';
  totalCount?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PaymentInterface>>>;
};

export type RuleInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** rule type */
  ruleType?: Maybe<Scalars['String']>;
  /** rule type */
  ruleType_Icontains?: Maybe<Scalars['String']>;
  /** specification */
  specification?: Maybe<Scalars['JSONString']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  description_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ruleType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  specification_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ruleSet?: Maybe<RulesSetInputFilterObject>;
};

export type RuleListType = {
  __typename?: 'RuleListType';
  /** Rule list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<RuleType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type RuleListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type RuleMutation = {
  __typename?: 'RuleMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  rule?: Maybe<RuleType>;
};

export type RulesCardOverrideInputType = {
  cardProxyId: Scalars['String'];
  createdAt?: Maybe<Scalars['AutoParsedDateTime']>;
  comment?: Maybe<Scalars['String']>;
  requestedById?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['AutoParsedDateTime']>;
  validNTimes?: Maybe<Scalars['Int']>;
  override: CardRulesInputSelectionType;
};

export type RulesCardOverrideType = RulesOverride & {
  __typename?: 'RulesCardOverrideType';
  ruleName?: Maybe<Scalars['String']>;
  specification?: Maybe<RulesCardSpecification>;
};

export type RulesCardSpecification = {
  ruleName?: Maybe<Scalars['String']>;
};

export type RulesDisableOverrideInputType = {
  cardProxyId: Scalars['String'];
  createdAt?: Maybe<Scalars['AutoParsedDateTime']>;
  comment?: Maybe<Scalars['String']>;
  requestedById?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['AutoParsedDateTime']>;
  validNTimes?: Maybe<Scalars['Int']>;
  override: Array<Maybe<Scalars['String']>>;
};

export type RulesDisableOverrideType = RulesOverride & {
  __typename?: 'RulesDisableOverrideType';
  ruleName?: Maybe<Scalars['String']>;
  disabledRules: Array<Maybe<Scalars['String']>>;
};

export type RulesModificationOverrideInputType = {
  cardProxyId: Scalars['String'];
  createdAt?: Maybe<Scalars['AutoParsedDateTime']>;
  comment?: Maybe<Scalars['String']>;
  requestedById?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['AutoParsedDateTime']>;
  validNTimes?: Maybe<Scalars['Int']>;
  ruleName: Payment_Rule;
  override?: Maybe<Array<Maybe<MetaEntryInput>>>;
};

export type RulesModificationOverrideSpecificationType = {
  __typename?: 'RulesModificationOverrideSpecificationType';
  overridingRuleName?: Maybe<Payment_Rule>;
  params?: Maybe<Array<Maybe<MetaEntry>>>;
};

/**
 * This is pretty much placeholder when this functionality gets a "shape" and
 * we can make proper data structure
 */
export type RulesModificationOverrideType = RulesOverride & {
  __typename?: 'RulesModificationOverrideType';
  ruleName?: Maybe<Scalars['String']>;
  specification?: Maybe<RulesModificationOverrideSpecificationType>;
};

export type RulesOverride = {
  ruleName?: Maybe<Scalars['String']>;
};

export type RulesSetInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** name */
  name_Icontains?: Maybe<Scalars['String']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** description */
  description_Icontains?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  active_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  productVersion?: Maybe<ProductVersionInputFilterObject>;
  rules?: Maybe<RuleInputFilterObject>;
};

export type RulesSetListType = {
  __typename?: 'RulesSetListType';
  /** RulesSet list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<RulesSetType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type RulesSetListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type RulesSetMutation = {
  __typename?: 'RulesSetMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  rulesset?: Maybe<RulesSetType>;
};

export type RulesSetType = {
  __typename?: 'RulesSetType';
  active?: Maybe<Scalars['Boolean']>;
  /** description */
  description?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** name */
  name?: Maybe<Scalars['String']>;
  /** product version */
  productVersion?: Maybe<ProductVersionType>;
  /** Rule list */
  rules?: Maybe<Array<Maybe<RuleType>>>;
};

export type RulesSetTypeRulesArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ruleSet_Isnull?: Maybe<Scalars['Boolean']>;
  ruleSet?: Maybe<RulesSetInputFilterObject>;
  ruleType_Isnull?: Maybe<Scalars['Boolean']>;
  ruleType?: Maybe<Scalars['String']>;
  ruleType_Iexact?: Maybe<Scalars['String']>;
  ruleType_Contains?: Maybe<Scalars['String']>;
  ruleType_Icontains?: Maybe<Scalars['String']>;
  ruleType_Startswith?: Maybe<Scalars['String']>;
  ruleType_Istartswith?: Maybe<Scalars['String']>;
  ruleType_Endswith?: Maybe<Scalars['String']>;
  ruleType_Iendswith?: Maybe<Scalars['String']>;
  ruleType_In?: Maybe<Scalars['String']>;
  description_Isnull?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

export type RulesState = {
  __typename?: 'RulesState';
  policyId?: Maybe<Scalars['ID']>;
  ruleType?: Maybe<Scalars['String']>;
  triggered?: Maybe<Scalars['Boolean']>;
};

export type RuleType = {
  __typename?: 'RuleType';
  /** description */
  description?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** rule set */
  ruleSet?: Maybe<RulesSetType>;
  ruleType?: Maybe<Scalars['String']>;
  specification?: Maybe<RulesCardSpecification>;
};

/** Security method used (if applicable) when performing 3D secure transaction. */
export type SecurityMethod3dSecureRuleType = PaymentPlatformRuleType & {
  __typename?: 'SecurityMethod3dSecureRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Scalars['String']>;
};

/** Security protocol (usually HTTPS) used when sending the data into the payment network. */
export type SecurityProtocolRuleType = PaymentPlatformRuleType & {
  __typename?: 'SecurityProtocolRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Scalars['String']>;
};

export type SendDocumentToProviderInputType = {
  id?: Maybe<Scalars['ID']>;
};

export type SessionLocationStampInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** platform */
  platform?: Maybe<Scalars['String']>;
  /** platform */
  platform_Icontains?: Maybe<Scalars['String']>;
  /** timestamp */
  timestamp?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** timestamp */
  timestamp_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** gps latitude */
  gpsLatitude?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Gt?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Lt?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Gte?: Maybe<Scalars['Float']>;
  /** gps latitude */
  gpsLatitude_Lte?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Gt?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Lt?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Gte?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  platform_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  timestamp_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  gpsLatitude_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  gpsLongitude_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  user?: Maybe<MobileAppUserInputFilterObject>;
};

export type SessionLocationStampListType = {
  __typename?: 'SessionLocationStampListType';
  /** SessionLocationStamp list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<SessionLocationStampType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type SessionLocationStampListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum SessionLocationStampPlatformEnum {
  /** iOS */
  Ios = 'IOS',
  /** Android */
  Android = 'ANDROID'
}

export type SessionLocationStampType = {
  __typename?: 'SessionLocationStampType';
  /** gps latitude */
  gpsLatitude?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** platform */
  platform?: Maybe<SessionLocationStampPlatformEnum>;
  /** timestamp */
  timestamp?: Maybe<Scalars['CustomDateTime']>;
  /** user */
  user?: Maybe<MobileAppUserType>;
};

export type SettlementFundsInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** external ledger id */
  externalLedgerId?: Maybe<Scalars['String']>;
  /** external ledger id */
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  externalLedgerId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fundingAccount?: Maybe<FundingAccountInputFilterObject>;
  transactions?: Maybe<SettlementTransactionInputFilterObject>;
};

export type SettlementFundsType = {
  __typename?: 'SettlementFundsType';
  /** external ledger id */
  externalLedgerId?: Maybe<Scalars['String']>;
  /** funding account */
  fundingAccount?: Maybe<FundingAccountType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
};

export type SettlementTransactionInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** external transaction id */
  externalTransactionId?: Maybe<Scalars['String']>;
  /** external transaction id */
  externalTransactionId_Icontains?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** metadata */
  metadata?: Maybe<Scalars['JSONString']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  externalTransactionId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  metadata_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  settlementFunds?: Maybe<SettlementFundsInputFilterObject>;
  settlement?: Maybe<PaymentSettlementInputFilterObject>;
  settlementEvent?: Maybe<PaymentSettlementEventInputFilterObject>;
};

export type SetupPaymentCardInput = {
  customer: CardCustomerInputType;
  preAuthorization: CardPreAuthorizationInputType;
  merchant: CardMerchantInputType;
  cardSettings: CardPaymentCardInputType;
};

/** An enumeration. */
export enum Sex {
  F = 'F',
  M = 'M',
  O = 'O'
}

export type SmsInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  /** phone number */
  phoneNumber_Icontains?: Maybe<Scalars['String']>;
  /** body */
  body?: Maybe<Scalars['String']>;
  /** body */
  body_Icontains?: Maybe<Scalars['String']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  phoneNumber_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  body_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  sentSmses?: Maybe<SmsInputFilterObject>;
  cards?: Maybe<CardInputFilterObject>;
};

export type SmsType = {
  __typename?: 'SmsType';
  /** body */
  body?: Maybe<Scalars['String']>;
  /** Card list */
  cards?: Maybe<Array<Maybe<CardType>>>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SmsTypeCardsArgs = {
  actions_Isnull?: Maybe<Scalars['Boolean']>;
  actions?: Maybe<CardActionInputFilterObject>;
  aggregatedbeneficiarycard_Isnull?: Maybe<Scalars['Boolean']>;
  aggregatedbeneficiarycard?: Maybe<AggregatedBeneficiaryCardInputFilterObject>;
  insurerPayments_Isnull?: Maybe<Scalars['Boolean']>;
  insurerPayments?: Maybe<InsurerPaymentInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  proxyId_Isnull?: Maybe<Scalars['Boolean']>;
  proxyId?: Maybe<Scalars['String']>;
  proxyId_Iexact?: Maybe<Scalars['String']>;
  proxyId_Contains?: Maybe<Scalars['String']>;
  proxyId_Icontains?: Maybe<Scalars['String']>;
  proxyId_Startswith?: Maybe<Scalars['String']>;
  proxyId_Istartswith?: Maybe<Scalars['String']>;
  proxyId_Endswith?: Maybe<Scalars['String']>;
  proxyId_Iendswith?: Maybe<Scalars['String']>;
  proxyId_In?: Maybe<Scalars['String']>;
  beneficiary_Isnull?: Maybe<Scalars['Boolean']>;
  beneficiary?: Maybe<BeneficiaryInputFilterObject>;
  nameOnCard_Isnull?: Maybe<Scalars['Boolean']>;
  nameOnCard?: Maybe<Scalars['String']>;
  nameOnCard_Iexact?: Maybe<Scalars['String']>;
  nameOnCard_Contains?: Maybe<Scalars['String']>;
  nameOnCard_Icontains?: Maybe<Scalars['String']>;
  nameOnCard_Startswith?: Maybe<Scalars['String']>;
  nameOnCard_Istartswith?: Maybe<Scalars['String']>;
  nameOnCard_Endswith?: Maybe<Scalars['String']>;
  nameOnCard_Iendswith?: Maybe<Scalars['String']>;
  nameOnCard_In?: Maybe<Scalars['String']>;
  lastDigits_Isnull?: Maybe<Scalars['Boolean']>;
  lastDigits?: Maybe<Scalars['String']>;
  lastDigits_Iexact?: Maybe<Scalars['String']>;
  lastDigits_Contains?: Maybe<Scalars['String']>;
  lastDigits_Icontains?: Maybe<Scalars['String']>;
  lastDigits_Startswith?: Maybe<Scalars['String']>;
  lastDigits_Istartswith?: Maybe<Scalars['String']>;
  lastDigits_Endswith?: Maybe<Scalars['String']>;
  lastDigits_Iendswith?: Maybe<Scalars['String']>;
  lastDigits_In?: Maybe<Scalars['String']>;
  expiration_Isnull?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['String']>;
  expiration_Iexact?: Maybe<Scalars['String']>;
  expiration_Contains?: Maybe<Scalars['String']>;
  expiration_Icontains?: Maybe<Scalars['String']>;
  expiration_Startswith?: Maybe<Scalars['String']>;
  expiration_Istartswith?: Maybe<Scalars['String']>;
  expiration_Endswith?: Maybe<Scalars['String']>;
  expiration_Iendswith?: Maybe<Scalars['String']>;
  expiration_In?: Maybe<Scalars['String']>;
  policyChain_Isnull?: Maybe<Scalars['Boolean']>;
  policyChain?: Maybe<PolicyChainInputFilterObject>;
  isFrozen_Isnull?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isBlocked_Isnull?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeactivated_Isnull?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isCancelled_Isnull?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isIssued_Isnull?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered_Isnull?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  defaultClaim_Isnull?: Maybe<Scalars['Boolean']>;
  defaultClaim?: Maybe<ClaimInputFilterObject>;
  currentBeneficiaryForPayments_Isnull?: Maybe<Scalars['Boolean']>;
  currentBeneficiaryForPayments?: Maybe<BeneficiaryInputFilterObject>;
  cardType_Isnull?: Maybe<Scalars['Boolean']>;
  cardType?: Maybe<Scalars['String']>;
  cardType_Iexact?: Maybe<Scalars['String']>;
  cardType_Contains?: Maybe<Scalars['String']>;
  cardType_Icontains?: Maybe<Scalars['String']>;
  cardType_Startswith?: Maybe<Scalars['String']>;
  cardType_Istartswith?: Maybe<Scalars['String']>;
  cardType_Endswith?: Maybe<Scalars['String']>;
  cardType_Iendswith?: Maybe<Scalars['String']>;
  cardType_In?: Maybe<Scalars['String']>;
  aggregationProcessedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationProcessedAt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationProcessedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedAt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Gte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lt?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_Lte?: Maybe<Scalars['DateTime']>;
  aggregationFailedAt_In?: Maybe<Scalars['DateTime']>;
  aggregationKeepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount_Isnull?: Maybe<Scalars['Boolean']>;
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Gte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lt?: Maybe<Scalars['Int']>;
  aggregationFailedCount_Lte?: Maybe<Scalars['Int']>;
  aggregationFailedCount_In?: Maybe<Scalars['Int']>;
  idForPublicRetrieval_Isnull?: Maybe<Scalars['Boolean']>;
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  externalLedgerId_Isnull?: Maybe<Scalars['Boolean']>;
  externalLedgerId?: Maybe<Scalars['String']>;
  externalLedgerId_Iexact?: Maybe<Scalars['String']>;
  externalLedgerId_Contains?: Maybe<Scalars['String']>;
  externalLedgerId_Icontains?: Maybe<Scalars['String']>;
  externalLedgerId_Startswith?: Maybe<Scalars['String']>;
  externalLedgerId_Istartswith?: Maybe<Scalars['String']>;
  externalLedgerId_Endswith?: Maybe<Scalars['String']>;
  externalLedgerId_Iendswith?: Maybe<Scalars['String']>;
  externalLedgerId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  sentSmses_Isnull?: Maybe<Scalars['Boolean']>;
  sentSmses?: Maybe<SmsInputFilterObject>;
  policyId?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum Source {
  MobileApp = 'MOBILE_APP',
  InsurerDashboard = 'INSURER_DASHBOARD',
  PaymentPlatform = 'PAYMENT_PLATFORM',
  ImportScript = 'IMPORT_SCRIPT',
  VisaPortal = 'VISA_PORTAL'
}

export type StatisticsType = {
  __typename?: 'StatisticsType';
  timeSaved?: Maybe<Scalars['Int']>;
  insurerFundsAmounts?: Maybe<Array<Maybe<InsurerFundsAmount>>>;
  claimsAwaitingActionCount?: Maybe<ClaimsawaitingactioncountType>;
  claimsCount?: Maybe<ClaimscountType>;
  averageReviewDuration?: Maybe<AveragereviewdurationType>;
};

/** Checks whether the insurer has sufficient funds to cover the payment. */
export type SufficientFundsType = PaymentPlatformRuleType & {
  __typename?: 'SufficientFundsType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Float']>;
};

/** An enumeration. */
export enum SupportingDocumentFileTypeEnum {
  /** Guarantee Of Payment */
  GuaranteeOfPayment = 'GUARANTEE_OF_PAYMENT',
  /** Customer Agreement */
  CustomerAgreement = 'CUSTOMER_AGREEMENT',
  /** Signed Customer Agreement */
  SignedCustomerAgreement = 'SIGNED_CUSTOMER_AGREEMENT',
  /** Estimated Bill */
  EstimatedBill = 'ESTIMATED_BILL',
  /** Interim Bill */
  InterimBill = 'INTERIM_BILL',
  /** Other */
  Other = 'OTHER'
}

/** An enumeration. */
export enum SupportingDocumentFileTypeEnumCreate {
  /** Guarantee Of Payment */
  GuaranteeOfPayment = 'GUARANTEE_OF_PAYMENT',
  /** Customer Agreement */
  CustomerAgreement = 'CUSTOMER_AGREEMENT',
  /** Signed Customer Agreement */
  SignedCustomerAgreement = 'SIGNED_CUSTOMER_AGREEMENT',
  /** Estimated Bill */
  EstimatedBill = 'ESTIMATED_BILL',
  /** Interim Bill */
  InterimBill = 'INTERIM_BILL',
  /** Other */
  Other = 'OTHER'
}

/** An enumeration. */
export enum SupportingDocumentFileTypeEnumUpdate {
  /** Guarantee Of Payment */
  GuaranteeOfPayment = 'GUARANTEE_OF_PAYMENT',
  /** Customer Agreement */
  CustomerAgreement = 'CUSTOMER_AGREEMENT',
  /** Signed Customer Agreement */
  SignedCustomerAgreement = 'SIGNED_CUSTOMER_AGREEMENT',
  /** Estimated Bill */
  EstimatedBill = 'ESTIMATED_BILL',
  /** Interim Bill */
  InterimBill = 'INTERIM_BILL',
  /** Other */
  Other = 'OTHER'
}

export type SupportingDocumentInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** source */
  source?: Maybe<Scalars['String']>;
  /** source */
  source_Icontains?: Maybe<Scalars['String']>;
  /** (('ACCEPTED', 'Accepted'), ('DECLINED', 'Declined'), ('REQUESTED_NEW', 'Requested New')) */
  state?: Maybe<Scalars['String']>;
  /** (('ACCEPTED', 'Accepted'), ('DECLINED', 'Declined'), ('REQUESTED_NEW', 'Requested New')) */
  state_Icontains?: Maybe<Scalars['String']>;
  /**  reason */
  Reason?: Maybe<Scalars['String']>;
  /**  reason */
  Reason_Icontains?: Maybe<Scalars['String']>;
  /** uploaded at */
  uploadedAt?: Maybe<Scalars['CustomDateTime']>;
  /** uploaded at */
  uploadedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** uploaded at */
  uploadedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** uploaded at */
  uploadedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** uploaded at */
  uploadedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** file */
  file?: Maybe<Scalars['String']>;
  /** file */
  file_Icontains?: Maybe<Scalars['String']>;
  /** filename */
  filename?: Maybe<Scalars['String']>;
  /** filename */
  filename_Icontains?: Maybe<Scalars['String']>;
  /**  resolved at */
  ResolvedAt?: Maybe<Scalars['CustomDateTime']>;
  /**  resolved at */
  ResolvedAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /**  resolved at */
  ResolvedAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /**  resolved at */
  ResolvedAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /**  resolved at */
  ResolvedAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** file type */
  fileType?: Maybe<Scalars['String']>;
  /** file type */
  fileType_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  state_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  Reason_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  file_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  filename_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  ResolvedAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  fileType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claim?: Maybe<ClaimInputFilterObject>;
  user?: Maybe<UserInputFilterObject>;
  ResolvedBy?: Maybe<UserInputFilterObject>;
  sentEmails?: Maybe<EmailInputFilterObject>;
};

export type SupportingDocumentListType = {
  __typename?: 'SupportingDocumentListType';
  /** SupportingDocument list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<SupportingDocumentType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type SupportingDocumentListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type SupportingDocumentMutation = {
  __typename?: 'SupportingDocumentMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  supportingdocument?: Maybe<SupportingDocumentType>;
};

/** An enumeration. */
export enum SupportingDocumentSourceEnum {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

/** An enumeration. */
export enum SupportingDocumentSourceEnumCreate {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

/** An enumeration. */
export enum SupportingDocumentSourceEnumUpdate {
  /** Mobile App */
  MobileApp = 'MOBILE_APP',
  /** Insurer Dashboard */
  InsurerDashboard = 'INSURER_DASHBOARD',
  /** Payment Platform */
  PaymentPlatform = 'PAYMENT_PLATFORM',
  /** Import Script */
  ImportScript = 'IMPORT_SCRIPT',
  /** Visa Portal */
  VisaPortal = 'VISA_PORTAL'
}

export type SupportingDocumentType = {
  __typename?: 'SupportingDocumentType';
  /** claim */
  claim?: Maybe<ClaimType>;
  file?: Maybe<Scalars['String']>;
  /** file type */
  fileType?: Maybe<SupportingDocumentFileTypeEnum>;
  /** filename */
  filename?: Maybe<Scalars['String']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** Email list */
  sentEmails?: Maybe<Array<Maybe<EmailType>>>;
  /** source */
  source?: Maybe<SupportingDocumentSourceEnum>;
  /** (('ACCEPTED', 'Accepted'), ('DECLINED', 'Declined'), ('REQUESTED_NEW', 'Requested New')) */
  state?: Maybe<Scalars['String']>;
  /** uploaded at */
  uploadedAt?: Maybe<Scalars['CustomDateTime']>;
  /** user */
  user?: Maybe<UserType>;
  thumbnail?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  resolvedBy?: Maybe<UserType>;
  resolvedAt?: Maybe<Scalars['String']>;
};

export type SupportingDocumentTypeSentEmailsArgs = {
  supportingDocuments_Isnull?: Maybe<Scalars['Boolean']>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  cards_Isnull?: Maybe<Scalars['Boolean']>;
  cards?: Maybe<CardInputFilterObject>;
  notifications_Isnull?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<NotificationInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  sender_Isnull?: Maybe<Scalars['Boolean']>;
  sender?: Maybe<Scalars['String']>;
  sender_Iexact?: Maybe<Scalars['String']>;
  sender_Contains?: Maybe<Scalars['String']>;
  sender_Icontains?: Maybe<Scalars['String']>;
  sender_Startswith?: Maybe<Scalars['String']>;
  sender_Istartswith?: Maybe<Scalars['String']>;
  sender_Endswith?: Maybe<Scalars['String']>;
  sender_Iendswith?: Maybe<Scalars['String']>;
  sender_In?: Maybe<Scalars['String']>;
  recipient_Isnull?: Maybe<Scalars['Boolean']>;
  recipient?: Maybe<Scalars['String']>;
  recipient_Iexact?: Maybe<Scalars['String']>;
  recipient_Contains?: Maybe<Scalars['String']>;
  recipient_Icontains?: Maybe<Scalars['String']>;
  recipient_Startswith?: Maybe<Scalars['String']>;
  recipient_Istartswith?: Maybe<Scalars['String']>;
  recipient_Endswith?: Maybe<Scalars['String']>;
  recipient_Iendswith?: Maybe<Scalars['String']>;
  recipient_In?: Maybe<Scalars['String']>;
  subject_Isnull?: Maybe<Scalars['Boolean']>;
  subject?: Maybe<Scalars['String']>;
  subject_Iexact?: Maybe<Scalars['String']>;
  subject_Contains?: Maybe<Scalars['String']>;
  subject_Icontains?: Maybe<Scalars['String']>;
  subject_Startswith?: Maybe<Scalars['String']>;
  subject_Istartswith?: Maybe<Scalars['String']>;
  subject_Endswith?: Maybe<Scalars['String']>;
  subject_Iendswith?: Maybe<Scalars['String']>;
  subject_In?: Maybe<Scalars['String']>;
  body_Isnull?: Maybe<Scalars['Boolean']>;
  body?: Maybe<Scalars['String']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  messageId_Isnull?: Maybe<Scalars['Boolean']>;
  messageId?: Maybe<Scalars['UUID']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
};

export type TimestampRuleType = PaymentPlatformRuleType & {
  __typename?: 'TimestampRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<DatetimeRangeType>;
  actual?: Maybe<Scalars['DateTime']>;
};

export type TokenActivityInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** last activity */
  lastActivity?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  lastActivity_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  token?: Maybe<TokenInputFilterObject>;
};

export type TokenInputFilterObject = {
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** Key */
  key_Icontains?: Maybe<Scalars['String']>;
  /** Created */
  created?: Maybe<Scalars['CustomDateTime']>;
  /** Created */
  created_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** Created */
  created_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** Created */
  created_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** Created */
  created_Lte?: Maybe<Scalars['CustomDateTime']>;
  key_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  user?: Maybe<UserInputFilterObject>;
  activity?: Maybe<TokenActivityInputFilterObject>;
};

export type TopUpTransactionInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** external transaction id */
  externalTransactionId?: Maybe<Scalars['String']>;
  /** external transaction id */
  externalTransactionId_Icontains?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lt?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Gte?: Maybe<Scalars['Float']>;
  /** amount */
  amount_Lte?: Maybe<Scalars['Float']>;
  /** received at */
  receivedAt?: Maybe<Scalars['CustomDate']>;
  /** received at */
  receivedAt_Gt?: Maybe<Scalars['CustomDate']>;
  /** received at */
  receivedAt_Lt?: Maybe<Scalars['CustomDate']>;
  /** received at */
  receivedAt_Gte?: Maybe<Scalars['CustomDate']>;
  /** received at */
  receivedAt_Lte?: Maybe<Scalars['CustomDate']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** created at */
  createdAt_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  externalTransactionId_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  receivedAt_In?: Maybe<Array<Maybe<Scalars['CustomDate']>>>;
  createdAt_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  fundingAccount?: Maybe<FundingAccountInputFilterObject>;
};

export type TopUpTransactionType = {
  __typename?: 'TopUpTransactionType';
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  /** created at */
  createdAt?: Maybe<Scalars['CustomDateTime']>;
  /** external transaction id */
  externalTransactionId?: Maybe<Scalars['String']>;
  /** funding account */
  fundingAccount?: Maybe<FundingAccountType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** received at */
  receivedAt?: Maybe<Scalars['CustomDate']>;
};

export type TransactionInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** classification */
  classification?: Maybe<Scalars['String']>;
  /** classification */
  classification_Icontains?: Maybe<Scalars['String']>;
  /** entity type */
  entityType?: Maybe<Scalars['String']>;
  /** entity type */
  entityType_Icontains?: Maybe<Scalars['String']>;
  /** entity id */
  entityId?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Gt?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Lt?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Gte?: Maybe<Scalars['Int']>;
  /** entity id */
  entityId_Lte?: Maybe<Scalars['Int']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  classification_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityType_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityId_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  journalentry?: Maybe<JournalEntryInputFilterObject>;
};

/** Checks if the transaction type is the supported one - for instance we usually block ATM withdrawals. */
export type TransactionTypeRuleType = PaymentPlatformRuleType & {
  __typename?: 'TransactionTypeRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Array<Maybe<Scalars['String']>>>;
  actual?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UnsupportedMccRuleType = PaymentPlatformRuleType & {
  __typename?: 'UnsupportedMCCRuleType';
  ruleType?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<MetaEntry>>>;
  skipped?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  expectedRaw?: Maybe<Scalars['String']>;
  actualRaw?: Maybe<Scalars['String']>;
  expected?: Maybe<Scalars['String']>;
  actual?: Maybe<Scalars['String']>;
};

export type UpdateBeneficiaryInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  /** member since */
  memberSince?: Maybe<Scalars['CustomDate']>;
  /** customer uuid */
  customerUuid?: Maybe<Scalars['UUID']>;
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  mobileAppAllowed?: Maybe<Scalars['Boolean']>;
  cardAllowed?: Maybe<Scalars['Boolean']>;
  /** token */
  token?: Maybe<Scalars['String']>;
  parentsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  policiesIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customer?: Maybe<CustomerInputType>;
  dependantsIds?: Maybe<Array<Scalars['ID']>>;
  policyHolderId?: Maybe<Scalars['ID']>;
  usagesIds?: Maybe<Array<Scalars['ID']>>;
  mobileAppUserId?: Maybe<Scalars['ID']>;
  unlocksIds?: Maybe<Array<Scalars['ID']>>;
  requestedClaimsIds?: Maybe<Array<Scalars['ID']>>;
  cardsIds?: Maybe<Array<Scalars['ID']>>;
  currentCardsForPaymentsIds?: Maybe<Array<Scalars['ID']>>;
  cardActionsIds?: Maybe<Array<Scalars['ID']>>;
  paymentRulesOverridesIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateCardActionInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** comment */
  comment?: Maybe<Scalars['String']>;
  /** action */
  action?: Maybe<CardActionActionEnumUpdate>;
  /** source */
  source?: Maybe<CardActionSourceEnumUpdate>;
  /** card */
  cardId?: Maybe<Scalars['ID']>;
  /** requested by */
  requestedById?: Maybe<Scalars['ID']>;
};

export type UpdateCardInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** proxy id */
  proxyId?: Maybe<Scalars['String']>;
  /** name on card */
  nameOnCard?: Maybe<Scalars['String']>;
  /** last digits */
  lastDigits?: Maybe<Scalars['String']>;
  /** expiration */
  expiration?: Maybe<Scalars['String']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isIssued?: Maybe<Scalars['Boolean']>;
  isReplacementOrdered?: Maybe<Scalars['Boolean']>;
  /** card type */
  cardType?: Maybe<CardCardTypeEnumUpdate>;
  /** aggregation processed at */
  aggregationProcessedAt?: Maybe<Scalars['CustomDateTime']>;
  /** aggregation failed at */
  aggregationFailedAt?: Maybe<Scalars['CustomDateTime']>;
  aggregationKeepProcessing?: Maybe<Scalars['Boolean']>;
  /** aggregation failed count */
  aggregationFailedCount?: Maybe<Scalars['Int']>;
  /** id for public retrieval */
  idForPublicRetrieval?: Maybe<Scalars['UUID']>;
  /** external ledger id */
  externalLedgerId?: Maybe<Scalars['String']>;
  aggregatedbeneficiarycard?: Maybe<Array<Scalars['ID']>>;
  /** beneficiary */
  beneficiaryId?: Maybe<Scalars['ID']>;
  /** policy chain */
  policyChainId?: Maybe<Scalars['ID']>;
  /** default claim */
  defaultClaimId?: Maybe<Scalars['ID']>;
  /** current beneficiary for payments */
  currentBeneficiaryForPaymentsId?: Maybe<Scalars['ID']>;
  sentEmailsIds?: Maybe<Array<Scalars['ID']>>;
  sentSmsesIds?: Maybe<Array<Scalars['ID']>>;
  actionsIds?: Maybe<Array<Scalars['ID']>>;
  insurerPaymentsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateClaimCptCodeInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  default?: Maybe<Scalars['Boolean']>;
  /** cpt code */
  cptCodeId?: Maybe<Scalars['ID']>;
  /** claim */
  claimId?: Maybe<Scalars['ID']>;
};

export type UpdateClaimIcdCodeInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  default?: Maybe<Scalars['Boolean']>;
  /** icd code */
  icdCodeId?: Maybe<Scalars['ID']>;
  /** claim */
  claimId?: Maybe<Scalars['ID']>;
};

export type UpdateCorporatePolicyChainInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  corporationsIds?: Maybe<Array<Scalars['ID']>>;
  chainsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateCorporationInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** company name */
  companyName?: Maybe<Scalars['String']>;
  /** address */
  address?: Maybe<Scalars['String']>;
  /** contact name */
  contactName?: Maybe<Scalars['String']>;
  /** contact email */
  contactEmail?: Maybe<Scalars['String']>;
  /** contact phone */
  contactPhone?: Maybe<Scalars['String']>;
  chainsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateMerchantInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** address */
  address?: Maybe<Scalars['String']>;
  /** gps latitude */
  gpsLatitude?: Maybe<Scalars['Float']>;
  /** gps longitude */
  gpsLongitude?: Maybe<Scalars['Float']>;
  /** detailed info */
  detailedInfo?: Maybe<Scalars['String']>;
  isVerifiedByAdmin?: Maybe<Scalars['Boolean']>;
  categoriesIds?: Maybe<Array<Scalars['ID']>>;
  /** country */
  countryId?: Maybe<Scalars['ID']>;
  providerId?: Maybe<Scalars['ID']>;
  claimsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateMobileAppUserInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** password */
  password?: Maybe<Scalars['String']>;
  /** last login */
  lastLogin?: Maybe<Scalars['CustomDateTime']>;
  /** username */
  username?: Maybe<Scalars['String']>;
  /** password expiration */
  passwordExpiration?: Maybe<Scalars['CustomDateTime']>;
  /** beneficiary */
  beneficiaryId?: Maybe<Scalars['ID']>;
  sessionsIds?: Maybe<Array<Scalars['ID']>>;
  locationStampsIds?: Maybe<Array<Scalars['ID']>>;
  mobileNotificationsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdatePaymentRulesOverrideInputType = {
  id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['AutoParsedDateTime']>;
  validNTimes?: Maybe<Scalars['Int']>;
};

export type UpdatePersonalCardInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** proxy id */
  proxyId?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  /** policy holder */
  policyHolderId?: Maybe<Scalars['ID']>;
  beneficiaryPaymentsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdatePolicyChainInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  holdersIds?: Maybe<Array<Scalars['ID']>>;
  /** product */
  productId?: Maybe<Scalars['ID']>;
  /** corporate policy chain */
  corporatePolicyChainId?: Maybe<Scalars['ID']>;
  policiesIds?: Maybe<Array<Scalars['ID']>>;
  cardsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdatePolicyHolderInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** customer uuid */
  customerUuid?: Maybe<Scalars['UUID']>;
  chainsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  customer?: Maybe<CustomerInputType>;
  /** beneficiary */
  beneficiaryId?: Maybe<Scalars['ID']>;
  personalCardsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdatePolicyInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** external id */
  externalId?: Maybe<Scalars['String']>;
  /** additional information */
  additionalInformation?: Maybe<Scalars['String']>;
  /** expiration */
  expiration?: Maybe<Scalars['CustomDateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  beneficiariesIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** chain */
  chainId?: Maybe<Scalars['ID']>;
  /** product version */
  productVersionId?: Maybe<Scalars['ID']>;
  usagesIds?: Maybe<Array<Scalars['ID']>>;
  sessionsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateProviderEmployeeInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** provider */
  providerId?: Maybe<Scalars['ID']>;
  /** user */
  userId?: Maybe<Scalars['ID']>;
};

export type UpdateProviderInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** email */
  email?: Maybe<Scalars['String']>;
  /** phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  receiveNotifications?: Maybe<Scalars['Boolean']>;
  claimicdcode?: Maybe<Array<Scalars['ID']>>;
  /** merchant */
  merchantId?: Maybe<Scalars['ID']>;
  providerEmployeesIds?: Maybe<Array<Scalars['ID']>>;
  unlocksIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateReceiptInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** source */
  source?: Maybe<ReceiptSourceEnumUpdate>;
  filename?: Maybe<Scalars['String']>;
  /**  resolved at */
  ResolvedAt?: Maybe<Scalars['CustomDateTime']>;
  /** external payment authorization_id */
  paymentAuthorizationId?: Maybe<Scalars['String']>;
  fxRate?: Maybe<Scalars['DecimalScalar']>;
  transactionAt?: Maybe<Scalars['AutoParsedDateTime']>;
  /** file type */
  fileType?: Maybe<ReceiptFileTypeEnumUpdate>;
  currencyId?: Maybe<Scalars['ID']>;
  file?: Maybe<Scalars['Upload']>;
  /** claim */
  claimId?: Maybe<Scalars['ID']>;
  itemsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateReceiptItemInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** title */
  title?: Maybe<Scalars['String']>;
  /** amount */
  amount?: Maybe<Scalars['Float']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  /** receipt */
  receiptId?: Maybe<Scalars['ID']>;
  /** icd */
  icdId?: Maybe<Scalars['ID']>;
};

export type UpdateSupportingDocumentInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** source */
  source?: Maybe<SupportingDocumentSourceEnumUpdate>;
  filename?: Maybe<Scalars['String']>;
  /**  resolved at */
  ResolvedAt?: Maybe<Scalars['CustomDateTime']>;
  /** file type */
  fileType?: Maybe<SupportingDocumentFileTypeEnumUpdate>;
  file?: Maybe<Scalars['Upload']>;
  /** claim */
  claimId?: Maybe<Scalars['ID']>;
  sentEmailsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateUserInputType = {
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** password */
  password?: Maybe<Scalars['String']>;
  /** last login */
  lastLogin?: Maybe<Scalars['CustomDateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: Maybe<Scalars['Boolean']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: Maybe<Scalars['String']>;
  /** first name */
  firstName?: Maybe<Scalars['String']>;
  /** last name */
  lastName?: Maybe<Scalars['String']>;
  /** email address */
  email?: Maybe<Scalars['String']>;
  /** Designates whether the user can log into this admin site. */
  isStaff?: Maybe<Scalars['Boolean']>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** date joined */
  dateJoined?: Maybe<Scalars['CustomDateTime']>;
  notification?: Maybe<Array<Scalars['ID']>>;
  userpasswordhistoryconfig?: Maybe<Array<Scalars['ID']>>;
  logentry?: Maybe<Array<Scalars['ID']>>;
  groupsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupsIds?: Maybe<Array<Scalars['ID']>>;
  userPermissionsIds?: Maybe<Array<Scalars['ID']>>;
  authTokenId?: Maybe<Scalars['ID']>;
  employeeId?: Maybe<Scalars['ID']>;
  passwordExpirationId?: Maybe<Scalars['ID']>;
  detailsId?: Maybe<Scalars['ID']>;
  providerEmployeeId?: Maybe<Scalars['ID']>;
  supportingDocumentsIds?: Maybe<Array<Scalars['ID']>>;
  resolvedSupportingDocumentsIds?: Maybe<Array<Scalars['ID']>>;
  receiptsIds?: Maybe<Array<Scalars['ID']>>;
  resolvedReceiptsIds?: Maybe<Array<Scalars['ID']>>;
  cardActionsIds?: Maybe<Array<Scalars['ID']>>;
};

export type UserDetailsInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** Maximal amount that the user can act on. To set no limit, please use "-1". */
  claimsAuthority?: Maybe<Scalars['Float']>;
  /** Maximal amount that the user can act on. To set no limit, please use "-1". */
  claimsAuthority_Gt?: Maybe<Scalars['Float']>;
  /** Maximal amount that the user can act on. To set no limit, please use "-1". */
  claimsAuthority_Lt?: Maybe<Scalars['Float']>;
  /** Maximal amount that the user can act on. To set no limit, please use "-1". */
  claimsAuthority_Gte?: Maybe<Scalars['Float']>;
  /** Maximal amount that the user can act on. To set no limit, please use "-1". */
  claimsAuthority_Lte?: Maybe<Scalars['Float']>;
  /** last activity */
  lastActivity?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** last activity */
  lastActivity_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** permitted endpoints */
  permittedEndpoints?: Maybe<Scalars['JSONString']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  claimsAuthority_In?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lastActivity_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  permittedEndpoints_In?: Maybe<Array<Maybe<Scalars['JSONString']>>>;
  user?: Maybe<UserInputFilterObject>;
};

export type UserDetailsType = {
  __typename?: 'UserDetailsType';
  /** Maximal amount that the user can act on. To set no limit, please use "-1". */
  claimsAuthority?: Maybe<Scalars['Float']>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** last activity */
  lastActivity?: Maybe<Scalars['CustomDateTime']>;
  /** permitted endpoints */
  permittedEndpoints?: Maybe<Scalars['JSONString']>;
  /** user */
  user?: Maybe<UserType>;
};

export type UserInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** password */
  password?: Maybe<Scalars['String']>;
  /** password */
  password_Icontains?: Maybe<Scalars['String']>;
  /** last login */
  lastLogin?: Maybe<Scalars['CustomDateTime']>;
  /** last login */
  lastLogin_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** last login */
  lastLogin_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** last login */
  lastLogin_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** last login */
  lastLogin_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: Maybe<Scalars['Boolean']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: Maybe<Scalars['String']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username_Icontains?: Maybe<Scalars['String']>;
  /** first name */
  firstName?: Maybe<Scalars['String']>;
  /** first name */
  firstName_Icontains?: Maybe<Scalars['String']>;
  /** last name */
  lastName?: Maybe<Scalars['String']>;
  /** last name */
  lastName_Icontains?: Maybe<Scalars['String']>;
  /** email address */
  email?: Maybe<Scalars['String']>;
  /** email address */
  email_Icontains?: Maybe<Scalars['String']>;
  /** Designates whether the user can log into this admin site. */
  isStaff?: Maybe<Scalars['Boolean']>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** date joined */
  dateJoined?: Maybe<Scalars['CustomDateTime']>;
  /** date joined */
  dateJoined_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** date joined */
  dateJoined_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** date joined */
  dateJoined_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** date joined */
  dateJoined_Lte?: Maybe<Scalars['CustomDateTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  password_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastLogin_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  isSuperuser_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  username_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  isStaff_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  isActive_In?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  dateJoined_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  groups?: Maybe<GroupInputFilterObject>;
  userPermissions?: Maybe<PermissionInputFilterObject>;
  authToken?: Maybe<TokenInputFilterObject>;
  employee?: Maybe<InsurerEmployeeInputFilterObject>;
  passwordExpiration?: Maybe<PasswordExpirationInputFilterObject>;
  details?: Maybe<UserDetailsInputFilterObject>;
  supportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  resolvedSupportingDocuments?: Maybe<SupportingDocumentInputFilterObject>;
  receipts?: Maybe<ReceiptInputFilterObject>;
  resolvedReceipts?: Maybe<ReceiptInputFilterObject>;
  cardActions?: Maybe<CardActionInputFilterObject>;
  notification?: Maybe<NotificationInputFilterObject>;
  providerEmployee?: Maybe<ProviderEmployeeInputFilterObject>;
  userpasswordhistoryconfig?: Maybe<UserPasswordHistoryConfigInputFilterObject>;
  logentry?: Maybe<LogEntryInputFilterObject>;
};

export type UserListType = {
  __typename?: 'UserListType';
  /** User list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<UserType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type UserMutation = {
  __typename?: 'UserMutation';
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  user?: Maybe<UserType>;
};

export type UserPasswordHistoryConfigInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** When created salt */
  date?: Maybe<Scalars['CustomDateTime']>;
  /** When created salt */
  date_Gt?: Maybe<Scalars['CustomDateTime']>;
  /** When created salt */
  date_Lt?: Maybe<Scalars['CustomDateTime']>;
  /** When created salt */
  date_Gte?: Maybe<Scalars['CustomDateTime']>;
  /** When created salt */
  date_Lte?: Maybe<Scalars['CustomDateTime']>;
  /** Salt for the user */
  salt?: Maybe<Scalars['String']>;
  /** Salt for the user */
  salt_Icontains?: Maybe<Scalars['String']>;
  /** The number of of iterations for Hasher */
  iterations?: Maybe<Scalars['Int']>;
  /** The number of of iterations for Hasher */
  iterations_Gt?: Maybe<Scalars['Int']>;
  /** The number of of iterations for Hasher */
  iterations_Lt?: Maybe<Scalars['Int']>;
  /** The number of of iterations for Hasher */
  iterations_Gte?: Maybe<Scalars['Int']>;
  /** The number of of iterations for Hasher */
  iterations_Lte?: Maybe<Scalars['Int']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  date_In?: Maybe<Array<Maybe<Scalars['CustomDateTime']>>>;
  salt_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  iterations_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  user?: Maybe<UserInputFilterObject>;
  passwordhistory?: Maybe<PasswordHistoryInputFilterObject>;
};

/**
 * Filters
 * -------
 *  - is_me: Filter all users except the current one if true, exclude current user if false, do nothing when
 *           this filtering field is omit
 */
export type UserType = {
  __typename?: 'UserType';
  /** CardAction list */
  cardActions?: Maybe<Array<Maybe<CardActionType>>>;
  /** date joined */
  dateJoined?: Maybe<Scalars['CustomDateTime']>;
  details?: Maybe<UserDetailsType>;
  /** email address */
  email?: Maybe<Scalars['String']>;
  employee?: Maybe<InsurerEmployeeType>;
  /** first name */
  firstName?: Maybe<Scalars['String']>;
  /** Group list */
  groups?: Maybe<Array<Maybe<GroupType>>>;
  /** Django object unique identification field */
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Designates whether the user can log into this admin site. */
  isStaff?: Maybe<Scalars['Boolean']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: Maybe<Scalars['Boolean']>;
  /** last login */
  lastLogin?: Maybe<Scalars['CustomDateTime']>;
  /** last name */
  lastName?: Maybe<Scalars['String']>;
  /** Notification list */
  notification?: Maybe<Array<Maybe<NotificationType>>>;
  /** password */
  password?: Maybe<Scalars['String']>;
  providerEmployee?: Maybe<ProviderEmployeeType>;
  /** Receipt list */
  receipts?: Maybe<Array<Maybe<ReceiptType>>>;
  /** Receipt list */
  resolvedReceipts?: Maybe<Array<Maybe<ReceiptType>>>;
  /** SupportingDocument list */
  resolvedSupportingDocuments?: Maybe<Array<Maybe<SupportingDocumentType>>>;
  /** SupportingDocument list */
  supportingDocuments?: Maybe<Array<Maybe<SupportingDocumentType>>>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastActivity?: Maybe<Scalars['DateTime']>;
};

/**
 * Filters
 * -------
 *  - is_me: Filter all users except the current one if true, exclude current user if false, do nothing when
 *           this filtering field is omit
 */
export type UserTypeCardActionsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  card_Isnull?: Maybe<Scalars['Boolean']>;
  card?: Maybe<CardInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  created_Isnull?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  created_Gt?: Maybe<Scalars['DateTime']>;
  created_Gte?: Maybe<Scalars['DateTime']>;
  created_Lt?: Maybe<Scalars['DateTime']>;
  created_Lte?: Maybe<Scalars['DateTime']>;
  created_In?: Maybe<Scalars['DateTime']>;
  comment_Isnull?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  action_Isnull?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_Iexact?: Maybe<Scalars['String']>;
  action_Contains?: Maybe<Scalars['String']>;
  action_Icontains?: Maybe<Scalars['String']>;
  action_Startswith?: Maybe<Scalars['String']>;
  action_Istartswith?: Maybe<Scalars['String']>;
  action_Endswith?: Maybe<Scalars['String']>;
  action_Iendswith?: Maybe<Scalars['String']>;
  action_In?: Maybe<Scalars['String']>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  requestedBy_Isnull?: Maybe<Scalars['Boolean']>;
  requestedBy?: Maybe<BeneficiaryInputFilterObject>;
};

/**
 * Filters
 * -------
 *  - is_me: Filter all users except the current one if true, exclude current user if false, do nothing when
 *           this filtering field is omit
 */
export type UserTypeGroupsArgs = {
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  details_Isnull?: Maybe<Scalars['Boolean']>;
  details?: Maybe<GroupDetailsInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_Isnull?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_Iexact?: Maybe<Scalars['String']>;
  name_Contains?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Startswith?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  name_Endswith?: Maybe<Scalars['String']>;
  name_Iendswith?: Maybe<Scalars['String']>;
  name_In?: Maybe<Scalars['String']>;
  permissions_Isnull?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<PermissionInputFilterObject>;
  groups?: Maybe<GroupInputFilterObject>;
};

/**
 * Filters
 * -------
 *  - is_me: Filter all users except the current one if true, exclude current user if false, do nothing when
 *           this filtering field is omit
 */
export type UserTypeNotificationArgs = {
  mobileNotifications_Isnull?: Maybe<Scalars['Boolean']>;
  mobileNotifications?: Maybe<MobileNotificationInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  processedAt_Isnull?: Maybe<Scalars['Boolean']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_Gt?: Maybe<Scalars['DateTime']>;
  processedAt_Gte?: Maybe<Scalars['DateTime']>;
  processedAt_Lt?: Maybe<Scalars['DateTime']>;
  processedAt_Lte?: Maybe<Scalars['DateTime']>;
  processedAt_In?: Maybe<Scalars['DateTime']>;
  failedAt_Isnull?: Maybe<Scalars['Boolean']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_Gt?: Maybe<Scalars['DateTime']>;
  failedAt_Gte?: Maybe<Scalars['DateTime']>;
  failedAt_Lt?: Maybe<Scalars['DateTime']>;
  failedAt_Lte?: Maybe<Scalars['DateTime']>;
  failedAt_In?: Maybe<Scalars['DateTime']>;
  failedCount_Isnull?: Maybe<Scalars['Boolean']>;
  failedCount?: Maybe<Scalars['Int']>;
  failedCount_Gt?: Maybe<Scalars['Int']>;
  failedCount_Gte?: Maybe<Scalars['Int']>;
  failedCount_Lt?: Maybe<Scalars['Int']>;
  failedCount_Lte?: Maybe<Scalars['Int']>;
  failedCount_In?: Maybe<Scalars['Int']>;
  keepProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  keepProcessing?: Maybe<Scalars['Boolean']>;
  needManualProcessing_Isnull?: Maybe<Scalars['Boolean']>;
  needManualProcessing?: Maybe<Scalars['Boolean']>;
  insurer_Isnull?: Maybe<Scalars['Boolean']>;
  insurer?: Maybe<InsurerInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  action_Isnull?: Maybe<Scalars['Boolean']>;
  action?: Maybe<Scalars['String']>;
  action_Iexact?: Maybe<Scalars['String']>;
  action_Contains?: Maybe<Scalars['String']>;
  action_Icontains?: Maybe<Scalars['String']>;
  action_Startswith?: Maybe<Scalars['String']>;
  action_Istartswith?: Maybe<Scalars['String']>;
  action_Endswith?: Maybe<Scalars['String']>;
  action_Iendswith?: Maybe<Scalars['String']>;
  action_In?: Maybe<Scalars['String']>;
  timestamp_Isnull?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  timestamp_Gt?: Maybe<Scalars['DateTime']>;
  timestamp_Gte?: Maybe<Scalars['DateTime']>;
  timestamp_Lt?: Maybe<Scalars['DateTime']>;
  timestamp_Lte?: Maybe<Scalars['DateTime']>;
  timestamp_In?: Maybe<Scalars['DateTime']>;
  read_Isnull?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  createdAt_Isnull?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_Gt?: Maybe<Scalars['DateTime']>;
  createdAt_Gte?: Maybe<Scalars['DateTime']>;
  createdAt_Lt?: Maybe<Scalars['DateTime']>;
  createdAt_Lte?: Maybe<Scalars['DateTime']>;
  createdAt_In?: Maybe<Scalars['DateTime']>;
  entityType_Isnull?: Maybe<Scalars['Boolean']>;
  entityType?: Maybe<ContentTypeInputFilterObject>;
  entityId_Isnull?: Maybe<Scalars['Boolean']>;
  entityId?: Maybe<Scalars['String']>;
  entityId_Iexact?: Maybe<Scalars['String']>;
  entityId_Contains?: Maybe<Scalars['String']>;
  entityId_Icontains?: Maybe<Scalars['String']>;
  entityId_Startswith?: Maybe<Scalars['String']>;
  entityId_Istartswith?: Maybe<Scalars['String']>;
  entityId_Endswith?: Maybe<Scalars['String']>;
  entityId_Iendswith?: Maybe<Scalars['String']>;
  entityId_In?: Maybe<Scalars['String']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
  source?: Maybe<Scalars['String']>;
  source_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  claimsAuthority?: Maybe<Scalars['Float']>;
  search?: Maybe<NotificationSearchInputType>;
  claimId?: Maybe<Scalars['String']>;
};

/**
 * Filters
 * -------
 *  - is_me: Filter all users except the current one if true, exclude current user if false, do nothing when
 *           this filtering field is omit
 */
export type UserTypeReceiptsArgs = {
  items_Isnull?: Maybe<Scalars['Boolean']>;
  items?: Maybe<ReceiptItemInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Scalars['DateTime']>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Scalars['String']>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Scalars['DateTime']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  paymentAuthorizationId_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizationId?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iexact?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Contains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Icontains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Startswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Istartswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Endswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iendswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_In?: Maybe<Scalars['String']>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Scalars['Float']>;
  transactionAt_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gte?: Maybe<Scalars['DateTime']>;
  transactionAt_Lt?: Maybe<Scalars['DateTime']>;
  transactionAt_Lte?: Maybe<Scalars['DateTime']>;
  transactionAt_In?: Maybe<Scalars['DateTime']>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Scalars['String']>;
};

/**
 * Filters
 * -------
 *  - is_me: Filter all users except the current one if true, exclude current user if false, do nothing when
 *           this filtering field is omit
 */
export type UserTypeResolvedReceiptsArgs = {
  items_Isnull?: Maybe<Scalars['Boolean']>;
  items?: Maybe<ReceiptItemInputFilterObject>;
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Scalars['DateTime']>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Scalars['String']>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Scalars['DateTime']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  paymentAuthorizationId_Isnull?: Maybe<Scalars['Boolean']>;
  paymentAuthorizationId?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iexact?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Contains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Icontains?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Startswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Istartswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Endswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_Iendswith?: Maybe<Scalars['String']>;
  paymentAuthorizationId_In?: Maybe<Scalars['String']>;
  currency_Isnull?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<CurrencyInputFilterObject>;
  fxRate_Isnull?: Maybe<Scalars['Boolean']>;
  fxRate?: Maybe<Scalars['Float']>;
  fxRate_Gt?: Maybe<Scalars['Float']>;
  fxRate_Gte?: Maybe<Scalars['Float']>;
  fxRate_Lt?: Maybe<Scalars['Float']>;
  fxRate_Lte?: Maybe<Scalars['Float']>;
  fxRate_In?: Maybe<Scalars['Float']>;
  transactionAt_Isnull?: Maybe<Scalars['Boolean']>;
  transactionAt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gt?: Maybe<Scalars['DateTime']>;
  transactionAt_Gte?: Maybe<Scalars['DateTime']>;
  transactionAt_Lt?: Maybe<Scalars['DateTime']>;
  transactionAt_Lte?: Maybe<Scalars['DateTime']>;
  transactionAt_In?: Maybe<Scalars['DateTime']>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Scalars['String']>;
};

/**
 * Filters
 * -------
 *  - is_me: Filter all users except the current one if true, exclude current user if false, do nothing when
 *           this filtering field is omit
 */
export type UserTypeResolvedSupportingDocumentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Scalars['DateTime']>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Scalars['String']>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Scalars['DateTime']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Scalars['String']>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
};

/**
 * Filters
 * -------
 *  - is_me: Filter all users except the current one if true, exclude current user if false, do nothing when
 *           this filtering field is omit
 */
export type UserTypeSupportingDocumentsArgs = {
  id_Isnull?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Float']>;
  id_Gt?: Maybe<Scalars['Float']>;
  id_Gte?: Maybe<Scalars['Float']>;
  id_Lt?: Maybe<Scalars['Float']>;
  id_Lte?: Maybe<Scalars['Float']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_Isnull?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  source_Iexact?: Maybe<Scalars['String']>;
  source_Contains?: Maybe<Scalars['String']>;
  source_Icontains?: Maybe<Scalars['String']>;
  source_Startswith?: Maybe<Scalars['String']>;
  source_Istartswith?: Maybe<Scalars['String']>;
  source_Endswith?: Maybe<Scalars['String']>;
  source_Iendswith?: Maybe<Scalars['String']>;
  source_In?: Maybe<Scalars['String']>;
  state_Isnull?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  state_Iexact?: Maybe<Scalars['String']>;
  state_Contains?: Maybe<Scalars['String']>;
  state_Icontains?: Maybe<Scalars['String']>;
  state_Startswith?: Maybe<Scalars['String']>;
  state_Istartswith?: Maybe<Scalars['String']>;
  state_Endswith?: Maybe<Scalars['String']>;
  state_Iendswith?: Maybe<Scalars['String']>;
  state_In?: Maybe<Scalars['String']>;
  Reason_Isnull?: Maybe<Scalars['Boolean']>;
  Reason?: Maybe<Scalars['String']>;
  uploadedAt_Isnull?: Maybe<Scalars['Boolean']>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Gte?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lt?: Maybe<Scalars['DateTime']>;
  uploadedAt_Lte?: Maybe<Scalars['DateTime']>;
  uploadedAt_In?: Maybe<Scalars['DateTime']>;
  filename_Isnull?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Scalars['String']>;
  filename_Iexact?: Maybe<Scalars['String']>;
  filename_Contains?: Maybe<Scalars['String']>;
  filename_Icontains?: Maybe<Scalars['String']>;
  filename_Startswith?: Maybe<Scalars['String']>;
  filename_Istartswith?: Maybe<Scalars['String']>;
  filename_Endswith?: Maybe<Scalars['String']>;
  filename_Iendswith?: Maybe<Scalars['String']>;
  filename_In?: Maybe<Scalars['String']>;
  ResolvedAt_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedAt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Gte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lt?: Maybe<Scalars['DateTime']>;
  ResolvedAt_Lte?: Maybe<Scalars['DateTime']>;
  ResolvedAt_In?: Maybe<Scalars['DateTime']>;
  claim_Isnull?: Maybe<Scalars['Boolean']>;
  claim?: Maybe<ClaimInputFilterObject>;
  user_Isnull?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserInputFilterObject>;
  fileType_Isnull?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  fileType_Iexact?: Maybe<Scalars['String']>;
  fileType_Contains?: Maybe<Scalars['String']>;
  fileType_Icontains?: Maybe<Scalars['String']>;
  fileType_Startswith?: Maybe<Scalars['String']>;
  fileType_Istartswith?: Maybe<Scalars['String']>;
  fileType_Endswith?: Maybe<Scalars['String']>;
  fileType_Iendswith?: Maybe<Scalars['String']>;
  fileType_In?: Maybe<Scalars['String']>;
  ResolvedBy_Isnull?: Maybe<Scalars['Boolean']>;
  ResolvedBy?: Maybe<Scalars['ID']>;
  sentEmails_Isnull?: Maybe<Scalars['Boolean']>;
  sentEmails?: Maybe<EmailInputFilterObject>;
};

export type ValidForIcdCodeType = {
  __typename?: 'ValidForIcdCodeType';
  result?: Maybe<Scalars['Boolean']>;
  forClaim?: Maybe<Scalars['Boolean']>;
  forBeneficiary?: Maybe<Scalars['Boolean']>;
  forPolicy?: Maybe<Scalars['Boolean']>;
};

export type VirtualCardAccountMetaInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** card design */
  cardDesign?: Maybe<Scalars['String']>;
  /** card design */
  cardDesign_Icontains?: Maybe<Scalars['String']>;
  /** card programme */
  cardProgramme?: Maybe<Scalars['String']>;
  /** card programme */
  cardProgramme_Icontains?: Maybe<Scalars['String']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cardDesign_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardProgramme_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  fundingAccount?: Maybe<FundingAccountInputFilterObject>;
};

export type VirtualCardAccountMetaListType = {
  __typename?: 'VirtualCardAccountMetaListType';
  /** VirtualCardAccountMeta list, paginated by PagePaginator */
  results?: Maybe<Array<Maybe<VirtualCardMetaAccountType>>>;
  /** Total count of matches elements */
  totalCount?: Maybe<Scalars['Int']>;
};

export type VirtualCardAccountMetaListTypeResultsArgs = {
  page?: Maybe<Scalars['Int']>;
  ordering?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type VirtualCardMetaAccountType = {
  __typename?: 'VirtualCardMetaAccountType';
  /** card design */
  cardDesign?: Maybe<Scalars['String']>;
  /** card programme */
  cardProgramme?: Maybe<Scalars['String']>;
  /** funding account */
  fundingAccount?: Maybe<FundingAccountType>;
  /** Django object unique identification field */
  id: Scalars['ID'];
};

export type WorkDayInputFilterObject = {
  /** Django object unique identification field */
  id?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lt?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Gte?: Maybe<Scalars['ID']>;
  /** Django object unique identification field */
  id_Lte?: Maybe<Scalars['ID']>;
  /** day number */
  dayNumber?: Maybe<Scalars['Int']>;
  /** day number */
  dayNumber_Gt?: Maybe<Scalars['Int']>;
  /** day number */
  dayNumber_Lt?: Maybe<Scalars['Int']>;
  /** day number */
  dayNumber_Gte?: Maybe<Scalars['Int']>;
  /** day number */
  dayNumber_Lte?: Maybe<Scalars['Int']>;
  /** work hours start */
  workHoursStart?: Maybe<Scalars['CustomTime']>;
  /** work hours end */
  workHoursEnd?: Maybe<Scalars['CustomTime']>;
  id_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
  dayNumber_In?: Maybe<Array<Maybe<Scalars['Int']>>>;
  workHoursStart_In?: Maybe<Array<Maybe<Scalars['CustomTime']>>>;
  workHoursEnd_In?: Maybe<Array<Maybe<Scalars['CustomTime']>>>;
  insurer?: Maybe<InsurerInputFilterObject>;
};

export type BeneficiaryUsagesQueryVariables = Exact<{
  beneficiaryId: Scalars['ID'];
}>;

export type BeneficiaryUsagesQuery = { __typename?: 'Query' } & {
  beneficiary?: Maybe<
    { __typename?: 'BeneficiaryType' } & {
      policies?: Maybe<
        Array<
          Maybe<
            { __typename?: 'PolicyType' } & Pick<PolicyType, 'id'> & {
                productVersion?: Maybe<
                  { __typename?: 'ProductVersionType' } & Pick<
                    ProductVersionType,
                    'perClaimExcess'
                  > & {
                      currency?: Maybe<
                        { __typename?: 'CurrencyType' } & Pick<CurrencyType, 'code'>
                      >;
                    }
                >;
                usages?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'BenefitUsageType' } & Pick<
                        BenefitUsageType,
                        'currentAmount' | 'currentCount'
                      > & {
                          benefit?: Maybe<
                            { __typename?: 'BenefitType' } & Pick<
                              BenefitType,
                              | 'id'
                              | 'title'
                              | 'description'
                              | 'beneficiaryLimitAmount'
                              | 'beneficiaryLimitCount'
                              | 'coPayment'
                              | 'coPaymentMax'
                              | 'excess'
                            > & {
                                parent?: Maybe<
                                  { __typename?: 'BenefitType' } & Pick<BenefitType, 'id'>
                                >;
                              }
                          >;
                        }
                    >
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type PolicyExclusionsQueryVariables = Exact<{
  policyId: Scalars['Int'];
}>;

export type PolicyExclusionsQuery = { __typename?: 'Query' } & {
  codesExclusions?: Maybe<
    { __typename?: 'CodesExclusionListType' } & {
      results?: Maybe<
        Array<
          Maybe<
            { __typename?: 'CodesExclusionType' } & Pick<
              CodesExclusionType,
              'name' | 'description'
            > & {
                icdCodes?: Maybe<
                  Array<Maybe<{ __typename?: 'IcdCodeType' } & Pick<IcdCodeType, 'code' | 'name'>>>
                >;
                cptCodes?: Maybe<
                  Array<Maybe<{ __typename?: 'CptCodeType' } & Pick<CptCodeType, 'code' | 'name'>>>
                >;
              }
          >
        >
      >;
    }
  >;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = { __typename?: 'Query' } & {
  users?: Maybe<
    { __typename?: 'UserListType' } & {
      results?: Maybe<
        Array<
          Maybe<
            { __typename?: 'UserType' } & Pick<
              UserType,
              | 'id'
              | 'firstName'
              | 'lastName'
              | 'email'
              | 'permissions'
              | 'lastActivity'
              | 'lastLogin'
              | 'isSuperuser'
            > & {
                details?: Maybe<
                  { __typename?: 'UserDetailsType' } & Pick<UserDetailsType, 'claimsAuthority'>
                >;
                groups?: Maybe<
                  Array<Maybe<{ __typename?: 'GroupType' } & Pick<GroupType, 'name'>>>
                >;
                providerEmployee?: Maybe<
                  { __typename?: 'ProviderEmployeeType' } & {
                    provider?: Maybe<
                      { __typename?: 'ProviderType' } & Pick<ProviderType, 'id'> & {
                          merchant?: Maybe<
                            { __typename?: 'MerchantType' } & Pick<MerchantType, 'id' | 'name'>
                          >;
                        }
                    >;
                  }
                >;
              }
          >
        >
      >;
    }
  >;
};

export const BeneficiaryUsagesDocument = gql`
  query beneficiaryUsages($beneficiaryId: ID!) {
    beneficiary(id: $beneficiaryId) {
      policies {
        id
        productVersion {
          perClaimExcess
          currency {
            code
          }
        }
        usages {
          benefit {
            id
            title
            description
            beneficiaryLimitAmount
            beneficiaryLimitCount
            coPayment
            coPaymentMax
            excess
            parent {
              id
            }
          }
          currentAmount
          currentCount
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryUsagesGQL extends Apollo.Query<
  BeneficiaryUsagesQuery,
  BeneficiaryUsagesQueryVariables
> {
  document = BeneficiaryUsagesDocument;
}
export const PolicyExclusionsDocument = gql`
  query policyExclusions($policyId: Int!) {
    codesExclusions(entityId: $policyId, entityType: { model: "policy" }) {
      results {
        name
        description
        icdCodes {
          code
          name
        }
        cptCodes {
          code
          name
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class PolicyExclusionsGQL extends Apollo.Query<
  PolicyExclusionsQuery,
  PolicyExclusionsQueryVariables
> {
  document = PolicyExclusionsDocument;
}
export const GetMeDocument = gql`
  query getMe {
    users(isMe: true) {
      results {
        id
        firstName
        lastName
        email
        details {
          claimsAuthority
        }
        groups {
          name
        }
        permissions
        lastActivity
        lastLogin
        providerEmployee {
          provider {
            id
            merchant {
              id
              name
            }
          }
        }
        isSuperuser
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class GetMeGQL extends Apollo.Query<GetMeQuery, GetMeQueryVariables> {
  document = GetMeDocument;
}
