import gql from 'graphql-tag';
import { BeneficiaryFragments, CurrencyFragments, ProductVersionFragments } from '.';

export const PolicyFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on PolicyType {
      id
      externalId
      beneficiaryCount
      expiration
      isActive
      productVersion {
        id
        version
        product {
          id
          name
        }
      }
      chain {
        corporate
      }
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on PolicyType {
      id
      externalId
      beneficiaryCount
      expiration
      usages {
        benefit {
          id
        }
        currentAmount
        currentPendingAmount
      }
      productVersion {
        ...productVersionFatFields
      }
      chain {
        id
        holders {
          id
          customer {
            externalId
            firstName
            lastName
            address {
              formatted
            }
            dateOfBirth
            email
          }
          beneficiary {
            ...beneficiaryFatFields
          }
        }
        corporate
        corporatePolicyChain {
          id
          externalId
          corporations {
            id
            externalId
            companyName
            address
            contactName
            contactEmail
            contactPhone
          }
        }
        currency {
          ...currencyFatFields
        }
      }
    }
    ${ProductVersionFragments.fat('productVersionFatFields')}
    ${BeneficiaryFragments.fat('beneficiaryFatFields')}
    ${CurrencyFragments.fat('currencyFatFields')}
  `
};
