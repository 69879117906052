import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalComponentModule } from '../modal';
import { ButtonComponentModule } from '../button';
import { ClaimHistoryComponent } from './claim-history.component';
import { ClaimNoteComponentModule } from '../claim-note';
import { DefaultToPipeModule, LocalDatePipeModule } from '@common/pipes';
import { LinkComponentModule } from '../link';

@NgModule({
  imports: [
    CommonModule,
    ModalComponentModule,
    ClaimNoteComponentModule,
    LocalDatePipeModule,
    DefaultToPipeModule,
    ButtonComponentModule,
    LinkComponentModule
  ],
  declarations: [ClaimHistoryComponent],
  exports: [ClaimHistoryComponent]
})
export class ClaimHistoryComponentModule {}
