<div class="BenefitUsage" [ngClass]="{ 'BenefitUsage--closed': !open }">
  <div class="BenefitUsage__side">
    <button class="BenefitUsage__toggle" (click)="toggle()">
      <fa-icon [icon]="open ? icons.faMinusCircle : icons.faPlusCircle"></fa-icon>
    </button>
    <div class="BenefitUsage__threadline" (click)="toggle()"></div>
  </div>
  <div class="BenefitUsage__main">
    <div class="BenefitUsage__header" (click)="toggle()">
      <h3 class="BenefitUsage__title">{{ benefit.title }}</h3>
      <span class="BenefitUsage__expandText">
        {{ open ? 'Collapse' : 'Expand' }}
        <fa-icon [icon]="open ? icons.faCompressAlt : icons.faExpandAlt"></fa-icon>
      </span>
      <div class="BenefitUsage__snapshot">
        <span>
          <span class="font--semiBold">{{
            benefit.limit.amount.total | currency: currencyCode
          }}</span>
          <small>({{ benefit.limit.amount.remaining | currency: currencyCode }} Remaining)</small>
        </span>
        <div class="BenefitUsage__progress">
          <div class="BenefitUsage__progressFill" [style]="{ width: progressWidth }"></div>
        </div>
      </div>
    </div>
    <div class="BenefitUsage__content">
      <ps-benefit-breakdown
        [benefit]="benefit"
        [currencyCode]="currencyCode"
      ></ps-benefit-breakdown>
      <ps-benefit-exclusions
        [exclusions]="benefit.exclusions"
        *ngIf="benefit.exclusions.length > 0"
      ></ps-benefit-exclusions>
      <ps-benefit-usage
        *ngFor="let b of benefit.children"
        [benefit]="b"
        [currencyCode]="currencyCode"
        [open]="false"
      ></ps-benefit-usage>
    </div>
  </div>
</div>
