import { Component, Input } from '@angular/core';
import { CodesExclusionType } from '@common/gql';
import { ModalProvider } from '@common/providers';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { BenefitExclusionDialogComponent } from '../benefit-exclusion-dialog/benefit-exclusion-dialog.component';

@Component({
  selector: 'ps-benefit-exclusion[exclusion]',
  templateUrl: './benefit-exclusion.component.html',
  styleUrls: ['./benefit-exclusion.component.scss']
})
export class BenefitExclusionComponent {
  @Input() exclusion: CodesExclusionType;

  icons = { faEye };

  constructor(private modalProvider: ModalProvider) {}

  viewExclusion() {
    this.modalProvider.open(BenefitExclusionDialogComponent, {
      exclusion: this.exclusion
    });
  }
}
