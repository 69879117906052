<div class="Container row" [ngClass]="{ 'Container--loading': loading }">
  <div class="col-md-7">
    <div class="Card" #cardEl>
      <span class="Card__pan">**** **** **** {{ card?.lastDigits }}</span>
      <span class="Card__exp">{{ card?.expiration }}</span>
      <span class="Card__name">{{ card?.nameOnCard }}</span>
    </div>
    <div class="text-center">
      <span class="cardType badge badge-info">
        <ng-container i18n="@@physical" *ngIf="card?.cardType === CardType.Physical">
          Physical
        </ng-container>
        <ng-container i18n="@@virtual" *ngIf="card?.cardType === CardType.Virtual">
          Virtual
        </ng-container>
        <ng-container i18="@@card">
          Card
        </ng-container>
      </span>
    </div>
  </div>
  <div class="col-md-5" *ngIf="!loading">
    <div class="Status">
      <div *ngIf="card.ready">
        <fa-icon class="Status__icon--ready mr-1" [icon]="icons.ok"></fa-icon>Card ready for use
        <small i18n="@@cardForTransactionsUpTo"
          >For transactions up to {{ cardThreshold | currency: card.currency.code }}</small
        >
      </div>
      <div *ngIf="card.isBlocked" i18n="@@cardBlocked">
        <fa-icon class="Status__icon--blocked mr-1" [icon]="icons.blocked"></fa-icon>Card blocked
        <small>
          By
          <ps-link
            [disableLoadOnClick]="true"
            [href]="'mailto:' + card.lastBlockedAction.user.email"
            >{{ card.lastBlockedAction.user.email }}</ps-link
          ><br />
          <ng-container *ngIf="card.lastBlockedAction.comment"
            >Message: {{ card.lastBlockedAction.comment }}<br
          /></ng-container>
          <em>{{ card.lastBlockedAction.created | localDate }}</em>
        </small>
      </div>

      <ng-container *ngIf="!card.isBlocked && card.isFrozen" i18n="@@cardFrozen">
        <fa-icon class="Status__icon--frozen mr-1" [icon]="icons.frozen"></fa-icon>
        Card frozen
        <small *ngIf="card.lastFrozenAction.source === 'INSURER_DASHBOARD'">
          By
          <ps-link
            [disableLoadOnClick]="true"
            [href]="'mailto:' + card.lastFrozenAction.user.email"
            >{{ card.lastFrozenAction.user.email }}</ps-link
          ><br />
          <ng-container *ngIf="card.lastFrozenAction.comment"
            >Message: {{ card.lastFrozenAction.comment }}<br
          /></ng-container>
          <em>{{ card.lastFrozenAction.created | localDate }}</em>
        </small>
        <small *ngIf="card.lastFrozenAction.source === 'MOBILE_APP'">
          By beneficiary<br />
          <em>{{ card.lastFrozenAction.created | localDate }}</em>
        </small>
      </ng-container>
    </div>
    <!-- Status end -->

    <span class="ReplacementStatus" *ngIf="card.isReplacementOrdered">
      <small i18n="@@replacementStatus">
        Replacement ordered on
        {{ card.lastReplacedAction.created | localDate: 'MMM Do YYYY [at] HH:mm' }}
        <ng-container *ngIf="card.lastReplacedAction.source === 'INSURER_DASHBOARD'">
          by
          <ps-link
            [disableLoadOnClick]="true"
            [href]="'mailto:' + card.lastReplacedAction.user.email"
            >{{ card.lastReplacedAction.user.email }}</ps-link
          >
        </ng-container>
        <ng-container *ngIf="card.lastReplacedAction.source === 'MOBILE_APP'"
          >by beneficiary</ng-container
        >
      </small>
    </span>

    <div class="Actions btn-group" dropdown placement="right">
      <ps-button
        id="button-animated"
        btnClass="btn btn-secondary dropdown-toggle full-width medium"
        dropdownToggle
        i18n="@@cardActionsBtn"
        >Actions
        <span class="caret"></span>
      </ps-button>
      <ul
        *dropdownMenu
        id="dropdown-animated"
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-basic"
      >
        <li role="menuitem" *ngIf="!card.isFrozen">
          <a class="dropdown-item" (click)="freeze()" i18n="@@freezeCardLink"
            >Freeze Card <small class="text-muted">Beneficiary can unfreeze their card</small></a
          >
        </li>
        <li role="menuitem" *ngIf="card.isFrozen">
          <a class="dropdown-item" (click)="unfreeze()" i18n="@@unfreezeCardLink"
            >Unfreeze Card <small class="text-muted">The beneficiary will be notified</small></a
          >
        </li>
        <li role="menuitem" *ngIf="!card.isBlocked">
          <a class="dropdown-item" (click)="block()" i18n="@@blockCardLink"
            >Block Card <small class="text-muted">Beneficiary cannot unblock their card</small></a
          >
        </li>
        <li role="menuitem" *ngIf="card.isBlocked">
          <a class="dropdown-item" (click)="unblock()" i18n="@@unblockCardLink"
            >Unblock Card <small>The beneficiary will be notified</small></a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" (click)="replace()" i18n="@@orderReplacementLink"
            >Order Replacement
            <small class="text-muted"
              >This card will remain active unless frozen or blocked</small
            ></a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" (click)="showPin()" i18n="@@showPin">
            Show PIN
            <small class="text-muted">
              Reveal this card's PIN code
            </small>
          </a>
        </li>
      </ul>
    </div>
    <!-- Actions end -->

    <div class="Settings btn-group" dropdown placement="right">
      <ps-button
        id="button-animated"
        btnClass="btn btn-secondary dropdown-toggle full-width medium"
        dropdownToggle
        i18n="@@cardSettingsBtn"
        >Settings
        <span class="caret"></span>
      </ps-button>
      <ul
        *dropdownMenu
        id="dropdown-basic"
        class="dropdown-menu dropdown-menu-right"
        role="menu"
        aria-labelledby="button-basic"
      >
        <li role="menuitem">
          <a
            class="dropdown-item"
            (click)="showPaymentControls()"
            i18n="@@paymentControlsDropdownItem"
          >
            Payment Controls
            <small class="text-muted">Modify payment controls</small>
          </a>
        </li>
        <li>
          <a class="dropdown-item" (click)="showPaymentRules()" i18n="@@paymentRulesDropdownItem">
            Payment Rules
            <small class="text-muted">Modify payment rules</small>
          </a>
        </li>
      </ul>
    </div>
    <!-- Settings end -->
  </div>
</div>
