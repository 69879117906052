import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { StatisticsService } from './statistics.service';

export const StatisticsServiceConfig = new InjectionToken<ServiceConfig>('StatisticsServiceConfig');

@NgModule()
export class StatisticsServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<StatisticsServiceModule> {
    return {
      ngModule: StatisticsServiceModule,
      providers: [StatisticsService, { provide: StatisticsServiceConfig, useValue: config }]
    };
  }
}
