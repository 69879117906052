<div
  class="SearchResult"
  [@searchResult]
  [ngClass]="{
    'SearchResult--loading': result.loading,
    'SearchResult--disabled': result.disabled
  }"
>
  <div class="SearchResult__box Provider" *ngIf="result.isMerchant">
    <div class="SearchResult__type" i18n="searchResultTypeProviderHeading">
      <span class="glyphicon glyphicon-globe"></span> Provider
    </div>
    <div class="Provider__map" [style.backgroundImage]="mapBgImg('350x100', 13)"></div>
    <div class="SearchResult__inner">
      <h4 class="Provider__name SearchResult__heading" [innerHTML]="result.field('name')"></h4>
      <span class="Provider__address" [innerHTML]="result.field('address')"></span>
      <span
        *ngIf="result.merchant.isVerifiedByAdmin"
        class="Provider__status Provider__status--verified"
        i18n="@@searchResultTypeVerified"
      >
        <span class="glyphicon glyphicon-ok"></span> Verified
      </span>
      <span
        *ngIf="!result.merchant.isVerifiedByAdmin"
        class="Provider__status Provider__status--unverified"
        i18n="notVerified"
      >
        <span class="glyphicon glyphicon-warning-sign"></span> Not Verified
      </span>
    </div>
  </div>

  <div class="SearchResult__box Claim" *ngIf="result.isClaim">
    <div
      class="SearchResult__type"
      i18n="@@search result type Claim heading@@searchResultTypeClaimHeading"
    >
      <span class="glyphicon glyphicon-tag"></span> Claim
    </div>
    <div class="SearchResult__inner">
      <h4
        class="SearchResult__heading"
        i18n="
           search result claim content heading|displays claim search result information heading with
          price and provider, for example €1,000 at Some Hospital@@claimSearchResultContentHeading
        "
      >
        {{ result.claim.amount | currency: result.claim.currency.code }} at
        <span [innerHTML]="result.field('merchantName')"></span>
        <small>{{ result.claim.created | localDate }}</small>
      </h4>
      <table class="SearchResult__attributes">
        <tbody>
          <tr>
            <td i18n="@@beneficiary">Beneficiary</td>
            <td>
              <span [innerHTML]="result.field('beneficiaryName')"></span>
              <small *ngIf="result.matched('beneficiaryExternalId')"
                >ID: <span [innerHTML]="result.field('beneficiaryExternalId')"></span
              ></small>
            </td>
          </tr>

          <tr>
            <td i18n="@@status">Status</td>
            <td>
              <ps-claim-state-label
                [state]="result.claim.state"
                [tooltip]="false"
              ></ps-claim-state-label>
            </td>
          </tr>
          <tr>
            <td i18n="@@policyId">Policy ID</td>
            <td>
              <span [innerHTML]="result.field('policyExternalId')"></span>
            </td>
          </tr>
          <tr
            *ngIf="
              result.matched('policyHolder', 'policyHolderExternalId') ||
              !result.matched('corporationName', 'corporationExternalId')
            "
          >
            <td i18n="@@policyHolder">Policy Holder</td>
            <td>
              <span [innerHTML]="result.field('policyHolderName')"></span>
              <small *ngIf="result.matched('policyHolderExternalId')">
                ID:
                <span [innerHTML]="result.field('policyHolderExternalId')"></span
              ></small>
            </td>
          </tr>
          <tr *ngIf="result.matched('corporationName', 'corporationExternalId')">
            <td i18n="@@company">Company</td>
            <td>
              <span [innerHTML]="result.field('corporationName')"></span>
              <small *ngIf="result.matched('corporationExternalId')"
                >ID: <span [innerHTML]="result.field('corporationExternalId')"></span
              ></small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="SearchResult__box Policy" *ngIf="result.isPolicy">
    <div class="SearchResult__type" i18n="@@searchResultTypePolicyHeading">
      <span class="glyphicon glyphicon-list-alt"></span> Policy
    </div>
    <div class="SearchResult__inner">
      <h4
        class="SearchResult__heading"
        i18n="search result type Policy heading@@searchResultTypePolicyHeading"
      >
        Policy #<span [innerHTML]="result.field('externalId')"></span>
      </h4>
      <table class="SearchResult__attributes">
        <tbody>
          <tr>
            <td i18n="@@product">Product</td>
            <td>
              {{ result.policy.productVersion.product.name }}
              {{ result.policy.productVersion.productVersionVersion }}
            </td>
          </tr>
          <tr>
            <td i18n="@@policyHolder">Policy Holder</td>
            <td>
              <span [innerHTML]="result.field('policyHolderName')"></span>
              <small *ngIf="result.matched('policyHolderExternalId')"
                >ID: <span [innerHTML]="result.field('policyHolderExternalId')"></span
              ></small>
            </td>
          </tr>
          <tr *ngIf="result.hasField('corporationName')">
            <td i18n="@@company">Company</td>
            <td>
              <span [innerHTML]="result.field('corporationName')"></span>
              <small *ngIf="result.matched('corporationExternalId')"
                >ID: <span [innerHTML]="result.field('corporationExternalId')"></span
              ></small>
            </td>
          </tr>
          <tr *ngIf="result.matched('beneficiaryNames')">
            <td i18n="@@matchedBeneficiary">
              Matched Beneficiary
            </td>
            <td>
              <span [innerHTML]="result.field('beneficiaryNames')"></span>
            </td>
          </tr>
          <tr *ngIf="result.matched('beneficiaryExternalIds')">
            <td i18n="@@matchedBeneficiaryId">
              Matched Beneficiary ID
            </td>
            <td>
              <span [innerHTML]="result.field('beneficiaryExternalIds')"></span>
            </td>
          </tr>
          <tr>
            <td i18n="@@expires">Expires</td>
            <td>{{ result.policy.expiration | localDate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="SearchResult__box Product" *ngIf="result.isProductVersion">
    <div class="SearchResult__type" i18n="productVersionHeading">
      <span class="glyphicon glyphicon-dashboard"></span> Product Version
    </div>
    <div class="SearchResult__inner">
      <h4 class="SearchResult__heading" [innerHTML]="result.field('nameVersion')"></h4>
      <p class="Product__description">
        {{ result.productVersion.description | truncate: 100 }}
      </p>
      <table class="SearchResult__attributes">
        <tbody>
          <tr>
            <td>{{ result.productVersion.rootBenefit.title }}</td>
            <td>
              {{
                result.productVersion.rootBenefit.beneficiaryLimitAmount
                  | currency: result.productVersion.currency.code
              }}
            </td>
          </tr>
          <tr>
            <td i18n="@@benefitCount">
              Benefit Count
            </td>
            <td>{{ result.productVersion.benefits.length }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
