import gql from "graphql-tag";
import { CurrencyFragments } from '.';
import { BenefitFragments } from './benefit.fragments';
import { ProductFragments } from './product.fragments';

export const ProductVersionFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on ProductVersionType {
      id
      version
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on ProductVersionType {
      id
      product {
        ...productFields
      }
      version
      description
      currency {
        ...currencyFields
      }
      cardThreshold
      perClaimExcess
      isActive
      blockOnPendingClaim
      benefits {
        ...benefitFields
      }
    }
    ${CurrencyFragments.fat('currencyFields')}
    ${BenefitFragments.fat('benefitFields')}
    ${ProductFragments.slim('productFields')}
  `
};
