import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Platform, ClaimHistoryAction } from '@common/enums';
import { checkVal } from '@common/utils';
import { ClaimService } from '@common/services';
import { ModalProvider } from '@common/providers';
import { ClaimHistory, Benefit } from '@common/models';
import { ClaimNoteComponent } from '@common/components';

import { config } from '@app/config/config';

@Component({
  selector: 'ps-claim-history',
  templateUrl: './claim-history.component.html',
  styleUrls: ['./claim-history.component.scss']
})
export class ClaimHistoryComponent implements OnInit {
  loading = true;
  history: ClaimHistory[];
  icons = {
    plus: faPlus
  };

  // Positioning
  initialOffsetTop: number;
  fixed = false;
  height: number;

  @Output() onAddNote = new EventEmitter();
  @Output() onOpenReceipt = new EventEmitter();
  @Output() onOpenDocument = new EventEmitter();

  @Input() benefits: Benefit[];
  @Input() claimId: number;
  @Input() claimSource: Platform;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  containerEl: ViewContainerRef;

  constructor(private claimService: ClaimService, private modalService: ModalProvider) {}

  ngOnInit() {
    checkVal(this.claimId, 'ClaimHistoryComponent expected Claim ID');
    checkVal(this.benefits, 'ClaimHistoryComponent expected Benefits');

    this.loadHistory(this.claimId).subscribe(history => {
      this.history = history;
    });

    // Set initial offset
    const top = this.containerEl.element.nativeElement.getBoundingClientRect().top;
    this.initialOffsetTop = Math.abs(window.pageYOffset - Math.abs(top));
  }

  /**
   * Populates claim history entries with related User,
   * Merchant, and Benefit objects.
   */
  private loadHistory(claimId: number): Observable<ClaimHistory[]> {
    this.loading = true;

    return this.claimService.getHistory(claimId).pipe(
      map(history => {
        // Attach benefits objects to BenefitChanged events
        return history.map(h => {
          if (h.action === ClaimHistoryAction.BenefitChanged) {
            h.data.oldBenefit = this.benefits.find(b => b.id === h.data.oldBenefitId);
            h.data.newBenefit = this.benefits.find(b => b.id === h.data.newBenefitId);
          }
          return h;
        });
      }),
      finalize(() => (this.loading = false))
    );
  }

  /**
   * Reload claim history
   */
  reload() {
    if (this.loading) {
      return;
    }
    this.loadHistory(this.claimId).subscribe(history => {
      this.history = history;
    });
  }

  @HostListener('window:scroll')
  onScroll() {
    const scrollY = window.pageYOffset;
    const bitePoint = this.initialOffsetTop - config.navbarHeight;

    if (scrollY >= bitePoint && !this.fixed) {
      this.fixed = true;
      this.onResize();
    } else if (scrollY < bitePoint && this.fixed) {
      this.fixed = false;
      this.onResize();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.height = window.innerHeight - config.navbarHeight;
  }

  addNote() {
    this.onAddNote.emit();
  }

  viewNote(item: ClaimHistory) {
    this.modalService.open(ClaimNoteComponent, {
      title: 'Claim Note',
      text: item.data.text,
      timestamp: item.timestamp,
      userEmail: item.user.email
    });
  }

  viewClaimResetReason(item: ClaimHistory) {
    this.modalService.open(ClaimNoteComponent, {
      title: 'Claim Reset Reason',
      text: item.data.reason,
      timestamp: item.timestamp,
      userEmail: item.user.email
    });
  }

  viewDeclineReason(item: ClaimHistory) {
    this.modalService.open(ClaimNoteComponent, {
      title: 'Reason for Decline',
      text: item.data.reason,
      timestamp: item.timestamp,
      userEmail: item.user.email
    });
  }

  viewCancelReason(item: ClaimHistory) {
    this.modalService.open(ClaimNoteComponent, {
      title: 'Reason for Cancellation',
      text: item.data.reason,
      timestamp: item.timestamp,
      userEmail: item.user.email
    });
  }

  viewReceipt(item: ClaimHistory) {
    const validActions = [
      ClaimHistoryAction.ReceiptNewDecision,
      ClaimHistoryAction.ReceiptUploaded
    ];

    if (validActions.indexOf(item.action) === -1) {
      return;
    }

    this.onOpenReceipt.emit(item.data.documentId);
  }

  viewDocument(item: ClaimHistory) {
    const validActions = [
      ClaimHistoryAction.SupportingDocumentUploaded,
      ClaimHistoryAction.SupportingDocumentNewDecision
    ];

    if (!validActions.includes(item.action)) {
      return;
    }

    this.onOpenDocument.emit(item.data.documentId);
  }
}
