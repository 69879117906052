import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthProvider, ModalProvider } from '@common/providers';
import { SearchComponent } from '../search';
import Required from '@common/decorators/required';

export interface NavbarLink {
  text: string;
  to: string;
}

@Component({
  selector: 'ps-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() showSearch: boolean;
  @Input() showLogo = true;
  @Input() @Required('ps-navbar') links: NavbarLink[];
  @Output() onMenuToggle = new EventEmitter();

  isCollapsed = true;
  icons = {
    faSearch,
    faBars
  };

  constructor(
    private modalService: ModalProvider,
    private authProvider: AuthProvider,
    private router: Router,
    private notifications: NotificationsService
  ) {}

  logout() {
    this.authProvider.logout();
    this.router.navigate(['login']);
    this.notifications.info('Logged Out', 'You are now logged out');
  }

  openSearch() {
    this.modalService.open(SearchComponent);
  }
}
