import gql from 'graphql-tag';

export const ClaimBreakdownFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on ClaimBreakdownType {
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on ClaimBreakdownType {
      policyWideExcess
      perClaimExcess
      benefitLevelExcess
      coPayment
      covered
    }
  `
};
