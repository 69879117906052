import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { LimitAmountStringPipe } from './limit-amount-string.pipe';

@NgModule({
  declarations: [LimitAmountStringPipe],
  exports: [LimitAmountStringPipe],
  providers: [CurrencyPipe]
})
export class LimitAmountStringPipeModule {}
