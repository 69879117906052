<table class="table SettlementEvents">
  <tbody>
    <tr *ngFor="let event of settlementEvents">
      <td width="160">
        {{ event.transactionTime | localDate }}
      </td>
      <td>
        {{ eventTypeDict[event.eventType] || event.eventType }}
      </td>
      <td class="lastCell">
        <span>
          {{ event.amount | currency: currencyCode }}
        </span>
      </td>
    </tr>
  </tbody>
</table>
