import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { AgmCoreModule } from '@agm/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { environment } from '@environment';
import { ProviderPickerComponent } from './provider-picker.component';
import { ButtonComponentModule } from '../button';
import { TruncatePipeModule } from '../../pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NguiAutoCompleteModule,
    TypeaheadModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places']
    }),
    FontAwesomeModule,
    TruncatePipeModule,
    ButtonComponentModule
  ],
  declarations: [ProviderPickerComponent],
  exports: [ProviderPickerComponent]
})
export class ProviderPickerComponentModule {}
