export enum DocumentFileType {
  Receipt = 'RECEIPT',
  GuaranteeOfPayment = 'GUARANTEE_OF_PAYMENT',
  CustomerAgreement = 'CUSTOMER_AGREEMENT',
  SignedCustomerAgreement = 'SIGNED_CUSTOMER_AGREEMENT',
  EstimatedBill = 'ESTIMATED_BILL',
  InterimBill = 'INTERIM_BILL',
  FinalBill = 'FINAL_BILL',
  Other = 'OTHER'
}
