import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { config } from '@app/config/config';
import { ConfigValueService } from '@common/services';
import { ConfigValue } from '@common/models';
import { ConfigValueKey } from '@common/enums';

interface Status {
  name: string;
  color: string;
  description: string;
  expanded: boolean;
}

@Component({
  selector: 'ps-system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss']
})
export class SystemStatusComponent implements OnInit, OnDestroy {
  statuses: Status[];
  private pollTimeout: ReturnType<typeof setTimeout>;

  constructor(private configValueService: ConfigValueService, private zone: NgZone) {}

  ngOnInit() {
    // Start polling for status updates
    this.poll();
  }

  ngOnDestroy() {
    clearTimeout(this.pollTimeout);
  }

  /**
   * Retreive the system statuses
   */
  private loadSystemStatus(): Observable<Status[]> {
    return this.configValueService.getAll().pipe(
      map(values => {
        // Extract only certain keys, in this order
        const arr: ConfigValue[] = [];
        arr.push(values.find(v => v.key === ConfigValueKey.TrafficLightCore));
        arr.push(values.find(v => v.key === ConfigValueKey.TrafficLightMobileApp));
        arr.push(values.find(v => v.key === ConfigValueKey.TrafficLightPaymentPlatform));

        return arr.map(item => {
          const status: Status = {
            name: null,
            description: null,
            color: item.value,
            expanded: false
          };

          switch (item.key) {
            case ConfigValueKey.TrafficLightCore:
              status.name = 'Core Services';
              status.description = values.find(
                v => v.key === ConfigValueKey.TrafficLightCoreDesc
              ).value;
              break;
            case ConfigValueKey.TrafficLightMobileApp:
              status.name = 'Mobile App';
              status.description = values.find(
                v => v.key === ConfigValueKey.TrafficLightMobileAppDesc
              ).value;
              break;
            case ConfigValueKey.TrafficLightPaymentPlatform:
              status.name = 'Payment Platform';
              status.description = values.find(
                v => v.key === ConfigValueKey.TrafficLightPaymentPlatformDesc
              ).value;
              break;
          }

          return status;
        });
      })
    );
  }

  /**
   * Poll for status updates
   * As this uses a timer, we must run the timer outside
   * the angular zone to avoid slowing down our e2e tests.
   */
  private poll(): void {
    this.loadSystemStatus().subscribe(statuses => {
      this.statuses = statuses;

      // Run timeout outside Angular zone
      this.zone.runOutsideAngular(() => {
        this.pollTimeout = setTimeout(() => {
          // Poll should run within zone
          this.zone.run(() => this.poll());
        }, config.systemStatusPollInterval);
      });
    });
  }

  /**
   * Expand/collapse status
   * @param status
   */
  toggle(status: Status) {
    // Collapse other statuses
    this.statuses.filter(s => s.name !== status.name).forEach(s => (s.expanded = false));

    status.expanded = !status.expanded;
  }
}
