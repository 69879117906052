import { Injectable } from '@angular/core';
import { Currency } from '../../models';
import { GQLService } from '../common';
import { Apollo } from 'apollo-angular';
import { CurrencyFragments } from '@common/fragments';

@Injectable({ providedIn: 'root' })
export class CurrencyService extends GQLService<Currency> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: Currency,
      singular: 'currency',
      plural: 'currencies',
      fragments: CurrencyFragments
    });
  }
}
