import { Component, ViewChild } from '@angular/core';

import { ModalDialog } from '../../interfaces';
import { checkVal } from '../../utils';
import { ModalComponent } from '../modal/modal.component';

interface AlertButton {
  text: string;
  role?: 'cancel';
  handler?: () => boolean;
}

export interface AlertComponentInput {
  title: string;
  text: string;
  buttons: AlertButton[];
}

@Component({
  selector: 'ps-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements ModalDialog {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  title: string;
  text: string;
  buttons: AlertButton[];

  constructor() {}

  initModal(data) {
    this.title = checkVal(data['title'], 'AlertComponent expected title');
    this.text = checkVal(data['text'], 'AlertComponent expected text');
    this.buttons = checkVal(data['buttons'], 'AlertComponent expected buttons');
  }

  getButtonClass(btn: AlertButton): string {
    if (btn.role === 'cancel') {
      return 'btn btn-secondary';
    } else {
      return 'btn btn-primary';
    }
  }

  handleButtonClick(btn: AlertButton): void {
    let shouldClose = btn.role === 'cancel';

    if (btn.handler) {
      shouldClose = btn.handler();
    }

    if (shouldClose) {
      this.modal.close();
    }
  }
}
