import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { Statistics } from '@common/models';
import { map, take } from 'rxjs/operators';
import { FundingAccount } from '@common/models/funding-account.model';
import { InsurerFundsAmount } from '@common/models/insurer-funds-amount.model';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor(private apollo: Apollo) {}

  getFundingAccount(): Observable<FundingAccount> {
    return this.apollo
      .watchQuery({
        query: gql`
          {
            fundingAccounts {
              results {
                statistics {
                  claimsAwaitingActionCount {
                    pendingAuthorization
                    pendingReview
                    stagnant
                  }
                  claimsCount {
                    preAuthorizations {
                      today
                      thisWeek
                      thisMonth
                      allTime
                    }
                    all {
                      today
                      thisWeek
                      thisMonth
                      allTime
                    }
                    instant {
                      today
                      thisWeek
                      thisMonth
                      allTime
                    }
                  }
                  averageReviewDuration {
                    allTime {
                      preAuthorization {
                        durationWorkHours
                        duration
                        target
                      }
                      receipt {
                        durationWorkHours
                        duration
                        target
                      }
                    }
                    lastWeek {
                      preAuthorization {
                        durationWorkHours
                        duration
                        target
                      }
                      receipt {
                        durationWorkHours
                        duration
                        target
                      }
                    }
                    lastMonth {
                      preAuthorization {
                        durationWorkHours
                        duration
                        target
                      }
                      receipt {
                        durationWorkHours
                        duration
                        target
                      }
                    }
                    today {
                      preAuthorization {
                        durationWorkHours
                        duration
                        target
                      }
                      receipt {
                        durationWorkHours
                        duration
                        target
                      }
                    }
                  }
                  timeSaved
                  insurerFundsAmounts {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        `
      })
      .valueChanges.pipe(
        take(1),
        map((result: any) =>
          new FundingAccount().deserialize(result.data.fundingAccounts.results[0])
        )
      );
  }

  getInsurerFundsAmounts(): Observable<InsurerFundsAmount[]> {
    return this.apollo
      .watchQuery({
        query: gql`
          {
            fundingAccounts {
              results {
                statistics {
                  insurerFundsAmounts {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        `
      })
      .valueChanges.pipe(
        take(1),
        map((result: any) => {
          const fundingAccounts = result.data.fundingAccounts.results;
          return (
            fundingAccounts
              // Ensure that all results contain insurer funds data
              .filter(
                (item: FundingAccount) =>
                  item.statistics.insurerFundsAmounts &&
                  item.statistics.insurerFundsAmounts.length > 0
              )
              .map((item: FundingAccount) =>
                // each FundingAccount contains exactly one record within insurerFundsAmount array
                new InsurerFundsAmount().deserialize(item.statistics.insurerFundsAmounts[0])
              )
          );
        })
      );
  }

  getStatistics(): Observable<Statistics> {
    return this.getFundingAccount().pipe(map(fundingAccount => fundingAccount.statistics));
  }
}
