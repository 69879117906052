import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { IcdCodeService } from './icd-code.service';

export const IcdCodeServiceConfig = new InjectionToken<ServiceConfig>('IcdCodeServiceConfig');

@NgModule()
export class IcdCodeServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<IcdCodeServiceModule> {
    return {
      ngModule: IcdCodeServiceModule,
      providers: [IcdCodeService, { provide: IcdCodeServiceConfig, useValue: config }]
    };
  }
}
