import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { RulesSetService } from './rules-set.service';

export const RulesSetServiceConfig = new InjectionToken<ServiceConfig>('RulesSetServiceConfig');

@NgModule()
export class RulesSetServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<RulesSetServiceModule> {
    return {
      ngModule: RulesSetServiceModule,
      providers: [RulesSetService, { provide: RulesSetServiceConfig, useValue: config }]
    };
  }
}
