import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalComponent } from './modal.component';
import { ButtonComponentModule } from '../button';

@NgModule({
  imports: [CommonModule, ButtonComponentModule],
  declarations: [ModalComponent],
  entryComponents: [ModalComponent],
  exports: [ModalComponent]
})
export class ModalComponentModule {}
