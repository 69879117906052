import { Model } from './model';

export class Country extends Model {
  id: number;
  name: string;
  code: string;

  constructor() {
    super(['id', 'name', 'code']);
  }
}
