import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigValue } from '../../models';
import { Service, ServiceConfig } from '../common';
import { ConfigValueServiceConfig } from './config-value.service.module';
import { ConfigValueKey } from '@common/enums';

@Injectable({ providedIn: 'root' })
export class ConfigValueService extends Service {
  constructor(http: HttpClient, @Inject(ConfigValueServiceConfig) serviceConfig: ServiceConfig) {
    super({
      httpClient: http,
      apiEndpoint: `${serviceConfig.baseUrl}/config-values`
    });
  }

  getAll(): Observable<ConfigValue[]> {
    return this.request<any[]>({ method: 'GET' }).pipe(
      map(res => res.map(data => new ConfigValue().deserialize(data)))
    );
  }

  get(key: ConfigValueKey): Observable<ConfigValue> {
    return this.getAll().pipe(map(vals => {
      return vals.find(v => v.key === key);
    }));
  }
}
