
/**
 * Make a component's input attribute required
 */
function Required(componentName?: string) {
  return (target: object, propertyKey: string) => {
    Object.defineProperty(target, propertyKey, {
      get () {
        throw new Error(`Attribute ${componentName}[${propertyKey}] is required`);
      },
      set (value) {
        Object.defineProperty(target, propertyKey, {
          value, writable: true, configurable: true,
        });
      },
      configurable: true
    });
  }
}

export default Required;
