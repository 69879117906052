import { Injectable, NgZone, Inject, forwardRef } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService as AngularNotifications } from 'angular2-notifications';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Notification } from '../../models';
import { NotificationService } from '../../services/notification.service';
import { AuthProvider } from '../auth.provider';
import {
  NotificationManagerConfig,
  INotificationManagerConfig
} from './notification-manager.module';

@Injectable({ providedIn: 'root' })
export class NotificationManager {
  /**
   * The current number of unread notifications
   */
  unread: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * Fired when a new notification is received
   */
  onNotificationsReceived = new Subject();

  private enabled = false;

  constructor(
    @Inject(forwardRef(() => NotificationService)) private notificationService: NotificationService,
    private angularNotifications: AngularNotifications,
    private router: Router,
    @Inject(forwardRef(() => AuthProvider)) private authProvider: AuthProvider,
    private zone: NgZone,
    @Inject(NotificationManagerConfig) private config: INotificationManagerConfig
  ) {
    // TODO: Temporarily disabled polling as it's polluting my console with errors
    if (this.authProvider.loggedIn) {
      this.initUnreadCount();
      this.startPolling();
    }

    this.authProvider.onLogout.subscribe(() => {
      this.stopPolling();
    });

    this.authProvider.onLogin.subscribe(() => {
      this.initUnreadCount();
      this.startPolling();
    });
  }

  private initUnreadCount() {
    this.notificationService.getUnreadCount().subscribe(count => {
      this.unread.next(count);
    });
  }

  private poll(firstPoll: boolean, lastId?: number) {
    if (!this.enabled) {
      return;
    }

    const pollInterval = this.config.pollInterval;

    this.notificationService.getNotifications(true, lastId).subscribe(
      notifications => {
        // If no new notifications
        if (notifications.length === 0) {
          this.nonBlockingTimeout(() => this.poll(false, lastId), pollInterval);
          return;
        }

        // Show new notifications
        if (!firstPoll) {
          notifications.forEach(notification => {
            this.notify(notification);

            // Augment unread count
            this.augmentUnread();
          });

          // Notify observers
          this.onNotificationsReceived.next();
        }

        // Poll again
        const newLastId = notifications[0].id;
        this.nonBlockingTimeout(() => this.poll(false, newLastId), pollInterval);
      },
      () => {
        // Error.. Don't give up!
        this.nonBlockingTimeout(() => this.poll(firstPoll, lastId), pollInterval);
      }
    );
  }

  private notify(notification: Notification) {
    if (notification.isReceiptUpload) {
      this.showToast('Receipt Uploaded', 'A new receipt was uploaded to a claim.');
    } else if (notification.isNewClaim) {
      this.showToast('Claim Submitted', 'A new claim was submitted for pre-authorization.');
    } else if (notification.isAuthorizationReferral) {
      this.showToast(
        'Claim Authorization Referral',
        'A claim was referred for authorization by another user.'
      );
    }
  }

  private showToast(title: string, body: string) {
    const toast = this.angularNotifications.info(title, body);
    toast.click.subscribe(() => {
      this.router.navigate(['dashboard', 'notifications']);
    });
  }

  private startPolling() {
    this.enabled = true;
    this.poll(true);
  }

  private stopPolling() {
    this.enabled = false;
  }

  /**
   * Non-blocking timeout
   * The timer runs outside the Angular zone and callbacks
   * are run within it. This prevents the timeouts from
   * freezing up e2e tests.
   * @param callback
   * @param delay
   */
  private nonBlockingTimeout(callback: any, delay: number): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.zone.run(() => callback());
      }, delay);
    });
  }

  augmentUnread() {
    this.unread.pipe(take(1)).subscribe(unread => {
      this.unread.next(unread + 1);
    });
  }

  decrementUnread() {
    this.unread.pipe(take(1)).subscribe(unread => {
      this.unread.next(unread - 1);
    });
  }
}
