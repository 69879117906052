import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { CountryService } from './country.service';
import { ServiceConfig } from '../common';

export const CountryServiceConfig = new InjectionToken<ServiceConfig>('CountryServiceConfig');

@NgModule()
export class CountryServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<CountryServiceModule> {
    return {
      ngModule: CountryServiceModule,
      providers: [CountryService, { provide: CountryServiceConfig, useValue: config }]
    };
  }
}
