import { Component, OnInit, Input } from '@angular/core';

import { checkVal } from '@common/utils';
import { Payment } from '@common/models';
import { PaymentState } from '@common/enums';

@Component({
  selector: 'ps-payment-state-label',
  templateUrl: './payment-state-label.component.html',
  styleUrls: ['./payment-state-label.component.scss']
})
export class PaymentStateLabelComponent implements OnInit {
  @Input() state: PaymentState;

  constructor() {}

  ngOnInit() {
    checkVal(this.state, 'PaymentStateLabelComponent expected state');
  }

  get className(): string {
    switch (this.state) {
      case PaymentState.Settled:
        return 'badge-success';
      case PaymentState.Authorized:
        return 'badge-info';
      case PaymentState.Cancelled:
      case PaymentState.Declined:
        return 'badge-danger';
      case PaymentState.Pending:
        return 'badge-warning';
      default:
        return 'badge-default';
    }
  }

  get stateString(): string {
    return Payment.stateToString(this.state);
  }
}
