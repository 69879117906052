import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { NotificationsService as AngularNotifications } from 'angular2-notifications';
import { NotificationManager } from './notification-manager';

export interface INotificationManagerConfig {
  pollInterval: number;
}

export const NotificationManagerConfig = new InjectionToken<INotificationManagerConfig>(
  'NotificationManagerConfig'
);

@NgModule()
export class NotificationManagerModule {
  public static forRoot(config: INotificationManagerConfig): ModuleWithProviders<NotificationManagerModule> {
    return {
      ngModule: NotificationManagerModule,
      providers: [
        NotificationManager,
        AngularNotifications,
        { provide: NotificationManagerConfig, useValue: config }
      ]
    };
  }
}
