import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ClaimService } from './claim.service';
import { ServiceConfig } from '../common';

export const ClaimServiceConfig = new InjectionToken<ServiceConfig>('ClaimServiceConfig');

@NgModule()
export class ClaimServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<ClaimServiceModule> {
    return {
      ngModule: ClaimServiceModule,
      providers: [ClaimService, { provide: ClaimServiceConfig, useValue: config }]
    };
  }
}
