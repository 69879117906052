import { Model } from './model';

export class BeneficiaryDataUnlock extends Model {
  id: string;
  createdAt: string;
  usedAt: string;
  isEnabled: boolean;
  provider: { id: string };
  beneficiary: { id: string };

  constructor() {
    super(['id', 'createdAt', 'usedAt', 'isEnabled', 'provider', 'beneficiary']);
  }
}
