import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SearchComponent } from './search.component';
import { SearchResultComponent } from './search-result.component';
import { LocalDatePipeModule, TruncatePipeModule } from '@common/pipes';
import { ModalProviderModule } from '@common/providers';
import { SearchServiceModule } from '@common/services';
import { ClaimStateLabelComponentModule } from '../claim-state-label';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LocalDatePipeModule,
    ClaimStateLabelComponentModule,
    TruncatePipeModule,
    ModalProviderModule,
    SearchServiceModule,
    TooltipModule,
    FontAwesomeModule
  ],
  declarations: [SearchComponent, SearchResultComponent],
  entryComponents: [SearchComponent]
})
export class SearchComponentModule {}
