import {
  Injectable,
  Inject,
  ComponentFactoryResolver,
  ViewContainerRef,
  ComponentRef
} from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModalProvider {
  modals: ComponentRef<any>[] = [];
  modalCloseSubjects: Subject<any>[] = [];
  componentFactoryResolver: ComponentFactoryResolver;
  viewContainerRef: ViewContainerRef;

  constructor(@Inject(ComponentFactoryResolver) componentFactoryResolver) {
    this.componentFactoryResolver = componentFactoryResolver;
  }

  updateContext(cfr: ComponentFactoryResolver): void {
    this.componentFactoryResolver = cfr;
  }

  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  open(component: any, data?: any): Subject<any> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = this.viewContainerRef.createComponent(componentFactory);

    if (typeof data !== 'undefined') {
      (<any>componentRef.instance).initModal(data);
    } else {
      (<any>componentRef.instance).initModal({});
    }

    const modalCloseSubject = new Subject();

    this.modals.push(componentRef);
    this.modalCloseSubjects.push(modalCloseSubject);

    return modalCloseSubject;
  }

  pop(data?: any) {
    const componentRef = this.modals.pop();
    const modalCloseSubject = this.modalCloseSubjects.pop();

    modalCloseSubject.next(data ? data : {});
    componentRef.destroy();
  }
}
