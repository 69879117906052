import { Model } from './model';

export class InsurerFundsAmount extends Model {
  insurer: string;
  currencyCode: string;
  amount: number;

  constructor() {
    super(['insurer', 'currencyCode', 'amount']);
  }
}
