import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, empty } from 'rxjs';

import { GQLService } from '../common';
import { Card } from '../../models';
import { CardFragments } from '@common/fragments';
import gql from 'graphql-tag';
import { config } from '@app/config/config';
import { objToGql } from '@common/utils';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CardService extends GQLService<Card> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: Card,
      singular: 'card',
      plural: 'cards',
      fragments: CardFragments
    });
  }

  private createAction(cardId: number, action: string, comment?: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          cardActionCreate(newCardAction: {
            ${objToGql({
              cardId,
              source: config.apiSource,
              action,
              comment
            }, ['source', 'action'])}
          }) {
            ok
          }
        }
      `
    }).pipe(map((res: any) => {
      const data = res.data.cardActionCreate;
      if (data.ok) {
        return true
      } else {
        throw new Error(`Couldn't create card action. Card #${cardId}, Action: ${action}`);
      }
    }));
  }

  freeze(cardId: number, comment?: string): Observable<string> {
    return this.createAction(cardId, 'FREEZE', comment);
  }

  unfreeze(cardId: number, comment?: string): Observable<string> {
    return this.createAction(cardId, 'UNFREEZE', comment);
  }

  block(cardId: number, comment?: string): Observable<string> {
    return this.createAction(cardId, 'BLOCK', comment);
  }

  unblock(cardId: number, comment?: string): Observable<string> {
    return this.createAction(cardId, 'UNBLOCK', comment);
  }

  replace(cardId: number, comment?: string): Observable<string> {
    return this.createAction(cardId, 'REPLACEMENT', comment);
  }
}
