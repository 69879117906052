import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LoaderProvider } from '@common/providers';

@Component({
  selector: 'ps-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent {
  @Input() loaderId?: string;
  @Input() routerLink?: any;
  @Input() queryParams?: any;
  @Input() href?: any;
  @Input() isLoading?: boolean;
  @Input() linkClass?: String;
  @Input() glyphicon?: String;
  @Input() loaderPosition = 'before';
  @Input() disableLoadOnClick?: boolean;
  @Input() target = '_self';
  @Input() icon?: any;
  @Input() iconClass?: string;

  @Output() onClick = new EventEmitter<any>();

  constructor(private loaderService: LoaderProvider) {}

  onLinkClick(event: MouseEvent) {
    if (!this.disableLoadOnClick) {
      if (this.loaderId) {
        this.loaderService.startLoading(this.loaderId);
      } else {
        this.isLoading = true;
      }
    }
    this.onClick.emit(event);
  }

  get loading() {
    return this.isLoading || this.loaderService.isLoading(this.loaderId);
  }
}
