import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';

import { GQLService } from '../common';
import { PaymentRulesOverride } from '@common/models';
import { PaymentRulesOverrideFragments } from '@common/fragments';
import { arrayToGql, valToGql, objToGql } from '@common/utils';

@Injectable({ providedIn: 'root' })
export class PaymentRulesOverrideService extends GQLService<PaymentRulesOverride> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: PaymentRulesOverride,
      singular: 'paymentRulesOverride',
      plural: 'paymentRulesOverrides',
      fragments: PaymentRulesOverrideFragments
    });
  }

  createRulesDisable(cardProxyId: string, disabledRules: string[]): Observable<any> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          paymentRulesOverrideCreate(newRulesDisable: {
            cardProxyId: ${valToGql(cardProxyId)},
            override: ${arrayToGql(disabledRules)}
          }) {
            ok
          }
        }
      `
      })
      .pipe(
        map((res: any) => {
          if (res.data.paymentRulesOverrideCreate.ok) {
            return true;
          } else {
            throw new Error(
              `Couldn't create rulesDisable rules entity for cardProxyId: ${cardProxyId}`
            );
          }
        })
      );
  }

  updateRulesOverride(id: number, body: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          paymentRulesOverrideUpdate(newPaymentRulesOverride: {
            id: ${id},
            ${objToGql(body)}
          }) {
            ok
          }
        }
      `
      })
      .pipe(
        map((res: any) => {
          if (res.data.paymentRulesOverrideUpdate.ok) {
            return true;
          } else {
            throw new Error(`Couldn't update rules disable override #${id}`);
          }
        })
      );
  }

  createCardOverride(cardProxyId: string, override: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          paymentRulesOverrideCreate(newCardOverride: {
            cardProxyId: ${valToGql(cardProxyId)},
            override: {${objToGql(override)}}
          }) {
            ok
            paymentrulesoverride {
              ...paymentRulesOverrideFields
            }
          }
        }
        ${PaymentRulesOverrideFragments.fat('paymentRulesOverrideFields')}
      `
      })
      .pipe(
        map((res: any) => {
          if (res.data.paymentRulesOverrideCreate.ok) {
            return new PaymentRulesOverride().deserialize(
              res.data.paymentRulesOverrideCreate.paymentrulesoverride
            );
          } else {
            throw new Error(`Couldn't create card override entity for cardProxyId: ${cardProxyId}`);
          }
        })
      );
  }

  deleteCardOverride(id: number): Observable<any> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          paymentRulesOverrideDelete(id: ${id}) {
            ok
          }
        }
      `
      })
      .pipe(
        map((res: any) => {
          if (res.data.paymentRulesOverrideDelete.ok) {
            return true;
          } else {
            throw new Error(`Couldn't delete card override #${id}`);
          }
        })
      );
  }
}
