import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * The base http service
 */
export abstract class Service {
  private http: HttpClient;
  protected apiEndpoint: string;

  constructor(config: { httpClient: HttpClient; apiEndpoint: string }) {
    this.http = config.httpClient;
    this.apiEndpoint = config.apiEndpoint;
  }

  /**
   * Create fully qualified URL from supplied path
   * @param path
   */
  private makeUrl(path?: string): string {
    // Append path to url
    let url = this.apiEndpoint;
    if (path) {
      url += path;
    }

    return url;
  }

  /**
   * Make HttpParams object from primitive object
   * @param params
   */
  private makeHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(key => {
        httpParams = httpParams.append(key, params[key]);
      });
    }
    return httpParams;
  }

  /**
   * Perform an HTTP request
   * @param params
   * @param params.type GET, POST, PUT, or DELETE
   * @param params.path Path to append to URL
   * @param params.url Fully qualified URL (overrides path)
   * @param params.body Body of the request
   * @param params.params Query parameters
   */
  protected request<ResponseType>(params: {
    method: string;
    path?: string;
    url?: string;
    body?: any;
    params?: any;
  }): Observable<ResponseType> {
    let url: string;
    if (typeof params.url !== 'undefined') {
      url = params.url;
    } else {
      url = this.makeUrl(params.path);
    }

    switch (params.method) {
      case 'GET':
        return this.http.get<ResponseType>(url, {
          params: this.makeHttpParams(params.params)
        });
      case 'POST':
        return this.http.post<ResponseType>(url, params.body);
      case 'PUT':
        return this.http.put<ResponseType>(url, params.body);
      case 'DELETE':
        return this.http.delete<ResponseType>(url);
      default:
        throw new Error(`Invalid request type: ${params.method}`);
    }
  }
}
