import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'limitAmountString' })
export class LimitAmountStringPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  /**
   * @param amount The limit amount
   * @param currencyCode Optional currency code
   * @returns 'Unlimited' if the limit amount is -1, stringified input amount
   * with currency symbol if the limit is a number, or '-' blank indicator
   */
  transform(amount: number, currencyCode = 'USD'): string {
    if (amount === -1) {
      return 'Unlimited';
    }
    return Number.isFinite(amount) ? this.currencyPipe.transform(amount, currencyCode) : '-';
  }
}
