<div class="Login">
  <div class="wrapper">
    <img src="/assets/img/paysure-transparent.svg" width="150" class="img-fluid d-flex" />
    <div class="card">
      <div class="card-body">
        <div class="alert alert-danger" *ngIf="invalidCreds" i18n="@@invalidEmailOrPasswordWarning">
          Invalid email address or password.
        </div>
        <div
          class="alert alert-danger"
          *ngIf="unexpectedError"
          i18n="@@unexpetedErrorOccurredWarning"
        >
          An unexpected error occurred. Please contact your administrator.
        </div>

        <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
          <div
            class="form-group"
            [ngClass]="{
              'has-error': submitted && loginForm.get('email').invalid
            }"
          >
            <label for="email" i18n="@@emailAddressLabel">Email address</label>
            <input
              type="email"
              class="form-control"
              formControlName="email"
              placeholder="Email"
              i18n-placeholder="@@emailPholder"
            />
            <span
              class="invalid-feedback"
              *ngIf="submitted && loginForm.get('email').invalid"
              i18n="@@enterValidEmailAddressWarning"
              >Enter a valid email address</span
            >
          </div>

          <div
            class="form-group"
            [ngClass]="{
              'has-error': submitted && loginForm.get('password').invalid
            }"
          >
            <label for="password" i18n="@@passwordLabel">Password</label>
            <input
              type="password"
              class="form-control"
              formControlName="password"
              placeholder="Password"
              i18n-placeholder="@@passwordPholder"
            />
            <span
              class="invalid-feedback"
              *ngIf="submitted && loginForm.get('password').invalid"
              i18n="@@enterYourPasswordWarning"
              >Enter your password</span
            >
          </div>

          <ps-button
            type="submit"
            btnClass="btn btn-primary"
            i18n="@@submit"
            [isLoading]="submitting"
            loaderPosition="after"
            >Submit</ps-button
          >
        </form>
      </div>
    </div>
  </div>
</div>
