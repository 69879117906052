import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SimpleNotificationsModule } from 'angular2-notifications';

import { ButtonComponentModule, LinkComponentModule } from '@common/components';

import { LoginComponent } from './login.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SimpleNotificationsModule,
    ButtonComponentModule,
    LinkComponentModule
  ],
  declarations: [LoginComponent]
})
export class LoginComponentModule {}
