import { Component, Input, OnInit } from '@angular/core';

import {
  faAngleDown,
  faAngleUp,
  faCaretDown,
  faCaretRight,
  faCaretUp
} from '@fortawesome/free-solid-svg-icons';

import { PaymentAuthorization } from '../../../models';

@Component({
  selector: 'ps-insurer-payments',
  templateUrl: './insurer-payments.component.html',
  styleUrls: ['./insurer-payments.component.scss']
})
export class InsurerPaymentsComponent implements OnInit {
  @Input() insurerPayments: PaymentAuthorization[];
  @Input() canViewClaim: boolean;

  icons = {
    caredDown: faAngleDown,
    caretUp: faAngleUp
  };

  constructor() {}

  ngOnInit() {}

  toggle(paymentAuthorization: PaymentAuthorization) {
    paymentAuthorization.expanded = !paymentAuthorization.expanded;
  }
}
