<div class="page-wrap">
  <div class="content-wrapper text-center">
    <span class="heading" i18n="@@serverErrorPageHeading">Server Error</span>
    <p i18n="@@serverErrorPageDescription">
      Looks like it's time for a coffee break. Our team has been notified and we should be back by
      the time you finish.
    </p>
    <p i18n="@@serverErrorPageNavigation">
      Try again by clicking
      <a routerLink="/dashboard" class="btn-link">here</a>.
    </p>
  </div>
</div>
