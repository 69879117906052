import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { NotificationService } from './notification.service';
import { ServiceConfig } from '../common';

export const NotificationServiceConfig = new InjectionToken<ServiceConfig>(
  'NotificationServiceConfig'
);

@NgModule()
export class NotificationServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<NotificationServiceModule> {
    return {
      ngModule: NotificationServiceModule,
      providers: [NotificationService, { provide: NotificationServiceConfig, useValue: config }]
    };
  }
}
