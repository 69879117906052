import { Model } from './model';
import { PaymentRuleType } from '../enums';
import { Rule } from './rule.model';

export class RulesSet extends Model {
  id: number;
  name: string;
  productVersionId: number;
  description: string;
  rules: Rule[];

  constructor() {
    super([
      'id',
      'name',
      'productVersionId',
      'description',
      { key: 'rules', useModel: Rule }
    ]);
  }

  getRule(ruleType: PaymentRuleType): Rule {
    return this.rules.find(rule => rule.ruleType === ruleType);
  }
}
