import { Model } from '@common/models';
import { PaymentAuthReversedBy, PaymentRule, PaymentState } from '../enums';
import { PaymentSettlement } from './payment-settlement.model';
import { parseJSON } from '@common/utils/model-transforms';

class MatchingExplanation extends Model {
  ruleType: PaymentRule;
  score: number;
  result: boolean;
  skipped: boolean;
  actualRaw: any;
  expectedRaw: any;

  constructor() {
    super([
      'ruleType',
      'score',
      'result',
      'skipped',
      { key: 'expectedRaw', transform: parseJSON },
      { key: 'actualRaw', transform: parseJSON }
    ]);
  }

  get passed(): boolean {
    return this.result;
  }

  get actual() {
    return this.actualRaw;
  }

  get expected() {
    return this.expectedRaw;
  }
}

export class PreAuthMatching extends Model {
  claimId: number;
  success: boolean;
  matchingExplanation: MatchingExplanation[];

  expanded = false;

  constructor() {
    super(['claimId', 'success', { key: 'matchingExplanation', useModel: MatchingExplanation }]);
  }
}

export class AppliedRule extends Model {
  ruleType: PaymentRule;
  result: boolean;
  skipped: boolean;
  actualRaw: any;
  expectedRaw: any;

  constructor() {
    super([
      'ruleType',
      'result',
      'skipped',
      { key: 'actualRaw', transform: parseJSON },
      { key: 'expectedRaw', transform: parseJSON }
    ]);
  }

  get passed(): boolean {
    return this.result;
  }

  get actual() {
    return this.actualRaw;
  }

  get expected() {
    return this.expectedRaw;
  }
}

export class Reversal extends Model {
  id: number;
  createdAt: string;
  amount: number;
  reversedBy: PaymentAuthReversedBy;

  constructor() {
    super(['id', 'createdAt', 'amount', 'reversedBy']);
  }
}

export class PaymentAuthorizationAttempt extends Model {
  id: number;
  amount: number;
  fxRate: number;
  transactionTime: string;
  decisionResult: boolean;
  merchantName: string;
  createdAt: string;
  appliedRules: AppliedRule[];
  preAuthMatching: PreAuthMatching[];
  reversals: Reversal[];

  expanded = false;

  constructor() {
    super([
      'id',
      'amount',
      'fxRate',
      'transactionTime',
      'merchantName',
      'decisionResult',
      'createdAt',
      { key: 'appliedRules', useModel: AppliedRule },
      { key: 'preAuthMatching', useModel: PreAuthMatching },
      { key: 'reversals', useModel: Reversal }
    ]);
  }
}

export class PaymentAuthorization extends Model {
  id: number;
  authorizationId: number;
  currencyCode: string;
  merchantName: string;
  createdAt: string;
  attempts: PaymentAuthorizationAttempt[];
  settlement: PaymentSettlement;
  acceptedAmount: number;

  expanded = false;

  constructor() {
    super([
      'id',
      'authorizationId',
      'amount',
      'claimId',
      'currencyCode',
      'fxRate',
      'merchantName',
      'createdAt',
      'acceptedAmount',
      { key: 'attempts', useModel: PaymentAuthorizationAttempt },
      { key: 'settlement', useModel: PaymentSettlement }
    ]);
  }

  get state(): PaymentState {
    if (this.settlement) {
      return PaymentState.Settled;
    }

    // The payment is authorized if the last attempt was successful, otherwise it's declined
    return this.attempts[0].decisionResult ? PaymentState.Authorized : PaymentState.Declined;
  }
}
