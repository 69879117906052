import gql from 'graphql-tag';
import { UserFragments } from './user.fragments';

export const SupportingDocumentFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on SupportingDocumentType {
      id
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on SupportingDocumentType {
      id
      source
      state
      reason
      uploadedAt
      file
      thumbnail
      filename
      resolvedAt
      resolvedBy {
        ...userFields
      }
      sentEmails {
        id
        recipient
        sender
      }
      user {
        ...userFields
      }
      fileType
    }
    ${UserFragments.fat('userFields')}
  `
};
