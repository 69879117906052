import gql from 'graphql-tag';

import { BenefitFragments } from './benefit.fragments';

export const BenefitUsageFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on BenefitUsageType {
      id
      benefit {
        ...benefitFields
      }
      currentAmount
      currentPendingAmount
    }
    ${BenefitFragments.slim('benefitFields')}
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on BenefitUsageType {
      id
      benefit {
        ...benefitFields
      }
      currentAmount
      currentPendingAmount
      currentCount
      currentAmountCovered
      remainingLimitCount
      remainingLimitAmount
      currentPendingAmount
      currentPendingCount
    }
    ${BenefitFragments.slim('benefitFields')}
  `
};
