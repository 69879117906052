import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { ModalDialog } from '@common/interfaces';
import { ModalComponent } from '@common/components/modal/modal.component';
import { checkVal } from '@common/utils';
import { PaymentAuthorizationAttempt, PreAuthMatching } from '@common/models';
import { rulesDict } from '@common/components/payment-rules-modal/rules';
import { PaymentRule } from '@common/enums';
import { LoaderProvider } from '@common/providers';

@Component({
  selector: 'ps-auth-decision-process',
  templateUrl: './auth-decision-process.component.html',
  styleUrls: ['./auth-decision-process.component.scss']
})
export class AuthDecisionProcessComponent implements ModalDialog {
  authAttempt: PaymentAuthorizationAttempt;
  claimPath: string;
  canViewClaim: boolean;
  currencyCode: string;

  rules = rulesDict;
  Rules = PaymentRule;

  icons = {
    caretRight: faCaretRight,
    caretDown: faCaretDown
  };

  @ViewChild('modal', { static: true }) modal: ModalComponent;

  constructor(private router: Router, private loaderService: LoaderProvider) {}

  initModal({
    authAttempt,
    claimPath,
    canViewClaim,
    currencyCode
  }: {
    authAttempt: PaymentAuthorizationAttempt;
    claimPath: string;
    canViewClaim: boolean;
    currencyCode: string;
  }) {
    this.authAttempt = checkVal(authAttempt, 'AuthDecisionProcessComponent expected authAttempt');
    this.claimPath = checkVal(claimPath, 'AuthDecisionProcessComponent expected claimPath');
    this.canViewClaim = checkVal(
      canViewClaim,
      'AuthDecisionProcessComponent expected canViewClaim'
    );
    this.currencyCode = checkVal(
      currencyCode,
      'AuthDecisionProcessComponent expected currencyCode'
    );
  }

  toggleMatchingExplanation(item: PreAuthMatching) {
    item.expanded = !item.expanded;
  }

  viewClaim(claimId: number): void {
    this.router.navigate([this.claimPath, claimId]).then(() => {
      this.loaderService.stopLoading(`viewPreAuth${claimId}`);
      this.modal.close();
    });
  }

  ruleDisplayName(name: string) {
    return rulesDict[name] ? rulesDict[name].displayName : name;
  }

  ruleDescription(name: string, passed: boolean) {
    const type = passed ? 'success' : 'failed';
    return rulesDict[name] ? rulesDict[name].description[type] : '-';
  }

  isPosDecision(ruleName: PaymentRule) {
    return [
      PaymentRule.CardHolderPresenceRule,
      PaymentRule.CardPresenceRule,
      PaymentRule.CardDataInputRule,
      PaymentRule.ChipFallbackRule,
      PaymentRule.FraudIndicatorMerchantRule,
      PaymentRule.SecurityProtocolRule,
      PaymentRule.SecurityMethod3dSecureRule,
      PaymentRule.AuthenticationMethodRule,
      PaymentRule.AuthenticationEntityRule
    ].includes(ruleName);
  }
}
