import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { GQLService } from '../common';
import { PaymentRule } from '../../models';
import { PaymentRuleFragments } from '@common/fragments/payment-rule.fragments';

@Injectable({ providedIn: 'root' })
export class PaymentRuleService extends GQLService<PaymentRule> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: PaymentRule,
      singular: 'paymentPlatformRule',
      plural: 'paymentPlatformRules',
      fragments: PaymentRuleFragments
    });
  }
}
