import { Model } from './model';

export class Customer extends Model {
  externalId: string;
  firstName: string;
  lastName?: string;
  address: {
    id: string;
    formatted: string;
  };
  dateOfBirth?: string;
  email?: string;
  phoneNumber: string;

  constructor() {
    super([
      'externalId',
      'firstName',
      'lastName',
      'address',
      'dateOfBirth',
      'email',
      'phoneNumber'
    ]);
  }

  get fullName(): string {
    let fullName = this.firstName;

    if (this.lastName) {
      fullName += ` ${this.lastName}`;
    }

    return fullName;
  }

  get formattedAddress(): string {
    if (!this.address || !this.address.formatted) {
      return '-';
    }
    return this.address.formatted;
  }
}
