import { mapImgUrl } from '../utils';

import { Model } from './model';
import { toInt } from '@common/utils/model-transforms';
import { MerchantCategory } from './merchant-category.model';
import { Country } from './country.model';

export class Merchant extends Model {
  id: number;
  categoryIds: number[];
  countryId: number;
  name: string;
  address: string;
  gpsLatitude: string;
  gpsLongitude: string;
  detailedInfo: string;
  isVerifiedByAdmin: boolean;
  categories: MerchantCategory[];
  country: Country;
  externalId: string;
  provider: { email: string };

  constructor() {
    super([
      { key: 'id', transform: toInt },
      'categoryIds',
      'countryId',
      'name',
      'address',
      'gpsLatitude',
      'gpsLongitude',
      'detailedInfo',
      'isVerifiedByAdmin',
      { key: 'categories', useModel: MerchantCategory },
      { key: 'country', useModel: Country },
      'externalId',
      'provider'
    ]);
  }

  /**
   * Generates a Google Map image URL for this merchant
   * @param width
   * @param height
   * @param zoom
   */
  getMap(width: number, height: number, zoom: number, gmapsApiKey: string): string {
    return mapImgUrl(this.latLng, `${width}x${height}`, zoom, gmapsApiKey);
  }

  get lat(): number {
    return parseFloat(this.gpsLatitude);
  }

  get lng(): number {
    return parseFloat(this.gpsLongitude);
  }

  get latLng(): string {
    return `${this.gpsLatitude},${this.gpsLongitude}`;
  }

  get categoriesString(): string {
    const names = this.categories.map((item: MerchantCategory) => item.name);
    return names.join(', ');
  }

  get hasProvider(): boolean {
    return !!this.provider;
  }
}
