import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { ConfigValueService } from './config-value.service';

export const ConfigValueServiceConfig = new InjectionToken<ServiceConfig>(
  'ConfigValueServiceConfig'
);

@NgModule()
export class ConfigValueServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<ConfigValueServiceModule> {
    return {
      ngModule: ConfigValueServiceModule,
      providers: [ConfigValueService, { provide: ConfigValueServiceConfig, useValue: config }]
    };
  }
}
