import { Component, Input, Inject, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { faAngleDown, faAngleUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import {
  PaymentAuthorization,
  PaymentAuthorizationAttempt,
  PaymentSettlement
} from '../../../models';
import { ModalProvider } from '../../../providers';
import { AuthDecisionProcessComponent } from '../auth-decision-process/auth-decision-process.component';

@Component({
  selector: 'ps-payment-breakdown',
  templateUrl: './payment-breakdown.component.html',
  styleUrls: ['./payment-breakdown.component.scss']
})
export class PaymentBreakdownComponent implements OnInit {
  @Input() payment: PaymentAuthorization;
  @Input() currencyCode: string;
  @Input() canViewClaim: boolean;
  @Input() merchantName: string;

  settlement: PaymentSettlement;
  attempts: PaymentAuthorizationAttempt[];
  icons = {
    info: faInfoCircle,
    caredDown: faAngleDown,
    caretUp: faAngleUp
  };
  expanded = false;

  constructor(
    private modalProvider: ModalProvider,
    private router: Router,
    @Inject(ComponentFactoryResolver) private cfr: ComponentFactoryResolver
  ) {
    this.modalProvider.updateContext(this.cfr);
  }

  ngOnInit() {
    this.settlement = this.payment.settlement;
    this.attempts = this.payment.attempts;
  }

  toggleReversals(attempt: PaymentAuthorizationAttempt) {
    attempt.expanded = !attempt.expanded;
  }

  toggleSettlementEvents(settlement: PaymentSettlement) {
    settlement.expanded = !settlement.expanded;
  }

  viewReason(authAttempt: PaymentAuthorizationAttempt) {
    const url: string = this.router.url;
    // We need to provide component with claimPath as "AuthDecisionProcessComponent" is a non-routed
    // component, therefore we can't get route data in the component itself
    // Pass the entire url without a claimId at the end
    const claimPath = url.substring(0, url.lastIndexOf('/') + 1);
    this.modalProvider.open(AuthDecisionProcessComponent, {
      authAttempt,
      claimPath,
      canViewClaim: this.canViewClaim,
      currencyCode: this.currencyCode
    });
  }
}
