import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LoaderProviderModule } from '@common/providers';
import { LinkComponent } from './link.component';

@NgModule({
  imports: [CommonModule, TooltipModule.forRoot(), FontAwesomeModule],
  declarations: [LinkComponent],
  exports: [LinkComponent]
})
export class LinkComponentModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: LinkComponentModule,
      providers: [LoaderProviderModule]
    };
  }
}
