import gql from "graphql-tag";
import { CurrencyFragments } from './currency.fragments';
import { ProductVersionFragments } from './product-version.fragments';
import { CountryFragments } from './country.fragments';

export const ProductFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on ProductType {
      id
      name
      insurer {
        slug
        name
      }
      description
      versions {
        id
      }
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on ProductType {
      id
      name
      insurer {
        slug
        name
        country {
          ...countryFields
        }
      }
      description
      currency {
        ...currencyFields
      }
      versions {
        ...productVersionFields
      }
    }
    ${CurrencyFragments.fat('currencyFields')}
    ${ProductVersionFragments.fat('productVersionFields')}
    ${CountryFragments.fat('countryFields')}
  `
};
