import { random as _random } from 'lodash';

import { Model } from './model';
import { Currency } from './currency.model';
import { User } from './user.model';
import { CardType, PaymentRuleType } from '@common/enums';
import { toInt } from '@common/utils/model-transforms';
import { PaymentRulesOverride } from './payment-rules-override.model';

export class RuleCurrentState extends Model {
  policyId: string;
  ruleType: PaymentRuleType;
  triggered: boolean;

  constructor() {
    super(['policyId', 'ruleType', 'triggered']);
  }
}

class CardAction extends Model {
  action: string;
  created: string;
  source: string;
  comment: string;
  user: User;

  constructor() {
    super(['action', 'created', 'source', 'comment', { key: 'user', useModel: User }]);
  }
}

export class Card extends Model {
  id: number;
  proxyId: string;
  actions: CardAction[];
  lastDigits: string;
  nameOnCard: string;
  expiration: string;
  ready: boolean;
  currency: Currency;
  blockedPendingClaim: boolean;
  isFrozen: boolean;
  isBlocked: boolean;
  isDeactivated: boolean;
  isCancelled: boolean;
  isReplacementOrdered: boolean;
  isIssued: boolean;
  cardType: CardType;
  rulesCurrentStates: RuleCurrentState[];
  paymentRulesOverrides: PaymentRulesOverride[];

  constructor() {
    super([
      { key: 'id', transform: toInt },
      'proxyId',
      { key: 'actions', useModel: CardAction },
      'lastDigits',
      'nameOnCard',
      'expiration',
      'ready',
      { key: 'currency', useModel: Currency },
      'blockedPendingClaim',
      'isFrozen',
      'isBlocked',
      'isDeactivated',
      'isCancelled',
      'isReplacementOrdered',
      'isIssued',
      'cardType',
      { key: 'rulesCurrentStates', useModel: RuleCurrentState },
      { key: 'paymentRulesOverrides', useModel: PaymentRulesOverride }
    ]);
  }

  get lastBlockedAction(): CardAction {
    const actions = this.actions.filter(a => a.action === 'BLOCK');
    return actions[actions.length - 1];
  }

  get lastFrozenAction(): CardAction {
    const actions = this.actions.filter(a => a.action === 'FREEZE');
    return actions[actions.length - 1];
  }

  get lastReplacedAction(): CardAction {
    const actions = this.actions.filter(a => a.action === 'REPLACEMENT');
    return actions[actions.length - 1];
  }
}
