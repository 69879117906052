import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { SettingsProvider } from './settings.provider';

export interface ISettingsProviderConfig {
  localKey: string;
}

export const SettingsProviderConfig = new InjectionToken<ISettingsProviderConfig>(
  'SettingsProviderConfig'
);

@NgModule()
export class SettingsProviderModule {
  public static forRoot(config: ISettingsProviderConfig): ModuleWithProviders<SettingsProviderModule> {
    return {
      ngModule: SettingsProviderModule,
      providers: [SettingsProvider, { provide: SettingsProviderConfig, useValue: config }]
    };
  }
}
