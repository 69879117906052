import gql from 'graphql-tag';

export const PaymentVelocityControlFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentVelocityControlSpecificationType {
      amount
      timeWindowMinutes
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentVelocityControlSpecificationType {
      amount
      timeWindowMinutes
    }
  `
};
