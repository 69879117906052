import { Model } from './model';

export class PaymentRule extends Model {
  ruleName: string;
  subrules: string[];

  constructor() {
    super(['ruleName', 'subrules']);
  }
}
