import { Component, Input } from '@angular/core';
import { PaymentAuthReversedBy } from '@common/enums';
import { Reversal } from '@common/models';

@Component({
  selector: 'ps-payment-reversals',
  templateUrl: './payment-reversals.component.html',
  styleUrls: ['./payment-reversals.component.scss']
})
export class PaymentReversalsComponent {
  @Input() reversals: Reversal[];
  @Input() currencyCode: string;
  expanded = false;

  reversedByDict = {
    [PaymentAuthReversedBy.Manually]: 'Authorisation was reversed manually',
    [PaymentAuthReversedBy.Processor]: 'Authorisation was reversed by the card processor',
    [PaymentAuthReversedBy.Refund]: 'Authorisation was reversed as a part of the refund process'
  };
}
