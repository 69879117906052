import { Model } from './model';
import { toInt } from '@common/utils/model-transforms';

export class Currency extends Model {
  id: number;
  name: string;
  code: string;
  symbolBefore: string;
  symbolAfter: string;

  constructor() {
    super([
      { key: 'id', transform: toInt },
      'name',
      'code',
      'symbolBefore',
      'symbolAfter'
    ]);
  }

  get label(): string {
    return `${this.name} (${this.code})`;
  }
}
