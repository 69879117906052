import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CardPinModalComponent } from './card-pin-modal.component';
import { ButtonComponentModule } from '../button';
import { ModalComponentModule } from '../modal';

@NgModule({
  imports: [CommonModule, ModalComponentModule, ButtonComponentModule],
  declarations: [CardPinModalComponent],
  entryComponents: [CardPinModalComponent],
  exports: [CardPinModalComponent]
})
export class CardPinModalComponentModule {}
