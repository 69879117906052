export enum PaymentRule {
  HasInsuranceRule = 'HasInsuranceRule',
  SufficientFunds = 'SufficientFunds',
  PendingClaimRule = 'PendingClaimRule',
  BenefitAmountRule = 'BenefitAmountRule',
  BenefitCountRule = 'BenefitCountRule',
  CardDynamicRules = 'CardDynamicRules',
  TransactionTypeRule = 'TransactionTypeRule',
  CardIsReadyRule = 'CardIsReadyRule',
  MandatoryNotNullFieldsRule = 'MandatoryNotNullFieldsRule',
  ClaimPreauthRule = 'ClaimPreauthRule',
  CountryRule = 'CountryRule',
  CurrencyRule = 'CurrencyRule',
  MerchantCodeRule = 'MerchantCodeRule',
  MerchantNameRule = 'MerchantNameRule',
  LocationRule = 'LocationRule',
  TimestampRule = 'TimestampRule',
  MerchantIdRule = 'MerchantIdRule',
  UnsupportedMCCRule = 'UnsupportedMCCRule',
  InsufficientFundsRule = 'InsufficientFundsRule',
  CashbackRule = 'CashbackRule',
  GratuityFeeRule = 'GratuityFeeRule',
  CardHolderPresenceRule = 'CardHolderPresenceRule',
  CardPresenceRule = 'CardPresenceRule',
  CardDataInputRule = 'CardDataInputRule',
  ChipFallbackRule = 'ChipFallbackRule',
  FraudIndicatorMerchantRule = 'FraudIndicatorMerchantRule',
  SecurityProtocolRule = 'SecurityProtocolRule',
  SecurityMethod3dSecureRule = 'SecurityMethod3dSecureRule',
  AuthenticationMethodRule = 'AuthenticationMethodRule',
  AuthenticationEntityRule = 'AuthenticationEntityRule'
}
