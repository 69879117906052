import { PaymentSettlementEventType } from '@common/enums';
import { Model } from './model';

export class PaymentSettlementEvent extends Model {
  id: number;
  amount: number;
  transactionTime: string;
  eventType: PaymentSettlementEventType;

  constructor() {
    super(['id', 'amount', 'transactionTime', 'eventType']);
  }
}

export class PaymentSettlement extends Model {
  id: number;
  authorizationId: number;
  amount: number;
  currencyCode: string;
  fxRate: number;
  transactionTime: string;
  events: PaymentSettlementEvent[];

  expanded = false;

  constructor() {
    super([
      'id',
      'authorizationId',
      'amount',
      'currencyCode',
      'fxRate',
      'transactionTime',
      { key: 'events', useModel: PaymentSettlementEvent }
    ]);
  }
}
