import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Receipt, ReceiptItem } from '../../models';
import { DocumentDecision } from '../../interfaces';
import { GQLService } from '../common';
import { Apollo } from 'apollo-angular';
import { ReceiptFragments, ReceiptItemFragments } from '@common/fragments';
import gql from 'graphql-tag';
import { objToGql } from '@common/utils';
import { map } from 'rxjs/operators';
import { DocumentFileType } from '@common/enums';

interface NewReceiptItem {
  title: string;
  amount: number;
  isAccepted: boolean;
  icdId: string;
}

@Injectable({ providedIn: 'root' })
export class ReceiptService extends GQLService<Receipt> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: Receipt,
      singular: 'receipt',
      plural: 'receipts',
      fragments: ReceiptFragments,
      constants: ['source']
    });
  }

  create(
    body: { claimId: number; source: string; fileType: DocumentFileType },
    file: any
  ): Observable<any> {
    const { claimId, source, fileType } = body;
    const variables: any = {
      file
    };
    const context: any = {
      useMultipart: true
    };
    return this.apollo
      .mutate({
        mutation: gql`
        mutation($file: Upload!) {
          receiptCreate(newReceipt: {
            claimId: ${claimId}
            source: ${source},
            fileType: ${String(fileType)}
            file: $file
          }) {
            ok
            receipt {
              ...receiptFatFields
            }
          }
        }
        ${ReceiptFragments.fat('receiptFatFields')}
      `,
        variables,
        context
      })
      .pipe(
        map(res => {
          const data = res.data['receiptCreate'];
          if (data.ok) {
            return new Receipt().deserialize(data['receipt']);
          } else {
            throw new Error(`Couldn't upload a receipt. Response: ${JSON.stringify(data)}`);
          }
        })
      );
  }

  makeDecision(receiptId: number, decision: DocumentDecision): Observable<Receipt> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          receiptUpdate(newReceiptDecision: {
            id: ${receiptId}
            documentAction: ${decision.action}
            reason: "${decision.reason}"
          }) {
            ok
            receipt {
              ...receiptFatFields
            }
          }
        }
        ${ReceiptFragments.fat('receiptFatFields')}
      `
    }).pipe(map(res => {
      const data = res.data['receiptUpdate'];
      if (data.ok) {
        return new Receipt().deserialize(data['receipt']);
      } else {
        throw new Error(`Couldn't make decision for receipt #${receiptId}. Response: ${JSON.stringify(data)}`);
      }
    }));
  }

  getItems(receiptId: number): Observable<ReceiptItem[]> {
    return this.apollo.query({
      query: gql`
        {
          receiptItems(receipt: { id: ${receiptId} }) {
            results {
              ...receiptItemFatFields
            }
          }
        }
        ${ReceiptItemFragments.fat('receiptItemFatFields')}
      `,
      fetchPolicy: 'no-cache'
    }).pipe(map(res => {
      return res.data['receiptItems'].results.map(data => {
        return new ReceiptItem().deserialize(data);
      });
    }));
  }

  addItem(receiptId: number, newItem: NewReceiptItem): Observable<ReceiptItem> {
    const createKey = 'receiptItemCreate';
    const resultKey = 'receiptitem'; // Not sure why this is all lowercase

    return this.apollo.mutate({
      mutation: gql`
        mutation {
          ${createKey}(newReceiptItem: {
            receiptId: ${receiptId}
            ${objToGql(newItem)}
          }) {
            ok
            ${resultKey} {
              ...receiptItemFatFields
            }
          }
        }
        ${ReceiptItemFragments.fat('receiptItemFatFields')}
      `
    }).pipe(map(res => {
      const data = res.data[createKey];
      if (data.ok) {
        return new ReceiptItem().deserialize(data[resultKey]);
      } else {
        throw new Error(`Couldn't create receipt item. Response: ${JSON.stringify(res)}`);
      }
    }));
  }

  updateItem(id: number, item: Partial<NewReceiptItem>): Observable<ReceiptItem> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          receiptItemUpdate(newReceiptItem: {
            id: ${id}
            ${objToGql(item)}
          }) {
            ok
            receiptitem {
              ...receiptItemFatFields
            }
          }
        }
        ${ReceiptItemFragments.fat('receiptItemFatFields')}
      `
    }).pipe(map(res => {
      const data = res.data['receiptItemUpdate'];
      if (data.ok) {
        return new ReceiptItem().deserialize(data['receiptitem']);
      } else {
        throw new Error(`Couldn't update receipt item #${id}. Response: ${JSON.stringify(res)}`);
      }
    }));
  }

  deleteItem(id: number): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          receiptItemDelete(id: ${id}) {
            ok
          }
        }
      `
    }).pipe(map(res => {
      const data = res.data['receiptItemDelete'];
      if (!data.ok) {
        throw new Error(`Couldn't delete receipt item #${id}. Response: ${JSON.stringify(data)}`)
      }
    }));
  }
}
