import gql from "graphql-tag";

export const PaymentRuleFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentRuleType {
      ruleName
      subrules
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentRuleType {
      ruleName
      subrules
    }
  `
};
