import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { UserService } from './user.service';
import { ServiceConfig } from '../common';

export const UserServiceConfig = new InjectionToken<ServiceConfig>('UserServiceConfig');

@NgModule()
export class UserServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<UserServiceModule> {
    return {
      ngModule: UserServiceModule,
      providers: [UserService, { provide: UserServiceConfig, useValue: config }]
    };
  }
}
