import { Injectable } from '@angular/core';
import { MerchantCategory } from '../../models';
import { GQLService } from '../common';
import { Apollo } from 'apollo-angular';
import { MerchantCategoryFragments } from '@common/fragments';

@Injectable({ providedIn: 'root' })
export class MerchantCategoryService extends GQLService<MerchantCategory> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: MerchantCategory,
      singular: 'merchantCategory',
      plural: 'merchantCategories',
      fragments: MerchantCategoryFragments
    });
  }
}
