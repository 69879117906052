<div class="BenefitBreakdown">
  <div class="BenefitBreakdown__item" *ngFor="let item of items">
    <div class="BenefitBreakdown__itemHeader">{{ item.title }}</div>
    <div class="BenefitBreakdown__itemCols">
      <div class="BenefitBreakdown__itemCol" *ngFor="let col of item.cols">
        <div class="BenefitBreakdown__itemHeader">{{ col.title }}</div>
        <div class="BenefitBreakdown__content">
          <span class="font--semiBold">{{ col.value }}</span>
          <small *ngIf="col.remaining">{{ col.remaining }} Remaining</small>
        </div>
      </div>
    </div>
  </div>
</div>
