import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Service, ServiceConfig, GQLService } from '../common';
import { MarketFxRate } from '../../models';
import { Apollo } from 'apollo-angular';
import { MarketFxRateFragments } from '@common/fragments';

@Injectable({ providedIn: 'root' })
export class MarketFxRateService extends GQLService<MarketFxRate> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: MarketFxRate,
      singular: 'marketFxRate',
      plural: 'marketFxRates',
      fragments: MarketFxRateFragments
    });
  }

  getFxRate(from: string, to: string, date?: string): Observable<MarketFxRate> {
    const filters: any = { codeFrom: from, codeTo: to };

    if (typeof date !== 'undefined' && date !== null) {
      filters.date = date;
    }

    return this.getAll({ filters, noPaging: true }).pipe(
      filter(rates => rates.length > 0),
      map(rates => new MarketFxRate().deserialize(rates[0])));
  }
}
