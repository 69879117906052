<div class="SystemStatus card card-default">
  <div class="card-body">
    <h5 class="SystemStatus__heading" i18n="@@systemStatusHeading">System Status</h5>
    <ul *ngIf="statuses" class="SystemStatus__list">
      <li *ngFor="let status of statuses">
        <a class="SystemStatusItem" (click)="toggle(status)">
          <div
            class="SystemStatusItem__indicator SystemStatusItem__indicator--{{ status.color }}"
          ></div>
          <div class="SystemStatusItem__name">{{ status.name }}</div>
          <div class="SystemStatusItem__disclosure">
            <span
              class="glyphicon"
              [ngClass]="{
                'glyphicon-chevron-down': !status.expanded,
                'glyphicon-chevron-up': status.expanded
              }"
            ></span>
          </div>
        </a>
        <div class="SystemStatusInfo" [ngClass]="{ 'SystemStatusInfo--show': status.expanded }">
          <p class="SystemStatusInfo__text">{{ status.description }}</p>
        </div>
      </li>
    </ul>
  </div>
</div>
