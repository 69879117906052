import { Model } from './model';

export class MerchantCategory extends Model {
  id: number;
  name: string;
  code: string;

  constructor() {
    super(['id', 'name', 'code']);
  }

  get label(): string {
    return `${this.name} (${this.code})`;
  }
}
