import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, ReplaySubject, of, throwError } from 'rxjs';
import { mergeMap, catchError, take, map } from 'rxjs/operators';

import { User } from '../../models';
import { AuthProviderConfig, IAuthProviderConfig } from './auth.provider.module';
import { GetMeGQL } from '@common/gql';

const authKey = 'paysure.authed';

@Injectable()
export class AuthProvider {
  onLogin = new Subject();
  onLogout = new Subject();

  private userRequestInitiated = false;
  private userSubject = new ReplaySubject<User>(1);

  private _loggedIn = false;

  constructor(
    private http: HttpClient,
    @Inject(forwardRef(() => GetMeGQL)) private getMeGQL: GetMeGQL,
    @Inject(AuthProviderConfig) private config: IAuthProviderConfig
  ) {
    const authed = localStorage.getItem(authKey);
    this._loggedIn = authed === 'true' ? true : false;
  }

  /**
   * Fetches a currently logged in user and returns a deserialized object
   */
  fetchUser(): Observable<User> {
    return this.getMeGQL.watch({}, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map(
        ({
          data: {
            users: { results }
          }
        }) => {
          return new User().deserialize(results[0]);
        }
      )
    );
  }

  /**
   * Hits a /login/ endpoint which sets a "csrftoken" cookie
   */
  setCsrfToken(email: string, password: string) {
    return this.http.post(`${this.config.baseUrl}/login/`, {
      email,
      password,
      endpoint: this.config.gqlEndpoint
    });
  }

  /**
   * Fetches and sets data for a currently logged in user
   */
  setUser() {
    this.loggedIn = true;
    return this.fetchUser().pipe(
      map((user: User) => {
        this.userSubject.next(user);
        this.onLogin.next();
        return true;
      })
    );
  }

  login(email: string, password: string): Observable<boolean> {
    return this.setCsrfToken(email, password).pipe(
      mergeMap(() => this.setUser()),
      catchError(res => {
        if (res.status === 403) {
          return of(false); // Indicates failed login attempt
        }
        return throwError(res);
      })
    );
  }

  resetAuthState() {
    this.onLogout.next();
    this.loggedIn = false;
    this.userRequestInitiated = false;
    this.userSubject = new ReplaySubject<User>(1);
  }

  logout() {
    this.http
      .post(`${this.config.baseUrl}/logout/`, {})
      .pipe(
        // We don't want to throw an error if the token has expired, as the user will have
        // been logged out anyway
        catchError(() => {
          return of();
        })
      )
      .subscribe();
    this.resetAuthState();
  }

  get user(): Observable<User> {
    if (!this.userRequestInitiated) {
      this.userRequestInitiated = true;
      this.fetchUser().subscribe((user: User) => {
        this.userSubject.next(user);
      });
    }

    return this.userSubject.pipe(take(1));
  }

  set loggedIn(val: boolean) {
    if (val) {
      localStorage.setItem(authKey, 'true');
    } else {
      localStorage.removeItem(authKey);
    }
    this._loggedIn = val;
  }

  get loggedIn(): boolean {
    return this._loggedIn;
  }
}
