import gql from "graphql-tag";
import { AddressFragments } from './address.fragments';

export const CustomerFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on CustomerType {
      id
      externalId
      firstName
      lastName
      dateOfBirth
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on CustomerType {
      id
      externalId
      firstName
      lastName
      address {
        ...addressFields
      }
      dateOfBirth
      email
    }
    ${AddressFragments.slim('addressFields')}
  `
};
