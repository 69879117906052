import gql from 'graphql-tag';
import { PaymentVelocityControlFragments } from './payment-velocity-control-specification.fragments';
import { PaymentFrequencyControlFragments } from './payment-frequency-control-specification.fragments';

export const RuleFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on RuleType {
      id
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on RuleType {
      id
      ruleType
      description
      specification {
        ...paymentVelocityControlSlimFields
        ...paymentFrequencyControlSlimFields
      }
    }
    ${PaymentVelocityControlFragments.slim('paymentVelocityControlSlimFields')}
    ${PaymentFrequencyControlFragments.slim('paymentFrequencyControlSlimFields')}
  `
};
