import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarDisplayOptions } from '@common/interfaces';
import { AuthProvider } from '@common/providers';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBell,
  faTimes,
  faUserCircle,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import { NotificationsService } from 'angular2-notifications';

export interface Link {
  text: string;
  to: string;
  icon: IconProp;
}

const LOCAL_KEY_COLLAPSED = 'ps.collapsed';

@Component({
  selector: 'ps-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() displayOptions: SidebarDisplayOptions;
  @Input() open: boolean;
  @Input() links: Link[];
  @Output() onClose = new EventEmitter();

  unreadNotifications: number;
  collapsed = false;
  icons = { faBell, faTimes, faUserCircle, faSignOutAlt };

  constructor(
    private authProvider: AuthProvider,
    private router: Router,
    private notifications: NotificationsService
  ) {}

  ngOnInit() {
    // Read local storage to determine initial collapsed state
    if (window.localStorage.getItem(LOCAL_KEY_COLLAPSED) === '1') {
      this.collapsed = true;
    }
  }

  /**
   * The icon for the toggle button
   */
  get toggleIcon() {
    return this.collapsed ? faAngleDoubleRight : faAngleDoubleLeft;
  }

  /**
   * Toggle sidebar collapse state
   */
  toggleCollapse() {
    // Persist the collapsed state in local storage
    if (!this.collapsed) {
      window.localStorage.setItem(LOCAL_KEY_COLLAPSED, '1');
    } else {
      window.localStorage.setItem(LOCAL_KEY_COLLAPSED, '0');
    }
    this.collapsed = !this.collapsed;
  }

  logout() {
    this.authProvider.logout();
    this.router.navigate(['login']);
    this.notifications.info('Logged Out', 'You are now logged out');
  }
}
