import { Model } from './model';
import { Platform, ClaimDecisionAction } from '../enums';
import { User } from './user.model';
import { parseJSON } from '@common/utils/model-transforms';

export enum NotificationAction {
  Created = 'CREATED',
  NewDecision = 'NEW_DECISION'
}

export class ContentType extends Model {
  id: number;
  appLabel: string;
  model: string;

  constructor() {
    super(['id', 'appLabel', 'model']);
  }
}

export class Notification extends Model {
  id: number;
  insurerId: string;
  entityId: string;
  entityType: ContentType;
  user: User;
  action: NotificationAction;
  source: Platform;
  data?: any;
  timestamp: string;
  read: boolean;

  constructor() {
    super([
      'id',
      'insurerId',
      'entityType',
      'entityId',
      'userId',
      'action',
      'source',
      { key: 'data', transform: parseJSON },
      'timestamp',
      'read',
      { key: 'entityType', useModel: ContentType },
      { key: 'user', useModel: User }
    ]);
  }

  get isNewClaim(): boolean {
    return (
      this.entityType.appLabel === 'claim' &&
      this.entityType.model === 'claim' &&
      this.action === NotificationAction.Created
    );
  }

  get isReceiptUpload(): boolean {
    return (
      this.entityType.appLabel === 'claim' &&
      this.entityType.model === 'receipt' &&
      this.action === NotificationAction.Created
    );
  }

  get isAuthorizationReferral(): boolean {
    return (
      this.action === NotificationAction.NewDecision &&
      this.data['action'] === ClaimDecisionAction.ReferAuthorization
    );
  }
}
