import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { MerchantService } from './merchant.service';

export const MerchantServiceConfig = new InjectionToken<ServiceConfig>('MerchantServiceConfig');

@NgModule()
export class MerchantServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<MerchantServiceModule> {
    return {
      ngModule: MerchantServiceModule,
      providers: [MerchantService, { provide: MerchantServiceConfig, useValue: config }]
    };
  }
}
