import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';

import { NotificationsService } from 'angular2-notifications';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { ModalComponent } from '@common/components';
import { ModalDialog } from '@common/interfaces';
import { PaymentRuleService } from '@common/services';
import { PaymentRule, PaymentRulesOverride } from '@common/models';
import { checkVal } from '@common/utils';
import { PaymentRulesOverrideService } from '@common/services';
import { PaymentRuleOverrideType } from '@common/enums';
import { rulesDict, RulesDict } from './rules';

@Component({
  selector: 'app-payment-rules-modal',
  templateUrl: './payment-rules-modal.component.html',
  styleUrls: ['./payment-rules-modal.component.scss']
})
export class PaymentRulesModalComponent implements ModalDialog, OnInit {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  cardProxyId: string;
  loading = true;

  rulesForms: FormGroup[];
  disabledRules: string[] = [];
  rulesDict: RulesDict = rulesDict;
  checkAll = false;
  icons = {
    caretRight: faCaretRight,
    caretDown: faCaretDown
  };

  constructor(
    private paymentRuleService: PaymentRuleService,
    private paymentRulesOverrideService: PaymentRulesOverrideService,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService
  ) {}

  initModal({ cardProxyId }) {
    this.cardProxyId = checkVal(cardProxyId, 'Expected cardId');
  }

  ngOnInit() {
    this.fetchRules(this.cardProxyId);
  }

  /**
   * Creates an array of FormGroups. Each FormGroup represents one rule with its subrules as
   * form control with the name of "subrules". The "subrules" form control contains a FormArray,
   * each item representing one subrule.
   * @param rules An array of rules from the paymentPlatformRules endpoint
   * @param allRulesDisabled Whether all rules are disabled, i.e. the "data" field equals ['*']
   */
  createForms(rules: PaymentRule[], allRulesDisabled: boolean): FormGroup[] {
    return rules.map((el: PaymentRule) => {
      const { ruleName, subrules } = el;

      const parentDisabled = this.disabledRules.includes(ruleName);
      let checkParent = !parentDisabled;

      // An array of all subrules (if any) for current rule
      const subrules_ = subrules.map(subrule => {
        const checked =
          !parentDisabled &&
          !this.disabledRules.includes(`${ruleName}.${subrule}`) &&
          !allRulesDisabled;

        // If any subrule is disabled, we have to uncheck the parent as well
        if (!checked) {
          checkParent = false;
        }

        return {
          name: subrule,
          checked
        };
      });

      return this.formBuilder.group({
        name: ruleName,
        checked: checkParent && !allRulesDisabled,
        expanded: false,
        subrules: this.formBuilder.array(subrules_.map(subrule => this.formBuilder.group(subrule)))
      });
    });
  }

  /**
   * Fetches payment rules overrides for the current card with the "RULES_DISABLE"
   * override type alongside all available payment platform rules
   * @param cardProxyId
   */
  fetchRules(cardProxyId: string): void {
    forkJoin([
      this.paymentRulesOverrideService.getAll({
        filters: {
          cardProxyId,
          overrideType: PaymentRuleOverrideType.RulesDisable,
          isActive: true
        }
      }),
      this.paymentRuleService.getAll()
    ]).subscribe(([rulesOverrides, rules]: [PaymentRulesOverride[], PaymentRule[]]) => {
      let allRulesDisabled = false;

      // If there are any existing overrides for the current card
      if (rulesOverrides.length > 0) {
        const lastIndex = rulesOverrides.length - 1;
        // If the array contains just one item with "*" string, then all rules should be disabled
        if (rulesOverrides[lastIndex].override.disabledRules[0] === '*') {
          allRulesDisabled = true;
        } else {
          this.disabledRules = rulesOverrides[lastIndex].override.disabledRules;
        }
      }

      this.rulesForms = this.createForms(rules, allRulesDisabled);
      this.checkAllState();
      this.loading = false;
    });
  }

  /**
   * Helper method for easier manual checking or unchecking of form control with the name of
   * "checked" (checkbox)
   * @param item
   * @param checked
   */
  setCheckedControlValue(item: FormControl | FormGroup, checked: boolean): void {
    item['controls']['checked'].setValue(checked);
  }

  /**
   * Helper method for easier accessing of all subrules's controls for a given rule
   * @param item
   */
  getSubrulesControls(item: FormControl | FormGroup): FormControl[] {
    return item['controls']['subrules']['controls'];
  }

  /**
   * Checks or unchecks all rules/subrules. Action is determined by component's "checkAll" private property
   */
  setAll(): void {
    this.rulesForms.forEach(item => {
      this.setCheckedControlValue(item, this.checkAll);
      this.getSubrulesControls(item).forEach(subItem => {
        this.setCheckedControlValue(subItem, this.checkAll);
      });
    });
  }

  /**
   * Updates components "checkAll" private property depending on the state of all rules/subrules
   */
  checkAllState() {
    const checkAll = !this.rulesForms.some((rule: FormGroup) => {
      return (
        rule.get('checked').value === false ||
        this.getSubrulesControls(rule).some(
          (item: FormControl) => item['controls']['checked'].value === false
        )
      );
    });
    this.checkAll = checkAll;
  }

  /**
   * Is triggered on any state change of a parent rule and checks or unchecks all of its subrules
   * depending on its current state
   * @param formGroup rule's FormGroup
   */
  parentCheck(formGroup: FormGroup): void {
    const checked = formGroup.get('checked').value;
    this.getSubrulesControls(formGroup).forEach((item: FormControl) => {
      this.setCheckedControlValue(item, checked);
    });
    this.checkAllState();
  }

  /**
   * Returns true if any of subrule's state of a given rule is the same as that of a "state" parameter
   * @param rule
   * @param state
   */
  checkSubrulesState(rule: FormGroup, state: boolean) {
    return this.getSubrulesControls(rule).some(
      (item: FormControl) => item['controls']['checked'].value === state
    );
  }

  /**
   * Is triggered on any state change of any subrule and checks or unchecks parent rule depending
   * on the state of all current rule's subrules
   * @param formGroup rule's FormGroup
   */
  childCheck(parent: FormGroup): void {
    const checkParent = !this.checkSubrulesState(parent, false);
    this.setCheckedControlValue(parent, checkParent);
    this.checkAllState();
  }

  toggleExpanded(item: FormGroup): void {
    item.controls['expanded'].setValue(!item.get('expanded').value);
  }

  /**
   * Returns a number of all subrules for a given rule
   * @param rule
   */
  getSubrulesCount(rule: FormGroup): number {
    return (rule.controls['subrules'] as FormArray).length;
  }

  /**
   * Generates a list of rule names or ['*'] when all rules are disabled. For parent rules, the
   * rule name is used, and for subrules, the "ruleName.subruleName" is used.
   * Example: ["ClaimPreauthRule.CountryRule", "BenefitAmountRule"], where the first rule is a subrule
   * and the second rule is a parent rule
   */
  generateDisabledRulesArray(): Array<string> {
    const data = [];
    this.rulesForms.forEach(rule => {
      const ruleName = rule.get('name').value;

      // If the disabled rule has no subrules OR if all rules's subrules are disabled, we just
      // append the rule's name to an array
      if (
        (!this.getSubrulesCount(rule) && !rule.get('checked').value) ||
        (this.getSubrulesCount(rule) > 0 && !this.checkSubrulesState(rule, true))
      ) {
        data.push(ruleName);
        return;
      }

      // Otherwise we append "ruleName.subruleName" for all disabled (unchecked) subrules
      this.getSubrulesControls(rule).forEach(subrule => {
        if (!subrule['controls']['checked'].value) {
          const subruleName = subrule['controls']['name'].value;
          data.push(`${ruleName}.${subruleName}`);
        }
      });
    });
    return data;
  }

  /**
   * Checks if an enabled rules override entity with override type "RULES_DISABLE" exists and decides
   * whether we need to disable the old one and then create a new entity, or just create a new one
   * @param disabledRules list of all disabled rules for the current card
   */
  makeRequest(disabledRules: string[]): Observable<any> {
    return this.paymentRulesOverrideService
      .getAll({
        filters: {
          cardProxyId: this.cardProxyId,
          overrideType: PaymentRuleOverrideType.RulesDisable,
          isEnabled: true
        }
      })
      .pipe(
        mergeMap(data => {
          // No existing enabled overrides, just create a new one
          if (!data[0]) {
            return this.paymentRulesOverrideService.createRulesDisable(
              this.cardProxyId,
              disabledRules
            );
          }
          // Enabled override entity exists, disable it and then create a new one
          else {
            const oldId = data[0].id;
            return (
              this.paymentRulesOverrideService
                // Disable the old rule
                .updateRulesOverride(oldId, { isActive: false })
                .pipe(
                  mergeMap(() => {
                    // Create a new one
                    return this.paymentRulesOverrideService.createRulesDisable(
                      this.cardProxyId,
                      disabledRules
                    );
                  })
                )
            );
          }
        })
      );
  }

  save(): void {
    const allDisabled: boolean = !this.rulesForms.some(
      rule =>
        rule.get('checked').value ||
        this.getSubrulesControls(rule).some(
          (item: FormControl) => item['controls']['checked'].value
        )
    );

    const disabledRules: string[] = allDisabled ? ['*'] : this.generateDisabledRulesArray();

    this.makeRequest(disabledRules).subscribe(() => {
      this.notificationsService.success(
        'Updated Payment Rules',
        'Successfully updated payment rules.'
      );
      this.modal.close();
    });
  }

  ruleDisplayName(name: string) {
    return rulesDict[name] ? this.rulesDict[name].displayName : name;
  }

  ruleDescription(name: string) {
    return rulesDict[name] ? this.rulesDict[name].description.general : '-';
  }
}
