import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { BeneficiaryService } from './beneficiary.service';

export const BeneficiaryServiceConfig = new InjectionToken<ServiceConfig>(
  'BeneficiaryServiceConfig'
);

@NgModule()
export class BeneficiaryServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<BeneficiaryServiceModule> {
    return {
      ngModule: BeneficiaryServiceModule,
      providers: [BeneficiaryService, { provide: BeneficiaryServiceConfig, useValue: config }]
    };
  }
}
