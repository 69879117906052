import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

class Page {
  number: number;
  current: boolean;
}

@Component({
  selector: 'ps-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  private _totalPages: number;

  @Input() set totalPages(totalPages: number) {
    this._totalPages = totalPages;
    this.init();
  }

  get totalPages() {
    return this._totalPages;
  }

  private currentPage: number;
  maxVisible = 5; // Must be odd

  pages: Page[];
  enablePrev: boolean;
  enableNext: boolean;
  showFirst: boolean;
  showLast: boolean;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.currentPage = 'page' in params ? parseInt(params['page'], 10) : 1;
      this.init();
    });
  }

  private init() {
    this.enablePrev = this.currentPage > 1;
    this.enableNext = this.currentPage !== 0 && this.currentPage < this.totalPages;

    const pages: Page[] = [];
    let from = 1;
    let to: number;

    const padding = (this.maxVisible - 1) / 2;
    const total = this.totalPages;
    const current = this.currentPage;

    // Try to keep current page in center of range
    if (total > this.maxVisible && current > padding) {
      if (current + padding > total) {
        from = total - (this.maxVisible - 1);
      } else {
        from = current - padding;
      }
    }

    to = total < this.maxVisible ? total : from + (this.maxVisible - 1);

    for (let i = from; i <= to; i++) {
      pages.push({
        number: i,
        current: current === i
      });
    }

    this.pages = pages;
    this.showFirst = from > 1;
    this.showLast = to < total;
  }

  goto(pageNumber: number) {
    this.router.navigate([], {
      queryParams: { page: pageNumber },
      queryParamsHandling: 'merge'
    });
  }

  prev() {
    if (this.enablePrev) {
      this.goto(this.currentPage - 1);
    }
  }

  next() {
    if (this.enableNext) {
      this.goto(this.currentPage + 1);
    }
  }
}
