import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { ModalProvider, LoaderProvider } from '@common/providers';

declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  @ViewChild('modalPlaceholder', { read: ViewContainerRef, static: true })
  modalPlaceholder: ViewContainerRef;

  notificationsOptions = {
    position: ['bottom', 'right'],
    timeOut: 4000,
    lastOnBottom: true,
    showProgressBar: false
  };

  constructor(
    private modalService: ModalProvider,
    private router: Router,
    private loaderProvider: LoaderProvider
  ) {}

  ngOnInit() {
    // Give the modal service somewhere to inject modals
    this.modalService.setViewContainerRef(this.modalPlaceholder);

    // Always move to top of page when route changes
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
      window.scrollTo(0, 0);
      this.loaderProvider.stopLoading();
    });
  }
}
