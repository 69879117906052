import { Model, ModelKeyMappings } from './model';
import { Claim } from './claim.model';
import { Merchant } from './merchant.model';
import { Policy } from './policy.model';
import { ProductVersion } from './product-version.model';

export abstract class SearchResult extends Model {
  abstract indexedFields: any;
  highlight: { string: string[] };
  loading: boolean;
  disabled: boolean;
  isClaim?: boolean;
  isPolicy?: boolean;
  isMerchant?: boolean;
  isProductVersion?: boolean;

  constructor(keyMappings: ModelKeyMappings) {
    super(keyMappings);
  }

  /**
   * Test whether this is one of the fields that matched
   * (i.e. if it is highlighted)
   * @param indexedFieldName
   */
  matched(...fieldNames: string[]): boolean {
    for (let i = 0; i < fieldNames.length; i++) {
      const name = fieldNames[i];
      if (this.highlight[name]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns value for an indexed field, highlighted with
   * <em> if necessarry.
   * @param name
   */
  field(name: string): string {
    if (!this.indexedFields[name]) {
      return '';
    }

    if (this.highlight[name]) {
      return this.highlight[name][0];
    } else {
      return this.indexedFields[name];
    }
  }

  /**
   * Test whether result has an indexed field with this key
   * @param indexedFieldName
   */
  hasField(name: string): boolean {
    return !!this.indexedFields[name];
  }
}

export class ClaimResult extends SearchResult {
  claim: Claim;
  indexedFields: {
    beneficiaryExternalId: string;
    beneficiaryName: string;
    corporationExternalId: string;
    corporationName: string;
    merchantName: string;
    policyExternalId: string;
    policyHolderExternalId: string;
    policyHolderName: string;
  };
  highlight: {
    string: string[];
  };

  constructor() {
    super([{ key: 'claim', useModel: Claim }, 'indexedFields', 'highlight']);
  }

  isClaim = true;
}

export class MerchantResult extends SearchResult {
  merchant: Merchant;
  indexedFields: {
    name: string;
  };
  highlight: {
    string: string[];
  };

  constructor() {
    super([{ key: 'merchant', useModel: Merchant }, 'indexedFields', 'highlight']);
  }

  isMerchant = true;
}

export class PolicyResult extends SearchResult {
  policy: Policy;
  indexedFields: {
    beneficiaryExternalIds: string[];
    beneficiaryNames: string[];
    corporationExternalId: string;
    corporationName: string;
    externalId: string;
    policyHolderExternalId: string;
    policyHolderName: string;
  };
  highlight: {
    string: string[];
  };

  constructor() {
    super([{ key: 'policy', useModel: Policy }, 'indexedFields', 'highlight']);
  }

  isPolicy = true;
}

export class ProductVersionResult extends SearchResult {
  productVersion: ProductVersion;
  indexedFields: {
    name: string;
  };
  highlight: {
    string: string[];
  };

  constructor() {
    super([{ key: 'productVersion', useModel: ProductVersion }, 'indexedFields', 'highlight']);
  }

  isProductVersion = true;
}
