import { Model } from '@common/models';
import { PaymentRuleOverrideType, PaymentRuleType } from '../enums';

export class Specification extends Model {
  ruleName: PaymentRuleType;
  amount: number;
  timeWindowMinutes: number;
  count: number;

  constructor() {
    super(['ruleName', 'amount', 'timeWindowMinutes', 'count']);
  }
}

export class Override extends Model {
  ruleName: string;
  disabledRules: string[];
  specification: Specification;

  constructor() {
    super(['ruleName', 'disabledRules', { key: 'specification', useModel: Specification }]);
  }
}

export class PaymentRulesOverride extends Model {
  id: number;
  cardId: number;
  overrideType: PaymentRuleOverrideType;
  data: any;
  createdAt: string;
  comment: string;
  requestedById: number;
  isActive: boolean;
  validUntil: string;
  validNTimes: number;
  override: Override;

  constructor() {
    super([
      'id',
      'cardId',
      'overrideType',
      'data',
      'createdAt',
      'comment',
      'requestedById',
      'isActive',
      'validUntil',
      'validNTimes',
      { key: 'override', useModel: Override }
    ]);
  }
}
