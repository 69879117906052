<div class="Filters mb-4">
  <div class="Filters__header d-flex justify-content-between">
    <h5>Filters</h5>
    <a (click)="reset()">Reset</a>
  </div>
  <div class="Filters__content mt-3">
    <form class="form-inline" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="form-group filter" *ngFor="let filter of filters; index as i">
        <label [for]="'iFilter' + i">{{ filter.label }}</label>
        <ng-container [ngSwitch]="filter.type">
          <input
            *ngSwitchCase="'text'"
            type="text"
            [formControlName]="filter.param"
            class="form-control"
            [id]="'iFilter' + i"
            [placeholder]="filter.label"
            [attr.disabled]="filter.enabled ? null : ''"
          />
          <select
            *ngSwitchCase="'select'"
            [formControlName]="filter.param"
            class="form-control"
            [id]="'iFilter' + i"
            [attr.disabled]="filter.enabled ? null : ''"
          >
            <option [value]="null" selected>{{ filter.default }}</option>
            <option *ngFor="let option of filter.options" [value]="option.value">{{
              option.label
            }}</option>
          </select>
          <input
            *ngSwitchCase="'date'"
            bsDatepicker
            [maxDate]="maxDate"
            type="text"
            [formControlName]="filter.param"
            placeholder="Choose date"
            i18n-placeholder="@@chooseDatePholder"
            class="form-control"
            [id]="'iFilter' + i"
            [attr.disabled]="filter.enabled ? null : ''"
            [bsConfig]="{ isAnimated: true, adaptivePosition: true }"
          />
          <input
            *ngSwitchCase="'dateRange'"
            bsDaterangepicker
            [maxDate]="maxDate"
            type="text"
            [formControlName]="filter.param"
            placeholder="Select range"
            class="form-control Filter__control--l"
            [id]="'iFilter' + i"
            [attr.disabled]="filter.enabled ? null : ''"
          />
          <input
            *ngSwitchCase="'checkbox'"
            type="checkbox"
            [formControlName]="filter.param"
            class="form-control"
            [id]="'iFilter' + i"
            [placeholder]="filter.label"
            [attr.disabled]="filter.enabled ? null : ''"
          />
        </ng-container>
      </div>

      <div class="submit">
        <ps-button type="submit" btnClass="btn btn-primary filterBtn full-width medium"
          >Filter</ps-button
        >
      </div>
    </form>
  </div>
</div>
