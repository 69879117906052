<ps-modal heading="Payment Rules" #modal>
  <div class="modal-body">
    <div class="Loader" *ngIf="loading"></div>
    <table class="table table-condensed rules-table" *ngIf="rulesForms">
      <tbody>
        <tr class="rules-table__controls">
          <td>
            <input
              type="checkbox"
              [(ngModel)]="checkAll"
              (ngModelChange)="setAll()"
              id="select_all"
            />
          </td>
          <td></td>
          <td>
            <label class="rules-table__label" for="select_all">Select all</label>
          </td>
        </tr>
        <ng-container *ngFor="let rule of rulesForms">
          <tr>
            <td>
              <input
                type="checkbox"
                [(ngModel)]="rule.controls['checked'].value"
                (ngModelChange)="parentCheck(rule)"
              />
            </td>
            <td>
              <a
                (click)="toggleExpanded(rule)"
                class="rules-table__expand d-flex"
                *ngIf="getSubrulesCount(rule) > 0"
              >
                <fa-icon
                  class="mr-1"
                  [icon]="rule.controls['expanded'].value ? icons.caretDown : icons.caretRight"
                ></fa-icon>
                <span *ngIf="!rule.controls['expanded'].value">
                  (+{{ getSubrulesCount(rule) }})
                </span>
              </a>
            </td>
            <td>
              <span class="rules-table__name">
                {{ ruleDisplayName(rule.controls['name'].value) }}
              </span>
              <span class="rules-table__desc">
                {{ ruleDescription(rule.controls['name'].value) }}
              </span>
            </td>
          </tr>
          <tr class="rules-table__subrules" [hidden]="!rule.controls['expanded'].value">
            <td colspan="4">
              <div class="card well">
                <div class="card-body">
                  <table class="table table-condensed subrules-table">
                    <tbody>
                      <tr *ngFor="let subrule of rule.controls['subrules'].controls">
                        <td>
                          <input
                            type="checkbox"
                            [(ngModel)]="subrule.controls['checked'].value"
                            (ngModelChange)="childCheck(rule)"
                          />
                        </td>
                        <td>
                          <span class="rules-table__name">
                            {{ ruleDisplayName(subrule.controls['name'].value) }}
                          </span>
                          <span class="rules-table__desc">
                            {{ ruleDescription(subrule.controls['name'].value) }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <ps-button (onClick)="modal.close()" btnClass="btn btn-secondary">
      Close
    </ps-button>
    <ps-button (onClick)="save()" btnClass="btn btn-primary">Save</ps-button>
  </div>
</ps-modal>
