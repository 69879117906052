import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// Guards
import { AuthGuardProvider } from './core/providers';

// Pages
import { DashboardPageComponent } from './modules/dashboard/pages/dashboard/dashboard-page.component';
import { LoginComponent } from '@common/components';
import { ServerErrorComponent } from '@common/error-page/server-error/server-error.component';
import { NotFoundComponent } from '@common/error-page/not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard/overview', pathMatch: 'full' },
  { path: 'error', component: ServerErrorComponent },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuardProvider],
    data: {
      loggedInRedirect: 'dashboard'
    }
  },
  {
    path: 'dashboard',
    component: DashboardPageComponent,
    canActivate: [AuthGuardProvider],
    data: {
      loggedOutRedirect: 'login'
    },
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      {
        path: 'overview',
        loadChildren: () => import('./modules/overview/overview.module').then(m => m.OverviewModule)
      },
      {
        path: 'claims',
        loadChildren: () => import('./modules/claims/claims.module').then(m => m.ClaimsModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'policies',
        loadChildren: () => import('./modules/policies/policies.module').then(m => m.PoliciesModule)
      },
      {
        path: 'providers',
        loadChildren: () =>
          import('./modules/providers/providers.module').then(m => m.ProvidersModule)
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./modules/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
      }
    ]
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  declarations: [],
  providers: [AuthGuardProvider]
})
export class AppRoutingModule {}
