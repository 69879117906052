import { Component, Input, OnInit } from '@angular/core';

import { Currency, Payment, PaymentAuthorization } from '@common/models';

@Component({
  selector: 'ps-beneficiary-payments',
  templateUrl: './beneficiary-payments.component.html',
  styleUrls: ['./beneficiary-payments.component.scss']
})
export class BeneficiaryPaymentsComponent {
  @Input() beneficiaryPayments: Payment[];
  @Input() claimCurrency: Currency;

  constructor() {}
}
