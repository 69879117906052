import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NotificationsService } from 'angular2-notifications';
import { Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthProvider } from '../../providers';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  submitted: Boolean = false;
  submitting: Boolean = false;
  invalidCreds: Boolean = false;
  unexpectedError: Boolean = false;
  loginSub: Subscription;

  constructor(
    private auth: AuthProvider,
    private router: Router,
    private notifications: NotificationsService,
    private formBuilder: FormBuilder
  ) {
    this.loginForm = this.createForm();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.loginSub.unsubscribe();
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });
  }

  login(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.submitting = true;

    const values = this.loginForm.value;

    this.loginSub = this.auth
      .login(values.email, values.password)
      .pipe(
        catchError(err => {
          this.submitting = false;
          return throwError(err);
        })
      )
      .subscribe(success => {
        if (success) {
          this.router.navigate(['dashboard']);
          this.notifications.info('Logged In', 'You are now logged in');
        } else {
          this.invalidCreds = true;
        }
        this.submitting = false;
      });
  }
}
