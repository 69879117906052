<ps-modal [heading]="title" #modal>
  <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="form-text">
            <p class="text-center" i18n="@@cardActionModalDescription">
              The beneficiary will be notified via their mobile app. You may
              <span class="font--semiBold">optionally</span> provide a message for them to see.
            </p>
          </div>
          <div class="form-group" [ngClass]="{ 'has-error': hasError('message') }">
            <textarea
              rows="3"
              formControlName="message"
              class="form-control"
              placeholder="Message"
              i18n-placeholder="@@messagePholder"
              #textarea
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <ps-button (onClick)="modal.close()" btnClass="btn btn-secondary" i18n="@@cancelBtn">
        Cancel
      </ps-button>
      <ps-button type="submit" btnClass="btn btn-primary">
        {{ title }}
      </ps-button>
    </div>
  </form>
</ps-modal>
