import { snakeCase } from 'lodash';
import { camelCaseObj } from '../utils';

interface ModelPropertyMapping {
  key: string;
  useModel?: any;
  from?: string;
  transform?: Function;
}

interface ModelPropertyTarget {
  targetKey: string;
  model?: any;
  transform: Function;
}

export type ModelKeyMappings = (string | ModelPropertyMapping)[];

export class Model {
  private mapping: { [s: string]: ModelPropertyTarget };

  constructor(keyMappings: ModelKeyMappings) {
    this.mapping = {};

    keyMappings.forEach(keyMapping => {
      let sourceKey: string;
      let targetKey: string;
      let model: any;
      let transform: Function;

      if (typeof keyMapping === 'string') {
        sourceKey = keyMapping;
        targetKey = keyMapping;
      } else {
        sourceKey = keyMapping.from ? keyMapping.from : keyMapping.key;
        targetKey = keyMapping.key;
        if (keyMapping.useModel) {
          model = keyMapping.useModel;
        }
        if (keyMapping.transform) {
          transform = keyMapping.transform;
        }
      }

      this.mapping[sourceKey] = {
        targetKey: targetKey,
        model: model,
        transform: transform
      };
    });
  }

  /**
   * Deserializes from JSON data to model properties
   * @param input JSON object
   */
  deserialize(input: any): this {
    Object.keys(input).forEach(key => {
      // If the mapping for this key is not defined
      if (!this.mapping.hasOwnProperty(key)) {
        // Only log if not production
        // if (!this.environment.production) {
        //   console.warn(
        //     `Couldn't map value to ${this.constructor.name}. No mapping for key '${key}'.`
        //   );
        // }
        return; // Continue
      }

      const mapping = this.mapping[key];
      let value = input[key];

      if (mapping.transform && value) {
        value = mapping.transform(value);
      }

      if (mapping.model) {
        const ProvidedModel = mapping.model;

        // Collection or single?
        if (Array.isArray(value)) {
          value = value.map(o => new ProvidedModel().deserialize(o));
        } else if (value !== null) {
          value = new ProvidedModel().deserialize(value);
        }
      }

      // If the value is an object but no model provided
      // then just make sure it's camelCased
      if (
        value &&
        typeof value === 'object' &&
        value.constructor === Object &&
        mapping.model === undefined
      ) {
        value = camelCaseObj(value);
      }

      this[mapping.targetKey] = value;
    });

    return this;
  }
}
