import { Model } from './model';
import { User } from './user.model';
import { Merchant } from './merchant.model';
import { Benefit } from './benefit.model';
import { ClaimHistoryAction, ClaimHistoryDataAction, Platform } from '../enums';
import { parseJSON, toFloat } from '@common/utils/model-transforms';

class ClaimHistoryData extends Model {
  claimId?: number;

  // MERCHANT_CHANGED
  newMerchantId?: number;
  oldMerchantId?: number;
  newMerchant?: Merchant;
  oldMerchant?: Merchant;

  // BENEFIT_CHANGED
  newBenefitId?: number;
  oldBenefitId?: number;
  newBenefit?: Benefit;
  oldBenefit?: Benefit;

  // NEW_NOTE
  text?: string;

  // (NEW_DECISION|*_NEW_DECISION)
  action?: ClaimHistoryDataAction;

  // NEW_DECISION
  preAuthorization?: any;
  maxAmount?: number;
  reason?: string;

  // *_NEW_DECISION
  documentId?: number;

  // PAYMENT_*
  amount?: string;
  currencyCode?: string;
  fxRate?: string;

  // PAYMENT_(DECLINED|AUTHORIZED)
  merchantName?: string;
  paymentAuthorizationId?: number;

  // PAYMENT_SETTLED
  paymentSettlementId?: number;

  constructor() {
    super([
      { key: 'claimId', from: 'claim_id' },
      { key: 'newMerchantId', from: 'new_merchant_id' },
      { key: 'oldMerchantId', from: 'old_merchant_id' },
      { key: 'oldBenefitId', from: 'old_benefit_id' },
      { key: 'newBenefitId', from: 'new_benefit_id' },
      'text',
      'action',
      { key: 'preAuthorization', from: 'pre_authorizaton' },
      { key: 'maxAmount', from: 'max_amount', transform: toFloat },
      'reason',
      { key: 'documentId', from: 'document_id' },
      { key: 'amount', transform: toFloat },
      { key: 'currencyCode', from: 'currency_code' },
      { key: 'fxRate', from: 'fx_rate', transform: toFloat },
      { key: 'merchantName', from: 'merchant_name' },
      { key: 'paymentAuthorizationId', from: 'payment_authorization_id' },
      { key: 'paymentSettlementId', from: 'payment_settlement_id' }
    ]);
  }

  // ACCESSORS

  get isAccept(): boolean {
    return this.action === ClaimHistoryDataAction.Accept;
  }

  get isPartiallyAccept(): boolean {
    return this.action === ClaimHistoryDataAction.PartiallyAccept;
  }

  get isDecline(): boolean {
    return this.action === ClaimHistoryDataAction.Decline;
  }

  get isCancel(): boolean {
    return this.action === ClaimHistoryDataAction.Cancel;
  }

  get isReset(): boolean {
    return this.action === ClaimHistoryDataAction.Reset;
  }

  get isAuthorize(): boolean {
    return this.action === ClaimHistoryDataAction.Authorize;
  }

  get isReferAuthorization(): boolean {
    return this.action === ClaimHistoryDataAction.ReferAuthorization;
  }

  get isRequestNew(): boolean {
    return this.action === ClaimHistoryDataAction.RequestNew;
  }

  get isPendingAcceptance(): boolean {
    return this.action === ClaimHistoryDataAction.PendingAcceptance;
  }

  get isMarkAsPaid(): boolean {
    return this.action === ClaimHistoryDataAction.MarkAsPaid;
  }
}

export class ClaimHistory extends Model {
  id: number;
  action: ClaimHistoryAction;
  data: ClaimHistoryData;
  timestamp: string;
  source: Platform;
  user: User;

  constructor() {
    super([
      'id',
      'action',
      { key: 'data', transform: parseJSON, useModel: ClaimHistoryData },
      'timestamp',
      'source',
      { key: 'user', useModel: User }
    ]);
  }

  // Accessors

  get fromMobileApp(): boolean {
    return this.source === Platform.MobileApp;
  }

  get fromInsurerDashboard(): boolean {
    return this.source === Platform.InsurerDashboard;
  }

  get fromPaymentPlatform(): boolean {
    return this.source === Platform.PaymentPlatform;
  }

  get fromVisaPortal(): boolean {
    return this.source === Platform.VisaPortal;
  }

  get isCreated(): boolean {
    return this.action === ClaimHistoryAction.Created;
  }

  get isUpdated(): boolean {
    return this.action === ClaimHistoryAction.Updated;
  }

  get isMerchantChanged(): boolean {
    return this.action === ClaimHistoryAction.MerchantChanged;
  }

  get isBenefitChanged(): boolean {
    return this.action === ClaimHistoryAction.BenefitChanged;
  }

  get isNewDecision(): boolean {
    return this.action === ClaimHistoryAction.NewDecision;
  }

  get isNewNote(): boolean {
    return this.action === ClaimHistoryAction.NewNote;
  }

  get isReceiptUploaded(): boolean {
    return this.action === ClaimHistoryAction.ReceiptUploaded;
  }

  get isReceiptNewDecision(): boolean {
    return this.action === ClaimHistoryAction.ReceiptNewDecision;
  }

  get isSupportingDocumentUploaded(): boolean {
    return this.action === ClaimHistoryAction.SupportingDocumentUploaded;
  }

  get isSupportingDocumentNewDecision(): boolean {
    return this.action === ClaimHistoryAction.SupportingDocumentNewDecision;
  }

  get isPaymentDeclined(): boolean {
    return this.action === ClaimHistoryAction.PaymentDeclined;
  }

  get isPaymentAuthorized(): boolean {
    return this.action === ClaimHistoryAction.PaymentAuthorized;
  }

  get isPaymentSettled(): boolean {
    return this.action === ClaimHistoryAction.PaymentSettled;
  }
}
