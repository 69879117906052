<div class="Dashboard">
  <ps-sidebar
    [links]="links"
    [displayOptions]="displayOptions.sidebarOptions"
    [open]="sidebarOpen"
    (onClose)="toggleSidebar()"
  ></ps-sidebar>
  <div class="Dashboard__main">
    <ps-navbar
      [showSearch]="displayOptions.showSearch"
      [showLogo]="showNavbar"
      (onMenuToggle)="toggleSidebar()"
    ></ps-navbar>
    <div class="Dashboard__content">
      <ps-breadcrumbs *ngIf="displayOptions.showBreadcrumbs"></ps-breadcrumbs>
      <div class="Dashboard__view">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
