import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import * as Sentry from '@sentry/browser';
import { environment } from '@environment';
import { ModalProvider } from '@common/providers';
import { AlertComponent, AlertComponentInput } from '@common/components';

@Injectable({ providedIn: 'root' })
export class PaysureErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }

    if (error.rejection instanceof HttpErrorResponse) {
      // Server or connection error happened
      const router = this.injector.get(Router);
      router.navigate(['/error']);
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      const modalProvider = this.injector.get(ModalProvider);
      modalProvider.open(AlertComponent, <AlertComponentInput>{
        title: 'Unexpected Error',
        text: [
          'An unexpected error has occurred. Our team has ',
          'been notified and will look into it as soon as ',
          'possible. If the problem persists, please contact ',
          'Paysure customer support.'
        ].join(''),
        buttons: [
          {
            text: 'OK',
            handler: () => true // Close on click
          }
        ]
      });
    }
  }
}
