import gql from 'graphql-tag';

export const FundingAccountFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on FundingAccountType {
      iban
      bic
      statistics {
        insurerFundsAmounts {
          amount
          currencyCode
        }
      }
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on FundingAccountType {
      iban
      bic
      statistics {
        insurerFundsAmounts {
          amount
          currencyCode
        }
      }
    }
  `
};
