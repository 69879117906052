export enum ClaimState {
  Authorized = 'AUTHORIZED',
  Accepted = 'ACCEPTED',
  PartiallyAccepted = 'PARTIALLY_ACCEPTED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED',
  PaymentDeclined = 'PAYMENT_DECLINED',
  PendingAuthorization = 'PENDING_AUTHORIZATION',
  ReferredAuthorization = 'REFERRED_AUTHORIZATION',
  Paid = 'PAID',
  PendingReview = 'PENDING_REVIEW',
  PendingAcceptance = 'PENDING_ACCEPTANCE',
  HistoricClaim = 'HISTORIC_CLAIM',
  DirectSettlement = 'DIRECT_SETTLEMENT'
}
