import gql from 'graphql-tag';

export const AddressFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on AddressType {
      formatted
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on AddressType {
      formatted
    }
  `
};
