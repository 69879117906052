<h5 i18n="@@insurerPayments">Insurer Payments</h5>
<div class="table-responsive">
  <table class="table payments-table">
    <thead>
      <tr>
        <th i18n="@@time">Time</th>
        <th i18n="@@status">Status</th>
        <th i18n="@@amount">Amount</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let p of insurerPayments">
        <tr class="payments-table__row" (click)="toggle(p)">
          <td width="160">{{ p.createdAt | localDate }}</td>
          <td>
            <ps-payment-state-label [state]="p.state"></ps-payment-state-label>
          </td>
          <td class="expandCell d-flex justify-content-end expand">
            <span>
              {{
                p.settlement?.amount !== null
                  ? (p.settlement.amount | currency: p.currencyCode)
                  : (0 | currency: p.currencyCode)
              }}
            </span>
            <div class="expandIconContainer">
              <fa-icon [icon]="p.expanded ? icons.caretUp : icons.caredDown"></fa-icon>
            </div>
          </td>
        </tr>
        <tr class="payments-table__breakdown" [ngClass]="{ 'd-none': !p.expanded }">
          <td colspan="4" class="pl-0 pr-0">
            <ps-payment-breakdown
              [payment]="p"
              [canViewClaim]="canViewClaim"
              [currencyCode]="p.currencyCode"
              [merchantName]="p.merchantName"
            ></ps-payment-breakdown>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
