import { Component, Input } from '@angular/core';
import {
  faMinusCircle,
  faPlusCircle,
  faExpandAlt,
  faCompressAlt
} from '@fortawesome/free-solid-svg-icons';
import { BenefitUsageInput } from '../benefit-usages.component';

@Component({
  selector: 'ps-benefit-usage[benefit][currencyCode]',
  templateUrl: './benefit-usage.component.html',
  styleUrls: ['./benefit-usage.component.scss']
})
export class BenefitUsageComponent {
  @Input() benefit: BenefitUsageInput;
  @Input() open = true;
  @Input() currencyCode: string;

  icons = {
    faPlusCircle,
    faMinusCircle,
    faExpandAlt,
    faCompressAlt
  };

  get progressWidth() {
    const pct = (this.benefit.limit.amount.remaining / this.benefit.limit.amount.total) * 100;
    return `${Math.round(pct)}%`;
  }

  toggle() {
    this.open = !this.open;
  }
}
