import { Component, OnInit, Input } from '@angular/core';

import { checkVal } from '@common/utils';
import { Claim } from '@common/models';
import { ClaimState } from '@common/enums';

@Component({
  selector: 'ps-claim-state-label',
  templateUrl: './claim-state-label.component.html',
  styleUrls: ['./claim-state-label.component.scss']
})
export class ClaimStateLabelComponent implements OnInit {
  @Input() state: ClaimState;
  @Input() showTooltip?: boolean;

  constructor() {}

  ngOnInit() {
    checkVal(this.state, 'ClaimStateLabelComponent expected state');
  }

  get className(): string {
    switch (this.state) {
      case ClaimState.Accepted:
      case ClaimState.PartiallyAccepted:
        return 'badge-success';
      case ClaimState.Authorized:
      case ClaimState.ReferredAuthorization:
      case ClaimState.Paid:
      case ClaimState.DirectSettlement:
        return 'badge-info';
      case ClaimState.Cancelled:
      case ClaimState.Declined:
      case ClaimState.PaymentDeclined:
        return 'badge-danger';
      case ClaimState.PendingAuthorization:
      case ClaimState.PendingReview:
      case ClaimState.PendingAcceptance:
        return 'badge-warning';
      default:
        return 'badge-default';
    }
  }

  get tooltipText(): string {
    switch (this.state) {
      case ClaimState.Accepted:
        return 'The claim has been accepted';
      case ClaimState.PartiallyAccepted:
        return 'The claim has been partially accepted';
      case ClaimState.Authorized:
        return 'The claim has been authorized and is ready to spend';
      case ClaimState.Cancelled:
        return 'The claim was cancelled';
      case ClaimState.Declined:
        return 'The claim was declined';
      case ClaimState.PendingAuthorization:
        return 'The claim is awaiting authorization';
      case ClaimState.PendingReview:
        return 'The claim has receipts that must be reviewed';
      case ClaimState.ReferredAuthorization:
        return 'The claim has been referred for authorization by a user with insufficient claims authority to assess it';
      case ClaimState.Paid:
        return 'Treatment has been paid for and supporting documentation may now be added';
      case ClaimState.DirectSettlement:
        return 'The claim has been settled directly with the provider.';
      case ClaimState.PendingAcceptance:
        return 'All receipts have been resolved and the claim is ready to accept';
      case ClaimState.PaymentDeclined:
        return 'Payment was declined by the decision engine';
      case ClaimState.HistoricClaim:
        return 'The claim originates from before Paysure was implemented and cannot be changed';
      default:
        return null;
    }
  }

  get stateString(): string {
    return Claim.stateToString(this.state);
  }
}
