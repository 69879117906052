import { Currency } from './currency.model';
import { Document } from './document.abstract.model';
import { toDate, toFloat } from '@common/utils/model-transforms';
import { Claim } from './claim.model';
import { ReceiptItem } from './receipt-item.model';
import { DocumentState } from '@common/enums';

export class Receipt extends Document {
  paymentAuthorizationId?: number;
  acceptedAmount: number;
  acceptedOriginAmount: number;
  currencyId?: number;
  fxRate?: number;
  transactionFxRate?: number;
  transactionAt?: Date;
  currency?: Currency;
  claim?: Claim;
  items: ReceiptItem[];

  constructor() {
    super([
      'paymentAuthorizationId',
      'acceptedAmount',
      'acceptedOriginAmount',
      'currencyId',
      { key: 'fxRate', transform: toFloat },
      { key: 'transactionFxRate', transform: toFloat },
      { key: 'transactionAt', transform: toDate },
      { key: 'currency', useModel: Currency },
      { key: 'claim', useModel: Claim },
      { key: 'items', useModel: ReceiptItem }
    ]);
  }

  /**
   * Returns authorization FX rate if not null, otherwise
   * receipt FX rate, otherwise 1.00.
   */
  get bestFxRate(): number {
    let fxRate: number;
    if (this.transactionFxRate) {
      fxRate = this.transactionFxRate;
    } else if (this.fxRate) {
      fxRate = this.fxRate;
    } else {
      fxRate = 1;
    }
    return fxRate;
  }

  get itemsTotal() {
    let total = 0;
    this.items.forEach(item => {
      total += item.amount;
    });

    if (this.fxRate) {
      return total / this.fxRate;
    } else {
      return total;
    }
  }

  get ineligibleTotal() {
    let total = 0;

    this.items.forEach(item => {
      if (this.state === DocumentState.Declined) {
        total += item.amount;
      } else {
        total += !item.isAccepted ? item.amount : 0;
      }
    });

    if (this.fxRate) {
      return total / this.fxRate;
    } else {
      return total;
    }
  }
}
