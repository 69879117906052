import { Model } from './model';
import { Currency } from './currency.model';
import { Merchant } from './merchant.model';
import { Benefit } from './benefit.model';
import { User } from './user.model';
import { Receipt } from './receipt.model';
import { Platform, ClaimState, ClaimType, CardState } from '../enums';
import { SupportingDocument } from './supporting-document.model';
import { Policy } from './policy.model';
import { Netting } from './netting.model';
import { CptCodes } from './cpt-codes.model';
import { IcdCode } from './icd-code.model';
import { Payment } from './payment.model';
import { Beneficiary } from './beneficiary.model';
import { toInt } from '@common/utils/model-transforms';
import { PaymentAuthorization } from './payment-authorization.model';

export class ClaimIcdCode extends Model {
  id: number;
  icdCode: IcdCode;
  default: boolean;
  provider: {
    id: number;
  };

  constructor() {
    super([
      { key: 'id', transform: toInt },
      { key: 'icdCode', useModel: IcdCode },
      'default',
      'provider'
    ]);
  }
}

export class PreAuthorization extends Model {
  symptoms: string;
  condition: string;
  comments?: string;
  isNewCondition: boolean;
  preferredDate: Date;
  receivedTreatment: boolean;
  timeWindowFrom?: string;
  timeWindowTo?: string;
  preferredMerchantId: number;
  preferredMerchant: Merchant;
  maxAmount: number;

  constructor() {
    super([
      'symptoms',
      'condition',
      'comments',
      'isNewCondition',
      { key: 'preferredDate', transform: val => new Date(val) },
      'preferredMerchantId',
      'receivedTreatment',
      { key: 'timeWindowFrom', transform: val => new Date(val) },
      { key: 'timeWindowTo', transform: val => new Date(val) },
      'preferredMerchantId',
      { key: 'preferredMerchant', useModel: Merchant },
      'maxAmount'
    ]);
  }
}

export class Claim extends Model {
  id: number;
  creatorId?: number;
  currency: Currency;
  merchantId?: number;
  policyId: number;
  benefitId: number;
  beneficiaryId: number;
  state: ClaimState;
  maxAmount?: number;
  eligibleAmount?: number;
  breakdown?: {
    benefitLevelExcess: number;
    coPayment: number;
    covered: number;
    perClaimExcess: number;
    policyWideExcess: number;
  };
  totalAmount?: number;
  source: Platform;
  created: string;
  updated: string;
  preAuthorization?: PreAuthorization;
  isDummy: boolean;
  requestedById: number;
  reason?: string;
  retroactive: boolean;
  amountToBeCharged?: number;
  amountToBeReimbursed?: number;
  netting: Netting;
  icdCodes: ClaimIcdCode[];
  cptCodes: CptCodes;
  referenceId: string;
  cardState: CardState;
  remainingAmount: number;
  numberOfPayments: number;
  additionalInformation: string;

  /**
   * DEPRECIATED
   */
  acceptedAmount?: number;

  /**
   * DEPRECIATED
   */
  transactionAmount?: number;

  // Related objects
  policy?: Policy;
  beneficiary?: Beneficiary;
  merchant?: Merchant;
  receipts?: Receipt[];
  supportingDocuments?: SupportingDocument[];
  payments?: Payment[];
  paymentAuthorizations?: PaymentAuthorization[];
  benefit?: Benefit;
  creator?: User;

  constructor() {
    super([
      { key: 'id', transform: toInt },
      'creatorId',
      { key: 'currency', useModel: Currency },
      'merchantId',
      'policyId',
      'benefitId',
      'beneficiaryId',
      'state',
      { key: 'maxAmount', transform: val => parseFloat(val) },
      { key: 'eligibleAmount', transform: val => parseFloat(val) },
      'breakdown',
      { key: 'totalAmount', transform: val => parseFloat(val) },
      'source',
      'created',
      'updated',
      { key: 'preAuthorization', useModel: PreAuthorization },
      'isDummy',
      'requestedById',
      'retroactive',
      'reason',
      'retroactive',
      { key: 'amountToBeCharged', transform: val => parseFloat(val) },
      { key: 'amountToBeReimbursed', transform: val => parseFloat(val) },
      { key: 'acceptedAmount', transform: val => parseFloat(val) },
      { key: 'transactionAmount', transform: val => parseFloat(val) },
      { key: 'policy', useModel: Policy },
      { key: 'beneficiary', useModel: Beneficiary },
      { key: 'merchant', useModel: Merchant },
      { key: 'receipts', useModel: Receipt },
      { key: 'supportingDocuments', useModel: SupportingDocument },
      { key: 'netting', useModel: Netting },
      { key: 'icdCodes', from: 'claimicdcode', useModel: ClaimIcdCode },
      { key: 'cptCodes', useModel: CptCodes },
      { key: 'payments', useModel: Payment },
      { key: 'benefit', useModel: Benefit },
      'referenceId',
      'cardState',
      { key: 'paymentAuthorizations', useModel: PaymentAuthorization },
      { key: 'benefit', useModel: Benefit },
      'remainingAmount',
      'numberOfPayments',
      'additionalInformation'
    ]);
  }

  static stateToString(state: ClaimState): string {
    switch (state) {
      case ClaimState.Authorized:
        return 'Authorized';
      case ClaimState.Accepted:
        return 'Accepted';
      case ClaimState.PartiallyAccepted:
        return 'Partially Accepted';
      case ClaimState.Declined:
        return 'Declined';
      case ClaimState.Cancelled:
        return 'Cancelled';
      case ClaimState.PaymentDeclined:
        return 'Payment Declined';
      case ClaimState.PendingAuthorization:
        return 'Pending Authorization';
      case ClaimState.ReferredAuthorization:
        return 'Referred for Authorization';
      case ClaimState.Paid:
        return 'Paid';
      case ClaimState.PendingReview:
        return 'Pending Review';
      case ClaimState.PendingAcceptance:
        return 'Pending Acceptance';
      case ClaimState.HistoricClaim:
        return 'Historic Claim';
      case ClaimState.DirectSettlement:
        return 'Direct Settlement';
      default:
        return 'Unknown';
    }
  }

  get isAuthorized(): boolean {
    return this.state === ClaimState.Authorized;
  }

  get isAccepted(): boolean {
    return this.state === ClaimState.Accepted;
  }

  get isPartiallyAccepted(): boolean {
    return this.state === ClaimState.PartiallyAccepted;
  }

  get isDeclined(): boolean {
    return this.state === ClaimState.Declined;
  }

  get isCancelled(): boolean {
    return this.state === ClaimState.Cancelled;
  }

  get isPaymentDeclined(): boolean {
    return this.state === ClaimState.PaymentDeclined;
  }

  get isPendingAuthorization(): boolean {
    return this.state === ClaimState.PendingAuthorization;
  }

  get isReferredAuthorization(): boolean {
    return this.state === ClaimState.ReferredAuthorization;
  }

  get isPaid(): boolean {
    return this.state === ClaimState.Paid;
  }

  get isPendingReview(): boolean {
    return this.state === ClaimState.PendingReview;
  }

  get isPendingAcceptance(): boolean {
    return this.state === ClaimState.PendingAcceptance;
  }

  get isHistoricClaim(): boolean {
    return this.state === ClaimState.HistoricClaim;
  }

  get isResolved(): boolean {
    return (
      [
        ClaimState.Accepted,
        ClaimState.PartiallyAccepted,
        ClaimState.Cancelled,
        ClaimState.Declined,
        ClaimState.PaymentDeclined,
        ClaimState.HistoricClaim
      ].indexOf(this.state) !== -1
    );
  }

  get canResetState(): boolean {
    return (
      [
        ClaimState.Accepted,
        ClaimState.PartiallyAccepted,
        ClaimState.Cancelled,
        ClaimState.Declined
      ].indexOf(this.state) !== -1
    );
  }

  get stateString(): string {
    return Claim.stateToString(this.state);
  }

  get sourceString(): string {
    switch (this.source) {
      case Platform.InsurerDashboard:
        return 'Insurer Dashboard';
      case Platform.MobileApp:
        return 'Mobile App';
      case Platform.PaymentPlatform:
        return 'Payment Platform';
      case Platform.ImportScript:
        return 'Import Script';
      case Platform.VisaPortal:
        return 'Visa Portal';
      default:
        return 'Unknown';
    }
  }

  get hasReceipts(): boolean {
    return this.receipts && this.receipts.length > 0;
  }

  get hasSupportingDocuments(): boolean {
    return this.supportingDocuments && this.supportingDocuments.length > 0;
  }

  get hasPreauthorization(): boolean {
    return this.preAuthorization ? true : false;
  }

  get hasPreferredMerchant(): boolean {
    return this.hasPreauthorization && !!this.preAuthorization.preferredMerchant;
  }

  get amount(): number {
    if (this.acceptedAmount) {
      return this.acceptedAmount;
    } else if (this.maxAmount) {
      return this.maxAmount;
    } else if (this.transactionAmount) {
      return this.transactionAmount;
    } else {
      return null;
    }
  }

  get sumOfReceipts(): number {
    let sum = 0;
    if (!this.receipts) {
      return sum;
    }
    this.receipts.forEach(receipt => {
      if (receipt.isAccepted || receipt.isPartiallyAccepted) {
        sum += receipt.acceptedAmount;
      }
    });
    return sum;
  }

  get unresolvedReceipts(): Receipt[] {
    if (!this.receipts) {
      return [];
    }
    return this.receipts.filter(r => !r.isResolved);
  }

  get unresolvedSupportingDocuments(): SupportingDocument[] {
    if (!this.supportingDocuments) {
      return [];
    }
    return this.supportingDocuments.filter(d => !d.isResolved);
  }

  get type(): ClaimType {
    return this.source === Platform.PaymentPlatform ? ClaimType.Instant : ClaimType.PreAuth;
  }

  static cardStateToString(state: CardState): string {
    switch (state) {
      case CardState.Queued:
        return 'Queued';
      case CardState.Active:
        return 'Active';
      case CardState.Revoked:
        return 'Revoked';
      case CardState.Used:
        return 'Used';
      case CardState.MultipleCards:
        return 'Multiple Cards';
      case CardState.NoCard:
        return 'No Card';
      default:
        return 'Unknown';
    }
  }
}
