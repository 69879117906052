import { PaymentRule } from '@common/enums';

export const mappingsDict = {
  [PaymentRule.CardHolderPresenceRule]: {
    PRESENT: 'Cardholder present',
    NOT_PRESENT_UNSPECIFIED: 'Cardholder was not present',
    NOT_PRESENT_MAIL: 'Transaction was conducted over mail',
    NOT_PRESENT_PHONE: 'Transaction was conducted over phone',
    NOT_PRESENT_RECURRING: 'Recurring transaction',
    NOT_PRESENT_ELECTRONIC: 'Transaction was conducted over Internet',
    NOT_PRESENT_INSTALMENT: 'Instalment',
    UNKNOWN: 'We were unable to check if the cardholder was present for this transaction'
  },
  [PaymentRule.CardPresenceRule]: {
    PRESENT: 'Card was present',
    NOT_PRESENT: 'Card was not present',
    UNKNOWN: 'We were unable to check if the card was present for this transaction'
  },
  [PaymentRule.CardDataInputRule]: {
    UNSPECIFIED: 'Card data input method was not specified',
    MANUAL: 'Manual input of card data',
    MAGNETIC_STRIPE: 'Magnetic stripe was used',
    BAR_CODE: 'Bar code was used',
    OCR: 'Optical Content Recognition method was used',
    EMV_CONTACT: 'Chip was used',
    KEY_ENTERED_MERCHANT: 'Card number was entered manually by the merchant',
    CONTACTLESS: 'Contactless payment',
    E_COMMERCE: 'E-commerce payment',
    E_COMMERCE_EMV: 'E-commerce payment; EMV cryptogram was used',
    CONTACTLESS_MAGNETIC_STRIPE: 'Contactless payment with magnetic stripe card',
    KEY_ENTERED_ACQUIRER: 'Card number was entered manually by the acquirer',
    MICR_READER: 'MICR reader was used',
    QR_CODE: 'QR code was used'
  },
  [PaymentRule.ChipFallbackRule]: {
    UNKNOWN: 'We were unable to check if the chip was used for this transaction',
    NOT_FALLBACK: 'Fallback for chip card was not used',
    FALLBACK: "Magnetic stripe was used instead of the card's chip"
  },
  [PaymentRule.FraudIndicatorMerchantRule]: {
    NO_PROBLEM: 'Transaction was not flagged by merchant as suspicious',
    MERCHANT_SUSPICIOUS: 'Transaction was flagged by merchant as suspicious',
    MERCHANT_VERIFIED_BY_CARDHOLDER_ID: 'Merchant verified the cardholder ID'
  },
  [PaymentRule.SecurityProtocolRule]: {
    NONE: 'No secure method transmission of data was used',
    HTTPS: 'Data transmitted securely (HTTPS)',
    NOT_APPLICABLE: 'Not applicable'
  },
  [PaymentRule.SecurityMethod3dSecureRule]: {
    UNKNOWN: 'Authentication method is unknown',
    NONE: 'There was no authentication method used',
    PASSWORD: 'Card holder was verified by password',
    SECRET_KEY: 'Card holder was verified by secret key or one time password',
    OTP_KEY: 'Card holder was verified by one time key',
    OTP_APP: 'Card holder was verified by one time key provided by an application',
    OTP_OTHER: 'Card holder was verified by one time password',
    KBA: 'Card holder was verified by answering knowledge-based questions.',
    OOB_BIOMETRIC: 'Card holder was verified by Out Of Band authentication - using biometrics',
    OOB_APP: 'Card holder was verified by Out Of Band authentication - using application',
    OOB_OTHER: 'Card holder was verified by Out Of Band authentication',
    OTHER: 'Card holder was verified by unspecified authentication method',
    ATTEMPTS_SERVER_RESPONDING: 'Card holder was verified by Visa Attempts Server',
    FRICTIONLESS: 'Frictionless flow was used'
  },
  [PaymentRule.AuthenticationMethodRule]: {
    NOT_AUTHENTICATED: 'No authentication method was used',
    PIN: 'PIN was used',
    ELECTRONIC_SIGNATURE_ANALYSIS: 'Electronic signature analysis was performed',
    BIOMETRICS: 'Card holder was verified by the use of biometrics',
    BIOGRAPHIC: 'Card holder was verified by their biographic data',
    MANUAL_SIGNATURE: 'Card holder was verified by their signature',
    OTHER_MANUAL_VERIFICATION: 'Card holder was verified by unknown manual method',
    UNKNOWN: 'Authentication method used is unknown',
    USED_3D_SECURE: 'Card holder was verified by 3D Secure service'
  },
  [PaymentRule.AuthenticationEntityRule]: {
    NOT_AUTHENTICATED: 'No authentication entity was present',
    CHIP_CARD: 'Card holder verification was performed by the chip card',
    CARD_ACCEPTANCE_DEVICE: 'Card holder verification was performed by a card acceptance device',
    AUTHORISING_AGENT: 'Card holder verification was performed by the authorising agent',
    MERCHANT: 'Card holder verification was performed by the merchant',
    OTHER: 'Card holder verification was performed by unrecognised method',
    CARD_HOLDER_DEVICE: 'Card holder verification was performed by a device held by the user',
    UNKNOWN: 'User verification was performed by unknown entity'
  }
};
