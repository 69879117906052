import { Component, Input } from '@angular/core';

import { PaymentAuthorization, Payment, Currency } from '../../models';

@Component({
  selector: 'ps-payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.scss']
})
export class PaymentsTableComponent {
  @Input() insurerPayments: PaymentAuthorization[];
  @Input() beneficiaryPayments: Payment[];
  @Input() canViewClaim: boolean;
  @Input() claimCurrency: Currency;
}
