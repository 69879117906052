import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderComponent } from './provider.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, TooltipModule.forRoot()],
  declarations: [ProviderComponent],
  exports: [ProviderComponent]
})
export class ProviderComponentModule {}
