import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { CardService } from './card.service';

export const CardServiceConfig = new InjectionToken<ServiceConfig>('CardServiceConfig');

@NgModule()
export class CardServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<CardServiceModule> {
    return {
      ngModule: CardServiceModule,
      providers: [CardService, { provide: CardServiceConfig, useValue: config }]
    };
  }
}
