import { Component, OnInit } from '@angular/core';
import {
  Location,
  LocationStrategy,
  HashLocationStrategy
} from '@angular/common';

@Component({
  selector: 'app-not-found',
  providers: [
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit() {}

  goBack() {
    this.location.back();
  }
}
