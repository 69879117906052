import { Component, OnInit, Inject, ComponentFactoryResolver } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import * as moment from 'moment-timezone';
import { NotificationsService } from 'angular2-notifications';

import { User } from '@common/models';
import { SettingsProvider, ModalProvider } from '@common/providers';
import { checkVal } from '@common/utils';
import { FundingAccount } from '@common/models/funding-account.model';
import { CardIbanModalComponent } from '@common/components';

interface RouteData {
  currentUser: User;
  fundingAccounts: FundingAccount[];
}

@Component({
  selector: 'ps-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  settingsForm: FormGroup;
  timezones: string[];
  currentUser: User;
  fundingAccounts: FundingAccount[];

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalProvider: ModalProvider,
    private settingsService: SettingsProvider,
    private notifications: NotificationsService,
    @Inject(ComponentFactoryResolver) private cfr: ComponentFactoryResolver
  ) {
    this.modalProvider.updateContext(this.cfr);
    this.settingsForm = this.createForm();
  }

  ngOnInit() {
    this.route.data.subscribe(({ currentUser, fundingAccounts }: RouteData) => {
      this.currentUser = checkVal(currentUser, 'AccountComponent expected resolved currentUser');

      this.fundingAccounts = checkVal(
        fundingAccounts,
        'AccountComponent expected resolved fundingAccounts'
      );
    });
    // Populate timezone dropdown
    this.timezones = moment.tz
      .names()
      .filter(tz => (tz.match(/\//g) || []).length === 1 && !tz.match(/Etc/));
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      timezone: [this.settingsService.settings.timezone, Validators.required]
    });
  }

  onSubmit(): void {
    const formModel = this.settingsForm.value;

    this.settingsService.settings.timezone = formModel.timezone;
    this.settingsService.save();

    this.notifications.success('Settings Updated', 'Your settings have been updated');
  }

  showIban(iban: string, bic: string) {
    this.modalProvider.open(CardIbanModalComponent, { iban, bic });
  }
}
