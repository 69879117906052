import { Model } from './model';
import { PaymentState, PaymentType, PaymentTypename } from '../enums';
import { PaymentAuthorization } from './payment-authorization.model';
import { PaymentSettlement } from './payment-settlement.model';

export class Payment extends Model {
  id: number;
  cardId: number;
  personalCardId: number;
  claimId: number;
  authorizationId: number;
  settlementId: number;
  amount: number;
  transactionTime: string;
  createdAt: string;
  type: PaymentType;
  state: PaymentState;
  __typename: PaymentTypename;

  authorization: PaymentAuthorization;
  settlement: PaymentSettlement;

  expanded = false;

  constructor() {
    super([
      'id',
      'cardId',
      'personalCardId',
      'claimId',
      'authorizationId',
      'settlementId',
      'amount',
      'transactionTime',
      'createdAt',
      'type',
      '__typename',
      'state',
      { key: 'authorization', useModel: PaymentAuthorization },
      { key: 'settlement', useModel: PaymentSettlement }
    ]);
  }

  static stateToString(state: PaymentState): string {
    switch (state) {
      case PaymentState.Authorized:
        return 'Authorized';
      case PaymentState.Cancelled:
        return 'Cancelled';
      case PaymentState.Declined:
        return 'Declined';
      case PaymentState.Pending:
        return 'Pending';
      case PaymentState.Settled:
        return 'Settled';
      default:
        return 'Unknown';
    }
  }

  get isInsurerPayment(): boolean {
    return this.__typename === PaymentTypename.Insurer;
  }

  get isBeneficiaryPayment(): boolean {
    return this.__typename === PaymentTypename.Beneficiary;
  }
}
