import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { ProductVersionService } from './product-version.service';

export const ProductVersionServiceConfig = new InjectionToken<ServiceConfig>(
  'ProductVersionServiceConfig'
);

@NgModule()
export class ProductVersionServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<ProductVersionServiceModule> {
    return {
      ngModule: ProductVersionServiceModule,
      providers: [ProductVersionService, { provide: ProductVersionServiceConfig, useValue: config }]
    };
  }
}
