<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-1 result">
        <fa-icon [icon]="resultIcon" [ngClass]="resultIconClass"></fa-icon>
      </div>
      <div class="col-11 content">
        <h5>
          {{ ruleName }}
        </h5>
        <p>
          {{ ruleDescription }}
        </p>
        <ng-content> </ng-content>
      </div>
    </div>
  </div>
</div>
