import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { AuthProvider } from './auth.provider';
import { ServiceConfig } from '../../services/common';

export interface IAuthProviderConfig extends ServiceConfig {
  gqlEndpoint?: string;
}

export const AuthProviderConfig = new InjectionToken<IAuthProviderConfig>('AuthProviderConfig');

@NgModule()
export class AuthProviderModule {
  public static forRoot(config: IAuthProviderConfig): ModuleWithProviders<AuthProviderModule> {
    return {
      ngModule: AuthProviderModule,
      providers: [AuthProvider, { provide: AuthProviderConfig, useValue: config }]
    };
  }
}
