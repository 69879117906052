import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SimpleNotificationsModule } from 'angular2-notifications';

import { AccountComponent } from './account.component';
import { LinkComponentModule } from '../link';
import { NavbarComponentModule } from '../navbar';
import { ButtonComponentModule } from '../button';
import { LocalDatePipeModule } from '@common/pipes';
import { CardIbanModalComponentModule } from '../card-iban-modal';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    LinkComponentModule,
    ButtonComponentModule,
    NavbarComponentModule,
    LocalDatePipeModule,
    SimpleNotificationsModule.forRoot(),
    CardIbanModalComponentModule
  ],
  declarations: [AccountComponent],
  exports: [AccountComponent]
})
export class AccountComponentModule {}
