import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderProvider {
  private loaders = {};

  constructor() {}

  startLoading(loaderId: string) {
    this.loaders[loaderId] = true;
  }

  stopLoading(loaderId?: string) {
    if (loaderId) {
      this.loaders[loaderId] = false;
    } else {
      this.loaders = {};
    }
  }

  isLoading(loaderId: string): boolean {
    return this.loaders[loaderId] || false;
  }
}
