import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BenefitBreakdownComponent } from './benefit-breakdown/benefit-breakdown.component';
import { BenefitExclusionsComponent } from './benefit-exclusions/benefit-exclusions.component';
import { BenefitExclusionComponent } from './benefit-exclusion/benefit-exclusion.component';
import { BenefitUsageComponent } from './benefit-usage/benefit-usage.component';
import { BenefitUsagesComponent } from './benefit-usages.component';
import { TruncatePipeModule } from '@common/pipes';
import { BenefitExclusionDialogComponent } from './benefit-exclusion-dialog/benefit-exclusion-dialog.component';
import { ModalComponentModule } from '../modal';
import { ModalProviderModule } from '@common/providers';
import { ButtonComponentModule } from '../button';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    TruncatePipeModule,
    ModalComponentModule,
    ModalProviderModule,
    ButtonComponentModule
  ],
  providers: [
    CurrencyPipe
  ],
  declarations: [
    BenefitUsagesComponent,
    BenefitUsageComponent,
    BenefitBreakdownComponent,
    BenefitExclusionsComponent,
    BenefitExclusionComponent,
    BenefitExclusionDialogComponent
  ],
  exports: [BenefitUsagesComponent]
})
export class BenefitUsagesComponentModule {}
