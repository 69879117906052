import { Injectable } from '@angular/core';
import { Country } from '../../models';
import { GQLService } from '../common';
import { CountryFragments } from '@common/fragments';
import { Apollo } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class CountryService extends GQLService<Country> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: Country,
      singular: 'country',
      plural: 'countries',
      fragments: CountryFragments
    });
  }
}
