import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultTo'
})
export class DefaultToPipe implements PipeTransform {
  transform(value: any, defaultValue: string): any {
    const isFalsey = !value;
    const isNegativeNumber = typeof value === 'number' && value < 0;

    if (isFalsey || isNegativeNumber) {
      return defaultValue;
    }

    return value;
  }
}
