import gql from 'graphql-tag';

export const UserFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on UserType {
      id
      email
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on UserType {
      id
      firstName
      lastName
      email
      details {
        claimsAuthority
      }
      groups {
        name
      }
      permissions
      lastActivity
      lastLogin
      providerEmployee {
        provider {
          id
          merchant {
            id
            name
          }
        }
      }
      isSuperuser
    }
  `
};
