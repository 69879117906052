import {
  Component,
  OnInit,
  Renderer2,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { tap, debounceTime } from 'rxjs/operators';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';

import { config } from '@app/config/config';
import { ModalDialog } from '@common/interfaces';
import { ModalProvider } from '@common/providers';
import { SearchService } from '@common/services';
import {
  SearchResult,
  ClaimResult,
  ProductVersionResult,
  MerchantResult,
  PolicyResult,
  Claim,
  Policy,
  ProductVersion,
  Merchant
} from '@common/models';

@Component({
  selector: 'ps-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('overlay', [
      state('in', style({ transform: 'scale3d(1, 1, 1)', opacity: 1 })),
      state('void', style({ transform: 'scale3d(1.05, 1.05, 1.05)', opacity: 0 })),
      transition('void => *', [animate(config.searchTransitionDuration)]),
      transition('* => void', [animate(config.searchTransitionDuration)])
    ])
  ]
})
export class SearchComponent implements AfterViewInit, OnInit, ModalDialog {
  show = true;
  loading = false;
  search: FormControl;
  results: SearchResult[] = [];
  icons = {
    close: faTimes,
    search: faSearch
  }

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private modalService: ModalProvider,
    private renderer: Renderer2,
    private searchService: SearchService,
    private router: Router
  ) {}

  initModal() {}

  ngOnInit() {
    // Disable scrolling of page beneath
    this.renderer.addClass(document.body, 'modal-open');

    // Init search control
    this.search = new FormControl();

    this.search.valueChanges
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(500)
      )
      .subscribe(this.handleQuery.bind(this));
  }

  ngAfterViewInit() {
    (<HTMLFormElement>this.searchInput.nativeElement).focus();
  }

  @HostListener('document:keydown.escape')
  escapeKeyHandler() {
    this.close();
  }

  private staggerResults(results: SearchResult[]): void {
    if (results.length === 0 || this.loading) {
      return;
    }

    const result = results.shift();
    this.results.push(result);

    if (results.length > 0 && !this.loading) {
      setTimeout(() => {
        this.staggerResults(results);
      }, 150);
    }
  }

  private openClaim(claim: Claim): void {
    this.router
      .navigate([
        '/dashboard/policies',
        claim.policy.id,
        'beneficiaries',
        claim.beneficiary.id,
        'claims',
        claim.id
      ])
      .then(() => this.close());
  }

  private openPolicy(policy: Policy): void {
    this.router.navigate(['/dashboard/policies', policy.id]).then(() => this.close());
  }

  private openProductVersion(productVersion: ProductVersion): void {
    this.router
      .navigate(['/dashboard/products', productVersion.product.id, 'versions', productVersion.id])
      .then(() => this.close());
  }

  private openMerchant(merchant: Merchant): void {
    this.router.navigate(['dashboard/providers', merchant.id]).then(() => this.close());
  }

  handleQuery(query: string): void {
    if (query.length === 0) {
      this.loading = false;
      this.results = [];
      return;
    }

    this.searchService
      .search(query)
      .pipe(tap(() => (this.loading = false)))
      .subscribe(results => {
        this.results = [];
        this.staggerResults(results);
      });
  }

  close(): void {
    this.show = false;

    setTimeout(() => {
      this.renderer.removeClass(document.body, 'modal-open');
      this.modalService.pop();
    }, config.searchTransitionDuration);
  }

  /**
   * Navigates to the underlying object page, resolving any
   * related objects beforehand.
   */
  open(result: SearchResult): void {
    // If this is already loading then don't do anything
    if (result.loading || result.disabled) {
      return;
    }

    // Mark all results as disabled and not loading
    this.results.forEach(r => {
      r.disabled = true;
      r.loading = false;
    });

    // Mark this result as loading and not disabled
    result.loading = true;
    result.disabled = false;

    if (result instanceof ClaimResult) {
      this.openClaim(result.claim);
    } else if (result instanceof ProductVersionResult) {
      this.openProductVersion(result.productVersion);
    } else if (result instanceof MerchantResult) {
      this.openMerchant(result.merchant);
    } else if (result instanceof PolicyResult) {
      this.openPolicy(result.policy);
    }
  }
}
