import { Component, OnInit, ViewChild, Inject, ComponentFactoryResolver } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { NotificationsService } from 'angular2-notifications';
import { forkJoin, Observable, empty } from 'rxjs';
import { faRedo, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons';

import { ModalComponent } from '@common/components';
import { ModalDialog } from '@common/interfaces';
import { checkVal } from '@common/utils';
import { PaymentRulesOverrideService, RulesSetService, CardService } from '@common/services';
import { PaymentRuleOverrideType, PaymentRuleType } from '@common/enums';
import { PaymentRulesOverride, RulesSet, Rule, Card, RuleCurrentState } from '@common/models';
import { ModalProvider } from '@common/providers';
import { AlertComponentInput, AlertComponent } from '@common/components';
import { mergeMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-payment-controls-modal',
  templateUrl: './payment-controls-modal.component.html',
  styleUrls: ['./payment-controls-modal.component.scss']
})
export class PaymentControlsModalComponent implements ModalDialog, OnInit {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  loading = true;
  cardProxyId: string;
  policyId: string;
  rulesGlobal: Rule[] = [];
  rulesOverrides: Rule[] = [];
  rulesStates: RuleCurrentState[] = [];

  paymentVelocity: Rule;
  paymentVelocityFormOpen = false;
  paymentVelocityForm: FormGroup;

  paymentFrequency: Rule;
  paymentFrequencyFormOpen = false;
  paymentFrequencyForm: FormGroup;

  ruleTypes = PaymentRuleType;

  numberRegex = new RegExp('^[0-9]+$');

  icons = {
    repeat: faRedo,
    edit: faEdit,
    check: faCheck
  };

  /**
   * Finds and returns a rule with a given rule type.
   * @param rules array of rules to search through
   * @param ruleType rule type enum to search for, e.g. payment-velocity-control
   */
  static getRule(rules: Rule[], ruleType: PaymentRuleType): Rule {
    const rule = rules.find((item: Rule) => item.ruleType === ruleType);
    if (rule) {
      // A workaround to avoid object reference issues
      return JSON.parse(JSON.stringify(rule));
    }
    return null;
  }

  constructor(
    private formBuilder: FormBuilder,
    private modalProvider: ModalProvider,
    private paymentRulesOverrideService: PaymentRulesOverrideService,
    private rulesSetService: RulesSetService,
    private notificationsService: NotificationsService,
    private cardService: CardService,
    @Inject(ComponentFactoryResolver) private cfr: ComponentFactoryResolver
  ) {
    this.modalProvider.updateContext(this.cfr);
  }

  ngOnInit() {
    this.fetchRules(this.cardProxyId);
  }

  initModal({ cardProxyId, policyId }) {
    this.cardProxyId = checkVal(cardProxyId, 'PaymentControlsModalComponent expected cardProxyId');
    this.policyId = checkVal(policyId, 'PaymentControlsModalComponent expected policyId');
  }

  /**
   * Inits and transforms data so that it can be used in forms
   * @param rulesOverrides an array of all overrides for all rules
   */
  initFormsData(rulesOverrides: PaymentRulesOverride[]): void {
    if (rulesOverrides.length > 0) {
      this.rulesOverrides = rulesOverrides.map((item: PaymentRulesOverride) =>
        this.transformRule(item)
      );
    }
    this.chooseRules();
  }

  /**
   * Chooses global or overriden parameters for each rule (payment-velocity-control, payment-frequency-control)
   */
  chooseRules() {
    this.paymentVelocity = this.chooseRule(PaymentRuleType.PaymentVelocityControl);
    this.paymentFrequency = this.chooseRule(PaymentRuleType.PaymentFrequencyControl);
  }

  /**
   * Fetches payment rules overrides alongside global rules (the last rule set in rulesSets endpoint)
   * and the current states of all rules (whether they have been triggered or not)
   * @param cardProxyId
   */
  fetchRules(cardProxyId: string) {
    forkJoin([
      this.paymentRulesOverrideService.getAll({
        filters: {
          cardProxyId,
          overrideType: PaymentRuleOverrideType.CardOverrides,
          isEnabled: true
        }
      }),
      this.rulesSetService.getAll({
        filters: { productVersion: { policies: { id: this.policyId } } }
      }),
      this.fetchRulesStates()
    ]).subscribe(([rulesOverrides, rulesSets]: [PaymentRulesOverride[], RulesSet[]]) => {
      if (rulesSets.length > 0) {
        // Get the last rule set
        const rulesSet: RulesSet = rulesSets[0];
        this.rulesGlobal = rulesSet.rules;
        this.initFormsData(rulesOverrides);
      }
      this.loading = false;
    });
  }

  /**
   * Transforms a rule override to an object with the same schema as
   * global rule's schema defined in rules sets (rulesSets endpoint)
   * @param rulesOverride
   */
  transformRule(override: PaymentRulesOverride) {
    return new Rule().deserialize({
      id: override.id,
      description: '',
      ruleType: override.override.specification.ruleName,
      specification: override.override.specification
    });
  }

  /**
   * For a given rule type, it returns an overriden rule if it exists and is attached to the
   * card (if its in `rulesCurrentStates`). If it is attached to the card and has not been overriden yet,
   * it returns a global rule or undefined.
   * @param ruleType rule type enum, e.g. payment-velocity-control
   */
  chooseRule(ruleType: PaymentRuleType): Rule {
    return (
      this.getRuleState(ruleType) &&
      (PaymentControlsModalComponent.getRule(this.rulesOverrides, ruleType) ||
        PaymentControlsModalComponent.getRule(this.rulesGlobal, ruleType))
    );
  }

  private createPaymentVelocityForm() {
    return this.formBuilder.group({
      amount: [
        this.paymentVelocity.specification.amount,
        [Validators.required, Validators.pattern(this.numberRegex)]
      ],
      timeWindowMinutes: [
        this.paymentVelocity.specification.timeWindowMinutes,
        [Validators.required, Validators.pattern(this.numberRegex)]
      ]
    });
  }

  openPaymentVelocityForm() {
    this.paymentVelocityFormOpen = true;
    this.paymentVelocityForm = this.createPaymentVelocityForm();
  }

  private createPaymentFrequencyForm() {
    return this.formBuilder.group({
      count: [
        this.paymentFrequency.specification.count,
        [Validators.required, Validators.pattern(this.numberRegex)]
      ],
      timeWindowMinutes: [
        this.paymentFrequency.specification.timeWindowMinutes,
        [Validators.required, Validators.pattern(this.numberRegex)]
      ]
    });
  }

  openPaymentFrequencyForm() {
    this.paymentFrequencyFormOpen = true;
    this.paymentFrequencyForm = this.createPaymentFrequencyForm();
  }

  /**
   * Fetches current rules states and assigns them to "this.rulesStates".
   * We need to do this after each user action as the state can change
   * after the user changes some payment control parameter.
   */
  fetchRulesStates(): any {
    return this.cardService
      .getAll({
        filters: {
          proxyId: this.cardProxyId,
          policyId: this.policyId
        }
      })
      .pipe(
        map((cards: Card[]) => {
          this.rulesStates = cards[0].rulesCurrentStates;
          return empty();
        })
      );
  }

  createOverride(override: Rule): Observable<any> {
    // Create New Card Override mutation body object
    let key;
    if (override.ruleType === PaymentRuleType.PaymentVelocityControl) {
      key = 'paymentVelocityControl';
    } else {
      key = 'paymentFrequencyControl';
    }
    const body = {
      [key]: {
        ...override.specification
      }
    };
    delete body[key].ruleName;
    delete body[key]['mapping'];

    return this.paymentRulesOverrideService.createCardOverride(this.cardProxyId, body).pipe(
      mergeMap((data: PaymentRulesOverride) =>
        this.fetchRulesStates().pipe(
          map(() => {
            const rule = this.transformRule(data);
            this.deleteRuleOverride(rule.ruleType);
            this.rulesOverrides.push(rule);
            this.chooseRules();
            return true;
          })
        )
      )
    );
  }

  /**
   * Checks if an enabled rules override entity with override type "CARD_OVERRIDE" exists and decides
   * whether we need to disable the old one and then create a new entity, or just create a new one
   * @param override An override object containing specification and rule type
   */
  makeCreateRequest(override: Rule): Observable<any> {
    return this.paymentRulesOverrideService
      .getAll({
        filters: {
          cardProxyId: this.cardProxyId,
          overrideType: PaymentRuleOverrideType.CardOverrides,
          isEnabled: true,
          specificationQuery: `has_key=${override.ruleType}`
        }
      })
      .pipe(
        mergeMap((data: PaymentRulesOverride[]) => {
          const oldOverride = data[0];

          // No existing enabled overrides, just create a new one
          if (!oldOverride) {
            return this.createOverride(override);
          }
          // Enabled override entity exists, disable it and then create a new one
          else {
            return (
              this.paymentRulesOverrideService
                // Disable the old rule
                .updateRulesOverride(oldOverride.id, { isActive: false })
                .pipe(
                  mergeMap(() => {
                    // Create a new one
                    return this.createOverride(override);
                  })
                )
            );
          }
        })
      );
  }

  submitPaymentVelocityForm(): void {
    this.makeCreateRequest(this.paymentVelocity).subscribe(() => {
      this.paymentVelocityFormOpen = false;
      this.notificationsService.success(
        'Payment Velocity Updated',
        'Payment velocity successfully changed.'
      );
    });
  }

  submitPaymentFrequencyForm(): void {
    this.makeCreateRequest(this.paymentFrequency).subscribe(() => {
      this.paymentFrequencyFormOpen = false;
      this.notificationsService.success(
        'Payment Frequency Updated',
        'Payment frequency successfully changed.'
      );
    });
  }

  deleteRuleOverride(ruleType: PaymentRuleType | string) {
    const index = this.rulesOverrides.findIndex((item: Rule) => item.ruleType === ruleType);
    if (index !== -1) {
      this.rulesOverrides.splice(index, 1);
    }
  }

  resetPaymentVelocity() {
    this.modalProvider.open(AlertComponent, <AlertComponentInput>{
      title: 'Reset Payment Velocity',
      text: 'Are you sure you want to reset payment velocity control to its default values?',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            return this.paymentRulesOverrideService
              .deleteCardOverride(this.paymentVelocity.id)
              .pipe(mergeMap(() => this.fetchRulesStates()))
              .subscribe(() => {
                this.deleteRuleOverride(PaymentRuleType.PaymentVelocityControl);
                this.chooseRules();
                this.notificationsService.success(
                  'Payment Velocity Reset',
                  'Payment velocity successfully reset.'
                );
                return true; // Close modal
              });
          }
        }
      ]
    });
  }

  resetPaymentFrequency() {
    this.modalProvider.open(AlertComponent, <AlertComponentInput>{
      title: 'Reset Payment Frequency',
      text: 'Are you sure you want to reset payment frequency control to its default values?',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            return this.paymentRulesOverrideService
              .deleteCardOverride(this.paymentFrequency.id)
              .pipe(mergeMap(() => this.fetchRulesStates()))
              .subscribe(() => {
                this.deleteRuleOverride(PaymentRuleType.PaymentFrequencyControl);
                this.chooseRules();
                this.notificationsService.success(
                  'Payment Frequency Reset',
                  'Payment frequency successfully reset.'
                );
                return true; // Close modal
              });
          }
        }
      ]
    });
  }

  /**
   * Checks whether the rule's parameters can be reset to its global
   * (default) values, i.e. when an override exists
   * @param ruleType rule type enum, e.g. payment-velocity-control
   */
  canResetRule(ruleType: PaymentRuleType): boolean {
    return this.rulesOverrides.some((item: Rule) => item.ruleType === ruleType);
  }

  /**
   * Checks whether the rule is attached to the card (if it exists in the `rulesCurrentStates` array).
   * Returns rule's current state object or undefined.
   */
  getRuleState(ruleType: PaymentRuleType): RuleCurrentState {
    return this.rulesStates.find((item: RuleCurrentState) => item.ruleType === ruleType);
  }

  isTriggered(ruleType: PaymentRuleType): boolean {
    return this.getRuleState(ruleType).triggered;
  }
}
