import gql from "graphql-tag";
import { IcdCodeFragments } from './icd-code.fragments';

export const ReceiptItemFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on ReceiptItemType {
      id
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on ReceiptItemType {
      id
      title
      amount
      isAccepted
      icd {
        ...icdCodeFatFields
      }
    }
    ${IcdCodeFragments.fat('icdCodeFatFields')}
  `
};
