import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { GQLService } from '../common';
import { FundingAccountFragments } from '@common/fragments';
import { FundingAccount } from '@common/models/funding-account.model';
import { Currency } from '@common/models';

@Injectable({ providedIn: 'root' })
export class FundingAccountService extends GQLService<FundingAccount> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: FundingAccount,
      singular: 'fundingAccount',
      plural: 'fundingAccounts',
      fragments: FundingAccountFragments
    });
  }

  getCurrencies(): Observable<Currency[]> {
    return this.apollo
      .watchQuery({
        query: gql`
          {
            fundingAccounts {
              results {
                currency {
                  name
                  code
                }
              }
            }
          }
        `
      })
      .valueChanges.pipe(
        take(1),
        map((result: any) => {
          return result.data.fundingAccounts.results.map((item: any) =>
            new Currency().deserialize(item.currency)
          );
        })
      );
  }
}
