import { Model } from './model';
import { toInt } from '@common/utils/model-transforms';

class BenefitCategory extends Model {
  title: string;
  description: string;
  icon?: string;

  constructor() {
    super(['title', 'description', 'icon']);
  }
}

export class Benefit extends Model {
  id: number;
  parentId: string;
  parent: { id: string };
  children?: Benefit[];
  category: BenefitCategory;
  title: string;
  description: string;
  excess: number;
  limitCount: number;
  limitAmount: number;
  beneficiaryLimitCount: number;
  beneficiaryLimitAmount: number;
  renewalAtYearsEnd: boolean;
  coPayment: number;
  coPaymentMax: number;
  minimalDurationDays?: number;

  constructor() {
    super([
      { key: 'id', transform: toInt },
      'parentId',
      'parent',
      { key: 'children', useModel: Benefit },
      { key: 'category', useModel: BenefitCategory },
      'title',
      'description',
      'excess',
      'limitCount',
      'limitAmount',
      'beneficiaryLimitCount',
      'beneficiaryLimitAmount',
      'renewalAtYearsEnd',
      'coPayment',
      'coPaymentMax',
      'minimalDurationDays'
    ]);
  }

  childrenCount(): number {
    if (typeof this.children === 'undefined') {
      return 0;
    } else {
      return this.children.length;
    }
  }
}
