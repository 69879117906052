import gql from "graphql-tag";

export const MerchantCategoryFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on MerchantCategoryType {
      id
      name
      code
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on MerchantCategoryType {
      id
      name
      code
    }
  `
};
