import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Beneficiary } from '../../models';
import { GQLService } from '../common';
import { BeneficiaryFragments } from '@common/fragments';
import { objToGql } from '@common/utils';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService extends GQLService<Beneficiary> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: Beneficiary,
      singular: 'beneficiary',
      plural: 'beneficiaries',
      fragments: BeneficiaryFragments
    });
  }

  unlockData(body: any): Observable<any> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          beneficiaryDataUnlockCreate(newExpiringToken: {
            ${objToGql(body)}
          }) {
            ok
          }
        }
      `
      })
      .pipe(
        map((res: any) => {
          const data = res.data.beneficiaryDataUnlockCreate;
          if (data.ok) {
            return true;
          } else {
            throw new Error(`Couldn't unlock beneficiary data.`);
          }
        })
      );
  }
}
