import { PaymentRule } from '../../enums';

export interface RulesDict {
  [key: string]: {
    internalName: PaymentRule;
    displayName: string;
    description: {
      general: string;
      success: string;
      failed: string;
    };
  };
}

export const rulesDict = {
  [PaymentRule.HasInsuranceRule]: {
    internalName: PaymentRule.HasInsuranceRule,
    displayName: 'Active Card',
    description: {
      general: 'Checks whether an active policy is present',
      success: 'The customer has a valid and current insurance policy',
      failed: 'This card is not active'
    }
  },
  [PaymentRule.SufficientFunds]: {
    internalName: PaymentRule.SufficientFunds,
    displayName: 'Sufficient Insurer Funds',
    description: {
      general: 'There are sufficient funds in your account',
      success: 'There were sufficient funds to cover this transaction',
      failed:
        'There were not enough insurer funds to cover this transaction. Please top-up your account'
    }
  },
  [PaymentRule.PendingClaimRule]: {
    internalName: PaymentRule.PendingClaimRule,
    displayName: 'Pending Claims',
    description: {
      general:
        'If applicable, any new payments are blocked if there is any other claim pending acceptance (does not apply to pre-auths).',
      success: 'No pending claims prevented this transaction from being approved',
      failed: 'This transaction was not approved due to an open claim'
    }
  },
  [PaymentRule.BenefitAmountRule]: {
    internalName: PaymentRule.BenefitAmountRule,
    displayName: 'Benefit Limit',
    description: {
      general: 'Checks whether sufficient benefit is available',
      success: 'Sufficient benefit was available',
      failed: 'Insufficient benefit available for this category of transaction'
    }
  },
  [PaymentRule.BenefitCountRule]: {
    internalName: PaymentRule.BenefitCountRule,
    displayName: 'Benefit Count',
    description: {
      general: "Checks whether the payment will fit into corresponding benefit's count limit",
      success: 'The benefit has a sufficient number of allowed transactions remaining',
      failed: 'The benefit transaction count limit has been reached'
    }
  },
  [PaymentRule.CardDynamicRules]: {
    internalName: PaymentRule.CardDynamicRules,
    displayName: 'Dynamic Card Rules',
    description: {
      general: 'Uses tailored rules defined in the application',
      success: 'All dynamic factors passed',
      failed: 'One or more dynamic factors did not pass validation'
    }
  },
  [PaymentRule.TransactionTypeRule]: {
    internalName: PaymentRule.TransactionTypeRule,
    displayName: 'Allowed Transaction Type',
    description: {
      general: 'Blocks unusual transaction types (e.g. ATM withdrawals)',
      success: 'This transaction type is permitted',
      failed: 'This transaction type is not permitted'
    }
  },
  [PaymentRule.CardIsReadyRule]: {
    internalName: PaymentRule.CardIsReadyRule,
    displayName: 'Card Ready',
    description: {
      general: 'Blocks payments for blocked or frozen cards',
      success: "The beneficiary's payment card was in a ready state",
      failed: "The beneficiary's payment card is blocked or frozen"
    }
  },
  [PaymentRule.MandatoryNotNullFieldsRule]: {
    internalName: PaymentRule.MandatoryNotNullFieldsRule,
    displayName: 'Mandatory Fields',
    description: {
      general: 'Blocks payments with incomplete transaction data provided by payment network',
      success: 'All mandatory fields were received by the payment platform',
      failed:
        'Insufficient transaction data received. Transaction declined for security reasons. Please contact the customer/merchant'
    }
  },
  [PaymentRule.ClaimPreauthRule]: {
    internalName: PaymentRule.ClaimPreauthRule,
    displayName: 'Pre-authorization',
    description: {
      general: 'Pre-authorization controls',
      success: 'A pre-authorization was required and a suitable one was matched',
      failed: 'No matching pre-authorization was found for this transaction'
    }
  },
  [PaymentRule.CountryRule]: {
    internalName: PaymentRule.CountryRule,
    displayName: 'Country',
    description: {
      general: 'Checks if the country is permitted',
      success: 'The transaction country is permitted',
      failed: 'This transaction took place in an unauthorized country'
    }
  },
  [PaymentRule.CurrencyRule]: {
    internalName: PaymentRule.CurrencyRule,
    displayName: 'Currency',
    description: {
      general: 'Checks whether the currency of the transaction is permitted',
      success: 'The currency of the transaction was as expected',
      failed: 'The transaction took place in an unauthorized currency'
    }
  },
  [PaymentRule.MerchantCodeRule]: {
    internalName: PaymentRule.MerchantCodeRule,
    displayName: 'Merchant Category Code',
    description: {
      general: 'Checks whether the category code of the merchant matches permitted categories',
      success: 'The merchant category code of the transaction was permitted',
      failed: 'The transaction took place at an unauthorized merchant category'
    }
  },
  [PaymentRule.MerchantNameRule]: {
    internalName: PaymentRule.MerchantNameRule,
    displayName: 'Merchant Name (similarity)',
    description: {
      general: "Checks whether the merchant's name is as authorized",
      success: 'The merchant name matches',
      failed: 'The merchant name did not match'
    }
  },
  [PaymentRule.LocationRule]: {
    internalName: PaymentRule.LocationRule,
    displayName: "Beneficiary's location",
    description: {
      general: "Checks last known location of the beneficiary's device",
      success: "The beneficiary's device was within the same area as the transaction",
      failed: "The transaction took place in a location far from the beneficiary's device"
    }
  },
  [PaymentRule.TimestampRule]: {
    internalName: PaymentRule.TimestampRule,
    displayName: 'Timestamp',
    description: {
      general: "Checks if the transaction's time fits into the authorized time window",
      success: 'The transaction took place within the permitted time window',
      failed: 'The timestamp of the transaction was outside of the permitted time window'
    }
  },
  [PaymentRule.MerchantIdRule]: {
    internalName: PaymentRule.MerchantIdRule,
    displayName: 'Merchant ID',
    description: {
      general: 'Checks if the Merchant ID is correct/permitted',
      success: 'Merchant ID matched',
      failed: "The Merchant ID didn't match"
    }
  },
  [PaymentRule.UnsupportedMCCRule]: {
    internalName: PaymentRule.UnsupportedMCCRule,
    displayName: 'MCC Restrictions',
    description: {
      general: 'Checks if the transaction is for a restricted merchant category',
      success: 'Merchant category is not restricted',
      failed: 'Merchant category is not permitted'
    }
  },
  [PaymentRule.InsufficientFundsRule]: {
    internalName: PaymentRule.InsufficientFundsRule,
    displayName: 'Value of Pre-authorization',
    description: {
      general: 'Checks if the transaction amount exceeds the pre-authorized value',
      success: 'The transaction amount is within the pre-authorized limit',
      failed: 'The transaction amount exceeds the pre-authorized limit'
    }
  },
  [PaymentRule.CashbackRule]: {
    internalName: PaymentRule.CashbackRule,
    displayName: 'Cashback',
    description: {
      general: 'Checks whether the transaction contained an extra amount for a cashback',
      success: 'The cashback is either not prohibited or not present in the transaction',
      failed: 'The transaction includes prohibited cashback'
    }
  },
  [PaymentRule.GratuityFeeRule]: {
    internalName: PaymentRule.GratuityFeeRule,
    displayName: 'Gratuity Fee',
    description: {
      general: 'Checks whether the transaction contained an extra amount as a gratuity fee',
      success: 'The gratuity fee is either not prohibited or not present in the transaction',
      failed: 'The transaction includes prohibited gratuity fee'
    }
  },
  [PaymentRule.CardHolderPresenceRule]: {
    internalName: PaymentRule.CardHolderPresenceRule,
    displayName: 'Card Holder Presence',
    description: {
      general: 'Checks whether the card holder was present when the transaction took place',
      success: 'Cardholder present',
      failed: 'We were unable to confirm that the cardholder was present'
    }
  },
  [PaymentRule.CardPresenceRule]: {
    internalName: PaymentRule.CardPresenceRule,
    displayName: 'Card Presence',
    description: {
      general: 'Checks whether the card was actually present when the transaction took place',
      success: 'The card was present for the transaction',
      failed: 'We were unable to confirm that the card was present'
    }
  },
  [PaymentRule.CardDataInputRule]: {
    internalName: PaymentRule.CardDataInputRule,
    displayName: 'Card Data Input',
    description: {
      general:
        'Checks whether the way card data was input into the payments network is considered safe',
      success: 'Card data was entered securely',
      failed: 'Card data was not entered securely'
    }
  },
  [PaymentRule.ChipFallbackRule]: {
    internalName: PaymentRule.ChipFallbackRule,
    displayName: 'Chip Fallback',
    description: {
      general:
        'Chip Fallback occurs when a chip card is used a swipe card (i.e. with magnetic stripe)',
      success: 'This transaction was initiated via a magnetic stripe',
      failed: 'Card data was not read securely'
    }
  },
  [PaymentRule.FraudIndicatorMerchantRule]: {
    internalName: PaymentRule.FraudIndicatorMerchantRule,
    displayName: 'Fraud Indicator',
    description: {
      general: `Additional indicator about the cardholder's trustworthiness provided by the merchant`,
      success: 'Merchant checks passed',
      failed: 'Merchant flagged the transaction as potentially suspect'
    }
  },
  [PaymentRule.SecurityProtocolRule]: {
    internalName: PaymentRule.SecurityProtocolRule,
    displayName: 'Security Protocol',
    description: {
      general:
        'Security protocol (usually HTTPS) used when sending the data into the payment network',
      success: 'Data was transferred securely',
      failed: 'An insecure method was used for data transfer'
    }
  },
  [PaymentRule.SecurityMethod3dSecureRule]: {
    internalName: PaymentRule.SecurityMethod3dSecureRule,
    displayName: 'Security Method 3D Secure',
    description: {
      general: 'Security method used (if applicable) when performing 3D secure transaction',
      success: '3DS verified',
      failed: '3DS could not be verified'
    }
  },
  [PaymentRule.AuthenticationMethodRule]: {
    internalName: PaymentRule.AuthenticationMethodRule,
    displayName: 'Authentication Method',
    description: {
      general: `Authentication method used to verify cardholder's identity`,
      success: 'Authentication checks were performed',
      failed: 'Authentication checks could not be verified'
    }
  },
  [PaymentRule.AuthenticationEntityRule]: {
    internalName: PaymentRule.AuthenticationEntityRule,
    displayName: 'Authentication Entity',
    description: {
      general: `Authentication entity used to verify cardholder's identity. It's always check along with the auth method`,
      success: 'Authentication checks were performed securely',
      failed: 'Authentication checks were not performed securely'
    }
  }
};
