export enum ClaimHistoryDataAction {
  Accept = 'ACCEPT',
  PartiallyAccept = 'PARTIALLY_ACCEPT',
  Decline = 'DECLINE',
  Cancel = 'CANCEL',
  Reset = 'RESET',
  Authorize = 'AUTHORIZE',
  ReferAuthorization = 'REFER_AUTHORIZATION',
  RequestNew = 'REQUEST_NEW',
  PendingAcceptance = 'MARK_AS_PENDING_ACCEPTANCE',
  MarkAsPaid = 'MARK_AS_PAID'
}
