import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
  AbstractControl
} from '@angular/forms';

import { ModalComponent } from '@common/components';
import { ModalDialog } from '@common/interfaces';
import { checkVal } from '@common/utils';

@Component({
  selector: 'ps-card-action-modal',
  templateUrl: './card-action-modal.component.html',
  styleUrls: ['./card-action-modal.component.scss']
})
export class CardActionModalComponent implements ModalDialog, OnInit {
  form: FormGroup;
  title: string;

  @ViewChild('modal', { static: true }) modal: ModalComponent;
  @ViewChild('textarea', { static: true }) textarea: ElementRef;

  private submitted = false;

  constructor(private formBuilder: FormBuilder) {}

  initModal(data) {
    this.title = checkVal(data['title'], 'Expected title');
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      message: ['']
    });

    this.textarea.nativeElement.focus();
  }

  hasError(controlName: string): boolean {
    return this.form.get(controlName).invalid && this.submitted;
  }

  submitForm() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const message = this.form.get('message').value;

    this.modal.close({
      success: true,
      message: message === '' ? null : message
    });
  }
}
