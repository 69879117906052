<ps-modal heading="Add a note" #modal>
  <form [formGroup]="form" (ngSubmit)="form.valid && submitForm()" #formEl="ngForm" novalidate>
    <div class="modal-body">
      <div class="form-text">
        <p i18n="@@addClaimNoteHeading">
          {{
            description
              ? description
              : "This note will be added to the claim's history and visible by other dashboard users."
          }}
        </p>
      </div>
      <div
        class="form-group"
        [ngClass]="{
          'has-error': form.get('text').invalid && formEl.submitted
        }"
      >
        <textarea rows="5" formControlName="text" class="form-control" #textarea></textarea>
        <p
          class="invalid-feedback"
          *ngIf="form.get('text').invalid && formEl.submitted"
          i18n="@@youMustWriteSomethingWarning"
        >
          You must write something
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <ps-button (onClick)="modal.close()" btnClass="btn btn-secondary" i18n="@@cancelBtn">
        Cancel
      </ps-button>
      <ps-button type="submit" btnClass="btn btn-primary addNoteBtn" i18n="@@submitBtn"
        >Submit</ps-button
      >
    </div>
  </form>
</ps-modal>
