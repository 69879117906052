import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponentModule } from '../button';
import { ClaimNoteComponent } from './claim-note.component';
import { LinkComponentModule } from '../link';
import { LocalDatePipeModule, BreakLinesPipeModule } from '@common/pipes';
import { ModalComponentModule } from '../modal';

@NgModule({
  imports: [
    CommonModule,
    ButtonComponentModule,
    LinkComponentModule,
    LocalDatePipeModule,
    BreakLinesPipeModule,
    ModalComponentModule
  ],
  entryComponents: [ClaimNoteComponent],
  declarations: [ClaimNoteComponent],
  exports: [ClaimNoteComponent]
})
export class ClaimNoteComponentModule {}
