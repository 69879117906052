import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocalDatePipeModule } from '../../pipes';
import { PaymentsTableComponent } from './payments-table.component';
import { LinkComponentModule } from '../link';
import { ButtonComponentModule } from '../button';
import { PaymentReversalsComponent } from './payment-reversals/payment-reversals.component';
import { PaymentBreakdownComponent } from './payment-breakdown/payment-breakdown.component';
import { PaymentSettlementEventsComponent } from './payment-settlement-events/payment-settlement-events.component';
import { InsurerPaymentsComponent } from './insurer-payments/insurer-payments.component';
import { BeneficiaryPaymentsComponent } from './beneficiary-payments/beneficiary-payments.component';
import { PaymentStateLabelComponent } from './payment-state-label/payment-state-label.component';
import { DecisionResultComponent } from './decision-result/decision-result.component';
import { AuthDecisionProcessComponent } from './auth-decision-process/auth-decision-process.component';
import { PosDecisionResultComponent } from './auth-decision-process/pos-decision-result/pos-decision-result.component';
import { ModalComponentModule } from '../modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    LocalDatePipeModule,
    ButtonComponentModule,
    LinkComponentModule,
    ModalComponentModule,
    FontAwesomeModule
  ],
  declarations: [
    PaymentsTableComponent,
    PaymentReversalsComponent,
    PaymentBreakdownComponent,
    PaymentSettlementEventsComponent,
    InsurerPaymentsComponent,
    BeneficiaryPaymentsComponent,
    PaymentStateLabelComponent,
    DecisionResultComponent,
    AuthDecisionProcessComponent,
    PosDecisionResultComponent
  ],
  exports: [PaymentsTableComponent]
})
export class PaymentsTableComponentModule {}
