import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import gql from 'graphql-tag';

import { PolicyFragments, BenefitUsageFragments } from '@common/fragments';
import { GQLService } from '../common';
import { Policy, BenefitUsage } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class PolicyService extends GQLService<Policy> {
  beneficiaryPageCount = new BehaviorSubject(0);

  constructor(apollo: Apollo) {
    super({
      apollo,
      model: Policy,
      singular: 'policy',
      plural: 'policies',
      fragments: PolicyFragments
    });
  }

  getBenefitUsages(policyId: number): Observable<BenefitUsage[]> {
    return this.apollo
      .watchQuery({
        query: gql`
          {
            benefitUsages(policy: {id: "${policyId}"}) {
              results {
                ...benefitUsageFields
              }
            }
          }
          ${BenefitUsageFragments.slim('benefitUsageFields')}
        `
      })
      .valueChanges.pipe(
        take(1),
        map((res: any) => {
          const results = res.data.benefitUsages.results;
          if (results.length > 0) {
            return results.map((item: BenefitUsage) => new BenefitUsage().deserialize(item));
          } else {
            throw new Error(`Could not get benefit usages for policy #${policyId}`);
          }
        })
      );
  }
}
