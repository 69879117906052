import { Component, OnInit, Input } from '@angular/core';

import { Merchant } from '@common/models';
import { checkVal } from '@common/utils';

import { environment } from '@environment';
import { faExclamationTriangle, faCheck, faBan } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ps-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit {
  @Input() merchant: Merchant;
  @Input() showVerified = true;
  @Input() showUnlocked = true;
  @Input() isBeneficiaryUnlocked: boolean;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  icons = {
    warning: faExclamationTriangle,
    ok: faCheck,
    locked: faBan
  };

  constructor() {}

  ngOnInit() {
    checkVal(this.merchant, 'ProviderComponent expected merchant');
  }

  getMap(width: number, height: number, zoom: number): string {
    return this.merchant.getMap(width, height, zoom, environment.googleMapsApiKey);
  }

  get isSmall(): boolean {
    return this.size === 'small';
  }

  get isMedium(): boolean {
    return this.size === 'medium';
  }

  get isLarge(): boolean {
    return this.size === 'large';
  }
}
