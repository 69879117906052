import { Model } from './model';
import { Breadcrumb, Breadcrumbizable } from '../interfaces';
import { ProductVersion } from './product-version.model';
import { PolicyChain } from './policy-chain.model';
import { BenefitUsage } from './benefit-usage.model';
import { toInt } from '@common/utils/model-transforms';
import { Product } from './product.model';

export class Policy extends Model implements Breadcrumbizable {
  id: number;
  externalId: string;
  chainId: number;
  beneficiaryCount: number;
  expiration: string;
  isActive: boolean;
  usages?: BenefitUsage[];
  productVersion?: ProductVersion;
  chain?: PolicyChain;
  product?: Product;

  constructor() {
    super([
      { key: 'id', transform: toInt },
      'externalId',
      'chainId',
      'beneficiaryCount',
      'expiration',
      'isActive',
      { key: 'usages', useModel: BenefitUsage },
      { key: 'productVersion', useModel: ProductVersion },
      { key: 'chain', useModel: PolicyChain },
      { key: 'product', useModel: Product }
    ]);
  }

  get fullProductName(): string {
    return `${this.productVersion.product.name} (${this.productVersion.version})`;
  }

  get type(): string {
    return this.chain.corporate ? 'Corporate' : 'Individual';
  }

  breadcrumbize(): Breadcrumb {
    return {
      title: 'Policy',
      routerLink: `/dashboard/policies/${this.id}`,
      items: [
        {
          name: 'Policy Number',
          value: this.externalId
        },
        {
          name: 'Product',
          value: this.fullProductName
        },
        {
          name: 'Beneficiaries',
          value: String(this.beneficiaryCount)
        },
        {
          name: 'Type',
          value: this.type
        },
        {
          name: 'Expires',
          value: this.expiration // @TODO: use local date pipe?
        }
      ]
    };
  }
}
