import gql from 'graphql-tag';

export const NotificationFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on NotificationType {
      id
      action
      data
      entityId
      timestamp
      read
      source
      entityType {
        id
        appLabel
        model
      }
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on NotificationType {
      id
      action
      data
      entityId
      timestamp
      read
      source
      entityType {
        id
        appLabel
        model
      }
    }
  `
};
