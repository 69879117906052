import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { MerchantCategoryService } from './merchant-category.service';
import { ServiceConfig } from '../common';

export const MerchantCategoryServiceConfig = new InjectionToken<ServiceConfig>(
  'MerchantCategoryServiceConfig'
);

@NgModule()
export class MerchantCategoryServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<MerchantCategoryServiceModule> {
    return {
      ngModule: MerchantCategoryServiceModule,
      providers: [
        MerchantCategoryService,
        { provide: MerchantCategoryServiceConfig, useValue: config }
      ]
    };
  }
}
