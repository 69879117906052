import { Model } from './model';
import { User } from './user.model';
import { Platform, DocumentState, DocumentFileType } from '../enums';
import { toDate, toInt } from '@common/utils/model-transforms';
import { Email } from './email.model';

export abstract class Document extends Model {
  id: number;
  claimId: number;
  source: Platform;
  userId: number;
  state?: DocumentState;
  reason?: string;
  uploadedAt: string;
  file: string;
  thumbnail: string;
  filename: string;
  resolvedById?: number;
  resolvedAt?: Date;
  user?: User;
  resolvedBy?: User;
  fileType: DocumentFileType;
  sentEmails: Email[];

  constructor(keyMappings?: any[]) {
    if (typeof keyMappings === 'undefined') {
      keyMappings = [];
    }

    super(
      [
        { key: 'id', transform: toInt },
        'claimId',
        'source',
        'userId',
        'state',
        'reason',
        'uploadedAt',
        'file',
        'thumbnail',
        'filename',
        'resolvedById',
        { key: 'resolvedAt', transform: toDate },
        { key: 'user', useModel: User },
        { key: 'resolvedBy', useModel: User },
        { key: 'sentEmails', useModel: Email },
        'fileType'
      ].concat(keyMappings)
    );
  }

  get filetype(): string {
    const extension = this.filename
      .split('.')
      .pop()
      .toLowerCase();

    const supported = {
      jpg: 'jpg',
      jpeg: 'jpeg',
      png: 'png',
      pdf: 'pdf',
      doc: 'doc'
    };

    if (supported.hasOwnProperty(extension)) {
      return supported[extension];
    } else {
      return 'file';
    }
  }

  get isImage(): boolean {
    return ['jpg', 'png', 'jpeg'].indexOf(this.filetype) !== -1;
  }

  get isPdf(): boolean {
    return this.filetype === 'pdf';
  }

  get isAccepted(): boolean {
    return this.state === DocumentState.Accepted;
  }

  get isPartiallyAccepted(): boolean {
    return this.state === DocumentState.PartiallyAccepted;
  }

  get isDeclined(): boolean {
    return this.state === DocumentState.Declined;
  }

  get requestedNew(): boolean {
    return this.state === DocumentState.RequestedNew;
  }

  get isResolved(): boolean {
    return this.isAccepted || this.isDeclined || this.requestedNew || this.isPartiallyAccepted;
  }

  get isCustomerAgreement(): boolean {
    return this.fileType === DocumentFileType.CustomerAgreement;
  }

  get isGuaranteeOfPayment(): boolean {
    return this.fileType === DocumentFileType.GuaranteeOfPayment;
  }

  static documentFileTypeToString(type: DocumentFileType): string {
    switch (type) {
      case DocumentFileType.GuaranteeOfPayment:
        return 'Guarantee of Payment';
      case DocumentFileType.CustomerAgreement:
        return 'Customer Agreement';
      case DocumentFileType.SignedCustomerAgreement:
        return 'Signed Customer Agreement';
      case DocumentFileType.EstimatedBill:
        return 'Estimated Bill';
      case DocumentFileType.InterimBill:
        return 'Interim Bill';
      case DocumentFileType.FinalBill:
        return 'Receipt';
      case DocumentFileType.Receipt:
        return 'Receipt';
      case DocumentFileType.Other:
        return 'Supporting Document';
      default:
        return 'Unknown';
    }
  }

  get documentFileTypeString(): string {
    return Document.documentFileTypeToString(this.fileType);
  }
}
