import { Component, ViewChild } from '@angular/core';

import { ModalComponent } from '@common/components';
import { checkVal } from '@common/utils';

@Component({
  selector: 'ps-card-iban-modal',
  templateUrl: './card-iban-modal.component.html',
  styleUrls: ['./card-iban-modal.component.scss']
})
export class CardIbanModalComponent {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  iban: string;
  bic: string;

  constructor() {}

  initModal({ iban, bic }) {
    this.iban = checkVal(iban, 'Expected IBAN');
    this.bic = checkVal(bic, 'Expected BIC');
  }
}
