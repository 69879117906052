<ps-modal heading="Card PIN Code" #modal>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-8 offset-md-2 text-center">
        <div class="pin__wrapper">
          <div *ngIf="loading" class="Loader"></div>
          <p *ngIf="!loading" class="pin__code">{{ pin }}</p>
        </div>
        <p class="pin__smallprint">
          Please ensure that the beneficiary does not share their PIN code with anyone else, or
          write it down.
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ps-button (onClick)="modal.close()" btnClass="btn btn-secondary" i18n="@@okBtn">
      OK
    </ps-button>
  </div>
</ps-modal>
