import { Component, Input, OnInit } from '@angular/core';
import {
  BeneficiaryUsagesGQL,
  BeneficiaryUsagesQuery,
  CodesExclusionType,
  PolicyExclusionsGQL
} from '@common/gql';
import { flatToTree } from '@common/utils';
import { take } from 'rxjs/operators';

export interface BenefitUsageInput {
  title: string;
  limit: {
    amount: {
      total: number;
      remaining: number;
    };
    uses: {
      total: number;
      remaining: number;
    };
  };
  coPayment: {
    pct: number;
    max: number;
  };
  excess: {
    benefit: number;
    claim: number;
  };
  exclusions: CodesExclusionType[];
  children?: BenefitUsageInput[];
}

@Component({
  selector: 'ps-benefit-usages[beneficiaryId]',
  template: `
    <div *ngIf="!benefit" class="Loader"></div>
    <ps-benefit-usage
      *ngIf="!!benefit"
      [benefit]="benefit"
      [currencyCode]="currency"
    ></ps-benefit-usage>
  `
})
export class BenefitUsagesComponent implements OnInit {
  @Input() beneficiaryId: number;

  loading = true;
  benefit: BenefitUsageInput;
  currency: string;

  constructor(
    private beneficiaryUsagesGQL: BeneficiaryUsagesGQL,
    private policyExclusionsGQL: PolicyExclusionsGQL
  ) {}

  ngOnInit() {
    // Get benefits
    this.beneficiaryUsagesGQL
      .watch({ beneficiaryId: `${this.beneficiaryId}` })
      .valueChanges.subscribe(({ data: uData }) => {
        let policyId: string;
        [this.benefit, this.currency, policyId] = this.processUsages(uData);

        // Get policy exclusions
        this.policyExclusionsGQL
          .watch({ policyId: parseInt(policyId, 10) })
          .valueChanges.pipe(take(1))
          .subscribe(({ data: pData }) => {
            // Add the exclusions to the root benefit
            // NOTE: If we want to introduce benefit-level exclusions in the
            // future we should change this.
            const exclusions = pData.codesExclusions.results;
            this.benefit.exclusions = exclusions as CodesExclusionType[];
            this.loading = false;
          });
      });
  }

  private processUsages(data: BeneficiaryUsagesQuery): [BenefitUsageInput, string, string] {
    // TODO: This assumes there is a 1-to-1 relationship between beneficiary
    // and policy. If the beneficiary has more than one policy we need to
    // refactor this, but right now there's no way to filter the GQL result by
    // policy ID.
    const policy = data.beneficiary.policies[0];

    // Map out the benefits, ensuring current amounts are on the same level
    const benefits = policy.usages.map(u => {
      return {
        ...u.benefit,
        currentAmount: u.currentAmount,
        currentCount: u.currentCount,
        perClaimExcess: policy.productVersion.perClaimExcess
      };
    });

    // Now create the benefit tree
    const [tree] = flatToTree(benefits, 'id', 'parent', 'children');

    const makeBenefit = (root: any): BenefitUsageInput => ({
      title: root.title,
      limit: {
        amount: {
          total: root.beneficiaryLimitAmount,
          remaining: root.beneficiaryLimitAmount - root.currentAmount
        },
        uses: {
          total: root.beneficiaryLimitCount,
          remaining: root.beneficiaryLimitCount - root.currentCount
        }
      },
      coPayment: {
        pct: root.coPayment,
        max: root.coPaymentMax
      },
      excess: {
        benefit: root.excess,
        claim: root.perClaimExcess
      },
      exclusions: [], // We'll add to this once the exclusions finish fetching
      children: root.children ? root.children.map(c => makeBenefit(c)) : []
    });

    return [makeBenefit(tree), policy.productVersion.currency.code, policy.id];
  }
}
