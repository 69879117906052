<div [style.width]="elementWidth + 'px'">
  <div class="Breadcrumbs" (mouseleave)="blur()">
    <div
      class="Breadcrumbs__crumb"
      *ngFor="let breadcrumb of breadcrumbs; let i = index"
      [ngClass]="{ 'Breadcrumbs__crumb--collapsed': shouldCollapse(i) }"
      (mouseenter)="focus(i)"
      (click)="navigate(breadcrumb.routerLink)"
    >
      <h4>{{ breadcrumb.title }}</h4>
      <ul>
        <li *ngFor="let item of breadcrumb.items">
          <span class="font--semiBold">{{ item.name }}</span>
          {{ item.value }}
        </li>
      </ul>
      <fa-icon class="Breadcrumbs__chevron" [icon]="icons.faChevronRight"></fa-icon>
    </div>
  </div>
</div>
