import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataTableComponent } from './data-table.component';
import { DataTableColumnComponent } from './data-table-column.component';
import { PaginationComponentModule } from '../pagination';

@NgModule({
  imports: [CommonModule, PaginationComponentModule],
  declarations: [DataTableComponent, DataTableColumnComponent],
  exports: [DataTableComponent, DataTableColumnComponent]
})
export class DataTableComponentModule {}
