import { Component, Input } from '@angular/core';
import { CodesExclusionType } from '@common/gql';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ps-benefit-exclusions[exclusions]',
  templateUrl: './benefit-exclusions.component.html',
  styleUrls: ['./benefit-exclusions.component.scss']
})
export class BenefitExclusionsComponent {
  @Input() exclusions: CodesExclusionType[];

  icons = {
    faExclamationCircle
  };

  get count() {
    return this.exclusions.length;
  }
}
