<ps-modal heading="Fund Details" #modal>
  <div class="modal-body">
    <div class="data-wrapper">
      <div class="data-label">IBAN</div>
      <div class="data-value">
        {{ iban }}
      </div>
    </div>
    <div class="data-wrapper mt-3">
      <div class="data-label">BIC</div>
      <div class="data-value">
        {{ bic }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ps-button (onClick)="modal.close()" btnClass="btn btn-primary" i18n="@@okBtn">
      OK
    </ps-button>
  </div>
</ps-modal>
