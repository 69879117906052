<ps-modal heading="Payment Controls" #modal>
  <div class="modal-body">
    <div class="Loader" *ngIf="loading"></div>
    <p *ngIf="!loading && rulesGlobal.length === 0">
      No global rules were found.
    </p>
    <div *ngIf="paymentVelocity || paymentFrequency" class="rulesWrapper">
      <!-- Payment Velocity Control -->
      <div class="card" *ngIf="paymentVelocity">
        <div class="card-body">
          <h5 class="d-flex">
            <ng-container i18n="@@paymentVelocityTitle">
              Payment Velocity
            </ng-container>
            <div *ngIf="isTriggered(ruleTypes.PaymentVelocityControl)" class="ml-3">
              <span class="badge badge-danger" i18n="@@triggered">Triggered</span>
            </div>
          </h5>
          <div class="d-flex">
            <div
              *ngIf="!paymentVelocityFormOpen"
              class="d-flex flex-grow-1 justify-content-between"
            >
              {{ paymentVelocity.specification.amount }}
              <ng-container i18n="@@amounts">
                amounts
              </ng-container>
              /
              {{ paymentVelocity.specification.timeWindowMinutes }}
              minutes.
              <ps-button
                *ngIf="!paymentVelocityFormOpen"
                (onClick)="openPaymentVelocityForm()"
                container="body"
                btnClass="btn btn-secondary small"
                i18n="@@claimChangeProviderBtn"
                [icon]="icons.edit"
                iconClass="noText"
              >
              </ps-button>
            </div>
            <form
              *ngIf="paymentVelocityFormOpen"
              [formGroup]="paymentVelocityForm"
              (ngSubmit)="submitPaymentVelocityForm()"
              class="form-group d-flex flex-grow-1 justify-content-between"
            >
              <div>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="paymentVelocity.specification.amount"
                  formControlName="amount"
                />
                <ng-container i18n="@@amounts">
                  amounts
                </ng-container>
                /
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="paymentVelocity.specification.timeWindowMinutes"
                  formControlName="timeWindowMinutes"
                />
                minutes.
              </div>
              <ps-button
                [disabled]="!paymentVelocityForm.valid"
                type="submit"
                container="body"
                btnClass="btn btn-secondary small ml-1"
                [icon]="icons.check"
                iconClass="noText"
              >
              </ps-button>
            </form>
            <ps-button
              *ngIf="canResetRule(ruleTypes.PaymentVelocityControl)"
              tooltip="Resets payment velocity to its default values."
              (onClick)="resetPaymentVelocity()"
              container="body"
              btnClass="btn btn-secondary small ml-1"
              [icon]="icons.repeat"
              iconClass="noText"
            >
            </ps-button>
          </div>
        </div>
        <!-- Card Body end -->
      </div>
      <!-- Card end -->

      <!-- Payment Frequency -->
      <div class="card" *ngIf="paymentFrequency">
        <div class="card-body">
          <h5 class="d-flex">
            <ng-container i18n="@@paymentFrequencyTitle">
              Payment Frequency
            </ng-container>
            <div *ngIf="isTriggered(ruleTypes.PaymentFrequencyControl)" class="ml-3">
              <span class="badge badge-danger" i18n="@@triggered">Triggered</span>
            </div>
          </h5>
          <div class="d-flex">
            <div
              *ngIf="!paymentFrequencyFormOpen"
              class="d-flex flex-grow-1 justify-content-between"
            >
              {{ paymentFrequency.specification.count }}
              <ng-container *ngIf="paymentFrequency.specification.count === 1" i18n="@@transaction">
                transaction
              </ng-container>
              <ng-container
                *ngIf="paymentFrequency.specification.count !== 1"
                i18n="@@transactions"
              >
                transactions
              </ng-container>
              /
              {{ paymentFrequency.specification.timeWindowMinutes }}
              minutes.
              <ps-button
                *ngIf="!paymentFrequencyFormOpen"
                (onClick)="openPaymentFrequencyForm()"
                container="body"
                btnClass="btn btn-secondary small"
                i18n="@@claimChangeProviderBtn"
                [icon]="icons.edit"
                iconClass="noText"
              >
              </ps-button>
            </div>
            <form
              *ngIf="paymentFrequencyFormOpen"
              [formGroup]="paymentFrequencyForm"
              (ngSubmit)="submitPaymentFrequencyForm()"
              class="form-group d-flex flex-grow-1 justify-content-between"
            >
              <div>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="paymentFrequency.specification.count"
                  formControlName="count"
                />
                <ng-container
                  *ngIf="paymentFrequency.specification.count === 1"
                  i18n="@@transaction"
                >
                  transaction
                </ng-container>
                <ng-container
                  *ngIf="paymentFrequency.specification.count !== 1"
                  i18n="@@transactions"
                >
                  transactions
                </ng-container>
                /
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="paymentFrequency.specification.timeWindowMinutes"
                  formControlName="timeWindowMinutes"
                />
                minutes.
              </div>
              <ps-button
                [disabled]="!paymentFrequencyForm.valid"
                type="submit"
                container="body"
                btnClass="btn btn-secondary small ml-1"
                [icon]="icons.check"
                iconClass="noText"
              >
              </ps-button>
            </form>
            <ps-button
              *ngIf="canResetRule(ruleTypes.PaymentFrequencyControl)"
              tooltip="Resets payment velocity to its default values."
              (onClick)="resetPaymentFrequency()"
              container="body"
              btnClass="btn btn-secondary small ml-1"
              [icon]="icons.repeat"
              iconClass="noText"
            >
            </ps-button>
          </div>
        </div>
        <!-- Card Body end -->
      </div>
      <!-- Card end -->
    </div>
    <!-- .rulesWrapper end -->
  </div>
  <!-- .modal-body end -->
  <div class="modal-footer">
    <ps-button (onClick)="modal.close()" btnClass="btn btn-secondary" i18n="@@close">
      Close
    </ps-button>
  </div>
</ps-modal>
