import { Injectable } from '@angular/core';

import { User } from '../../models';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { UserFragments } from '@common/fragments';

@Injectable()
export class UserService {

  constructor(private apollo: Apollo) {
  }

  getMe(fragment?: any): Observable<User> {
    return this.apollo.watchQuery({
      query: gql`
        {
          users(isMe: true) {
            results {
              ...userFields
            }
          }
        }
        ${fragment ? fragment('userFields') : UserFragments.fat('userFields')}
      `,
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(map((res: any) => {
      const results = res.data.users.results;
      if (results.length > 0) {
        return new User().deserialize(results[0]);
      } else {
        throw new Error('Could not get current user');
      }
    }));
  }

  // TODO: Not sure if this logic is correct?
  getSome(ids: number[]): Observable<User[]> {
    return this.apollo.watchQuery({
      query: gql`
        ${ids.map(id => gql`
          {
            user${id}: user(id: ${id}) {
              ...userFields
            }
          }
        `)}
        ${UserFragments.slim('userFields')}
      `
    }).valueChanges.pipe(map((res: any) => {
      return [];
    }));
  }
}
