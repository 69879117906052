import { Injectable, Inject } from '@angular/core';

import * as moment from 'moment-timezone';
import { ISettingsProviderConfig, SettingsProviderConfig } from './settings.provider.module';

class Settings {
  timezone: string;
}

@Injectable({ providedIn: 'root' })
export class SettingsProvider {
  settings: Settings;

  constructor(@Inject(SettingsProviderConfig) private config: ISettingsProviderConfig) {
    this.settings = this.getSettings();

    if (!this.settings.timezone) {
      this.settings.timezone = moment.tz.guess();
      this.save();
    }
  }

  private getSettings(): Settings {
    const json = localStorage.getItem(this.config.localKey);
    const settings = new Settings();

    if (!json) {
      return settings;
    } else {
      Object.assign(settings, JSON.parse(json));
      return settings;
    }
  }

  save(): void {
    const json = JSON.stringify(this.settings);
    localStorage.setItem(this.config.localKey, json);
  }
}
