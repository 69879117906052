import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { ServiceConfig } from '../common';
import { PolicyService } from './policy.service';

export const PolicyServiceConfig = new InjectionToken<ServiceConfig>('PolicyServiceConfig');

@NgModule()
export class PolicyServiceModule {
  public static forRoot(config: ServiceConfig): ModuleWithProviders<PolicyServiceModule> {
    return {
      ngModule: PolicyServiceModule,
      providers: [PolicyService, { provide: PolicyServiceConfig, useValue: config }]
    };
  }
}
