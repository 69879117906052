<div class="ProviderPicker card card-border">
  <div class="card-body body-padding">
    <ng-container *ngIf="!selectedMerchant">
      <ul class="nav nav-pills">
        <li class="nav-item" role="presentation" [ngClass]="{ active: activeTab == 'existing' }">
          <a
            class="nav-link"
            [ngClass]="{ active: activeTab == 'existing' }"
            (click)="changeTab('existing')"
            >Existing Providers</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" [ngClass]="{ active: activeTab == 'new' }" (click)="changeTab('new')"
            >Add New Provider</a
          >
        </li>
      </ul>
      <div class="map" [hidden]="showProviderForm">
        <div class="search">
          <!-- @TODO: Use TypeaheadModule -->
          <input
            auto-complete
            *ngIf="!mustZoomIn"
            class="form-control"
            [placeholder]="
              activeTab == 'existing' ? 'Search Existing Providers' : 'Search Google Maps'
            "
            [source]="searchSource.bind(this)"
            [list-formatter]="searchListFormatter"
            [value-formatter]="searchValueFormatter"
            [open-on-focus]="false"
            [max-num-list]="5"
            (valueChanged)="searchValueChanged($event)"
            [formControl]="searchControl"
          />
          <div *ngIf="mustZoomIn" class="must-zoom">
            Zoom in another
            {{ requiredZoom == 1 ? 'time' : requiredZoom + ' times' }} to search an area
          </div>
        </div>
        <agm-map
          [latitude]="45"
          [longitude]="0"
          [zoom]="1"
          [streetViewControl]="false"
          [scrollwheel]="false"
          (boundsChange)="mapBoundsChanged.next($event)"
          (zoomChange)="zoomChanged($event)"
        >
          <agm-marker *ngFor="let m of mapMarkers" [latitude]="m.lat" [longitude]="m.lng">
            <agm-info-window>
              <div class="marker-info">
                <span class="name">{{ m.name }}</span>
                <span class="address">{{ m.address | truncate: 25 }}</span>
                <ps-button
                  type="button"
                  btnClass="btn btn-primary small"
                  (onClick)="selectMarker(m)"
                >
                  {{ m.merchant ? 'Select Provider' : 'Add To Providers' }}
                </ps-button>
              </div>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
      <form
        class="provider-form"
        *ngIf="showProviderForm"
        [formGroup]="providerForm"
        (ngSubmit)="submitProviderForm()"
        novalidate
      >
        <p>Please verify the following details.</p>
        <div
          class="form-group"
          [ngClass]="{
            'has-error': providerFormSubmitted && providerForm.get('name').invalid
          }"
        >
          <label for="name">Name</label>
          <input type="text" class="form-control" formControlName="name" />
          <span
            class="invalid-feedback"
            *ngIf="providerFormSubmitted && providerForm.get('name').invalid"
            >Enter a name</span
          >
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group"
              [ngClass]="{
                'has-error': providerFormSubmitted && providerForm.get('address').invalid
              }"
            >
              <label for="address">Address</label>
              <textarea class="form-control" formControlName="address" rows="3"></textarea>
              <span
                class="invalid-feedback"
                *ngIf="providerFormSubmitted && providerForm.get('address').invalid"
                >Enter an address</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="detailedInfo">Detailed Info</label>
              <textarea class="form-control" formControlName="detailedInfo" rows="3"></textarea>
            </div>
          </div>
        </div>
        <div
          class="form-group"
          [ngClass]="{
            'has-error': providerFormSubmitted && providerForm.get('category').invalid
          }"
        >
          <label for="iCategory" i18n="@@providerCategoriesLabel">Categories</label>
          <div class="input-group">
            <div class="input-group-append">
              <fa-icon class="input-group-text" [icon]="icons.search"></fa-icon>
            </div>
            <input
              type="text"
              formControlName="category"
              [typeahead]="categoryDataSource"
              [typeaheadOptionsLimit]="5"
              [typeaheadMinLength]="0"
              typeaheadOptionField="label"
              placeholder="Choose a category"
              i18n-placeholder="@@chooseProviderCategoryPholder"
              (typeaheadOnSelect)="selectCategory($event)"
              id="iCategories"
              class="form-control IconInput__input"
              autocomplete="off"
            />
          </div>
          <ng-container *ngIf="selectedCategories.length > 0">
            <label
              class="selectedCategoriesListLabel"
              for="selectedCategoriesList"
              i18n="@@providerSelectedCategoriesLabel"
              >Selected Categories:</label
            >
            <ul class="list-group" id="selectedCategoriesList">
              <li
                class="list-group-item"
                *ngFor="let category of selectedCategories; let i = index"
              >
                {{ category.name }} ({{ category.code }})
                <span
                  class="glyphicon glyphicon-remove"
                  aria-hidden="true"
                  (click)="removeCategory(i)"
                ></span>
              </li>
            </ul>
          </ng-container>
        </div>
        <ps-button type="submit" btnClass="btn btn-primary mr-1">Create Provider</ps-button>
        <a class="btn btn-secondary" (click)="closeProviderForm()">Cancel</a>
      </form>
    </ng-container>
    <div class="selected flex-column flex-sm-row" *ngIf="selectedMerchant">
      <div>
        <span class="name">{{ selectedMerchant.name }}</span>
        <span class="address">{{ selectedMerchant.address | truncate: 50 }}</span>
      </div>
      <ps-button
        class="mt-3 d-sm-none"
        btnClass="btn btn-secondary medium full-width"
        (onClick)="unselectProvider()"
      >
        Change
      </ps-button>
      <ps-button
        class="d-none d-sm-block"
        btnClass="btn btn-secondary medium"
        (onClick)="unselectProvider()"
      >
        Change
      </ps-button>
    </div>
  </div>
</div>
