import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { AuthProvider } from '@common/providers';

@Injectable({ providedIn: 'root' })
export class AuthGuardProvider implements CanActivate {
  constructor(private authProvider: AuthProvider, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (route.data.hasOwnProperty('loggedInRedirect') && this.authProvider.loggedIn) {
      this.router.navigate([route.data.loggedInRedirect]);
      return false;
    }

    if (route.data.hasOwnProperty('loggedOutRedirect') && !this.authProvider.loggedIn) {
      this.router.navigate([route.data.loggedOutRedirect]);
      return false;
    }

    return true;
  }
}
