<ps-modal [heading]="'Decision Process'" #modal>
  <div class="modal-body">
    <h5 class="DecisionProcessResult">
      List of decisions taken during the authorization process
      <span
        class="DecisionProcessResult__Label badge {{
          authAttempt.decisionResult ? 'badge-success' : 'badge-danger'
        }}"
      >
        <ng-container *ngIf="authAttempt.decisionResult">
          PASSED
        </ng-container>
        <ng-container *ngIf="!authAttempt.decisionResult">
          FAILED
        </ng-container>
      </span>
    </h5>

    <!-- Results -->
    <div class="Result" *ngFor="let result of authAttempt.appliedRules">
      <ps-decision-result
        [ruleName]="ruleDisplayName(result.ruleType)"
        [ruleDescription]="ruleDescription(result.ruleType, result.passed)"
        [result]="result.passed"
      >
        <!-- Result -->
        <!-- Show additional information only when the rule did not pass -->
        <div class="Result__content mt-1" *ngIf="!result.passed">
          <!-- Show a different result table for POS decision rules -->
          <ps-pos-decision-result
            *ngIf="isPosDecision(result.ruleType)"
            [result]="result"
            [ruleName]="result.ruleType"
          ></ps-pos-decision-result>
          <!-- TransactionTypeRule -->
          <ng-container *ngIf="result.ruleType === Rules.TransactionTypeRule">
            <table class="Result__table">
              <tbody>
                <tr>
                  <td>
                    <span class="Result__label" i18n="@@allowedTransactionTypes"
                      >Allowed transaction types:</span
                    >
                  </td>
                  <td>
                    <span class="Result__value">
                      <ng-container *ngFor="let item of result.expected.values; let i = index">
                        {{ i === result.expected.values.length - 1 ? item : item + ', ' }}
                      </ng-container>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="Result__label" i18n="@@actualTransactionType"
                      >Actual transaction type:</span
                    >
                  </td>
                  <td>
                    <span class="Result__value">{{ result.actual.value }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <!-- TransactionTypeRule end -->

          <!-- MandatoryNotNullFieldsRule -->
          <ng-container *ngIf="result.ruleType === Rules.MandatoryNotNullFieldsRule">
            <p>
              This is likely due to a problem with the connection between Visa and ourselves. We
              have been notified and will rectify the issue as soon as possible.
            </p>
          </ng-container>
          <!-- MandatoryNotNullFieldsRule -->

          <!-- BenefitAmountRule -->
          <ng-container *ngIf="result.ruleType === Rules.BenefitAmountRule">
            <div class="row Benefit">
              <div class="col-md-6">
                <h5>BENEFIT</h5>
                <table class="Result__table">
                  <tbody>
                    <tr>
                      <td>
                        <span class="Result__label" i18n="@@mechantCategoryCode"
                          >Merchant Category Code:</span
                        >
                      </td>
                      <td>
                        <span class="Result__value font--semiBold">{{
                          result.actual.category
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="Result__label" i18n="@@amount">Limit:</span>
                      </td>
                      <td>
                        <span class="Result__value font--semiBold">{{
                          result.actual.value | currency: currencyCode
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="col-md-6">
                <h5>TRANSACTION</h5>

                <table class="Result__table">
                  <tbody>
                    <tr>
                      <td>
                        <span class="Result__label" i18n="@@merchantCategoryCode"
                          >Merchant Category Code:</span
                        >
                      </td>
                      <td>
                        <span class="Result__value font--semiBold">{{
                          result.expected.category
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="Result__label" i18n="@@limit">Amount:</span>
                      </td>
                      <td>
                        <span class="Result__value font--semiBold">{{
                          result.expected.value | currency: currencyCode
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
          <!-- BenefitAmountRule end -->

          <!-- BenefitCountRule -->
          <ng-container *ngIf="result.ruleType === Rules.BenefitCountRule">
            <div class="row Benefit">
              <div class="col-md-6">
                <h5 i18n="@@benefit">
                  BENEFIT
                </h5>
                <table class="Result__table">
                  <tbody>
                    <tr>
                      <td>
                        <span class="Result__label" i18n="@@merchantCategoryCode"
                          >Merchant Category Code:</span
                        >
                      </td>
                      <td>
                        <span class="Result__value font--semiBold">
                          {{ result.expected.category }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="Result__label" i18n="@@maxTransactions"
                          >Max transactions:</span
                        >
                      </td>
                      <td>
                        <span class="Result__value font--semiBold">
                          {{ result.expected.value | currency: currencyCode }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <h5>Transaction</h5>
                <table class="Result__table">
                  <tbody>
                    <tr>
                      <td>
                        <span class="Result__label" i18n="@@mechantCategoryCode"
                          >Merchant Category Code:</span
                        >
                      </td>
                      <td>
                        <span class="Result__value font--semiBold">
                          {{ result.actual.category }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
          <!-- BenefitCountRule end -->

          <!-- ClaimPreAuthRule -->
          <div class="row" *ngIf="result.ruleType === Rules.ClaimPreauthRule">
            <div class="col-md-12">
              <h5>Attempted matches</h5>
              <table class="table-condensed pre-auths-table">
                <tbody>
                  <ng-container *ngFor="let m of authAttempt.preAuthMatching">
                    <tr>
                      <td class="pre-auths-table__expandCell">
                        <a class="pre-auths-table__expand">
                          <fa-icon
                            (click)="toggleMatchingExplanation(m)"
                            [icon]="m.expanded ? icons.caretDown : icons.caretRight"
                          ></fa-icon>
                        </a>
                      </td>
                      <td class="claim-number-cell">Claim #{{ m.claimId }}</td>
                      <td *ngIf="canViewClaim" class="view-claim-cell">
                        <div class="viewClaimContainer">
                          <ps-link
                            [loaderId]="'viewPreAuth' + m.claimId"
                            linkClass="btn btn-secondary small"
                            linkClass="btn btn-secondary small"
                            (onClick)="viewClaim(m.claimId)"
                            i18n="@@viewLink"
                            >View</ps-link
                          >
                        </div>
                      </td>
                    </tr>
                    <!-- ClaimPreAuthRule matching explanation expand -->
                    <tr *ngIf="m.expanded">
                      <td colspan="3" class="pre-auths-table__explanationCell">
                        <ps-decision-result
                          *ngFor="let e of m.matchingExplanation"
                          [ruleName]="ruleDisplayName(e.ruleType)"
                          [ruleDescription]="ruleDescription(e.ruleType, e.result)"
                          [result]="e.passed"
                        >
                          <div class="Result__content mt-1" *ngIf="!e.passed">
                            <!--TimestampRule-->
                            <ng-container *ngIf="e.ruleType === Rules.TimestampRule">
                              <table class="Result__table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <span class="Result__label" i18n="@@expectedRange"
                                        >Expected Range:</span
                                      >
                                    </td>
                                    <td>
                                      <span class="Result__value font--semiBold">
                                        {{ e.expected.valueFrom }} to
                                        {{ e.expected.valueTo }}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span class="Result__label" i18n="@@actualTimestamp"
                                        >Actual Timestamp:</span
                                      >
                                    </td>
                                    <td>
                                      <span class="Result__value font--semiBold">{{
                                        e.actual.value
                                      }}</span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </ng-container>
                          </div>
                          <!-- TimestampRule end -->
                        </ps-decision-result>
                      </td>
                    </tr>
                    <!-- PreAuthRule Matching Explanation row end -->
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <!-- ClaimPreAuthRule end -->
        </div>
        <!-- All rules end -->
      </ps-decision-result>
    </div>
    <!-- Result container end -->
  </div>
  <!-- Modal body end -->
  <div class="modal-footer">
    <ps-button (onClick)="modal.close()" btnClass="btn btn-primary" i18n="@@okBtn">OK</ps-button>
  </div>
</ps-modal>
x
