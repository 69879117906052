import { Model } from './model';
import { Customer } from './customer.model';
import { Policy } from './policy.model';
import { Rule } from './rule.model';

import { Breadcrumbizable, Breadcrumb } from '../interfaces';
import { toInt } from '@common/utils/model-transforms';
import { PaymentRulesOverride } from './payment-rules-override.model';
import { Card } from './card.model';

export class Beneficiary extends Model implements Breadcrumbizable {
  id: number;
  policies: Policy[];
  policyExternalIds: number[];
  cards: Card[];
  customer: Customer;
  memberSince: string;
  isActive: boolean;
  hasDummyClaims: boolean;
  parentsIds: number[];
  mobileAppAllowd?: boolean;
  cardAllowed?: boolean;
  isLeading?: boolean;
  rules: Rule[];
  rulesOverride: any;
  dependants: { id: number }[];
  requestedClaims: { id: number };

  parent: Beneficiary;
  paymentRulesOverrides?: PaymentRulesOverride[];

  constructor() {
    super([
      { key: 'id', transform: toInt },
      { key: 'policies', useModel: Policy },
      'policyExternalIds',
      { key: 'cards', useModel: Card },
      { key: 'customer', useModel: Customer },
      'memberSince',
      'isActive',
      'hasDummyClaims',
      'parentsIds',
      { key: 'parent', useModel: Beneficiary },
      'mobileAppAllowed',
      'cardAllowed',
      'isLeading',
      'rulesOverride',
      { key: 'rules', useModel: Rule },
      'dependants',
      { key: 'paymentRulesOverrides', useModel: PaymentRulesOverride },
      'requestedClaims'
    ]);
  }

  breadcrumbize(): Breadcrumb {
    return {
      title: 'Beneficiary',
      routerLink: '../../',
      items: [
        {
          name: 'Member ID',
          value: this.customerExternalId
        },
        {
          name: 'Name',
          value: this.customerFullName
        },
        {
          name: 'Date of Birth',
          value: this.customerDateOfBirth || '-'
        },
        {
          name: 'Address',
          value: this.customerFormattedAddress
        }
      ]
    };
  }

  // Get first card ID or return null
  get cardId(): number {
    if (!this.cards || this.cards.length === 0) {
      return null;
    }
    return this.cards[0].id;
  }

  // Get first card or return null
  get card(): Card {
    if (this.cards.length === 0) {
      return null;
    }
    return this.cards[0];
  }

  // Get first policy ID or return null
  get policyId(): number {
    if (this.policies.length === 0) {
      return null;
    }
    return this.policies[0].id;
  }

  get policy(): Policy {
    if (this.policies.length === 0) {
      return null;
    }

    return this.policies[0];
  }

  // Get first policy external ID or return null
  get policyExternalId(): number {
    if (!this.policyExternalIds || this.policyExternalIds.length === 0) {
      return null;
    }
    return this.policyExternalIds[0];
  }

  get isParentBeneficiary(): boolean {
    return !this.parentsIds || this.parentsIds.length === 0;
  }

  get dependantsIds(): number[] {
    return this.dependants.map(item => item.id);
  }

  get filteredCustomerDataPlaceholder(): string {
    return this.customer === null ? '[filtered]' : null;
  }

  // Customer getters
  get customerFirstName(): string {
    return this.filteredCustomerDataPlaceholder || this.customer.firstName;
  }

  get customerLastName(): string {
    return this.filteredCustomerDataPlaceholder || this.customer.lastName;
  }

  get customerFullName(): string {
    return this.filteredCustomerDataPlaceholder || this.customer.fullName;
  }

  get customerEmail(): string {
    return this.filteredCustomerDataPlaceholder || this.customer.email;
  }

  get customerExternalId(): string {
    return this.filteredCustomerDataPlaceholder || this.customer.externalId;
  }

  get customerDateOfBirth(): string {
    return this.filteredCustomerDataPlaceholder || this.customer.dateOfBirth;
  }

  get customerFormattedAddress(): string {
    return this.filteredCustomerDataPlaceholder || this.customer.formattedAddress;
  }
}
