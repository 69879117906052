import { Model } from './model';
import { Statistics } from './statistics.model';
import { Currency } from './currency.model';
import { InsurerFundsAmount } from './insurer-funds-amount.model';

export class FundingAccount extends Model {
  statistics: Statistics;
  currency: Currency;
  iban: string;
  bic: string;

  constructor() {
    super([
      { key: 'statistics', useModel: Statistics },
      { key: 'currency', useModel: Currency },
      'iban',
      'bic'
    ]);
  }

  get insurerFundsAmount(): InsurerFundsAmount {
    // each FundingAccount contains exactly one record within insurerFundsAmount array
    return this.statistics.insurerFundsAmounts[0];
  }
}
