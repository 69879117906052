import gql from "graphql-tag";

export const BenefitFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on BenefitType {
      id
      title
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on BenefitType {
      id
      parent {
        id
      }
      category {
        title
        description
        icon
      }
      title
      description
      excess
      limitCount
      limitAmount
      beneficiaryLimitCount
      beneficiaryLimitAmount
      renewalAtYearsEnd
      coPayment
      coPaymentMax
      minimalDurationDays
    }
  `
};
