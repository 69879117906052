import gql from 'graphql-tag';

export const BeneficiaryDataUnlockFragments = {
  slim: (fragmentName: string) => gql`
  fragment ${fragmentName} on BeneficiaryDataUnlockType {
    createdAt
    usedAt
    isEnabled
    provider {
      id
    }
    beneficiary {
      id
    }
  }`,
  fat: (fragmentName: string) => gql`
  fragment ${fragmentName} on BeneficiaryDataUnlockType {
    createdAt
    usedAt
    isEnabled
    provider {
      id
    }
    beneficiary {
      id
    }
  }`,
  count: (fragmentName: string) => gql`
  fragment ${fragmentName} on BeneficiaryDataUnlockType {
    id
  }
`
};
