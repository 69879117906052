import gql from 'graphql-tag';
import { RulesCardFragments } from './rules-card-override.fragments';
import { RulesDisableFragments } from './rules-disable-override.fragments';

export const PaymentRulesOverrideFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentRulesOverrideType {
      id
      override {
        ruleName
        ...rulesCardFatFields
        ...rulesDisableSlimFields
      }
    }
    ${RulesCardFragments.fat('rulesCardFatFields')}
    ${RulesDisableFragments.slim('rulesDisableSlimFields')}
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on PaymentRulesOverrideType {
      id
      override {
        ruleName
        ...rulesCardFatFields
        ...rulesDisableSlimFields
      }
    }
    ${RulesCardFragments.fat('rulesCardFatFields')}
    ${RulesDisableFragments.slim('rulesDisableSlimFields')}
  `
};
