import gql from "graphql-tag";

export const MarketFxRateFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on MarketFxRateObject {
      pair
      quote
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on MarketFxRateObject {
      pair
      quote
    }
  `
};
