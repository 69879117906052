import gql from 'graphql-tag';
import { CustomerFragments } from './customer.fragments';
import { PolicyFragments } from './policy.fragments';

export const BeneficiaryFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on BeneficiaryType {
      id
      customer {
        ...customerSlimFields
      }
    }
    ${CustomerFragments.slim('customerSlimFields')}
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on BeneficiaryType {
      id
      customer {
        ...customerFatFields
      }
      memberSince
      isActive
      hasDummyClaims
      mobileAppAllowed
      cardAllowed
      isLeading
      policies {
        ...policySlimFields
      }
      cards {
        id
      }
      dependants {
        id
      }
    }
    ${PolicyFragments.slim('policySlimFields')}
    ${CustomerFragments.fat('customerFatFields')}
  `
};
