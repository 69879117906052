import gql from 'graphql-tag';

export const RulesDisableFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on RulesDisableOverrideType {
      disabledRules
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on RulesDisableOverrideType {
      disabledRules
    }
  `
};
