import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter.component';
import { FiltersComponent } from './filters.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonComponentModule } from '@common/components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    ButtonComponentModule,
    FontAwesomeModule
  ],
  declarations: [FilterComponent, FiltersComponent],
  exports: [FilterComponent, FiltersComponent]
})
export class FiltersComponentModule {}
