import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Service, ServiceConfig } from '../common';
import {
  SearchResult,
  ClaimResult,
  MerchantResult,
  PolicyResult,
  ProductVersionResult,
  Claim,
  Merchant,
  ProductVersion,
  Policy
} from '../../models';
import { camelCaseObj } from '@common/utils';
import { ClaimService } from '../claim.service';
import { MerchantService } from '../merchant.service';
import { PolicyService } from '../policy.service';
import { ProductVersionService } from '../product-version.service';
import { SearchServiceConfig } from './search.service.module';
import { Platform } from '@common/enums';

@Injectable({ providedIn: 'root' })
export class SearchService extends Service {
  constructor(
    http: HttpClient,
    @Inject(SearchServiceConfig) serviceConfig: ServiceConfig,
    private claimService: ClaimService,
    private merchantService: MerchantService,
    private policyService: PolicyService,
    private productVersionService: ProductVersionService
  ) {
    super({
      httpClient: http,
      apiEndpoint: `${serviceConfig.baseUrl}/search_360`
    });
  }

  static getIds(arr: any[]) {
    return arr.map(item => item['indexedFields']['id']);
  }

  createSearchResults(res: any[]): Observable<SearchResult[]> {
    const results = {
      all: [],
      claims: [],
      merchants: [],
      productVersions: [],
      policies: []
    };

    results.all = res.map(result => {
      const type = result['type'];
      delete result['data'];

      switch (type) {
        case 'claim':
          results.claims.push(result);
          return result;
        case 'merchant':
          results.merchants.push(result);
          return result;
        case 'policy':
          results.policies.push(result);
          return result;
        case 'product_version':
          results.productVersions.push(result);
          return result;
        default:
          return null; // Skip result
      }
    });

    const claimIds = SearchService.getIds(results.claims);
    const merchantIds = SearchService.getIds(results.merchants);
    const policyIds = SearchService.getIds(results.policies);
    const productVersionIds = SearchService.getIds(results.productVersions);

    const claimFilters = {
      source_In: [
        Platform.PaymentPlatform,
        Platform.InsurerDashboard,
        Platform.ImportScript,
        Platform.MobileApp
      ]
    };

    const policyFilters = {
      externalId_Not_In: ['DUMMY_PRODUCT']
    };

    return forkJoin(
      this.claimService.getSome(claimIds, { filters: claimFilters }),
      this.merchantService.getSome(merchantIds),
      this.policyService.getSome(policyIds, { filters: policyFilters }),
      this.productVersionService.getSome(productVersionIds, { fat: true })
    ).pipe(
      map(
        ([claims, merchants, policies, productVersions]: [
          Claim[],
          Merchant[],
          Policy[],
          ProductVersion[]
        ]) => {
          return results.all
            .filter(item => item !== null)
            .map(item => {
              const type = item['type'];
              delete item['type'];

              switch (type) {
                case 'claim':
                  item['claim'] = claims.find(
                    c => parseInt(item['indexedFields']['id'], 10) === c.id
                  );
                  return item['claim'] && new ClaimResult().deserialize(item);
                case 'merchant':
                  item['merchant'] = merchants.find(
                    m => parseInt(item['indexedFields']['id'], 10) === m.id
                  );
                  return new MerchantResult().deserialize(item);
                case 'policy':
                  item['policy'] = policies.find(
                    p => parseInt(item['indexedFields']['id'], 10) === p.id
                  );
                  return item['policy'] && new PolicyResult().deserialize(item);
                case 'product_version':
                  item['productVersion'] = productVersions.find(
                    pv => item['indexedFields']['id'] === pv.id
                  );
                  return new ProductVersionResult().deserialize(item);
              }
            })
            .filter(item => item);
        }
      )
    );
  }

  search(query: string): Observable<SearchResult[]> {
    return this.request<any[]>({
      method: 'GET',
      params: {
        query: query
      }
    }).pipe(
      mergeMap((res: any) => {
        const results: any[] = res.results;
        if (results.length === 0) {
          return of([]);
        }
        // We need to camelCase all results as the search-service still uses old snake_case keys
        return this.createSearchResults(results.map(item => camelCaseObj(item)));
      })
    );
  }
}
