import { Model } from './model';

class ClaimObligations extends Model {
  receivables: number;
  liabilities: number;

  constructor() {
    super(['receivables', 'liabilities']);
  }
}

export class Netting extends Model {
  thisClaim: ClaimObligations;
  previousClaims: ClaimObligations;

  constructor() {
    super([
      { key: 'this_claim', useModel: ClaimObligations },
      { key: 'previous_claims', useModel: ClaimObligations }
    ]);
  }
}
