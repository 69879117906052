import gql from 'graphql-tag';

export const CountryFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on CountryType {
      id
      code
      name
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on CountryType {
      id
      code
      name
    }
  `
};
