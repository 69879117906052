import gql from 'graphql-tag';
import { MerchantFragments } from './merchant.fragments';
import { CurrencyFragments } from './currency.fragments';
import { ReceiptFragments } from './receipt.fragments';
import { SupportingDocumentFragments } from './supporting-document.fragments';
import { BenefitFragments } from './benefit.fragments';
import { ClaimIcdCodeFragments } from './claim-icd-code.fragments';
import { PaymentAuthorizationFragments } from './payment-authorization.fragments';
import { ClaimBreakdownFragments } from './claim-breakdown.fragments';
import { CustomerFragments } from './customer.fragments';

export const ClaimFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on ClaimType {
      id
      state
      maxAmount
      acceptedAmount
      transactionAmount
      created
      currency {
        ...currencySlimFields
      }
      merchant {
        ...merchantSlimFields
      }
      preAuthorization {
        preferredMerchant {
          ...merchantSlimFields
        }
      }
      policy {
        id
      }
      beneficiary {
        id
      }
      benefit {
        ...benefitSlimFields
      }
    }
    ${MerchantFragments.slim('merchantSlimFields')}
    ${CurrencyFragments.slim('currencySlimFields')}
    ${BenefitFragments.slim('benefitSlimFields')}
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on ClaimType {
      id
      creatorId
      currency {
        ...currencyFatFields
      }
      benefit {
        ...benefitFatFields
      }
      state
      maxAmount
      acceptedAmount
      eligibleAmount
      totalAmount
      transactionAmount
      source
      additionalInformation
      merchant {
        ...merchantFatFields
      }
      preAuthorization {
        symptoms
        comments
        condition
        isNewCondition
        receivedTreatment
        timeWindowFrom
        timeWindowTo
        preferredDate
        preferredMerchant {
          ...merchantFatFields
        }
      }
      policy {
        id
      }
      beneficiary {
        id
        customer {
          ...customerFatFragments
        }
      }
      isDummy
      reason
      retroactive
      amountToBeCharged
      amountToBeReimbursed
      netting {
        thisClaim {
          receivables
          liabilities
        }
        previousClaims {
          receivables
          liabilities
        }
      }
      claimicdcode {
        ...claimIcdCodeFatFields
      }
      cptCodes {
        code
      }
      receipts {
        ...receiptFatFields
      }
      supportingDocuments {
        ...supportingDocumentFatFields
      }
      payments {
        amount
        createdAt
        state
        transactionTime
        __typename
      }
      paymentAuthorizations {
        ...paymentAuthorizationFatFields
      }
      breakdown {
        ...claimBreakdownFatFields
      }
      created
      updated
    }
    ${PaymentAuthorizationFragments.fat('paymentAuthorizationFatFields')}
    ${ClaimIcdCodeFragments.fat('claimIcdCodeFatFields')}
    ${MerchantFragments.fat('merchantFatFields')}
    ${CurrencyFragments.fat('currencyFatFields')}
    ${ReceiptFragments.fat('receiptFatFields')}
    ${SupportingDocumentFragments.fat('supportingDocumentFatFields')}
    ${BenefitFragments.fat('benefitFatFields')}
    ${ClaimBreakdownFragments.fat('claimBreakdownFatFields')}
    ${CustomerFragments.fat('customerFatFragments')}
  `
};
