// Some quick-access transformers compatiable with our model transform mapping.

export function toInt(val: any) {
  return parseInt(val, 10);
}

export function toFloat(val: any) {
  return parseFloat(val);
}

export function parseJSON(val: string) {
  try {
    return JSON.parse(val);
  } catch (e) {
    return val;
  }
}

export function toDate(val: string) {
  return new Date(val);
}
