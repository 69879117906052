import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ModalDialog } from '@common/interfaces';
import { ModalComponent } from '@common/components';

@Component({
  selector: 'ps-add-claim-note',
  templateUrl: './add-claim-note.component.html',
  styleUrls: ['./add-claim-note.component.scss']
})
export class AddClaimNoteComponent implements ModalDialog, OnInit {
  form: FormGroup;
  description: string;

  @ViewChild('modal', { static: true }) modal: ModalComponent;
  @ViewChild('textarea', { read: ViewContainerRef, static: true }) textarea: ViewContainerRef;

  constructor(private formBuilder: FormBuilder) {}

  initModal({ description }) {
    this.description = description;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      text: [null, Validators.required]
    });

    this.textarea.element.nativeElement.focus();
  }

  submitForm() {
    this.modal.close({
      success: true,
      text: this.form.get('text').value
    });
  }
}
