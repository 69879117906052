import { Model } from './model';
import { Corporation } from './corporation.model';

export class CorporatePolicyChain extends Model {
  id: number;
  external_id?: string;
  corporationIds: number[];
  chainIds: number[];
  corporation?: Corporation;

  constructor() {
    super([
      'id',
      'external_id',
      'corporationIds',
      'chainIds',
      { key: 'corporation', useModel: Corporation }
    ]);
  }
}
