import gql from "graphql-tag";

export const CurrencyFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on CurrencyType {
      id
      code
      name
    }
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on CurrencyType {
      id
      code
      name
      symbolBefore
      symbolAfter
    }
  `
};
