import { Model } from './model';
import { Customer } from './customer.model';
import { Beneficiary } from './beneficiary.model';
import { Corporation } from './corporation.model';

export class PolicyHolder extends Model {
  id: number;
  corporate: boolean;
  private _customer: Customer;
  corporation: Corporation;
  chainIds: number[];
  beneficiary: Beneficiary;

  constructor() {
    super([
      'id',
      'corporate',
      'beneficiaryId',
      { key: '_customer', from: 'customer', useModel: Customer },
      { key: 'corporation', useModel: Corporation },
      'chainIds',
      { key: 'beneficiary', useModel: Beneficiary }
    ]);
  }

  /**
   * Get customer object
   * If no customer exists on policy holder, return beneficiary.customer
   */
  get customer(): Customer {
    if (this._customer) {
      return this._customer;
    } else if (this.beneficiary) {
      return this.beneficiary.customer;
    } else {
      return null;
    }
  }

  set customer(customer: Customer) {
    this._customer = customer;
  }
}
