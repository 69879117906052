import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GQLService } from '../common';
import { SupportingDocument } from '../../models';
import { DocumentDecision } from '../../interfaces';
import { Apollo } from 'apollo-angular';
import { SupportingDocumentFragments } from '@common/fragments';
import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { DocumentFileType } from '@common/enums';

@Injectable({ providedIn: 'root' })
export class SupportingDocumentService extends GQLService<SupportingDocument> {
  constructor(apollo: Apollo) {
    super({
      apollo,
      model: SupportingDocument,
      singular: 'supportingDocument',
      plural: 'supportingDocuments',
      fragments: SupportingDocumentFragments,
      constants: ['source']
    });
  }

  create(
    body: { claimId: number; source: string; fileType: DocumentFileType },
    file: any
  ): Observable<any> {
    const { claimId, source, fileType } = body;
    const variables: any = {
      file
    };
    const context: any = {
      useMultipart: true
    };
    return this.apollo
      .mutate({
        mutation: gql`
        mutation($file: Upload!) {
          supportingDocumentCreate(newSupportingDocument: {
            claimId: ${claimId}
            source: ${source},
            fileType: ${String(fileType)}
            file: $file
          }) {
            ok
            supportingdocument {
              ...supportingDocumentFatFields
            }
          }
        }
        ${SupportingDocumentFragments.fat('supportingDocumentFatFields')}
      `,
        variables,
        context
      })
      .pipe(
        map(res => {
          const data = res.data['supportingDocumentCreate'];
          if (data.ok) {
            return new SupportingDocument().deserialize(data['supportingdocument']);
          } else {
            throw new Error(
              `Couldn't upload a supporting document. Response: ${JSON.stringify(data)}`
            );
          }
        })
      );
  }

  generate(body: { claimId: number; fileType: DocumentFileType }): Observable<any> {
    const { claimId, fileType } = body;
    const variables: any = {};
    const context: any = {
      useMultipart: true
    };
    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          supportingDocumentCreate(generateDocument: {
            claimId: ${claimId}
            fileType: ${String(fileType)}
          }) {
            ok
            supportingdocument {
              ...supportingDocumentFatFields
            }
          }
        }
        ${SupportingDocumentFragments.fat('supportingDocumentFatFields')}
      `,
        variables,
        context
      })
      .pipe(
        map(res => {
          const data = res.data['supportingDocumentCreate'];
          if (data.ok) {
            return new SupportingDocument().deserialize(data['supportingdocument']);
          } else {
            throw new Error(`Couldn't generate a document. Response: ${JSON.stringify(data)}`);
          }
        })
      );
  }

  sendGuaranteeOfPayment(body: { documentId: number }): Observable<SupportingDocument> {
    const { documentId } = body;
    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          supportingDocumentUpdate(sendGuaranteeOfPayment: {
            id: ${documentId}
          }) {
            ok
            supportingdocument {
              ...supportingDocumentFatFields
            }
          }
        }
        ${SupportingDocumentFragments.fat('supportingDocumentFatFields')}
      `
      })
      .pipe(
        map(res => {
          const data = res.data['supportingDocumentUpdate'];
          if (data.ok) {
            return new SupportingDocument().deserialize(data['supportingdocument']);
          } else {
            throw new Error(
              `Couldn't send guarantee of payment. Response: ${JSON.stringify(data)}`
            );
          }
        })
      );
  }

  makeDecision(documentId: number, decision: DocumentDecision): Observable<any> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation {
          supportingDocumentUpdate(newSupportingDocumentDecision: {
            id: ${documentId}
            documentAction: ${decision.action}
            reason: "${decision.reason}"
          }) {
            ok
            supportingdocument {
              ...supportingDocumentFatFields
            }
          }
        }
        ${SupportingDocumentFragments.fat('supportingDocumentFatFields')}
      `
      })
      .pipe(
        map(res => {
          const data = res.data['supportingDocumentUpdate'];
          if (data.ok) {
            return new SupportingDocument().deserialize(data['supportingdocument']);
          } else {
            throw new Error(
              `Couldn't make decision for document #${documentId}. Response: ${JSON.stringify(
                data
              )}`
            );
          }
        })
      );
  }
}
