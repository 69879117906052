import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CardActionModalComponent } from './card-action-modal.component';
import { ButtonComponentModule } from '../button';
import { ModalComponentModule } from '../modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponentModule,
    ModalComponentModule
  ],
  declarations: [CardActionModalComponent],
  entryComponents: [CardActionModalComponent],
  exports: [CardActionModalComponent]
})
export class CardActionModalComponentModule {}
