import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonComponentModule } from '../button';
import { ModalComponentModule } from '../modal';
import { GenerateDocumentComponent } from './generate-document.component';

@NgModule({
  imports: [CommonModule, ButtonComponentModule, ModalComponentModule],
  declarations: [GenerateDocumentComponent],
  exports: [GenerateDocumentComponent]
})
export class GenerateDocumentComponentModule {}
