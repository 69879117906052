<nav aria-label="Page navigation">
  <ul class="pagination d-flex justify-content-end">
    <li class="page-item" [ngClass]="{ disabled: !enablePrev }">
      <a class="page-link" (click)="prev()" aria-label="Previous">&laquo; Prev</a>
    </li>
    <ng-container *ngIf="showFirst">
      <li class="page-item"><a class="page-link" (click)="goto(1)">1</a></li>
      <li class="spacer page-item" *ngIf="pages[0].number !== 2">
        <a class="page-link">&hellip;</a>
      </li>
    </ng-container>
    <li class="page-item" *ngFor="let page of pages" [ngClass]="{ active: page.current }">
      <a class="page-link" *ngIf="!page.current" (click)="goto(page.number)">{{ page.number }}</a>
      <a class="page-link" *ngIf="page.current"
        >{{ page.number }} <span class="sr-only">(current)</span></a
      >
    </li>
    <ng-container *ngIf="showLast">
      <li class="spacer page-item" *ngIf="pages[pages.length - 1].number !== totalPages - 1">
        <a class="page-link">&hellip;</a>
      </li>
      <li class="page-item">
        <a class="page-link" (click)="goto(totalPages)">{{ totalPages }}</a>
      </li>
    </ng-container>
    <li class="page-item" [ngClass]="{ disabled: !enableNext }">
      <a class="page-link" (click)="next()" aria-label="Next">Next &raquo;</a>
    </li>
  </ul>
</nav>
