import gql from "graphql-tag";
import { UserFragments } from './user.fragments';

export const ClaimHistoryFragments = {
  slim: (fragmentName: string) => gql`
    fragment ${fragmentName} on NotificationType {
      id
      id
      action
      data
      source
      user {
        ...userSlimFields
      }
      timestamp
    }
    ${UserFragments.slim('userSlimFields')}
  `,
  fat: (fragmentName: string) => gql`
    fragment ${fragmentName} on NotificationType {
      id
    }
  `
};
